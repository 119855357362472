import { CertificateApi, IAMApi, Configuration } from "@certblock/api";

const BASEPATH = window.ApiCertblock.apiUrl;

export class ApiService {

    constructor() {
        
    }

    getIAM_API(jwt) {

        const CONF = new Configuration({ apiKey: jwt, basePath: BASEPATH }); 
        const IAM_API= new IAMApi(CONF);
        
        return IAM_API;
    }

    getCRT_API(jwt) {

        const CONF = new Configuration({ apiKey: jwt, basePath: BASEPATH }); 
        const CRT_API = new CertificateApi(CONF);
        
        return CRT_API;

    }
}

export default ApiService
