import React from 'react';
import MdDelete from 'react-icons/lib/md/delete-forever';
import MdEdit from 'react-icons/lib/md/edit';
import Messages from '../messages';
import 'react-datepicker/dist/react-datepicker.css';
import '../resources/css/profile.css';
import { FormattedMessage } from 'react-intl';
import LoadingComponent from '../components/LoadingComponent';
import axios from 'axios';
import OidcProvider from '../services/OidcProvider';

import Footer from '../components/Footer';

import { ApiService } from '../services/ApiService';
import NavBar from '../components/NavBar';

const globalMessages = Messages;

export class ProfileContainer extends React.Component {

    constructor(props) {

        super(props);
        this.apiConfig = window.ApiConfig;
        const companyData = localStorage.getItem('oidc_user') ? JSON.parse(localStorage.getItem('oidc_user')) : { name: "", street: "", zip: "", city: "", country: "" };

        this.state = {
            isSupplier: false,
            readyOnly: true,
            companyName: companyData.name,
            companyStreet: companyData.street,
            companyZipCode: companyData.zip,
            companyCity: companyData.city,
            companyCountry: companyData.country,
            supplierNumber : '',
            testerName: '',
            testDescription: '',
            editTester: '',
            testers: [],
            tests: [],
            addMaterialTestHidden: false,
            companyNameValid: true,
            companyZipCodeValid: true,
            companyStreetValid: true,
            companyCityValid: true,
            companyCountryValid: true,
            testerNameValid: false,
            materialTestDescriptionValid: false,
            emailValid: false,
            formValid: false,
            showLoadingModal: false,
            btnDeleteTestersDisable: false,
            preventDeleteTesters: false,
            editTesterMode: false,
            editPosition: '',
            editTesterCardColor: '#ffffff',
            formErrors: {
                email: '',
                companyName: '',
                companyZipCode: '',
                companyStreet: '',
                companyCity: '',
                companyCountry: '',
                testerName: '',
                materialTestDescription: ''
            }
        };

    }

    componentDidMount() {
        this.getRole();
        this.getTestersAndOverviewTests();  
    }

    getUser = () => {
        return JSON.parse(localStorage.getItem('oidc_user'));
    };

    getTestersAndOverviewTests = async () => {

        const user = this.getUser();

        const certblockAPI = new ApiService();
        const IAM_API = certblockAPI.getIAM_API(user.id_token);

        IAM_API.userCurrent()                        
        .then((certblockUser) => {
            this.setState({ 
                tests: certblockUser.data.materialTests ? certblockUser.data.materialTests : [], 
                supplierNumber: certblockUser.data.supplier ? certblockUser.data.supplier : '',
                testers: certblockUser.data.testers ? certblockUser.data.testers : [], 
            }) 
        }).catch((error) => {
            //this.setState({ addMaterialTestHidden: true });
            console.error("DEBUG: [tests] Unable to get tests:", error);
        });
    
    }

    getRole = () => {
        const oidc = new OidcProvider();
        const user = oidc.getUser();
        
        if (user.role === 'userSupplier') {
            this.setState({ isSupplier: true });
        }

    }


    toogleTab = (e) => {

        document.getElementById("v-pills-profile-tab").className = "nav-link";
        document.getElementById("v-pills-testers-tab").className = "nav-link";
        document.getElementById("v-pills-tests-tab").className = "nav-link";
        document.getElementById("v-pills-profile").className = "tab-pane fade";
        document.getElementById("v-pills-testers").className = "tab-pane fade";
        document.getElementById("v-pills-tests").className = "tab-pane fade";

        document.getElementById(e.currentTarget.id).className = "nav-link active show";
        document.getElementById(e.currentTarget.id.split("-tab")[0]).className = "tab-pane fade show active";
    };

    handleAddTester = async () => {

        const user = this.getUser();

        if (this.state.testerName.length >= 2) {
            this.setState({
                showLoadingModal: true
            });

            const certblockAPI = new ApiService();
            const IAM_API = certblockAPI.getIAM_API(user.id_token);
            let userCurrent = await IAM_API.userCurrent();

            if (!userCurrent.data.testers) {
                userCurrent.data.testers = [];
            }

            userCurrent.data.testers.push(this.state.testerName);

            await IAM_API.userUpdate(userCurrent.data.id, userCurrent.data);
            await this.getTestersAndOverviewTests();

            this.setState({
                showLoadingModal: false
            });

        }
    };

    handleDeleteTester = async (e) => {
        this.setState({
            showLoadingModal: true
        });

        const user = this.getUser();

        const certblockAPI = new ApiService();
        const IAM_API = certblockAPI.getIAM_API(user.id_token);
        let userCurrent = await IAM_API.userCurrent();
        userCurrent.data.testers.splice(e, 1);

        await IAM_API.userUpdate(userCurrent.data.id, userCurrent.data);
        await this.getTestersAndOverviewTests();

        this.setState({
            showLoadingModal: false
        });
    };

    handleEditTester = (e) => {
        this.setState({
            testerName: this.state.testers[e].name,
            preventDeleteTesters: true,
            btnDeleteTestersDisable: true,
            editTesterMode: true,
            editPosition: e,
            editTesterCardColor: '#f3f3f4'
        });
    };

    handleCancelEditTester = () => {
        this.setState({
            testerName: '',
            preventDeleteTesters: false,
            btnDeleteTestersDisable: false,
            editTesterMode: false,
            editPosition: '',
            editTesterCardColor: '#ffffff'
        });
    };

    saveEditTester = () => {
        const newState = { ...this.state };
        newState.testers[this.state.editPosition].name = this.state.testerName;
        this.setState({
            testers: newState.testers,
        }, this.handleCancelEditTester());
        localStorage.setItem('testerList', JSON.stringify(newState.testers))
    };

    handleAddMaterialTest = async () => {
        if (this.state.testDescription.length >= 2) {
            this.setState({
                showLoadingModal: true
            });

            const user = this.getUser();

            const certblockAPI = new ApiService();
            const IAM_API = certblockAPI.getIAM_API(user.id_token);
            let userCurrent = await IAM_API.userCurrent();
            userCurrent.data.materialTests.push(this.state.testDescription);
    
            await IAM_API.userUpdate(userCurrent.data.id, userCurrent.data);
            await this.getTestersAndOverviewTests();
    
            this.setState({
                showLoadingModal: false
            });
        }

    };

    handleDeleteMaterialTest = async (e) => {
        this.setState({
            showLoadingModal: true
        });

        const user = this.getUser();

        const certblockAPI = new ApiService();
        const IAM_API = certblockAPI.getIAM_API(user.id_token);
        let userCurrent = await IAM_API.userCurrent();
        userCurrent.data.materialTests.splice(e, 1);

        await IAM_API.userUpdate(userCurrent.data.id, userCurrent.data);
        await this.getTestersAndOverviewTests();

        this.setState({
            showLoadingModal: false
        });
    };

    handleChange = (e) => {

        let name;
        let value;

        if (e != null) {

            name = e._isAMomentObject ? name = "inspectionDate" : e.target.name;
            value = e._isAMomentObject ? value = e : (e.target.type === "checkbox" ? value = e.target.checked : e.target.value);

            switch (name) {
                case "companyName": {
                    this.setState({ companyName: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "companyZipCode": {
                    this.setState({ companyZipCode: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "companyStreet": {
                    this.setState({ companyStreet: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "companyCity": {
                    this.setState({ companyCity: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "companyCountry": {
                    this.setState({ companyCountry: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "testerName": {
                    this.setState({ testerName: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "testDescription": {
                    this.setState({ testDescription: value }, () => { this.validateField(name, value) });
                    break;
                }
                default: {
                    break;
                }

            }
        }
    };



    validateField(fieldName, value) {

        let fieldValidationErrors = this.state.formErrors;
        let companyNameValid = this.state.companyNameValid;
        let companyZipCodeValid = this.state.companyZipCodeValid;
        let companyStreetValid = this.state.companyStreetValid;
        let companyCityValid = this.state.companyCityValid;
        let companyCountryValid = this.state.companyCountryValid;
        let testerNameValid = this.state.testerNameValid;
        let materialTestDescriptionValid = this.state.materialTestDescriptionValid;

        switch (fieldName) {
            case "companyName": {
                companyNameValid = value.length >= 5;
                fieldValidationErrors.companyName = companyNameValid ? '' : ` has to be 5 characters or more. Total of characters: ${value.length}`;
                break;
            }
            case "companyZipCode": {
                companyZipCodeValid = value.length >= 2;
                fieldValidationErrors.companyZipCode = companyZipCodeValid ? '' : ` has to be 2 characters or more. Total of characters: ${value.length}`;
                break;
            }
            case "companyStreet": {
                companyStreetValid = value.length >= 5;
                fieldValidationErrors.companyStreet = companyStreetValid ? '' : ` has to be 5 characters or more. Total of characters: ${value.length}`;
                break;
            }
            case "companyCity": {
                companyCityValid = value.length >= 2;
                fieldValidationErrors.companyCity = companyCityValid ? '' : ` has to be 2 characters or more. Total of characters: ${value.length}`;
                break;
            }
            case "companyCountry": {
                companyCountryValid = value.length >= 5;
                fieldValidationErrors.companyCountry = companyCountryValid ? '' : ` has to be 5 characters or more. Total of characters: ${value.length}`;
                break;
            }
            case "testerName": {
                testerNameValid = value.length >= 2;
                fieldValidationErrors.testerName = testerNameValid ? '' : ` has to be 2 characters or more. Total of characters: ${value.length}`;
                break;
            }
            case "testDescription": {
                materialTestDescriptionValid = value.length >= 2;
                fieldValidationErrors.materialTestDescription = materialTestDescriptionValid ? '' : ` has to be 2 characters or more. Total of characters: ${value.length}`;
                break;
            }
            default: {
                break;
            }
        }

        this.setState({
            formErrors: fieldValidationErrors,
            companyNameValid: companyNameValid,
            companyZipCodeValid: companyZipCodeValid,
            companyStreetValid: companyStreetValid,
            companyCityValid: companyCityValid,
            companyCountryValid: companyCountryValid,
            testerNameValid: testerNameValid,
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid: this.state.companyNameValid
                && this.state.companyZipCodeValid
                && this.state.companyStreetValid
                && this.state.companyCityValid
                && this.state.companyCountryValid
        });
    }

    errorClass = (error) => {
        return (error.length === 0 ? '' : 'is-invalid');
    };

    saveProfile = async (e) => {

        const user = this.getUser();

        const company = {
            name: this.state.companyName,
            street: this.state.companyStreet,
            zip: this.state.companyZipCode,
            city: this.state.companyCity,
            country: this.state.companyCountry,
        };

        if (this.state.formValid) {
            this.setState({
                showLoadingModal: true
            });

            const certblockAPI = new ApiService();
            const IAM_API = certblockAPI.getIAM_API(user.id_token);
            const userCurrent = await IAM_API.userCurrent();

            const certblockUser = await IAM_API.userUpdate(userCurrent.data.id, 
                { 
                    ...userCurrent.data, 
                    name: company.name,
                    street: company.street,                
                    zip: company.zip,
                    city: company.city,
                    country: company.country
                }
            );

            let userUpdate = {
                ...user,
                created: true,  
                approved: certblockUser.data.approved, 
                role: certblockUser.data.role, 
                name: certblockUser.data.name, 
                zip: certblockUser.data.zip, 
                street: certblockUser.data.street, 
                country: certblockUser.data.country,
                city: certblockUser.data.city
            };
            localStorage.setItem('oidc_user',JSON.stringify(userUpdate));
            
            this.setState({
                showLoadingModal: false
            });
        }
    };

    editProfileMode = () => {
        this.setState({
            readyOnly: false
        })
    };

    cancelEditProfile = () => {
        const companyData = localStorage.getItem('oidc_user') ? JSON.parse(localStorage.getItem('oidc_user')) : { name: "", street: "", zip: "", city: "", country: "" };
        this.setState({
            readyOnly: true,
            companyName: companyData.name,
            companyStreet: companyData.street,
            companyZipCode: companyData.zip,
            companyCity: companyData.city,
            companyCountry: companyData.country,
            formErrors: {
                companyName: '',
                companyZipCode: '',
                companyStreet: '',
                companyCity: '',
                companyCountry: '',
                testerName: ''
            }
        })
    };

    render() {
        return (
            <div>
                <NavBar />
                {this.state.isSupplier &&
                    <div className="container col-xl-6 col-lg-9 col-md-12 col-sm-12" >
                        <form>
                            <div className="row">
                                <div className="col-3">
                                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <a className="nav-link active" id="v-pills-profile-tab" data-toggle="pill" onClick={this.toogleTab} href="#v-pills-profile" role="tab" aria-controls="v-pills-profile"><FormattedMessage id="profile.btnTabProfile" /></a>
                                        <a className="nav-link" id="v-pills-testers-tab" data-toggle="pill" onClick={this.toogleTab} href="#v-pills-testers" role="tab" aria-controls="v-pills-testers"><FormattedMessage id="profile.btnTabTesters" /></a>
                                        <a className="nav-link" id="v-pills-tests-tab" data-toggle="pill" onClick={this.toogleTab} href="#v-pills-tests" role="tab" aria-controls="v-pills-tests"><FormattedMessage id="profile.btnTabMaterialTests" /></a>                                   
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="tab-content" id="v-pills-tabContent">
                                        <div className="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                            <div className="card" style={{ padding: 20 }}>
                                                <h1 hidden={!this.state.readyOnly} ><FormattedMessage id="profile.formTitle" /></h1>
                                                <h1 hidden={this.state.readyOnly} ><FormattedMessage id="profile.formEditTitle" hidden={this.state.readyOnly} /></h1>
                                                <div className="card" style={{ padding: 20, marginTop: 8 }}>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12 col-sm-12">
                                                            <label htmlFor="companyNameInput"><FormattedMessage id="profile.formName" />*</label>
                                                            <input name="companyName" type="text" value={this.state.companyName} onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.companyName)}`} id="companyNameInput" placeholder={globalMessages[localStorage.getItem('language')].profile.formNamePlaceHolder} readOnly={this.state.readyOnly} />
                                                            <div className="invalid-feedback">Name {this.state.formErrors.companyName}</div>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-9 col-sm-12">
                                                            <label htmlFor="companyStreetInput"><FormattedMessage id="profile.formStreet" />*</label>
                                                            <input name="companyStreet" type="text" value={this.state.companyStreet} onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.companyStreet)}`} id="companyStreetInput" placeholder={globalMessages[localStorage.getItem('language')].profile.formStreetPlaceHolder} readOnly={this.state.readyOnly} />
                                                            <div className="invalid-feedback">Street {this.state.formErrors.companyStreet}</div>
                                                        </div>
                                                        <div className="form-group col-md-3 col-sm-12">
                                                            <label htmlFor="companyZipCodeInput"><FormattedMessage id="profile.formZipCode" />*</label>
                                                            <input name="companyZipCode" type="text" value={this.state.companyZipCode} onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.companyZipCode)}`} id="companyZipCodeInput" placeholder={globalMessages[localStorage.getItem('language')].profile.formZipCodePlaceHolder} readOnly={this.state.readyOnly} />
                                                            <div className="invalid-feedback">Zip Code{this.state.formErrors.companyZipCode}</div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="companyCityInput"><FormattedMessage id="profile.formCity" />*</label>
                                                        <input name="companyCity" type="text" value={this.state.companyCity} onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.companyCity)}`} id="companyCityInput" placeholder={globalMessages[localStorage.getItem('language')].profile.formCityPlaceHolder} readOnly={this.state.readyOnly} />
                                                        <div className="invalid-feedback">City {this.state.formErrors.companyCity}</div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="companyCountryInput"><FormattedMessage id="profile.formCountry" />*</label>
                                                        <input name="companyCountry" type="text" value={this.state.companyCountry} onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.companyCountry)}`} id="companyCountryInput" placeholder={globalMessages[localStorage.getItem('language')].profile.formCountryPlaceHolder} readOnly={this.state.readyOnly} />
                                                        <div className="invalid-feedback">Country </div>
                                                    </div>
                                                </div>

                                                <div className="card" style={{ padding: 20, marginTop: 30 }}>
                                                    <div className="d-flex justify-content-end">
                                                        <button type="button" onClick={this.editProfileMode} className="btn btn-primary col-4" hidden={!this.state.readyOnly}><FormattedMessage id="profile.formEditSupplier" /></button>
                                                        <button type="button" onClick={this.cancelEditProfile} className="btn btn-primary col-4" hidden={this.state.readyOnly}><FormattedMessage id="profile.btnCancel" /></button>
                                                        &nbsp;&nbsp;
                                                    <button type="button" onClick={this.saveProfile} className="btn btn-warning col-4" hidden={this.state.readyOnly} disabled={!this.state.formValid}><FormattedMessage id="profile.btnSaveSupplier" /></button>
                                                    </div>
                                                </div>
                                                <LoadingComponent hidden={this.state.showLoadingModal} />
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-testers" role="tabpanel" aria-labelledby="v-pills-testers-tab">
                                            <div className="card" style={{ padding: 20 }}>
                                                <h1><FormattedMessage id="testers.formTitle" /></h1>
                                                <div className="card" style={{ padding: 20, marginTop: 30, backgroundColor: this.state.editTesterCardColor }}>
                                                    <div className="form-row d-flex justify-content-between">
                                                        <div className="form-group col-md-7 col-sm-12">
                                                            <label htmlFor="testerInput">{this.state.editTesterMode ? `Edit Tester #` + (this.state.editPosition + 1) : globalMessages[localStorage.getItem('language')].profile.fromDescription}</label>
                                                            <input name="testerName" value={this.state.testerName} type="text" onChange={this.handleChange} onKeyUp={this.handleKeyUp} className={`form-control ${this.errorClass(this.state.formErrors.testerName)}`} id="testerInput" placeholder={globalMessages[localStorage.getItem('language')].testers.addTesterPlaceHolder} />
                                                            <div className="invalid-feedback"><FormattedMessage id="testers.formTitle" /> {this.state.formErrors.testerName}</div>
                                                        </div>
                                                        <div className="form-group col-md-4 col-sm-12" style={{ textAlign: 'center' }} hidden={this.state.editTesterMode}>
                                                            <button type="button" onClick={this.handleAddTester} className="btn btn-primary col-10" style={{ whiteSpace: 'normal', marginTop: 26 }}>{globalMessages[localStorage.getItem('language')].profile.btnAddTester}</button>
                                                        </div>
                                                        <div className="form-group col-md-3 col-sm-12" style={{ textAlign: 'center' }} hidden={!this.state.editTesterMode}>
                                                            <button type="button" onClick={this.handleCancelEditTester} className="btn btn-primary col-10" style={{ whiteSpace: 'normal', marginTop: 26 }}>Cancel</button>
                                                        </div>
                                                        <div className="form-group col-md-2 col-sm-12" style={{ textAlign: 'center' }} hidden={!this.state.editTesterMode}>
                                                            <button type="button" onClick={this.saveEditTester} className="btn btn-warning col-10" style={{ whiteSpace: 'normal', marginTop: 26 }}>Edit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col"><FormattedMessage id="testers.name" /></th>
                                                                <th scope="col" />
                                                                <th scope="col" />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.testers.map((tester, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>{tester}</td>
                                                                        <td>&nbsp;</td>
                                                                        <td>
                                                                            <button type="button" value={index} onClick={this.state.preventDeleteTesters ? null : () => this.handleDeleteTester(index)} id={'deleteIconBtn-' + index} className="btn btn-default btnDeleteMaterial" disabled={this.state.btnDeleteTestersDisable} style={{ padding: 0 }}>
                                                                                <MdDelete size={25} />
                                                                            </button>
                                                                        </td>
                                                                        {/* Edit Button */}
                                                                        {/* <td>
                                                                        <button type="button" value={index} onClick={() => this.handleEditTester(index)} id={'editIconBtn' + index} className="btn btn-default" style={{ padding: 0 }}>
                                                                            <MdEdit size={25} />
                                                                        </button>
                                                                    </td> */}
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    { /* Begin new card*/}
                                    
                                    <div className="tab-pane fade" id="v-pills-tests" role="tabpanel" aria-labelledby="v-pills-tests-tab">
                                            <div className="card" style={{ padding: 20, marginBottom: 250 }}>
                                                <h1><FormattedMessage id="materialTests.formTitle" /></h1>
                                                <div className="card" style={{ padding: 20, marginTop: 30, backgroundColor: this.state.editTesterCardColor }}>
                                                    <div className="form-row d-flex justify-content-between">
                                                        <div className="form-group col-md-7 col-sm-12">
                                                            <label htmlFor="testInput">{globalMessages[localStorage.getItem('language')].profile.formAddMaterialDescription}</label>
                                                            <input name="testDescription" value={this.state.testDescription} type="text" onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.materialTestDescription)}`} id="testInput" placeholder={globalMessages[localStorage.getItem('language')].materialTests.addTestPlaceHolder} />
                                                            <div className="invalid-feedback"><FormattedMessage id="materialTests.formTitle" /> {this.state.formErrors.materialTestDescription}</div>
                                                        </div>
                                                        
                                                        <div className="form-group col-md-4 col-sm-12" style={{ textAlign: 'center' }} hidden={this.state.editTesterMode}>
                                                            <button type="button" onClick={this.handleAddMaterialTest} className="btn btn-primary col-10" style={{ whiteSpace: 'normal', marginTop: 26 }}>{globalMessages[localStorage.getItem('language')].profile.btnAddMaterialTest}</button>
                                                        </div>
                                                        { /* Not active buttons
                                                        <div className="form-group col-md-3 col-sm-12" style={{ textAlign: 'center' }} hidden={!this.state.editTesterMode}>
                                                            <button type="button" onClick={this.handleCancelEditTester} className="btn btn-primary col-10" style={{ whiteSpace: 'normal', marginTop: 26 }}>Cancel</button>
                                                        </div>
                                                        <div className="form-group col-md-2 col-sm-12" style={{ textAlign: 'center' }} hidden={!this.state.editTesterMode}>
                                                            <button type="button" onClick={this.saveEditTester} className="btn btn-warning col-10" style={{ whiteSpace: 'normal', marginTop: 26 }}>Edit</button>
                                                        </div>
                                                        */ }
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col"><FormattedMessage id="materialTests.name" /></th>
                                                                <th scope="col" />
                                                                <th scope="col" />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.tests.map((test, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>{test}</td>
                                                                        <td>&nbsp;</td>
                                                                        <td>
                                                                            <button type="button" value={index} onClick={() => this.handleDeleteMaterialTest(index)} id={'deleteIconBtn-' + index} className="btn btn-default btnDeleteMaterial" style={{ padding: 0 }}>
                                                                                <MdDelete size={25} />
                                                                            </button>
                                                                        </td>
                                                                        {/* Edit Button */}
                                                                        {/* <td>
                                                                        <button type="button" value={index} onClick={() => this.handleEditTester(index)} id={'editIconBtn' + index} className="btn btn-default" style={{ padding: 0 }}>
                                                                            <MdEdit size={25} />
                                                                        </button>
                                                                    </td> */}
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    { /* End new card */ }


                                </div>
                            </div>
                            <LoadingComponent hidden={this.state.showLoadingModal} />
                        </form>
                    </div >
                }
                <Footer />
            </div >
        );
    }
}

export default ProfileContainer
