import React, { Fragment } from 'react';
import DatePicker from 'react-datepicker';
import MdDelete from 'react-icons/lib/md/delete-forever';
import MdEdit from 'react-icons/lib/md/edit';

import NavBar from '../components/NavBar';

import { Modal, Button, ModalHeader } from 'react-bootstrap';
import {AsyncTypeahead, Typeahead} from 'react-bootstrap-typeahead';

import { goToAnchor } from 'react-scrollable-anchor';
import ScrollableAnchor from 'react-scrollable-anchor';

import Messages from '../messages';

import 'react-datepicker/dist/react-datepicker.css';
import '../resources/css/cert-block.css';

import { FormattedMessage } from 'react-intl';
import LoadingComponent from '../components/LoadingComponent';
import Footer from '../components/Footer';
import { GetMaterials } from '../models/Products';
import axios from 'axios';
import OidcProvider from '../services/OidcProvider';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import consignee from '../data/consignee.json';
import { ApiService } from '../services/ApiService';

const globalMessages = Messages;

export class CreateCertificateContainer extends React.Component {

    constructor(props) {
        super(props);
        this.apiConfig = window.ApiConfig;
        this.deliveryNumberInput = React.createRef();

        this.state = {
            isSupplier: true,
            supplier: '',
            supplierNumber: '',
            orderNumber: '',
            deliveryNumber: '',
            orderPosition: '',
            materialNumber: '',
            materialDescription: '',
            quantity: '',
            serialNumber: '',
            overviewTests: '',
            nameInspector: '',
            inspectionDate: new Date(),
            confirmationText: '',
            consigneePosition: null,
            confirmation: false,
            checkTestResults: false,
            successfulCreated: false,
            isFromOrderNumber: true,
            serialNumbers: [],
            materials: [],
            tests: [],
            orderNumbersForSupplier: [],
            materialsFromOrderNum: [],
            materialsFromOrderNumToAdd: [],
            consigneeList: [],
            testerList: [],
            editPosition: '',
            editSNPosition: '',
            testerPosition: '',
            supplierData: {
                name: '',
                street: '',
                zip: '',
                city: '',
                country: ''
            },
            consigneeData: {
                name: '',
                street: '',
                zip: '',
                city: '',
                country: ''
            },
            messageCreateCertificate: '',
            disableSubmitBtn: false,
            supplierValid: true,
            supplierNumberValid: false,
            orderNumberValid: false,
            deliveryNumberValid: false,
            orderPositionValid: false,
            materialNumberValid: false,
            materialDescriptionValid: false,
            quantityValid: false,
            serialNumberValid: true,
            overviewTestsValid: true,
            nameInspectorValid: false,
            inspectionDateValid: true,
            confirmationTextValid: true,
            confirmationValid: false,
            checkTestResultsValid: false,
            materialsValid: true,
            multipleEntries: false,
            preventDeleteSerials: false,
            preventDeleteMaterials: false,
            customMode: false,
            showModal: false,
            showConfirmationModal: false,
            showConsigneeListModal: false,
            showLoadingModal: false,
            formValid: false,
            formMaterialValid: false,
            addMaterialBtnHidden: false,
            materialCardHidden: true,
            editMaterialBtnHidden: true,
            cancelEditMaterialBtnHidden: true,
            addMaterialTitleHidden: true,
            materialFromOrderNumHidden: true,
            editMaterialTitleHidden: true,
            flagMultipleEntriesIdHidden: false,
            labelFlagMultiplesEntriesIdHidden: false,
            btnCancelEdtSerialNumberHidden: true,
            btnEditSerialNumberHidden: true,
            btnAddSerialNumberHidden: false,
            editSNTextHidden: true,
            materialCardBackgroundColor: '#ffffff',
            btnDeleteMaterialDisabled: false,
            btnDeleteSNDisabled: false,
            btnAddFromOrderNumberDisabled: true,
            inspectionDateInvalidFeedbackDisplay: 'none',
            orderPositionAlertHidden: true,
            showTesterListModal: false,
            showAPIErrorModal: false,
            apiErrorMessage: '',
            formErrors: {
                supplier: '',
                consignee: '',
                orderNumber: '',
                deliveryNumber: '',
                orderPosition: '',
                materialNumber: '',
                materialDescription: '',
                quantity: '',
                serialNumber: '',
                overviewTests: '',
                nameInspector: '',
                inspectionDate: '',
                checkTestResults: '',
                confirmationText: '',
                confirmation: ''
            }
        };
    }

    componentDidMount() {

        this.getConsigneeList().then(
            (data) => {
                return data;
            }
        ).then((consigneeList) => {
            let consigneeListJson = [];
            consigneeList.forEach(consignee => {
                consigneeListJson.push(consignee);
            });

            if (!this.state.consigneePosition) {
                this.setState({consigneeList: consigneeListJson});
            } else {
                this.setState({
                    consigneeList: consigneeListJson,
                    consigneeData: consigneeListJson[this.state.consigneePosition]
                })
            }
        });

        this.getTestersOverviewTestsAndSupplierNumber();

        this.getRole();

        if (sessionStorage.getItem('companyData')) {

            let supplier = JSON.parse(sessionStorage.getItem('companyData'));

            if (this.state.supplierData.name !== '') {
                this.setState({supplierData: supplier});
            } else this.loadCompanyData()

        } else {
            this.loadCompanyData()
        }

    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.supplierNumber !== '' && this.state.supplierNumber !== prevState.supplierNumber) {
            this.getOrderNumbers(this.state.supplierNumber);
        }

    }

    getUser = () => {
        return JSON.parse(localStorage.getItem('oidc_user'));
    };

    loadCompanyData() {

        const oidc = new OidcProvider();
        const user = oidc.getUser();

        let companyData = {
            name: user.name,
            street: user.street,
            zip: user.zip,
            city: user.city,
            country: user.country
        };

        this.setState({
            supplierData: companyData
        });

    }

    async getApiData(endpoint, parameter, subParameter) {
        const oidc = new OidcProvider();
        const user = oidc.getUser();
        let url = ''
        if (endpoint === 'suppliernumber' && parameter === 'email') {
            url = `${this.apiConfig.apiUrl}/suppliers?ident=${user.profile.email}`
        } else if (endpoint === 'orders') {
            url = `${this.apiConfig.apiUrl}/supplier/${parameter}/orders`
        } else if (endpoint === 'orderPrefix') {
            url = `${this.apiConfig.apiUrl}/supplier/${parameter}/orders/${subParameter}`
        } else if (endpoint === 'materials') {
            url = `${this.apiConfig.apiUrl}/orders/${parameter}/material`
        }

        if (user === null || !user.profile.email) {
            console.warn("User not authenticated. Unable to get API data");
            return null;
        } else {
            axios.defaults.headers.common['Authorization'] = user.id_token;
            var request = axios({
                method: 'get',
                url: url !== '' ? url : `${this.apiConfig.apiUrl}/${endpoint}/${parameter}`
            }).catch(error => {
                this.setState({
                    showAPIErrorModal: true, apiErrorMessage: globalMessages[localStorage.getItem('language')].createCertificate.uiErrorMessageFailAPICall
                });
            });
            return request;
        }
    }

    getOrderNumbersPrefix = async (orderNumberPrefix) => {
        try {
            let supplierNumber = this.state.supplierNumber;
            if(supplierNumber) {
                return (await this.getApiData('orderPrefix', supplierNumber, orderNumberPrefix)).data;
            }
        } catch (e) {
            console.log(e);
        }
        return [];
    }

    getOrderNumbers = async (supplierNumber) => {
        try {
            let ordersList = (await this.getApiData('orders', supplierNumber)).data;
            if(!ordersList) {
                this.setState({
                    showAPIErrorModal: true,
                    apiErrorMessage: globalMessages[localStorage.getItem('language')].createCertificate.uiErrorMessageFailAPICall
                });
                return;
            }

            if (ordersList.length > 0) {
                this.setState({ orderNumbersForSupplier: ordersList });
                console.log('order(s) found ... create your certificate')
            } else {
                this.setState({ showAPIErrorModal: true, apiErrorMessage: globalMessages[localStorage.getItem('language')].createCertificate.uiErrorMessageFailAPICall });
            }
        } catch (e) {
            this.setState({
                showAPIErrorModal: true,
                apiErrorMessage: globalMessages[localStorage.getItem('language')].createCertificate.uiErrorMessageFailAPICall
            });
            return;
        }
    }

    getRole = () => {
        const oidc = new OidcProvider();
        const user = oidc.getUser();

        if (user.role === 'userSupplier') {
            this.setState({ isSupplier: true });
        }

    }


    handleChangeOrderNumber = (value) => {
        this.setState({ orderNumber: value }, () => { this.validateField('orderNumber', value.toString()) });
        this.deliveryNumberInput.current.focus();
    }

    handleChangeOverviewtests = (value) => {
        this.state.overviewTests = value;
        this.setState({ overviewTests: this.state.overviewTests });
    }

    handleChange = (e) => {

        let name;
        let value;

        if (e != null) {

            let isInspectionDate = (e instanceof Date) ? true : false;

            name = isInspectionDate ? name = "inspectionDate" : e.target.name;
            value = isInspectionDate ? value = e : (e.target.type === "checkbox" ? value = e.target.checked : e.target.value);

            switch (name) {
                case "supplier": {
                    this.setState({ supplier: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "orderNumber": {
                    this.setState({ orderNumber: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "deliveryNumber": {
                    this.setState({ deliveryNumber: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "orderPosition": {
                    this.setState({ orderPosition: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "materialNumber": {
                    this.setState({ materialNumber: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "materialDescription": {
                    this.setState({ materialDescription: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "quantity": {
                    this.setState({ quantity: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "serialNumber": {
                    this.setState({ serialNumber: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "overviewTests": {
                    this.setState({ overviewTests: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "nameInspector": {
                    this.setState({ nameInspector: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "inspectionDate": {
                    this.setState({ inspectionDate: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "confirmationText": {
                    this.setState({ confirmationText: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "confirmation": {
                    this.setState({ confirmation: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "checkTestResults": {
                    this.setState({ checkTestResults: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "flagMultipleEntries": {
                    this.setState({ multipleEntries: value });
                    break;
                }
                default: {
                    break;
                }

            }
        }
    };

    setCustomMode = (value) => {

        this.setState({
            customMode: value,
            addMaterialTitleHidden: !value,
            materialCardHidden: !value,
            showAPIErrorModal: false
        });
    }

    handleChangeMode = () => {
        if (this.state.customMode) {
            this.setCustomMode(false);
        } else {
            this.setCustomMode(true);
        }

    }

    handleMaterialChange = (value) => {
        const materials = GetMaterials();
        const materialDesc = (value in materials) ? materials[value].de + ' ( ' + materials[value].en + ' ) ' : 'Description not found';
        this.setState({ materialDescription: materialDesc });
    }

    handleAddMaterialFromOrderNum = (e) => {

        let value;
        let isInspectionDate = (e instanceof Date) ? true : false;

        value = isInspectionDate ? value = e : (e.target.type === "checkbox" ? value = e.target.checked : e.target.value);
        if (value) {
            this.state.materialsFromOrderNumToAdd.push(this.state.materialsFromOrderNum[e.target.id]);
        } else {
            this.state.materialsFromOrderNumToAdd.forEach((material, index) => {
                if (material === this.state.materialsFromOrderNum[e.target.id]) {
                    this.state.materialsFromOrderNumToAdd.splice(index, 1);
                }
            });
        }

        this.state.btnAddFromOrderNumberDisabled = this.state.materialsFromOrderNumToAdd.length > 0 ? false : true;

        this.setState({
            materialsFromOrderNumToAdd: this.state.materialsFromOrderNumToAdd,
            btnAddFromOrderNumberDisabled: this.state.btnAddFromOrderNumberDisabled
        });

    }

    getMaterials = async (value, callback) => {

        try {
            let response = (await this.getApiData('materials', value)).data;
            callback(response.map(p => {
                return {
                    orderPosition: p.EBELP.replace(/^0+(?!\.|$)/, ''),
                    orderNumber: p.EBELN,
                    materialNumber: p.EMATN,
                    materialDescription: p.TXZ01,
                    quantity: p.BSTMG
                };
            }))
        }catch (e) {
            callback([]);
        }

        //
        // var response = await this.getApiData('materials', value);
        //
        // const materials_data = (({ data }) => ({ data }))(response);
        // const functionPromise = item => {
        //
        //     const _orderPosition = item.EBELP.replace(/^0+(?!\.|$)/, '');
        //     const _orderNumber = item.EBELN;
        //     const _materialNumber = item.EMATN;
        //     const _quantity = item.BSTMG;
        //     const _materialDescription = item.TXZ01;
        //     return Promise.resolve(
        //         { orderNumber: _orderNumber, orderPosition: _orderPosition, materialNumber: _materialNumber, materialDescription: _materialDescription, quantity: _quantity }
        //     )
        //
        // }
        // const anAsync = async item => {
        //     return functionPromise(item);
        // }
        // const getMaterialsList = async () => {
        //     return Promise.all(materials_data.data.d.results.map(item => anAsync(item)))
        // }
        // getMaterialsList().then(data => {
        //     callback(data);
        // })

    }

    handleOrderNumber = (value) => {

        this.setState({
            showLoadingModal: true,
            showModal: false
        });

        this.setState({ materialsFromOrderNum: [] });
        this.getMaterials(value, (materialsData) => {
            if (materialsData === null) {
                this.setState({
                    showModal: false,
                    showLoadingModal: false,
                    showConfirmationModal: true,
                    messageCreateCertificate: globalMessages[localStorage.getItem('language')].createCertificate.uiErrorMessageFailAPICall
                });
                return false;
            }

            if (materialsData.length === 0) {
                this.setState({
                    showModal: false,
                    showConfirmationModal: true,
                    messageCreateCertificate: globalMessages[localStorage.getItem('language')].createCertificate.selectOrderNumberMessage
                });
            }

            for (var i = 0; i < materialsData.length; i++) {
                var materialNumberValid = false;
                materialNumberValid = (materialsData[i].materialNumber.toUpperCase().startsWith("A2V") || materialsData[i].materialNumber.toUpperCase().startsWith("C1B")) && (materialsData[i].materialNumber.length === 14);
                if (materialNumberValid) {
                    this.state.materialsFromOrderNum.push(materialsData[i]);
                }
            }
            this.setState({ materialsFromOrderNum: this.state.materialsFromOrderNum });

            setTimeout(() => {
                this.setState({
                    showLoadingModal: false,
                    showModal: false
                });
                this.forceUpdate();
            }, 300);
        });
    }

    validateField = (fieldName, value) => {

        let fieldValidationErrors = this.state.formErrors;
        let supplierValid = this.state.supplierValid;
        let orderNumberValid = this.state.orderNumberValid;
        let deliveryNumberValid = this.state.deliveryNumberValid;
        let orderPositionValid = this.state.orderPositionValid;
        let materialNumberValid = this.state.materialNumberValid;
        let materialDescriptionValid = this.state.materialDescriptionValid;
        let quantityValid = this.state.quantityValid;
        let serialNumberValid = this.state.serialNumberValid;
        let overviewTestsValid = this.state.overviewTestsValid;
        let nameInspectorValid = this.state.nameInspectorValid;
        let inspectionDateValid = this.state.inspectionDateValid;
        let confirmationTextValid = this.state.confirmationTextValid;
        let confirmationValid = this.state.confirmationValid;
        let checkTestResultsValid = this.state.checkTestResultsValid;

        switch (fieldName) {
            case "supplier": {
                supplierValid = value.length >= 5;
                fieldValidationErrors.supplier = supplierValid ? '' : ` has to be 10 characters. Total of characters: ${value.length}`;
                break;
            }
            case "orderNumber": {
                orderNumberValid = value.length === 10;
                fieldValidationErrors.orderNumber = orderNumberValid ? '' : ` has to be 10 characters. Total of characters: ${value.length}`;

                if (orderNumberValid) {
                    fieldValidationErrors.orderNumber = orderNumberValid ? '' : `The order number must have 10 chars at least.`;
                    if (!this.state.customMode) { this.handleOrderNumber(value); }
                    this.setState({ materialFromOrderNumHidden: false, materials: [] })
                } else {
                    this.setState({ materialFromOrderNumHidden: true, materialsFromOrderNum: [], materialsFromOrderNumToAdd: [] })
                }

                break;
            }
            case "deliveryNumber": {
                deliveryNumberValid = (value.length >= 1);
                fieldValidationErrors.deliveryNumber = deliveryNumberValid ? '' : ' is required.';
                break;
            }
            case "orderPosition": {
                orderPositionValid = value > 0;
                fieldValidationErrors.orderPosition = orderPositionValid ? '' : ' is required and has to be higher or equal 0.';
                break;
            }
            case "materialNumber": {
                materialNumberValid = value.length >= 10;
                fieldValidationErrors.materialNumber = materialNumberValid ? '' : `is required and has to be 10 characters at least. Total of characters: ${value.length}`;

                if (materialNumberValid) {
                    //materialNumberValid = value.toUpperCase().startsWith("A2V") || value.toUpperCase().startsWith("C1B");
                    //fieldValidationErrors.materialNumber = materialNumberValid ? '' : `The material number has to start with A2V or C1B.`;
                    //if (materialNumberValid) {
                    //this.handleMaterialChange(value);
                    materialDescriptionValid = value.length > 0 && value.length <= 160;
                    fieldValidationErrors.materialDescription = materialDescriptionValid ? '' : `is required and has to be less or equal of 160 characters. Total of characters: ${value.length}`;
                    //}
                }

                break;
            }
            case "materialDescription": {
                materialDescriptionValid = value.length > 0 && value.length <= 160;
                fieldValidationErrors.materialDescription = materialDescriptionValid ? '' : `is required and has to be less or equal of 160 characters. Total of characters: ${value.length}`;
                break;
            }
            case "quantity": {
                quantityValid = value > 0;
                fieldValidationErrors.quantity = quantityValid ? '' : ` is required and has to be higher than 0.`;
                break;
            }
            case "serialNumber": {
                serialNumberValid = value.length >= 0;
                fieldValidationErrors.serialNumber = serialNumberValid ? '' : ' This field is not required.';
                break;
            }
            case "overviewTests": {
                overviewTestsValid = value.length >= 0;
                fieldValidationErrors.overviewTests = overviewTestsValid ? '' : 'is too short.';
                break;
            }
            case "nameInspector": {
                nameInspectorValid = value.length > 0;
                fieldValidationErrors.nameInspector = nameInspectorValid ? '' : ' is required.';
                break;
            }
            case "inspectionDate": {
                inspectionDateValid = value.getTime() < Date.now();
                fieldValidationErrors.inspectionDate = inspectionDateValid ? '' : ' can\'t be higher than today.';
                inspectionDateValid ? this.setState({ inspectionDateInvalidFeedbackDisplay: 'none' }) : this.setState({ inspectionDateInvalidFeedbackDisplay: 'block' });
                break;
            }
            case "checkTestResults": {
                checkTestResultsValid = value;
                fieldValidationErrors.checkTestResults = checkTestResultsValid ? '' : 'is required.';
                break;
            }
            case "confirmationText": {
                confirmationTextValid = value.length > 0;
                fieldValidationErrors.confirmationText = confirmationTextValid ? '' : 'is required.';
                break;
            }
            case "confirmation": {
                confirmationValid = value;
                fieldValidationErrors.confirmation = confirmationValid ? '' : 'is required.';
                break;
            }
            default: {
                break;
            }
        }

        this.setState({
            formErrors: fieldValidationErrors,
            supplierValid: supplierValid,
            orderNumberValid: orderNumberValid,
            deliveryNumberValid: deliveryNumberValid,
            orderPositionValid: orderPositionValid,
            materialNumberValid: materialNumberValid,
            materialDescriptionValid: materialDescriptionValid,
            quantityValid: quantityValid,
            serialNumberValid: serialNumberValid,
            overviewTestsValid: overviewTestsValid,
            nameInspectorValid: nameInspectorValid,
            inspectionDateValid: inspectionDateValid,
            checkTestResultsValid: checkTestResultsValid,
            confirmationTextValid: confirmationTextValid,
            confirmationValid: confirmationValid,
        }, this.validateForm);
    };

    validateForm() {
        this.setState({
            formValid: this.state.supplierValid
                && this.state.consigneeData.name.length >= 1
                && this.state.orderNumberValid
                && this.state.deliveryNumberValid
                && this.state.nameInspectorValid
                && this.state.inspectionDateValid
                && this.state.confirmationTextValid
                && this.state.checkTestResultsValid
                && this.state.confirmationValid
                && this.state.materials.length >= 1
                && this.state.materialsValid,
            formMaterialValid: this.state.orderPositionValid
                && this.state.materialNumberValid
                && this.state.materialDescriptionValid
                && this.state.quantityValid
                && this.state.serialNumberValid
                && this.state.overviewTestsValid
        });
    }

    /* HandleMaterial CODE */

    handleMaterialsFromOrderNum = () => {

        let serials;

        this.state.materialsFromOrderNumToAdd.forEach((value, index) => {
            let material = {
                orderPosition: value.orderPosition,
                materialNumber: value.materialNumber,
                materialDescription: value.materialDescription,
                quantity: value.quantity,
                serialNumber: ''
            };

            this.state.materials.push(material);
        });
        this.setState({
            materials: this.state.materials,
            materialFromOrderNumHidden: true
        });
    }

    /* POI */
    handleMaterial = () => {

        let serials = this.state.serialNumbers.join();

        let material = {
            orderPosition: this.state.orderPosition,
            materialNumber: this.state.materialNumber,
            materialDescription: this.state.materialDescription,
            quantity: this.state.quantity,
            serialNumber: serials,
            overviewTests: this.state.overviewTests.toString()
        };

        this.isFormMaterialValid(material);
        this.setState({ materials: this.state.materials }, this.isMaterialsValid);

        this.hasMultipleEntries();

    };

    handleDeleteMaterial = (e) => {
        this.state.materials.splice(e, 1);
        this.setState({ materials: this.state.materials }, this.isMaterialsValid && this.validateForm);
    };

    handleEditMaterial = (e) => {

        this.setMaterialCardEditMode();


        let serials = this.state.materials[e].serialNumber.length > 0 ? this.state.materials[e].serialNumber.split(",") : []

        this.setState({
            orderPosition: this.state.materials[e].orderPosition,
            materialNumber: this.state.materials[e].materialNumber,
            quantity: this.state.materials[e].quantity,
            materialDescription: this.state.materials[e].materialDescription,
            serialNumbers: serials,
            overviewTests: this.state.materials[e].overviewTests,
            preventDeleteMaterials: true,
            editPosition: e,
            materialCardHidden: false
        });

        goToAnchor('section1', false);

    };

    handleSaveEditMaterial = () => {

        console.log(`DEBUG: [handleSaveEditMaterial]`)

        const newState = { ...this.state };

        newState.materials[this.state.editPosition].orderPosition = this.state.orderPosition;
        newState.materials[this.state.editPosition].materialNumber = this.state.materialNumber;
        newState.materials[this.state.editPosition].quantity = this.state.quantity;
        newState.materials[this.state.editPosition].materialDescription = this.state.materialDescription;
        newState.materials[this.state.editPosition].serialNumber = this.state.serialNumbers.join();
        newState.materials[this.state.editPosition].overviewTests = this.state.overviewTests.toString();

        this.setState({
            materials: newState.materials,
            preventDeleteMaterials: false,
            materialCardHidden: true
        }, this.isMaterialsValid);

        this.cleanMaterial();
        this.setMaterialCardDefault();

    };

    handleCancelEditMaterial = (e) => {

        this.setState({
            preventDeleteMaterials: false,
            materialCardHidden: true
        }, this.setMaterialCardDefault());

        this.cleanMaterial();

    };

    handleAddSerialNumber = (e) => {

        let serialNumbers = this.state.serialNumbers;
        serialNumbers.push(this.state.serialNumber);

        this.setState({
            serialNumbers: serialNumbers
        });

    };

    handleDeleteSerialNumber = (e) => {
        this.state.serialNumbers.splice(e, 1);
        this.setState({ serialNumbers: this.state.serialNumbers });
    };

    handleEditSerialNumber = (e) => {

        this.setState({
            serialNumber: this.state.serialNumbers[e],
            preventDeleteSerials: true,
            editSNPosition: e,
            btnCancelEdtSerialNumberHidden: false,
            btnEditSerialNumberHidden: false,
            btnAddSerialNumberHidden: true,
            editSNTextHidden: false,
            btnDeleteSNDisabled: true
        });
    };

    handleCancedlEditSerialNumber = () => {

        this.setState({
            serialNumber: '',
            editSNPosition: '',
            preventDeleteSerials: false,
            btnCancelEdtSerialNumberHidden: true,
            btnEditSerialNumberHidden: true,
            btnAddSerialNumberHidden: false,
            editSNTextHidden: true,
            btnDeleteSNDisabled: false
        });
    };

    handleSaveEditSerialNumber = () => {
        const serialNumbers = [...this.state.serialNumbers];
        serialNumbers[this.state.editSNPosition] = this.state.serialNumber;

        this.setState({
            serialNumbers: serialNumbers,
            preventDeleteSerials: false,
            serialNumber: '',
            editSNPosition: '',
            btnCancelEdtSerialNumberHidden: true,
            btnEditSerialNumberHidden: true,
            btnAddSerialNumberHidden: false,
            editSNTextHidden: true,
            btnDeleteSNDisabled: false
        });

    };

    handleConsigneeList = () => {
        this.setState({
            showConsigneeListModal: true
        });
    };

    getTestersOverviewTestsAndSupplierNumber = async () => {
        const certblockAPI = new ApiService();
        const IAM_API = certblockAPI.getIAM_API(this.getUser().id_token);

        IAM_API.userCurrent()
            .then((certblockUser) => {
                this.setState({
                    testerList: certblockUser.data.testers ? certblockUser.data.testers : [],
                    tests: certblockUser.data.materialTests ? certblockUser.data.materialTests : [],
                    supplierNumber: certblockUser.data.supplier ? certblockUser.data.supplier : ''
                });
            }).catch((error) => {
                this.setState({
                    showAPIErrorModal: true,
                    apiErrorMessage: globalMessages[localStorage.getItem('language')].createCertificate.uiErrorMessageFailAPICall
                });
            });

    };

    handleTesterList = () => {
        this.setState({
            showTesterListModal: true
        });
    };

    handleSelectTester = (e) => {
        this.setState({
            nameInspector: this.state.testerList[e],
            testerPosition: e
        }, this.validateField('nameInspector', this.state.testerList[e]));
        this.handleCancelSelectTester();
    };

    handleCancelSelectConsignee = () => {
        this.setState({
            showConsigneeListModal: false
        });
    };

    handleCancelSelectTester = () => {
        this.setState({
            showTesterListModal: false
        });
    };

    handleSelectConsignee = (e) => {
        this.setState({
            consigneeData: this.state.consigneeList[e],
            consigneePosition: e
        }
            , this.validateForm
        );
        this.handleCancelSelectConsignee();



    };

    handleConfirmation = () => {
        this.setState({ showModal: true });
    };

    handleCancelConfirmation = () => {
        this.setState({ showModal: false });
    };

    handleSuccessModal = () => {
        this.state.successfulCreated ? window.location.reload() : this.setState({ showConfirmationModal: false });
    };

    handleSubmit = (e) => {

        this.setState({
            disableSubmitBtn: true
        });

        // console.log('DEBUG: consigneIndex: ' + this.state.consigneePosition);
        // console.log('DEBUG: orderNumber: ' + this.state.orderNumber + ' Type: ' + typeof(this.state.orderNumber));
        // console.log('DEBUG: deliveryNumber: ' + this.state.deliveryNumber + ' Type: ' + typeof(this.state.deliveryNumber));
        // console.log('DEBUG: materialList: ' + JSON.stringify(this.state.materials));
        // console.log('DEBUG: inspectionDate: ' + Math.floor(this.state.inspectionDate.getTime() / 1000));
        // console.log('DEBUG: nameInspector: ' + this.state.nameInspector);
        // console.log(`DEBUG: serialNumbers ${JSON.stringify(this.state.materials)}`)

        const certProps = {
            supplierId: this.state.supplierNumber,
            consigneeIndex: this.state.consigneePosition,
            consignee: this.state.consigneeData,
            orderNumber: this.state.orderNumber.toString(),
            deliveryNumber: this.state.deliveryNumber,
            materialList: this.state.materials,
            testResultsText: 'Tests are carried out in accordance with the test plan/technical delivery conditions. The requirements are met. The test results are documented by measurement sheets, test reports, etc. and form the basis of this inspection cerfificate. The objects tested can be assigned to this inspection certificate by means of appropriate identification.',
            checkTestResults: this.state.checkTestResults,
            confirmationText: "The results of the tests performed on our delivery items confirm that the above listed parts comply with the order specifications. All the relevant data / documents will be kept in a safe place for at last 10 years or as contractly agreed. The customer may check the data / documents at any time.",
            checkConfirmation: this.state.confirmation,
            inspectionDate: Math.floor(this.state.inspectionDate.getTime() / 1000),
            nameInspector: this.state.nameInspector,
            changedCommentary: ''
        };

        if (this.state.formValid) {

            this.setState({
                showLoadingModal: true,
                showModal: false
            });

            const certblockAPI = new ApiService();
            const CRT_API = certblockAPI.getCRT_API();

            CRT_API.certificateCreate(certProps)
                .then((certificate) => {
                    this.setState({
                        showModal: false,
                        showLoadingModal: false,
                        showConfirmationModal: true,
                        messageCreateCertificate: "Your inspection certificate was successfully created.",
                        successfulCreated: true
                    });
                }).catch((error) => {
                    this.setState({
                        showModal: false,
                        showLoadingModal: false,
                        showConfirmationModal: true,
                        messageCreateCertificate: "Your inspection certificate couldn't be created. Try again a few minutes later.",
                        successfulCreated: false,
                        disableSubmitBtn: false
                    });
                    console.error(error);
                });
        }

        e.preventDefault();
    };

    cleanMaterial = () => {
        this.setState({
            orderPosition: "",
            materialNumber: "",
            quantity: "",
            materialDescription: "",
            serialNumber: "",
            serialNumbers: [],
            overviewTests: "",
            orderPositionValid: false,
            materialNumberValid: false,
            materialDescriptionValid: false,
            quantityValid: false,
        }, this.validateForm());
    };

    errorClass = (error) => {
        return (error.length === 0 ? '' : 'is-invalid');
    };

    getConsigneeList = async () => {
        return consignee;
    };

    setMaterialCardEditMode() {
        this.setState({
            addMaterialBtnHidden: true,
            editMaterialBtnHidden: false,
            cancelEditMaterialBtnHidden: false,
            addMaterialTitleHidden: true,
            editMaterialTitleHidden: false,
            flagMultipleEntriesIdHidden: true,
            labelFlagMultiplesEntriesIdHidden: true,
            materialCardBackgroundColor: '#f3f3f4',
            btnDeleteMaterialDisabled: true
        });
    }

    setMaterialCardDefault() {
        this.setState({
            addMaterialBtnHidden: false,
            editMaterialBtnHidden: true,
            cancelEditMaterialBtnHidden: true,
            addMaterialTitleHidden: this.state.isFromOrderNumber ? true : false,
            editMaterialTitleHidden: true,
            flagMultipleEntriesIdHidden: false,
            labelFlagMultiplesEntriesIdHidden: false,
            materialCardBackgroundColor: '#ffffff',
            btnDeleteMaterialDisabled: false
        });
    }

    isMaterialsValid() {

        let materialsValid = this.state.materialsValid;
        let uniqueOrderPositionValues = new Set([]);

        this.state.materials.forEach(stateMaterial => {
            uniqueOrderPositionValues.add(stateMaterial.orderPosition);
        });

        uniqueOrderPositionValues.size === this.state.materials.length ? materialsValid = true : materialsValid = false;

        materialsValid ? this.setState({ orderPositionAlertHidden: true }) : this.setState({ orderPositionAlertHidden: false });

        this.setState({
            materialsValid: materialsValid,
        }, this.validateForm);

    }

    hasMultipleEntries() {
        if (!this.state.multipleEntries) {
            this.cleanMaterial();
        }
    }

    isFormMaterialValid(material) {
        if (this.state.formMaterialValid) {
            this.state.materials.push(material);
        }
    }


    formatDate(date) {

        function padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        }

        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }

    render() {
        return (
            <div>
                <NavBar />
                {/* <FormErrors formErrors={this.state.formErrors} /> */}
                < div className="container col-xl-6 col-lg-9 col-md-12 col-sm-12" >
                    {this.state.isSupplier &&
                        <form onSubmit={this.handleSubmit}>
                            <h2>
                                <FormattedMessage id="createCertificate.formTitle" />
                            </h2>
                            <div className="card" style={{ padding: 20, marginTop: 30 }}>
                                <div className="form-row">
                                    <div className="form-group col-md-7 col-sm-12">
                                        <h4>
                                            <FormattedMessage id="createCertificate.formSupplier" />
                                        </h4>
                                        <p style={{ marginTop: 20 }}>
                                            {this.state.supplierData.name}<br />
                                            {this.state.supplierData.street}<br />
                                            {this.state.supplierData.zip}&nbsp;{this.state.supplierData.city}<br />
                                            {this.state.supplierData.country} <br />
                                        </p>
                                    </div>
                                    <div className="form-group col-md-5 col-sm-12">
                                        <div className="form-row">
                                            <h4>
                                                <FormattedMessage id="createCertificate.formConsignee" />
                                            </h4>
                                            &nbsp;&nbsp;&nbsp;
                                            <button className="btn btn-outline-secondary col-3" type="button" id="btnAddConsignee" onClick={this.handleConsigneeList}><FormattedMessage id="commonButtons.select" /></button>
                                            <button className="btn btn-link btn-sm" type="button" hidden={this.state.customMode} id="btnChangeMode" onClick={this.handleChangeMode}>{globalMessages[localStorage.getItem('language')].createCertificate.formSwitchModeBtn}</button>
                                        </div>
                                        <p style={{ marginTop: 20 }}>
                                            {this.state.consigneeData.name}<br />
                                            {this.state.consigneeData.street}<br />
                                            {this.state.consigneeData.zip}&nbsp;{this.state.consigneeData.city}<br />
                                            {this.state.consigneeData.country}
                                        </p>
                                    </div>
                                </div>

                                { /* POI - Input OrderNumber*/}
                                <div className="form-row">
                                    <div className="form-group col-md-12 col-sm-12" hidden={this.state.customMode}>
                                        <label htmlFor="orderNumberInput"><FormattedMessage id="createCertificate.formOrderNumber" />*</label>
                                        <AsyncTypeahead
                                            onChange={this.handleChangeOrderNumber}
                                            id="orderNumberInput"
                                            value={this.state.orderNumber}
                                            inputProps={{ className: `form-control ${this.errorClass(this.state.formErrors.orderNumber)}` }}
                                            placeholder={globalMessages[localStorage.getItem('language')].createCertificate.formOrderNumberPlaceHolder}
                                            options={this.state.orderNumbersForSupplier}
                                            onSearch={this.getOrderNumbersPrefix}/>
                                        <div className="invalid-feedback"><FormattedMessage id="createCertificate.formOrderNumber" /> {this.state.formErrors.orderNumber}</div>
                                    </div>
                                    <div className="form-group col-md-12 col-sm-12" hidden={!this.state.customMode}>
                                        <label htmlFor="orderNumberInput"><FormattedMessage id="createCertificate.formOrderNumber" />*</label>
                                        <input name="orderNumber" type="text" value={this.state.orderNumber} onChange={this.handleChange}
                                            className={`form-control ${this.errorClass(this.state.formErrors.orderNumber)}`}
                                            id="orderNumberInput" placeholder={globalMessages[localStorage.getItem('language')].createCertificate.formOrderNumberPlaceHolder} />
                                        <div className="invalid-feedback"><FormattedMessage id="createCertificate.formOrderNumber" /> {this.state.formErrors.orderNumber}</div>
                                    </div>
                                </div>

                                <ScrollableAnchor id={'section1'}>
                                    <div className="form-row">
                                        <div className="form-group col-md-12 col-sm-12">
                                            <label htmlFor="deliveryNumberInput"><FormattedMessage id="createCertificate.formDeliveryNumber" />*</label>
                                            <input name="deliveryNumber" type="text" value={this.state.deliveryNumber}
                                                ref={this.deliveryNumberInput}
                                                onChange={this.handleChange}
                                                className={`form-control ${this.errorClass(this.state.formErrors.deliveryNumber)}`}
                                                id="deliveryNumberInput"
                                                placeholder={globalMessages[localStorage.getItem('language')].createCertificate.formDeliveryNoteNumberPlaceHolder} />
                                            <div className="invalid-feedback"><FormattedMessage id="createCertificate.formDeliveryNumber" /> {this.state.formErrors.deliveryNumber}</div>
                                        </div>
                                    </div>
                                </ScrollableAnchor>
                            </div>

                            { /* Control if user is using custom mode or not */}
                            <div id="normalMode" hidden={this.state.customMode} >

                                <h3 id="addMaterialTitle" style={{ marginTop: 30 }} hidden={this.state.materialFromOrderNumHidden}><FormattedMessage id="createCertificate.formMaterialFromOrderNum" /></h3>

                                {/* POI - MaterialsFromOrderNumber */}
                                <div className="card" style={{ padding: 20, marginTop: 30 }} hidden={this.state.materialFromOrderNumHidden}>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col"><FormattedMessage id="createCertificate.formOrderPosition" /></th>
                                                    <th scope="col"><FormattedMessage id="createCertificate.formQuantity" /></th>
                                                    <th scope="col"><FormattedMessage id="createCertificate.formMaterialNumber" /></th>
                                                    <th scope="col"><FormattedMessage id="createCertificate.formMaterialName" /></th>
                                                    <th scope="col" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.materialsFromOrderNum.map((material, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{material.orderPosition}</td>
                                                            <td>{material.quantity}</td>
                                                            <td style={{ wordWrap: 'break-word', minWidth: 100, maxWidth: 160 }}>{material.materialNumber}</td>
                                                            <td style={{ wordWrap: 'break-word', minWidth: 100, maxWidth: 160 }}>{material.materialDescription}</td>
                                                            <td>
                                                                <input type="checkbox" name="addMaterialFromSAP" onChange={this.handleAddMaterialFromOrderNum} checked={this.state.autoWordCloudPhrase} style={{ width: 20, height: 20, float: 'left' }} className={`form-control`} id={index} />
                                                                <label style={{ marginLeft: 5, float: 'left' }} className="form-check-label" htmlFor="confirmationCheckBox"><FormattedMessage id="createCertificate.wordincludeInCertCheckBox" /></label>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                <tr>
                                                    <td>

                                                        <button className="btn btn-primary" type="button" id="btnMaterialsFromOrderNum" disabled={this.state.btnAddFromOrderNumberDisabled} onClick={this.handleMaterialsFromOrderNum}><FormattedMessage id="commonButtons.add" /></button>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                { /* Materials Table render conditionally*/}

                                {!this.state.customMode &&
                                    <div className="card" style={{ padding: 20, marginTop: 30 }}>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col"><FormattedMessage id="createCertificate.formOrderPosition" /></th>
                                                        <th scope="col"><FormattedMessage id="createCertificate.formQuantity" /></th>
                                                        <th scope="col"><FormattedMessage id="createCertificate.formMaterialNumber" /></th>
                                                        <th scope="col"><FormattedMessage id="createCertificate.formMaterialName" /></th>
                                                        <th scope="col"><FormattedMessage id="createCertificate.formSerialNumber" /></th>
                                                        <th scope="col"><FormattedMessage id="createCertificate.formOverviewTests" /></th>
                                                        <th scope="col" />
                                                        <th scope="col" />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.materials.map((material, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <th scope="row">{index + 1}</th>
                                                                <td>{material.orderPosition}</td>
                                                                <td>{material.quantity}</td>
                                                                <td style={{ wordWrap: 'break-word', minWidth: 100, maxWidth: 160 }}>{material.materialNumber}</td>
                                                                <td style={{ wordWrap: 'break-word', minWidth: 100, maxWidth: 160 }}>{material.materialDescription}</td>
                                                                <td>{material.serialNumber}</td>
                                                                <td style={{ wordWrap: 'break-word', minWidth: 100, maxWidth: 160 }}>{material.overviewTests}</td>
                                                                <td>
                                                                    <button type="button" value={index} onClick={this.state.preventDeleteMaterials ? null : () => this.handleDeleteMaterial(index)} id={'deleteIconBtn-' + index} className="btn btn-default btnDeleteMaterial" disabled={this.state.btnDeleteMaterialDisabled} style={{ padding: 0 }}>
                                                                        <MdDelete size={25} />
                                                                    </button>
                                                                </td>
                                                                <td>
                                                                    <button type="button" value={index} onClick={() => this.handleEditMaterial(index)} id={'editIconBtn' + index} className="btn btn-default" style={{ padding: 0 }}>
                                                                        <MdEdit size={25} />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }

                            </div>

                            <h3 id="addMaterialTitle" style={{ marginTop: 30 }} hidden={this.state.addMaterialTitleHidden}><FormattedMessage id="createCertificate.formAddMaterial" /></h3>
                            <h3 id="editMaterialTitle" style={{ marginTop: 30 }} hidden={this.state.editMaterialTitleHidden}><FormattedMessage id="createCertificate.formEditMaterial" /> #{this.state.editPosition + 1}</h3>

                            <div className="card" id="materialCard" hidden={this.state.materialCardHidden} style={{ padding: 20, marginTop: 30, backgroundColor: this.state.materialCardBackgroundColor }}>
                                <div className="form-row">
                                    <div className="form-group col-md-3 col-sm-12">
                                        <label htmlFor="orderPositionInput"><FormattedMessage id="createCertificate.formOrderPosition" />*</label>
                                        <input name="orderPosition" type="number" value={this.state.orderPosition} onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.orderPosition)}`} id="orderPositionInput" placeholder={globalMessages[localStorage.getItem('language')].createCertificate.formOrderPositionPlaceHolder} />
                                        <div className="invalid-feedback"><FormattedMessage id="createCertificate.formOrderPosition" /> {this.state.formErrors.orderPosition}</div>
                                    </div>
                                    <div className="form-group col-md-6 col-sm-12">
                                        <label htmlFor="materialNumberInput"><FormattedMessage id="createCertificate.formMaterialNumber" />*</label>
                                        <input name="materialNumber" type="text" value={this.state.materialNumber} onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.materialNumber)}`} id="materialNumberInput" placeholder={globalMessages[localStorage.getItem('language')].createCertificate.formMaterialNumberPlaceHolder} />
                                        <div className="invalid-feedback"><FormattedMessage id="createCertificate.formMaterialNumber" /> {this.state.formErrors.materialNumber}</div>
                                    </div>
                                    <div className="form-group col-md-3 col-sm-12">
                                        <label htmlFor="quantityInput"><FormattedMessage id="createCertificate.formQuantity" />*</label>
                                        <input name="quantity" type="number" value={this.state.quantity} onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.quantity)}`} id="quantityInput" placeholder={globalMessages[localStorage.getItem('language')].createCertificate.formQuantityPlaceHolder} />
                                        <div className="invalid-feedback"><FormattedMessage id="createCertificate.formQuantity" /> {this.state.formErrors.quantity}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="materialDescriptionInput"><FormattedMessage id="createCertificate.formMaterialName" />*</label>
                                    <textarea name="materialDescription" type="text" value={this.state.materialDescription} onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.materialDescription)}`} id="materialDescriptionInput" placeholder={globalMessages[localStorage.getItem('language')].createCertificate.formMaterialNamePlaceHolder} rows="4" />
                                    <div className="invalid-feedback"><FormattedMessage id="createCertificate.formMaterialName" /> {this.state.formErrors.materialDescription}</div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="serialNumberInput">
                                        <FormattedMessage id="createCertificate.formSerialNumber" />
                                        <span id="editSNText" hidden={this.state.editSNTextHidden}>(edit #{this.state.editSNPosition + 1})</span>
                                    </label>
                                    <div className="input-group mb-3">
                                        <input name="serialNumber" type="text" value={this.state.serialNumber} onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.serialNumber)}`} id="serialNumberInput" placeholder={globalMessages[localStorage.getItem('language')].createCertificate.formSerialNumberPlaceHolder} />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="button" id="btnAddSerialNumber" onClick={this.handleAddSerialNumber} disabled={this.state.serialNumber.length === 0} hidden={this.state.btnAddSerialNumberHidden}><FormattedMessage id="commonButtons.add" /></button>
                                            <button className="btn btn-outline-secondary" type="button" id="btnCancelEdtSerialNumber" onClick={this.handleCancedlEditSerialNumber} hidden={this.state.btnCancelEdtSerialNumberHidden}><FormattedMessage id="commonButtons.cancel" /></button>
                                            <button className="btn btn-outline-secondary" type="button" id="btnEditSerialNumber" onClick={this.handleSaveEditSerialNumber} hidden={this.state.btnEditSerialNumberHidden}><FormattedMessage id="commonButtons.edit" /></button>
                                        </div>
                                        <div className="invalid-feedback"><FormattedMessage id="createCertificate.formInvalidSerialNumberMessage" /></div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Serial Numbers</th>
                                                <th scope="col" />
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.serialNumbers.map((serialNumber, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{serialNumber}</td>
                                                        <td>
                                                            <button type="button" value={index} onClick={this.state.preventDeleteSerials ? null : () => this.handleDeleteSerialNumber(index)} id={`deleteSNIconBtn-` + index} className="btn btn-default btnDeleteSN" disabled={this.state.btnDeleteSNDisabled} style={{ padding: 0 }}>
                                                                <MdDelete size={25} />
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button type="button" value={index} onClick={() => this.handleEditSerialNumber(index)} id="editSNIconBtn" className="btn btn-default" style={{ padding: 0 }}>
                                                                <MdEdit size={25} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="overviewTestsInput"><FormattedMessage id="createCertificate.formOverviewTests" /></label>

                                    { /* POI 
                                    <textarea name="overviewTests" type="text" value={this.state.overviewTests} onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.overviewTests)}`} id="overviewTestsInput" placeholder={globalMessages[localStorage.getItem('language')].createCertificate.formOverviewTestsPlaceHolder} rows="4" />
                                    */}
                                    <Fragment>
                                        <Typeahead onChange={this.handleChangeOverviewtests}
                                            id="overviewTestsInput"
                                            labelKey="name"
                                            placeholder={globalMessages[localStorage.getItem('language')].createCertificate.formOverviewTestsPlaceHolder}
                                            multiple
                                            options={this.state.tests} />
                                    </Fragment>
                                    <div className="invalid-feedback"><FormattedMessage id="createCertificate.formInvalidOverviewTestsMessage" /></div>
                                </div>
                                <div className="form-row d-flex align-items-center">
                                    <div className="form-group col-3 d-flex justify-content-start">
                                        <input type="checkbox" name="flagMultipleEntries" onChange={this.handleChange} id="flagMultipleEntriesId" style={{ width: 20, height: 20 }} hidden={this.state.flagMultipleEntriesIdHidden} />
                                        <label className="form-check-label" htmlFor="flagMultipleEntries" id="labelFlagMultiplesEntriesId" hidden={this.state.labelFlagMultiplesEntriesIdHidden}>&nbsp;&nbsp;<FormattedMessage id="createCertificate.formMaintainData" /></label>
                                    </div>
                                    <div className="form-group col-9 d-flex justify-content-end">
                                        {/* POI */}
                                        <button type="button" id="addMaterialBtn" className="btn btn-primary" onClick={this.handleMaterial} style={{ marginRight: 5, marginTop: 20 }} disabled={!this.state.formMaterialValid} hidden={this.state.addMaterialBtnHidden}><FormattedMessage id="createCertificate.formAddMaterialBtn" /></button>
                                        <button type="button" id="cancelEditMaterialBtn" className="btn btn-secondary" onClick={this.handleCancelEditMaterial} style={{ marginRight: 5, marginTop: 20 }} hidden={this.state.cancelEditMaterialBtnHidden}><FormattedMessage id="createCertificate.formCancelEditMaterialBtn" /></button>
                                        <button type="button" id="editMaterialBtn" className="btn btn-primary" onClick={this.handleSaveEditMaterial} style={{ marginRight: 5, marginTop: 20 }} hidden={this.state.editMaterialBtnHidden}><FormattedMessage id="createCertificate.formEditMaterialBtn" /></button>
                                    </div>
                                </div>
                            </div>

                            <div className="alert alert-warning" role="alert" id="orderPositionAlert" style={{ marginTop: 30 }} hidden={this.state.orderPositionAlertHidden}>
                                There is one or more material with the same order position. Change it to create a certificate.
                            </div>


                            {this.state.customMode &&
                                <div className="card" style={{ padding: 20, marginTop: 30 }}>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col"><FormattedMessage id="createCertificate.formOrderPosition" /></th>
                                                    <th scope="col"><FormattedMessage id="createCertificate.formQuantity" /></th>
                                                    <th scope="col"><FormattedMessage id="createCertificate.formMaterialNumber" /></th>
                                                    <th scope="col"><FormattedMessage id="createCertificate.formMaterialName" /></th>
                                                    <th scope="col"><FormattedMessage id="createCertificate.formSerialNumber" /></th>
                                                    <th scope="col"><FormattedMessage id="createCertificate.formOverviewTests" /></th>
                                                    <th scope="col" />
                                                    <th scope="col" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.materials.map((material, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{material.orderPosition}</td>
                                                            <td>{material.quantity}</td>
                                                            <td style={{ wordWrap: 'break-word', minWidth: 100, maxWidth: 160 }}>{material.materialNumber}</td>
                                                            <td style={{ wordWrap: 'break-word', minWidth: 100, maxWidth: 160 }}>{material.materialDescription}</td>
                                                            <td>{material.serialNumber}</td>
                                                            <td style={{ wordWrap: 'break-word', minWidth: 100, maxWidth: 160 }}>{material.overviewTests}</td>
                                                            <td>
                                                                <button type="button" value={index} onClick={this.state.preventDeleteMaterials ? null : () => this.handleDeleteMaterial(index)} id={'deleteIconBtn-' + index} className="btn btn-default btnDeleteMaterial" disabled={this.state.btnDeleteMaterialDisabled} style={{ padding: 0 }}>
                                                                    <MdDelete size={25} />
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <button type="button" value={index} onClick={() => this.handleEditMaterial(index)} id={'editIconBtn' + index} className="btn btn-default" style={{ padding: 0 }}>
                                                                    <MdEdit size={25} />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }


                            <div className="card" style={{ padding: 20, marginTop: 30 }}>
                                <div className="form-row">
                                    {/* <div className="form-group col-lg-10 col-md-8 col-sm-12">
                                    <label htmlFor="nameInspectorInput"><FormattedMessage id="createCertificate.formInspectorName" />*</label>
                                    <input name="nameInspector" type="text" value={this.state.nameInspector} onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.nameInspector)}`} id="nameInspectorInput" placeholder={globalMessages[localStorage.getItem('language')].createCertificate.formInspectorNamePlaceHolder} />
                                    <div className="invalid-feedback"><FormattedMessage id="createCertificate.formInspectorName" /> {this.state.formErrors.nameInspector}</div>
                                </div> */}
                                    <div className="form-group col-lg-10 col-md-6 col-sm-12">
                                        <label htmlFor="nameInspectorInput"><FormattedMessage id="createCertificate.formInspectorName" />*</label>
                                        <div className="input-group mb-3">
                                            <input name="nameInspector" type="text" value={this.state.nameInspector} onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.nameInspector)}`} id="nameInspectorInput" placeholder={globalMessages[localStorage.getItem('language')].createCertificate.formInspectorNamePlaceHolder} readOnly />
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button" id="btnAddConsignee" onClick={this.handleTesterList}><FormattedMessage id="commonButtons.select" /></button>
                                            </div>
                                            <div className="invalid-feedback"><FormattedMessage id="createCertificate.formInspectorName" /> {this.state.formErrors.nameInspector}</div>
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-2 col-md-4 col-sm-12" style={{ paddingTop: '10px !important' }}>
                                        <label htmlFor="inspectionDateInput"><FormattedMessage id="createCertificate.formInspectionDate" />*</label>
                                        <DatePicker
                                            name="inspectionDate"
                                            dateFormat="dd/MM/yyyy"
                                            selected={this.state.inspectionDate}
                                            onChange={this.handleChange}
                                            className={`form-control ${this.errorClass(this.state.formErrors.inspectionDate)}`}
                                            id="inspectionDateInput"
                                        />
                                        <div className="invalid-feedback" id="inspectionDateInvalidFeedback" style={{ display: this.state.inspectionDateInvalidFeedbackDisplay }}><FormattedMessage id="createCertificate.formInspectionDate" /> {this.state.formErrors.inspectionDate}</div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="form-row" style={{ marginTop: 20 }}>
                                        <div className="form-group col-12 d-flex align-items-center justify-content-center">
                                            <h4><FormattedMessage id="createCertificate.formNoteTestsResultTitle" />*</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="card" style={{ marginTop: 10 }}>
                                    <div className="form-row" style={{ marginTop: 20 }}>
                                        <div className="form-group col-md-10 col-sm-12 d-flex align-items-center justify-content-center">
                                            <div className="text-justify" style={{ paddingLeft: 20, paddingRight: 20 }}>
                                                <FormattedMessage id="createCertificate.formNoteTestsResultsText" />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-2 col-sm-12  d-flex align-items-center justify-content-center">
                                            <input type="checkbox" name="checkTestResults" onChange={this.handleChange} checked={this.state.checkTestResults} className={`form-control ${this.errorClass(this.state.formErrors.checkTestResults)}`} id="checkTestResultsCheckBox" style={{ width: 20, height: 20 }} />
                                            <div className="invalid-feedback col-md-10 col-sm-3">  required.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card" style={{ marginTop: 10 }}>
                                    <div className="form-row" style={{ marginTop: 25 }}>
                                        <div className="form-group col-12 d-flex align-items-center justify-content-center">
                                            <h4>
                                                <FormattedMessage id="createCertificate.formConfirmationTitle" />*
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="card" style={{ marginTop: 10 }}>
                                    <div className="form-row" style={{ marginTop: 25 }}>
                                        <div className="form-group col-md-10 col-sm-12 d-flex align-items-center justify-content-center">
                                            <div className="text-justify" id="confirmationTextId" style={{ paddingLeft: 20, paddingRight: 20 }}>
                                                <FormattedMessage id="createCertificate.formConfirmationText" />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-2 col-sm-12  d-flex align-items-center justify-content-center">
                                            <input type="checkbox" name="confirmation" onChange={this.handleChange} checked={this.state.confirmation} className={`form-control ${this.errorClass(this.state.formErrors.confirmation)}`} id="confirmationCheckBox" style={{ width: 20, height: 20 }} />
                                            <div className="invalid-feedback col-md-10 col-sm-3"><FormattedMessage id="createCertificate.formRequiredMessage" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card" style={{ padding: 20, marginTop: 30, marginBottom: 30 }}>
                            <div>
                                <div className="form-group col-md-2 col-sm-12  d-flex align-items-center justify-content-center">
                                    <input type="file" name="files" onChange={this.handleChange} id="fileBox" style={{ width: 20, height: 20 }} />
k                                </div>
                            </div>
                        </div> */}
                            <div className="card" style={{ padding: 20, marginTop: 30, marginBottom: 30 }}>
                                <div className="d-flex justify-content-between" style={{ paddingLeft: 20 }}>
                                    <p style={{ marginTop: 'auto', marginRight: 'auto', marginBottom: 'auto' }}><em>* <FormattedMessage id="commonMessages.isRequired" /></em></p>
                                    <button type="button" onClick={this.handleConfirmation} className="btn btn-primary col-md-3 col-sm-4" disabled={!this.state.formValid}><FormattedMessage id="createCertificate.formCreateCertificateBtn" /></button>
                                </div>
                            </div>
                            <div>
                                <Modal animation={false} show={this.state.showModal}>
                                    <ModalHeader>
                                        <Modal.Title><FormattedMessage id="createCertificate.formTitle" /></Modal.Title>
                                    </ModalHeader>
                                    <Modal.Body id="modalConfirmCreationId">
                                        <div className="card" style={{ padding: 20, marginTop: 5 }} id="pdfSupplierConsigneeONumberId">
                                            <div className="form-row">
                                                <div className="form-group col-md-7 col-sm-12">
                                                    <h4><FormattedMessage id="createCertificate.formSupplier" /></h4>
                                                    <p style={{ marginTop: 20 }} id="pdfSupplierInfo">
                                                        {this.state.supplierData.name}<br />
                                                        {this.state.supplierData.street}<br />
                                                        {this.state.supplierData.zip}&nbsp;{this.state.supplierData.city}<br />
                                                        {this.state.supplierData.country}<br />
                                                    </p>
                                                </div>
                                                <div className="form-group col-md-5 col-sm-12">
                                                    <h4><FormattedMessage id="createCertificate.formConsignee" /></h4>
                                                    <p style={{ marginTop: 20 }} id="pdfConsigneeInfo">
                                                        {this.state.consigneeData.name}<br />
                                                        {this.state.consigneeData.street}<br />
                                                        {this.state.consigneeData.zip}&nbsp;{this.state.consigneeData.city}<br />
                                                        {this.state.consigneeData.country}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-7 col-sm-12">
                                                    <label htmlFor="orderNumberId"><h4><FormattedMessage id="createCertificate.formOrderNumber" /></h4></label>
                                                    <input id="orderNumberId" name="orderNumber" value={this.state.orderNumber} type="text" className="form-control-plaintext" readOnly />
                                                </div>
                                                <div className="form-group col-md-5 col-sm-12">
                                                    <label htmlFor="deliveryNumberId"><h4><FormattedMessage id="createCertificate.formDeliveryNumber" /></h4></label>
                                                    <input id="deliveryNumberId" name="deliveryNumber" value={this.state.deliveryNumber} type="text" className="form-control-plaintext" readOnly />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card" style={{ padding: 20, marginTop: 30 }}>
                                            <div className="table-responsive">
                                                <table className="table" id="materialListTableId">
                                                    <thead id="pdfHeadMaterialId">
                                                        <tr>
                                                            <th scope="col"><FormattedMessage id="createCertificate.formOrderPosition" /></th>
                                                            <th scope="col"><FormattedMessage id="createCertificate.formQuantity" /></th>
                                                            <th scope="col"><FormattedMessage id="createCertificate.formMaterialNumber" /></th>
                                                            <th scope="col"><FormattedMessage id="createCertificate.formMaterialName" /></th>
                                                            <th scope="col"><FormattedMessage id="createCertificate.formSerialNumber" /></th>
                                                            <th scope="col"><FormattedMessage id="createCertificate.formOverviewTests" /></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.materials.map((material, index) => {
                                                            return (
                                                                <tr key={index} className="pdfItemMaterialList">
                                                                    <td>{material.orderPosition}</td>
                                                                    <td>{material.quantity}</td>
                                                                    <td>{material.materialNumber}</td>
                                                                    <td>{material.materialDescription}</td>
                                                                    <td>{material.serialNumber}</td>
                                                                    <td>{material.overviewTests}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="card" style={{ padding: 20, marginTop: 30 }} id="pdfFooterId">
                                            <div className="form-row">
                                                <div className="form-group col-lg-10 col-md-8 col-sm-12">
                                                    <label htmlFor="nameInspectorInput"><h6><FormattedMessage id="createCertificate.formInspectorName" /></h6></label>
                                                    <input name="nameInspector" value={this.state.nameInspector} type="text" className="form-control-plaintext" id="nameInspectorInput" readOnly />
                                                </div>
                                                <div className="form-group col-lg-2 col-md-4 col-sm-12">
                                                    <label htmlFor="inspectionDateInput"><h6><FormattedMessage id="createCertificate.formInspectionDate" /></h6></label>
                                                    <input name="inspectionDate" value={this.formatDate(new Date(this.state.inspectionDate))} className="form-control-plaintext" id="inspectionDateInput" readOnly />
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="form-row" style={{ marginTop: 20 }}>
                                                    <div className="form-group col-12 d-flex align-items-center justify-content-center">
                                                        <h4><FormattedMessage id="createCertificate.formNoteTestsResultTitle" /></h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card" style={{ marginTop: 10 }}>
                                                <div className="form-row" style={{ marginTop: 20 }}>
                                                    <div className="form-group col-10 d-flex align-items-center justify-content-center">
                                                        <div className="text-justify" style={{ paddingLeft: 30 }} id="noteTestsText">
                                                            <FormattedMessage id="createCertificate.formNoteTestsResultsText" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-2 d-flex align-items-center justify-content-center">
                                                        <input type="checkbox" disabled="disabled" checked={this.state.confirmation} name="noteTestResults" className="form-control-plaintext" id="noteTestResultsCheckBox" style={{ width: 20, height: 20 }} readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card" style={{ marginTop: 10 }}>
                                                <div className="form-row" style={{ marginTop: 25 }}>
                                                    <div className="form-group col-12 d-flex align-items-center justify-content-center">
                                                        <h4><FormattedMessage id="createCertificate.formConfirmationTitle" /></h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card" style={{ marginTop: 10 }}>
                                                <div className="form-row" style={{ marginTop: 25 }}>
                                                    <div className="form-group col-md-10 col-sm-12 d-flex align-items-center justify-content-center">
                                                        <div className="text-justify" style={{ paddingLeft: 20, paddingRight: 20 }} id="confirmationText">
                                                            <FormattedMessage id="createCertificate.formConfirmationText" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-2 col-sm-12  d-flex align-items-center justify-content-center">
                                                        <input type="checkbox" name="confirmation" disabled="disabled" checked={this.state.confirmation} className="form-control-plaintext" id="confirmationCheckBox" style={{ width: 20, height: 20 }} readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className="btn btn-warning" onClick={this.handleCancelConfirmation}><FormattedMessage id="createCertificate.formEditCertificateBtn" /></Button>
                                        <Button className="btn btn-primary" id="btnCreateInspectionCertificate" onClick={this.handleSubmit} disabled={this.state.disableSubmitBtn}><FormattedMessage id="createCertificate.formCreateInspectionCertificateBtn" /></Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                            <div>

                                { /* POI */}
                                <Modal animation={false} show={this.state.showAPIErrorModal}>
                                    <ModalHeader>
                                        <Modal.Title><FormattedMessage id="createCertificate.formTitle" /></Modal.Title>
                                    </ModalHeader>
                                    <Modal.Body>
                                        <div className="card" style={{ padding: 20, marginTop: 5 }}>
                                            <div className="form-row">
                                                <div className="form-group">
                                                    <h4>{this.state.apiErrorMessage}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button onClick={this.setCustomMode}><FormattedMessage id="createCertificate.formSwitchModeBtn" /></Button>
                                    </Modal.Footer>
                                </Modal>



                                <Modal animation={false} show={this.state.showConfirmationModal}>
                                    <ModalHeader>
                                        <Modal.Title><FormattedMessage id="createCertificate.formTitle" /></Modal.Title>
                                    </ModalHeader>
                                    <Modal.Body>
                                        <div className="card" style={{ padding: 20, marginTop: 5 }}>
                                            <div className="form-row">
                                                <div className="form-group">
                                                    <h4>{this.state.messageCreateCertificate}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button onClick={this.handleSuccessModal}><FormattedMessage id="createCertificate.formCloseBtn" /></Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                            <div>
                                <Modal animation={false} show={this.state.showConsigneeListModal}>
                                    <ModalHeader>
                                        <Modal.Title><FormattedMessage id="createCertificate.formSelectConsignee" /></Modal.Title>
                                    </ModalHeader>
                                    <Modal.Body>
                                        <div className="card" style={{ padding: 20, marginTop: 5 }}>
                                            <div className="form-group">
                                                <table className="table-responsive">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col"><FormattedMessage id="createCertificate.selectConsigneeName" /></th>
                                                            <th scope="col"><FormattedMessage id="createCertificate.selectConsigneeStreet" /></th>
                                                            <th scope="col"><FormattedMessage id="createCertificate.selectConsigneeZip" /></th>
                                                            <th scope="col"><FormattedMessage id="createCertificate.selectConsigneeCity" /></th>
                                                            <th scope="col"><FormattedMessage id="createCertificate.selectConsigneeCountry" /></th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.consigneeList.map((consignee, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <th scope="row">{index + 1}</th>
                                                                    <td>{consignee.name}</td>
                                                                    <td>{consignee.street}</td>
                                                                    <td>{consignee.zip}</td>
                                                                    <td>{consignee.city}</td>
                                                                    <td>{consignee.country}</td>
                                                                    <td>
                                                                        <button type="button" id={"consigneeList-" + index} value={index} onClick={() => this.handleSelectConsignee(index)} className="btn btn-primary"><FormattedMessage id="commonButtons.select" /></button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button onClick={this.handleCancelSelectConsignee}><FormattedMessage id="createCertificate.formCloseBtn" /></Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                            <div>
                                <Modal animation={false} show={this.state.showTesterListModal}>
                                    <ModalHeader>
                                        <Modal.Title><FormattedMessage id="createCertificate.formSelectTester" /></Modal.Title>
                                    </ModalHeader>
                                    <Modal.Body>
                                        <div className="card" style={{ padding: 20, marginTop: 5 }}>
                                            <div className="form-group">
                                                <table className="table-responsive">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col"><FormattedMessage id="createCertificate.selectConsigneeName" /></th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.testerList.map((tester, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <th scope="row">{index + 1}</th>
                                                                    <td>{tester}</td>
                                                                    <td>
                                                                        <button type="button" id={"testerList-" + index} value={index} onClick={() => this.handleSelectTester(index)} className="btn btn-primary"><FormattedMessage id="commonButtons.select" /></button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button onClick={this.handleCancelSelectTester}><FormattedMessage id="createCertificate.formCloseBtn" /></Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                            <LoadingComponent hidden={this.state.showLoadingModal} />
                        </form>
                    }
                </div >
                <Footer />
            </div >
        );
    }
}

export default CreateCertificateContainer
