import React from 'react';
import FormErrors from '../components/FormErrors';
import { Modal, Button, ModalHeader } from 'react-bootstrap';
import LoadingComponent from '../components/LoadingComponent';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import UserService from '../services/UserService';
import { FormattedMessage } from 'react-intl';
import Messages from '../messages';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Footer from '../components/Footer';

import OidcProvider from '../services/OidcProvider';
import NavBar from '../components/NavBar';

import { ApiService } from '../services/ApiService';

const globalMessages = Messages;
const userService = userService == null ? new UserService() : userService;
const userRole = userService.getUserRole();
const isSupplier = userRole === 'userSupplier';

let certificates = [{}];
let rowItemkey = 0;

export class ShowCertificatesContainer extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            isSiemensOrSupplier: true,
            certificateId: '',
            supplier: '',
            orderNumber: '',
            deliveryNumber: '',
            orderPosition: '',
            materialNumber: '',
            materialDescription: '',
            quantity: '',
            serialNumber: '',
            overviewTests: '',
            nameInspector: '',
            inspectionDate: moment(),
            confirmationText: '',
            confirmation: '',
            checkTestsResults: '',
            materialList: [],
            certificateList: [],
            supplierData: {
                name: '',
                street: '',
                zip: '',
                city: '',
                country: ''
            },
            consigneeIndex: null,
            consigneeData: {
                name: '',
                street: '',
                zip: '',
                city: '',
                country: ''
            },
            expandedRows: [],
            showModal: false,
            showLoadingModal: false,
            supplierValid: true,
            orderNumberValid: false,
            deliveryNumberValid: false,
            orderPositionValid: true,
            materialNumberValid: false,
            materialDescriptionValid: false,
            quantityValid: false,
            serialNumberValid: true,
            overviewTestsValid: true,
            nameInspectorValid: false,
            inspectionDateValid: true,
            confirmationTextValid: false,
            confirmationValid: false,
            formValid: false,
            formMaterialValid: false,
            formErrors: {
                supplier: '',
                orderNumber: '',
                deliveryNumber: '',
                orderPosition: '',
                materialNumber: '',
                materialDescription: '',
                quantity: '',
                serialNumber: '',
                overviewTests: '',
                nameInspector: '',
                inspectionDate: '',
                confirmationText: '',
                confirmation: ''
            },
            decryptError: false
        };

    }

    componentDidMount() {
        this.getRole();
        this.getDirectLinkCertificate();
    }

    getDirectLinkCertificate = () => {


        const oNr = sessionStorage.getItem("orderNumber");
        console.log(`DEBUG: [orderNumberFromStaticLink] ${oNr}`)
        const oPos = sessionStorage.getItem("orderPosition");
        const dBr = sessionStorage.getItem("deliveryNumber");
        if (oNr && oPos && dBr) {
            this.setState({
                showLoadingModal: true
            });

            const user = this.getUser();
            const certblockAPI = new ApiService();
            const CRT_API = certblockAPI.getCRT_API(user.id_token);
            CRT_API.getCertificateId(oNr, oPos, dBr)
                .then((certificate) => {
                    this.setState({
                        showLoadingModal: false
                    });

                    sessionStorage.removeItem("orderNumber");
                    sessionStorage.removeItem("orderPosition");
                    sessionStorage.removeItem("deliveryNumber");

                    this.showCertificate(certificate.data, 0);
                })
                .catch(() => {
                    this.setState({
                        showLoadingModal: false
                    });

                    sessionStorage.removeItem("orderNumber");
                    sessionStorage.removeItem("orderPosition");
                    sessionStorage.removeItem("deliveryNumber");
                    console.error('Certificate not found');
                });
        }

    };

    getRole = () => {
        const oidc = new OidcProvider();
        const user = oidc.getUser();

        if (user.role === 'userSupplier') {
            this.setState({ isSupplier: true });
        }

    }

    handleKeyUp = (e) => {
        if (e == null || e.target.name !== "orderNumber") return;

        switch (e.key) {
            case "Enter":
            case "Return": {
                this.getCertificates();
                break;
            }
        }
    };

    handleChange = (e) => {

        let name;
        let value;

        if (e != null) {

            name = e._isAMomentObject ? "inspectionDate" : e.target.name;
            value = e._isAMomentObject ? e : (e.target.type === "checkbox" ? e.target.checked : e.target.value);

            switch (name) {
                case "orderNumber": {
                    this.setState({ orderNumber: value });
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };

    handleClose = () => {
        this.setState({ showModal: false });
    };

    getUser = () => {
        return JSON.parse(localStorage.getItem('oidc_user'));
    };

    getCertificates = () => {
        this.setState({
            showLoadingModal: true
        });

        const user = this.getUser();

        const certblockAPI = new ApiService();
        const CRT_API = certblockAPI.getCRT_API(user.id_token);

        CRT_API.getCertificateIdList(this.state.orderNumber)
        .then((certificates) => {
            console.log(`[certificateGet] ${JSON.stringify(certificates.data)}`);
            this.setState({
                certificateList: certificates.data,
                showLoadingModal: false
            });
        })
        .catch((error) => {
            console.error(error);
            this.setState({
                showLoadingModal: false
            });
        });

    };

    showCertificate = (id, version) => {

        this.setState({
            showLoadingModal: true
        });

        console.log(`DEBUG: [id/version] ${id}/${version}`)

        const user = this.getUser();

        const certblockAPI = new ApiService();
        const CRT_API = certblockAPI.getCRT_API(user.id_token);

        CRT_API.getCertificateSiemensById(id, version)
        .then((certificate) => {
            this.setState({
                showLoadingModal: false,
                showModal: true,
                supplierData: certificate.data.creator,
                orderNumber: certificate.data.orderNumber,
                deliveryNumber: certificate.data.deliveryNumber,
                materialList: certificate.data.materialList,
                nameInspector: certificate.data.nameInspector,
                inspectionDate: certificate.data.inspectionDate,
                confirmationText: certificate.data.confirmationText,
                confirmation: certificate.data.checkConfirmation,
                checkTestsResults: certificate.data.checkTestsResults,
                consigneeData: certificate.data.consignee,
                orderPosition: certificate.data.materialList[0].orderPosition
            });
        })
        .catch((error) => {
            console.error(error);
            this.setState({
                showLoadingModal: false
            });
        });
    
    };

    generatePDF = () => {

        const pdfSupplier = document.getElementById('pdfSupplierInfo').innerText;
        const pdfConsignee = document.getElementById('pdfConsigneeInfo').innerText;
        const pdfOrderNumber = document.getElementById('orderNumberInput').value;
        const pdfDeliveryNumber = document.getElementById('deliveryNumberInput').value;
        const pdfItemMaterialList = Array.from(document.querySelectorAll(".pdfItemMaterialList"));
        const pdfInspectorName = document.getElementById('nameInspectorInput').value;
        const pdfInspectionDate = document.getElementById('inspectionDateInput').value;
        const pdfNoteTestsResultsText = document.getElementById('noteTestsText').innerText;
        const pdfConfirmationText = document.getElementById('confirmationText').innerText;
        const pdfNoteTestsResults = document.getElementById('noteTestResultsCheckBox').checked ? 'Agreed by signer' : 'Not agreed by signer';
        const pdfConfirmation = document.getElementById('confirmationCheckBox').checked ? 'Agreed by signer' : 'Not agreed by signer';

        // init

        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'pt',
            format: 'a4'
        });

        //Title
        pdf.setFontSize(25);
        pdf.setTextColor(100);
        pdf.text('Inspection Certificate 3.1 acc. EN 10204', 40, 50);

        //Supplier info

        let colHeaderSupplier = [
            { title: "Supplier", dataKey: "text" }
        ];

        let rowHeaderSupplier = [];
        rowHeaderSupplier.push(
            { text: pdfSupplier }
        );

        pdf.autoTable(colHeaderSupplier.slice(0, 2), rowHeaderSupplier, {
            startY: 90,
            theme: 'plain',
            styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
            columnStyles: { text: { columnWidth: 'auto' } },
            margin: { right: 240 }
        });

        // Reset page to the same as before previous table

        pdf.setPage(1 + pdf.internal.getCurrentPageInfo().pageNumber - pdf.autoTable.previous.pageCount);

        //Consignee info

        let colHeaderConsignee = [
            { title: "Consignee", dataKey: "text" }
        ];

        let rowHeaderConsignee = [];
        rowHeaderConsignee.push(
            { text: pdfConsignee }
        );

        pdf.autoTable(colHeaderConsignee.slice(0, 2), rowHeaderConsignee, {
            startY: 90,
            theme: 'plain',
            styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
            columnStyles: { text: { columnWidth: 'auto' } },
            margin: { left: 360 }
        });

        let nextOutputY = pdf.autoTable.previous.finalY + 10;

        //OrderNumber info

        let colHeaderOrderNumber = [
            { title: "Order Number", dataKey: "text" }
        ];

        let rowHeaderOrderNumber = [];
        rowHeaderOrderNumber.push(
            { text: pdfOrderNumber }
        );

        pdf.autoTable(colHeaderOrderNumber, rowHeaderOrderNumber, {
            startY: nextOutputY,
            theme: 'plain',
            styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
            columnStyles: { text: { columnWidth: 'auto' } },
            margin: { right: 240 }
        });

        //DeliveryNumber info

        let colHeaderDeliveryNumber = [
            { title: "Delivery Note Number", dataKey: "text" }
        ];

        let rowHeaderDeliveryNumber = [];
        rowHeaderDeliveryNumber.push(
            { text: pdfDeliveryNumber }
        );

        pdf.autoTable(colHeaderDeliveryNumber, rowHeaderDeliveryNumber, {
            startY: nextOutputY,
            theme: 'plain',
            styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
            columnStyles: { text: { columnWidth: 'auto' } },
            margin: { left: 360 }
        });

        //Materials info

        let colMaterialList = ['Order Position', 'Quantity', 'Material Number', 'Material Name', 'Serial Number', 'Overview Tests'];
        let rowMaterialList = [];

        pdfItemMaterialList.forEach(line => {
            let contentList = [];
            let column = line.cells;
            for (let i = 0; i < column.length; i++) {
                const element = column.item(i).innerText;
                contentList.push(element);
            }
            rowMaterialList.push(contentList);
        });

        pdf.autoTable(colMaterialList, rowMaterialList, {
            startY: pdf.autoTable.previous.finalY + 10,
            styles: { overflow: 'linebreak', halign: 'left', valign: 'middle' },
            showHeader: 'firstPage'
        });

        //Inspector Name info

        let colFooterInspectorName = [
            { title: "Inspector Name", dataKey: "text" }
        ];

        let rowFooterInspectorName = [];
        rowFooterInspectorName.push(
            { text: pdfInspectorName }
        );

        pdf.autoTable(colFooterInspectorName.slice(0, 2), rowFooterInspectorName, {
            startY: pdf.autoTable.previous.finalY + 25,
            theme: 'plain',
            styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
            columnStyles: { text: { columnWidth: 'auto' } },
            margin: { right: 135 },
            showHeader: 'firstPage'
        });

        //InspectionDate info

        let colFooterInspectionDate = [
            { title: "Inspection Date", dataKey: "text" }
        ];

        let rowFooterInspectionDate = [];
        rowFooterInspectionDate.push(
            { text: pdfInspectionDate }
        );

        pdf.autoTable(colFooterInspectionDate.slice(0, 2), rowFooterInspectionDate, {
            startY: pdf.autoTable.previous.finalY - 45,
            theme: 'plain',
            styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
            columnStyles: { text: { columnWidth: 'auto' } },
            margin: { left: 465 },
            showHeader: 'firstPage'
        });

        //Note Tests Results Text

        let colFooterNoteTestsResults = [
            { title: "Note/Tests Results", dataKey: "text" }
        ];

        let rowFooterNoteTestsResults = [];
        rowFooterNoteTestsResults.push(
            { text: pdfNoteTestsResultsText }
        );

        pdf.autoTable(colFooterNoteTestsResults.slice(0, 2), rowFooterNoteTestsResults, {
            startY: pdf.autoTable.previous.finalY + 30,
            theme: 'plain',
            styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
            columnStyles: { text: { columnWidth: 'auto' } },
            margin: { right: 170 },
            showHeader: 'firstPage'
        });

        //Note Tests Results check

        // let colFooterNoteTestsResultsCheck = [
        //     { title: "", dataKey: "check" }
        // ];

        // let rowFooterNoteTestsResultsCheck = [];
        // rowFooterNoteTestsResultsCheck.push(
        //     { check: pdfNoteTestsResults }
        // );

        // pdf.autoTable(colFooterNoteTestsResultsCheck.slice(0, 2), rowFooterNoteTestsResultsCheck, {
        //     startY: pdf.autoTable.previous.finalY - 60,
        //     theme: 'plain',
        //     styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
        //     columnStyles: { text: { columnWidth: 'auto' } },
        //     margin: { left: 470 }
        // });

        //Confirmation Text

        let colFooterConfirmation = [
            { title: "Confirmation", dataKey: "text" }
        ];

        let rowFooterConfirmation = [];
        rowFooterConfirmation.push(
            { text: pdfConfirmationText }
        );

        pdf.autoTable(colFooterConfirmation.slice(0, 2), rowFooterConfirmation, {
            startY: pdf.autoTable.previous.finalY + 20,
            theme: 'plain',
            styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
            columnStyles: { text: { columnWidth: 'auto' } },
            margin: { right: 170 },
            showHeader: 'firstPage'
        });

        //Confirmation check

        // let colFooterConfirmationCheck = [
        //     { title: "", dataKey: "check" }
        // ];

        // let rowFooterConfirmationCheck = [];
        // rowFooterConfirmationCheck.push(
        //     { check: pdfConfirmation }
        // );

        // pdf.autoTable(colFooterConfirmationCheck.slice(0, 2), rowFooterConfirmationCheck, {
        //     startY: pdf.autoTable.previous.finalY - 60,
        //     theme: 'plain',
        //     styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
        //     columnStyles: { text: { columnWidth: 'auto' } },
        //     margin: { left: 470 }
        // });


        // FinalNote: "This certificate was created electronically and is valid without signature."

        let pdfFinalNote = "This certificate was created electronically and is valid without signature.";
        let colHeaderFinalNote = [
            { title: "", dataKey: "text" }
        ];

        let rowHeaderFinalNote = [];
        rowHeaderFinalNote.push(
            { text: pdfFinalNote }
        );

        pdf.autoTable(colHeaderFinalNote, rowHeaderFinalNote, {
            startY: pdf.autoTable.previous.finalY + 20,
            theme: 'plain',
            styles: { textColor: 125, overflow: 'linebreak', halign: 'center', valign: 'middle' },
            columnStyles: { text: { columnWidth: 'auto' } },
        });



        // Generating pdf

        // pdf-filename: "<date>_<supplierName>_<orderNumber>_<deliveryNumber>_3_1_Zertifikat.pdf"
        let dateYYYYMMDD = moment.unix(this.state.inspectionDate).format("YYYYMMDD");
        let pdfFileName = `${dateYYYYMMDD}_${this.state.supplierData.name}_${pdfOrderNumber}_${pdfDeliveryNumber}_3_1_Zertifikat.pdf`;

        pdf.save(pdfFileName);

    };

    handleRowClick = (rowId) => {
        const currentExpandedRows = this.state.expandedRows;
        const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

        const newExpandedRows = isRowCurrentlyExpanded ?
            currentExpandedRows.filter(id => id !== rowId) :
            currentExpandedRows.concat(rowId);

        document.getElementById("materialBtn-" + rowId).innerHTML === globalMessages[localStorage.getItem('language')].showCertificate.btnShowPositions ?
            document.getElementById("materialBtn-" + rowId).innerHTML = globalMessages[localStorage.getItem('language')].showCertificate.btnHidePositions :
            document.getElementById("materialBtn-" + rowId).innerHTML = globalMessages[localStorage.getItem('language')].showCertificate.btnShowPositions;

        this.setState({ expandedRows: newExpandedRows });
    };

    renderItem = (item) => {

        const clickCallback = () => this.handleRowClick(item.certificateId);
        const itemRows = [
            <tr key={"row-data-" + item.certificateId + moment.unix(item.inspectionDate).format("DDMMYYYYhh:mm:ssa")}>
                <td>{item.orderNumber}</td>
                <td>{item.deliveryNumber}</td>
                <td>{item.materialList.length}</td>
                <td>{moment.unix(item.inspectionDate).format("DD/MM/YYYY")}</td>
                <td>
                    <button type="button" id={"materialBtn-" + item.certificateId} value={item.certificateId} onClick={clickCallback} className="btn btn-primary">{globalMessages[localStorage.getItem('language')].showCertificate.btnShowPositions}</button>
                </td>
                <td>
                    <button type="button" data-toggle="modal" data-target="#modal" name="showCertificateBtn" value={(item.certificateId, item.currentVersion)} onClick={this.showCertificate.bind(this, item.certificateId, item.currentVersion)} className="btn btn-primary"><FormattedMessage id="showCertificate.formTableButtonShowCertificate" /></button>
                </td>
            </tr >
        ];

        if (this.state.expandedRows.includes(item.certificateId)) {
            itemRows.push(
                <tr className="table-dark" key={"row-item-title" + item.certificateId + moment.unix(item.inspectionDate).format("DDMMYYYYhh:mm:ssa")}>
                    <td style={{ borderTopWidth: 0 }}>{globalMessages[localStorage.getItem('language')].showCertificate.modalOrderPosition}</td>
                    <td style={{ borderTopWidth: 0 }} colSpan="2">{globalMessages[localStorage.getItem('language')].showCertificate.modalMaterialNumber}</td>
                    <td style={{ borderTopWidth: 0 }} colSpan="2">{globalMessages[localStorage.getItem('language')].showCertificate.modalMaterialName}</td>
                </tr>
            );
            item.materialList.forEach((material) => {
                itemRows.push(
                    <tr key={"row-item-" + rowItemkey}>
                        <td>{material.orderPosition}</td>
                        <td colSpan="2" style={{ wordWrap: 'break-word', minWidth: 100, maxWidth: 160 }}>{material.materialNumber}</td>
                        <td colSpan="2" style={{ wordWrap: 'break-word', minWidth: 100, maxWidth: 160 }}>{material.materialDescription}</td>
                    </tr>
                );
                rowItemkey++;
            });
        }

        return itemRows;
    };

    render = () => {
        let allItemRows = [];

        this.state.certificateList.forEach(item => {
            const perItemRows = this.renderItem(item);
            allItemRows = allItemRows.concat(perItemRows);
        });

        // 3712345678


        // this.state.data.forEach(item => {
        //     const perItemRows = this.renderItem(item);
        //     allItemRows = allItemRows.concat(perItemRows);
        // });
        return (
            <div>
                <NavBar />
                <FormErrors formErrors={this.state.formErrors} />
                {this.state.isSiemensOrSupplier &&
                    <div className="container col-xl-6 col-lg-9 col-md-12 col-sm-12">
                        {/* <form onSubmit={this.handleSubmit}>     --> not needed here and would prevent that "handleKeyUp"-event is executed/handled!*/}
                        <h2><FormattedMessage id="showCertificate.formTitle" /></h2>
                        <div className="card" style={{ padding: 20, marginTop: 30 }}>
                            <div className="form-row">
                                <div className="form-group col-md-9 col-sm-12">
                                    <label htmlFor="orderNumberInput"><FormattedMessage id="showCertificate.formOrderNumber" /></label>
                                    <input name="orderNumber" type="text" onChange={this.handleChange} onKeyUp={this.handleKeyUp} className="form-control" id="orderNumberInput" placeholder={globalMessages[localStorage.getItem('language')].showCertificate.formOrderNumberPlaceHolder} />
                                    <div className="invalid-feedback"><FormattedMessage id="showCertificate.formOrderNumber" /> {this.state.formErrors.orderNumber}</div>
                                </div>
                                <div className="form-group col-md-3 col-sm-12 d-flex justify-content-center" style={{ marginTop: 31 }}>
                                    <button type="button" onClick={this.getCertificates} className="btn btn-primary" style={{ whiteSpace: 'normal' }}><FormattedMessage id="showCertificate.formShowCertificatesBtn" /></button>
                                </div>
                            </div>
                        </div>

                        <div className="card" style={{ padding: 20, marginTop: 30 }}>
                            <div className="table-responsive">
                                <table className="table table-hover" style={{ marginTop: 20 }}>
                                    <thead>
                                        <tr>
                                            <th scope="col"><FormattedMessage id="showCertificate.formTableOrderNumberTh" /></th>
                                            <th scope="col"><FormattedMessage id="showCertificate.formTableDeliveryNumberTh" /></th>
                                            <th scope="col"><FormattedMessage id="showCertificate.formTableMaterialQtyTh" /></th>
                                            <th scope="col"><FormattedMessage id="showCertificate.formTableCreationDteTh" /></th>
                                            <th scope="col" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allItemRows}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* </form> */}
                        <div className="alert alert-danger" role="alert" hidden={!this.state.decryptError}>
                            <FormattedMessage id="showCertificate.errorDecryption" />
                        </div>
                    </div>
                }
                <div>
                    <LoadingComponent hidden={this.state.showLoadingModal} />
                </div>
                <div>
                    <Modal animation={false} show={this.state.showModal}>
                        <ModalHeader>
                            <Modal.Title><FormattedMessage id="showCertificate.modalTitle" /></Modal.Title>
                        </ModalHeader>
                        <Modal.Body>
                            <div className="card" style={{ padding: 20, marginTop: 5 }} id="pdfSupplierConsigneeONumberId">
                                <div className="form-row">
                                    <div className="form-group col-md-7 col-sm-12">
                                        <h4><FormattedMessage id="showCertificate.modalSupplier" /></h4>
                                        <p style={{ marginTop: 20 }} id="pdfSupplierInfo">
                                            {this.state.supplierData.name}<br />
                                            {this.state.supplierData.street}<br />
                                            {this.state.supplierData.zip}&nbsp;{this.state.supplierData.city}<br />
                                            {this.state.supplierData.country}<br />

                                        </p>
                                    </div>
                                    <div className="form-group col-md-5 col-sm-12">
                                        <h4><FormattedMessage id="showCertificate.modalConsignee" /></h4>
                                        <p style={{ marginTop: 20 }} id="pdfConsigneeInfo">
                                            {this.state.consigneeData.name}<br />
                                            {this.state.consigneeData.street}<br />
                                            {this.state.consigneeData.zip}&nbsp;{this.state.consigneeData.city}<br />
                                            {this.state.consigneeData.country}
                                        </p>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-7 col-sm-12">
                                        <label htmlFor="orderNumberInput"><h4><FormattedMessage id="showCertificate.modalOrderNumber" /></h4></label>
                                        <input name="orderNumber" value={this.state.orderNumber} type="text" className="form-control-plaintext" id="orderNumberInput" readOnly />
                                    </div>
                                    <div className="form-group col-md-5 col-sm-12">
                                        <label htmlFor="deliveryNumberInput"><h4><FormattedMessage id="showCertificate.modalDeliveryNumber" /></h4></label>
                                        <input name="deliveryNumber" value={this.state.deliveryNumber} type="text" className="form-control-plaintext" id="deliveryNumberInput" readOnly />
                                    </div>
                                    <div className="form-group col-md-7 col-sm-12">
                                        <label htmlFor="linkURLLabel"><h4>URL</h4></label>
                                        <input name="linkurl" value={window.location.origin + "/certNumber/" + this.state.orderNumber + "/" + this.state.orderPosition + "/" + this.state.deliveryNumber} type="text" className="form-control-plaintext" id="urloutput" readOnly />
                                    </div>
                                </div>
                            </div>

                            <div className="card" style={{ padding: 20, marginTop: 30 }}>
                                <div className="table-responsive">
                                    <table className="table" id="materialListTableId">
                                        <thead id="pdfHeadMaterialId">
                                            <tr>
                                                <th scope="col"><FormattedMessage id="showCertificate.modalOrderPosition" /></th>
                                                <th scope="col"><FormattedMessage id="showCertificate.modalQuantity" /></th>
                                                <th scope="col"><FormattedMessage id="showCertificate.modalMaterialNumber" /></th>
                                                <th scope="col"><FormattedMessage id="showCertificate.modalMaterialName" /></th>
                                                <th scope="col"><FormattedMessage id="showCertificate.modalSerialNumber" /></th>
                                                <th scope="col"><FormattedMessage id="showCertificate.modalOverviewTests" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.materialList.map((material, index) => {
                                                return (
                                                    <tr key={index} className="pdfItemMaterialList">
                                                        <td>{material.orderPosition}</td>
                                                        <td>{material.quantity}</td>
                                                        <td>{material.materialNumber}</td>
                                                        <td>{material.materialDescription}</td>
                                                        <td>{material.serialNumber}</td>
                                                        <td>{material.overviewTests}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="card" style={{ padding: 20, marginTop: 30 }} id="pdfFooterId">
                                <div className="form-row">
                                    <div className="form-group col-lg-10 col-md-8 col-sm-12">
                                        <label htmlFor="nameInspectorInput"><h6><FormattedMessage id="showCertificate.modalInspectorName" /></h6></label>
                                        <input name="nameInspector" value={this.state.nameInspector} type="text" className="form-control-plaintext" id="nameInspectorInput" readOnly />
                                    </div>
                                    <div className="form-group col-lg-2 col-md-4 col-sm-12">
                                        <label htmlFor="inspectionDateInput"><h6><FormattedMessage id="showCertificate.modalInspectionDate" /></h6></label>
                                        <input name="inspectionDate" value={moment.unix(this.state.inspectionDate).format("DD/MM/YYYY")} className="form-control-plaintext" id="inspectionDateInput" readOnly />
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="form-row" style={{ marginTop: 20 }}>
                                        <div className="form-group col-12 d-flex align-items-center justify-content-center">
                                            <h4><FormattedMessage id="showCertificate.modalNoteTestsResultsTitle" /></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="card" style={{ marginTop: 10 }}>
                                    <div className="form-row" style={{ marginTop: 20 }}>
                                        <div className="form-group col-10 d-flex align-items-center justify-content-center">
                                            <div className="text-justify" style={{ paddingLeft: 30 }} id="noteTestsText">
                                                <FormattedMessage id="showCertificate.modalNoteTestsResultsText" />
                                            </div>
                                        </div>
                                        <div className="form-group col-2 d-flex align-items-center justify-content-center">
                                            <input type="checkbox" disabled="disabled" checked={this.state.confirmation} name="noteTestResults" className="form-control-plaintext" id="noteTestResultsCheckBox" style={{ width: 20, height: 20 }} readOnly />
                                        </div>
                                    </div>
                                </div>
                                <div className="card" style={{ marginTop: 10 }}>
                                    <div className="form-row" style={{ marginTop: 25 }}>
                                        <div className="form-group col-12 d-flex align-items-center justify-content-center">
                                            <h4><FormattedMessage id="showCertificate.modalConfirmationTitle" /></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="card" style={{ marginTop: 10 }}>
                                    <div className="form-row" style={{ marginTop: 25 }}>
                                        <div className="form-group col-md-10 col-sm-12 d-flex align-items-center justify-content-center">
                                            <div className="text-justify" style={{ paddingLeft: 20, paddingRight: 20 }} id="confirmationText">
                                                <FormattedMessage id="showCertificate.modalConfirmationText" />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-2 col-sm-12  d-flex align-items-center justify-content-center">
                                            <input type="checkbox" name="confirmation" disabled="disabled" checked={this.state.confirmation} className="form-control-plaintext" id="confirmationCheckBox" style={{ width: 20, height: 20 }} readOnly />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {isSupplier &&
                                <LinkContainer to={{ pathname: "/create-certificate", state: this.state }}>
                                    <Button className="btn btn-warning"><FormattedMessage id="showCertificate.btnEditCert" /></Button>
                                </LinkContainer>
                            }
                            <Button className="btn btn-info" onClick={this.generatePDF}><FormattedMessage id="showCertificate.modalGeneratePDFBtn" /></Button>
                            <Button onClick={this.handleClose}><FormattedMessage id="showCertificate.modalCloseBtn" /></Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <Footer />
            </div >
        );
    }
}

export default ShowCertificatesContainer
