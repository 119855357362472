import React from 'react';
let user;

export class UserService extends React.Component {

    constructor(props) {
        super(props);

        if (user == null) {
            //
            user = localStorage.getItem('oidc_user') !== null ? localStorage.getItem('oidc_user') : null;


            //
            // if (localStorage.getItem('siemensInfo')) {
            //     this.setAdmin();
            // }
            // else {
            //     if (localStorage.getItem('userInfo')) {
            //         this.setSupplier();
            //     } else {
            //         this.setNewUser();
            //     }
            // }
        }
    }

    getUser() {
        return user;
    }

    getUserRole() {
        return user === null ? 'none' : user.role;
    }

    // setAdmin() {
    //     user = {
    //         role: 'admin',
    //         encryptedInfo: localStorage.getItem('userInfo')
    //     }

    // }

    // setSupplier() {
    //     user = {
    //         role: 'supplier',
    //         encryptedInfo: localStorage.getItem('userInfo')
    //     }
    // }

    // setNewUser() {
    //     user = {
    //         role: 'newUser',
    //         pk: null
    //     }
    // }

}

export default UserService
