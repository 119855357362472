import React from 'react';
import LoadingComponent from '../components/LoadingComponent';
import { Modal, Button, ModalHeader } from 'react-bootstrap';
import { ApiService } from '../services/ApiService';
import NavBar from '../components/NavBar';
import Badge from 'react-bootstrap/Badge';

import 'react-datepicker/dist/react-datepicker.css';

import Footer from '../components/Footer';

export class RetrievePendingCompaniesContainer extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            isSiemensAdmin: false,
            supplierListId: [],
            supplierListData: [],
            usersList: [],
            siemensListId: [],
            siemensListData: [],
            showModal: false,
            showLoadingModal: false,
            showSiemensModal: false,
            approveIndex: '',
            selectedCompany: '', 
            formErrors: {
                supplierId: '',
            }, 
            formValid: false, 
            supplierId: '',
        };
    }

    async componentDidMount() {
        this.getRole();
        this.setState({ showLoadingModal: true });
        //Get pending users
        let usersList = await this.getPendingUsers();
        this.setState({ 
            usersList: usersList.data 
        });

        this.setState({
            showLoadingModal: false
        });
    }

    getRole = () => {

        let user = this.getUser();

        if (user.role === 'userAdmin') {
            this.setState({ isSiemensAdmin: true });
            return 'userAdmin';
        }
    }

    getUser = () => {
        const userData = localStorage.getItem('oidc_user');
        if(!userData) {
            return null;
        }

        const user = JSON.parse(userData);

        // check if expired
        if(user.expires_at * 1000 < Date.now()) {
            //expired
            localStorage.removeItem('oidc_user');
            return null;
        }

        return user;
    };

    getPendingUsers = async () => {
        const certblockAPI = new ApiService();
        const user = this.getUser();
        const IAM_API = certblockAPI.getIAM_API(user.id_token);
        return IAM_API.usersPending();
    };

    getCompanyListData = async () => {
        this.setState({ showLoadingModal: true });
        let supplierListId = this.state.supplierListId;
        let supplierListData = this.state.supplierListData;

        if (supplierListId.length != 0) {
            for (const id of supplierListId) {
                const data = await this.getCompany(id);
                data.address = id;
                supplierListData.push(data);
            };

            this.setState({
                supplierListData: supplierListData
            });

        }

    }

    showMessage = (message) => {
        alert(message);
    }

    handleApproveSupplier = async () => {
        this.setState({ 
            showLoadingModal: true, 
            showModal: false 
        });
        const certblockAPI = new ApiService();
        const user = this.getUser();
        
        let promises = [];
        
        const IAM_API = certblockAPI.getIAM_API(user.id_token);

        promises.push(IAM_API.userApprove(this.state.selectedCompany.id));
        promises.push(IAM_API.userUpdateSupplier(this.state.selectedCompany.id, { supplierId: this.state.supplierId}));

        Promise.all(promises)
        .then((values) => {
            console.log(`Supplier Activated}`)
        })
        .catch((error) => {
            this.setState({ 
                showModal: false, 
                showLoadingModal: false }, 
                this.showMessage("Supplier " + this.state.supplierListData[this.state.approveIndex].name + " couldn't be approved. Please try again later or contact the administrator."))
        })
        .finally(() => {
            this.setState({ 
                showModal: false, 
                showLoadingModal: false 
            });
            window.location.reload();
        });

    }

    handleDeclineSupplier = () => {
        this.setState({ 
            showLoadingModal: true, 
            showModal: false 
        });
        const certblockAPI = new ApiService();
        const user = this.getUser();

        const IAM_API = certblockAPI.getIAM_API(user.id_token);

        IAM_API.userDecline(this.state.selectedCompany.id)
        .then((values) => {
            this.state.supplierListData.splice(this.state.approveIndex, 1);
            this.setState({ 
                supplierListData: this.state.supplierListData
            });
        })
        .catch((error) => {
            this.setState({ 
                showModal: false, 
                showLoadingModal: false 
            }, this.showMessage("Supplier " + this.state.supplierListData[this.state.approveIndex].name + " couldn't be declined. Please try again later or contact the administrator."))
        })
        .finally(() => {
            this.setState({ 
                showModal: false, 
                showLoadingModal: false 
            });
            window.location.reload();
        });
    }

    handleApproveSiemens = () => {

        this.setState({ 
            showLoadingModal: true, 
            showModal: false 
        });
        const certblockAPI = new ApiService();
        const user = this.getUser();

        const IAM_API = certblockAPI.getIAM_API(user.id_token);

        IAM_API.userApprove(this.state.selectedCompany.id)
        .then((values) => {
            this.state.supplierListData.splice(this.state.approveIndex, 1);
            this.setState({ 
                supplierListData: this.state.supplierListData
            });
        })
        .catch((error) => {
            this.setState({ 
                showModal: false, 
                showLoadingModal: false 
            }, this.showMessage("Supplier " + this.state.supplierListData[this.state.approveIndex].name + " couldn't be approved. Please try again later or contact the administrator."))
        })
        .finally(() => {
            this.setState({ 
                showModal: false, 
                showLoadingModal: false 
            });
            window.location.reload();
        });

    }

    handleDeclineSiemens = () => {
        this.setState({ 
            showLoadingModal: true, 
            showModal: false 
        });
        const certblockAPI = new ApiService();
        const user = this.getUser();

        const IAM_API = certblockAPI.getIAM_API(user.id_token);

        IAM_API.userDecline(this.state.selectedCompany.id)
        .then((values) => {
            this.state.supplierListData.splice(this.state.approveIndex, 1);
            this.setState({ 
                supplierListData: this.state.supplierListData
            });
        })
        .catch((error) => {
            this.setState({ 
                showModal: false, 
                showLoadingModal: false 
            }, this.showMessage("Supplier " + this.state.supplierListData[this.state.approveIndex].name + " couldn't be declined. Please try again later or contact the administrator."))
        })
        .finally(() => {
            this.setState({ 
                showModal: false, 
                showLoadingModal: false 
            });
            window.location.reload();
        });
    }

    handleCancelApproval = () => {
        this.setState({
            showModal: false,
            showSiemensModal: false
        })
    }

    handleShowModal = (e) => {
        this.setState({
            approveIndex: e,
            selectedCompany: this.state.usersList[e],
            showModal: true
        });
    }

    handleShowSiemensModal = (e) => {

        console.log(this.state.siemensListData[e])

        this.setState({
            approveIndex: e,
            selectedCompany: this.state.siemensListData[e],
            showSiemensModal: true
        });
    }

    cancelLoadingModal(supplierListData, countData) {
        supplierListData.length === countData ? this.setState({ showLoadingModal: false }) : this.setState({ showLoadingModal: true });
    }

    errorClass = (error) => {
        return (error.length === 0 ? '' : 'is-invalid');
    };

    handleChange = (e) => {
    
        let name;
        let value;
        
        if (e != null) {

            name = e.target.name;
            value = e.target.value;
            
            switch (name) {
                case "supplierId": {
                    this.setState({ supplierId: value }, () => { this.validateForm(name, value) });
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    validateForm() {
        this.setState({
            formValid: this.state.supplierId.match(/^\d{10}$/) 
        });
    }

    render() {
        const btnStyle = {
            padding: '2px 10px'
        }
        const btnCellStyle = {
            width: '300px'
        }

        return (
            <div>
                <NavBar />
                {this.state.isSiemensAdmin &&
                    <div className="container col-xl-6 col-lg-9 col-md-12 col-sm-12">
                        <h2>Pending Users</h2>
                        <div className="card" style={{ padding: 20, marginTop: 30 }}>
                            <h3>Siemens & Suppliers</h3>
                            <div className="table-responsive">
                                <div className="card" id="materialCard" style={{ padding: 20, marginTop: 30 }}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Name</th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.usersList.map((user, index) => {
                                                return (
                                                    <tr key={index + user.name}>
                                                        <td scope="row">{index + 1}</td>
                                                        <td>{user.name} <Badge pill bg={user.role === 'userSupplier' ? 'secondary' : 'primary'}>{user.role === 'userSupplier' ? 'Supplier' : 'Siemens'}</Badge></td>
                                                        <td style={btnCellStyle}>
                                                            <button type="button" value={index} onClick={() => this.handleShowModal(index)} id={`info-` + index} className="btn btn-primary" style={btnStyle} disabled={false}>
                                                                Show approval request
                                                        </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Modal animation={false} show={this.state.showModal}>
                                <ModalHeader>
                                    <Modal.Title>{this.state.selectedCompany.role === 'userSupplier' ? 'Supplier' : 'Siemens'} approval</Modal.Title>
                                </ModalHeader>
                                <Modal.Body>
                                    <div className="card" style={{ padding: 20, marginTop: 5 }}>
                                        <div className="form-group">
                                            {this.state.selectedCompany.name}<br />
                                            {this.state.selectedCompany.street}<br />
                                            {this.state.selectedCompany.zip}<br />
                                            {this.state.selectedCompany.city}<br />
                                            {this.state.selectedCompany.country}<br />
                                            {this.state.selectedCompany.eMailAddress}
                                        </div>
                                    
                                    <div className="form-group">
                                        <label htmlFor="supplierIdInput">Supplier ID*</label>
                                        <input name="supplierId" type="text" value={this.state.supplierId} onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.supplierId)}`} id="supplierIdInput" placeholder='Supplier ID number'/>
                                        <div className="invalid-feedback">Incorrect supplier ID{this.state.formErrors.supplierId}</div>
                                    </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn btn-info" onClick={this.handleCancelApproval}>Cancel</Button>
                                    <Button className="btn btn-warning" onClick={this.handleDeclineSupplier}>Decline</Button>
                                    <Button className="btn btn-primary" onClick={this.handleApproveSupplier} disabled={!this.state.formValid}>Approve</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        <div>
                            <Modal animation={false} show={this.state.showSiemensModal}>
                                <ModalHeader>
                                    <Modal.Title>Siemens approval</Modal.Title>
                                </ModalHeader>
                                <Modal.Body>
                                    <div className="card" style={{ padding: 20, marginTop: 5 }}>
                                        <div className="form-group">
                                            {this.state.selectedCompany.name}<br />
                                            {this.state.selectedCompany.street}<br />
                                            {this.state.selectedCompany.zip}<br />
                                            {this.state.selectedCompany.city}<br />
                                            {this.state.selectedCompany.country} <br />
                                            {this.state.selectedCompany.eMailAddress}
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn btn-info" onClick={this.handleCancelApproval}>Cancel</Button>
                                    <Button className="btn btn-warning" onClick={this.handleDeclineSiemens}>Decline</Button>
                                    <Button className="btn btn-primary" onClick={this.handleApproveSiemens}>Approve</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        <LoadingComponent hidden={this.state.showLoadingModal} />
                    </div>
                }
                <Footer />
            </div>
        );
    }
}

export default RetrievePendingCompaniesContainer
