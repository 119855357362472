import React, { Component} from 'react';
import { Navigate } from 'react-router-dom';
import { UserManager, Log } from 'oidc-client';
import { ApiService } from '../services/ApiService';

export default class OidcProvider extends Component {
        constructor(props) {
            super(props);
            this.config = window.AuthConfig;
            Log.logger = console;

            this.userManager = new UserManager({
                authority: 'https://' + this.config.authAuthority,
                client_id: this.config.authClientId,
                redirect_uri: window.location.origin + this.config.authRedirect,
                response_type: 'token id_token',
                loadUserInfo: true,
                scope: 'openid profile email',
                extraQueryParams: {
                    connection: "main-tenant-oidc",
                    display: 'sup'
                }
            });

            this.userManagerLogout = new UserManager({
                authority: 'https://' + this.config.authAuthority,
                client_id: this.config.authClientId,
                redirect_uri: window.location.origin + this.config.authRedirect,
                response_type: 'token id_token',
                loadUserInfo: true,
                scope: 'openid profile email',
                revokeAccessTokenOnSignout: true, 
                extraQueryParams: {
                    connection: "main-tenant-oidc",
                    display: "sup"
                }, 
                metadata: {
                    end_session_endpoint: `https://${this.config.authUrl}/v2/logout?returnTo=https://${this.config.authAuthority}/v2/logout?returnTo=${window.location.origin}`
                }
            }); 

            this.userManager.events.addUserLoaded(this.onUserLoad);
            this.state = {
                user: null,
                status: 'loggedout'
            }
        }

        getUniqueUserId = () => {
            const user = this.getUser();
            return user.profile[this.config.authUniqueUserKey];
        }

        getUser = () => {
            const userData = localStorage.getItem('oidc_user');
            if(!userData) {
                return null;
            }

            const user = JSON.parse(userData);

            // check if expired
            if(user.expires_at * 1000 < Date.now()) {
                //expired
                localStorage.removeItem('oidc_user');
                return null;
            }

            return user;
        };

        onUserLoad = (user) => {
            localStorage.setItem('oidc_user',JSON.stringify(user));

            // localstorage seems to be not 100% synchronous. So we need to wait a bit (maybe at least for the next event loop) so other components can access it.
            setTimeout(() => {
                this.setState({
                    user: user,
                    status: 'loggedin'
                });
                this.forceUpdate();
            },250);

        };

        signIn = () => {
            this.userManager.signinRedirect({state:window.location.pathname}).catch(function (err) {
            console.log(err)
            })
        };

        signOut = () => {
            this.userManagerLogout.signoutRedirect().catch(function (err) {
                console.log(err)
            });
        }

        componentDidMount() {            

            const certblockAPI = new ApiService();

            if(window.location.pathname === this.config.authRedirect) {
                this.userManager.signinRedirectCallback()
                .then((user) => {
                    //
                    let userId = ''; 

                    if (user.profile["sub"].includes("Siemens")) {
                        userId = (user.profile["sub"].split("|")[4]).replace(/\s+/g, " ").trim();
                    } else {
                        userId = (user.profile["sub"].split("|")[3]).replace(/\s+/g, " ").trim();
                    }

                    const IAM_API = certblockAPI.getIAM_API(user.id_token);
                    IAM_API.userCurrent()
                        .then((certblockUser) => {
                            console.log(`DEBUG: [user] ${JSON.stringify(certblockUser)}`);
                            user = {
                                ...user,
                                created: true,  
                                approved: certblockUser.data.approved, 
                                role: certblockUser.data.role, 
                                name: certblockUser.data.name, 
                                zip: certblockUser.data.zip, 
                                street: certblockUser.data.street, 
                                country: certblockUser.data.country,
                                city: certblockUser.data.city
                            };

                            this.setState({
                                ...this.state,
                                user:user,
                                status: 'loggedin'
                            });
                            localStorage.setItem('oidc_user',JSON.stringify(user));
                            if(user.state.length > 1) {
                                window.location.href = user.state;
                            }
                            this.props.setUserLoad(true);
                            this.forceUpdate();
                        }).catch((error) => {
                            console.log(`DEBUG: [errorLogin] ${error}`);
                            user = {
                                ...user,
                                created: false,  
                                approved: false, 
                                role: 'newUser'
                            };
                            this.setState({
                                ...this.state,
                                user:user,
                                status: 'loggedin'
                            });
                            localStorage.setItem('oidc_user',JSON.stringify(user));
                            if(user.state.length > 1) {
                                window.location.href = user.state;
                            }
                            this.props.setUserLoad(true);
                            this.forceUpdate();
                        }) ;
                })
                .catch((error) => {
                    console.error("Unable to process callback:", error);
                    this.setState({
                        ...this.state,
                        status: 'failed'
                    });
                });
            } else {
                const storedUser = this.getUser();
                if(storedUser) {
                    this.setState({
                        ...this.state,
                        user:storedUser,
                        status: 'loggedin'
                    });
                } else { 
                    const logout = localStorage.getItem('logout');
                    localStorage.removeItem('logout');

                    if (logout === 'true') {
                        this.signOut();
                    } else {
                        this.signIn();
                    }
                   
                }
		
            }
        }

        render() {

            if(this.state.user) {
                if(window.location.pathname === this.config.authRedirect && this.state.status === "loggedin") {
                    return <Navigate to='/'/>;
                } else if(window.location.pathname === this.config.authRedirect) {
                    return <div>Login in progress...</div>;
                } else {
                    return this.props.children;
                }
            } else if(this.state.status === 'failed') {
                // TODO: Replace with proper auth fail component
                return <div>Authentication failed</div>;
            }
            else {
                // TODO: Replace with proper auth login button
                //return <AuthComponent onClick={this.signIn} authName={this.config.authName}/>
                return (
			        <div>
			        </div>
		        ) 

            }
        }
    }

