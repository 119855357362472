import * as React from 'react';
import NavBar from '../components/NavBar';
import 'bootstrap/dist/css/bootstrap.css';
import BigButtonsComponent from '../components/BigButtonsComponent';
import Footer from '../components/Footer';

const user = localStorage.getItem('oidc_user');

class AppContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            role: ''
        }

    }

    componentDidMount() {
        this.getRole()
    }

    getRole = () => {


        const user = JSON.parse(localStorage.getItem('oidc_user'));

        if (user.approved) {
            switch (user.role) {
                case 'userSiemens':
                    this.setState({ role: 'isSupplierOrSiemens' })
                    break; 
                case 'userSupplier':
                    this.setState({ role: 'isSupplier' })
                    break; 
                case 'userAdmin':
                    this.setState({ role: 'isSiemensAdmin' })
                    break; 
            }
        }
    }

    render() {

        const oNr = sessionStorage.getItem("orderNumber")
        const oPos = sessionStorage.getItem("orderPosition")
        const dBr = sessionStorage.getItem("deliveryNumber")

        if (oNr && oPos && dBr) {
            console.log("orNr: " + oNr)
            console.log("oPos: " + oPos)
            console.log("dBr: " + dBr)

            this.props.navigation.navigate('/show-certificate', {replace: true})

        }

        var isRootLocation = false;
        if (window.location.pathname === '/'){
            isRootLocation = true;
        }

        let languageText = localStorage.getItem('language') === "en-US" ? 'English (US)' : 'Deutsch (DE)';
        var messageText = (languageText === "English (US)") ?  'Dear suppliers, welcome to CertBlock 3.0 You will now find your orders and order details in the application. If the information is not or incorrectly displayed, please contact us at sourcing-logistics.mobility@siemens.com' 
                : 'Liebe Lieferanten, willkommen bei CertBlock 3.0. Sie finden nun Ihre Bestellungen und Bestelldetails in der Anwendung. Falls die Informationen nicht oder falsch angezeigt werden, melden Sie sich bitte unter sourcing-logistics.mobility@siemens.com';
 
        return (
            <div>
                <NavBar />
                <BigButtonsComponent role={this.state.role !== null ? this.state.role : ''} />
                <div style={{ maxWidth: 900, margin: '20px auto', border: '0px' }} >
                    <div className="d-flex justify-content-center">
                        
                        {/* Message Display */}
                        {/* <div className="alert alert-warning" role="alert" id="orderPositionAlert" style={{ width: '550px', whiteSpace: 'pre-wrap' }} >
                                {messageText}
                        </div>  */}
                    </div>
                </div>   
                {isRootLocation ? <Footer /> : <div /> }
                

            </div >
        );
    }
}

export default AppContainer;
