exports.GetMaterials = () => {

    const materials = {
		A2V00201316106 : {
			de: "Rippe",
			en: "rib"
		}
		,A2V00201170755 : {
			de: "Crash-Element",
			en: "crash-element"
		}
		,A2V00000378718 : {
			de: "WIG 16P, Geraetedose/Kupplung ohne",
			en: "Odometer pulse generator"
		}
		,A2V00201175400 : {
			de: "Crash-Element",
			en: "crash-element"
		}
		,A2V00370048318 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00207471057 : {
			de: "Crash-Element",
			en: "Crash element"
		}
		,A2V00207471056 : {
			de: "Crash-Element",
			en: "Crash element"
		}
		,A2V00001106092 : {
			de: "Tachogenerator/Geschwindigkeitsgeber",
			en: "Tachometer generator/speed probe"
		}
		,A2V00001748199 : {
			de: "Drehmomentenstuetze",
			en: "Dog bone"
		}
		,A2V00001823480 : {
			de: "BK3 Regler fuer aktiven Drehdaempfer",
			en: "BK3 regulator for active yaw damper"
		}
		,A2V00001098779 : {
			de: "Haupttransformator EN60310",
			en: "Main transformer EN60310"
		}
		,A2V00001191371 : {
			de: "Motor f. Kaohsiung 1TB2010-1GA02",
			en: "Motor f. Kaohsiung 1TB2010-1GA02"
		}
		,A2V00001907018 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001141870 : {
			de: "Halter fÃ¼r Indusimagnet ET401",
			en: "Holder f. INDUSI-magnet ET401"
		}
		,A2V00370051582 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001158252 : {
			de: "Tuerfluegel vollst., Doppeltuer rechts",
			en: "door leaves compl, left incl push button"
		}
		,A2V00001158269 : {
			de: "Tuerfluegel, vollst. rechts, o.Taster",
			en: "Door leave compl, without pushbutton"
		}
		,A2V00001158290 : {
			de: "TÃ¼rflÃ¼gel, vollst.",
			en: "leaf complete"
		}
		,A2V00207352627 : {
			de: "LZB-Sendeantenne 56kHz",
			en: "LZB transmitting aerial 56kHz"
		}
		,A2V00207354550 : {
			de: "Stromabnehmer 25KV Isolierhorn",
			en: "Pantograph 25KV isolating horn"
		}
		,A2V00001094946 : {
			de: "Sonderschraube M16 x 330",
			en: "Special screw M16 x 330"
		}
		,A2V00001870147 : {
			de: "ASIG Verstaerker mit Box",
			en: "ASIG amplifier module with box"
		}
		,A2V00114252616 : {
			de: "Schuerzenklappe komplett WGT.1/2",
			en: "Side flap arrangement car 1/2"
		}
		,A2V00114252623 : {
			de: "Schuerzenklappe komplett WGT.1/2",
			en: "Side flap arrangement car 1/2"
		}
		,A2V00114252647 : {
			de: "Schuerzenklappe komplett WGT.3/5",
			en: "Side flap aragement car 3/5"
		}
		,A2V00207472631 : {
			de: "Scharblech geschweisst",
			en: "Eaves flashing, welded"
		}
		,A2V00113043192 : {
			de: "OPTIMOL           OPTIMOLY",
			en: "OPTIMOL           OPTIMOLY"
		}
		,A2V00370026882 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00001870137 : {
			de: "DMI-Display 8 Zoll",
			en: "DMI Unit 8 inch"
		}
		,A2V00001362440 : {
			de: "Eckverbinder aussen rechts",
			en: "Connector outside right"
		}
		,A2V00001362442 : {
			de: "Eckverbinder aussen links",
			en: "Corner connector   outside"
		}
		,A2V00001870155 : {
			de: "Cubicle Typ 1",
			en: "Cubicle type 1"
		}
		,A2V00001870156 : {
			de: "Cubicle Typ 2",
			en: "Cubicle type 2"
		}
		,A2V00001870157 : {
			de: "Cubicle Typ 3",
			en: "Cubicle type 3"
		}
		,A2V00002339841 : {
			de: "Anhebepratze",
			en: "Hoist plug"
		}
		,A2V00001169514 : {
			de: "Sperrkantring",
			en: "Spring washer"
		}
		,A2V00207473095 : {
			de: "Schneeraeumer,geschw.",
			en: "Cow catcher welded"
		}
		,A2V00201172200 : {
			de: "Halter vollst.",
			en: "Halter vollst."
		}
		,A2V00001191370 : {
			de: "Motor f. Kaohsiung 1TB2010-1GA02",
			en: "Tractionmotor f. Kaohsiung 1TB2010-1GA02"
		}
		,A2V00001998187 : {
			de: "Tatzlagerantrieb 2KF2319-4EA00",
			en: "Drive unit 2KF2319-4EA00"
		}
		,A2V00002554293 : {
			de: "Bremssteuergeraet HEY 2P/4G",
			en: "Brake controlling unit HEY 2P/4G"
		}
		,A2V00002570881 : {
			de: "Verzurrlasche ZSB",
			en: "Lashing support ASSY"
		}
		,A2V00001167856 : {
			de: "Sechskantschraube ISO4017 A2 M16x45",
			en: "Sechskantschraube ISO4017 A2 M16x45"
		}
		,A2V00113034275 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00109373578 : {
			de: "SEITENSCHEIBE,LINKS",
			en: "Side window, left"
		}
		,A2V00112519001 : {
			de: "DRUCKLUFTGERUEST VT 642.0",
			en: "air pressure frame"
		}
		,A2V00000379036 : {
			de: "Zugkoppelspule 50/100/850kHz",
			en: "Vehicle coupling coil 50/100/850kHz"
		}
		,A2V00001192751 : {
			de: "Sechskantschraube ISO4017 8.8VG M16x45",
			en: "Sechskantschraube ISO4017 8.8VG M16x45"
		}
		,A2V00001814529 : {
			de: "Fahrmotor 1TB2624-0GA02",
			en: "Traction motor 1TB2624-0GA02"
		}
		,A2V00001936942 : {
			de: "HBU-Transformator T-ABC",
			en: "Aux. conv. transformer T-ABC"
		}
		,A2V00001147867 : {
			de: "Schnellsenkventil komplett",
			en: "Rapid lowering valve complete"
		}
		,A2V00002691927 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001987407 : {
			de: "Ausdrehanschlag w.g.",
			en: "Arrester a.d."
		}
		,A2V00002124727 : {
			de: "Radsatzlager",
			en: "Wheelset bearing"
		}
		,A2V00002128388 : {
			de: "Ausdrehanschlag sp.g.",
			en: "Arrester i.m.i."
		}
		,A2V00001399355 : {
			de: "Getriebe",
			en: "Gearbox"
		}
		,A2V00200675518 : {
			de: "Vierkantrohr",
			en: "Square pipe"
		}
		,A2V00200677187 : {
			de: "Hohlprofil",
			en: "Hollow profile"
		}
		,A2V00207421802 : {
			de: "Zugeinrichtung Typ 450",
			en: "train equipment"
		}
		,A2V00001421077 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00001684255 : {
			de: "Radsatzlagerdeckel, hinten",
			en: "wheelset back cover"
		}
		,A2V00001687990 : {
			de: "Druckkappe",
			en: "end cap"
		}
		,A2V00001699867 : {
			de: "Montagematerial hint. RSL-Deckel",
			en: "mounting material wheelset box housing"
		}
		,A2V00001847987 : {
			de: "AC Freiluft-Oberspannungswandler 1 Sek",
			en: "Outdoor voltage transformer AC 1 sec"
		}
		,A2V00370048207 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370048319 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00397284183 : {
			de: "Lenker, vollst.",
			en: "Guide bar,complete"
		}
		,A2V00109385494 : {
			de: "SEITENSCHEIBE, PLAN",
			en: "Side windows, plan"
		}
		,A2V00001168865 : {
			de: "Oberarme komplett",
			en: "Upper arms cpl."
		}
		,A2V00397207537 : {
			de: "Grundplatte",
			en: "Base plate"
		}
		,A2V00397182956 : {
			de: "Butzen",
			en: "scrap"
		}
		,A2V00370026612 : {
			de: "FL DIN1017-30x8-S355JO",
			en: "FL DIN1017-30x8-S355JO"
		}
		,A2V00201470298 : {
			de: "Schuerze kpl. li.",
			en: "Schuerze kpl. li."
		}
		,A2V00201470299 : {
			de: "Schuerze kpl. re.",
			en: "Schuerze kpl. re."
		}
		,A2V00001380718 : {
			de: "Radsatzlager",
			en: "Wheelset bearing"
		}
		,A2V00112403326 : {
			de: "Nutenstein mit Federbuegel",
			en: "Slot nut with spring clip"
		}
		,A2V00001777639 : {
			de: "Fahrmotor M1 - 1TB2723-0GA02",
			en: "Traction motor M1 - 1TB2723-0GA02"
		}
		,A2V00001446567 : {
			de: "Daempfer sekundaer horizontal",
			en: "secondary horizontal damper"
		}
		,A2V00001705853 : {
			de: "Luftfederbeilage 1 mm",
			en: "Shim for air spring 1 mm"
		}
		,A2V00001705855 : {
			de: "Luftfederbeilage 2 mm",
			en: "Shim for air spring 2 mm"
		}
		,A2V00001705956 : {
			de: "Luftfederbeilage 5 mm",
			en: "Shim for air spring 5 mm"
		}
		,A2V00001147768 : {
			de: "IGBT PHASENBAUSTEIN SP3000WL",
			en: "IGBT Phase modul SP3000WL"
		}
		,A2V00113035504 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001068474 : {
			de: "Sicherungsschraube W158 M8x25-10.9",
			en: "Safety screw W158 M8X25-10.9"
		}
		,A2V00109329064 : {
			de: "Lenker",
			en: "Guide"
		}
		,A2V00113028979 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00105668140 : {
			de: "BOLZEN",
			en: "BOLT"
		}
		,A2V00109380238 : {
			de: "SEITENSCHEIBE,PLAN",
			en: "Side windows, plan"
		}
		,A2V00370044676 : {
			de: "SCHLAUCHLEITUNG 3TE 6 A0A3 500 P",
			en: "HOSE ASSEMBLY 3TE 6 A0A3 500 P"
		}
		,A2V00356130034 : {
			de: "6KT-MU DIN 6330 - B M30 - 10",
			en: "HEX.NUT DIN 6330 - B M30 - 10"
		}
		,A2V00001933184 : {
			de: "Erdungskontakt AB 438 A",
			en: "Earthing brush AB 438 A"
		}
		,A2V00001079884 : {
			de: "Radstehbolzen M14x95  10.9",
			en: "wheel bolt M14x95  10.9"
		}
		,A2V00370037135 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00397170776 : {
			de: "Gewindeadapter",
			en: "Threaded connection"
		}
		,A2V00397206780 : {
			de: "Federzentrierung prim.",
			en: "Spring centring device"
		}
		,A2V00001887245 : {
			de: "Fahrmotor M3 - 1TB2421-0GC02",
			en: "Traction motor M3 - 1TB2421-0GC02"
		}
		,A2V00002067490 : {
			de: "Drehzahlgeber GEL 2474",
			en: "Pulse generator GEL 2474"
		}
		,A2V00370052025 : {
			de: "Firmenschild",
			en: "Equipment label"
		}
		,A2V00113033773 : {
			de: "FRONTGLASSCHEIBE   F. BR642",
			en: "front windscreen"
		}
		,A2V00207355367 : {
			de: "Kompressoraggregat SL20-5-90 inkl. E-Mot",
			en: "Compressor set SL20-5-90 incl. e-motor"
		}
		,A2V00370043265 : {
			de: "Magnetschienenbremse EMS 60",
			en: "Track Brake"
		}
		,A2V00001981828 : {
			de: "Stromrichterpaket DC750V TBK9",
			en: "Inverter package DC750V TBK9"
		}
		,A2V00370031900 : {
			de: "SCHLAUCHLEITUNG 2TE 16 AA 750 P",
			en: "HOSE ASSEMBLY 2TE 16 AA 750 P"
		}
		,A2V00001134665 : {
			de: "Seitenscheibe rechteck. FÃ¼hrerkabine li",
			en: "Side wall window square driver cab left"
		}
		,A2V00001129984 : {
			de: "Seitenscheibe FÃ¼hrerkabine rechts",
			en: "side wall windows drivers cab right"
		}
		,A2V00156021277 : {
			de: "110VDC-ERDSCHLUSS  ERFASSUNG",
			en: "110VDC-earth connection registration"
		}
		,A2V00201351821 : {
			de: "Systemwahlschalter IR401896",
			en: "System change over switch  IR401896"
		}
		,A2V00207250196 : {
			de: "Sensor,Druck DG10",
			en: "pressure sensor"
		}
		,A2V00370038289 : {
			de: "Konusfeder",
			en: "conical spring"
		}
		,A2V00207356873 : {
			de: "Steuerung",
			en: "Control unit"
		}
		,A2V00001171542 : {
			de: "Luftbehaelter Alu 60l",
			en: "compressed air vessel Alu 60l"
		}
		,A2V00001392341 : {
			de: "Schichtfeder",
			en: "layer spring"
		}
		,A2V00001446571 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical damper"
		}
		,A2V00370043636 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001019187 : {
			de: "Blattfeder T1",
			en: "Leaf spring p1"
		}
		,A2V00370037748 : {
			de: "Grundierung Epoxy 41 Universal hellgrau",
			en: "Primer Epoxy 41 universal light gray"
		}
		,A2V00001421728 : {
			de: "EPIC BremshebelgerÃ¤t",
			en: "EPIC Brake Control Handle Assembly"
		}
		,A2V00116302962 : {
			de: "KLAPPSITZ VOLLST.",
			en: "TIP-UP SEAT       COMPLETE"
		}
		,A2V00112508842 : {
			de: "EINFUELLSTUTZEN   RECHTS",
			en: "filler right"
		}
		,A2V00000304720 : {
			de: "Elektronischer Summer 16,8-150 V DC",
			en: "Electronic buzzer 16,8-150 V DC"
		}
		,A2V00001879091 : {
			de: "Sechskantmutter ISO 7040 M24 8",
			en: "Hexagon nut ISO 7040 M24 8"
		}
		,A2V00001158244 : {
			de: "TÃ¼rflÃ¼gel, vollst 1-flgl.",
			en: "door leaf complete (single floding door)"
		}
		,A2V00000010595 : {
			de: "Isolator 3 kV",
			en: "Insulator 3 kV"
		}
		,A2V00000010634 : {
			de: "Gewindebolzen Ausf.3",
			en: "Theaded bolt vers.3"
		}
		,A2V00000010643 : {
			de: "Buchse D23/12",
			en: "Sleeve D23/12"
		}
		,A2V00000010648 : {
			de: "AbstandhÃ¼lse",
			en: "Spacer sleeve"
		}
		,A2V00000010650 : {
			de: "Dichtkappe fÃ¼r Kuppelstange",
			en: "Packing for coupling rod"
		}
		,A2V00000013147 : {
			de: "AbstandshÃ¼lse T1",
			en: "Spacer P1"
		}
		,A2V00000013630 : {
			de: "Gummifederelement 65mm",
			en: "Rubber spring element 65mm"
		}
		,A2V00000379037 : {
			de: "Zugkoppelspule 50/100/850KHz",
			en: "Vehicle coupling coil 50/100/850kHz"
		}
		,A2V00001012059 : {
			de: "STÃœTZISOLATOR 25KV",
			en: "isolator 25kV"
		}
		,A2V00001048648 : {
			de: "Tragleiste",
			en: "Girding strip"
		}
		,A2V00001063764 : {
			de: "Lagerhuelse T1",
			en: "Bearing sleeve p1"
		}
		,A2V00001079909 : {
			de: "Lagerwelle Basis Ausf.1",
			en: "Bearing shaft base vers.1"
		}
		,A2V00001711267 : {
			de: "Erdschlussueberw. 110V m.Stiftleiste",
			en: "earth fault monitoring   110V with strip"
		}
		,A2V00001114406 : {
			de: "Sofis DatentrÃ¤ger 20 Bit ID-Tag, grau",
			en: "Sofis data carrier 20 BIT"
		}
		,A2V00001121667 : {
			de: "Faltenbalg schwarz",
			en: "Bellow black"
		}
		,A2V00001151513 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00001151534 : {
			de: "RO ISO1127-42x2-D4T3-S-1.4301+M",
			en: "RO ISO1127-42x2-D4T3-S-1.4301+M"
		}
		,A2V00001069410 : {
			de: "Magnetventil DC110V 14W IP65 KNO:",
			en: "Magnet valve DC110V 14W IP65 KNO:"
		}
		,A2V00002189924 : {
			de: "Manuelle Abschleppkupplung Albert 280kN",
			en: "Manual towing coupler Albert 280kN"
		}
		,A2V00001169351 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001169487 : {
			de: "Sechskantmutter ISO 4032 M16 A4-70",
			en: "Hexagon nut ISO 4032 M16 A4-70"
		}
		,A2V00001966261 : {
			de: "Beilage 10MM",
			en: "Shim 10MM"
		}
		,A2V00002260116 : {
			de: "E-Geraetecontainer, kpl. ++RAC2, +98.10",
			en: "E-Container, cpl. ++RAC2, +98.10"
		}
		,A2V00207423083 : {
			de: "Schraubenkupplung",
			en: "screw coupler"
		}
		,A2V00002576529 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002611029 : {
			de: "Bedientafel X4E8511_V22 E+M",
			en: "Operating panel X4E8511_V22 e+m"
		}
		,A2V00002452043 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002452044 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001195952 : {
			de: "PALETTENFUEHRUNG",
			en: "Collector head-guide"
		}
		,A2V00370032990 : {
			de: "NOTFEDERBEILAGE 5MM",
			en: "Air Spring Shim 5MM"
		}
		,A2V00001195960 : {
			de: "Horn kompl. rechts",
			en: "Horn complete right"
		}
		,A2V00001218516 : {
			de: "ERDUNGSBLECH",
			en: "EARTHING SHEET"
		}
		,A2V00001831126 : {
			de: "Hebantrieb SBS2T-NG kompl. Ausf.2",
			en: "Lifting device SBS2T-NG compl. vers.2"
		}
		,A2V00201351826 : {
			de: "Schuetz LTHHE1000-1pol  DC 110V",
			en: "Contactor LTHHE1000-1pol  DC 110V"
		}
		,A2V00001228782 : {
			de: "Beilage Gleitstueck",
			en: "shim friction block"
		}
		,A2V00001228943 : {
			de: "Beilage Gleitstueck",
			en: "shim friction block"
		}
		,A2V00002355663 : {
			de: "Auflagescheibe",
			en: "Contact shim"
		}
		,A2V00002152124 : {
			de: "Kabeleinfuehrungsplatte 1 86K",
			en: "Gland plate 1 86K"
		}
		,A2V00002152463 : {
			de: "Kabeleinfuehrungsplatte 3 86K",
			en: "Gland plate 3 86K"
		}
		,A2V00002152474 : {
			de: "Kabeleinfuehrungsplatte 2 86K",
			en: "Gland plate 2 86K"
		}
		,A2V00001396149 : {
			de: "Haerter Haerter 51 EP W",
			en: "Hardener Hardener 51 EP W"
		}
		,A2V00001306678 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002496293 : {
			de: "SekundÃ¤rschichtfeder Avenio Meterspur",
			en: "secondary layer spring Avenio metertrack"
		}
		,A2V00002706973 : {
			de: "Halter, rechter, geschw.",
			en: "Holder, right, welded"
		}
		,A2V00002706974 : {
			de: "Halter, linker, geschw.",
			en: "Holder, left, welded"
		}
		,A2V00002706980 : {
			de: "Auflage, w.g., geschw.",
			en: "Support, a.d., welded"
		}
		,A2V00002707050 : {
			de: "Halter, w.g., geschw.",
			en: "Holder, a.d., welded"
		}
		,A2V00002740795 : {
			de: "Distanzscheibe",
			en: "Distance washer"
		}
		,A2V00002740876 : {
			de: "Nutenstein M12",
			en: "Groove stone M12"
		}
		,A2V00001415910 : {
			de: "Schraubenisolierung",
			en: "Screw isolation"
		}
		,A2V00001416709 : {
			de: "Buchse",
			en: "bush"
		}
		,A2V00001346831 : {
			de: "Palettenbogen zur Flachfederwippe NS",
			en: "Collector head bow for collector head NS"
		}
		,A2V00001418496 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001387466 : {
			de: "Zugstange",
			en: "Pull rod"
		}
		,A2V00001396934 : {
			de: "Spriegel",
			en: "Stick"
		}
		,A2V00001397146 : {
			de: "Spriegel",
			en: "Stick"
		}
		,A2V00001442882 : {
			de: "Metalladapter",
			en: "metal adapter"
		}
		,A2V00001835427 : {
			de: "Zylinderschraube",
			en: "hexagon socket head cap screwÂ "
		}
		,A2V00001408199 : {
			de: "Gurt, unterer",
			en: "Lower flange"
		}
		,A2V00001409733 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001451457 : {
			de: "Schraubadapter",
			en: "Screw adapter"
		}
		,A2V00001417350 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001417734 : {
			de: "Seitenflansch",
			en: "Side flange"
		}
		,A2V00001417837 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00001418268 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001418503 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001419126 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00001419150 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001419151 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001419918 : {
			de: "Druckblech GP 47.5 1-fach",
			en: "Pressure sheet GP 47.5 1-fold"
		}
		,A2V00001664037 : {
			de: "Spachtelmasse Feinspachtel Colormatic",
			en: "Putty Feinspachtel Colormatic"
		}
		,A2V00001420990 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001421007 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001422677 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00001422678 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00001422685 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001670859 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00002165473 : {
			de: "Wiegand Radreifensensor 6S",
			en: "Wiegand Wheel Sensor 6S"
		}
		,A2V00370032988 : {
			de: "NOTFEDERBEILAGE 1MM",
			en: "emergency spring shim 1mm"
		}
		,A2V00370032991 : {
			de: "NOTFEDERBEILAGE 10MM",
			en: "emergency spring shim 10mm"
		}
		,A2V00370032989 : {
			de: "NOTFEDERBEILAGE 2MM",
			en: "emergency spring shim 2mm"
		}
		,A2V00001421361 : {
			de: "Halbscherenstromabnehmer NS",
			en: "Single arm pantograph NS"
		}
		,A2V00001423527 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002165496 : {
			de: "Wiegand Radreifensensor 6S",
			en: "Wiegand Wheel Sensor 6S"
		}
		,A2V00001678150 : {
			de: "Befestigungselement",
			en: "Element for fastening"
		}
		,A2V00001430000 : {
			de: "Versteifungsprofil",
			en: "Stiffening profile"
		}
		,A2V00001430934 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00001679980 : {
			de: "Schraubadapter",
			en: "Screw adapter"
		}
		,A2V00001443563 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00001444675 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001444680 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002418213 : {
			de: "Drehzahlgeber GEL 2475",
			en: "Speed sensor GEL2475"
		}
		,A2V00001444696 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001444703 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001444707 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001445238 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001376635 : {
			de: "Gliederbruecke,   vst. mit Schlitten",
			en: "Linking bridge,   compl. with sledge"
		}
		,A2V00201314641 : {
			de: "Zughakenfuehrung",
			en: "draw hook guide"
		}
		,A2V00001472225 : {
			de: "Halfen-Montageschiene",
			en: "Halfen-mounting rail"
		}
		,A2V00001473667 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001659181 : {
			de: "Befestigungslasche",
			en: "Attachment parts"
		}
		,A2V00001667315 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00001799231 : {
			de: "Traktionsstromrichter SIBAC ER24PC",
			en: "Traction converter SIBAC ER24PC"
		}
		,A2V00001802548 : {
			de: "Seitenfenster",
			en: "Window"
		}
		,A2V00001833509 : {
			de: "Zylinderschraube",
			en: "hexagon socket head cap screwÂ "
		}
		,A2V00001667332 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00001667333 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00001667334 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00001667335 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00001667376 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00001667377 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00001669749 : {
			de: "Rohr",
			en: "Pipe"
		}
		,A2V00001445966 : {
			de: "SIBAC-Traktions-SR fuer X4 Belgien",
			en: "SIBAC - traction converter for X4 Be"
		}
		,A2V00113052792 : {
			de: "Wellrohrverschraubung, 90Â°",
			en: "Conduit connector, 90Â°"
		}
		,A2V00207421265 : {
			de: "Schloss,Einsteck-",
			en: "Schloss,Einsteck-"
		}
		,A2V00001694760 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00001694860 : {
			de: "Konsole",
			en: "Console"
		}
		,A2V00001694886 : {
			de: "Konsole",
			en: "Console"
		}
		,A2V00001695183 : {
			de: "Halter Kabel f.",
			en: "Support cable f."
		}
		,A2V00002127868 : {
			de: "Werkstatteinspeisung vollst.",
			en: "Shop power compl."
		}
		,A2V00001699899 : {
			de: "Anschraubplatte",
			en: "Screw-on plate"
		}
		,A2V00001703424 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001835286 : {
			de: "Dachplatte         mech. bearbeitet",
			en: "Roofplate         machined"
		}
		,A2V00001838745 : {
			de: "Antennentraeger vollst.",
			en: "antenna support complete"
		}
		,A2V00001395898 : {
			de: "ERDUNGSBUERSTE KOMPLETT",
			en: "Earth brush assembly complete"
		}
		,A2V00001839606 : {
			de: "Fahrschaltereinheit",
			en: "Master controller unit"
		}
		,A2V00001841465 : {
			de: "B-Laengstraeger   links",
			en: "b long. girder      left"
		}
		,A2V00001841548 : {
			de: "B-Laengstraeger rechts",
			en: "b long. girder      right"
		}
		,A2V00001841798 : {
			de: "A-Laengstraeger   links",
			en: "a long. girder     left"
		}
		,A2V00001841799 : {
			de: "A-Laengstraeger   rechts",
			en: "a long. girder      right"
		}
		,A2V00001718167 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001843739 : {
			de: "C-Quertraeger     bearb.",
			en: "c-cross girder    mach."
		}
		,A2V00001720696 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001844418 : {
			de: "Wellenbalg, vollst.",
			en: "Corrugated bellows, compl."
		}
		,A2V00001803556 : {
			de: "Traktionsstromrichter  (DC-Umrichter)",
			en: "Traction converter, DC"
		}
		,A2V00001733791 : {
			de: "Anschlussblech",
			en: "Junction sheet"
		}
		,A2V00001741436 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00001845648 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002171241 : {
			de: "Kasten vollst. f. Ausklinkstab",
			en: "Box compl. f. uncoupling rod"
		}
		,A2V00002094819 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002173232 : {
			de: "Daempferbolzen",
			en: "damper bolt"
		}
		,A2V00001852425 : {
			de: "Ventiltafel zweistufig",
			en: "valve plate two stage"
		}
		,A2V00001873512 : {
			de: "Sprechstelle fuer Zugchefabteil",
			en: "Handset conductor compartment"
		}
		,A2V00001846760 : {
			de: "Fuehrerbremsventil FSB1-2",
			en: "driver brake valve FBS1-2"
		}
		,A2V00001847913 : {
			de: "Eckprofil geschweisst",
			en: "corner profil welded"
		}
		,A2V00001848031 : {
			de: "Eckprofil geschweisst",
			en: "corner profile welded"
		}
		,A2V00001745766 : {
			de: "Lenker, mech. bearb.",
			en: "Guiding rod, machined"
		}
		,A2V00001813925 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00001370432 : {
			de: "Rollenlager 807850",
			en: "Roller bearing 807850"
		}
		,A2V00370024921 : {
			de: "KASTENKULISSE SGP 400-DSW W.G.",
			en: "box crank SGP 400-DSC a.d."
		}
		,A2V00370024923 : {
			de: "KASTENKULISSE SGP 400-DSW SP.G.",
			en: "box crank SGP 400-DSC o.a.d."
		}
		,A2V00002538624 : {
			de: "Erdungskontakt TFW",
			en: "Grounding device MB"
		}
		,A2V00002538625 : {
			de: "Erdungskontakt LFW",
			en: "Grounding device TB"
		}
		,A2V00370027772 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001710788 : {
			de: "Frontklappe, lack",
			en: "front flap, lacquered"
		}
		,A2V00101235591 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001806192 : {
			de: "Sechskantmutter ISO 7042 M24 8",
			en: "Hexagon nut ISO 7042 M24 8"
		}
		,A2V00001873372 : {
			de: "Bodenplatte kpl.",
			en: "floor plate compl."
		}
		,A2V00001873739 : {
			de: "SCHWEISSAUSLAUFBLECH 8 mm",
			en: "SCHWEISSAUSLAUFBLECH 8 mm"
		}
		,A2V00001874242 : {
			de: "Rahmen            Wassertank",
			en: "frame             water tank"
		}
		,A2V00001875091 : {
			de: "Bodenplatte kpl.",
			en: "floor plate compl."
		}
		,A2V00002413714 : {
			de: "Buerste",
			en: "brush"
		}
		,A2V00001876176 : {
			de: "HS-Doppelspiralwendel",
			en: "HV - Double Spiral Coil"
		}
		,A2V00001876819 : {
			de: "Bodenplatte kpl.",
			en: "floor plate compl."
		}
		,A2V00001876820 : {
			de: "Bodenplatte kpl.",
			en: "floor plate compl."
		}
		,A2V00001877100 : {
			de: "Bodenplatte kpl.",
			en: "floor plate compl."
		}
		,A2V00001879028 : {
			de: "Bodenplatte kpl.",
			en: "floor plate compl."
		}
		,A2V00001879935 : {
			de: "Dachblech mit Anschweissteilen",
			en: "roof sheeting     movein pantograph"
		}
		,A2V00001831121 : {
			de: "Lagerhuelse T2",
			en: "bearing sleeve"
		}
		,A2V00001831129 : {
			de: "Rundzylinder",
			en: "Round cylinder"
		}
		,A2V00001882429 : {
			de: "Kontrollstutzen K1-E",
			en: "test fitting K1-E"
		}
		,A2V00001882753 : {
			de: "Bodenplatte kpl.",
			en: "floor plate compl."
		}
		,A2V00001882860 : {
			de: "Spitzenleuchte",
			en: "Tail light"
		}
		,A2V00082044977 : {
			de: "Radarsensor DRS05S1a mit SW51-023I",
			en: "Radar sensor DRS05S1a with SW 51-023I"
		}
		,A2V00370009667 : {
			de: "Deckplatte DIN 3015-L DP-5-W1",
			en: "Cover plate DIN 3015-L DP-5-W1"
		}
		,A2V00001887959 : {
			de: "Distanzscheibe 2mm",
			en: "spacer 2mm"
		}
		,A2V00001888266 : {
			de: "Haltegriff vollst.geschweisst",
			en: "handle compl.     welded"
		}
		,A2V00397282808 : {
			de: "BLECH",
			en: "SHEET METAL"
		}
		,A2V00397264306 : {
			de: "BLECH",
			en: "SHEET METAL"
		}
		,A2V00207310305 : {
			de: "Trittstufe, lackiert",
			en: "Steps, lacquered"
		}
		,A2V00001892994 : {
			de: "Dachblech vollst.",
			en: "roof sheeting complete"
		}
		,A2V00001894292 : {
			de: "Schweissbadsicherung",
			en: "weld pool backing"
		}
		,A2V00001895960 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002090462 : {
			de: "Queranschlagsplatte",
			en: "Plate for cross stop"
		}
		,A2V00397171712 : {
			de: "Entgleisungsschutzbalken, lackiert",
			en: "Derailment guard girder, varn."
		}
		,A2V00397163951 : {
			de: "Drehzapfenbuchse, komplett",
			en: "turn pin sleeve, complete"
		}
		,A2V00001673199 : {
			de: "Bolzen fuer ZD-Stange",
			en: "Bolt for push - pull rod"
		}
		,A2V00001917846 : {
			de: "Knotenblech Front 3 w.g",
			en: "Gusset plate front 3 a.d."
		}
		,A2V00001917847 : {
			de: "Knotenblech",
			en: "Gusset plate"
		}
		,A2V00001917851 : {
			de: "Blech w.g.",
			en: "Sheet metal a.d."
		}
		,A2V00001918344 : {
			de: "Tuersaeule 2, vollst. w.g.",
			en: "Door-post 2, compl. a.d."
		}
		,A2V00001918345 : {
			de: "Tuersaeule 2, vollst. sp.g.",
			en: "Door-post 2, compl. i.m.i."
		}
		,A2V00001918349 : {
			de: "Fenstersaeule geschw.",
			en: "Window-post welded"
		}
		,A2V00001918352 : {
			de: "Tuersaeule 3, vollst. w.g.",
			en: "Door-post 3, compl. a.d."
		}
		,A2V00001918419 : {
			de: "Stirnwand vollst. EZ sp.g.",
			en: "Bulkhead compl. TR i.m.i."
		}
		,A2V00001918421 : {
			de: "Stirnwand vollst. EZ w.g.",
			en: "Bulkhead compl. TR a.d."
		}
		,A2V00001918570 : {
			de: "Tuersaeule 3, vollst. sp.g.",
			en: "Door-post 3, compl. i.m.i."
		}
		,A2V00001918572 : {
			de: "Tuersaeule 1, vollst. w.g.",
			en: "Door post 1, compl. a.d."
		}
		,A2V00001918581 : {
			de: "Knotenblech 12 w.g.",
			en: "Gusset plate 12 a.d."
		}
		,A2V00001918730 : {
			de: "Knotenblech 12 sp.g.",
			en: "Gusset plate 12 i.m.i."
		}
		,A2V00001919586 : {
			de: "Abdeckblech Dach w.g.",
			en: "cover sheet roof a.d."
		}
		,A2V00001919663 : {
			de: "Tuerversteifung sp.g.",
			en: "Door stiffener i.m.i."
		}
		,A2V00001919675 : {
			de: "Tuerversteifung w.g.",
			en: "Door stiffener a.d."
		}
		,A2V00001919738 : {
			de: "Schliessblech 2",
			en: "Close sheet 2"
		}
		,A2V00001919741 : {
			de: "Endblech",
			en: "End sheet"
		}
		,A2V00370026982 : {
			de: "Puffer",
			en: "Buffer"
		}
		,A2V00397163984 : {
			de: "SCHEIBE",
			en: "WASHER"
		}
		,A2V00397165479 : {
			de: "GABELBLECH",
			en: "fork plate"
		}
		,A2V00397152164 : {
			de: "EINSATZBUCHSE",
			en: "insert bushing"
		}
		,A2V00346200505 : {
			de: "Haerter EP Remoplast farblos",
			en: "Hardener"
		}
		,A2V00001930966 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001932463 : {
			de: "Bruchbolzen",
			en: "Breaking pin"
		}
		,A2V00370082983 : {
			de: "Hohlbuchse",
			en: "cylindrical bush"
		}
		,A2V00001697192 : {
			de: "Getriebe 2LB9085-7CA68",
			en: "Gearbox 2LB9085-7CA68"
		}
		,A2V00002051597 : {
			de: "Drehstab 2, lackiert",
			en: "Torsion spring 2, painted"
		}
		,A2V00002051510 : {
			de: "Drehstab 1 lackiert",
			en: "Torsion spring 1 painted"
		}
		,A2V00002245892 : {
			de: "USC 4 komplett, ++RAB1, +95.40",
			en: "USC 4 complete, ++RAB1, +95.40"
		}
		,A2V00002420208 : {
			de: "Klemmteil 1",
			en: "clamping 1"
		}
		,A2V00002420210 : {
			de: "Klemmteil 2",
			en: "clamping 2"
		}
		,A2V00002420211 : {
			de: "Schutzblech ATBL",
			en: "Protection plate atbl"
		}
		,A2V00370066625 : {
			de: "Koaxialkabel 75Ohm, GX_04273-12",
			en: "Coaxial cable 75Ohm, GX_04273-12"
		}
		,A2V00002227370 : {
			de: "Beilage vertikal 1 mm",
			en: "shim vertical 1 mm"
		}
		,A2V00002205069 : {
			de: "Beilage Sekundaerfeder 5mm",
			en: "shim secondary spring 5mm"
		}
		,A2V00002221677 : {
			de: "Beilage SekundÃ¤rfeder 10mm",
			en: "shim secundary spring 10mm"
		}
		,A2V00002227444 : {
			de: "Beilage vertikal 10mm",
			en: "shim vertical 10mm"
		}
		,A2V00002227522 : {
			de: "Beilage vertikal 5 mm",
			en: "shim vertical 5 mm"
		}
		,A2V00001961312 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00001968033 : {
			de: "Schuerzenklappe Zusammenstellung",
			en: "Pinafore flap assembly"
		}
		,A2V00001919933 : {
			de: "Trittstufenhalter sp.g.",
			en: "tread support inv.dr."
		}
		,A2V00001919934 : {
			de: "Trittstufenhalter w.g.",
			en: "tread support as dr."
		}
		,A2V00397164640 : {
			de: "Deckel, li. fuer LT- KT1",
			en: "LH. LID/Cover for LT- KT1"
		}
		,A2V00397164675 : {
			de: "Anschluss fuer Sekundaerdaempfer",
			en: "Secondary damper connection"
		}
		,A2V00397164679 : {
			de: "Anschluss,vord. re. fuer Primaerdaempfer",
			en: "Front rh. primary damper connection"
		}
		,A2V00397164680 : {
			de: "Anschluss,hint. li. fuer Primaerdaempfer",
			en: "Rear lh. primary damper connection"
		}
		,A2V00397164681 : {
			de: "Anschluss,vord. re. fuer Primaerdaempfer",
			en: "Front rh. primary damper connection"
		}
		,A2V00397164696 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397167933 : {
			de: "Anschlag",
			en: "Stopper"
		}
		,A2V00397164642 : {
			de: "Deckel, li. fuer LT- KT2",
			en: "Cover, left for LT- KT2"
		}
		,A2V00397164643 : {
			de: "Deckel, re. fuer LT- KT2",
			en: "Cover, right for LT- KT2"
		}
		,A2V00397164706 : {
			de: "Anhebeplatte",
			en: "Lifting plate"
		}
		,A2V00397164641 : {
			de: "Deckel, re. fuer LT- KT1",
			en: "Cover, right for LT- KT1"
		}
		,A2V00002275452 : {
			de: "Steuergeraet 34TE mit AMVB/Ethernet",
			en: "Control unit 34TE with MVB/ethernet"
		}
		,A2V00001987389 : {
			de: "Konsole 2 F-Schrank",
			en: "Bracket 2 d-cabinet"
		}
		,A2V00001987393 : {
			de: "Konsole 1 F-Schrank",
			en: "Bracket 1 d-cabinet"
		}
		,A2V00001987802 : {
			de: "Rippe C-Profil Front",
			en: "Rib C-rail front"
		}
		,A2V00001987829 : {
			de: "Z-Profil quer",
			en: "Z-profile across"
		}
		,A2V00001994284 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00001996601 : {
			de: "Zylinderrohr RAL9006",
			en: "Cylinder tube RAL9006"
		}
		,A2V00001928847 : {
			de: "Schotterschutzgitter",
			en: "Brash Guard Grid"
		}
		,A2V00370072721 : {
			de: "Anschlagscheibe",
			en: "Stop plate"
		}
		,A2V00001687607 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370027443 : {
			de: "Sechskantmutter ISO 4032 M20 8 A3C",
			en: "Hexagon nut ISO 4032 M20 8 A3C"
		}
		,A2V00370032783 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002003568 : {
			de: "Z-Profil quer",
			en: "Z-profile across"
		}
		,A2V00001931421 : {
			de: "Schutzblech       kpl.",
			en: "protection plate  compl."
		}
		,A2V00001931552 : {
			de: "Schutzblech",
			en: "protecting plate"
		}
		,A2V00001933635 : {
			de: "Haerter HT204",
			en: "Hardener HT201"
		}
		,A2V00002020864 : {
			de: "Knotenblech",
			en: "Gusset plate 11"
		}
		,A2V00002020967 : {
			de: "Fensteruntergurt",
			en: "Window-lower belt"
		}
		,A2V00002021744 : {
			de: "Winkel",
			en: "Angle"
		}
		,A2V00002021772 : {
			de: "Erdungslasche Multi",
			en: "Earthing multi"
		}
		,A2V00002021904 : {
			de: "Wartungsklappe",
			en: "Maintenance cover"
		}
		,A2V00082012936 : {
			de: "Kipphebeltaster",
			en: "toggle switch"
		}
		,A2V00002025324 : {
			de: "Knotenblech 14",
			en: "Gusset plate 14"
		}
		,A2V00001937147 : {
			de: "Deflektor Wagenuebergang",
			en: "Deflector Gangway"
		}
		,A2V00001939508 : {
			de: "Dachvoute kpl.    rechts",
			en: "roof rounding comp right"
		}
		,A2V00001203772 : {
			de: "Doppeleckverbinder FM, ADV",
			en: "Double leak connector, FM, ADV"
		}
		,A2V00001940183 : {
			de: "Deflektor",
			en: "Deflector"
		}
		,A2V00001940374 : {
			de: "Dachvoute kpl.    links",
			en: "roof rounding comp left"
		}
		,A2V00001940375 : {
			de: "Dachvoute kpl.    links",
			en: "roof rounding comp left"
		}
		,A2V00001940378 : {
			de: "Dachvoute kpl.    rechts",
			en: "roof rounding comp right"
		}
		,A2V00001940379 : {
			de: "Dachvoute kpl.    rechts",
			en: "roof rounding comp right"
		}
		,A2V00002033827 : {
			de: "Abdeckblech Dach sp.g.",
			en: "cover sheet roof r.i."
		}
		,A2V00001747192 : {
			de: "Impulsgeber FS01J",
			en: "Pulse generator FS01J"
		}
		,A2V00001944139 : {
			de: "Deflektor WE2 vollst.",
			en: "Deflector CE2 compl."
		}
		,A2V00112506876 : {
			de: "SCHWANENHALS-     MIKROFON M4-VS40",
			en: "gooseneck microphone"
		}
		,A2V00112519000 : {
			de: "DRUCKLUFTGERUEST  VT 642.5",
			en: "air pressure frame"
		}
		,A2V00002039041 : {
			de: "Knotenblech Front 3 sp.g.",
			en: "Gusset plate front 3 i.m.i."
		}
		,A2V00002040877 : {
			de: "Wippe DC2 bC",
			en: "Compensator DC2 bC"
		}
		,A2V00002040883 : {
			de: "Gummipuffer d35x80",
			en: "Rubber pad d35x80"
		}
		,A2V00002040885 : {
			de: "Gelenkkopf KA 20-DNR",
			en: "Joint head, KA 20-DNR"
		}
		,A2V00002040886 : {
			de: "Gelenkkopf KAL 20-DNR",
			en: "Joint head, KAL 20-DNR"
		}
		,A2V00002040887 : {
			de: "Lenkhebel",
			en: "Drop arm"
		}
		,A2V00002045145 : {
			de: "Anschweissblech sp. g.",
			en: "Welding plate i.m.i."
		}
		,A2V00002045696 : {
			de: "Anschweissblech w.g.",
			en: "Welding plate a.d."
		}
		,A2V00001949162 : {
			de: "Antriebsrechner (AR) 24V",
			en: "Traction control unit (TCU) 24V"
		}
		,A2V00002048882 : {
			de: "Rippe C-Profil",
			en: "rib for c-profile"
		}
		,A2V00002049197 : {
			de: "Versteifung w. g.",
			en: "Stiffener a.d."
		}
		,A2V00001953579 : {
			de: "Kupplungsplatte",
			en: "coupler plate"
		}
		,A2V00002054028 : {
			de: "Tuerkonsole 1",
			en: "Door bracket 1"
		}
		,A2V00370081051 : {
			de: "Sechskantmutter ISO 7040 M16 A4-80",
			en: "Hexagon nut ISO 7040 M16 A4-80"
		}
		,A2V00370036301 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002054203 : {
			de: "U-Konsole, Dach 1",
			en: "U bracket, roof 1"
		}
		,A2V00002054391 : {
			de: "Mg-Laengsmitnahme ft.sp.g.",
			en: "em-traktion link mp.o.a.d."
		}
		,A2V00002054392 : {
			de: "Mg-Laengsmitnahme ft.w.g",
			en: "em traktion link mp.a.d."
		}
		,A2V00002057785 : {
			de: "Strebe",
			en: "Bar"
		}
		,A2V00002057847 : {
			de: "Lagerwelle Knie Ausf.2",
			en: "Bearing shaft knee vers.2"
		}
		,A2V00002057866 : {
			de: "Gewindestange T1",
			en: "Thread rod T1"
		}
		,A2V00002065849 : {
			de: "Tuersaeule 1, vollst. sp.g.",
			en: "Door post 1, compl. i.m.i."
		}
		,A2V00001191948 : {
			de: "SCHLAUCHLEITUNG 2TE 16 NN90 380",
			en: "HOSE ASSEMBLY 2TE 16 NN90 380 P"
		}
		,A2V00002218165 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00113028978 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002205093 : {
			de: "Beilage 1,5mm",
			en: "Shim 1,5mm"
		}
		,A2V00001966243 : {
			de: "Abdeckung rechts vollst.",
			en: "Barrier right compl."
		}
		,A2V00001968353 : {
			de: "Schuerze lackiert",
			en: "shroud coated"
		}
		,A2V00001968355 : {
			de: "Schuerze lackiert",
			en: "shroud coated"
		}
		,A2V00001968432 : {
			de: "Tritt lackiert",
			en: "step coated"
		}
		,A2V00001974621 : {
			de: "Tischfuss vollst.",
			en: "table-leg complete"
		}
		,A2V00113031219 : {
			de: "FRONTSCHEIBE      SICHTFELD",
			en: "Windshield field of view"
		}
		,A2V00002079683 : {
			de: "Stirnwandblech sp.g.",
			en: "End wall sheet i.m.i."
		}
		,A2V00370036732 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370024204 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00397201336 : {
			de: "Federzentrierung sek.",
			en: "spring centring device"
		}
		,A2V00002090414 : {
			de: "Beilage LÃ¤ngsmitnahme",
			en: "shim traction link"
		}
		,A2V00002090527 : {
			de: "Beilage Queranschlag",
			en: "shim cross stop"
		}
		,A2V00002092484 : {
			de: "Beilage Sekundaerfeder 1mm",
			en: "shim secondary spring 1mm"
		}
		,A2V00001991246 : {
			de: "Halter vollstaendig fuer Zugschluss",
			en: "Holder complete for train end"
		}
		,A2V00002096036 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002098891 : {
			de: "Konsole kurz",
			en: "console short"
		}
		,A2V00397138407 : {
			de: "Buchse",
			en: "Bush"
		}
		,A2V00002102659 : {
			de: "Scheibenwaschtank vollst.",
			en: "Cleaning tank compl."
		}
		,A2V00397250803 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00002103869 : {
			de: "Querdaempferkonsole bearb.",
			en: "Cross buffer console mach."
		}
		,A2V00002104427 : {
			de: "Winkel",
			en: "Bracket"
		}
		,A2V00102762781 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00112507043 : {
			de: "LAGERBOLZEN                            .",
			en: "bearing bold"
		}
		,A2V00002105606 : {
			de: "Strebe",
			en: "Bracer"
		}
		,A2V00002106159 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screwÂ "
		}
		,A2V00002010375 : {
			de: "Deflektor genietet",
			en: "Deflector riveted"
		}
		,A2V00002010377 : {
			de: "Deflektor genietet",
			en: "Deflector riveted"
		}
		,A2V00002107448 : {
			de: "Erdungslasche 2",
			en: "Earthing 2"
		}
		,A2V00001906904 : {
			de: "2-Kreis-Kuehlanlage f. DE-Lokomotive V02",
			en: "2-circuit-cooling plant f. DE-Loco. V02"
		}
		,A2V00370039867 : {
			de: "Puffer",
			en: "buffer"
		}
		,A2V00001446284 : {
			de: "Einpress / Abpressscheibe (IS BS)",
			en: "Press in / press out plate (IS BS)"
		}
		,A2V00002523074 : {
			de: "Hindernissimulationsgeraet",
			en: "Obstacle simulation device"
		}
		,A2V00002027781 : {
			de: "Dachplatte mech. bearbeitet",
			en: "roof plate machined"
		}
		,A2V00002111857 : {
			de: "Erdungslasche M10",
			en: "Earthing ear M10"
		}
		,A2V00002112325 : {
			de: "Puffer",
			en: "buffer"
		}
		,A2V00002030031 : {
			de: "Stirnwand",
			en: "endwall"
		}
		,A2V00002112700 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002112905 : {
			de: "Nutenstein",
			en: "Sliding block"
		}
		,A2V00002112960 : {
			de: "Gleitplatte",
			en: "Sliding plate"
		}
		,A2V00397240321 : {
			de: "GEGENHALTER ALU",
			en: "COUNTERSTAY ALU"
		}
		,A2V00002113341 : {
			de: "Z-Profil",
			en: "Z-profile"
		}
		,A2V00001894378 : {
			de: "Dehnhuelse M24",
			en: "Anti-fatigue sleeve M24"
		}
		,A2V00001940040 : {
			de: "Joch unten verzinkt",
			en: "Transport plate bottom zincked"
		}
		,A2V00001940039 : {
			de: "Joch oben verzinkt",
			en: "Transport plate top zinked"
		}
		,A2V00001867839 : {
			de: "Dehnhuelse",
			en: "Anti-fatigue sleeve"
		}
		,A2V00397179870 : {
			de: "Kastenmitnehmer LDG",
			en: "Car body tappet CT"
		}
		,A2V00397129369 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00370054736 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370037133 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00002101983 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00100026220 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370025801 : {
			de: "Zahnplatte",
			en: "Toothed Plate"
		}
		,A2V00370058836 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370011125 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002084028 : {
			de: "Verstellblech f. Sandung",
			en: "Adj. Plate f. bracket sander"
		}
		,A2V00001421012 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002037306 : {
			de: "Bodenplatte kpl.",
			en: "floor plate compl."
		}
		,A2V00002037312 : {
			de: "Bodenplatte vollst.",
			en: "floor plate compl."
		}
		,A2V00002055642 : {
			de: "Drehmomentstuetze",
			en: "Torque support"
		}
		,A2V00002115603 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002115699 : {
			de: "Konsole Fahrerstand",
			en: "Cantilever driver's cab"
		}
		,A2V00002116164 : {
			de: "Spange",
			en: "Stay bolt"
		}
		,A2V00002045583 : {
			de: "Stirnwand",
			en: "endwall"
		}
		,A2V00002091377 : {
			de: "Sechskantmutter ISO 7040 M16 8 A3C",
			en: "Hexagon nut ISO 7040 M16 8 A3C"
		}
		,A2V00002699354 : {
			de: "Lager, Unterteil",
			en: "Bearing, lower part"
		}
		,A2V00002700012 : {
			de: "Rippe 1 WT,SPG",
			en: "Rib 1 bolster,OAD"
		}
		,A2V00002698924 : {
			de: "Blech WT",
			en: "Plate bolster"
		}
		,A2V00002698292 : {
			de: "Rippe 1 WT,WG",
			en: "Rib 1 bolster,AD"
		}
		,A2V00002698343 : {
			de: "Seitenblech 2,WT",
			en: "Side plate 2,bolster"
		}
		,A2V00002698448 : {
			de: "Steg 2 SPG,WT",
			en: "Web 2 OAD,bolster"
		}
		,A2V00002697972 : {
			de: "Steg 2 WG,WT",
			en: "Web 2 AD,bolster"
		}
		,A2V00002698456 : {
			de: "Rippe 3,WT",
			en: "Rib 3,bolster"
		}
		,A2V00002698302 : {
			de: "Rippe 2,WT",
			en: "Rib 2,bolster"
		}
		,A2V00002699488 : {
			de: "Rippe,Lager",
			en: "Rib,bearing"
		}
		,A2V00002699332 : {
			de: "Stirnblech",
			en: "Front plate"
		}
		,A2V00002699503 : {
			de: "Profil",
			en: "profile"
		}
		,A2V00002699291 : {
			de: "Platte,Daempferkonsole",
			en: "Plate,damper bracket"
		}
		,A2V00002699303 : {
			de: "Steg,Daempferkonsole",
			en: "Web,damper bracket"
		}
		,A2V00002699349 : {
			de: "Blech,Daempferkonsole",
			en: "Plate,damper bracket"
		}
		,A2V00002696924 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002696960 : {
			de: "Rippe 2",
			en: "Stiffener 2"
		}
		,A2V00002697028 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00002696974 : {
			de: "Rippe 1",
			en: "Stiffener 1"
		}
		,A2V00002698352 : {
			de: "Seitenblech 1,WT",
			en: "Side plate 1,bolster"
		}
		,A2V00002697720 : {
			de: "U-Profil 2",
			en: "U-plate 2"
		}
		,A2V00002698342 : {
			de: "Rippe,WT",
			en: "Rib,bolster"
		}
		,A2V00002697719 : {
			de: "U-Profil 1",
			en: "U-plate 1"
		}
		,A2V00002700677 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002696007 : {
			de: "Obergurt",
			en: "Upper chord"
		}
		,A2V00002696026 : {
			de: "Untergurt",
			en: "Lower chord"
		}
		,A2V00002695972 : {
			de: "Anschlag",
			en: "Stop plate"
		}
		,A2V00002695855 : {
			de: "Rippe",
			en: "Stiffener"
		}
		,A2V00002695970 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00002695987 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00002696016 : {
			de: "Deckel",
			en: "Cover"
		}
		,A2V00002695959 : {
			de: "Rohr Federtopf",
			en: "Tube spring suspension"
		}
		,A2V00002695939 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002695986 : {
			de: "Rohr Lagertopf",
			en: "Tube bearing pot"
		}
		,A2V00002746710 : {
			de: "Hydraulikspeicher",
			en: "Hydraulic accumulator"
		}
		,A2V00002706960 : {
			de: "Buchse MG",
			en: "Bushing TB"
		}
		,A2V00002706838 : {
			de: "Verschleissplatte",
			en: "Wear plate"
		}
		,A2V00002706898 : {
			de: "Rippe Anschlag",
			en: "Stiffener f. stop"
		}
		,A2V00002709701 : {
			de: "Messmarke",
			en: "Measuring mark f. frame"
		}
		,A2V00002698454 : {
			de: "Federrohr",
			en: "Springtube"
		}
		,A2V00002704054 : {
			de: "Rippe LT SPG",
			en: "Stiffener LB OAD"
		}
		,A2V00002704781 : {
			de: "Rippe LT WG",
			en: "Stiffener LB AD"
		}
		,A2V00002702682 : {
			de: "Rippe 2 WG",
			en: "Plate 2 AD"
		}
		,A2V00002702484 : {
			de: "Rippe 1 WG",
			en: "Plate 1 AD"
		}
		,A2V00002702522 : {
			de: "Rippe 3",
			en: "Plate 3"
		}
		,A2V00002751229 : {
			de: "Rippe 2 SPG",
			en: "Plate 2 OAD"
		}
		,A2V00002688766 : {
			de: "Rippe 1 SPG",
			en: "Plate 1 OAD"
		}
		,A2V00002697761 : {
			de: "Platte Antenne",
			en: "Plate antenna"
		}
		,A2V00002698547 : {
			de: "Lagerplatte",
			en: "Supportplate"
		}
		,A2V00002697693 : {
			de: "Rippe Puffer",
			en: "Stiffener bumper"
		}
		,A2V00002698596 : {
			de: "Stirnplatte 1 WG",
			en: "Coverplate 1 AD"
		}
		,A2V00002698587 : {
			de: "Stirnplatte 1 SPG",
			en: "Coverplate 1 OAD"
		}
		,A2V00002697684 : {
			de: "Versteifung WG",
			en: "Stiffener AD"
		}
		,A2V00002697432 : {
			de: "Versteifung SPG",
			en: "Stiffener OAD"
		}
		,A2V00002697917 : {
			de: "Stirnplatte 2 SPG",
			en: "Cover plate 2"
		}
		,A2V00002691232 : {
			de: "Stegblech SPG",
			en: "Web OAD"
		}
		,A2V00002691233 : {
			de: "Stegblech WG",
			en: "Web AD"
		}
		,A2V00002697926 : {
			de: "Stirnplatte 2 WG",
			en: "Cover plate 2 AD"
		}
		,A2V00002698328 : {
			de: "Rippe Distanz SPG",
			en: "Stiffener distance OAD"
		}
		,A2V00002697968 : {
			de: "Seitenblech 2 SPG",
			en: "Side plate 2 OAD"
		}
		,A2V00002698296 : {
			de: "Seitenblech 1 SPG",
			en: "Side plate 1 OAD"
		}
		,A2V00002697738 : {
			de: "Lasche",
			en: "Plate"
		}
		,A2V00002697441 : {
			de: "Gurt Puffer",
			en: "Plate bumper"
		}
		,A2V00002695261 : {
			de: "Rippe 2",
			en: "Stiffener 2"
		}
		,A2V00002695235 : {
			de: "Rippe 1",
			en: "Stiffener 1"
		}
		,A2V00002695175 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002697795 : {
			de: "Rippe 1 Antenne",
			en: "Stiffener 1 antenna"
		}
		,A2V00002697895 : {
			de: "Platte Anschlag",
			en: "Plate stopper"
		}
		,A2V00002697916 : {
			de: "Rippe Anschlag SPG",
			en: "Stiffener stop OAD"
		}
		,A2V00002698327 : {
			de: "Rippe Distanz",
			en: "Stiffener distance"
		}
		,A2V00002697933 : {
			de: "Seitenblech 2 WG",
			en: "Siede plate 2 AD"
		}
		,A2V00002698285 : {
			de: "Seitenblech 1 WG",
			en: "Siede plate 1 AD3"
		}
		,A2V00002697688 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002697907 : {
			de: "Rippe 1 Anschlag WG",
			en: "Stiffener 1 stopper AD"
		}
		,A2V00002691038 : {
			de: "Deckblech WG",
			en: "Cover plate AD"
		}
		,A2V00002690910 : {
			de: "Deckblech SPG",
			en: "Cover plate OAD"
		}
		,A2V00002694156 : {
			de: "Stuetzblech",
			en: "Support plate"
		}
		,A2V00002698653 : {
			de: "Lagerplatte",
			en: "Support plate"
		}
		,A2V00002697841 : {
			de: "Rippe 2 Antenne WG",
			en: "Stiffener 2 antenna AD"
		}
		,A2V00002697784 : {
			de: "Rippe 2 Antenne SPG",
			en: "Stiffener antenna OAD"
		}
		,A2V00002696736 : {
			de: "Rippe 1",
			en: "Stiffener 1"
		}
		,A2V00002696689 : {
			de: "Querblech 1",
			en: "Cross plate 1"
		}
		,A2V00002696688 : {
			de: "Querblech 2",
			en: "Cross plate 2"
		}
		,A2V00002696659 : {
			de: "Seitenblech 2",
			en: "Side plate 2"
		}
		,A2V00002696660 : {
			de: "Seitenblech 1",
			en: "Side plate 1"
		}
		,A2V00002696442 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002696471 : {
			de: "Gurt 2",
			en: "Chord 2"
		}
		,A2V00002696496 : {
			de: "Gurt 1",
			en: "Chord 1"
		}
		,A2V00002696490 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00002696392 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00002696448 : {
			de: "Rippe",
			en: "Stiffener"
		}
		,A2V00002696435 : {
			de: "Winkel",
			en: "Angle"
		}
		,A2V00002693759 : {
			de: "Verschleissblech",
			en: "Wearing plate"
		}
		,A2V00002693846 : {
			de: "Anschlagrohr",
			en: "Connecting tube"
		}
		,A2V00002693780 : {
			de: "Anschlagplatte",
			en: "Stop plate"
		}
		,A2V00002694150 : {
			de: "Steg 1 SPG",
			en: "Web 1 OAD"
		}
		,A2V00002694424 : {
			de: "Steg 1 WG",
			en: "Web 1 AD"
		}
		,A2V00002694841 : {
			de: "Deckel",
			en: "Cap"
		}
		,A2V00002694785 : {
			de: "Rippe 2",
			en: "Stiffener 2"
		}
		,A2V00002694832 : {
			de: "Rippe 1",
			en: "Stiffener 1"
		}
		,A2V00002694830 : {
			de: "Winkel",
			en: "Angle"
		}
		,A2V00002694876 : {
			de: "Anschlussblech",
			en: "Connection plate"
		}
		,A2V00002693013 : {
			de: "Steg 1 SPG",
			en: "Web 1 OAD"
		}
		,A2V00002693148 : {
			de: "Steg 1 WG",
			en: "Web 1 AD"
		}
		,A2V00002700059 : {
			de: "Rippe 2 Lager WG",
			en: "Stiffener 2 bearing AD"
		}
		,A2V00002700050 : {
			de: "Rippe 1 Lager WG",
			en: "Stiffener 1 bearing AD"
		}
		,A2V00002700011 : {
			de: "Untergurt Ausleger WG",
			en: "Lower chord support AD"
		}
		,A2V00002693281 : {
			de: "Rippe 3 WG",
			en: "Stiffener 3 AD"
		}
		,A2V00002693062 : {
			de: "Anschweissplatte WG",
			en: "Connection plate AD"
		}
		,A2V00002700417 : {
			de: "Steg 2 WG",
			en: "Web 2 AD"
		}
		,A2V00002700353 : {
			de: "Steg 1 WG",
			en: "Web 1 AD"
		}
		,A2V00002693351 : {
			de: "Rippe 4",
			en: "Stiffener 4"
		}
		,A2V00002700369 : {
			de: "Rippe 2 Lager SPG",
			en: "Stiffener 2 bearing OAD"
		}
		,A2V00002700368 : {
			de: "Rippe 1 Lager SPG",
			en: "Stiffener 1 bearing OAD"
		}
		,A2V00002700416 : {
			de: "Untergurt Ausleger SPG",
			en: "Lower chord support OAD"
		}
		,A2V00002693529 : {
			de: "Rippe 1",
			en: "Stiffener 1"
		}
		,A2V00002693282 : {
			de: "Rippe 3 SPG",
			en: "Stiffener 3 OAD"
		}
		,A2V00002693061 : {
			de: "Anschweissplatte SPG",
			en: "Connection plate OAD"
		}
		,A2V00002700389 : {
			de: "Steg 2 Lager SPG",
			en: "Web 2 bearing OAD"
		}
		,A2V00002700367 : {
			de: "Steg 1 Lager SPG",
			en: "Web 1 bearing OAD"
		}
		,A2V00002688730 : {
			de: "Steg 2",
			en: "Web 2"
		}
		,A2V00002688754 : {
			de: "Steg 1",
			en: "Web 1"
		}
		,A2V00002688179 : {
			de: "Rippe 2",
			en: "Stiffener 2"
		}
		,A2V00002688162 : {
			de: "Rippe 1",
			en: "Stiffener 1"
		}
		,A2V00002687642 : {
			de: "Gurt 1 SPG",
			en: "Chord 1 OAD"
		}
		,A2V00002688161 : {
			de: "Gurt 1 WG",
			en: "Chord 1 AD"
		}
		,A2V00002688197 : {
			de: "Steg 1",
			en: "Web 1"
		}
		,A2V00002688756 : {
			de: "Rippe 7 WG",
			en: "Stiffener 7 AD"
		}
		,A2V00002688702 : {
			de: "Rippe 3",
			en: "Stiffener 3"
		}
		,A2V00002688786 : {
			de: "Rippe 5",
			en: "Stiffener 5"
		}
		,A2V00002688767 : {
			de: "Rippe 6",
			en: "Stiffener 6"
		}
		,A2V00002688716 : {
			de: "Wange",
			en: "Web"
		}
		,A2V00002121819 : {
			de: "Beschleunigungssensor mit Kabel",
			en: "Accelerometer with cable"
		}
		,A2V00002057273 : {
			de: "Dachblech vollst.",
			en: "roof sheeting complete"
		}
		,A2V00002122739 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screwÂ "
		}
		,A2V00002066570 : {
			de: "Dachplatte mech.bearb.",
			en: "roofplate machined"
		}
		,A2V00002073007 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00002133239 : {
			de: "Adapter, Vertikalanschlag",
			en: "Adapter, vertical support"
		}
		,A2V00002135025 : {
			de: "Kabelkanal UF 01 geschw.",
			en: "Cable duct UF 01 welded"
		}
		,A2V00002135156 : {
			de: "Kabelkanal UF 02 geschw.",
			en: "Cable duct UF 02 welded"
		}
		,A2V00002136132 : {
			de: "Kabelkanal UF 04 geschw.",
			en: "Cable duct UF 04 welded"
		}
		,A2V00002136133 : {
			de: "Kabelkanal UF 03 geschw.",
			en: "Cable duct UF 03 welded"
		}
		,A2V00002137926 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002137927 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002138724 : {
			de: "Winkel Fussnische",
			en: "Angle foot niche"
		}
		,A2V00397241885 : {
			de: "Montageplatte MG Bremse 161609R",
			en: "MOUNTING PLATE S. BRAKE 161609R"
		}
		,A2V00397233770 : {
			de: "Gewindestange",
			en: "Threaded bolt"
		}
		,A2V00397262111 : {
			de: "Buchse",
			en: "BUSH"
		}
		,A2V00397255641 : {
			de: "Fuehrungsstueck",
			en: "GUIDING PIECE"
		}
		,A2V00002534464 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00002612994 : {
			de: "Schallabsorber",
			en: "Sound-absorber"
		}
		,A2V00370070486 : {
			de: "Primaerfederbeilage 5 mm",
			en: "Shim primary suspension 5mm"
		}
		,A2V00001413097 : {
			de: "Manuelle Fernbetaetigung",
			en: "Manual remote apply"
		}
		,A2V00370043467 : {
			de: "Spannschlossmu DIN1480-SP M10-A3P",
			en: "Turnbuckle nut DIN1480-SP M10-A3P"
		}
		,A2V00370078036 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002143493 : {
			de: "Abdeckung",
			en: "Cover sheet"
		}
		,A2V00002252634 : {
			de: "Zeitbehaelter",
			en: "time reservoir"
		}
		,A2V00002148742 : {
			de: "Behaelter -Pumpen Kombination",
			en: "Tank-pump combination"
		}
		,A2V00002151829 : {
			de: "EP- Steuerung mit 3/2 Ventil",
			en: "EP- control with 3/2 valve"
		}
		,A2V00002264271 : {
			de: "Schlauchleitung",
			en: "Hose assembly"
		}
		,A2V00002707100 : {
			de: "Halter, sp.g., geschw.",
			en: "Holder, i.m.i., welded"
		}
		,A2V00002706990 : {
			de: "Auflage, sp.g., geschw.",
			en: "Support, i.m.i., welded"
		}
		,A2V00002145615 : {
			de: "Spange vorne",
			en: "Cramp front"
		}
		,A2V00002145661 : {
			de: "Spange",
			en: "Cramp"
		}
		,A2V00002145674 : {
			de: "Spaltabdeckung",
			en: "Gap cover"
		}
		,A2V00002145708 : {
			de: "Crimpkontaktbuchse",
			en: "Crimp contact female"
		}
		,A2V00002148973 : {
			de: "Blech sp.g.",
			en: "Sheet metal i.m.i."
		}
		,A2V00002150391 : {
			de: "Sonderwerkzeug fuer Luftfeder",
			en: "Special tool for air spring"
		}
		,A2V00002105301 : {
			de: "Galley Kaelteag.NK vollstaendig",
			en: "Refrig. unit gall. nc assy"
		}
		,A2V00002105843 : {
			de: "Langtraeger links kpl.",
			en: "longitudinal girder left, compl."
		}
		,A2V00002105845 : {
			de: "Langtraeger rechts kpl.",
			en: "longitudinal girder right, compl."
		}
		,A2V00002155817 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002110106 : {
			de: "Tuerfluegel Endeinstieg links",
			en: "Door leaf assemb. end side door left"
		}
		,A2V00002110107 : {
			de: "Tuerfluegel  Endeinstieg recht",
			en: "Door leaf assemb. end side door ri"
		}
		,A2V00002110108 : {
			de: "Tuerfluegel",
			en: "Door leaf assembly loading"
		}
		,A2V00002156100 : {
			de: "Auftritt Teil 1 w.g.",
			en: "Step part 1 a.d."
		}
		,A2V00002156101 : {
			de: "Auftritt Teil 1 sp.g.",
			en: "Step part 1 i.m.i."
		}
		,A2V00002156415 : {
			de: "Auftritt Teil 2",
			en: "Step part 2"
		}
		,A2V00102303922 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002158493 : {
			de: "Distanzhuelse",
			en: "Distance sleeve"
		}
		,A2V00002624502 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00002161472 : {
			de: "Gussteil oben w.g.",
			en: "Cast part at the top a.d."
		}
		,A2V00002161473 : {
			de: "Gussteil oben sp.g.",
			en: "Cast part at the top i.m.i."
		}
		,A2V00002161764 : {
			de: "Versteifung sp.g.",
			en: "Stiffener i.m.i."
		}
		,A2V00002163015 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002163878 : {
			de: "Halter, geschw.",
			en: "Holder, welded"
		}
		,A2V00002164160 : {
			de: "Gussteil unten w.g.",
			en: "Cast part at the bottom a.d."
		}
		,A2V00002164161 : {
			de: "Gussteil unten sp.g.",
			en: "Cast part at the bottom i.m.i."
		}
		,A2V00002128443 : {
			de: "Cube-06 ohne EP2002",
			en: "Cube-06 without EP2002"
		}
		,A2V00002165041 : {
			de: "Versteifung",
			en: "Stiffening"
		}
		,A2V00002165424 : {
			de: "Knotenblech 12  sp.g.",
			en: "Gusset plate 12  i.m.i."
		}
		,A2V00002165631 : {
			de: "Knotenblech 12 w.g.",
			en: "Gusset plate 12 a.d."
		}
		,A2V00002165695 : {
			de: "Ringleitungsrohr 02 vollst.",
			en: "Ring line pipe 02 compl."
		}
		,A2V00002165767 : {
			de: "Ringleitungsrohr 01 vollst.",
			en: "Ring line pipe 01 compl."
		}
		,A2V00002165816 : {
			de: "Ringleitungsrohr 08 vollst.",
			en: "Ring line pipe 08 compl."
		}
		,A2V00002165817 : {
			de: "Ringleitungsrohr 06 vollst.",
			en: "Ring line pipe 06 compl."
		}
		,A2V00002165818 : {
			de: "Ringleitungsrohr 05 vollst.",
			en: "Ring line pipe 05 compl."
		}
		,A2V00002165819 : {
			de: "Ringleitungsrohr 04 vollst.",
			en: "Ring line pipe 04 compl."
		}
		,A2V00002165820 : {
			de: "Ringleitungsrohr 03 vollst.",
			en: "Ring line pipe 03 compl."
		}
		,A2V00002165821 : {
			de: "Ringleitungsrohr 07 vollst.",
			en: "Ring line pipe 07 compl."
		}
		,A2V00112504608 : {
			de: "Fahr-/Bremsschalter S334 C33R",
			en: "drive/brake switch S334 C33R"
		}
		,A2V00002165857 : {
			de: "Formrohr 01 vollst.",
			en: "Hollow section 01 compl."
		}
		,A2V00002166043 : {
			de: "Formrohr 03 vollst.",
			en: "Hollow section 03 compl."
		}
		,A2V00002166044 : {
			de: "Formrohr 04 vollst.",
			en: "Hollow section 04 compl."
		}
		,A2V00002166045 : {
			de: "Formrohr 02 vollst.",
			en: "Hollow section 02 compl."
		}
		,A2V00002168000 : {
			de: "Quertraeger geschw.",
			en: "Cross member welded"
		}
		,A2V00002168478 : {
			de: "Hutprofil",
			en: "Hat profile"
		}
		,A2V00002168480 : {
			de: "Konsole, geschw.",
			en: "Console, welded"
		}
		,A2V00002187479 : {
			de: "Beilage 1mm",
			en: "Shim 1mm"
		}
		,A2V00002187480 : {
			de: "Beilage 5mm",
			en: "Shim 5mm"
		}
		,A2V00002187481 : {
			de: "Beilage 2mm",
			en: "Shim 2mm"
		}
		,A2V00113143263 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00113143792 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screwÂ "
		}
		,A2V00200505405 : {
			de: "Ringmutter",
			en: "lifting eyenut"
		}
		,A2V00002143544 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002144441 : {
			de: "Kuehlanlage",
			en: "Cooling unit"
		}
		,A2V00002170058 : {
			de: "Dachabschluss 1, vollst.",
			en: "Roof end 1, compl."
		}
		,A2V00002170062 : {
			de: "Sickenblech Dach",
			en: "Corrugated sheet roof"
		}
		,A2V00002170066 : {
			de: "Dachlangtraeger, vollst. sp.g.",
			en: "Roof main beam, compl. i.m.i."
		}
		,A2V00002170067 : {
			de: "Dachlangtraeger, vollst. w.g.",
			en: "Roof main beam, compl. a.d."
		}
		,A2V00002682315 : {
			de: "Beilage Luftfeder 10mm",
			en: "Shim air spring 10mm"
		}
		,A2V00002682317 : {
			de: "Beilage Luftfeder 5mm",
			en: "Shim air spring 5mm"
		}
		,A2V00002170190 : {
			de: "Kopf Vorbau",
			en: "Head front end"
		}
		,A2V00002170199 : {
			de: "Untergestell Mittelteil",
			en: "Underframe middle part"
		}
		,A2V00002170223 : {
			de: "Endteil EG",
			en: "End part single artic"
		}
		,A2V00002170224 : {
			de: "Endteil Kopf",
			en: "End part front end"
		}
		,A2V00002170233 : {
			de: "Gelenktraeger EG",
			en: "Support single artic"
		}
		,A2V00002170255 : {
			de: "Schubfeld geschw. sp.g.",
			en: "Shear field welded i.m.i."
		}
		,A2V00002170797 : {
			de: "Schubfeld geschw. w.g.",
			en: "Shear field welded a.d."
		}
		,A2V00370025444 : {
			de: "Gelenklager",
			en: "Spherical bearing"
		}
		,A2V00370026736 : {
			de: "Augenschraube",
			en: "Eye bolt"
		}
		,A2V00370037134 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00002170984 : {
			de: "Dachabschluss 3, vollst.",
			en: "Roof end 3, compl."
		}
		,A2V00002171018 : {
			de: "Sickenblech Dach",
			en: "Corrugated sheet roof"
		}
		,A2V00002171020 : {
			de: "Dachlangtraeger, vollst.",
			en: "Roof main beam, compl."
		}
		,A2V00002171097 : {
			de: "Endteil DG",
			en: "End part double artic"
		}
		,A2V00002171244 : {
			de: "Kasten vollst. f. Kurzschliesser",
			en: "Box compl. f. short-circuiting device"
		}
		,A2V00002171604 : {
			de: "Gelenktraeger DG",
			en: "Support double artic."
		}
		,A2V00002172180 : {
			de: "Sickenblech Dach",
			en: "Corrugated sheet roof"
		}
		,A2V00002172968 : {
			de: "Deckel 01 vollst. Durchfuehrungskasten",
			en: "Cover 01 compl. lead through box"
		}
		,A2V00002174043 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370052924 : {
			de: "POLRAD INCL.VERSCHRAUBUNG",
			en: "POLRAD INCL.VERSCHRAUBUNG"
		}
		,A2V00002158692 : {
			de: "GR Theke",
			en: "GR Bar Module"
		}
		,A2V00002175336 : {
			de: "Erdungslasche Multi",
			en: "Earthing multi"
		}
		,A2V00370025394 : {
			de: "Kleber (EP) Scotch-Weld DP 490 schwarz",
			en: "Adhesive(EP) Scotch-Weld DP 490 schwarz"
		}
		,A2V00370043446 : {
			de: "WINKELGELENK DIN71802-CS10-A3C",
			en: "angle joint  DIN71802-CS10-A3C"
		}
		,A2V00002121983 : {
			de: "Kuehlanlage",
			en: "Cooling system"
		}
		,A2V00001418430 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002175887 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00200375990 : {
			de: "Hohlprofil",
			en: "Hollow profile"
		}
		,A2V00002562380 : {
			de: "Drehzahlgeber GEL 2475",
			en: "Speed sensor GEL2475"
		}
		,A2V00001796682 : {
			de: "Netzdrossel",
			en: "Line reactor"
		}
		,A2V00102200252 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002178053 : {
			de: "Versteifung sp.g.",
			en: "Stiffener i.m.i."
		}
		,A2V00002178092 : {
			de: "Versteifung w.g.",
			en: "Stiffener a.d."
		}
		,A2V00002370290 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screwÂ "
		}
		,A2V00194009194 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002423231 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00397165373 : {
			de: "DREHSTAB,MECH.BEARB.",
			en: "torsion bar, machined"
		}
		,A2V00370046047 : {
			de: "Kohlebuerste",
			en: "Carbon brush"
		}
		,A2V00397220463 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002188570 : {
			de: "Halter f. Fahrscheinautomat sp.g.",
			en: "Bracket f. ticket machine i.m.i."
		}
		,A2V00002188572 : {
			de: "Halter f. Fahrscheinautomat sp.g.",
			en: "Bracket f. ticket machine i.m.i."
		}
		,A2V00002189533 : {
			de: "Anschweisswinkel vollst.",
			en: "Welding backet compl."
		}
		,A2V00002168529 : {
			de: "Tuerblatt, links Konfiguration",
			en: "doorleaf, left assembly"
		}
		,A2V00002168530 : {
			de: "Tuerblatt, rechts Konfiguration",
			en: "doorleaf, right assembly"
		}
		,A2V00002189738 : {
			de: "Schubfeld geschw.",
			en: "Shear field welded"
		}
		,A2V00207473863 : {
			de: "Spannhalter geschweisst",
			en: "clamping fixture welded"
		}
		,A2V00207473871 : {
			de: "Spannhalter geschw",
			en: "clamping fixture welded"
		}
		,A2V00200506511 : {
			de: "Sicherungsmutter",
			en: "prevailing torque type hexagon nut"
		}
		,A2V00001421577 : {
			de: "Luefter, radial",
			en: "fan, radial"
		}
		,A2V00002191354 : {
			de: "Rollenfuehrung komplett",
			en: "Roller guide"
		}
		,A2V00002191355 : {
			de: "Tuerblatt, rechts, vollst.",
			en: "Door sheet, right, complete"
		}
		,A2V00002191366 : {
			de: "Tuerblatt, links, voll.",
			en: "Door sheet, left, complete"
		}
		,A2V00002209051 : {
			de: "Halteblock Wankstablager",
			en: "Fixing block roll system"
		}
		,A2V00002209119 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screwÂ "
		}
		,A2V00002211751 : {
			de: "Wartungsklappe",
			en: "Maintenance cover"
		}
		,A2V00002213092 : {
			de: "Traktionsanschlagplatte",
			en: "Traction end stop plate"
		}
		,A2V00002214957 : {
			de: "Haengewinkel",
			en: "Hanging bracket"
		}
		,A2V00002215318 : {
			de: "Klemmblech",
			en: "Clamping plate"
		}
		,A2V00002215473 : {
			de: "Beilage, Queranschlag",
			en: "Shim, cross catch"
		}
		,A2V00002215500 : {
			de: "Befestigungswinkel",
			en: "Fixation bracket"
		}
		,A2V00002215586 : {
			de: "Deckblech",
			en: "Cover sheet"
		}
		,A2V00001192997 : {
			de: "Distanzhuelse H=27mm, unlackiert",
			en: "Support H=27mm, uncoated"
		}
		,A2V00002223627 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00001846540 : {
			de: "Blindfeld 06 kompl.",
			en: "blind panel 06 compl."
		}
		,A2V00001848628 : {
			de: "Schaltwalze",
			en: "Drum switch"
		}
		,A2V00001691955 : {
			de: "GFK-Seitenteil, vollst., links",
			en: "GRP side part, compl. left"
		}
		,A2V00397218772 : {
			de: "Beilage 5mm f. Querpuffer",
			en: "Shim 5mm f. lateral buffer"
		}
		,A2V00001440001 : {
			de: "Bugspitzenring",
			en: "nose cone"
		}
		,A2V00001911973 : {
			de: "Signalleuchte unten links",
			en: "Lower signal light left"
		}
		,A2V00002078917 : {
			de: "Spiegeltuer 600 vollst.  f. Spiegelschr.",
			en: "mirror door 600 compl. f.mirror cabinet"
		}
		,A2V00002009997 : {
			de: "Abwasserklappe oben",
			en: "Waste water flap top"
		}
		,A2V00002216231 : {
			de: "Tuerblatt, komplett",
			en: "Door leaf complete"
		}
		,A2V00001120846 : {
			de: "Multifunction Vehicle Bus 32 (MVB32)",
			en: "Multifunction Vehicle Bus 32"
		}
		,A2V00001985131 : {
			de: "Seitenklappe vollst.",
			en: "side flap complete"
		}
		,A2V00001349517 : {
			de: "Impulsgeber FS01A, einkanalig",
			en: "Speed Sensor FS01A, single channel"
		}
		,A2V00002116585 : {
			de: "Crashkopf",
			en: "Crash head absorber"
		}
		,A2V00001911971 : {
			de: "Signalleuchte unten rechts",
			en: "Lower signal light right"
		}
		,A2V00002719796 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002226015 : {
			de: "Befestigung Kupplung vollst.",
			en: "Fastening coupler compl."
		}
		,A2V00110050138 : {
			de: "Stahlrohr",
			en: "Steel tube"
		}
		,A2V00002230621 : {
			de: "Gewindeblech",
			en: "Thread sheet"
		}
		,A2V00002224262 : {
			de: "HS-Spirale",
			en: "HV-spiral"
		}
		,A2V00002233185 : {
			de: "Befestigung Kupplung vollst.",
			en: "Mounting coupler compl."
		}
		,A2V00002233186 : {
			de: "Gewindeklotz",
			en: "Thread block"
		}
		,A2V00002233515 : {
			de: "Konsole, vollst.",
			en: "Panel, compl."
		}
		,A2V00002233516 : {
			de: "Distanzhalter",
			en: "Spacer"
		}
		,A2V00370080988 : {
			de: "Schlauchleitung",
			en: "Hose assembly"
		}
		,A2V00002236206 : {
			de: "Kabelhalter vollst.",
			en: "Cable bracket compl."
		}
		,A2V00002721838 : {
			de: "Ausgleichsplatte w.g.",
			en: "compensating plate as d."
		}
		,A2V00002721839 : {
			de: "Ausgleichsplatte sp.g.",
			en: "compensating plate r.i."
		}
		,A2V00002237686 : {
			de: "Abstandsplatte",
			en: "Distance plate"
		}
		,A2V00002240389 : {
			de: "Gewindeeinleger",
			en: "Thread inlay"
		}
		,A2V00002249796 : {
			de: "Unterlage Blindnietmutter",
			en: "Washer blind rivet nut"
		}
		,A2V00082044982 : {
			de: "Radarsensor DRS05/1a mit SW51-031M",
			en: "Radar sensor DRS05/1a with SW51-031M"
		}
		,A2V00002257256 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00002257257 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00002257258 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00002257260 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00002264258 : {
			de: "Buchse",
			en: "bush"
		}
		,A2V00002051289 : {
			de: "Schichtfeder",
			en: "layer spring"
		}
		,A2V00002061612 : {
			de: "Schraubendruckfeder prim. 3",
			en: "Helical compression spring prim. 3"
		}
		,A2V00117611000 : {
			de: "Schuerze rechts verkuerzt",
			en: "Spoiler right shortened"
		}
		,A2V00001441539 : {
			de: "Tragrahmen kompl.,geschweisst",
			en: "supporting frame  complete welded"
		}
		,A2V00001677078 : {
			de: "Konsole Keillager M20",
			en: "console v-mount M20"
		}
		,A2V00002090706 : {
			de: "Schienenraeumer sp.g.",
			en: "rail  guard o.a.d."
		}
		,A2V00001896172 : {
			de: "Stromabnehmer 1950/AC",
			en: "Pantograph 1950/AC"
		}
		,A2V00002076197 : {
			de: "Drehsaeule vollst. links",
			en: "Rotating column compl. left"
		}
		,A2V00002269990 : {
			de: "Zylinderschraube",
			en: "Cylinder bolt"
		}
		,A2V00001112256 : {
			de: "Fahrschalter Standard",
			en: "Driver switch standard"
		}
		,A2V00001904958 : {
			de: "Sechskantmutter ISO 7042 M30 8",
			en: "Hexagon nut ISO 7042 M30 8"
		}
		,A2V00002271072 : {
			de: "Bremskupplung",
			en: "brake coupling"
		}
		,A2V00002271073 : {
			de: "Bremskupplung",
			en: "brake coupling"
		}
		,A2V00370052394 : {
			de: "Konusfeder",
			en: "conical spring"
		}
		,A2V00001887962 : {
			de: "Distanzscheibe 5mm",
			en: "spacer 5mm"
		}
		,A2V00159201965 : {
			de: "Nutenstein M10",
			en: "Tenon block M10"
		}
		,A2V00002040822 : {
			de: "Beilage 10 mm",
			en: "spring shim 10 mm"
		}
		,A2V00002040922 : {
			de: "Beilage 1 mm",
			en: "spring shim 1 mm"
		}
		,A2V00002040920 : {
			de: "Beilage 2 mm",
			en: "spring shim 2 mm"
		}
		,A2V00002040919 : {
			de: "Beilage 5mm",
			en: "spring shim 5 mm"
		}
		,A2V00002066842 : {
			de: "Beilage 1 mm",
			en: "Shim 1 mm"
		}
		,A2V00002067129 : {
			de: "Beilage 2 mm",
			en: "Shim 2 mm"
		}
		,A2V00001707882 : {
			de: "Polrad",
			en: "poole whell"
		}
		,A2V00002057788 : {
			de: "Hohlbuchse",
			en: "cylindrical bush"
		}
		,A2V00002001200 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical shock absorber"
		}
		,A2V00116215433 : {
			de: "KONSOLE TUERAUFH.",
			en: "KONSOLE TUERAUFH."
		}
		,A2V00002252974 : {
			de: "Glastrennwand ASM",
			en: "Glass part.wall ASM"
		}
		,A2V00001420278 : {
			de: "Bugspoiler unten vst. w.g.",
			en: "spoiler bottom compl.a.d."
		}
		,A2V00001896514 : {
			de: "Seitenklappe vollst.",
			en: "side flap complete"
		}
		,A2V00001846539 : {
			de: "Blindfeld 05 kompl.",
			en: "blind panel 05 compl."
		}
		,A2V00105553784 : {
			de: "Achsschluessel",
			en: "axle rod"
		}
		,A2V00370061258 : {
			de: "Daempfer primaer vertikal",
			en: "primary vertical damper"
		}
		,A2V00001209150 : {
			de: "Haengung links",
			en: "hanger left"
		}
		,A2V00370061266 : {
			de: "Daempfer sekundaer horizontal",
			en: "secondary horizontal damper"
		}
		,A2V00397183647 : {
			de: "3/3 WEGEVENTIL",
			en: "SELECTOR VALVE KNORR 2U14385"
		}
		,A2V00102084260 : {
			de: "KUGELDREHVERBINDUNG",
			en: "Ball bearing slewingring"
		}
		,A2V00001803371 : {
			de: "Fahrmotor Korea 2",
			en: "Traction drive Korea 2"
		}
		,A2V00370054963 : {
			de: "SCHLAUCHVERBINDUNG          I75921/04806",
			en: "HOSE CONNECTION             I75921/04806"
		}
		,A2V00370025159 : {
			de: "RD DIN1013-35-S355JO",
			en: "RD DIN1013-35-S355JO"
		}
		,A2V00370026889 : {
			de: "RD DIN1013-30-S355JO",
			en: "RD DIN1013-30-S355JO"
		}
		,A2V00370026890 : {
			de: "Rundstab",
			en: "Round bar"
		}
		,A2V00370011689 : {
			de: "FL DIN1017-60x5-S235JRG2",
			en: "FL DIN1017-60x5-S235JRG2"
		}
		,A2V00002279680 : {
			de: "Hals geschweisst",
			en: "Socket welded"
		}
		,A2V00002280260 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002262431 : {
			de: "Winkel",
			en: "angle"
		}
		,A2V00002268922 : {
			de: "KKG FST HVAC unit cabin",
			en: "Air conditioning unit Cab"
		}
		,A2V00002268923 : {
			de: "Klimageraet Fahrgastraum",
			en: "HVAC unit saloon"
		}
		,A2V00370036311 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002119150 : {
			de: "Getriebe mit Treibradsatzwelle",
			en: "Gearbox with motor axle"
		}
		,A2V00002155134 : {
			de: "Bremsbelag,  KRS400SOZS35",
			en: "Brake pad,  KRS400SOZS35"
		}
		,A2V00370081056 : {
			de: "Beilage 10mm",
			en: "Shim 10mm"
		}
		,A2V00002281048 : {
			de: "Hublift komplett, rechts INV-300/ 287",
			en: "Lift complete, right     INV-300/ 287"
		}
		,A2V00002281049 : {
			de: "Hublift komplett, links  INV-300/287",
			en: "Lift complete, left      INV-300/287"
		}
		,A2V00370081055 : {
			de: "Beilage 5mm",
			en: "Shim 5mm"
		}
		,A2V00370067644 : {
			de: "ERDUNGSKONTAKT",
			en: "GROUNDING DEVICE"
		}
		,A2V00001756403 : {
			de: "Radbremszange RZ44UF10/8KWS135",
			en: "brake caliper/wheel m. RZ44UF10/8KWS135"
		}
		,A2V00001756327 : {
			de: "Radbremszange RZ44UP10XS135",
			en: "brake caliper/wheel m. RZ44UP10XS135"
		}
		,A2V00002097300 : {
			de: "Montagematerial Lagerdeckel",
			en: "Mounting material Bearing cover"
		}
		,A2V00001939517 : {
			de: "Halter, geschw.",
			en: "Holder, welded"
		}
		,A2V00370032429 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001996285 : {
			de: "Kronenmutter",
			en: "Slotter nut"
		}
		,A2V00397218707 : {
			de: "Beilage 1 mm",
			en: "Shim 1mm"
		}
		,A2V00002606401 : {
			de: "Gleitplatte 7",
			en: "Sliding plate 7"
		}
		,A2V00002169699 : {
			de: "Adapter B",
			en: "Adapter B"
		}
		,A2V00002169700 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002169701 : {
			de: "Gewindestange",
			en: "Thread rod"
		}
		,A2V00002556421 : {
			de: "Schloss kompl.",
			en: "Lock completed"
		}
		,A2V00001921561 : {
			de: "Schlauchleitung 2TE",
			en: "Hose Pipe 2TE"
		}
		,A2V00001921560 : {
			de: "Schlauchleitung 2TE",
			en: "Hose Pipe 2TE"
		}
		,A2V00001950771 : {
			de: "Kabel Sensor Lager 90Â° li",
			en: "Cable sensor bear. 90Â° le"
		}
		,A2V00001927254 : {
			de: "Batterie 24V 300Ah",
			en: "Battery 24V 300Ah"
		}
		,A2V00002215186 : {
			de: "Ring 1 WankstÃ¼tze",
			en: "ring 1 anti roll bar"
		}
		,A2V00002531812 : {
			de: "Dichtungskit",
			en: "Sealing kit"
		}
		,A2V00002233239 : {
			de: "Batterietrog",
			en: "Battery box"
		}
		,A2V00001921559 : {
			de: "Schlauchleitung 2TE",
			en: "Hose Pipe 2TE"
		}
		,A2V00156018348 : {
			de: "SCHNITTSTELLEN-    UMSETZER RS 422",
			en: "interface converter   RS 422"
		}
		,A2V00001384367 : {
			de: "Beschleunigungssensor",
			en: "ACCELERAT. SENSOR"
		}
		,A2V00370024861 : {
			de: "6KT-MU BN205107-A-M24-8-A3P",
			en: "HEX.NUT BN205107-A-M24-8-A3P"
		}
		,A2V00117413254 : {
			de: "SANDKASTEN LINKS   VOLLST.",
			en: "SANDKASTEN LINKS   VOLLST."
		}
		,A2V00370084715 : {
			de: "BUCHSE F.WANKSTABILISATOR D=61MM",
			en: "BEARING BUSH FOR ROLL STABILISER D=61MM"
		}
		,A2V00001807310 : {
			de: "Huelse",
			en: "bush"
		}
		,A2V00370049659 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370035569 : {
			de: "Radsatzfuehrungsbuchse",
			en: "wheel set guiding bush"
		}
		,A2V00397163622 : {
			de: "FEDERBEILAGE 2MM",
			en: "Spring Washer"
		}
		,A2V00002435447 : {
			de: "Deckel, kpl",
			en: "Cover, coml"
		}
		,A2V00002435446 : {
			de: "Deckel, kpl",
			en: "Cover, coml"
		}
		,A2V00002435448 : {
			de: "Deckel, kpl",
			en: "Cover, coml"
		}
		,A2V00002435449 : {
			de: "Deckel, kpl",
			en: "Cover, coml"
		}
		,A2V00001846938 : {
			de: "Bremsgeruest EW8",
			en: "brake control module EW8"
		}
		,A2V00370029632 : {
			de: "PrimÃ¤rschichtfeder",
			en: "Primary layer spring"
		}
		,A2V00113041740 : {
			de: "Schwenkverschraubung G1/2 * A18-M26*1.5",
			en: "Banjo fitting G1/2 * A18-M26*1.5"
		}
		,A2V00397155077 : {
			de: "Drehzapfenlager",
			en: "Center pivot bearing"
		}
		,A2V00370033122 : {
			de: "Querpuffer Gruppe 2",
			en: "Lateral buffer group 2"
		}
		,A2V00002201551 : {
			de: "Tuerfluegel links",
			en: "Door leaf left"
		}
		,A2V00397134774 : {
			de: "Bolzen f. Aufhaengung",
			en: "Bolt for hanging"
		}
		,A2V00002612364 : {
			de: "Geraeteeinheit ZGE-203",
			en: "assembly ZGE-203"
		}
		,A2V00002030433 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370024856 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370030845 : {
			de: "Drehdaempfer",
			en: "yaw damper"
		}
		,A2V00001966259 : {
			de: "Beilage 2MM",
			en: "Shim 2MM"
		}
		,A2V00001966260 : {
			de: "Beilage 5MM",
			en: "Shim 5MM"
		}
		,A2V00001861003 : {
			de: "Hohlbuchse",
			en: "Cylindrical Bush"
		}
		,A2V00001878221 : {
			de: "Radsatzlagerdeckel, hinten",
			en: "axle box rear cover,back"
		}
		,A2V00002697896 : {
			de: "Schlauch Hochdruck SK6005",
			en: "Hose high pressure SK6005"
		}
		,A2V00001910632 : {
			de: "Zug-/ Druckstange",
			en: "pull-/ push rod"
		}
		,A2V00002051293 : {
			de: "Schraubendruckfeder",
			en: "Helical compression spring"
		}
		,A2V00002561783 : {
			de: "Getriebe mit Kupplungshaelfte",
			en: "Gearbox"
		}
		,A2V00082001215 : {
			de: "Abschirmblech mit Klappverriegelung",
			en: "schielding plate"
		}
		,A2V00370033121 : {
			de: "Querpuffer Gruppe 1",
			en: "Lateral buffer group 1"
		}
		,A2V00370032915 : {
			de: "Querpuffer Motortraeger",
			en: "Cross buffer motor support"
		}
		,A2V00001821756 : {
			de: "Sekundaerfedersystem",
			en: "Secondary spring system"
		}
		,A2V00397263163 : {
			de: "Bolzen f. Antennentraeger",
			en: "Bolt f. antenna beam"
		}
		,A2V00370068043 : {
			de: "ISOLIERSCHLAUCH HF KAIFLEX 9X42",
			en: "ISOLIERSCHLAUCH HF KAIFLEX 9X42"
		}
		,A2V00002234677 : {
			de: "Luftfederbeilage 1 mm",
			en: "Shim for air spring 1 mm"
		}
		,A2V00370038699 : {
			de: "RO ISO1127-35x2-D4T3-S-1.4301+M",
			en: "TUBE ISO1127-35x2-D4T3-S-1.4301+M"
		}
		,A2V00100641960 : {
			de: "Megi-Puffer",
			en: "BUFFER STOP"
		}
		,A2V00207310971 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00001939061 : {
			de: "Schallabsorber komplett",
			en: "sound absorber complete"
		}
		,A2V00001989004 : {
			de: "Puffer",
			en: "buffer"
		}
		,A2V00001815690 : {
			de: "Lenker, mech. bearbeitet",
			en: "Guiding rod, machined"
		}
		,A2V00001845853 : {
			de: "MAK-Schichtfeder RAL9005",
			en: "layer spring"
		}
		,A2V00001821325 : {
			de: "Lamellendichtring",
			en: "Fey lamella ring"
		}
		,A2V00001821377 : {
			de: "Lamellendichtring",
			en: "Fey lamella ring"
		}
		,A2V00001821378 : {
			de: "Lamellendichtring",
			en: "Fey lamella ring"
		}
		,A2V00001718301 : {
			de: "Decklack Etokat Aqua DS RAL 9005",
			en: "outer layer_Etokat Aqua DS_RAL 9005"
		}
		,A2V00002360372 : {
			de: "Gewindebolzen",
			en: "theaded bolt"
		}
		,A2V00113145910 : {
			de: "EINBAUSATZ,NACHRUESTUNG BYPASS",
			en: "kit backfitting bypass"
		}
		,A2V00002078400 : {
			de: "Klemmblech Gr. 3",
			en: "clamping plate Size 3"
		}
		,A2V00002128442 : {
			de: "Cube-05 ohne EP2002",
			en: "CUBE-05 without EP2002"
		}
		,A2V00001851669 : {
			de: "Frontmaske, vollst.",
			en: "front end, compl."
		}
		,A2V00001808409 : {
			de: "Luftfederbalg",
			en: "Air spring system"
		}
		,A2V00001924080 : {
			de: "Wiegand Radsensor",
			en: "Wiegand Wheel Sensor 8S"
		}
		,A2V00370060499 : {
			de: "Daempfer sekundaer horizontal",
			en: "secondary horizontal damper"
		}
		,A2V00370037963 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370062887 : {
			de: "HALTESCHEIBE CAMLOC-50E2-3BP",
			en: "clamp disc  CAMLOC-50E2-3BP"
		}
		,A2V00370024362 : {
			de: "VERBINDUNGSSTANGE",
			en: "connection rod"
		}
		,A2V00311421738 : {
			de: "4KT DIN1014-8-S235JRG2",
			en: "SQUARE DIN1014-8-S235JRG2"
		}
		,A2V00002275455 : {
			de: "Dachblech         kpl.",
			en: "roof sheeting     compl."
		}
		,A2V00002275943 : {
			de: "Bodenplatte vollst.",
			en: "floor plate compl."
		}
		,A2V00002754921 : {
			de: "Umruestung Isolierschlauch",
			en: "repair kit insulating hose"
		}
		,A2V00002714764 : {
			de: "Unterlage",
			en: "Shim"
		}
		,A2V00002682957 : {
			de: "Erdungsblech 1",
			en: "Grounding sheet 1"
		}
		,A2V00002375954 : {
			de: "Abfallbehaelter",
			en: "Waste bin"
		}
		,A2V00000379502 : {
			de: "LZB-Empfangs-Antenne 36kHz mit Flansch",
			en: "CATR-receiving aerial 36kHz with flange"
		}
		,A2V00000379503 : {
			de: "LZB-Sendeantenne 56kHz mit Flansch",
			en: "CATR-transmitting aerial 56kHz w. flange"
		}
		,A2V00397155686 : {
			de: "RIPPE",
			en: "rip"
		}
		,A2V00397155685 : {
			de: "EINSTELLPLATTE",
			en: "adjusting plate"
		}
		,A2V00397155684 : {
			de: "ANSCHLUSZPLATTE",
			en: "connecting plate"
		}
		,A2V00001669269 : {
			de: "Grundbeilage1",
			en: "priming shim1"
		}
		,A2V00001127800 : {
			de: "Stromversorgung 72V-110V/24V, 100W",
			en: "Converter 72V-110V/24V, 100W"
		}
		,A2V00370033004 : {
			de: "6KT-MU BN205107-A-M30-8-A3C",
			en: "HEX.NUT BN205107-A-M30-8-A3C"
		}
		,A2V00370036761 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001863057 : {
			de: "Schutzhaube Motortemperaturgeber",
			en: "protection cover engine temper. sensor"
		}
		,A2V00370080163 : {
			de: "Spuelventil SEMCO 51127",
			en: "Rinse valve SEMCO 51127"
		}
		,A2V00002377671 : {
			de: "Dichtblech A",
			en: "Sealing sheet A"
		}
		,A2V00002378638 : {
			de: "Dichtblech Erdung",
			en: "Sealing sheet earthing"
		}
		,A2V00002388774 : {
			de: "Entwaesserungsrohr",
			en: "Drainage pipe"
		}
		,A2V00002388775 : {
			de: "Verzurrlasche",
			en: "Lash plate"
		}
		,A2V00002388924 : {
			de: "Seitenwandfeld bearb.",
			en: "Side wall plate machined"
		}
		,A2V00002388926 : {
			de: "Seitenwandsaeule 1 vollst. w.g.",
			en: "Side wall pillar 1 compl. a.d."
		}
		,A2V00002388927 : {
			de: "Seitenwandsaeule 1 vollst. sp.g.",
			en: "Side wall pillar 1 compl. i.m.i."
		}
		,A2V00002388928 : {
			de: "Fenstersaeule 1 vollst. w.g.",
			en: "Window pillar 1 compl. a.d."
		}
		,A2V00002389066 : {
			de: "Stirnwand WE2 AB",
			en: "End-wall CE2 MTG"
		}
		,A2V00002389286 : {
			de: "Zwischenprofil Einzelteil",
			en: "Part profile floor"
		}
		,A2V00002389288 : {
			de: "Anschluss Bodenplatte bearb.",
			en: "Floor connection machined"
		}
		,A2V00002389289 : {
			de: "Langtraeger Abschluss sp.g.",
			en: "Longitudinal closing beam i.m.i."
		}
		,A2V00002389295 : {
			de: "Langtraeger Abschluss w.g.",
			en: "Longitudinal closing beam a.d."
		}
		,A2V00002389322 : {
			de: "Bodenprofil f. Vorbau vollst.",
			en: "Floor profile f. front end compl."
		}
		,A2V00002389338 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002389339 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002389341 : {
			de: "Gurt w.g.",
			en: "Belt a.d."
		}
		,A2V00002389355 : {
			de: "Tragbuegel",
			en: "Mounting bracket"
		}
		,A2V00002389356 : {
			de: "Dichtblech sp.g.",
			en: "Close sheet i.m.i."
		}
		,A2V00002389357 : {
			de: "Dichtblech w.g.",
			en: "Close sheet a.d."
		}
		,A2V00002389421 : {
			de: "Langtraeger Abschluss sp.g.",
			en: "Longitudinal closing beam i.m.i."
		}
		,A2V00002305233 : {
			de: "Deckel vst.",
			en: "Cover cpl."
		}
		,A2V00002389424 : {
			de: "Rippe w.g.",
			en: "Rib a.d."
		}
		,A2V00002389426 : {
			de: "Langtraeger Abschluss w.g.",
			en: "Longitudinal closing beam a.d."
		}
		,A2V00002389427 : {
			de: "Abschlussblech",
			en: "Close sheet"
		}
		,A2V00002305261 : {
			de: "Deckel vst.",
			en: "Cover cpl."
		}
		,A2V00002389449 : {
			de: "Tuersaeule vollst. WE1 w.g.",
			en: "Door pillar compl. CE1 a.d."
		}
		,A2V00002389451 : {
			de: "Tuersaeule vollst. WE1 sp.g.",
			en: "Door pillar compl. CE1 i.m.i."
		}
		,A2V00002389469 : {
			de: "Rippe sp.g.",
			en: "Rib i.m.i."
		}
		,A2V00002389470 : {
			de: "Rippe w.g.",
			en: "Rib a.d."
		}
		,A2V00002389477 : {
			de: "Halter f. Haltestange w.g.",
			en: "Bracket f. handrail a.d."
		}
		,A2V00002389478 : {
			de: "Halter vollst. w.g.",
			en: "Bracket compl. a.d."
		}
		,A2V00002389479 : {
			de: "Halter vollst. sp.g.",
			en: "Bracket compl. i.m.i."
		}
		,A2V00002389482 : {
			de: "Halter vollst.",
			en: "Bracket compl."
		}
		,A2V00002389483 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002391074 : {
			de: "Fenstersaeule 1 vollst. sp.g.",
			en: "Window pillar 1 compl. i.m.i."
		}
		,A2V00207358062 : {
			de: "Spruehduese",
			en: "nozzle"
		}
		,A2V00001803641 : {
			de: "Voutenhalter MM",
			en: "Cover bracket mm"
		}
		,A2V00001378260 : {
			de: "Deflektorplatte vollst.",
			en: "Deflector plate complete"
		}
		,A2V00002309164 : {
			de: "Bodenplatte vollst.",
			en: "floor plate compl."
		}
		,A2V00370035738 : {
			de: "DICHTRING",
			en: "sealing ring"
		}
		,A2V00001946865 : {
			de: "Sekundaerdaempfer, horiz.",
			en: "Secondary damper, horiz."
		}
		,A2V00113025791 : {
			de: "Stahlrohr",
			en: "Steel tube"
		}
		,A2V00397215060 : {
			de: "Notfangblech",
			en: "Emergency catch plate"
		}
		,A2V00002101918 : {
			de: "Traeger f. Sandung w.g. lackiert",
			en: "Support f. sander a.d. painted"
		}
		,A2V00001811588 : {
			de: "Beilage fuer Sandung und Schienraeumer",
			en: "Washer for sanding and railguard"
		}
		,A2V00370030857 : {
			de: "Primaerdaempfer, vertikal",
			en: "Primary damper, vertical"
		}
		,A2V00002725777 : {
			de: "Verbindungsblech",
			en: "Bracket"
		}
		,A2V00002725584 : {
			de: "Steckblech",
			en: "Bracket"
		}
		,A2V00002727266 : {
			de: "Verbindungsblech 2",
			en: "Bracket 2"
		}
		,A2V00370072494 : {
			de: "Drehstabfeder",
			en: "anti roll bar"
		}
		,A2V00397254243 : {
			de: "LAGERBOCK VOLL.",
			en: "bearing  block  compl."
		}
		,A2V00370076369 : {
			de: "ANLAUFSCHEIBE WANKSTUETZE",
			en: "thrust washer  anti roll bar"
		}
		,A2V00370061628 : {
			de: "Radbremszange RZS50K27X135",
			en: "brake caliper/wheel m. RZS50K27X135"
		}
		,A2V00002311242 : {
			de: "Bodenplatte vollst.",
			en: "floor plate"
		}
		,A2V00002398113 : {
			de: "Aufnahme, SWT",
			en: "Support, wp"
		}
		,A2V00002398115 : {
			de: "Traeger",
			en: "Beam"
		}
		,A2V00002398116 : {
			de: "Federlagerung, SWT",
			en: "Spring support, wp"
		}
		,A2V00001849188 : {
			de: "Trennschalter Stromabnehmer AC",
			en: "Disconnector for Pantograph AC"
		}
		,A2V00002398126 : {
			de: "Isolierstueck",
			en: "Insulator"
		}
		,A2V00002692597 : {
			de: "Stromabnehmer Bangkok BL rechts komplett",
			en: "Shoegear Bangkok BL right compl."
		}
		,A2V00002692598 : {
			de: "Stromabnehmer Bangkok BL links komplett",
			en: "Shoegear Bangkok BL left compl."
		}
		,A2V00200500113 : {
			de: "Halfenschraube",
			en: "halfen screw"
		}
		,A2V00001672428 : {
			de: "Notfangbolzen, beschichtet",
			en: "Safety bolt, coatet"
		}
		,A2V00001473443 : {
			de: "Kunststoffbeilage",
			en: "plastics washer"
		}
		,A2V00370067680 : {
			de: "Lenker",
			en: "steering rod"
		}
		,A2V00370082308 : {
			de: "Gummiring",
			en: "Rubber ring"
		}
		,A2V00356131008 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00001984357 : {
			de: "Schutzwinkel TBL",
			en: "protection angel TBL"
		}
		,A2V00001943161 : {
			de: "Beilage SDK",
			en: "Shim SDK"
		}
		,A2V00002440936 : {
			de: "EP2002 Smart Ventil",
			en: "EP2002 Smart Valve"
		}
		,A2V00370061831 : {
			de: "STROMBRUECKE",
			en: "SHUNT"
		}
		,A2V00370031899 : {
			de: "SCHLAUCHLEITUNG 2TE 16 AA 500 P",
			en: "hose assembly 2TE 16 AA 500 P"
		}
		,A2V00370026893 : {
			de: "Hohlbuchse",
			en: "cylindrical bush"
		}
		,A2V00001954027 : {
			de: "Schutzblech TBL A",
			en: "protection plate tbl a"
		}
		,A2V00001953110 : {
			de: "Schutzblech S",
			en: "protection plate s"
		}
		,A2V00001916576 : {
			de: "Sensor Radsatzlager",
			en: "Sensor axle box bearing"
		}
		,A2V00002398373 : {
			de: "Abdeckung T1",
			en: "Cover p1"
		}
		,A2V00002398416 : {
			de: "Buchse T1 d30/20",
			en: "Bushing P1 d30/20"
		}
		,A2V00002398440 : {
			de: "Fangteller",
			en: "Catching plate"
		}
		,A2V00002398441 : {
			de: "Lagerkopf",
			en: "Bearing head"
		}
		,A2V00002398477 : {
			de: "Distanzstueck T2",
			en: "Spacer block P2"
		}
		,A2V00002398487 : {
			de: "Distanzstueck T1",
			en: "Spacer P1"
		}
		,A2V00002398493 : {
			de: "Zwischengelenk T1",
			en: "Intermediate hinge P1"
		}
		,A2V00001746236 : {
			de: "EMV Kondensatorbaustein",
			en: "EMV Capacitor assembly"
		}
		,A2V00001998738 : {
			de: "Laufachsgeber",
			en: "speed sensor"
		}
		,A2V00002564479 : {
			de: "Rohrleitung",
			en: "Pipe"
		}
		,A2V00002398513 : {
			de: "ZwischenstÃ¼ck",
			en: "Intermediate piece"
		}
		,A2V00002398514 : {
			de: "ZwischenstÃ¼ck Senkantrieb",
			en: "Adapter lowering device"
		}
		,A2V00002398517 : {
			de: "Lagerwelle LR, Knie Ausf.2",
			en: "Bearing shaft LR, knee vers.2"
		}
		,A2V00002398535 : {
			de: "Abstandshalter T1",
			en: "Spacer P1"
		}
		,A2V00002398536 : {
			de: "Anschlussstueck",
			en: "Adapter"
		}
		,A2V00002398537 : {
			de: "Faltenbalg RAL9011",
			en: "Bellow RAL9011"
		}
		,A2V00002119519 : {
			de: "Halteplatte geschweisst",
			en: "Holder board welded"
		}
		,A2V00002398556 : {
			de: "Gummipuffer d35x110",
			en: "Rubber pad d35x110"
		}
		,A2V00002398562 : {
			de: "Distanzstueck H21",
			en: "Spacer H21"
		}
		,A2V00002398566 : {
			de: "Nabe zu Isolierholm -5YB93",
			en: "Hub to insulating horn -5YB93"
		}
		,A2V00002398567 : {
			de: "Distanzstueck H10",
			en: "Spacer H10"
		}
		,A2V00002398568 : {
			de: "Distanzstueck H34",
			en: "Spacer H34"
		}
		,A2V00002398829 : {
			de: "Verbindungswelle 3-11218.5177",
			en: "Connection shaft 3-11218.5177"
		}
		,A2V00002398854 : {
			de: "Fanghebel RAL3000",
			en: "Catching lever RAL3000"
		}
		,A2V00001853536 : {
			de: "Bremswirkgruppe 1, DC 24V",
			en: "Brake initializing group 1, DC 24V"
		}
		,A2V00002314706 : {
			de: "Bodenplatte kpl.",
			en: "floor plate compl."
		}
		,A2V00002399148 : {
			de: "Lochwinkel T1",
			en: "Bracket T1"
		}
		,A2V00002399176 : {
			de: "Haltebuegel komplett",
			en: "Mounting bracket complete"
		}
		,A2V00002315232 : {
			de: "Windschott",
			en: "Wind deflector"
		}
		,A2V00002315246 : {
			de: "Windschott",
			en: "Wind deflector"
		}
		,A2V00002399278 : {
			de: "Blattfeder",
			en: "Leaf spring"
		}
		,A2V00002399362 : {
			de: "Stuetzisolator 25kV",
			en: "Support insulator 25kV"
		}
		,A2V00002399369 : {
			de: "Tragkonsole Ausf.1 fuer AS",
			en: "Bracket vers.1 for ADD"
		}
		,A2V00002399390 : {
			de: "Scheibe",
			en: "Shim"
		}
		,A2V00002399391 : {
			de: "Abstandsblech",
			en: "Spacer plate"
		}
		,A2V00002399401 : {
			de: "Stuetzwinkel",
			en: "Support bracket"
		}
		,A2V00002399411 : {
			de: "Lenkstange RAL3000",
			en: "Handle bar RAL3000"
		}
		,A2V00002399429 : {
			de: "Hubfeder kompl. Ausf.1",
			en: "Lifting spring compl. Vers.1"
		}
		,A2V00002399440 : {
			de: "Hubfeder kompl. Ausf.2",
			en: "Lifting spring compl. Vers.2"
		}
		,A2V00002316484 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002316547 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002399562 : {
			de: "Gelenkgabel Ausf.1",
			en: "Joint fork vers.1"
		}
		,A2V00002399616 : {
			de: "Stromverbinder 150MM2 - 250 LG",
			en: "Current connector 150 MM2 - 250 LG"
		}
		,A2V00002399669 : {
			de: "Tragplatte",
			en: "Supporting plate"
		}
		,A2V00002399676 : {
			de: "Strebe 1175mm",
			en: "Bar 1175mm"
		}
		,A2V00002399765 : {
			de: "Gewindebolzen",
			en: "Stud bolt"
		}
		,A2V00002399834 : {
			de: "Stuetzblech GR",
			en: "Support plate bf"
		}
		,A2V00002399931 : {
			de: "Parallelfuehrung 1887 LG",
			en: "Collector head guide 1887 LG"
		}
		,A2V00002399959 : {
			de: "Lagerhuelse T3",
			en: "Bearing sleeve P3"
		}
		,A2V00002400005 : {
			de: "Beschlag  3-918.2811",
			en: "Fixing 3-918.2811"
		}
		,A2V00002400048 : {
			de: "Ventilblock",
			en: "Valve block"
		}
		,A2V00002400082 : {
			de: "Balgantrieb kpl. Edelstahl RAL9006",
			en: "Lifting device cpl. stainless st.RAL9006"
		}
		,A2V00002400089 : {
			de: "Windleitblech R=160",
			en: "Spoiler R=160"
		}
		,A2V00002400132 : {
			de: "Fangkonsole",
			en: "Catching console"
		}
		,A2V00002400137 : {
			de: "Lenkerhebel",
			en: "Guiding lever"
		}
		,A2V00002400148 : {
			de: "Gewindebolzen komplett - 656 LG",
			en: "Threaded bolt complete - 656 LG"
		}
		,A2V00002400218 : {
			de: "Lagerklotz",
			en: "Bearing support"
		}
		,A2V00002400228 : {
			de: "Hohlachse T4",
			en: "Hollow axle p4"
		}
		,A2V00002400229 : {
			de: "Lagerbolzen T2",
			en: "Bearing bolt p2"
		}
		,A2V00002400313 : {
			de: "Bundbuchse d30/15,1",
			en: "Collar bush d30/15,1"
		}
		,A2V00002400363 : {
			de: "Torsionsstab T1 9mm",
			en: "Torsion rod P1 9mm"
		}
		,A2V00002400364 : {
			de: "Gummifederelement",
			en: "Rubber spring element"
		}
		,A2V00002400419 : {
			de: "Gewindestange",
			en: "Thread rod"
		}
		,A2V00002400453 : {
			de: "Gummipuffer Ausf.P",
			en: "Rubber stop Vers.P"
		}
		,A2V00002400467 : {
			de: "Befestigungskonsole RAL3000",
			en: "Fixing bracket RAL3000"
		}
		,A2V00002400514 : {
			de: "Seil fÃ¼r Hubantrieb Ausf.4 L540",
			en: "Rope for lifting device Vers.4 L540"
		}
		,A2V00002400559 : {
			de: "Buchse D30/23",
			en: "Sleeve D30/23"
		}
		,A2V00002400561 : {
			de: "Torsionsstab",
			en: "Torsion bar"
		}
		,A2V00002400563 : {
			de: "Lenker SWT",
			en: "Guide WP"
		}
		,A2V00002400597 : {
			de: "Oberarm-Stahl G SWT, RAL9006",
			en: "Upper arm-steel G WP; RAL9006"
		}
		,A2V00002400623 : {
			de: "Distanzbuchse",
			en: "Spacer sleeve"
		}
		,A2V00002400691 : {
			de: "Stoessel, SWT",
			en: "Tappet, wp"
		}
		,A2V00002400731 : {
			de: "Tragplatte X4",
			en: "Supporting plate X4"
		}
		,A2V00002400780 : {
			de: "Wippenbogen RAL3000",
			en: "Pan head bow RAL3000"
		}
		,A2V00002406566 : {
			de: "Schweissbadsicherung 200",
			en: "Weld backing 200"
		}
		,A2V00002389340 : {
			de: "Kupplungsplatte",
			en: "Coupling plate"
		}
		,A2V00002389342 : {
			de: "Kupplungsplatte",
			en: "Coupling plate"
		}
		,A2V00002413137 : {
			de: "Rippe sp.g.",
			en: "Rib i.m.i."
		}
		,A2V00002413138 : {
			de: "Rippe w.g.",
			en: "Rib a.d."
		}
		,A2V00002416783 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002421718 : {
			de: "Grundrahmen RAL7012",
			en: "Base frame RAL7012"
		}
		,A2V00002422749 : {
			de: "Unterarm SSS Schweissteil RAL7012",
			en: "Lower arm SSS welding part RAL7012"
		}
		,A2V00002423230 : {
			de: "Seitenwandsaeule vollst. sp.g.",
			en: "Side wall pillar compl. i.m.i."
		}
		,A2V00002423373 : {
			de: "Tuersaeule vollst. WE2 w.g.",
			en: "Door pillar compl. CE2 a.d."
		}
		,A2V00002423403 : {
			de: "Seitenwandsaeule vollst. w.g.",
			en: "Side wall pillar compl. a.d."
		}
		,A2V00002423467 : {
			de: "Seitenwandfeld bearb.",
			en: "Side wall plate machined"
		}
		,A2V00002423494 : {
			de: "Tuersaeule vollst. WE2 sp.g.",
			en: "Door pillar compl. CE2 i.m.i."
		}
		,A2V00002428861 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002323220 : {
			de: "Uebergangsprofil",
			en: "crossing profile"
		}
		,A2V00002323221 : {
			de: "Schottprofil",
			en: "bulkhead profile"
		}
		,A2V00002323222 : {
			de: "Uebergangsprofil",
			en: "crossing profile"
		}
		,A2V00002323223 : {
			de: "Uebergangsprofil  w.g.",
			en: "crossing profile  as d."
		}
		,A2V00002323248 : {
			de: "Uebergangsprofil  sp.g.",
			en: "crossing profile  r.i"
		}
		,A2V00002323249 : {
			de: "Schottprofil",
			en: "bulkhead profile"
		}
		,A2V00002433566 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00002436685 : {
			de: "Querholm T1",
			en: "Transversal beam T1"
		}
		,A2V00002439821 : {
			de: "Zwischenboden vollst.",
			en: "intermediate floor compl."
		}
		,A2V00002442193 : {
			de: "Crashmodul, geschw.",
			en: "Crash module, welded"
		}
		,A2V00002442313 : {
			de: "Seitenwandfeld bearb.",
			en: "Side wall plate machined"
		}
		,A2V00002442753 : {
			de: "Anschweisslasche 02",
			en: "Weld on strap 02"
		}
		,A2V00002442774 : {
			de: "Halter 1",
			en: "Holder 1"
		}
		,A2V00002442877 : {
			de: "Anschweisslasche 01",
			en: "Weld on strap 01"
		}
		,A2V00002444006 : {
			de: "Abdeckblech w.g.",
			en: "Protection sheet a.d."
		}
		,A2V00002444012 : {
			de: "Abdeckblech w.g.",
			en: "Protection sheet a.d."
		}
		,A2V00002444014 : {
			de: "Abdeckblech",
			en: "Protection sheet"
		}
		,A2V00002444015 : {
			de: "Abdeckblech w.g.",
			en: "Protection sheet a.d."
		}
		,A2V00002444016 : {
			de: "Abdeckblech",
			en: "Protection sheet"
		}
		,A2V00002444083 : {
			de: "Abdeckblech sp.g.",
			en: "Protection sheet i.m.i."
		}
		,A2V00002444084 : {
			de: "Abdeckblech sp.g.",
			en: "Protection sheet i.m.i."
		}
		,A2V00002444085 : {
			de: "Abdeckblech sp.g.",
			en: "Protection sheet i.m.i."
		}
		,A2V00002452758 : {
			de: "Radkasten KK-E",
			en: "wheel pocket SC-E"
		}
		,A2V00370028705 : {
			de: "Draht",
			en: "Wire"
		}
		,A2V00002330262 : {
			de: "Endsaeule w.g. Portal",
			en: "endpost as d. portal"
		}
		,A2V00002330263 : {
			de: "Endsaeule sp.g. Portal",
			en: "endpost r.i. portal"
		}
		,A2V00002330313 : {
			de: "Tuerrippe sp.g.",
			en: "door rib m.d."
		}
		,A2V00002330314 : {
			de: "Tuerrippe w.g.",
			en: "door rib a.d."
		}
		,A2V00002330511 : {
			de: "Tuerrippe kurz",
			en: "door rib short"
		}
		,A2V00001893753 : {
			de: "Grundrahmen",
			en: "Base frame"
		}
		,A2V00002332167 : {
			de: "A-Saeule vollst li",
			en: "A-post complete le"
		}
		,A2V00002332168 : {
			de: "A-Saeule vollst re",
			en: "A-post complete ri"
		}
		,A2V00002464037 : {
			de: "Rohr",
			en: "Pipe"
		}
		,A2V00002465190 : {
			de: "Parallelfuehrungsstange RAL9006",
			en: "Collector head guide RAL9006"
		}
		,A2V00002466313 : {
			de: "Abfallbehaelter MW, vollst.",
			en: "Waste Bin MC, compl."
		}
		,A2V00002469193 : {
			de: "Gurt w.g.",
			en: "Belt a.d."
		}
		,A2V00002469194 : {
			de: "Gurt w.g.",
			en: "Belt a.d."
		}
		,A2V00002469259 : {
			de: "Gurt sp.g.",
			en: "Belt i.m.i."
		}
		,A2V00002469261 : {
			de: "Gurt sp.g.",
			en: "Belt i.m.i."
		}
		,A2V00002495635 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002495716 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002495718 : {
			de: "Blechteil fuer AP",
			en: "Sheet piece for WS"
		}
		,A2V00002496070 : {
			de: "Radkasten vollst. w.g.",
			en: "Wheelhouse compl. a.d."
		}
		,A2V00002669967 : {
			de: "Ueberlaufrohr, geschweisst",
			en: "Overflow pipe, welded"
		}
		,A2V00002670812 : {
			de: "Rohrverbindung, geschweisst",
			en: "Pipe connection, welded"
		}
		,A2V00002497294 : {
			de: "Deckel 11",
			en: "Cover 11"
		}
		,A2V00002497295 : {
			de: "Knotenblech 12 w.g.",
			en: "Gusset plate 12 a.d."
		}
		,A2V00002335196 : {
			de: "Geruest geschweisst",
			en: "Rack welded"
		}
		,A2V00002335311 : {
			de: "Dachblech         kpl.",
			en: "roof sheeting     compl."
		}
		,A2V00002497399 : {
			de: "Ausdrehanschlag w.g.",
			en: "Arrester a.d."
		}
		,A2V00002497410 : {
			de: "Erdungslasche 2",
			en: "Earthing 2"
		}
		,A2V00002497412 : {
			de: "Ausdrehanschlag sp.g.",
			en: "Arrester i.m.i."
		}
		,A2V00002497511 : {
			de: "Knotenblech w.g.",
			en: "Gusset plate a.d."
		}
		,A2V00002497575 : {
			de: "Schliessblech",
			en: "Closing plate"
		}
		,A2V00002497631 : {
			de: "Knotenblech Front w.g.",
			en: "Gusset plate front a.d."
		}
		,A2V00002497632 : {
			de: "Knotenblech Front sp.g.",
			en: "Gusset plate front i.m.i."
		}
		,A2V00002497633 : {
			de: "Knotenblech Front 3 w.g",
			en: "Gusset plate front 3 a.d."
		}
		,A2V00002497637 : {
			de: "Knotenblech 11 w.g.",
			en: "Gusset plate 11 a.d."
		}
		,A2V00002497643 : {
			de: "Knotenblech 11 sp.g.",
			en: "Gusset plate 11 i.m.i."
		}
		,A2V00002497651 : {
			de: "Abdeckblech Dach w.g.",
			en: "Cover sheet roof a.d."
		}
		,A2V00002497660 : {
			de: "Rippe C-Profil Front",
			en: "Rib C-rail front"
		}
		,A2V00002497681 : {
			de: "Knotenblech w.g.",
			en: "Gusset plate a.d."
		}
		,A2V00002497682 : {
			de: "Winkel",
			en: "Angle"
		}
		,A2V00002497686 : {
			de: "Knotenblech sp.g.",
			en: "Gusset plate i.m.i."
		}
		,A2V00002497688 : {
			de: "Abdeckblech Dach sp.g.",
			en: "Cover sheet roof i.m.i."
		}
		,A2V00002497695 : {
			de: "Erdungslasche M6",
			en: "Earthing M6"
		}
		,A2V00002497712 : {
			de: "Erdungslasche Multi",
			en: "Earthing multi"
		}
		,A2V00002497748 : {
			de: "Tuerversteifung sp.g.",
			en: "Door stiffener i.m.i."
		}
		,A2V00002497749 : {
			de: "Tuerversteifung w.g.",
			en: "Door stiffener a.d."
		}
		,A2V00002497750 : {
			de: "Knotenblech Front 3 sp.g.",
			en: "Gusset plate front 3 i.m.i."
		}
		,A2V00002497751 : {
			de: "Versteifung w. g.",
			en: "Stiffener a.d."
		}
		,A2V00002497752 : {
			de: "Versteifung sp.g.",
			en: "Stiffener i.m.i."
		}
		,A2V00002336624 : {
			de: "Dachblech vollst.",
			en: "roof sheeting"
		}
		,A2V00002498006 : {
			de: "Knotenblech 12 sp.g.",
			en: "Gusset plate 12 i.m.i."
		}
		,A2V00002498007 : {
			de: "Schliessblech 2",
			en: "Close sheet 2"
		}
		,A2V00002498089 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002505782 : {
			de: "Radkasten vollst. sp.g.",
			en: "Wheelhouse compl. i.m.i."
		}
		,A2V00002505868 : {
			de: "Halter fuer Unterflurkanal",
			en: "Bracket for underfloor duct"
		}
		,A2V00002341613 : {
			de: "Blechrutsche",
			en: "slip plate"
		}
		,A2V00002507824 : {
			de: "Rippe C-Profil",
			en: "Rib c-profile"
		}
		,A2V00002342038 : {
			de: "Kabelhalter",
			en: "cable holder"
		}
		,A2V00002342047 : {
			de: "Halter",
			en: "holder"
		}
		,A2V00002668352 : {
			de: "Geruest geschw.",
			en: "Rack welded"
		}
		,A2V00002700932 : {
			de: "Geraetecontainer +68.53. komplett",
			en: "E-container +68.53, complete"
		}
		,A2V00002342112 : {
			de: "Kabelkonsole",
			en: "cable bracket"
		}
		,A2V00002342217 : {
			de: "U-Profil",
			en: "u-profile"
		}
		,A2V00002512085 : {
			de: "Z-Winkel",
			en: "Z-angle"
		}
		,A2V00002342844 : {
			de: "Flanschblech",
			en: "flange"
		}
		,A2V00002344061 : {
			de: "Hilfsluftkompressor vst.",
			en: "Auxiliary air compressor cpl."
		}
		,A2V00002344415 : {
			de: "Hilfsluftkompressor      vst.",
			en: "Auxiliary air            compressor cpl."
		}
		,A2V00002514025 : {
			de: "Erdungslasche M10",
			en: "Grounding connection M10"
		}
		,A2V00002514315 : {
			de: "Quertraegerprofil",
			en: "Crossbeam profile"
		}
		,A2V00002514747 : {
			de: "Pneu. Isolierschlauch 25kV Edelstahl",
			en: "Pneu. insuating hose 25kV stainless st"
		}
		,A2V00002514757 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002516168 : {
			de: "Versteifungsblech, unteres sp.g.",
			en: "Bracing sheet, lower i.m.i."
		}
		,A2V00002518641 : {
			de: "Pruefstueck T=3",
			en: "Test piece T=3"
		}
		,A2V00002518956 : {
			de: "Pruefstueck T=10",
			en: "Test piece T=10"
		}
		,A2V00002519046 : {
			de: "Sandwichrampe",
			en: "sandwich ramp"
		}
		,A2V00002347034 : {
			de: "Deckblech A-Sauele",
			en: "cover plate A-post"
		}
		,A2V00002348197 : {
			de: "Schottprofil var.2",
			en: "bulkhead profile ver.2"
		}
		,A2V00002518640 : {
			de: "Pruefstueck T=1,5",
			en: "Test piece T=1,5"
		}
		,A2V00002352217 : {
			de: "Stirnwand",
			en: "endwall"
		}
		,A2V00002352259 : {
			de: "Stirnwand",
			en: "endwall"
		}
		,A2V00002533863 : {
			de: "Knotenblech sp.g.",
			en: "Gusset plate r.i."
		}
		,A2V00002533958 : {
			de: "Dichtblech sp. g.",
			en: "Sealing plate r. i."
		}
		,A2V00002533962 : {
			de: "Verstaerkung w. g.",
			en: "Reinforcement as d."
		}
		,A2V00002533964 : {
			de: "Knotenblech w.g.",
			en: "Gusset plate as d."
		}
		,A2V00002533971 : {
			de: "Verstaerkung sp. g.",
			en: "Reinforcement r. i."
		}
		,A2V00002534828 : {
			de: "Konsole 1 F-Schrank",
			en: "Bracket 1 d-cabinet"
		}
		,A2V00002534829 : {
			de: "Strebe",
			en: "Bracer"
		}
		,A2V00002535663 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002536762 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002536951 : {
			de: "Schweissbadsicherung",
			en: "Weldbacking"
		}
		,A2V00002540555 : {
			de: "Befestigungswinkel",
			en: "Mounting bracket"
		}
		,A2V00002540708 : {
			de: "Versteifung",
			en: "Stiffening"
		}
		,A2V00002540717 : {
			de: "Konsole kurz",
			en: "Console short"
		}
		,A2V00002078049 : {
			de: "Hauptfunkenschildeinheit links",
			en: "Main arcshield assembly left"
		}
		,A2V00002541234 : {
			de: "Knotenblech sp.g.",
			en: "Gusset plate i.m.i."
		}
		,A2V00002542618 : {
			de: "Erdungslasche M10",
			en: "Earthing ear M10"
		}
		,A2V00002542714 : {
			de: "Seitenwand",
			en: "Side wall"
		}
		,A2V00002542796 : {
			de: "C-Schiene SW oben",
			en: "C-bar SW upper"
		}
		,A2V00002544861 : {
			de: "Entwaesserungsrohr",
			en: "Drainpipe"
		}
		,A2V00002376155 : {
			de: "Portalblende voll.",
			en: "gateway cover compl."
		}
		,A2V00001686264 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002728884 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screwÂ "
		}
		,A2V00001971814 : {
			de: "O-Ring leitf. 496-3,53mm",
			en: "O-ring conduc. 496-3,53mm"
		}
		,A2V00002174672 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screwÂ "
		}
		,A2V00002546955 : {
			de: "Halter kpl.",
			en: "Holder cpl."
		}
		,A2V00002376817 : {
			de: "Langtraeger links vollstaendig",
			en: "longitudinal beam left complete"
		}
		,A2V00002376818 : {
			de: "Langtraeger rechts vollstaendig",
			en: "longitudinal beam right complete"
		}
		,A2V00000333575 : {
			de: "Deckel, komplett",
			en: "Cover, complete"
		}
		,A2V00000333583 : {
			de: "FILZPLATTE",
			en: "felt plate"
		}
		,A2V00370035856 : {
			de: "Impulsgeber GI6            II38240/10019",
			en: "Pulse generator"
		}
		,A2V00370035857 : {
			de: "Polrad Nr.127-2",
			en: "Pulse Wheel Nr.127-2"
		}
		,A2V00001289840 : {
			de: "Radmutter",
			en: "Wheel nut"
		}
		,A2V00107279507 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001872345 : {
			de: "Stiftschraube",
			en: "Stud screw"
		}
		,A2V00001873129 : {
			de: "Stiftschraube",
			en: "Stud screw"
		}
		,A2V00001873130 : {
			de: "Stiftschraube",
			en: "Stud screw"
		}
		,A2V00001670898 : {
			de: "Schutzhuelse",
			en: "Protective sleeve"
		}
		,A2V00002698871 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00001779917 : {
			de: "Rohr HFCHS",
			en: "Tube HFCHS"
		}
		,A2V00002698869 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002377970 : {
			de: "Portalblende vollst.",
			en: "gateway cover compl."
		}
		,A2V00002548436 : {
			de: "Knotenblech w.g.",
			en: "Gusset plate a.d."
		}
		,A2V00002548529 : {
			de: "Knotenblech sp.g.",
			en: "Gusset plate i.m.i"
		}
		,A2V00002549585 : {
			de: "Halter kpl.",
			en: "Holder cpl."
		}
		,A2V00001886046 : {
			de: "Kugelgelenk 50/20 (IS BS)",
			en: "Ball Joint 50/20 (IS BS)"
		}
		,A2V00002728905 : {
			de: "Spannstift",
			en: "Spring-type straight pin"
		}
		,A2V00002604841 : {
			de: "Zug -/ Druckstange",
			en: "Torsion spring compl."
		}
		,A2V00002612410 : {
			de: "Zug -/ Druckstange Verkleidung",
			en: "Torsion spring compl."
		}
		,A2V00002550195 : {
			de: "Rohrdurchfuehrung WC",
			en: "pipe lead through WC"
		}
		,A2V00002384417 : {
			de: "Uebergangsprofil",
			en: "crossing profile"
		}
		,A2V00002550603 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002551509 : {
			de: "Konsole kurz, w.g.",
			en: "Console short, a.d."
		}
		,A2V00002551566 : {
			de: "Konsole kurz, sp.g.",
			en: "Console short, i.m.i."
		}
		,A2V00001886048 : {
			de: "Kugelgelenk 50/20 (IS BS)",
			en: "Ball Joint 50/20 (IS BS)"
		}
		,A2V00106008984 : {
			de: "Stahlrohr",
			en: "Steel tube"
		}
		,A2V00156028050 : {
			de: "Kabelverschraubung",
			en: "Cable gland"
		}
		,A2V00001908861 : {
			de: "Sechskantmutter ISO 8673 M48 8 A4C",
			en: "Hexagon nut ISO 8673 M48 8 A4C"
		}
		,A2V00002598169 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00002704733 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001705943 : {
			de: "Schlauchleitung 2TE 8 NN45-680P",
			en: "hose assembly 2TE 8 NN45-680P"
		}
		,A2V00001705944 : {
			de: "Schlauchleitung 2TE 16 NN45-700P",
			en: "hose assembly 2TE 16 NN45-700P"
		}
		,A2V00370054932 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001929517 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002714751 : {
			de: "Passschraube",
			en: "Fit bolt"
		}
		,A2V00200386045 : {
			de: "Rohr",
			en: "Pipe"
		}
		,A2V00001198797 : {
			de: "Schlauchleitung 2SN 6 N45N90 570 P",
			en: "Hose assembly 2SN 6 N45N90 570 P"
		}
		,A2V00001950307 : {
			de: "Bremsbelag, organisch",
			en: "Brake pad, organic"
		}
		,A2V00001080101 : {
			de: "Fey Lamellenring",
			en: "Fey lamella ring"
		}
		,A2V00002555189 : {
			de: "Huelse d10,5/8",
			en: "Sleeve d10,5/8"
		}
		,A2V00001971813 : {
			de: "O-Ring leitfaehig 762-5mm",
			en: "o-ring conductive 762-5mm"
		}
		,A2V00002704562 : {
			de: "Passschraube",
			en: "Fit bolt"
		}
		,A2V00002696432 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00001429701 : {
			de: "Daempfer primaer vertikal",
			en: "primary vertical shock absorber"
		}
		,A2V00001429704 : {
			de: "Drehdaempfer",
			en: "yaw damper"
		}
		,A2V00001816377 : {
			de: "Flachkopfschraube",
			en: "Hexagon socket button head screw"
		}
		,A2V00397205079 : {
			de: "BEILAGE 1,5mm",
			en: "shim 1, 5mm"
		}
		,A2V00370075682 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370060612 : {
			de: "Faltenbalg",
			en: "bellow"
		}
		,A2V00002317806 : {
			de: "Dehnhuelse",
			en: "Extension sleeve"
		}
		,A2V00002667529 : {
			de: "Halter Schuerze, geschw.",
			en: "holder skirt, welded"
		}
		,A2V00002693752 : {
			de: "Kasten geschweisst",
			en: "welded box"
		}
		,A2V00002693819 : {
			de: "Kasten geschweisst",
			en: "Welded box"
		}
		,A2V00002560182 : {
			de: "Steckerkasten vollst. , links",
			en: "connector box compl. , left"
		}
		,A2V00002389471 : {
			de: "Auflage",
			en: "Support plate"
		}
		,A2V00002711688 : {
			de: "Kupplungsplatte WE1",
			en: "Coupling plate CE1"
		}
		,A2V00002561044 : {
			de: "Gewindeplatte",
			en: "Threaded plate"
		}
		,A2V00002668222 : {
			de: "Abschlussprofil Boden w.g.",
			en: "end profile floor plate a.d."
		}
		,A2V00001855266 : {
			de: "Schwenkfenster vollstaendig sp.g.",
			en: "swivelling window complete r.i."
		}
		,A2V00001840215 : {
			de: "Schwenkfenster vollstaendig w.g.",
			en: "swivelling window complete as d."
		}
		,A2V00002563310 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002439736 : {
			de: "Stromabnehmer 1600/AC komplett",
			en: "Pantograph 1600/AC complete"
		}
		,A2V00002564085 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002566648 : {
			de: "Winkelblech",
			en: "Angle plate"
		}
		,A2V00002566765 : {
			de: "Dichtblech Mitte",
			en: "seal plate center"
		}
		,A2V00002567138 : {
			de: "Dichtblech 2 Seite",
			en: "seal plate 2 side"
		}
		,A2V00002567139 : {
			de: "Dichtblech Ecke w.g.",
			en: "seal plate corner a.d."
		}
		,A2V00002567140 : {
			de: "Dichtblech Ecke sp.g.",
			en: "seal plate corner r.i."
		}
		,A2V00002567141 : {
			de: "Dichtblech 1 Seite",
			en: "seal plate 1 side"
		}
		,A2V00002567570 : {
			de: "Absicherung 1, Rampe",
			en: "lock out 1, ramp"
		}
		,A2V00002567571 : {
			de: "Absicherung 2, Rampe",
			en: "lock out 2, ramp"
		}
		,A2V00002569741 : {
			de: "Fassdraht Type Standard 80 kg",
			en: "Drum wire type Standard 80 kg"
		}
		,A2V00001188239 : {
			de: "Sandstreuanlage; kpl. TFW Variante 2",
			en: "sand equipment compl FFW ver. 2"
		}
		,A2V00002572101 : {
			de: "Nutenstein",
			en: "Sliding block"
		}
		,A2V00002355603 : {
			de: "Transportsicherung",
			en: "Transportation lock"
		}
		,A2V00002574574 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002468227 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00002470142 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002470252 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002470253 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002498834 : {
			de: "Seitenwand Obergurt links EW 2",
			en: "side wall top belt left EW 2"
		}
		,A2V00002498835 : {
			de: "Seitenwandfeld 1 links   EW 2",
			en: "side wall module 1, left  EW 2"
		}
		,A2V00002498856 : {
			de: "Seitenwandfeld 2 links   EW 2",
			en: "side wall module 2, left  EW 2"
		}
		,A2V00002498857 : {
			de: "Seitenwandfeld 3 links   EW 2",
			en: "side wall module 3, left  EW 2"
		}
		,A2V00002498859 : {
			de: "Seitenwand Obergurt rechts EW 2",
			en: "side wall top belt right EW 2"
		}
		,A2V00002498860 : {
			de: "Seitenwandfeld 1 rechts   EW 2",
			en: "side wall module 1 right  EW 2"
		}
		,A2V00002498861 : {
			de: "Seitenwandfeld 2 rechts   EW 2",
			en: "side wall module 2 right  EW 2"
		}
		,A2V00002498862 : {
			de: "Seitenwandfeld 3 rechts   EW 2",
			en: "side wall module 3 right  EW 2"
		}
		,A2V00002579232 : {
			de: "Windleitblech R160 B70",
			en: "Spoiler R160 W70"
		}
		,A2V00397194466 : {
			de: "SCHEIBE",
			en: "disc"
		}
		,A2V00397194467 : {
			de: "VERDREHSICHERUNG",
			en: "turn protection"
		}
		,A2V00002582790 : {
			de: "Gehauese geschw. w.g.",
			en: "Case welded a.d."
		}
		,A2V00002583467 : {
			de: "Gehauese geschw. sp.g.",
			en: "Case welded i.m.i."
		}
		,A2V00002134934 : {
			de: "Beilagscheibe 21x36x5",
			en: "Shim 21x36x5"
		}
		,A2V00002059724 : {
			de: "Schotterflugblech",
			en: "stone chipping sheet"
		}
		,A2V00002101818 : {
			de: "Traeger f. Sandung w.g.",
			en: "Support f. sander a.d."
		}
		,A2V00002459746 : {
			de: "CS Bolzen",
			en: "CS Bolt"
		}
		,A2V00001953681 : {
			de: "Anlaufring 238 BA",
			en: "Spacer disc 238 BA"
		}
		,A2V00001954897 : {
			de: "Huelse M16",
			en: "bush M16"
		}
		,A2V00002321740 : {
			de: "Konsole spg.",
			en: "Bracket oad."
		}
		,A2V00002321741 : {
			de: "Konsole wg.",
			en: "Bracket ad."
		}
		,A2V00370060405 : {
			de: "BEILAGE",
			en: "SHIM"
		}
		,A2V00002321529 : {
			de: "Rippe 2 spg.",
			en: "Rib 2 oad."
		}
		,A2V00002321530 : {
			de: "Rippe 2 wg.",
			en: "Rib 2 ad."
		}
		,A2V00002321531 : {
			de: "Rippe 3 wg.",
			en: "Rib 3 ad."
		}
		,A2V00002321532 : {
			de: "Rippe 1 wg.",
			en: "Rib 1 ad."
		}
		,A2V00002321533 : {
			de: "Rippe 3 spg.",
			en: "Rib 3 oad."
		}
		,A2V00002321534 : {
			de: "Rippe 1 spg.",
			en: "Rib 1 oad."
		}
		,A2V00002098599 : {
			de: "Klemmenkasten 101608 TDG1, gebohrt",
			en: "Terminal box 101608 MB1, drilled"
		}
		,A2V00002321623 : {
			de: "EDT Blech",
			en: "DDT plate"
		}
		,A2V00002321535 : {
			de: "Konsole wg.",
			en: "Bracket ad."
		}
		,A2V00002321586 : {
			de: "Konsole spg.",
			en: "Bracket oad."
		}
		,A2V00002004954 : {
			de: "Schellenelement B w.g.",
			en: "Pipe clamp B a.d."
		}
		,A2V00002517611 : {
			de: "Antrieb, vollst.",
			en: "Drive, complete"
		}
		,A2V00002517614 : {
			de: "Drehsaeule, vollst. sp.g.",
			en: "Rotating column, compl. r.i."
		}
		,A2V00002517615 : {
			de: "Drehsaeule, vollst. w.g.",
			en: "Rotating column, compl. as d."
		}
		,A2V00002585916 : {
			de: "Abdeckblech 2",
			en: "Cover sheet 2"
		}
		,A2V00002339495 : {
			de: "Fahrmotor 1TB2822-0SD02",
			en: "Traction motor without nose susp. drive"
		}
		,A2V00001228067 : {
			de: "Scheibe fuer Spannhuelsenschraube",
			en: "Washer for spring pin screw"
		}
		,A2V00001706160 : {
			de: "Sechskantschraube",
			en: "Hexagonal bolt"
		}
		,A2V00002380205 : {
			de: "Radsatzlagergehaeuse rh, WSB vorbereitet",
			en: "axlebox housing rh, ECB prepared"
		}
		,A2V00002380217 : {
			de: "Radsatzlagergehaeuse lh, WSB vorbereitet",
			en: "axle box housing lh, ECB prepared"
		}
		,A2V00001968179 : {
			de: "Endstueck Rohr",
			en: "end cap pipe"
		}
		,A2V00001998541 : {
			de: "Zwischenblech 1",
			en: "adjustment shim 1"
		}
		,A2V00001998585 : {
			de: "Zwischenblech 2",
			en: "adjustment shim 2"
		}
		,A2V00002122102 : {
			de: "Firmenschild",
			en: "Factory Sign"
		}
		,A2V00002587382 : {
			de: "Entwaesserungsrohr",
			en: "Drainage pipe"
		}
		,A2V00002528901 : {
			de: "Z-Winkel",
			en: "Z-angle"
		}
		,A2V00002589150 : {
			de: "Saeulenkonsole 1",
			en: "Pillar bracket 1"
		}
		,A2V00002589571 : {
			de: "Klebeblech",
			en: "Gluing sheet"
		}
		,A2V00002590366 : {
			de: "Seitenwand",
			en: "Side wall"
		}
		,A2V00002079367 : {
			de: "Zugentlastungskonsole",
			en: "traction relief bracket"
		}
		,A2V00002000010 : {
			de: "Halter f. Spurstangendaempfer",
			en: "Holder f. gauge tie bar damper"
		}
		,A2V00001407431 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00001433285 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00002367719 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00002189105 : {
			de: "Drehstabfeder",
			en: "Torsional spring"
		}
		,A2V00001858382 : {
			de: "Wellenschutzblech",
			en: "Guard plate f. wheelset axle"
		}
		,A2V00002594361 : {
			de: "Halter geschw.",
			en: "Holder welded"
		}
		,A2V00002594382 : {
			de: "Halter geschw.",
			en: "Holder welded"
		}
		,A2V00370026588 : {
			de: "Querpuffer",
			en: "Lateral buffer"
		}
		,A2V00002147983 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00397139188 : {
			de: "HALTER",
			en: "HALTER"
		}
		,A2V00397139182 : {
			de: "STANGE",
			en: "STANGE"
		}
		,A2V00002087436 : {
			de: "Unterlage grundiert 2mm",
			en: "Support primed 2mm"
		}
		,A2V00002087437 : {
			de: "Unterlage grundiert 3mm",
			en: "Support primed 3mm"
		}
		,A2V00002714490 : {
			de: "LÃ¤ngsanschlag Sockel",
			en: "Longitudinal stop base"
		}
		,A2V00002699755 : {
			de: "Daempfer primaer vertikal",
			en: "Primary vertical shock absorber"
		}
		,A2V00002595133 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002595329 : {
			de: "Grundrahmen Nuernberg SWT RAL3000",
			en: "Base frame Nuernberg wp RAL3000"
		}
		,A2V00002595557 : {
			de: "Unterarm Nuernberg SWT RAL3000",
			en: "Lower arm Nuernberg wp RAL3000"
		}
		,A2V00002541181 : {
			de: "Einholmstromabnehmer",
			en: "pantograph 420.021 1450 mm (CH)"
		}
		,A2V00002541183 : {
			de: "Einholmstromabnehmer",
			en: "single arm pantograph420.022 1450mm (CH)"
		}
		,A2V00002541958 : {
			de: "Sandwichrampe, lackiert",
			en: "Sandwich Ramp, painted"
		}
		,A2V00002596630 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002596656 : {
			de: "Umlenkhebel SWT RAL3000",
			en: "Lever wp RAL3000"
		}
		,A2V00397121198 : {
			de: "LENKER,VOLLST.",
			en: "connecting rod, compl."
		}
		,A2V00002335488 : {
			de: "Stellrohr komplett",
			en: "adjusting pipe complete"
		}
		,A2V00001978779 : {
			de: "Distanzscheibe 10",
			en: "distance disc 10"
		}
		,A2V00001977206 : {
			de: "Dehnhuelse M16x25",
			en: "extension sleeve M16x25"
		}
		,A2V00002082842 : {
			de: "Impulsgeber FS01A, 1-kanalig",
			en: "Pulse Generator FS01A, single channel"
		}
		,A2V00001778226 : {
			de: "Dichtring",
			en: "gasket ring"
		}
		,A2V00002716736 : {
			de: "Hohlkolbenzylinder Enerpac RCH-306",
			en: "Hollow piston cylinder Enerpac RCH-306"
		}
		,A2V00001948787 : {
			de: "Konsole Rohr 2",
			en: "panel pipe 2"
		}
		,A2V00001948930 : {
			de: "Konsole Rohr 2 Platte",
			en: "panel pipe 2 plate"
		}
		,A2V00001948951 : {
			de: "Konsole Rohr 2 Rippe",
			en: "panel pipe 2 rib"
		}
		,A2V00001948997 : {
			de: "Konsole Rohr 2 Platte",
			en: "panel pipe 2 plate"
		}
		,A2V00001954057 : {
			de: "Konsolenblech Sofis",
			en: "bracket plate sofis"
		}
		,A2V00001954877 : {
			de: "Rippe Halter ZUB",
			en: "rib bracket zub"
		}
		,A2V00001964544 : {
			de: "Schellenhalter",
			en: "clamps holder"
		}
		,A2V00001964545 : {
			de: "Schellenhalter",
			en: "clamps holder"
		}
		,A2V00001964636 : {
			de: "Schellenhalter",
			en: "clamps holder"
		}
		,A2V00001965227 : {
			de: "Konsole Rohr 2 Rippe spg.",
			en: "bracket pipe 2 rib mirr."
		}
		,A2V00001967009 : {
			de: "Gurt Halter unten",
			en: "flange holder upper"
		}
		,A2V00001967010 : {
			de: "Konsole Rohr 1 Steg R",
			en: "bracket pipe 1 web r"
		}
		,A2V00001967011 : {
			de: "Konsole  Rohr 1 Steg L",
			en: "Bracket pipe 1 web l"
		}
		,A2V00001967012 : {
			de: "Gurt 2 Halter ZUB",
			en: "flange 2 holder zub"
		}
		,A2V00001967013 : {
			de: "Rippe ZUB",
			en: "rib ZUB"
		}
		,A2V00001967014 : {
			de: "Konsole Rohr 1 Obergurt",
			en: "bracket pipe 1 flange"
		}
		,A2V00001967015 : {
			de: "Gurt Halter 1 ZUB",
			en: "flange holder 1 ZUB"
		}
		,A2V00001976344 : {
			de: "Schellenhalter",
			en: "clamps holder"
		}
		,A2V00001977052 : {
			de: "Rippe ZUB",
			en: "rib ZUB"
		}
		,A2V00001977063 : {
			de: "Rippe Querrohr",
			en: "rib crosspipe"
		}
		,A2V00001977207 : {
			de: "Rippe Querrohr",
			en: "rib crosspipe"
		}
		,A2V00002597494 : {
			de: "Befestigungsplatte f. Blattfeder",
			en: "Bracket plate for flat spring"
		}
		,A2V00002546183 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002598875 : {
			de: "Wippenkastenblech",
			en: "Collector head box plate"
		}
		,A2V00002599125 : {
			de: "Befestigungsblech",
			en: "Fixing plate"
		}
		,A2V00002599154 : {
			de: "Spannplatte fuer Blattfeder",
			en: "Clamping plate for flat spring"
		}
		,A2V00002547350 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002599242 : {
			de: "Wippenkastenhalterung SWT",
			en: "Collector h.b. support wp"
		}
		,A2V00002599359 : {
			de: "Halteblech",
			en: "Bracket"
		}
		,A2V00002599491 : {
			de: "Aufnahmewinkel",
			en: "Support bracket"
		}
		,A2V00002599858 : {
			de: "Befestigung f. flex. Schlauch 2",
			en: "Mounting f. flex. tube 2"
		}
		,A2V00002599859 : {
			de: "Befestigung f. flex. Schlauch 1",
			en: "Mounting f. flex. tube 1"
		}
		,A2V00002600007 : {
			de: "Wippenkasten",
			en: "Collector head box"
		}
		,A2V00002042608 : {
			de: "Winkel 3",
			en: "angle 3"
		}
		,A2V00002044750 : {
			de: "Winkel 4",
			en: "angle 4"
		}
		,A2V00001669866 : {
			de: "Stuetzring Gelenklager",
			en: "Retaining ring hinge bearing"
		}
		,A2V00001813923 : {
			de: "Fuehrungsstueck",
			en: "Guid Part"
		}
		,A2V00002518167 : {
			de: "Schraubendruckfedersatz LDG",
			en: "Helical Compression Spring TB"
		}
		,A2V00002093588 : {
			de: "Beilage 10mm",
			en: "Shim 10mm"
		}
		,A2V00002457624 : {
			de: "FDUE Messmodul E-Muster",
			en: "BDM acquisition unit E sample"
		}
		,A2V00002549561 : {
			de: "Halter kpl.",
			en: "Holder cpl."
		}
		,A2V00002549571 : {
			de: "Halter kpl.",
			en: "Holder cpl."
		}
		,A2V00002604842 : {
			de: "Drehmomentstuetze Motor",
			en: "Torsion spring compl."
		}
		,A2V00002107507 : {
			de: "Sibac Baustein",
			en: "Sibac-module"
		}
		,A2V00001722002 : {
			de: "Traeger f. Mitnahmebolzen",
			en: "Mounting f. driver pin"
		}
		,A2V00370031833 : {
			de: "MEGI-PUFFER 781074-MITTEL",
			en: "MEGI-BUFFER 781074-MITTEL"
		}
		,A2V00002055736 : {
			de: "Dehnhuelse M16x32x15",
			en: "Extension sleeve M16x32x15"
		}
		,A2V00001420092 : {
			de: "Wartungsklappe    hinten vollstaendig",
			en: "Opening for maintenance rear complete"
		}
		,A2V00002064583 : {
			de: "Dehnhuelse M20x35x25",
			en: "Extension sleeve M20x35x25"
		}
		,A2V00002605720 : {
			de: "Nutenstein",
			en: "Sliding block"
		}
		,A2V00002605858 : {
			de: "Gleitplatte 6",
			en: "Sliding plate 6"
		}
		,A2V00002606189 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002606402 : {
			de: "Gleitplatte 5",
			en: "Sliding plate 5"
		}
		,A2V00002610774 : {
			de: "Bef. f. Lautsprecher",
			en: "Mount. f. loudspeaker"
		}
		,A2V00002160295 : {
			de: "Schutzblech w.g.",
			en: "protection plate a.d."
		}
		,A2V00002160355 : {
			de: "Schutzblech sp.g.",
			en: "protection plate rev.im."
		}
		,A2V00002611236 : {
			de: "Tragplatte SWT",
			en: "Carrier plate wp"
		}
		,A2V00002612312 : {
			de: "Fassung fuer Schleifleiste SWT",
			en: "Frame for carbon strip wp"
		}
		,A2V00002073006 : {
			de: "Schelle",
			en: "Clamp"
		}
		,A2V00002559761 : {
			de: "Sockel Mitte",
			en: "Base Profile Middle"
		}
		,A2V00002617526 : {
			de: "Oberarm H Alu RAL9006 SWT m. Laschen",
			en: "Upper arm H Alu RAL9006 WP w. fishplate"
		}
		,A2V00002619332 : {
			de: "Endblech geschw. 01",
			en: "Endplate welded 01"
		}
		,A2V00109429213 : {
			de: "Laengsanschlag",
			en: "Longitudinal Stop"
		}
		,A2V00002619428 : {
			de: "Endblech geschw. 02",
			en: "Endplate welded 02"
		}
		,A2V00002619926 : {
			de: "Abdeckblech",
			en: "Cover sheet"
		}
		,A2V00002620398 : {
			de: "Abdeckung DS",
			en: "Cover DS"
		}
		,A2V00001870146 : {
			de: "Doppler Radar NG2",
			en: "Doppler Radar NG2"
		}
		,A2V00001921282 : {
			de: "Blende vollst.",
			en: "screen compl."
		}
		,A2V00002623452 : {
			de: "Haltewinkel 01 f. Absperrhahn",
			en: "Angle bracket 01 f. isolating cock"
		}
		,A2V00002565219 : {
			de: "Kabelhalter",
			en: "cable holder"
		}
		,A2V00002565271 : {
			de: "Kabelhalter",
			en: "cable holder"
		}
		,A2V00001474007 : {
			de: "Bolzen Aufhaengung lack.",
			en: "Bolt attachment painted"
		}
		,A2V00001742651 : {
			de: "Gewindestange 240mm",
			en: "Threaded rod 240mm"
		}
		,A2V00001245745 : {
			de: "Phasenbaustein SIBAC BB CBH-3000S",
			en: "phase module SIBAC BB CBH-3000S"
		}
		,A2V00001440014 : {
			de: "Kinematik, vollst.",
			en: "kinematics        compl."
		}
		,A2V00001720074 : {
			de: "Luftgitter",
			en: "Air bars"
		}
		,A2V00100497673 : {
			de: "Stahlrohr",
			en: "Steel tube"
		}
		,A2V00370054478 : {
			de: "Einschraubstueck",
			en: "Adapter"
		}
		,A2V00113028985 : {
			de: "Halteklammer",
			en: "Clamp"
		}
		,A2V00001742659 : {
			de: "Gewindestange 120mm",
			en: "Threaded rod 120mm"
		}
		,A2V00001414454 : {
			de: "Kurzkupplungs-    haelfte",
			en: "short coupler item"
		}
		,A2V00100558508 : {
			de: "Stahlrohr",
			en: "Steel tube"
		}
		,A2V00001780052 : {
			de: "Magnetventil",
			en: "Magnetic valve"
		}
		,A2V00001743107 : {
			de: "Verbindungslasche Radsatzanlenk. lack.",
			en: "Connectingclip Wheelsetsteering painted"
		}
		,A2V00002565537 : {
			de: "Querprofil",
			en: "cross profile"
		}
		,A2V00002003487 : {
			de: "Abdeckung vollst.",
			en: "cover compl."
		}
		,A2V00002624487 : {
			de: "Halter 02",
			en: "Holder 02"
		}
		,A2V00001890791 : {
			de: "Rahmen, geschw.",
			en: "Frame, welded"
		}
		,A2V00001213194 : {
			de: "LUEFTEREINHEIT VOLLST. CAR A",
			en: "LUEFTEREINHEIT VOLLST. VT642.0"
		}
		,A2V00002624567 : {
			de: "Abdeckung f. Zugnummeranzeige",
			en: "Cover f. train number display"
		}
		,A2V00002624661 : {
			de: "Halter 03",
			en: "Holder 03"
		}
		,A2V00002625116 : {
			de: "Welle",
			en: "Shaft"
		}
		,A2V00002625119 : {
			de: "Wippenkastenaufnahme",
			en: "Collector h. box support"
		}
		,A2V00002625122 : {
			de: "Auflaufhorn SWT",
			en: "Horn wp"
		}
		,A2V00002565978 : {
			de: "Gewindeplatte M10 Haltestange",
			en: "thread plate M10 holding rod"
		}
		,A2V00002625632 : {
			de: "Halter GSV",
			en: "Holder WSPV"
		}
		,A2V00002566272 : {
			de: "U-Kappe sp.g.",
			en: "U-cap r.i."
		}
		,A2V00002566273 : {
			de: "U-Kappe w.g.",
			en: "U-cap as d."
		}
		,A2V00002625828 : {
			de: "Halter 04",
			en: "Holder 04"
		}
		,A2V00002625857 : {
			de: "Flansch mit Rohr innen SWT",
			en: "Flange with tube inside WP"
		}
		,A2V00002566603 : {
			de: "Tuerverstaerkung sp.g.",
			en: "door reinforcment r.i."
		}
		,A2V00002566604 : {
			de: "Tuerverstaerkung w.g.",
			en: "door reinforcment as.d."
		}
		,A2V00002566735 : {
			de: "Schottwand 2, lackiert",
			en: "parititon wall 2, painted"
		}
		,A2V00002626173 : {
			de: "Kupplungsplatte",
			en: "Coupling plate"
		}
		,A2V00002626585 : {
			de: "Sickenblech Dach A4-Wagen",
			en: "Corrugated sheet roof A4-car"
		}
		,A2V00002626591 : {
			de: "C-Profil Front",
			en: "C-profile front"
		}
		,A2V00002626592 : {
			de: "L-Profil 1",
			en: "L-profile 1"
		}
		,A2V00002567107 : {
			de: "Schottwand 1, lackiert",
			en: "parititon wall 1, painted"
		}
		,A2V00002626593 : {
			de: "L-Profil kurz",
			en: "L-profile short"
		}
		,A2V00002626594 : {
			de: "L-Profil 2",
			en: "L-profile 2"
		}
		,A2V00002626964 : {
			de: "Sickenblech Dach C4-Wagen",
			en: "Corrugated sheet roof C4-car"
		}
		,A2V00002628198 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002628220 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002276757 : {
			de: "MO- Lager, Typ I",
			en: "MO- mounting, type I"
		}
		,A2V00002630489 : {
			de: "Kupplungkastenblech,sp.g.",
			en: "Couplingbox panel,i.m.i."
		}
		,A2V00002630490 : {
			de: "Kupplungskastenblech,w.g.",
			en: "Coupling-box panel,a.d."
		}
		,A2V00002630491 : {
			de: "Grundplatte",
			en: "Base plate"
		}
		,A2V00002573945 : {
			de: "Querprofil",
			en: "cross profile"
		}
		,A2V00002630622 : {
			de: "Montageschieneprofil 15",
			en: "Assembly rail profile 15"
		}
		,A2V00002723993 : {
			de: "Halter fuer Positionsschalter",
			en: "Bracket for position switch"
		}
		,A2V00002630843 : {
			de: "Montageschieneprofil 15",
			en: "Assembly rail profile 15"
		}
		,A2V00002630933 : {
			de: "Montageschieneprofil 15",
			en: "Assembly rail profile 15"
		}
		,A2V00001922463 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00200200077 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00002155494 : {
			de: "Drehzahlgeber GEL 247",
			en: "Speed sensor GEL247"
		}
		,A2V00002631042 : {
			de: "Verbindungsblech",
			en: "Connecting plate"
		}
		,A2V00002631051 : {
			de: "Trennblech 2",
			en: "Separating plate 2"
		}
		,A2V00002631260 : {
			de: "Klimaleitungsrohr geschweisst",
			en: "HVAC supply pipe welded"
		}
		,A2V00002631341 : {
			de: "Tuerbereichsabdeckung vollst.",
			en: "Door area cover compl."
		}
		,A2V00002631601 : {
			de: "Halter fuer Filter",
			en: "Holder for filter"
		}
		,A2V00002631699 : {
			de: "Tuerbereichsabdeckung vollst.",
			en: "Door area cover compl."
		}
		,A2V00002631739 : {
			de: "T-Stueck, vollst.",
			en: "T-piece, compl."
		}
		,A2V00002631865 : {
			de: "Haltewinkel 02 Absperrhahn",
			en: "Angel bracket 02 isolating cock"
		}
		,A2V00200372624 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00104744322 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002083672 : {
			de: "Profil f. C-Schiene 10",
			en: "Profile f. C-bar 10"
		}
		,A2V00002631932 : {
			de: "Einhaengeschiene",
			en: "Bracket profile"
		}
		,A2V00002631934 : {
			de: "Tuerbereichsabdeckung vollst. w.g.",
			en: "Door area cover compl. a.d."
		}
		,A2V00002632137 : {
			de: "Leiste",
			en: "Ledge"
		}
		,A2V00002632201 : {
			de: "Tuerbereichsabdeckung vollst. sp.g.",
			en: "Door area cover compl. i.m.i."
		}
		,A2V00002632271 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002632322 : {
			de: "Tuerbereichsabdeckung vollst. w.g.",
			en: "Door area cover compl. a.d."
		}
		,A2V00002632359 : {
			de: "Tuerbereichsabdeckung vollst. sp.g.",
			en: "Door area cover compl. i.m.i."
		}
		,A2V00002632390 : {
			de: "Einhaengewinkel",
			en: "Bracket angle"
		}
		,A2V00002584304 : {
			de: "Seitenwandmodul Mitte re Endwagen",
			en: "sidewall middle ri endcar"
		}
		,A2V00002633894 : {
			de: "Haengung w.g.",
			en: "Suspension a.d."
		}
		,A2V00002634118 : {
			de: "Stufe vollst. sp.g.",
			en: "Step compl. i.m.i."
		}
		,A2V00002634119 : {
			de: "Stufe vollst. w.g.",
			en: "Step compl. a.d."
		}
		,A2V00001218315 : {
			de: "Lagerbock, bearb.",
			en: "Bearing block machined"
		}
		,A2V00001218316 : {
			de: "Lagerbock, bearb.",
			en: "bearing block, machined"
		}
		,A2V00001378258 : {
			de: "Winkelblech",
			en: "Angle plate"
		}
		,A2V00001378257 : {
			de: "Deflektorplatte",
			en: "Deflector plate"
		}
		,A2V00001378259 : {
			de: "Winkelblech",
			en: "Angle plate"
		}
		,A2V00001352762 : {
			de: "Daempferkonsole",
			en: "Damper bracket"
		}
		,A2V00002624949 : {
			de: "Lagerblech",
			en: "Bracket"
		}
		,A2V00397165323 : {
			de: "Steg Kopftraeger",
			en: "Web head beam"
		}
		,A2V00397165324 : {
			de: "Obergurt Kopftraeger",
			en: "Upper belt head beam"
		}
		,A2V00397165325 : {
			de: "Untergurt Kopftraeger",
			en: "Lower belt head beam"
		}
		,A2V00397165327 : {
			de: "Lagerkonsole,inn. Kopftraeger",
			en: "Bearing bracket, inner head beam"
		}
		,A2V00397166864 : {
			de: "Anschweiszbutzen M10X20X32",
			en: "Weld on slug M10X20X32"
		}
		,A2V00397166868 : {
			de: "Lagerbock Antrieb",
			en: "Bearing block drive"
		}
		,A2V00397166924 : {
			de: "Lagerkonsole aeusz., re. WG",
			en: "Bearing bracket outer, right AD"
		}
		,A2V00397166925 : {
			de: "Lagerkonsole aeusz., links SPG",
			en: "Bearing bracket outer, left OAD"
		}
		,A2V00397170536 : {
			de: "Pufferbefestigungsblech",
			en: "Bumper fastening sheet"
		}
		,A2V00397170537 : {
			de: "Stuetzsteg, aeusz.",
			en: "Support web, outer"
		}
		,A2V00397170538 : {
			de: "Stuetzsteg, innen",
			en: "Support  web, inner"
		}
		,A2V00397170539 : {
			de: "Bahnraeumertragplatte",
			en: "Track sweeper carry plate"
		}
		,A2V00397220465 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002599030 : {
			de: "Haltebuegel",
			en: "Support bracket"
		}
		,A2V00002639582 : {
			de: "3-Faltenbalg inkl. Wulstringe FT210-32F",
			en: "3-Bellows with clincher ring FT210-32F"
		}
		,A2V00002667083 : {
			de: "Arretierlasche",
			en: "Fastening bracket"
		}
		,A2V00002667362 : {
			de: "Sickenblech Dach D4-Wagen",
			en: "Corrugated sheet roof D4-car"
		}
		,A2V00002667363 : {
			de: "Sickenblech Dach B4-Wagen",
			en: "Corrugated sheet roof B4-car"
		}
		,A2V00002667456 : {
			de: "Anhebelasche",
			en: "Lifting plate"
		}
		,A2V00002667527 : {
			de: "Abdeckung Schalter 2",
			en: "Cover switch 2"
		}
		,A2V00002667642 : {
			de: "Halter Bowdenzug 6",
			en: "Holder Bowdencable 6"
		}
		,A2V00002667914 : {
			de: "Konsole sp.g.",
			en: "Bracket i.m.i."
		}
		,A2V00002667915 : {
			de: "Konsole w.g.",
			en: "Bracket a.d."
		}
		,A2V00002668164 : {
			de: "Federtraeger",
			en: "Spring carrier"
		}
		,A2V00002668411 : {
			de: "Federtraeger",
			en: "Spring carrier"
		}
		,A2V00002668555 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002669041 : {
			de: "Haengung vollst. w.g.",
			en: "Suspension compl. a.d."
		}
		,A2V00002669095 : {
			de: "Haengung sp.g.",
			en: "Suspension i.m.i."
		}
		,A2V00002669206 : {
			de: "Haengung vollst. sp.g.",
			en: "Suspension compl. i.m.i."
		}
		,A2V00002669326 : {
			de: "Flansch mit Rohr aussen SWT",
			en: "Flange with tube outside WP"
		}
		,A2V00002669861 : {
			de: "Unterarm SWT/RAL7021",
			en: "Lower arm wp/RAL7021"
		}
		,A2V00002669953 : {
			de: "Befestigungswinkel 1",
			en: "Fastening angle 1"
		}
		,A2V00002670186 : {
			de: "Befestigungswinkel 2",
			en: "Fastening angle 2"
		}
		,A2V00002079032 : {
			de: "Halbschale kurz",
			en: "Half-shell short"
		}
		,A2V00001743108 : {
			de: "Verbindungslasche Radsatzanlenkung",
			en: "Connectingclip Wheelsetsteering"
		}
		,A2V00001722001 : {
			de: "Mitnahmebolzen",
			en: "Driver pin"
		}
		,A2V00002670416 : {
			de: "Deckblech",
			en: "Cover sheet"
		}
		,A2V00002670906 : {
			de: "Quertraeger ZSB",
			en: "Cross beam assy."
		}
		,A2V00002671240 : {
			de: "Oberarm M SWT/RAL7021",
			en: "Upper arm M wp/RAL7021"
		}
		,A2V00002590273 : {
			de: "Kabeldurchfuehrung kompl.",
			en: "Cable fairlead compl."
		}
		,A2V00002673325 : {
			de: "Gewindestange fuer Kuppelstange M",
			en: "Thread rod for coupling rod M"
		}
		,A2V00002674614 : {
			de: "Basisbox SWT",
			en: "Base box wp"
		}
		,A2V00002674727 : {
			de: "Deckel SWT",
			en: "Cover wp"
		}
		,A2V00002677333 : {
			de: "Aufnahmelasche Parallelfuehrung",
			en: "Holder collector head guide"
		}
		,A2V00002677731 : {
			de: "Parallelfuehrungsstange RAL7021",
			en: "Parallel guide rod RAL7021"
		}
		,A2V00002679108 : {
			de: "Gabelkopf",
			en: "Forke head"
		}
		,A2V00002679457 : {
			de: "Lagerbolzen",
			en: "Bearing bolt"
		}
		,A2V00002679458 : {
			de: "Lagerhuelse",
			en: "Bearing sleeve"
		}
		,A2V00002679499 : {
			de: "Kabeldom vollst. WE2 T2",
			en: "Cable housing compl. CE2 T2"
		}
		,A2V00002679500 : {
			de: "Kabeldom vollst. WE2 T1",
			en: "Cable housing compl. CE2 T1"
		}
		,A2V00002679602 : {
			de: "Nutenstein M12",
			en: "Slot stone M12"
		}
		,A2V00002683430 : {
			de: "Wischerblech B, geschw.",
			en: "Wiper plate B, welded"
		}
		,A2V00002683431 : {
			de: "Wischerblech A, geschw.",
			en: "Wiper plate A, welded"
		}
		,A2V00002683596 : {
			de: "Stuetzwinkel (UA)",
			en: "Support bracket (LA)"
		}
		,A2V00002604473 : {
			de: "Uebergangsleiste",
			en: "Cover strip profile"
		}
		,A2V00002604474 : {
			de: "Uebergangsleiste",
			en: "Cover strip profile"
		}
		,A2V00002604475 : {
			de: "Uebergangsleiste",
			en: "Cover strip profile"
		}
		,A2V00002683645 : {
			de: "Beulsteife Seite MV",
			en: "Stiffener side"
		}
		,A2V00002683704 : {
			de: "Hutprofil",
			en: "Hat profile"
		}
		,A2V00002683753 : {
			de: "Beulsteife Seite MV",
			en: "Stiffener side"
		}
		,A2V00002683879 : {
			de: "L-Profil",
			en: "L-profile"
		}
		,A2V00002683901 : {
			de: "Hutprofil",
			en: "Hat profile"
		}
		,A2V00002683906 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002684391 : {
			de: "Befestigung Sitz",
			en: "Fitting Seat"
		}
		,A2V00002684788 : {
			de: "Lagerwelle, Knie",
			en: "Shaft, knee"
		}
		,A2V00002168973 : {
			de: "Crashbox geschweisst",
			en: "Crashbox welded"
		}
		,A2V00002186776 : {
			de: "Unterfahrschutz geschweisst",
			en: "underride bar welded"
		}
		,A2V00370018559 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00100023469 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002613850 : {
			de: "U-Kappe",
			en: "u-cap"
		}
		,A2V00002685292 : {
			de: "Distanzstueck H29,5",
			en: "Spacer H29,5"
		}
		,A2V00001991835 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001941614 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001941613 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001709267 : {
			de: "Montagematerial Radsatzlagerdeckel",
			en: "mounting material wheelset front cover"
		}
		,A2V00002689152 : {
			de: "Konsole w.g.",
			en: "Bracket a.d."
		}
		,A2V00002689154 : {
			de: "Konsole sp.g.",
			en: "Bracket i.m.i."
		}
		,A2V00002690585 : {
			de: "C-Profil 2",
			en: "C-profile 2"
		}
		,A2V00002622354 : {
			de: "Drehsaeule, vollst.",
			en: "Rotating column, compl."
		}
		,A2V00002622355 : {
			de: "Drehsaeule, vollst.",
			en: "Rotating column, compl."
		}
		,A2V00002690763 : {
			de: "Konsole 1 Vollst.",
			en: "Bracket 1 compl."
		}
		,A2V00002690764 : {
			de: "Konsole 2 Vollst.",
			en: "Bracket 2 compl."
		}
		,A2V00002691124 : {
			de: "Profil",
			en: "Profile"
		}
		,A2V00002710493 : {
			de: "Winkel",
			en: "Angle"
		}
		,A2V00002692365 : {
			de: "Adapterblech",
			en: "Adapter plate"
		}
		,A2V00002692511 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002629371 : {
			de: "Unterfahrschutz",
			en: "underrun bumper"
		}
		,A2V00370042752 : {
			de: "BEILAGE F.QUERPUFFER 5MM",
			en: "SHIM F. LATERAL BUMPSTOP 5MM"
		}
		,A2V00370059331 : {
			de: "Kleber (AC) Scotch-Weld DP-8005 weiss",
			en: "Adhesive (AC) Scotch-Weld DP-8005 white"
		}
		,A2V00397235456 : {
			de: "BEILAGE 5MM",
			en: "SHIM 5MM"
		}
		,A2V00002693792 : {
			de: "Adapterblech",
			en: "Adapter sheet"
		}
		,A2V00002694054 : {
			de: "Sitzkonsole vollst., geschw.",
			en: "Seat console compl., welded"
		}
		,A2V00002633601 : {
			de: "Scharfenbergkupplung",
			en: "Scharfenberg coupler"
		}
		,A2V00002695816 : {
			de: "Verlaengerung Haltestange geschw.",
			en: "Extension handrail welded"
		}
		,A2V00001357870 : {
			de: "Vollrad 850/780-18,5-219",
			en: "solid wheel 850/780-18,5-219"
		}
		,A2V00001204303 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370037103 : {
			de: "Bolzen ISO 13918 - PD M8x25 - A4-50",
			en: "Stud ISO 13918 - PD M8x25 - A4-50"
		}
		,A2V00002668169 : {
			de: "Klebeprofil",
			en: "sticking profile"
		}
		,A2V00002668170 : {
			de: "Klebeprofil",
			en: "sticking profile"
		}
		,A2V00002668171 : {
			de: "Klebeprofil",
			en: "sticking profile"
		}
		,A2V00002668218 : {
			de: "Abschlussprofil Boden sp.g.",
			en: "end profile floor plate rev.im."
		}
		,A2V00002668219 : {
			de: "Abschlussprofil Boden sp.g.",
			en: "end profile floor plate  rev.im."
		}
		,A2V00002695885 : {
			de: "Haltestange Verbindung mit Sicherung",
			en: "Stanchion connection with securing"
		}
		,A2V00002668220 : {
			de: "Abschlussprofil Boden w.g.",
			en: "end profile floor plate a.d."
		}
		,A2V00002668221 : {
			de: "Abschlussprofil Boden",
			en: "end profile floor plate"
		}
		,A2V00002695926 : {
			de: "Haltestange Verbindung",
			en: "Stanchion connection"
		}
		,A2V00002668651 : {
			de: "Rippe",
			en: "rib"
		}
		,A2V00370082151 : {
			de: "SCHLAUCHLEITUNG 3TE 6 A2A2 390 P 270Â°",
			en: "HOSE ASSEMBLY 3TE 6 A2A2 390 P 270Â°"
		}
		,A2V00370067103 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002730071 : {
			de: "Durchfuehrung",
			en: "lead - through"
		}
		,A2V00001721611 : {
			de: "Impulsgeber 1-kanalig FS01A",
			en: "Pulse generator 1-channel FS01A"
		}
		,A2V00002697970 : {
			de: "Anschweissblech sp.g.",
			en: "Welding plate i.m.i."
		}
		,A2V00002697971 : {
			de: "Anschweissblech w.g.",
			en: "Welding plate a.d."
		}
		,A2V00370072732 : {
			de: "Wellrohrverschraubung, gerade",
			en: "Conduit connector, staight"
		}
		,A2V00370030473 : {
			de: "Sechskantmutter ISO 4035 M16 A4-70",
			en: "Hexagon nut ISO 4035 M16 A4-70"
		}
		,A2V00002698322 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002699632 : {
			de: "Rohr f. Lagerwelle Ausf.1",
			en: "Tube f. bearing shaft vers.1"
		}
		,A2V00002700065 : {
			de: "Gleitplatte 6,5",
			en: "Sliding plate 6,5"
		}
		,A2V00002700396 : {
			de: "Gleitplatte 5,5",
			en: "Sliding plate 5,5"
		}
		,A2V00002676001 : {
			de: "Schalenmuffenverbindung",
			en: "Muff coupling"
		}
		,A2V00002700696 : {
			de: "Randleiste C w.g.",
			en: "Border ledge C a.d."
		}
		,A2V00002700697 : {
			de: "Randleiste C sp.g.",
			en: "Border ledge C i.m.i."
		}
		,A2V00002700698 : {
			de: "Trittplatte B rechts geschw.",
			en: "Kick plate B right welded"
		}
		,A2V00002700699 : {
			de: "Trittplatte B links geschw.",
			en: "Kick plate B left welded"
		}
		,A2V00002701537 : {
			de: "Konsole geschw.",
			en: "Cantilever welded"
		}
		,A2V00002704207 : {
			de: "Blech w.g.",
			en: "Sheet a.d."
		}
		,A2V00002704208 : {
			de: "Blech sp.g.",
			en: "Sheet r.i."
		}
		,A2V00002704496 : {
			de: "Antrieb, 2-flg., vollst.",
			en: "Drive, 2 leafs, compl."
		}
		,A2V00002683086 : {
			de: "Abdeckblech sp.g.",
			en: "cover plate r.i."
		}
		,A2V00002683087 : {
			de: "Abdeckblech w.g.",
			en: "cover plate as d."
		}
		,A2V00002704979 : {
			de: "L-Profil",
			en: "L-profile"
		}
		,A2V00002704980 : {
			de: "L-Profil quer",
			en: "L-profile across"
		}
		,A2V00370068495 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00370068151 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002683367 : {
			de: "Platte Klebemuster",
			en: "Plate gluing sample"
		}
		,A2V00002684055 : {
			de: "Sandwichrampe Toilette",
			en: "sandwich ramp toilet"
		}
		,A2V00002684142 : {
			de: "Bodenwinklel",
			en: "floor cover"
		}
		,A2V00002684143 : {
			de: "Bodenwinklel",
			en: "floor cover"
		}
		,A2V00002684145 : {
			de: "Bodenwinklel",
			en: "floor cover"
		}
		,A2V00002706563 : {
			de: "Distanzstueck fuer Isolator",
			en: "Spacer for insulator"
		}
		,A2V00002688432 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00001942265 : {
			de: "Beilage Querfed. 10mm",
			en: "Shim lat. susp. 10mm"
		}
		,A2V00001942360 : {
			de: "Beilage Querfed. 5mm",
			en: "Shim lat. susp. 5mm"
		}
		,A2V00002709555 : {
			de: "Gewindestange isoliert",
			en: "Thread rod insulated"
		}
		,A2V00002691642 : {
			de: "F-Ende komplett",
			en: "front end complete"
		}
		,A2V00002709993 : {
			de: "Adapterblech",
			en: "Adapter sheet"
		}
		,A2V00002428310 : {
			de: "Winkel",
			en: "angle"
		}
		,A2V00002081510 : {
			de: "Aufnahme vollst.",
			en: "reception complete"
		}
		,A2V00001837084 : {
			de: "Tisch, vollst (hoehenverstellbar)",
			en: "Table, compl. (height adjust.)"
		}
		,A2V00001870152 : {
			de: "TVM sensor - TVM-BSP",
			en: "TVM sensor - TVM-BSP"
		}
		,A2V00397270832 : {
			de: "PE-Seil CU, 50 8 10 400",
			en: "PE-Rope CU, 50 8 10 400"
		}
		,A2V00002349674 : {
			de: "Rahmen kpl. Klimaanlage",
			en: "frame compl."
		}
		,A2V00002711101 : {
			de: "Schliessblech 3 sp.g.",
			en: "Strike plate 3 i.m.i."
		}
		,A2V00002711159 : {
			de: "Schliessblech 3 w.g.",
			en: "Strike plate 3 a.d."
		}
		,A2V00002694058 : {
			de: "Flanschplatte vollst.",
			en: "flange compl."
		}
		,A2V00002694425 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002714223 : {
			de: "Laufflaeche",
			en: "Running surface"
		}
		,A2V00002714225 : {
			de: "Fuehrungswinkel w.g.",
			en: "Guide elbow a.d."
		}
		,A2V00002714293 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002714306 : {
			de: "Fuehrungswinkel sp.g.",
			en: "Guide elbow i.m.i."
		}
		,A2V00002695651 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002714307 : {
			de: "Buegel",
			en: "Clamp"
		}
		,A2V00002695865 : {
			de: "Befuellstutzen vollst.",
			en: "Filling connection compl."
		}
		,A2V00002714320 : {
			de: "Futterblech, seitliches",
			en: "Shim, side"
		}
		,A2V00002714321 : {
			de: "Futterblech, mittleres",
			en: "Shim, middle"
		}
		,A2V00002695919 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002695920 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002714501 : {
			de: "Kurvenscheibe Geometrie M",
			en: "Cam disc geometry M"
		}
		,A2V00002714628 : {
			de: "Halter Hebel",
			en: "Holder lever"
		}
		,A2V00002715216 : {
			de: "Wassertank, geschw.",
			en: "Water tank, welded"
		}
		,A2V00002087684 : {
			de: "Beilage 1mm",
			en: "shim 1mm"
		}
		,A2V00001918418 : {
			de: "Schwenkwand, vollst.",
			en: "rotatable wall, compl."
		}
		,A2V00002734487 : {
			de: "Nutenstein LF",
			en: "thread block LF"
		}
		,A2V00002715486 : {
			de: "Halter Fahrkartenautomat",
			en: "Holder ticket machine"
		}
		,A2V00001429703 : {
			de: "Daempfer sekundaer horizontal",
			en: "secondary lateral shock absorber"
		}
		,A2V00002697885 : {
			de: "Druckluftdurchfuehrung komplett",
			en: "pneumatic feedthrough complete"
		}
		,A2V00002698324 : {
			de: "Ablaufrohr",
			en: "drain pipe"
		}
		,A2V00002698497 : {
			de: "Ablaufrohr",
			en: "drain pipe"
		}
		,A2V00002772261 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002698574 : {
			de: "Abschlussprofil Boden",
			en: "end profile floor plate"
		}
		,A2V00002698764 : {
			de: "Knotenblech w.g.",
			en: "junction plate a.d."
		}
		,A2V00002698789 : {
			de: "Knotenblech sp.g.",
			en: "junction plate rev.im."
		}
		,A2V00002716922 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002699489 : {
			de: "Rahmen komplett Kabelkamin",
			en: "frame complete cable pipe"
		}
		,A2V00002699613 : {
			de: "Badsicherung w.g.",
			en: "weld pool backing as d."
		}
		,A2V00002699641 : {
			de: "Konsole kompl. Dachelektrant",
			en: "console compl. connector block"
		}
		,A2V00002699651 : {
			de: "U-Kappe",
			en: "u-cap"
		}
		,A2V00002699677 : {
			de: "Badsicherung sp.g.",
			en: "weld pool backing r.i."
		}
		,A2V00002718725 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002699764 : {
			de: "Haube Powerkabel komplett",
			en: "box powercable complete"
		}
		,A2V00002718735 : {
			de: "Versteifung",
			en: "Stiffening"
		}
		,A2V00002699841 : {
			de: "Befuellungstopf kpl.",
			en: "filling box compl."
		}
		,A2V00002718768 : {
			de: "Versteifung",
			en: "Stiffening"
		}
		,A2V00002718769 : {
			de: "Versteifung",
			en: "Stiffening"
		}
		,A2V00002718906 : {
			de: "Versteifung",
			en: "Stiffening"
		}
		,A2V00002719234 : {
			de: "Schmiedeteil 6082 T6 48 FSW",
			en: "Forging 6082 T6 48 FSW"
		}
		,A2V00002700216 : {
			de: "Klebeprofil",
			en: "sticking profile"
		}
		,A2V00002719377 : {
			de: "Schmiedeteil 6082 T6 15 FSW",
			en: "Forging 60820 T6 15 FSW"
		}
		,A2V00002719431 : {
			de: "Schmiedeteil 6082 T6 15 MIG",
			en: "Forging 60820 T6 15 MIG"
		}
		,A2V00370039539 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002719702 : {
			de: "Halter w.g.",
			en: "Holder a.d."
		}
		,A2V00002719703 : {
			de: "Halter sp.g.",
			en: "Holder i.m.i."
		}
		,A2V00002721590 : {
			de: "Halter geschw.",
			en: "Holder welded"
		}
		,A2V00002701039 : {
			de: "Absaugkasten kpl.",
			en: "suction box compl."
		}
		,A2V00002722003 : {
			de: "Schubfeld Anbau w.g.",
			en: "Shear field mounting a.d."
		}
		,A2V00002722017 : {
			de: "Schubfeld Anbau sp.g.",
			en: "Shear field mounting i.m.i."
		}
		,A2V00002722018 : {
			de: "Schubfeld Anbau sp.g.",
			en: "Shear field mounting i.m.i."
		}
		,A2V00002722107 : {
			de: "Schubfeld Anbau w.g.",
			en: "Shear field mounting a.d."
		}
		,A2V00002722229 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002722392 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002722461 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002722477 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002723210 : {
			de: "Konsole",
			en: "Console"
		}
		,A2V00002723245 : {
			de: "Winkel",
			en: "Angle"
		}
		,A2V00002723694 : {
			de: "Halter, geschw.",
			en: "Holder welded"
		}
		,A2V00002723875 : {
			de: "Halter Heckfahrerstand, geschw.",
			en: "Holder driver cockpit, welded"
		}
		,A2V00002723968 : {
			de: "Windleitblech 3-1918.5116",
			en: "Spoiler 3-1918.5116"
		}
		,A2V00002723970 : {
			de: "Windleitblechhalter kpl. 3-W08.14850",
			en: "Spoiler compl. 3-W08.14850"
		}
		,A2V00002723972 : {
			de: "Windleitblech 3-W08.11351",
			en: "Spoiler 3-W08.11351"
		}
		,A2V00002723998 : {
			de: "Halter, w.g.",
			en: "Holder, a.d."
		}
		,A2V00002724018 : {
			de: "Halter, sp.g.",
			en: "Holder, i.m.i."
		}
		,A2V00002168972 : {
			de: "Crashbox geschweisst",
			en: "Crashbox welded"
		}
		,A2V00002703110 : {
			de: "Trennplatte",
			en: "Dividing Plate"
		}
		,A2V00002703932 : {
			de: "KK-Ende vollst.",
			en: "short coupler end compl."
		}
		,A2V00002704135 : {
			de: "Uebergangsstueck w.g.",
			en: "adapter a.d."
		}
		,A2V00002704192 : {
			de: "Uebergangsstueck sp.g.",
			en: "adapter rev.im."
		}
		,A2V00001848503 : {
			de: "Stromabnehmer 1450/AC",
			en: "Pantograph AC"
		}
		,A2V00002704278 : {
			de: "Uebergangsstueck sp.g.",
			en: "adapter rev.im."
		}
		,A2V00002704306 : {
			de: "Uebergangsstueck w.g.",
			en: "adapter a.d."
		}
		,A2V00002725438 : {
			de: "Bodenplatte 2 FSW",
			en: "Base plate 2 FSW"
		}
		,A2V00002725463 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397207538 : {
			de: "Stegblech",
			en: "Web plate"
		}
		,A2V00397207539 : {
			de: "Halteblech",
			en: "Holding plate"
		}
		,A2V00001433277 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00397185966 : {
			de: "Beilage 10mm",
			en: "Shim 10mm"
		}
		,A2V00397185968 : {
			de: "Beilage 3mm",
			en: "Shim 3mm"
		}
		,A2V00397185969 : {
			de: "Beilage 2mm",
			en: "Shim 2mm"
		}
		,A2V00397186394 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00397200589 : {
			de: "Entgleisungsschutzbalken, vollst.",
			en: "Derailment guard girder, compl."
		}
		,A2V00397164709 : {
			de: "DehnhÃ¼lse",
			en: "Bushing"
		}
		,A2V00002725465 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002725467 : {
			de: "Bodenplatte 1 FSW",
			en: "Base plate 1 FSW"
		}
		,A2V00397163996 : {
			de: "Unterlage 5mm f. Querpuffer",
			en: "shim 5mm f. cross bumper"
		}
		,A2V00397186393 : {
			de: "Unterlage grundiert",
			en: "Washer primed"
		}
		,A2V00002794621 : {
			de: "Saeulenkonsole 1, gsw.",
			en: "Pillar bracket 2, welded"
		}
		,A2V00397129506 : {
			de: "Erdungslasche",
			en: "grounding strip"
		}
		,A2V00397195911 : {
			de: "BEILAGE",
			en: "SHIM"
		}
		,A2V00002582593 : {
			de: "Deckplatte",
			en: "Cover plate"
		}
		,A2V00002705168 : {
			de: "Blech sp.g.",
			en: "sheet rev. i."
		}
		,A2V00002705169 : {
			de: "Blech w.g.",
			en: "sheet as d."
		}
		,A2V00002725509 : {
			de: "Bodenplatte 3 FSW",
			en: "Base plate 3 FSW"
		}
		,A2V00002726735 : {
			de: "Winkel Behaelter 02",
			en: "Bracket Reservoir 02"
		}
		,A2V00002726789 : {
			de: "Konsole Hauptanschluss SWT",
			en: "Bracket main connector WP"
		}
		,A2V00002726862 : {
			de: "Winkel Behaelter 01",
			en: "Bracket Reservoir 01"
		}
		,A2V00002726948 : {
			de: "Fensteruntergurt geschw.",
			en: "Window-lower belt welded"
		}
		,A2V00002726981 : {
			de: "Winkel Behaelter 03",
			en: "Bracket Reservoir 03"
		}
		,A2V00002726988 : {
			de: "Winkel Behaelter 04",
			en: "Bracket Reservoir 04"
		}
		,A2V00002727019 : {
			de: "Winkel Behaelter 06",
			en: "Bracket Reservoir 06"
		}
		,A2V00002727041 : {
			de: "AS-Adapter",
			en: "ADD adapter"
		}
		,A2V00002727075 : {
			de: "Winkel Behaelter 05",
			en: "Bracket Reservoir 05"
		}
		,A2V00002727143 : {
			de: "Halter Bowdenzug",
			en: "Holder Bowdencable"
		}
		,A2V00002727144 : {
			de: "Befestigungswinkel 10",
			en: "Fastening angle 10"
		}
		,A2V00002727649 : {
			de: "AS-Blech",
			en: "ADD sheet"
		}
		,A2V00002727731 : {
			de: "Welle L980",
			en: "Shaft L980"
		}
		,A2V00002706634 : {
			de: "Stirnwand",
			en: "end wall"
		}
		,A2V00002706649 : {
			de: "Stirnwand",
			en: "end wall"
		}
		,A2V00002707459 : {
			de: "Steckerkasten komplett",
			en: "connector box complete"
		}
		,A2V00002728657 : {
			de: "Tuersaeulenprofil",
			en: "Door pillar profile"
		}
		,A2V00002707465 : {
			de: "Stirnwand Innenprofil sp.g.",
			en: "endwall"
		}
		,A2V00002728658 : {
			de: "Endsaeulenprofil",
			en: "End pillar profile"
		}
		,A2V00002707516 : {
			de: "Stirnwand Innenprofil w.g.",
			en: "endwall inside profil a.d."
		}
		,A2V00002728776 : {
			de: "Seitenwandprofil mitte",
			en: "Sidewall profile middle"
		}
		,A2V00002728778 : {
			de: "Profil Seitenwand,ob. 1",
			en: "Profile,up. 1 f. sidewall"
		}
		,A2V00002728779 : {
			de: "Profil Seitenwand,ob. 2",
			en: "Profile,up. 2 f. sidewall"
		}
		,A2V00002728780 : {
			de: "Profil Seitenwand,ob. 3",
			en: "Profile,up. 3 f. sidewall"
		}
		,A2V00002728781 : {
			de: "Fenstersaeulenprofil",
			en: "Window pillar profile"
		}
		,A2V00002728815 : {
			de: "Winkellasche fuer Stecker",
			en: "Angle bracket for plug"
		}
		,A2V00001922390 : {
			de: "Dehnhuelse",
			en: "Extension sleeve"
		}
		,A2V00002729036 : {
			de: "Seitenwand oben 2",
			en: "Sidewall upper 2"
		}
		,A2V00002729135 : {
			de: "Fenstersaeule",
			en: "Window pillar"
		}
		,A2V00002710074 : {
			de: "Seitenwandmodul Mitte re Endwagen",
			en: "sidewall middle ri endcar"
		}
		,A2V00002710075 : {
			de: "Seitenwandmodul Mitte li",
			en: "sidewall middle left"
		}
		,A2V00002710242 : {
			de: "Langtraeger",
			en: "longitudinal beam"
		}
		,A2V00002730058 : {
			de: "Halter Schild",
			en: "Holder Label"
		}
		,A2V00002730495 : {
			de: "Edelstahlseil d8",
			en: "Wire stainless-steel d8"
		}
		,A2V00002730661 : {
			de: "Lageraufnahme kompl.",
			en: "Bearing house compl."
		}
		,A2V00002710725 : {
			de: "Konsole komplett Hauptschalter",
			en: "console complete main switch"
		}
		,A2V00002710920 : {
			de: "Konsole LZB",
			en: "console LZB"
		}
		,A2V00002710955 : {
			de: "Konsole komplett Spannungswandler",
			en: "console complete voltage transformator"
		}
		,A2V00002710978 : {
			de: "Konsole komplett Hauptschalter",
			en: "console complete main switch"
		}
		,A2V00002730787 : {
			de: "Quertraeger ZSB",
			en: "Cross beam assy."
		}
		,A2V00002710997 : {
			de: "WC-Konsole",
			en: "WC-console"
		}
		,A2V00002711011 : {
			de: "Konsole komplett Pantograph",
			en: "console complete pantograph"
		}
		,A2V00002711016 : {
			de: "WC-Konsole",
			en: "WC-console"
		}
		,A2V00002711038 : {
			de: "WC-Konsole",
			en: "WC-console"
		}
		,A2V00002711067 : {
			de: "Halter komplett Isolator",
			en: "holder complete isolator"
		}
		,A2V00002711069 : {
			de: "Konsole kpl. Toilettenrack",
			en: "console compl. toilet rack"
		}
		,A2V00002711146 : {
			de: "Konsole komplett Kabeldurchfuehrung",
			en: "console complete cable feedthrough"
		}
		,A2V00002731113 : {
			de: "Blech 6082 T6 18 FSW",
			en: "Plate 6082 T6 18 FSW"
		}
		,A2V00002711162 : {
			de: "Konsole kpl. Toilettenrack",
			en: "console compl. toilet rack"
		}
		,A2V00002731189 : {
			de: "Haltegriff vorne sp.g.",
			en: "Handle front i.m.i."
		}
		,A2V00002731190 : {
			de: "Haltegriff vorne w.g.",
			en: "Handle front a.d."
		}
		,A2V00002711298 : {
			de: "Platte Rohrdurchfuehrung",
			en: "plate pipe penetration"
		}
		,A2V00002731277 : {
			de: "Haltegriff hinten w.g.",
			en: "Handle back a.d."
		}
		,A2V00002731278 : {
			de: "Haltegriff hinten sp.g.",
			en: "Handle back i.m.i."
		}
		,A2V00002731782 : {
			de: "Klappsitzrahmen 2",
			en: "Tip-up seat frame 2"
		}
		,A2V00002731811 : {
			de: "Schliessblech 4 sp.g.",
			en: "Strike plate 4 i.m.i."
		}
		,A2V00002731816 : {
			de: "Schliessblech 4 w.g.",
			en: "Strike plate 4 a.d."
		}
		,A2V00002711568 : {
			de: "Langtraeger vollst. Untergestell EW",
			en: "longitudinal girder compl. underframe CE"
		}
		,A2V00002711570 : {
			de: "Langtraeger vollst. Untergestell EW",
			en: "longitudinal girder compl. underframe CE"
		}
		,A2V00002711584 : {
			de: "Gewindeplatte",
			en: "Thread plate"
		}
		,A2V00002732432 : {
			de: "Radkasten",
			en: "Wheel box"
		}
		,A2V00002732674 : {
			de: "Adapterblech",
			en: "Adapter plate"
		}
		,A2V00002732757 : {
			de: "Cantileverrahmen",
			en: "Cantilever frame"
		}
		,A2V00002733327 : {
			de: "Regenrinnenverkleidung vollst. sp.g.",
			en: "Cover of rain gutter compl. i.m.i."
		}
		,A2V00002733328 : {
			de: "Regenrinnenverkleidung vollst. w.g.",
			en: "Cover of rain gutter compl. a.d."
		}
		,A2V00002712991 : {
			de: "Langtraeger",
			en: "longitudinal beam"
		}
		,A2V00002733557 : {
			de: "Regenrinnenverkleidung vollst. sp.g.",
			en: "Cover of rain gutter compl. i.m.i."
		}
		,A2V00002713016 : {
			de: "B-Saeule kompl. L",
			en: "b-post compl. l"
		}
		,A2V00002733558 : {
			de: "Regenrinnenverkleidung vollst. w.g.",
			en: "Cover of rain gutter compl. a.d."
		}
		,A2V00002713017 : {
			de: "B-Saeule kompl. R",
			en: "b-post compl. r"
		}
		,A2V00002713182 : {
			de: "Tuerquertraeger kompl.",
			en: "door crossgirder compl."
		}
		,A2V00002713558 : {
			de: "Tuerquertraeger kompl.",
			en: "door crossgirder compl."
		}
		,A2V00002714071 : {
			de: "U-Kappe sp.gl.",
			en: "u-cap r.i."
		}
		,A2V00370039547 : {
			de: "Schichtfeder",
			en: "layer spring"
		}
		,A2V00002714072 : {
			de: "U-Kappe w.g.",
			en: "u-cap as d."
		}
		,A2V00002734141 : {
			de: "Gewindemuffe G1/4",
			en: "Screw socket G1/4"
		}
		,A2V00002734206 : {
			de: "Versteifung",
			en: "Stiffener"
		}
		,A2V00002734208 : {
			de: "Klappsitzrahmen 3",
			en: "Tip-up seat frame 3"
		}
		,A2V00002714784 : {
			de: "Eckprofil w.g.",
			en: "corner profile as d."
		}
		,A2V00002714785 : {
			de: "Eckprofil sp.g.",
			en: "corner profile r. i."
		}
		,A2V00002715072 : {
			de: "Knotenblech w.g.",
			en: "support plate as d."
		}
		,A2V00002715073 : {
			de: "Knotenblech sp. g.",
			en: "support plate r. i."
		}
		,A2V00002715116 : {
			de: "Gewindeplatte fuer Drehsaeule",
			en: "Threaded plate for lock column"
		}
		,A2V00001971395 : {
			de: "Bremswelle mit Doppelwellenbremsscheibe",
			en: "Brake shaft with brake rotor disk"
		}
		,A2V00001691886 : {
			de: "GroÃŸradnabe",
			en: "Hub of large gear"
		}
		,A2V00001983718 : {
			de: "Impulsgeber 1-kanalig FS01A",
			en: "Pulse generator 1-channel FS01A"
		}
		,A2V00002715260 : {
			de: "Knotenblech w. g.",
			en: "support plate as d."
		}
		,A2V00002715261 : {
			de: "Knotenblech sp. g.",
			en: "support plate r. i."
		}
		,A2V00002715397 : {
			de: "Schweissbadsicherung w.g.",
			en: "weld pool backing as d."
		}
		,A2V00002715407 : {
			de: "Schweissbadsicherung sp. g.",
			en: "weld pool backing r. i."
		}
		,A2V00002715503 : {
			de: "Badsicherung sp. g.",
			en: "weld pool backing r. i."
		}
		,A2V00002715526 : {
			de: "Badsicherung w. g.",
			en: "weld pool backing as d."
		}
		,A2V00002715682 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002715888 : {
			de: "Querprofil Portal voll.",
			en: "cross profile portal compl."
		}
		,A2V00002305843 : {
			de: "Einstiegstuer li.",
			en: "Entrance door le."
		}
		,A2V00002305836 : {
			de: "Einstiegstuer re.",
			en: "entrance door ri."
		}
		,A2V00002065393 : {
			de: "Einstiegstuer li.",
			en: "entrance door le."
		}
		,A2V00002538792 : {
			de: "Einstiegstuer li. RAL9016/EVVA-Zylinder",
			en: "entrance door le. RAL9016/EVVA-cylinder"
		}
		,A2V00002538791 : {
			de: "Einstiegstuer re. RAL9016/EVVA-Zylinder",
			en: "entrance door ri. RAL9016/EVVA-cylinder"
		}
		,A2V00002132530 : {
			de: "Abhebesicherung",
			en: "Lifting stop"
		}
		,A2V00002716220 : {
			de: "Knotenblech sp.g.",
			en: "junction plate rev.im."
		}
		,A2V00002716708 : {
			de: "Knotenblech w.g.",
			en: "junction plate a.d."
		}
		,A2V00002735806 : {
			de: "Seitenwand mitte 1",
			en: "Sidewall middle 1"
		}
		,A2V00002716801 : {
			de: "Knotenblech sp.g.",
			en: "junction plate rev.im."
		}
		,A2V00002716830 : {
			de: "Knotenblech",
			en: "junction plate"
		}
		,A2V00002716837 : {
			de: "Knotenblech w.g.",
			en: "junction plate a.d."
		}
		,A2V00002735835 : {
			de: "Befestigungswinkel",
			en: "Mounting angle"
		}
		,A2V00002716881 : {
			de: "Knotenblech w.g.",
			en: "junction plate a.d."
		}
		,A2V00002735905 : {
			de: "Regenrinnenverkleidung vollst. sp.g.",
			en: "Cover of rain gutter compl. i.m.i."
		}
		,A2V00002735960 : {
			de: "Anschweissblech w.g.",
			en: "Welding plate a.d."
		}
		,A2V00002735961 : {
			de: "Anschweissblech sp.g.",
			en: "Welding plate i.m.i."
		}
		,A2V00002716906 : {
			de: "Ecke w.g.",
			en: "corner as.d."
		}
		,A2V00002716907 : {
			de: "Ecke sp.g.",
			en: "corner r.i."
		}
		,A2V00002716910 : {
			de: "Abdeckblech sp. g.",
			en: "cover plate r. i."
		}
		,A2V00002735996 : {
			de: "Regenrinnenverkleidung vollst. w.g.",
			en: "Cover of rain gutter compl. a.d."
		}
		,A2V00002716911 : {
			de: "Abdeckblech w.g.",
			en: "cover plate as d."
		}
		,A2V00002716931 : {
			de: "Knotenblech sp.g.",
			en: "junction plate rev.im."
		}
		,A2V00002736068 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002736172 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002717605 : {
			de: "Ausgleichsblech w. g.",
			en: "levelling plate as d."
		}
		,A2V00002717606 : {
			de: "Ausgleichsblech sp. g.",
			en: "levelling plate r. i."
		}
		,A2V00002717663 : {
			de: "Blech kpl. Tuersaeule NF",
			en: "sheet complete door post lf"
		}
		,A2V00002717683 : {
			de: "Blech kpl. Tuersaeule NF",
			en: "sheet complete door post lf"
		}
		,A2V00002717887 : {
			de: "Blech kpl. Tuersaeule HF",
			en: "sheet complete door post hf"
		}
		,A2V00002717929 : {
			de: "Blech kpl. Tuersaeule HF",
			en: "sheet complete door post hf"
		}
		,A2V00002718016 : {
			de: "Portalaeule w.g.",
			en: "post portal as d."
		}
		,A2V00002718017 : {
			de: "Portalsaeule sp. gl.",
			en: "post portal r.i."
		}
		,A2V00002718035 : {
			de: "Badsicherung sp. g.",
			en: "Weld pool backing r. i."
		}
		,A2V00002718100 : {
			de: "Verstaerkungsblech sp.g.",
			en: "reinforcement plate r.i."
		}
		,A2V00002718106 : {
			de: "Badsicherung w.g.",
			en: "weld pool backing as d."
		}
		,A2V00002718127 : {
			de: "Verstaerkungsblech w.g.",
			en: "reinforcement plate a.d."
		}
		,A2V00002718307 : {
			de: "Langtraeger vollst.",
			en: "longit. beam complete"
		}
		,A2V00002718310 : {
			de: "Langtraeger vollst.",
			en: "longit. beam complete"
		}
		,A2V00002737239 : {
			de: "Rinne sp.g.",
			en: "Gutter i.m.i."
		}
		,A2V00002737240 : {
			de: "Rinne w.g.",
			en: "Gutter a.d."
		}
		,A2V00002718592 : {
			de: "Blech",
			en: "plate"
		}
		,A2V00002718679 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00002737361 : {
			de: "Regenrinnenverkleidung vollst. sp.g.",
			en: "Cover of rain gutter compl. i.m.i."
		}
		,A2V00002718680 : {
			de: "Gewindeplatte M20 Toilettenrack",
			en: "thread plate M20 toilet rack"
		}
		,A2V00002737362 : {
			de: "Regenrinnenverkleidung vollst. w.g.",
			en: "Cover of rain gutter compl. a.d."
		}
		,A2V00002718682 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00002718684 : {
			de: "Gewindeplatte M20 Toilettenrack",
			en: "thread plate M20 toilet rack"
		}
		,A2V00002718687 : {
			de: "Gewindeplatte M10 Toilettenrack",
			en: "thread plate M10 toilet rack"
		}
		,A2V00002718689 : {
			de: "Gewindeplatte M20",
			en: "thread plate M20"
		}
		,A2V00002718691 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00002718726 : {
			de: "Rohrdurchfuehrung",
			en: "pipe feedthrough"
		}
		,A2V00002114146 : {
			de: "Anschlagkonsole, gefraest",
			en: "Lateral stop, milled"
		}
		,A2V00002718898 : {
			de: "Verbindungsprofil sp.g.",
			en: "connecting profile r.i."
		}
		,A2V00002718899 : {
			de: "Verbindungsprofil w. g.",
			en: "connecting profile as d."
		}
		,A2V00002737580 : {
			de: "Regenrinnenverkleidung vollst. w.g.",
			en: "Cover of rain gutter compl. a.d."
		}
		,A2V00002737680 : {
			de: "Regenrinnenverkleidung vollst. sp.g.",
			en: "Cover of rain gutter compl. i.m.i."
		}
		,A2V00156019523 : {
			de: "FILTER",
			en: "strainer"
		}
		,A2V00002738858 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002719853 : {
			de: "Langtraeger bearb.",
			en: "longitudinal girder mach."
		}
		,A2V00001868351 : {
			de: "Federbeilage 3mm",
			en: "spacer primary spring 3mm"
		}
		,A2V00002720735 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002739408 : {
			de: "Deckel",
			en: "Cover plate"
		}
		,A2V00002720856 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002720857 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002720858 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002720859 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002720860 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002720861 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002720862 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002720863 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002720864 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002720865 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002720866 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002739993 : {
			de: "Saeule links, vollst.",
			en: "Pillar left, compl."
		}
		,A2V00002740042 : {
			de: "Saeule rechts, vollst.",
			en: "Pillar right, compl."
		}
		,A2V00002720876 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002720906 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002720907 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002720908 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002720909 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002740341 : {
			de: "Klappsitzrahmen 5",
			en: "Tip-up seat frame 5"
		}
		,A2V00002720910 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002720911 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002720912 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002106045 : {
			de: "Passschraube",
			en: "Fit bolt"
		}
		,A2V00002622917 : {
			de: "Gewindebolzen",
			en: "Stud bolt"
		}
		,A2V00002622906 : {
			de: "Gewindebolzen",
			en: "Stud bolt"
		}
		,A2V00002721993 : {
			de: "Ausgleichsplatte sp.gl.",
			en: "Compensating plate r.i."
		}
		,A2V00002721994 : {
			de: "Ausgleichsplatte w.g.",
			en: "Compensating plate as d."
		}
		,A2V00002722066 : {
			de: "Buchse",
			en: "bush"
		}
		,A2V00002722393 : {
			de: "Trittplatte 2, gsw.",
			en: "Kickplate 2, welded"
		}
		,A2V00002722502 : {
			de: "Trittplatte Halter 2,gsw.",
			en: "Kickplate holder 2,wld."
		}
		,A2V00002722504 : {
			de: "Trittplatte Halter 3,gsw.",
			en: "Kickplate holder 3,wld."
		}
		,A2V00002722547 : {
			de: "Halter geschweisst",
			en: "Holder welded"
		}
		,A2V00002722561 : {
			de: "Halter geschweisst",
			en: "Holder welded"
		}
		,A2V00002743641 : {
			de: "Schmiede 6005A T6 15 MIG",
			en: "Forging 6005A T6 15 MIG"
		}
		,A2V00002723286 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002744062 : {
			de: "Maske Seitenteil sp.g.",
			en: "Cab side i.m.i."
		}
		,A2V00002744063 : {
			de: "Maske Seitenteil w.g.",
			en: "Cab side a.d."
		}
		,A2V00002744064 : {
			de: "Lampenbox Deckel w.g.",
			en: "Lightbox lid a.d."
		}
		,A2V00002744065 : {
			de: "Lampenbox sp.g.",
			en: "Lightbox i.m.i."
		}
		,A2V00002744130 : {
			de: "Maske Teil 3 w.g.",
			en: "Cab part 3 a.d."
		}
		,A2V00002744131 : {
			de: "Maske Teil 4 w.g.",
			en: "Cab part 4 a.d."
		}
		,A2V00002744132 : {
			de: "Maske Teil 5",
			en: "Cab part 5"
		}
		,A2V00002744133 : {
			de: "Maske Teil 1",
			en: "Cab part 1"
		}
		,A2V00002744134 : {
			de: "Maske Teil 2 w.g.",
			en: "Cab part 2 a.d."
		}
		,A2V00002744135 : {
			de: "Maske Teil 2 sp.g.",
			en: "Cab part 2 i.m.i."
		}
		,A2V00002724934 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002744138 : {
			de: "Sockel Wischermotor",
			en: "Socket for Wipermotor"
		}
		,A2V00002744141 : {
			de: "Abschirmblech EMV",
			en: "Shielding plate EMC"
		}
		,A2V00002744147 : {
			de: "Maske Teil 3 sp.g.",
			en: "Cab part 3 i.m.i."
		}
		,A2V00002744157 : {
			de: "Rippe Maske sp.g.",
			en: "Rib cab i.m.i."
		}
		,A2V00002744158 : {
			de: "Rippe Maske w.g.",
			en: "Rib cab a.d."
		}
		,A2V00002725096 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002744176 : {
			de: "Lampenbox w.g.",
			en: "Lightbox a.d."
		}
		,A2V00002725097 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002725109 : {
			de: "Winkel",
			en: "angle"
		}
		,A2V00001165401 : {
			de: "Stromabnehmerwippe komplett fÃ¼r FS",
			en: "Pantograph collector head compl. for FS"
		}
		,A2V00370061629 : {
			de: "Radbremszange RZS50K27F135",
			en: "brake caliper/wheel m. RZS50K27F135"
		}
		,A2V00397164654 : {
			de: "Putzbremskonsole, hinten",
			en: "Brush brake bracket, back"
		}
		,A2V00397163986 : {
			de: "Federbeilage 3mm",
			en: "Spring shim 3mm"
		}
		,A2V00002725190 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002744441 : {
			de: "Abdeckplatte",
			en: "Cover plate"
		}
		,A2V00002725788 : {
			de: "Abdeckblech",
			en: "cover plate"
		}
		,A2V00002744900 : {
			de: "Beulsteife w.g.",
			en: "Buckling plate a.d."
		}
		,A2V00002744901 : {
			de: "Beulsteife sp.g.",
			en: "Buckling plate i.m.i."
		}
		,A2V00002744913 : {
			de: "Bodenplatte vollst. WE1",
			en: "Floorplate compl. CE1"
		}
		,A2V00002745249 : {
			de: "Bodenblech vollst. WE2",
			en: "Floor plate compl. CE2"
		}
		,A2V00002726756 : {
			de: "Trittkasten komplett",
			en: "tread box complete"
		}
		,A2V00002745411 : {
			de: "Mittel-LT ZSB. WE1",
			en: "Sole-bar assy. CE1"
		}
		,A2V00002726844 : {
			de: "Treppe komplett",
			en: "stair complete"
		}
		,A2V00002726925 : {
			de: "Abdeckblech vollst.",
			en: "cover sheet compl."
		}
		,A2V00002726956 : {
			de: "Abdeckwinkel vollst.",
			en: "cover angle compl."
		}
		,A2V00002745470 : {
			de: "CCTV Halter",
			en: "CCTV holder"
		}
		,A2V00001841351 : {
			de: "Polrad",
			en: "Pulse Wheel"
		}
		,A2V00002020150 : {
			de: "Empfaenger WAB 1-311",
			en: "Receiver WAB 1-311"
		}
		,A2V00002746600 : {
			de: "Oberarm G Alu SWT ohne Lasche",
			en: "Upper arm G Alu WP without fishplate"
		}
		,A2V00002746625 : {
			de: "Konsole 2 F-Schrank",
			en: "Bracket 2 d-cabinet"
		}
		,A2V00002746652 : {
			de: "Bodenblech vollst. w.g.",
			en: "Floor pl. compl. a.d."
		}
		,A2V00002746654 : {
			de: "Bodenblech vollst. sp.g.",
			en: "Floor pl. compl. i.m.i."
		}
		,A2V00002746675 : {
			de: "Bodenblech vollst. sp.g.",
			en: "Floor pl. compl. i.m.i."
		}
		,A2V00002746686 : {
			de: "Bodenblech vollst. w.g.",
			en: "Floor plate compl. a.d."
		}
		,A2V00002727874 : {
			de: "Abdeckblech sp.g.",
			en: "cover plate rev.im."
		}
		,A2V00002729109 : {
			de: "Rahmen geschweisst",
			en: "frame welded"
		}
		,A2V00002729241 : {
			de: "Abdeckblech w.g.",
			en: "cover plate a.d."
		}
		,A2V00002729265 : {
			de: "Abdeckblech sp.g.",
			en: "cover plate rev.im."
		}
		,A2V00002729360 : {
			de: "Abdeckblech",
			en: "cover plate"
		}
		,A2V00002729713 : {
			de: "Schweissbadsicherung sp.g.",
			en: "weld pool backing r.i."
		}
		,A2V00002729868 : {
			de: "Abdeckblech w.g.",
			en: "cover plate a.d."
		}
		,A2V00002747941 : {
			de: "Tuersaeule ZSB w.g.",
			en: "Door pillar assy. a.d."
		}
		,A2V00002747942 : {
			de: "Tuersaeule ZSB sp.g.",
			en: "Door pillar assy. i.m.i."
		}
		,A2V00002729930 : {
			de: "Abdeckblech sp.g.",
			en: "cover plate rev.im."
		}
		,A2V00002729987 : {
			de: "Schweissbadsicherung w.g.",
			en: "weld pool backing as d."
		}
		,A2V00002710398 : {
			de: "Huelse 35x17x10",
			en: "sleeve 35x17x10"
		}
		,A2V00002730552 : {
			de: "Abdeckblech w.g.",
			en: "cover plate a.d."
		}
		,A2V00002730609 : {
			de: "KK-Ende vollst. 2",
			en: "SC end compl. 2"
		}
		,A2V00002730670 : {
			de: "Abdeckblech sp.g.",
			en: "cover plate rev.im."
		}
		,A2V00002730903 : {
			de: "Abdeckblech vollst.",
			en: "cover sheet compl."
		}
		,A2V00002731110 : {
			de: "Langtraeger vollst. , Niederflur",
			en: "longit. beam complete, low floor"
		}
		,A2V00002748744 : {
			de: "Grundrahmen SSS RAL3000",
			en: "Base frame SSS RAL3000"
		}
		,A2V00002748874 : {
			de: "Kupplungskasten KKE",
			en: "Coupling-box SCE"
		}
		,A2V00002731293 : {
			de: "U-Profil, Bodenplatte",
			en: "U-profile, floor plate"
		}
		,A2V00002749005 : {
			de: "Haltebuegel",
			en: "Shackle"
		}
		,A2V00002731350 : {
			de: "Querprofil, Bodenplatte",
			en: "cross profil, floor plate"
		}
		,A2V00002765548 : {
			de: "Nischenboden",
			en: "Niche floor"
		}
		,A2V00002731408 : {
			de: "Bodenwinklel",
			en: "Floor cover"
		}
		,A2V00002731409 : {
			de: "Bodenwinklel",
			en: "Floor cover"
		}
		,A2V00002751191 : {
			de: "Ecksaeule ZSB w.g.",
			en: "Corner pillar assy a.d."
		}
		,A2V00002731410 : {
			de: "Bodenwinklel",
			en: "Floor cover"
		}
		,A2V00002751192 : {
			de: "Ecksaeule ZSB sp.g.",
			en: "Corner pillar assy i.m.i."
		}
		,A2V00002731421 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002731489 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002731490 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002731572 : {
			de: "Bodenwinkel",
			en: "Floor cover"
		}
		,A2V00002731573 : {
			de: "Bodenwinkel",
			en: "Floor cover"
		}
		,A2V00002731576 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002731629 : {
			de: "Bodenwinklel",
			en: "Floor cover"
		}
		,A2V00002751942 : {
			de: "Kupplungskasten WE1",
			en: "Coupling Support CE1"
		}
		,A2V00002731739 : {
			de: "Seitenwand 2 WE2, HF vollst.",
			en: "sidewall 2 ce2, hf compl."
		}
		,A2V00002731747 : {
			de: "Seitenwand 2 WE1, HF vollst.",
			en: "sidewall 2 ce1, hf compl."
		}
		,A2V00002751963 : {
			de: "Mittellangtraeger WE2",
			en: "Sole-bare CE2"
		}
		,A2V00002731756 : {
			de: "Seitenwand 1  WE1, vollst.",
			en: "sidewall 1 ce1, hf compl."
		}
		,A2V00110118794 : {
			de: "PROFIL FUER       BODENABSTUETZUNG     .",
			en: "PROFIL FUER       BODENABSTUETZUNG     ."
		}
		,A2V00002751984 : {
			de: "Stirnwand, ZSB w.g.",
			en: "Endwall, assy a.d."
		}
		,A2V00002731776 : {
			de: "Seitenwand 1 WE2,  HF vollst.",
			en: "sidewall 1 ce2, hf compl."
		}
		,A2V00002751985 : {
			de: "Stirnwand, ZSB sp.g.",
			en: "Endwall, assy i.m.i."
		}
		,A2V00002731910 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002731911 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002731912 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002731913 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002731914 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002731915 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002732043 : {
			de: "Bodenwinklel",
			en: "Floor cover"
		}
		,A2V00002732066 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002732067 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002732068 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002732069 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002732070 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002732071 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002732072 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002732073 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002732074 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002732075 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002732108 : {
			de: "Seitenwand 2, NF vollst.",
			en: "sidewall 2, lf   compl."
		}
		,A2V00002732146 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002732147 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002752416 : {
			de: "Langtraeger KKE sp.g.",
			en: "Long. Beam SCE i.m.i."
		}
		,A2V00002752417 : {
			de: "Langtraeger KKE w.g.",
			en: "Long. Beam  SCE a.d."
		}
		,A2V00002732148 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002732150 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002732151 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002732152 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002732155 : {
			de: "Seitenwand 1, NF vollst.",
			en: "sidewall 1, lf  compl."
		}
		,A2V00002752477 : {
			de: "Tuersaeule",
			en: "Door pillar"
		}
		,A2V00002752478 : {
			de: "Endsaeule",
			en: "End pillar"
		}
		,A2V00002732160 : {
			de: "Bodenwinkel",
			en: "Floor cover"
		}
		,A2V00002732161 : {
			de: "Bodenwinkel",
			en: "Floor cover"
		}
		,A2V00002732203 : {
			de: "Gewindeplatte",
			en: "threaded plate"
		}
		,A2V00002752788 : {
			de: "Sensorhalter WG EHW",
			en: "Sensor holder AD EHW"
		}
		,A2V00002752789 : {
			de: "Sensorhalter SPG EHW",
			en: "Sensor holder OAD EHW"
		}
		,A2V00002752791 : {
			de: "Aufnahme SWT - EHW",
			en: "Support WP - EHW"
		}
		,A2V00002732234 : {
			de: "Gewindeplatte",
			en: "threaded plate"
		}
		,A2V00002752792 : {
			de: "Aufnahme EHW",
			en: "Support EHW"
		}
		,A2V00201182636 : {
			de: "Aufstieg",
			en: "Step"
		}
		,A2V00002375467 : {
			de: "Frontend lackiert",
			en: "Frontend coated"
		}
		,A2V00002732345 : {
			de: "Schottblech kompl.",
			en: "partition sheet"
		}
		,A2V00002732726 : {
			de: "Buchse",
			en: "bush"
		}
		,A2V00002732753 : {
			de: "Buchse",
			en: "bush"
		}
		,A2V00002753525 : {
			de: "Wippenkastenhalter EHW",
			en: "Collector head box bracket EHW"
		}
		,A2V00002753557 : {
			de: "Langtraeger FSTE sp.g.",
			en: "Long. beam CE i.m.i."
		}
		,A2V00002753558 : {
			de: "Langtraeger FSTE w.g.",
			en: "Long. beam CE a.d."
		}
		,A2V00002753624 : {
			de: "Winkel lang rechts geschw.",
			en: "Angle long right welded"
		}
		,A2V00002753701 : {
			de: "Winkel lang links geschw.",
			en: "Angle long left welded"
		}
		,A2V00002753790 : {
			de: "Lagerhalter",
			en: "Bearing seat"
		}
		,A2V00002753791 : {
			de: "Isolationswinkel EHW",
			en: "Insulating angle EHW"
		}
		,A2V00002753796 : {
			de: "Fenstersaeule w.g.",
			en: "Window pillar a.d."
		}
		,A2V00002753797 : {
			de: "Fenstersaeule sp.g.",
			en: "Window pillar i.m.i."
		}
		,A2V00002733908 : {
			de: "Quertraeger",
			en: "Cross girder"
		}
		,A2V00002733911 : {
			de: "Dach",
			en: "Roof"
		}
		,A2V00002733912 : {
			de: "Dachschottblech w.g. , vollst.",
			en: "Roof plate as d. , complete"
		}
		,A2V00002733914 : {
			de: "Dachschottblech sp.g., vollst.",
			en: "Partition plate roof r.i. , complete"
		}
		,A2V00002733918 : {
			de: "Fraesteil sp.g. , Dachabschluss",
			en: "Milled part r.i. , roof ending"
		}
		,A2V00002733919 : {
			de: "Fraesteil w.g. , Dachabschluss",
			en: "Milled part as d. , roof ending"
		}
		,A2V00002733920 : {
			de: "Eckprofil unten w.g. , Dach",
			en: "Corner profile under as d. , roof"
		}
		,A2V00002733921 : {
			de: "Eckprofil unten sp.g. , Dach",
			en: "Corner profile under r.i. , roof"
		}
		,A2V00002733922 : {
			de: "Eckprofil oben",
			en: "Corner profile top"
		}
		,A2V00002734129 : {
			de: "Beilage 5 EDG",
			en: "Shim 5 EB"
		}
		,A2V00002734130 : {
			de: "Beilage 2 EDG",
			en: "Shim 2 EB"
		}
		,A2V00002734131 : {
			de: "Beilage 10 EDG",
			en: "Shim 10 EB"
		}
		,A2V00002091486 : {
			de: "Schachtfenster, rechts, kpl. ohne Griff",
			en: "Bay window on the right"
		}
		,A2V00002605361 : {
			de: "Verschraubungssatz Kupplung",
			en: "Torque support"
		}
		,A2V00002754431 : {
			de: "Profil f. Seitenwand Innolbwk, mp",
			en: "Profile f. side wall innolbcb, mp"
		}
		,A2V00002754810 : {
			de: "Pruefobjekt S275J2H",
			en: "Test object S275J2H"
		}
		,A2V00002734468 : {
			de: "Beilage 5 JDG",
			en: "Shim 5 JB"
		}
		,A2V00002734469 : {
			de: "Beilage 10 JDG",
			en: "Shim 10 JB"
		}
		,A2V00002734470 : {
			de: "Beilage 2 JDG",
			en: "Shim 2 JB"
		}
		,A2V00002755003 : {
			de: "Pruefobjekt S355J2H",
			en: "Test object S355J2H"
		}
		,A2V00002755241 : {
			de: "Pruefobjekt 1.4301",
			en: "Test object 1.4301"
		}
		,A2V00002755285 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002734697 : {
			de: "Schottblech kompl.",
			en: "partition sheet"
		}
		,A2V00002755297 : {
			de: "Tuersaeulenprofil Innolbwk mp",
			en: "Door post profile innolbcb mp"
		}
		,A2V00002735121 : {
			de: "Schottblech kompl.",
			en: "partition sheet"
		}
		,A2V00002755858 : {
			de: "Halter geschw.",
			en: "Holder welded"
		}
		,A2V00001710649 : {
			de: "Scharblech rechts",
			en: "gathering metal right"
		}
		,A2V00001710374 : {
			de: "Scharblech links",
			en: "gathering metal left"
		}
		,A2V00002414618 : {
			de: "KupplungshÃ¤lfte getriebeseitig komplett",
			en: "Coupling half at gear complete"
		}
		,A2V00201182641 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002756624 : {
			de: "Profil Seitenwand unten",
			en: "Profile sidewall bottom"
		}
		,A2V00002735868 : {
			de: "Platte",
			en: "plate"
		}
		,A2V00002753494 : {
			de: "Kessel und Kesseldeckel",
			en: "Chamber and chamber cap"
		}
		,A2V00002737420 : {
			de: "Crash Modul  KK-Ende",
			en: "crash modul  short coupler end"
		}
		,A2V00002759371 : {
			de: "Lagerdeckel",
			en: "Bearing cover"
		}
		,A2V00002737819 : {
			de: "Crash Modul KK-Ende",
			en: "crash module short coupler end"
		}
		,A2V00002737884 : {
			de: "Einleger",
			en: "fixing plate"
		}
		,A2V00002738149 : {
			de: "Leitungen kpl. im Wagen",
			en: "Conduit cpl. in car"
		}
		,A2V00002738166 : {
			de: "Leitungen kpl. im Wagen",
			en: "Conduit cpl. in car"
		}
		,A2V00002738459 : {
			de: "Seitenwand 2, NF vollst.",
			en: "Sidewall 2, lf compl."
		}
		,A2V00002738531 : {
			de: "Zwischenboden 3, vollst. Toilette",
			en: "intermediate floor 3, compl. toilete"
		}
		,A2V00002761018 : {
			de: "Schweissbadsicherung Innolbwk mp",
			en: "Weld backing innolbcb mp"
		}
		,A2V00002738727 : {
			de: "Seitenwand 1, NF vollst.",
			en: "sidewall 1, lf  compl."
		}
		,A2V00002738627 : {
			de: "SCHRB-ZYL M10X035 D 912 10.9 3.1",
			en: "HEX SOCK.HD CAP SCR M10X035 D 912 10.9 3"
		}
		,A2V00002738634 : {
			de: "SCHRB-SKT M06X016 D 933 10.9-GEOM",
			en: "HEXAGON HEAD SCREW M06X016 D 933 10."
		}
		,A2V00002739058 : {
			de: "Halter",
			en: "holder"
		}
		,A2V00002739281 : {
			de: "Unterflurschott vollst.",
			en: "partition underfloor complete"
		}
		,A2V00002739361 : {
			de: "Seitenwand 1 WE1 HF vollst.",
			en: "sidewall 1 ce1 hf compl."
		}
		,A2V00002739410 : {
			de: "Seitenwand 2 WE1 HF vollst.",
			en: "sidewall 2 ce1 hf compl."
		}
		,A2V00002740064 : {
			de: "Blech sp.g.",
			en: "plate r.i."
		}
		,A2V00002740065 : {
			de: "Blech w.g.",
			en: "plate as d."
		}
		,A2V00002740081 : {
			de: "Tuerverstaerkung w.g.",
			en: "door reinforcment as d."
		}
		,A2V00002740082 : {
			de: "Tuerverstaerkung sp.g.",
			en: "door reinforcment r.i."
		}
		,A2V00002740174 : {
			de: "Verstaerkungsprofil sp.g.",
			en: "reinforcement profile r.i."
		}
		,A2V00002740175 : {
			de: "Verstaerkungsprofil w.g.",
			en: "reinforcement profile as d."
		}
		,A2V00002740349 : {
			de: "Adapter vollst. w.g.",
			en: "adapter complete a.d."
		}
		,A2V00002740350 : {
			de: "Adapter vollst. sp.g.",
			en: "adapter complete r.i."
		}
		,A2V00002740509 : {
			de: "Abdeckung sp. g.",
			en: "faceplate r. i."
		}
		,A2V00002740510 : {
			de: "Abdeckung w. g.",
			en: "faceplate as d."
		}
		,A2V00002740533 : {
			de: "Eckprofil sp.g.",
			en: "corner r.i."
		}
		,A2V00002740534 : {
			de: "Eckprofil w.g.",
			en: "corner as d."
		}
		,A2V00002740538 : {
			de: "Endsaeule vollst. sp.g.",
			en: "end pillar compl. r.i."
		}
		,A2V00002740539 : {
			de: "Endsaeule vollst. w.g.",
			en: "end pillar compl. a.d."
		}
		,A2V00002740548 : {
			de: "Querprofil",
			en: "cross profile"
		}
		,A2V00002762730 : {
			de: "Haltebuegel",
			en: "Support"
		}
		,A2V00002740676 : {
			de: "Dichtblech w. g.",
			en: "saeling plate as d."
		}
		,A2V00002740677 : {
			de: "Dichtblech sg. g.",
			en: "saeling plate r. i."
		}
		,A2V00002741148 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002741149 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002741150 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002741151 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002741228 : {
			de: "Treppe komplett",
			en: "stair complete"
		}
		,A2V00001994250 : {
			de: "Traktionsstromr. RhB, X4 G.1b oh. KA",
			en: "Traction convert. RhB, X4 G.1b wo. CE"
		}
		,A2V00002764111 : {
			de: "Nutenstein M8",
			en: "Sliding block M8"
		}
		,A2V00201306964 : {
			de: "Ultrabuchse",
			en: "Ultrabuchse"
		}
		,A2V00002399833 : {
			de: "2-705 099 / Isolierhorn",
			en: "2-705 099 / Isolierhorn"
		}
		,A2V00002743408 : {
			de: "Knotenblech sp.g.",
			en: "Gusset plate r.i."
		}
		,A2V00002743409 : {
			de: "Knotenblech w.g.",
			en: "Gusset plate as d."
		}
		,A2V00002744020 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welding"
		}
		,A2V00002400301 : {
			de: "Wippenanschlag Ausf.2",
			en: "Collector head guide vers.2"
		}
		,A2V00002583464 : {
			de: "Stromabnehmer 1950/AC - DSB",
			en: "Pantograph 1950/AC - DSB"
		}
		,A2V00002744596 : {
			de: "Futterblech",
			en: "filler"
		}
		,A2V00002744883 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002744936 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002745058 : {
			de: "Futterblech",
			en: "filler"
		}
		,A2V00002745059 : {
			de: "Futterblech",
			en: "filler"
		}
		,A2V00002745060 : {
			de: "Futterblech",
			en: "filler"
		}
		,A2V00002745061 : {
			de: "Futterblech",
			en: "filler"
		}
		,A2V00002745062 : {
			de: "Futterblech",
			en: "filler"
		}
		,A2V00002745063 : {
			de: "Futterblech",
			en: "filler"
		}
		,A2V00002745064 : {
			de: "Futterblech",
			en: "filler"
		}
		,A2V00002745065 : {
			de: "Futterblech",
			en: "filler"
		}
		,A2V00002745112 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002745136 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002745137 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002745138 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002745139 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002745140 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002745141 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002745142 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002745143 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002745145 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002745156 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002745157 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002772236 : {
			de: "Ecksaeule ZSB C sp.g.",
			en: "Corner pillar assy c i.m.i."
		}
		,A2V00002745158 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002745159 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002745160 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002745161 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002745162 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002745237 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002745238 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002745239 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002745240 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00370025809 : {
			de: "Gummilager",
			en: "rubber bearing"
		}
		,A2V00002400516 : {
			de: "ParallelfÃ¼hrungsstange 1-P07.16452",
			en: "ParallelfÃ¼hrungsstange 1-P07.16452"
		}
		,A2V00002745242 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002745263 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002745264 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002745395 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002745488 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002745508 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002747321 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00001747015 : {
			de: "Buchse",
			en: "bush"
		}
		,A2V00002355765 : {
			de: "V J15 Bolzen",
			en: "J15 Bolt"
		}
		,A2V00002052360 : {
			de: "Stuetzrippe SPG",
			en: "Support rib OAD"
		}
		,A2V00002748142 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002749111 : {
			de: "Badsicherung w.g.,Tuersaeule",
			en: "weld pool backing as.d., door post"
		}
		,A2V00002749408 : {
			de: "Badsicherung sp.g.,Tuersaeule",
			en: "weld pool backing i.d., door post"
		}
		,A2V00002749585 : {
			de: "Tuerverstaerung sp.g. , vollst.",
			en: "Door reinforcement i.d. , complete"
		}
		,A2V00002751016 : {
			de: "Tuerverstaerung w.g. , vollst.",
			en: "Door reinforcement as.d. , complete"
		}
		,A2V00110081475 : {
			de: "BLINDFENSTER",
			en: "BLINDFENSTER"
		}
		,A2V00113048158 : {
			de: "BODENWANNENTRAEGER",
			en: "BODENWANNENTRAEGER"
		}
		,A2V00002176323 : {
			de: "Konsole",
			en: "bracket"
		}
		,A2V00002090624 : {
			de: "Rohrhalter",
			en: "pipe holder"
		}
		,A2V00002414788 : {
			de: "AC Freiluft-Oberspannungswandler 3 Sek",
			en: "outdoor voltage transformer AC 3 sec"
		}
		,A2V00002755343 : {
			de: "Abdeckung vollst",
			en: "Cover cpl"
		}
		,A2V00105148634 : {
			de: "ABDECKBLECH",
			en: "ABDECKBLECH"
		}
		,A2V00105148642 : {
			de: "ABDECKBLECH",
			en: "ABDECKBLECH"
		}
		,A2V00002765549 : {
			de: "Luftkanal z.Fahr",
			en: "Air duct to cab"
		}
		,A2V00113148218 : {
			de: "ROLLO, VOLLST.",
			en: "ROLLO, VOLLST."
		}
		,A2V00156023852 : {
			de: "SPULENSATZ M.KERN  KOMPL.",
			en: "coil-kit with core   compl"
		}
		,A2V00156019030 : {
			de: "TRANSFORMATOR",
			en: "transformer"
		}
		,A2V00113033589 : {
			de: "DECKENKLAPPE       VOLLST.",
			en: "DECKENKLAPPE       VOLLST."
		}
		,A2V00002757192 : {
			de: "Antennengeruest RB",
			en: "Antenna rack bs"
		}
		,A2V00002760878 : {
			de: "Zwischenboden 4, vollst.",
			en: "intermediate floor 4, complete"
		}
		,A2V00002762939 : {
			de: "Luftverteilbox",
			en: "Air distribution box"
		}
		,A2V00002590622 : {
			de: "Crashbox geschweisst",
			en: "Crashbox welded"
		}
		,A2V00001946005 : {
			de: "Haltestange geschweisst",
			en: "holding rod welded"
		}
		,A2V00002424543 : {
			de: "Pneumatikeinheit",
			en: "Pneumatic Unit"
		}
		,A2V00002778205 : {
			de: "Transformator",
			en: "Transformer"
		}
		,A2V00001963743 : {
			de: "Gehaeusedeckel kpl. TG01046-02",
			en: "Cover TG01046-02"
		}
		,A2V00001957066 : {
			de: "Oelkuehlerventilator",
			en: "Oil-Cooler-Fan"
		}
		,A2V00207424993 : {
			de: "Einstiegstuer vst.",
			en: "EinstiegstÃ¼r vst."
		}
		,A2V00002400377 : {
			de: "Flachfederwippe  AC 1800",
			en: "Collector head AC 1800"
		}
		,A2V00001809278 : {
			de: "Maschinenraumtuer",
			en: "engine room door"
		}
		,A2V00001809277 : {
			de: "Maschinenraumtuer",
			en: "engine room door"
		}
		,A2V00001874118 : {
			de: "Halter mitte w.g.",
			en: "support, mid as d."
		}
		,A2V00001874122 : {
			de: "Halter hinten w.g.",
			en: "support, back as.d."
		}
		,A2V00001849808 : {
			de: "Bremstafel",
			en: "Brake panel"
		}
		,A2V00002534061 : {
			de: "Drehsaeule rechts, vollst.",
			en: "Locking column right, compl."
		}
		,A2V00002459204 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002550568 : {
			de: "Tafel_4711_V6 E+M",
			en: "Panel_4711_V6 e+m"
		}
		,A2V00002527504 : {
			de: "Wellenscheibenbremse Ersatzeilkit",
			en: "Brake disc caliper/axle m. kit"
		}
		,A2V00001899595 : {
			de: "Generator 2400kW",
			en: "Generator 2400kW"
		}
		,A2V00200400030 : {
			de: "Sechskantschraube",
			en: "hexagon head screw"
		}
		,A2V00001734547 : {
			de: "MR-Seitenwand",
			en: "Side wall"
		}
		,A2V00001822056 : {
			de: "Drehdaempferkonsole",
			en: "rotation dampfer console"
		}
		,A2V00001840592 : {
			de: "Bodenbeblechung FR",
			en: "Metal panelling dc"
		}
		,A2V00001842846 : {
			de: "Bodenbeblechung FR",
			en: "Metall panelling dc"
		}
		,A2V00002621239 : {
			de: "Bedientafel X4 M1120 vst",
			en: "Control panel X4M1120 cpl"
		}
		,A2V00110072875 : {
			de: "PROFIL FUER TRITT-STUFE AUSSEN         .",
			en: "PROFIL FUER TRITT-STUFE AUSSEN         ."
		}
		,A2V00110073030 : {
			de: "PROFIL FUER FESTE SCHUERZE MITTE       .",
			en: "PROFIL FUER FESTE SCHUERZE MITTE       ."
		}
		,A2V00110076412 : {
			de: "PROFIL FUER       LANGTRAEGER",
			en: "PROFIL FUER       LANGTRAEGER"
		}
		,A2V00110072956 : {
			de: "PROFIL FUER TRITT-STUFE INNEN          .",
			en: "PROFIL FUER TRITT-STUFE INNEN          ."
		}
		,A2V00110119198 : {
			de: "PROFIL",
			en: "PROFIL"
		}
		,A2V00110071950 : {
			de: "PROFIL F.SEITENWAND UNTEN              .",
			en: "PROFIL F.SEITENWAND UNTEN              ."
		}
		,A2V00110040205 : {
			de: "PROFIL  KABELKANAL HINTEN              .",
			en: "PROFIL  KABELKANAL HINTEN              ."
		}
		,A2V00110071690 : {
			de: "PROFIL FUER       LANGTRAEGER",
			en: "PROFIL FUER       LANGTRAEGER"
		}
		,A2V00110073197 : {
			de: "PROFIL FUER FESTE SCHUERZE UNTEN       .",
			en: "PROFIL FUER FESTE SCHUERZE UNTEN       ."
		}
		,A2V00113001544 : {
			de: "PROFIL                                 .",
			en: "PROFIL                                 ."
		}
		,A2V00002215528 : {
			de: "Platte, Queranschlag",
			en: "Plate, cross catch"
		}
		,A2V00002065392 : {
			de: "Einstiegstuer re.",
			en: "entrance door ri."
		}
		,A2V00002621737 : {
			de: "Balancefenster breit, vollst.",
			en: "Balance window broad, compl."
		}
		,A2V00102070618 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002276161 : {
			de: "6KT-SHR ISO 4017-M16x60-8.8-tzn",
			en: "Hex Head Screw ISO 4017-M16x60-8.8-tzn"
		}
		,A2V00100633690 : {
			de: "Stahlrohr",
			en: "Steel tube"
		}
		,A2V00001904489 : {
			de: "Antennensaeule",
			en: "Antenna column"
		}
		,A2V00001848213 : {
			de: "Deckel f. Siemens16P Geber",
			en: "Cover f. siemens16P sensor"
		}
		,A2V00001942850 : {
			de: "Verbindungslasche f. Rsl",
			en: "Connecting link f. wsb"
		}
		,A2V00001895997 : {
			de: "Radsatzlagergehaeuse rechts fuer WSB",
			en: "axlebox housing right for ECB"
		}
		,A2V00002530343 : {
			de: "Verlaengerung Haltestange geschw.",
			en: "Extension handrail welded"
		}
		,A2V00001894376 : {
			de: "Antennentraegerprofil",
			en: "Antenna profile beam"
		}
		,A2V00001902820 : {
			de: "Deckel f. Faiveley ATESS",
			en: "Cover f. Faiveley ATESS"
		}
		,A2V00001904452 : {
			de: "Antennensaeulenplatte WG",
			en: "Antenna column plate AD"
		}
		,A2V00001897362 : {
			de: "Antennensaeulendeckel",
			en: "Antenna column cover"
		}
		,A2V00001897361 : {
			de: "Antennenaufnahme WG",
			en: "Antenna seating AD"
		}
		,A2V00001894113 : {
			de: "Antennenplatte",
			en: "Antenna plate"
		}
		,A2V00001894111 : {
			de: "Antennenplatte",
			en: "Antenna plate"
		}
		,A2V00001894110 : {
			de: "Profiltraeger",
			en: "Profile beam"
		}
		,A2V00001894109 : {
			de: "Lasche",
			en: "Plate"
		}
		,A2V00001894108 : {
			de: "Blech f. Sandrohrhalter",
			en: "Plate f. sand pipe brack."
		}
		,A2V00001894106 : {
			de: "Befestigungsblech Mitte",
			en: "Center mountig plate"
		}
		,A2V00001940626 : {
			de: "Anschlagleiste",
			en: "Connection latch"
		}
		,A2V00002110105 : {
			de: "Tuerfluegel / Dritteleinstieg rechts",
			en: "Door leaf right"
		}
		,A2V00002110104 : {
			de: "Tuerfluegel / Dritteleinstieg links",
			en: "Door leaf left"
		}
		,A2V00002152165 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002152380 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002617973 : {
			de: "Deckel, kpl",
			en: "Cover, coml"
		}
		,A2V00002617960 : {
			de: "Deckel, kpl",
			en: "Cover, coml"
		}
		,A2V00200423874 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001824175 : {
			de: "Erdungskontakt axial 200A",
			en: "earthing contact axial 200A"
		}
		,A2V00159100112 : {
			de: "PROFIL FUER DACH  ZWISCHEN",
			en: "PROFIL FUER DACH  ZWISCHEN"
		}
		,A2V00110040167 : {
			de: "PROFIL",
			en: "PROFIL"
		}
		,A2V00110071984 : {
			de: "PROFIL F.SEITENWAND FENSTER UNTEN",
			en: "PROFIL F.SEITENWAND FENSTER UNTEN"
		}
		,A2V00110185300 : {
			de: "PROFIL",
			en: "PROFIL"
		}
		,A2V00113001529 : {
			de: "PROFIL F.DACH, AUSSEN",
			en: "PROFIL F.DACH, AUSSEN"
		}
		,A2V00110040256 : {
			de: "STIRNWAND 1",
			en: "STIRNWAND 1"
		}
		,A2V00110040264 : {
			de: "STIRNWAND 2",
			en: "STIRNWAND 2"
		}
		,A2V00110040272 : {
			de: "STIRNWAND 3",
			en: "STIRNWAND 3"
		}
		,A2V00110040280 : {
			de: "PROFIL",
			en: "PROFIL"
		}
		,A2V00110040302 : {
			de: "PROFIL",
			en: "PROFIL"
		}
		,A2V00110040370 : {
			de: "PROFIL FUER DACH  MITTE",
			en: "PROFIL FUER DACH  MITTE"
		}
		,A2V00110071720 : {
			de: "PROFIL F.SEITENWAND OBEN",
			en: "PROFIL F.SEITENWAND OBEN"
		}
		,A2V00110071836 : {
			de: "PROFIL F.SEITENWAND FENSTER UNTEN",
			en: "PROFIL F.SEITENWAND FENSTER UNTEN"
		}
		,A2V00113001515 : {
			de: "PROFIL F.OBERGURT VERSTAERKUNG KOPF.",
			en: "PROFIL F.OBERGURT VERSTAERKUNG KOPF."
		}
		,A2V00002552770 : {
			de: "Aufkletterschutz geschw.",
			en: "Anticlimber welded"
		}
		,A2V00311400147 : {
			de: "STAB 4KT 25-3000M EN10059 S355JO EN10025",
			en: "BAR 4KT 25-3000M EN10059 S355JO EN10025-"
		}
		,A2V00311500625 : {
			de: "FL DIN1017-120x10-S355JO",
			en: "FL DIN1017-120x10-S355JO"
		}
		,A2V00333700226 : {
			de: "PUFFER,RUNDER D20xH16-M6x18,5",
			en: "BUFFER, ROUND D20xH16-M6x18,5"
		}
		,A2V00370003286 : {
			de: "RD DIN668-40-CK45K",
			en: "RD DIN668-40-CK45K"
		}
		,A2V00113011739 : {
			de: "PROFIL FUER FESTE  SCHUERZE OBEN       .",
			en: "PROFIL FUER FESTE  SCHUERZE OBEN       ."
		}
		,A2V00370013494 : {
			de: "FL DIN1017-40x8-S235JRG2",
			en: "FL DIN1017-40x8-S235JRG2"
		}
		,A2V00370017226 : {
			de: "L EN10056-1-30x30x3-S355JO",
			en: "L EN10056-1-30x30x3-S355JO"
		}
		,A2V00370020577 : {
			de: "FL DIN1017-20x10-S355JO",
			en: "FL DIN1017-20x10-S355JO"
		}
		,A2V00370020768 : {
			de: "FL DIN1017-40x5-S355JO",
			en: "FL DIN1017-40x5-S355JO"
		}
		,A2V00370020898 : {
			de: "FL DIN1017-50x10-S355JO",
			en: "FL DIN1017-50x10-S355JO"
		}
		,A2V00370021463 : {
			de: "STAB FL 20X5-6000M EN10058 S355J0",
			en: "BAR FL FL 20X5-6000M EN10058 S355J0"
		}
		,A2V00370024072 : {
			de: "RO ISO1127-40x2-1.4301-AUSTENITISIERT",
			en: "TUBE ISO1127-40x2-1.4301-AUSTENITIZED"
		}
		,A2V00370025077 : {
			de: "FL DIN1017-30x10-S355JO",
			en: "FL DIN1017-30x10-S355JO"
		}
		,A2V00370026406 : {
			de: "STAB FL 20X5-6000M EN10058 S235JR",
			en: "BAR FL 20X5-6000M EN10058 S235JR"
		}
		,A2V00370034614 : {
			de: "FL DIN1017-60x20-S235JRG2",
			en: "FL DIN1017-60x20-S235JRG2"
		}
		,A2V00370035452 : {
			de: "RO ISO1127-18x1,5-D4T3-S-1.4301+M",
			en: "TUBE ISO1127-18x1,5-D4T3-S-1.4301+M"
		}
		,A2V00370036027 : {
			de: "RO ISO1127-8x1-D4T3-S-1.4301+M",
			en: "TUBE ISO1127-8x1-D4T3-S-1.4301+M"
		}
		,A2V00370036259 : {
			de: "RO ISO1127-10x1-D4T3-S-1.4301+M",
			en: "TUBE ISO1127-10x1-D4T3-S-1.4301+M"
		}
		,A2V00370036404 : {
			de: "RO ISO1127-25x1,5-D4T3-S-1.4301+M",
			en: "TUBE ISO1127-25x1,5-D4T3-S-1.4301+M"
		}
		,A2V00370038523 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00002466260 : {
			de: "Transformator",
			en: "transformator"
		}
		,A2V00370052864 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical damper"
		}
		,A2V00002197452 : {
			de: "Spiegel kpl. rechts El. FH 1",
			en: "mirror cpl. right el. dc 1"
		}
		,A2V00207311030 : {
			de: "Vertikalanschlag",
			en: "Vertikalanschlag"
		}
		,A2V00001870401 : {
			de: "Sandung 1/4 vst",
			en: "sanding 1/4 cpl."
		}
		,A2V00001870402 : {
			de: "Sandung 2/3 vst",
			en: "sanding 2/3 cpl."
		}
		,A2V00002518455 : {
			de: "Profil fuer Boden",
			en: "Profile for ground"
		}
		,A2V00002518506 : {
			de: "Profil fuer Boden mittig",
			en: "Profile for ground concentric"
		}
		,A2V00370059615 : {
			de: "Verduennung 400 (EP)",
			en: "Thinner 400 (EP)"
		}
		,A2V00001942612 : {
			de: "Mitnehmerzapfen",
			en: "driving tag"
		}
		,A2V00397119309 : {
			de: "GLEITPLATTE",
			en: "slide plate"
		}
		,A2V00002147890 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00002140712 : {
			de: "Zug -/ Druckstange Avenio Hoeherlegung",
			en: "Connection rod raising  Avenio"
		}
		,A2V00002420868 : {
			de: "Antennenh. LZB 3.1 mit DB Abnahme",
			en: "antenna holder LZB 3.1 with DB approval"
		}
		,A2V00156014865 : {
			de: "STEUER.GATE-UNIT",
			en: "CONTROL. GATE-UNIT"
		}
		,A2V00397193137 : {
			de: "Lagerbock vollstaendig rechts",
			en: "Bearing complete right"
		}
		,A2V00397193136 : {
			de: "Lagerbock vollstaendig links",
			en: "Bearing complete left"
		}
		,A2V00207473109 : {
			de: "Zugeinrichtung ZE 450-1500",
			en: "Zugeinrichtung ZE 450-1500"
		}
		,A2V00397167934 : {
			de: "RING",
			en: "RING"
		}
		,A2V00207311485 : {
			de: "Schachtfenster links",
			en: "Shaft window"
		}
		,A2V00002067517 : {
			de: "Stromabnehmer SA AC4 k",
			en: "Pantograph SA AC4 k"
		}
		,A2V00397164653 : {
			de: "Putzbremskonsole, vorne",
			en: "Brush brake bracket, in front"
		}
		,A2V00370081057 : {
			de: "BOLZEN",
			en: "BOLT"
		}
		,A2V00001691444 : {
			de: "Zwischenblech",
			en: "intermediate plate"
		}
		,A2V00207318891 : {
			de: "Bodenpaneel links",
			en: "Floor panel  left"
		}
		,A2V00379990695 : {
			de: "Schweissstab 2,4x1000 S Al 5087",
			en: "Welding rod 2,4x1000 A Al 5087"
		}
		,A2V00379990696 : {
			de: "Schweissstab 3,2x1000 S Al 5087",
			en: "Welding rod 3,2x1000 S Al 5087"
		}
		,A2V00379990752 : {
			de: "Schweissdraht 1,2 S Al 5087",
			en: "Welding wire 1,2 S Al 5087"
		}
		,A2V00397121276 : {
			de: "Ausgleichsring",
			en: "shim ring"
		}
		,A2V00397121278 : {
			de: "Buchse",
			en: "sleeve"
		}
		,A2V00002505692 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00000316628 : {
			de: "Traeger",
			en: "Bracket"
		}
		,A2V00397133702 : {
			de: "FLANSCH",
			en: "bead"
		}
		,A2V00002112702 : {
			de: "Senkschraube",
			en: "Hexagon socket countersunk head screw"
		}
		,A2V00397150948 : {
			de: "Druckkappe",
			en: "Retaining cap"
		}
		,A2V00207351945 : {
			de: "Stromwandler GSO 1305 600A/1A",
			en: "Current transformer GSO 1305 600A/1A"
		}
		,A2V00207352626 : {
			de: "LZB-Empfangs-Antenne 36kHz",
			en: "LZB-receiving aerial 36kHz"
		}
		,A2V00002380828 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00207428068 : {
			de: "Flachdichtung",
			en: "flat seal"
		}
		,A2V00397159883 : {
			de: "Gewindebutzen - M12x30x20",
			en: "Threaded bush - M12x30x20"
		}
		,A2V00397181159 : {
			de: "FLANSCH ZU KONSOLE INDUSI",
			en: "FLANGE FOR INDUSI BRACKET"
		}
		,A2V00397183446 : {
			de: "Abhebesicherung",
			en: "lifting guide"
		}
		,A2V00397183456 : {
			de: "SICHERUNGSRING",
			en: "protection ring"
		}
		,A2V00397183515 : {
			de: "Rolle",
			en: "Roll"
		}
		,A2V00002187641 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00113018969 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001380249 : {
			de: "Sechskantmutter",
			en: "hexagon nut"
		}
		,A2V00397189528 : {
			de: "Verschraubung",
			en: "Srewing"
		}
		,A2V00397205087 : {
			de: "Pruefstueck T=3 C=1 W=45Â°",
			en: "Pruefstueck T=3 C=1 W=45Â°"
		}
		,A2V00002460936 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002538787 : {
			de: "Distanzhuelse",
			en: "Distance sleeve"
		}
		,A2V00002609545 : {
			de: "Leiter geschweisst",
			en: "Ladder welded"
		}
		,A2V00397248754 : {
			de: "NOTFANGPLATTE, GETRIEBE",
			en: "EMERGENCY CATCH, GEAR BOX"
		}
		,A2V00397250468 : {
			de: "Halfen-Montageschiene HM28/15-A2-200",
			en: "Halfen-mounting rail HM28/15-A2-200"
		}
		,A2V00397250470 : {
			de: "Halfen-Montageschiene HM28/15-A2-100",
			en: "Halfen-mounting rail HM28/15-A2-100"
		}
		,A2V00397264307 : {
			de: "BEILAGE",
			en: "SHIM"
		}
		,A2V00397266296 : {
			de: "BEILAGE",
			en: "SHIM"
		}
		,A2V00397285445 : {
			de: "BEILAGE 2 MM F. QUERPUFFER",
			en: "SHIM 2 MM F. LATERAL BUFFER"
		}
		,A2V00397285447 : {
			de: "FESTANSCHLAG",
			en: "LATERAL STOP"
		}
		,A2V00570019505 : {
			de: "Kaefigmutter",
			en: "Cage nut"
		}
		,A2V00001908927 : {
			de: "Bugnase GFK vst.",
			en: "front end nose    grp. compl."
		}
		,A2V00397216121 : {
			de: "DECKPLATTE",
			en: "COVERPLATE"
		}
		,A2V00001215578 : {
			de: "Flushwindow",
			en: "Flushwindow"
		}
		,A2V00001355582 : {
			de: "Dichtmanschette",
			en: "Sealing member"
		}
		,A2V00001367406 : {
			de: "Kupplung FT",
			en: "Clutch FT;"
		}
		,A2V00397161103 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00001010337 : {
			de: "Stromabnehmer fÃ¼r ICT (DB)",
			en: "Pantograph for ICT (DB)"
		}
		,A2V00311101277 : {
			de: "RD DIN668-30-CK15K",
			en: "RD DIN668-30-CK15K"
		}
		,A2V00311500119 : {
			de: "FL DIN1017-40x10-S355JO",
			en: "FL DIN1017-40x10-S355JO"
		}
		,A2V00311500656 : {
			de: "FL DIN1017-60x8-S355JO",
			en: "FL DIN1017-60x8-S355JO"
		}
		,A2V00311500722 : {
			de: "Stab FL40X20X6000 EN10058 S355J0",
			en: "Bar FL40X20X6000 EN10058 S355J0"
		}
		,A2V00316700205 : {
			de: "RERO 30X30X3-6000 E235+CR2-S2 EN10305-5",
			en: "RERO 30X30X3-6000 E235+CR2-S2 EN10305-5"
		}
		,A2V00112500597 : {
			de: "Klammer M10",
			en: "clamp M10"
		}
		,A2V00370018328 : {
			de: "STAB FL 80X10-6000M EN10058 S235JR",
			en: "BAR FL 80X10-6000M EN10058 S235JR"
		}
		,A2V00370019954 : {
			de: "FL DIN1017-30x5-S355JO",
			en: "FL DIN1017-30x5-S355JO"
		}
		,A2V00370026396 : {
			de: "FL DIN1017-50x12-S355JO",
			en: "FL DIN1017-50x12-S355JO"
		}
		,A2V00370031051 : {
			de: "HP DIN59411-120X80X8-ST510C       31B",
			en: "HP DIN59411-120X80X8-ST510C       31B"
		}
		,A2V00201306960 : {
			de: "Megi-Ringfeder",
			en: "Megi-Ringfeder"
		}
		,A2V00370039093 : {
			de: "Fuehrungszapfen",
			en: "Guide pin"
		}
		,A2V00370043137 : {
			de: "SEILPAAR F.ABHEBESICHERUNG",
			en: "cable pair for lift-off protection"
		}
		,A2V00397252488 : {
			de: "SCHEIBE",
			en: "DISK"
		}
		,A2V00370052667 : {
			de: "Puffer",
			en: "Buffer"
		}
		,A2V00370052681 : {
			de: "Schutztopf",
			en: "protection pot"
		}
		,A2V00370052863 : {
			de: "Daempfer sekundaer horizontal",
			en: "secondary horizontal damper"
		}
		,A2V00370054468 : {
			de: "Daempfer primaer vertikal",
			en: "damper primary vertical"
		}
		,A2V00001342936 : {
			de: "Eckverstaerkung MM links",
			en: "Corner enhancement"
		}
		,A2V00001343504 : {
			de: "Eckverstaerkung KM FM rechts",
			en: "Corner enhancement,  outside"
		}
		,A2V00001343503 : {
			de: "Eckverstaerkung MM rechts",
			en: "corner enhancement"
		}
		,A2V00001343388 : {
			de: "Eckverbinder aussen rechts 0,25",
			en: "Corner connector outside right 0,25"
		}
		,A2V00370060521 : {
			de: "RD DIN1013-50-S355J2G3",
			en: "RD DIN1013-50-S355J2G3"
		}
		,A2V00370061785 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00002118092 : {
			de: "Getriebe R200N 2LB8125-9CB47",
			en: "Gear R200B 2LB8125-9CC47"
		}
		,A2V00002509159 : {
			de: "Fahrersitz vollst.",
			en: "Driver seat compl."
		}
		,A2V00002604443 : {
			de: "HauptlÃ¼fter",
			en: "Main fan"
		}
		,A2V00001188238 : {
			de: "Sandstreuanlage; kpl. TFW Variante 1",
			en: "sand equipment compl TFW vers. 1"
		}
		,A2V00001220781 : {
			de: "FENSTER HECK RECHTS",
			en: "FLUSHWINDOW REAR RIGHT"
		}
		,A2V00001220782 : {
			de: "HECKSCHEIBE",
			en: "REAR WINDOW"
		}
		,A2V00397133909 : {
			de: "Buegel",
			en: "Clamp"
		}
		,A2V00397133910 : {
			de: "Anschlussplatte",
			en: "Connecting plate"
		}
		,A2V00397133911 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397133912 : {
			de: "Flansch f. Abdeckung",
			en: "Flange f. cover"
		}
		,A2V00397182624 : {
			de: "Obergurt",
			en: "Upper flange"
		}
		,A2V00397182627 : {
			de: "Untergurt",
			en: "Lower flange"
		}
		,A2V00397182632 : {
			de: "Rohr",
			en: "Pipe"
		}
		,A2V00397182633 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397182636 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397182639 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397182652 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397182701 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397182861 : {
			de: "Untergurt aussen",
			en: "Lower flange outside"
		}
		,A2V00397182870 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00397182928 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397182944 : {
			de: "Bolzen",
			en: "Pin"
		}
		,A2V00397183100 : {
			de: "Konsole",
			en: "bracket"
		}
		,A2V00397183101 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397183102 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397183103 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397183109 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397183150 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397183152 : {
			de: "Anschlag",
			en: "Stop"
		}
		,A2V00397183190 : {
			de: "Konsole f. MG-Bremse w.g.",
			en: "Bracket f. MT-brake a.d."
		}
		,A2V00397183216 : {
			de: "Rippe w.g.",
			en: "Rib a.d."
		}
		,A2V00397183259 : {
			de: "Rippe sp.g.",
			en: "Rib o.a.d."
		}
		,A2V00397183330 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00397183332 : {
			de: "Konsole f. Anschluszkasten",
			en: "bracket for terminal box"
		}
		,A2V00397183334 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00397183567 : {
			de: "Rippe fuer Wiege",
			en: "fin for bolster"
		}
		,A2V00397183591 : {
			de: "Buegel",
			en: "Clamp"
		}
		,A2V00397184511 : {
			de: "Querhalter",
			en: "Transverse holder"
		}
		,A2V00397189896 : {
			de: "Obergurt, mittlerer",
			en: "Upper flange, middle"
		}
		,A2V00397190011 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00207351764 : {
			de: "Dachdurchfuehrung AC25kV 630A 3-716-853",
			en: "Roof bushing AC25kV 630A 3-716-853"
		}
		,A2V00002527973 : {
			de: "Durchfuehrung Schlauch",
			en: "Execution Tube"
		}
		,A2V00002528000 : {
			de: "Sandeinfuellklappe aussen",
			en: "External filling flap"
		}
		,A2V00002528073 : {
			de: "Sandbehaelter vormontiert",
			en: "Sand container pre-assembled"
		}
		,A2V00397190012 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397190013 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397190028 : {
			de: "Rippe sp.g.",
			en: "Rib o.a.d."
		}
		,A2V00397190030 : {
			de: "Rippe mitte sp.g.",
			en: "Rib middle o.a.d."
		}
		,A2V00397190048 : {
			de: "Rippe mitte w.g.",
			en: "Rib middle a.d."
		}
		,A2V00397190064 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397190065 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397190067 : {
			de: "Anschlussbolzen",
			en: "Connecting bolt"
		}
		,A2V00397190091 : {
			de: "Steg w.g.",
			en: "Web a.d."
		}
		,A2V00397190092 : {
			de: "Steg sp.g.",
			en: "Web o.a.d."
		}
		,A2V00397190118 : {
			de: "Untergurt",
			en: "Lower flange"
		}
		,A2V00397190137 : {
			de: "Obergurt",
			en: "Upper flange"
		}
		,A2V00397190183 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00397190422 : {
			de: "Federauflage",
			en: "Spring seat"
		}
		,A2V00397190477 : {
			de: "Steg w.g.",
			en: "Web a.d."
		}
		,A2V00397190478 : {
			de: "Steg sp.g.",
			en: "Web o.a.d."
		}
		,A2V00397190594 : {
			de: "Rippe w.g.",
			en: "Rib a.d."
		}
		,A2V00397190633 : {
			de: "Sicherungsblech",
			en: "lock washer"
		}
		,A2V00397190720 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00397190845 : {
			de: "Anhebeoese",
			en: "Lifting eye"
		}
		,A2V00397191072 : {
			de: "Bolzen",
			en: "bolt"
		}
		,A2V00397191225 : {
			de: "Halter fuer MG-Bremse",
			en: "Holder for MT-brake"
		}
		,A2V00397191714 : {
			de: "Deckel",
			en: "Cover"
		}
		,A2V00397193393 : {
			de: "Schottblech",
			en: "Stiffening plate"
		}
		,A2V00397194099 : {
			de: "Rippe f. Federauflage w.g.",
			en: "Rib f. spring seat a.d."
		}
		,A2V00397194100 : {
			de: "Rippe f. Federauflage sp.g.",
			en: "Rib f. spring seat o.a.d."
		}
		,A2V00397195822 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397196983 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397196984 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397196985 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397210078 : {
			de: "Zylinderkonsole w.g.",
			en: "Cylinder bracket a.d."
		}
		,A2V00397210079 : {
			de: "Zylinderkonsole sp.g.",
			en: "Cylinder bracket o.a.d"
		}
		,A2V00397211374 : {
			de: "GLEITPLATTE",
			en: "SLIDING PLATE"
		}
		,A2V00397257277 : {
			de: "Rohr fuer Wiege",
			en: "tube for bolster"
		}
		,A2V00002041071 : {
			de: "Zug -/ Druckstange, S",
			en: "Connection Rod, C"
		}
		,A2V00109373586 : {
			de: "SEITENSCHEIBE,RECHTS",
			en: "Side windows, right"
		}
		,A2V00109380240 : {
			de: "SEITENSCHEIBE,PLAN",
			en: "Side windows, plan"
		}
		,A2V00109415415 : {
			de: "Halter kpl.",
			en: "Attachment"
		}
		,A2V00002575547 : {
			de: "Schuerze geschw. Ersatzteil",
			en: "Shroud welded spare part"
		}
		,A2V00002089692 : {
			de: "Distanzring 16mm",
			en: "distance washer 16mm"
		}
		,A2V00002089691 : {
			de: "Distanzring 26mm",
			en: "distance washer 26mm"
		}
		,A2V00002084264 : {
			de: "Motorbolzen unten",
			en: "lower motorbolt"
		}
		,A2V00002084263 : {
			de: "Motorbolzen oben",
			en: "upper motor bolt"
		}
		,A2V00002090787 : {
			de: "DÃ¼senhalter w.g",
			en: "nozzle holder a.d"
		}
		,A2V00002053980 : {
			de: "Halter Abhebesicherung ft.w.g.",
			en: "holder taking off mp.a.d."
		}
		,A2V00002090788 : {
			de: "DÃ¼senhalter sp.g",
			en: "nozzle holder o.a.d"
		}
		,A2V00002455256 : {
			de: "Hilfsbetriebeumrichter",
			en: "Auxiliary Power Unit"
		}
		,A2V00001210290 : {
			de: "Erdungsauge M12 VA",
			en: "Grounding lug M12 stainless steel"
		}
		,A2V00001804910 : {
			de: "Dieselmotor R84",
			en: "Diesel Engine R84"
		}
		,A2V00001228944 : {
			de: "GleitstÃ¼ck",
			en: "Friction plate"
		}
		,A2V00001257108 : {
			de: "BEILAGERING",
			en: "SHIM"
		}
		,A2V00001257385 : {
			de: "Druckscheibe",
			en: "thrust washer"
		}
		,A2V00001257387 : {
			de: "Druckscheibe",
			en: "thrust washer"
		}
		,A2V00001257731 : {
			de: "BEILAGE",
			en: "WASHER"
		}
		,A2V00001257835 : {
			de: "BEILAGE",
			en: "WASHER"
		}
		,A2V00001257836 : {
			de: "BEILAGE",
			en: "WASHER"
		}
		,A2V00001257837 : {
			de: "BEILAGE",
			en: "WASHER"
		}
		,A2V00001264399 : {
			de: "BEILAGERING",
			en: "WASHER RING"
		}
		,A2V00001264554 : {
			de: "BEILAGERING",
			en: "WASHER RING"
		}
		,A2V00001277768 : {
			de: "Nutmutter",
			en: "groove nut"
		}
		,A2V00001310056 : {
			de: "Querdaempferkonsole fuer Wiege",
			en: "transverse damper bracket for bolster"
		}
		,A2V00001310057 : {
			de: "Obergurt fuer Wiege",
			en: "upper flange for bolster"
		}
		,A2V00001310058 : {
			de: "Untergurt fuer Wiege",
			en: "lower flange for bolster"
		}
		,A2V00001310059 : {
			de: "Steg fuer Wiege",
			en: "web for bolster"
		}
		,A2V00001351970 : {
			de: "Innenrippe fuer Wiege",
			en: "inside fin for bolster"
		}
		,A2V00001354670 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002049101 : {
			de: "Scharblech geschw.",
			en: "gathering sheet welded"
		}
		,A2V00002049099 : {
			de: "Scharblech geschw.",
			en: "gathering sheet welded"
		}
		,A2V00002556244 : {
			de: "Kompensator fuer Fahrmotorluefter",
			en: "Compensator for Traction Motor Fan"
		}
		,A2V00001358761 : {
			de: "Blech fuer Bremskonsole",
			en: "sheet for brake bracket"
		}
		,A2V00001358763 : {
			de: "Rippe fuer Bremskonsole W.G.",
			en: "fin for brake bracket A.D."
		}
		,A2V00001360024 : {
			de: "Rippe fuer Bremsrohr",
			en: "fin for brake tube"
		}
		,A2V00001364478 : {
			de: "Rippe fuer Bremskonsole sp.g.",
			en: "rib for brake bracket o.a.d."
		}
		,A2V00001385050 : {
			de: "Platte Schienenraeumer",
			en: "Plate for track sweeper"
		}
		,A2V00001385053 : {
			de: "Befestigungsblech SPG",
			en: "sheet f.life guard"
		}
		,A2V00001385898 : {
			de: "Abschlussblech fuer Schienenraeumer",
			en: "sheet track sweeper"
		}
		,A2V00001385899 : {
			de: "Rippe fuer Schienenraeumer",
			en: "Rib for track sweeper"
		}
		,A2V00001393764 : {
			de: "Distanzring",
			en: "Spacer"
		}
		,A2V00001393827 : {
			de: "Radsatzlenkerbolzen",
			en: "Bolt f. axle guide"
		}
		,A2V00001394591 : {
			de: "Beilage 10mm",
			en: "Washer 10mm"
		}
		,A2V00001394592 : {
			de: "Beilage 5mm",
			en: "Washer 5mm"
		}
		,A2V00001394831 : {
			de: "Beilage 3mm",
			en: "Washer 3mm"
		}
		,A2V00001395413 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00001395528 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001395531 : {
			de: "Rippe, aussen",
			en: "Rib, outside"
		}
		,A2V00001395532 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001395536 : {
			de: "Rippe_5",
			en: "Rib_5"
		}
		,A2V00001395537 : {
			de: "Steg, aussen",
			en: "Web, outside"
		}
		,A2V00001395538 : {
			de: "Steg, innen",
			en: "Web, inside"
		}
		,A2V00001396500 : {
			de: "Beilage Pur-Elastomer",
			en: "Washer polyurethane elastomer"
		}
		,A2V00001398153 : {
			de: "Halterung fuer Rohr",
			en: "holder for piping"
		}
		,A2V00156024454 : {
			de: "ZSU 20.1",
			en: "ZSU 20.1"
		}
		,A2V00002573422 : {
			de: "Seitenscheibe mit doppeltem Siebdruck",
			en: "side window with double scren"
		}
		,A2V00002052213 : {
			de: "Querpuffer Platte",
			en: "Cross buffer plate"
		}
		,A2V00002052361 : {
			de: "Stuetzrippe WG",
			en: "Support rib AD"
		}
		,A2V00001422643 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001422645 : {
			de: "Steg_4",
			en: "Web_4"
		}
		,A2V00001422738 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00001422747 : {
			de: "Rippe, WG",
			en: "Rib, AD"
		}
		,A2V00001422748 : {
			de: "Steg 2",
			en: "Web 2"
		}
		,A2V00001422752 : {
			de: "Rippe, SPG",
			en: "Web, Oad"
		}
		,A2V00001422956 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001426078 : {
			de: "Bremslasche",
			en: "Brake latch"
		}
		,A2V00001428238 : {
			de: "Versteifung 2, WG",
			en: "Stiffener 2, ad"
		}
		,A2V00001428239 : {
			de: "Platte, SPG",
			en: "Plate, oad"
		}
		,A2V00001428240 : {
			de: "Versteifung 1, SPG",
			en: "Stiffener 1, OAD"
		}
		,A2V00001428242 : {
			de: "Versteifung 1, WG",
			en: "Stiffener 1, ad"
		}
		,A2V00001428243 : {
			de: "Versteifung 2, SPG",
			en: "Stiffener 2, OAD"
		}
		,A2V00001429471 : {
			de: "Rippe, WG",
			en: "Rib, ad"
		}
		,A2V00001429472 : {
			de: "Blech f. Ausdrehanschlag wg",
			en: "Plate f. turn stop ad"
		}
		,A2V00001429474 : {
			de: "Rippe_7, WG",
			en: "Rib_7, AD"
		}
		,A2V00001429477 : {
			de: "Steg_1",
			en: "Web_1"
		}
		,A2V00001431240 : {
			de: "Halter 1 Einstellventil",
			en: "bracket 1 load sensing valve"
		}
		,A2V00001431241 : {
			de: "Halter Federspeicherbremse",
			en: "bracket spring-operated brake"
		}
		,A2V00001431242 : {
			de: "Halter 2 Einstellventil",
			en: "bracket 2 load sensing valve"
		}
		,A2V00001431243 : {
			de: "Halter Notloeseeinrichtung",
			en: "bracket auxiliary release device"
		}
		,A2V00001435017 : {
			de: "Platte, WG",
			en: "Plate, ad"
		}
		,A2V00001435992 : {
			de: "Halter",
			en: "bracket"
		}
		,A2V00001442965 : {
			de: "Bremssattel HYA 26/50 rechts",
			en: "Brake caliper HYA 26/50 right"
		}
		,A2V00001447513 : {
			de: "Rippe_4, WG",
			en: "Rib_4, ad"
		}
		,A2V00001447514 : {
			de: "Rippe_4, SPG",
			en: "Rib_4, oad"
		}
		,A2V00001447515 : {
			de: "Rippe, WG",
			en: "Rib, AD"
		}
		,A2V00001451971 : {
			de: "Rippe, SPG",
			en: "Rib, oad"
		}
		,A2V00001470914 : {
			de: "Rippe_7, SPG",
			en: "Rib_7, OAD"
		}
		,A2V00001471298 : {
			de: "Rippe, SPG",
			en: "Rib, oad"
		}
		,A2V00001658926 : {
			de: "Pufferbeilage 1mm",
			en: "bumper washer 1mm"
		}
		,A2V00001658927 : {
			de: "Pufferbeilage 2mm",
			en: "bumper washer 2mm"
		}
		,A2V00001662275 : {
			de: "Rippe, WG",
			en: "Rib, ad"
		}
		,A2V00001662326 : {
			de: "Rippe, SPG",
			en: "Rippe, oad"
		}
		,A2V00001667895 : {
			de: "Schellenblech",
			en: "bracket for clamp"
		}
		,A2V00001669267 : {
			de: "Zentrierzapfen",
			en: "spigot"
		}
		,A2V00001671114 : {
			de: "Rippe_8",
			en: "Rib_8"
		}
		,A2V00156003106 : {
			de: "ANSCHLUSSSTUECK",
			en: "connecting element"
		}
		,A2V00001680261 : {
			de: "Rippe_9, SPG",
			en: "Rib_9, oad"
		}
		,A2V00001680262 : {
			de: "Rippe_9, WG",
			en: "Rib_9, ad"
		}
		,A2V00001680989 : {
			de: "Huelse",
			en: "bush"
		}
		,A2V00001691440 : {
			de: "Innenschale",
			en: "inner shell"
		}
		,A2V00001691443 : {
			de: "Rippe",
			en: "rib"
		}
		,A2V00001691445 : {
			de: "Zwischenblech",
			en: "intermediate plate"
		}
		,A2V00001691672 : {
			de: "Zwischenblech",
			en: "Intermediate plate"
		}
		,A2V00001699333 : {
			de: "Distanzscheibe",
			en: "spacer"
		}
		,A2V00001699508 : {
			de: "Blech, Befestigung",
			en: "Sheet, fixing"
		}
		,A2V00001710034 : {
			de: "Deckblech- mitte",
			en: "cover sheet middle"
		}
		,A2V00001710372 : {
			de: "Abstuetzung",
			en: "fulcrum"
		}
		,A2V00001710652 : {
			de: "Untergurt",
			en: "bottom flange"
		}
		,A2V00001710863 : {
			de: "Deckblech links",
			en: "cover sheet left"
		}
		,A2V00001711002 : {
			de: "Deckblech rechts",
			en: "cover sheet right"
		}
		,A2V00001711003 : {
			de: "Flansch",
			en: "Flange"
		}
		,A2V00001711005 : {
			de: "Versteifung links",
			en: "stiffening left"
		}
		,A2V00001711107 : {
			de: "Versteifung hinten links",
			en: "stiffening rear left"
		}
		,A2V00001711277 : {
			de: "Versteifung hinten rechts",
			en: "stiffening rear right"
		}
		,A2V00001711279 : {
			de: "Rippe",
			en: "rib"
		}
		,A2V00001711281 : {
			de: "Rippe links",
			en: "rib left"
		}
		,A2V00001711537 : {
			de: "Rippe",
			en: "rib"
		}
		,A2V00001711604 : {
			de: "Rippe",
			en: "rib"
		}
		,A2V00001711688 : {
			de: "Konsole links",
			en: "console left"
		}
		,A2V00001711689 : {
			de: "Konsole rechts",
			en: "console right"
		}
		,A2V00001711691 : {
			de: "Flansch vorn",
			en: "flange front"
		}
		,A2V00001713090 : {
			de: "Rippe rechts",
			en: "rib right"
		}
		,A2V00001713166 : {
			de: "Versteifung rechts",
			en: "stiffening right"
		}
		,A2V00001715528 : {
			de: "Antennenhalter",
			en: "aerial holder"
		}
		,A2V00001715625 : {
			de: "Antennenhalter",
			en: "aerial holder"
		}
		,A2V00001715668 : {
			de: "L- Profil",
			en: "l-profile"
		}
		,A2V00001715670 : {
			de: "Versteifung",
			en: "stiffening"
		}
		,A2V00001715810 : {
			de: "Antennenhalter vorn",
			en: "aerial holder front"
		}
		,A2V00001743729 : {
			de: "Flansch",
			en: "flange"
		}
		,A2V00207472810 : {
			de: "BACC4 Halter",
			en: "BACC4 attachment"
		}
		,A2V00001750418 : {
			de: "Erdungslasche M10",
			en: "Earthing lug M10"
		}
		,A2V00001756329 : {
			de: "Radbremszange RZ44UP10XS135",
			en: "brake caliper/wheel m. RZ44UP10XS135"
		}
		,A2V00001694500 : {
			de: "Lagerschild BS-FT N-Seite",
			en: "Bearing shielden"
		}
		,A2V00001693721 : {
			de: "Kupplung",
			en: "Coupling"
		}
		,A2V00001208787 : {
			de: "Schraube",
			en: "screw for fix block of torsion bar to ca"
		}
		,A2V00001666209 : {
			de: "GehÃ¤use",
			en: "TS IS Materialstamm 0008"
		}
		,A2V00159208997 : {
			de: "Scheibe fuer Glastrennwand",
			en: "Pane for glass partition wall"
		}
		,A2V00001777536 : {
			de: "Stegblech, wg",
			en: "Web plate, as drawn"
		}
		,A2V00001777537 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001777541 : {
			de: "Bremskonsole",
			en: "Brake bracket"
		}
		,A2V00001777546 : {
			de: "Stegblech, spg",
			en: "Web plate, oad"
		}
		,A2V00001777897 : {
			de: "Blech f. Bremskonsole",
			en: "Plate f. brake bracket"
		}
		,A2V00001778761 : {
			de: "Konsole f. Bremszylinder, wg",
			en: "Bracket f. brake cylinder, ad"
		}
		,A2V00001779067 : {
			de: "Konsole f. Bremszylinder, spg",
			en: "Bracket f. brake cylinder, oad"
		}
		,A2V00001779247 : {
			de: "Anschlussblech",
			en: "Connection plate"
		}
		,A2V00159300203 : {
			de: "KLAPPTRITT ENDEINSTIEG VOLLST. W.G.    .",
			en: "flap step ENDentrance cpl. as shown    ."
		}
		,A2V00001787788 : {
			de: "Konsolenblech",
			en: "Bracket plate"
		}
		,A2V00001787789 : {
			de: "Ring f. Motorkonsole",
			en: "Ring f. motor bracket"
		}
		,A2V00001787793 : {
			de: "Konsolenblech",
			en: "Bracket plate"
		}
		,A2V00001791018 : {
			de: "Klotz f. Bremsaufhaengung",
			en: "Block f. brake suspension"
		}
		,A2V00001791019 : {
			de: "Klotz 2 f. Bremsaufhaengung, wg",
			en: "Block f. brake suspension ad"
		}
		,A2V00001791020 : {
			de: "Klotz 2 f. Bremsaufhaengung, spg",
			en: "Block f. brake suspension oad"
		}
		,A2V00001793240 : {
			de: "Konsole f. Laengsmitnahme",
			en: "Bracket f. traction link"
		}
		,A2V00001795624 : {
			de: "Halter f. Notloesezug",
			en: "Holder f. mech. release"
		}
		,A2V00001795682 : {
			de: "Halter f. Notloesezug 1",
			en: "Holder f. mech. release 1"
		}
		,A2V00001798808 : {
			de: "Beilage 1mm",
			en: "Shim 1mm"
		}
		,A2V00001798809 : {
			de: "Beilage 1,5mm",
			en: "Shim 1,5mm"
		}
		,A2V00001799048 : {
			de: "Blech f. Daempferauflage",
			en: "Plate f. damper support"
		}
		,A2V00002114763 : {
			de: "Deckel",
			en: "Cover"
		}
		,A2V00001802364 : {
			de: "Leitungshalter",
			en: "Bow for wiring-clips"
		}
		,A2V00001873546 : {
			de: "Federschrauben",
			en: "Spring screws"
		}
		,A2V00001802444 : {
			de: "U-Buegel",
			en: "U-Bow"
		}
		,A2V00001807315 : {
			de: "Buegel, unterer",
			en: "holder, down"
		}
		,A2V00001807331 : {
			de: "Tragarm 1",
			en: "supporting arm 1"
		}
		,A2V00001807356 : {
			de: "Buegel, oberer",
			en: "holder, top"
		}
		,A2V00002723149 : {
			de: "Schalldaemmplatte",
			en: "Acoustic panel"
		}
		,A2V00002723146 : {
			de: "Z-Blech WG",
			en: "Z-sheet AD"
		}
		,A2V00002723147 : {
			de: "Z-Blech SPG",
			en: "Z-Sheet OAD"
		}
		,A2V00002723148 : {
			de: "Deckel",
			en: "Cover"
		}
		,A2V00002723150 : {
			de: "Schalldaemmplatte",
			en: "Acoustic panel"
		}
		,A2V00002723152 : {
			de: "Schalldaemmplatte",
			en: "Acoustic panel"
		}
		,A2V00002723153 : {
			de: "Schalldaemmplatte",
			en: "Acoustic panel"
		}
		,A2V00002723151 : {
			de: "Schalldaemmplatte",
			en: "Acoustic panel"
		}
		,A2V00002723112 : {
			de: "Schalldaemmplatte",
			en: "Acoustic panel"
		}
		,A2V00002683510 : {
			de: "Oberarm Alu, einarmig SWT/RAL7012",
			en: "Upper arm Alu, single-armed WP/RAL7012"
		}
		,A2V00002723110 : {
			de: "Schalldaemmplatte",
			en: "Acoustic panel"
		}
		,A2V00002723113 : {
			de: "Seitliche Schalldaemmplatte WG",
			en: "Acoustic panel AD"
		}
		,A2V00002723115 : {
			de: "Seitliche Schalldaemmplatte SPG",
			en: "Acoustic panel OAD"
		}
		,A2V00002723154 : {
			de: "Schalldaemmplatte SWT",
			en: "Acoustic panel WP"
		}
		,A2V00002723155 : {
			de: "Schalldaemmplatte SWT",
			en: "Acoustic panel WP"
		}
		,A2V00002723218 : {
			de: "Schalldaemmplatte SWT",
			en: "Acoustic panel WP"
		}
		,A2V00002723219 : {
			de: "Schalldaemmplatte SWT",
			en: "Acoustic panel WP"
		}
		,A2V00002723220 : {
			de: "Schalldaemmplatte SWT",
			en: "Acoustic panel WP"
		}
		,A2V00002723221 : {
			de: "Schalldaemmplatte SWT",
			en: "Acoustic panel WP"
		}
		,A2V00002723222 : {
			de: "Schalldaemmplatte SWT",
			en: "Acoustic panel WP"
		}
		,A2V00002723224 : {
			de: "Wanne SWT",
			en: "Tub WP"
		}
		,A2V00002667116 : {
			de: "Schnellsenkventil anflanschbar",
			en: "Fast-lowering valve attachable"
		}
		,A2V00002676231 : {
			de: "Steuerblock PV-A-2655 5A ET",
			en: "Control block PV-A-2655 5A ET"
		}
		,A2V00001825063 : {
			de: "Schraubendruckfeder prim.",
			en: "Helical compression spring prim."
		}
		,A2V00001825087 : {
			de: "Schraubendruckfeder sek.",
			en: "Helical compression spring"
		}
		,A2V00001836098 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00001849661 : {
			de: "RS-Lagerdeckel vorne",
			en: "wheelset bearing cover front"
		}
		,A2V00001857996 : {
			de: "Knochenbuchse Motorlager",
			en: "layer spring engine bearer"
		}
		,A2V00001858554 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00001861710 : {
			de: "Blech Fahrmotor",
			en: "Sheet Traction motor"
		}
		,A2V00001862360 : {
			de: "Schraubendruckfeder sek.",
			en: "Helical compression spring"
		}
		,A2V00001873203 : {
			de: "Primaerfederzentrierung",
			en: "Primary Spring Centering"
		}
		,A2V00001873271 : {
			de: "Bremstraegerrohr LDG",
			en: "Pipe for Brakeholder TB"
		}
		,A2V00001875328 : {
			de: "Konsolenblech w.g.",
			en: "bracket plate a.d."
		}
		,A2V00001875329 : {
			de: "Konsolenblech sp.g.",
			en: "bracket plate o.a.d."
		}
		,A2V00001875330 : {
			de: "Rippe w.g.",
			en: "rib a.d."
		}
		,A2V00001875331 : {
			de: "Rippe sp.g.",
			en: "rib o.a.d."
		}
		,A2V00001875346 : {
			de: "Anschlagblech",
			en: "stopper sheet"
		}
		,A2V00001878224 : {
			de: "Druckkappe",
			en: "end cap"
		}
		,A2V00001894107 : {
			de: "Deckel Antennenprofil",
			en: "Cap antenna profile"
		}
		,A2V00001894112 : {
			de: "Aufnahmeversteifung",
			en: "Adapter inforcement"
		}
		,A2V00001896001 : {
			de: "Radsatzlagergehaeuse links fuer WSB",
			en: "axle box housing left for ECB"
		}
		,A2V00001897180 : {
			de: "Spritzschutzblech Zuschnitt",
			en: "metal sheet splash guard cutting"
		}
		,A2V00001897360 : {
			de: "Antennenaufnahme SPG",
			en: "Antenna seating OAD"
		}
		,A2V00001898328 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00001904451 : {
			de: "Antennensaeulenplatte SPG",
			en: "Antenna column plate OAD"
		}
		,A2V00001904674 : {
			de: "Schraubensicherung",
			en: "Screw locking device"
		}
		,A2V00001908525 : {
			de: "Blech f. Ausdrehanschlag  spg",
			en: "Plate f. turn stop oad"
		}
		,A2V00001911485 : {
			de: "Buchse",
			en: "Sleeve"
		}
		,A2V00002264115 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002726787 : {
			de: "Konsole Hauptanschluss",
			en: "Bracket main connector"
		}
		,A2V00002726788 : {
			de: "Steg",
			en: "Support"
		}
		,A2V00002706428 : {
			de: "Anschlusskonsole SWT",
			en: "Connection bracket wp"
		}
		,A2V00113008969 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002598861 : {
			de: "Lagerhuelse",
			en: "Bearing sleeve"
		}
		,A2V00002599008 : {
			de: "Befestigungsblech Feder",
			en: "Spring mounting plate"
		}
		,A2V00002709988 : {
			de: "Knie Unterarm SWT",
			en: "knee lower arm WP"
		}
		,A2V00002689509 : {
			de: "Grundrahmen HN, RAL7012",
			en: "Base frame HN, RAL7012"
		}
		,A2V00002688492 : {
			de: "Gewindebolzen L305",
			en: "Threaded bolt L305"
		}
		,A2V00002694126 : {
			de: "Kurvenscheibe Geometrie N",
			en: "Cam disc geometry N"
		}
		,A2V00002699235 : {
			de: "Zwischenflansch Basis",
			en: "Fllange base"
		}
		,A2V00002699631 : {
			de: "Lagerwelle Basis isolierter UA",
			en: "Bearing shaft base"
		}
		,A2V00002699633 : {
			de: "Abstandshuelse",
			en: "Spacer"
		}
		,A2V00001926174 : {
			de: "Schraubendruckfeder prim.",
			en: "Helical compression spring prim."
		}
		,A2V00001926175 : {
			de: "Schraubendruckfeder sek.",
			en: "Helical compression spring sec."
		}
		,A2V00001929518 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001933981 : {
			de: "Schellenhalter",
			en: "handcuff bracket"
		}
		,A2V00001936159 : {
			de: "Erdungsbutzen",
			en: "Earthing connection"
		}
		,A2V00001936544 : {
			de: "Kastenkulisse EW WE1 w.g.",
			en: "Motion link EC CE1 a.d."
		}
		,A2V00001936545 : {
			de: "Kastenkulisse EW WE1 spg",
			en: "Motion link EC CE1 o.a.d."
		}
		,A2V00001936723 : {
			de: "Kastenkulisse sp.g.",
			en: "Motion link o.a.d."
		}
		,A2V00001936724 : {
			de: "Kastenkulisse w.g.",
			en: "Motion link a.d."
		}
		,A2V00001941203 : {
			de: "Verdrehsicherung 1",
			en: "Locking device 1"
		}
		,A2V00001941298 : {
			de: "Verdrehsicherung 2",
			en: "Locking device 2"
		}
		,A2V00001947274 : {
			de: "SKS Halter",
			en: "SKS bracket"
		}
		,A2V00001947796 : {
			de: "SKS Halter",
			en: "SKS bracket"
		}
		,A2V00001949697 : {
			de: "Querrohr Antenne",
			en: "Crosspipe antenna"
		}
		,A2V00002424857 : {
			de: "Halter 1",
			en: "Bracket 1"
		}
		,A2V00002361634 : {
			de: "Langtraeger KK-E voll-li",
			en: "longit. girder SC-E"
		}
		,A2V00001954555 : {
			de: "Rippe Querrohr",
			en: "rib crosspipe"
		}
		,A2V00001954643 : {
			de: "Halter Schienenraeumer",
			en: "holder track sweeper"
		}
		,A2V00397247755 : {
			de: "HEBEL FUER LUFTFEDERVENTIL",
			en: "Lever for airspring valve"
		}
		,A2V00001955104 : {
			de: "Bolzen",
			en: "bolt"
		}
		,A2V00001955107 : {
			de: "Federteller oben",
			en: "upper spring cap"
		}
		,A2V00001956194 : {
			de: "V Bolzen Ã˜20h9x50x45",
			en: "J Bolt Ã˜20h9x50x45"
		}
		,A2V00001959238 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screwÂ "
		}
		,A2V00001960360 : {
			de: "Rolle f. Querspielbegr.",
			en: "Roller for lateral stop"
		}
		,A2V00001960577 : {
			de: "Scheibe f. Querspielbegr.",
			en: "Wascher for lateral stop"
		}
		,A2V00001960647 : {
			de: "Bolzen f. Querspielbegr.",
			en: "Bolt for lateral stop"
		}
		,A2V00001961182 : {
			de: "Hohlbuchse",
			en: "spheribloc"
		}
		,A2V00001177668 : {
			de: "GetriebegehÃ¤use fÃ¼r E152",
			en: "Gear housing for E152"
		}
		,A2V00001961183 : {
			de: "Hohlbuchse",
			en: "spheribloc"
		}
		,A2V00001961420 : {
			de: "V MO Schluessel Kupplungsmontage",
			en: "J AS wrench clutch fitting"
		}
		,A2V00001961448 : {
			de: "V MO Spez. Aufsteck-Ringschluessel SW50",
			en: "J AS special end fitting WS 50"
		}
		,A2V00001965314 : {
			de: "Beilage 1",
			en: "shim 1"
		}
		,A2V00001967076 : {
			de: "Rippe Querrohr",
			en: "rib crosspipe"
		}
		,A2V00397164684 : {
			de: "Anschlag",
			en: "Limit stop"
		}
		,A2V00397247692 : {
			de: "SCHELLE",
			en: "CLAMP"
		}
		,A2V00001979416 : {
			de: "Abdeckplatte GS",
			en: "Protection plate SP"
		}
		,A2V00001979417 : {
			de: "Abdeckplatte HMH-Geber",
			en: "Protection plate HMH sensor"
		}
		,A2V00001989226 : {
			de: "Abhebesicherung",
			en: "lift lock"
		}
		,A2V00001991542 : {
			de: "Halter F V2 E2 40",
			en: "Holder F V2 E2 40"
		}
		,A2V00001991548 : {
			de: "Halter F V1 E6 60",
			en: "Holder F V1 E6 60"
		}
		,A2V00001991929 : {
			de: "Halter F V2 D2 50",
			en: "Holder F V2 D2 50"
		}
		,A2V00001992941 : {
			de: "SCHUTZTOPFFLANSCH Zuschnitt",
			en: "protection pot flange cutting"
		}
		,A2V00001993663 : {
			de: "Rohr HFCHS",
			en: "Tube HFCHS"
		}
		,A2V00001994377 : {
			de: "Beilagscheibe",
			en: "washer"
		}
		,A2V00001998491 : {
			de: "Halter",
			en: "bracket"
		}
		,A2V00002004217 : {
			de: "Fuehrung",
			en: "guide"
		}
		,A2V00002004219 : {
			de: "Achse",
			en: "axis"
		}
		,A2V00002004221 : {
			de: "Spurkranzrad 114",
			en: "wheel"
		}
		,A2V00002034184 : {
			de: "Hakenblech WG.",
			en: "hook sheet a.d."
		}
		,A2V00002034185 : {
			de: "Hakenblech SP.G.",
			en: "hook sheet o.a.d."
		}
		,A2V00002034410 : {
			de: "Lasttraverse",
			en: "load traverse"
		}
		,A2V00002038273 : {
			de: "Wanklagerbock Oberteil",
			en: "Anti roll bar upper bearing housing"
		}
		,A2V00002038275 : {
			de: "Wanklagerbock Unterteil",
			en: "Anti roll bar lower bearing housing"
		}
		,A2V00002039954 : {
			de: "Halter ZS",
			en: "Holder zs"
		}
		,A2V00001977389 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002042703 : {
			de: "Fahrmotor",
			en: "Traction motor"
		}
		,A2V00002051187 : {
			de: "Joch, lackiert",
			en: "Yoke, painted"
		}
		,A2V00002051191 : {
			de: "Anlenkstange lackiert",
			en: "Linkage rod painted"
		}
		,A2V00002051291 : {
			de: "Schraubendruckfeder prim. 1",
			en: "Helical compression spring prim. 1"
		}
		,A2V00002704349 : {
			de: "Nutenstein 1xM24",
			en: "slot nut 1xM24"
		}
		,A2V00002704347 : {
			de: "Nutenstein 1xM24",
			en: "thread block 1xM24"
		}
		,A2V00002715648 : {
			de: "Nutenstein 2xM27",
			en: "thread block 2xM27"
		}
		,A2V00002057068 : {
			de: "Untergurt Langtraeger",
			en: "Lower flange longitudinal beam"
		}
		,A2V00002057070 : {
			de: "Rundbolzen",
			en: "Bolt"
		}
		,A2V00002057442 : {
			de: "Gleitblech",
			en: "Sliding Plate"
		}
		,A2V00002058067 : {
			de: "Bolzen Ã˜25h9x120x112",
			en: "bolt Ã˜25h9x120x112"
		}
		,A2V00002063021 : {
			de: "Obergurt Langtraeger",
			en: "Upper flange longitudinal beam"
		}
		,A2V00002063098 : {
			de: "Platte,  Roh",
			en: "Plate,  row"
		}
		,A2V00002063645 : {
			de: "Stirnplatte WG",
			en: "Front plate AD"
		}
		,A2V00002063724 : {
			de: "Blech 2",
			en: "Sheet 2"
		}
		,A2V00002064079 : {
			de: "Schienenraeumerblech WG",
			en: "Rail guard sheet AD"
		}
		,A2V00002064310 : {
			de: "Blech 3",
			en: "Sheet 3"
		}
		,A2V00002064431 : {
			de: "Untergurt Quertraeger",
			en: "Lower flange lateral beam"
		}
		,A2V00002064658 : {
			de: "Luftfederzentrierung, LDG",
			en: "Air spring centering device, TBF"
		}
		,A2V00002190691 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002076568 : {
			de: "Halter, Roh",
			en: "Holder, raw"
		}
		,A2V00002076769 : {
			de: "Luftfederrohr Roh",
			en: "Air spring tube raw"
		}
		,A2V00001279661 : {
			de: "Gelenk unten      vollst. Doppelgelenk",
			en: "Hinge below complete double hinge"
		}
		,A2V00001881423 : {
			de: "Fuehrerstandstuerblatt, komplett, rechts",
			en: "Driver Cab Door, complet, right"
		}
		,A2V00001881424 : {
			de: "Fuehrerstandstuerblatt, komplett, links",
			en: "Driver Cab Door, complet, left"
		}
		,A2V00002077589 : {
			de: "Sechskantmutter ISO 7042 M10 10",
			en: "Hexagon nut ISO 7042 M10 10"
		}
		,A2V00002084856 : {
			de: "Notfangblech",
			en: "Emergencycatcher plate"
		}
		,A2V00002085189 : {
			de: "Rundmutter M16",
			en: "round nut M16"
		}
		,A2V00002085253 : {
			de: "Verbindungslasche",
			en: "mounting link"
		}
		,A2V00002085748 : {
			de: "Halter 10",
			en: "Holder 10"
		}
		,A2V00002085750 : {
			de: "Halter 12",
			en: "Holder 12"
		}
		,A2V00002085754 : {
			de: "Halter 13",
			en: "Holder 13"
		}
		,A2V00002086561 : {
			de: "Halter 1 wg",
			en: "Holder 1 ad"
		}
		,A2V00002088259 : {
			de: "Halter 7 geschw",
			en: "Holder 7 welded"
		}
		,A2V00002089267 : {
			de: "Lasche",
			en: "Hanger"
		}
		,A2V00002089268 : {
			de: "Federblatt",
			en: "Flat spring"
		}
		,A2V00002089919 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002090275 : {
			de: "Queranschlag",
			en: "Cross stop"
		}
		,A2V00002090645 : {
			de: "Schieneraeumer w.g.",
			en: "rail  guard a.d."
		}
		,A2V00002090666 : {
			de: "Radschutzblech",
			en: "Wheel guard sheet"
		}
		,A2V00002091076 : {
			de: "Halteblech",
			en: "bracket"
		}
		,A2V00002091238 : {
			de: "Kabelblech",
			en: "cable sheet"
		}
		,A2V00002091239 : {
			de: "Kabelblech sp",
			en: "cable sheet mi"
		}
		,A2V00002093072 : {
			de: "Mg Kabelhalter",
			en: "em cable bracket"
		}
		,A2V00002093419 : {
			de: "Schellenhalter sp.g.",
			en: "clamp holder o.a.d."
		}
		,A2V00002094190 : {
			de: "Schellenhalter w.g.",
			en: "clamp holder a.d."
		}
		,A2V00002094298 : {
			de: "Kupplungshalter",
			en: "coupling bracket"
		}
		,A2V00002095369 : {
			de: "Buchse Wankstab",
			en: "bushing rolling rod"
		}
		,A2V00002095389 : {
			de: "Bolzen Sekundaerfeder",
			en: "Bolt secondary spring"
		}
		,A2V00002101283 : {
			de: "Mitnehmerscheibe",
			en: "Carrier plate"
		}
		,A2V00002103269 : {
			de: "Daempfungsring",
			en: "Damping ring"
		}
		,A2V00002107046 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002107047 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002107048 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002107049 : {
			de: "Blech",
			en: "Plate"
		}
		,A2V00002108288 : {
			de: "Halter spg",
			en: "Holder oad"
		}
		,A2V00002108289 : {
			de: "Halter wg",
			en: "Holder ad"
		}
		,A2V00002108556 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002109989 : {
			de: "Druckstueck",
			en: "Pressure pad"
		}
		,A2V00002110239 : {
			de: "Federteller oben",
			en: "Spring plate top"
		}
		,A2V00002110544 : {
			de: "Federteller oben",
			en: "Spring plate top"
		}
		,A2V00116918601 : {
			de: "Bugspitze, vollst.",
			en: "front end,complet"
		}
		,A2V00201312584 : {
			de: "Trafooelleitung-Vl",
			en: "Trafooelleitung-Vl"
		}
		,A2V00002117764 : {
			de: "Erdungslasche 1",
			en: "Grounding link 1"
		}
		,A2V00002118355 : {
			de: "Schellenhalter 3",
			en: "Clip collar bracket 3"
		}
		,A2V00002118397 : {
			de: "Schellenhalter 1",
			en: "Clip collar bracket 1"
		}
		,A2V00002119678 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002119765 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002120240 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002120315 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002120473 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002120474 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002120539 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002120540 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002120692 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002121099 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002121185 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002121391 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002121392 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002122847 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002128361 : {
			de: "Erdungslasche geschw. wg",
			en: "Grounding connection welded ad"
		}
		,A2V00002128362 : {
			de: "Erdungslasche geschw. spg",
			en: "Grounding connection welded oad"
		}
		,A2V00002134401 : {
			de: "Beilage Notfang 1mm",
			en: "Shim emergency catch 1mm"
		}
		,A2V00002134961 : {
			de: "Beilage 1mm",
			en: "Shim 1mm"
		}
		,A2V00002135176 : {
			de: "Huelse",
			en: "Bush"
		}
		,A2V00002136026 : {
			de: "Erdungslasche",
			en: "Grounding strip"
		}
		,A2V00200717630 : {
			de: "Belag,Boden-",
			en: "floor covering"
		}
		,A2V00002138600 : {
			de: "Stirnplatte SPG",
			en: "Front plate OAD"
		}
		,A2V00002138624 : {
			de: "Schienenraeumerblech SPG",
			en: "Rail guard sheet OAD"
		}
		,A2V00002141289 : {
			de: "Blech WG",
			en: "Plate AD"
		}
		,A2V00002141290 : {
			de: "Blech SPG",
			en: "Plate OAD"
		}
		,A2V00002141293 : {
			de: "Rippe 5",
			en: "Rib 5"
		}
		,A2V00002141295 : {
			de: "Rippe 3 SPG",
			en: "Rib 3 OAD"
		}
		,A2V00002141321 : {
			de: "Rippe 1",
			en: "Rib 1"
		}
		,A2V00002141336 : {
			de: "Rippe 2",
			en: "Rib 2"
		}
		,A2V00002141347 : {
			de: "Rippe 3",
			en: "Rib 3"
		}
		,A2V00002141349 : {
			de: "Antennenhalter",
			en: "Antenna holder"
		}
		,A2V00002141380 : {
			de: "Rippe Magnetkonsole WG",
			en: "Rib magnet bracket AD"
		}
		,A2V00002141426 : {
			de: "Rippe 3 WG",
			en: "Rib 3 AD"
		}
		,A2V00002141466 : {
			de: "Rippe Magnetkonsole SPG",
			en: "Rib magnet bracket OAD"
		}
		,A2V00002141480 : {
			de: "Rippe 2",
			en: "Rib 2"
		}
		,A2V00002141486 : {
			de: "Rippe 1",
			en: "Rib 1"
		}
		,A2V00002141522 : {
			de: "Rippe 4 WG",
			en: "Rib 4 AD"
		}
		,A2V00002141526 : {
			de: "Rippe Antennenhalter",
			en: "Rib antenna holder"
		}
		,A2V00002147288 : {
			de: "Rohr Stromabnehmer",
			en: "Pipe collector"
		}
		,A2V00002147289 : {
			de: "Rohr Stromabnehmer",
			en: "Pipe collector"
		}
		,A2V00002147765 : {
			de: "Huelse",
			en: "Bush"
		}
		,A2V00002147889 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00002147891 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00002147892 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00002147893 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00002147894 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00002147895 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00002147981 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002148065 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00370038001 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002148646 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002149097 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002149137 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002149654 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002152379 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002152386 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002154564 : {
			de: "Isolierscheibe",
			en: "Insulating disk"
		}
		,A2V00002155189 : {
			de: "Beilage fuer Schienenraeumer",
			en: "Shim for rail guard"
		}
		,A2V00002157240 : {
			de: "Traegerrohr",
			en: "Carrier pipe"
		}
		,A2V00002157338 : {
			de: "Flansch",
			en: "Flange"
		}
		,A2V00002159136 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002159137 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002159184 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002159185 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002159826 : {
			de: "Anlaufblech 2",
			en: "Starting sheet 2"
		}
		,A2V00002161986 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002162302 : {
			de: "Rippe 4 SPG",
			en: "Rib 4 OAD"
		}
		,A2V00002166932 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002166933 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002166934 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002168283 : {
			de: "Kabelhalteblech",
			en: "cable bracket"
		}
		,A2V00002168734 : {
			de: "Anschweissteil Erdung w.g.",
			en: "weld on part earth a.d."
		}
		,A2V00002168735 : {
			de: "Anschweissteil Erdung sp.g.",
			en: "welding on part earth o.a.d."
		}
		,A2V00002168786 : {
			de: "Anschweissteil",
			en: "welding on part"
		}
		,A2V00001251473 : {
			de: "Auflaufhorn geschweisst 1",
			en: "Horn welded 1"
		}
		,A2V00001251474 : {
			de: "Auflaufhorn geschweisst 2",
			en: "Horn welded 2"
		}
		,A2V00001018275 : {
			de: "Fahrmotor 1TB2016-0GB02 CPTM Sao Paulo",
			en: "traction motor 1TB2016-0GB02"
		}
		,A2V00002173046 : {
			de: "RSL Abschlussscheibe ohne Belegung",
			en: "Closure disc without assignment"
		}
		,A2V00002174145 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002176402 : {
			de: "Blech",
			en: "Plate"
		}
		,A2V00002176698 : {
			de: "Adapter M24-M8",
			en: "adapter M24-M8"
		}
		,A2V00002178488 : {
			de: "Adapter",
			en: "adapter"
		}
		,A2V00002190479 : {
			de: "Blech 2; Rohr- und Kabeltraeger",
			en: "Plate 2; pipe- cable support"
		}
		,A2V00002190481 : {
			de: "Konsole spg.; Rohr- Kabeltraeger",
			en: "bracket o. as drawn; pipe- cable support"
		}
		,A2V00002190482 : {
			de: "Konsole wie gez.; Rohr- Kabeltraeger",
			en: "brakcet as drawn; pipe- cable support"
		}
		,A2V00397135834 : {
			de: "Bolzenhalter",
			en: "bolt holder"
		}
		,A2V00370048289 : {
			de: "DU-BUCHSE 13550DU",
			en: "DU-bush 13550DU"
		}
		,A2V00002197964 : {
			de: "Halter Schelle",
			en: "Holder clamp"
		}
		,A2V00002215092 : {
			de: "Gewindeleiste",
			en: "Thread plate"
		}
		,A2V00002221728 : {
			de: "Anschlagplatte",
			en: "Limit plate"
		}
		,A2V00001052152 : {
			de: "Stromabnehmer 15kV SSS87 m. Ãœberwachung",
			en: "Pantograph 15kV SSS87 with control"
		}
		,A2V00002229852 : {
			de: "Schlauchtuelle",
			en: "Hose fitting"
		}
		,A2V00002245849 : {
			de: "Abdeckung kurz",
			en: "cover short"
		}
		,A2V00397150933 : {
			de: "Beilagscheibe 1mm",
			en: "Primary suspension shim 1mm"
		}
		,A2V00002257316 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00002271888 : {
			de: "Klmh.Paar-LKP-G-25-35-PA",
			en: "Clamp-LKP-G-25-35-PA"
		}
		,A2V00002278738 : {
			de: "Wankstuetzenbock Deckel - Roh",
			en: "Anti roll bar bracket cover - Raw"
		}
		,A2V00002278740 : {
			de: "Bremslasche - ROH",
			en: "brake latch - RAW"
		}
		,A2V00002306008 : {
			de: "Versteifungsrippe, SPG",
			en: "Stiffener, OAD"
		}
		,A2V00002306009 : {
			de: "Versteifungsrippe, WG",
			en: "Stiffener AD"
		}
		,A2V00002307288 : {
			de: "Konsole Abhebesicherung",
			en: "Bracket lifting device"
		}
		,A2V00002308500 : {
			de: "Obergurt rechts",
			en: "Upper flange right"
		}
		,A2V00002308501 : {
			de: "Obergurt links",
			en: "Upper chord left"
		}
		,A2V00002309346 : {
			de: "Untergurt",
			en: "Lower chord"
		}
		,A2V00002310082 : {
			de: "Rippe 4 WG",
			en: "Stiffener 4 AD"
		}
		,A2V00002310083 : {
			de: "Rippe 3",
			en: "Stiffener 3"
		}
		,A2V00002310084 : {
			de: "Rippe 5 SPG",
			en: "Stiffener 5 OAD"
		}
		,A2V00002310085 : {
			de: "Rippe 5 WG",
			en: "Stiffener 5 AD"
		}
		,A2V00002310196 : {
			de: "Rippe 1",
			en: "Stiffener 1"
		}
		,A2V00002310197 : {
			de: "Rippe 4 SPG",
			en: "Stiffener 4 OAD"
		}
		,A2V00002310198 : {
			de: "Rippe 2",
			en: "Stiffener 2"
		}
		,A2V00002314069 : {
			de: "Obergurt,Seitenteil WG",
			en: "Upper flange sidepart AD"
		}
		,A2V00002314070 : {
			de: "Obergurt Seitenteil SPG",
			en: "Upper Flange sidepart OAD"
		}
		,A2V00002316318 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002317263 : {
			de: "Rippe 2",
			en: "Stiffener 2"
		}
		,A2V00002317312 : {
			de: "Rippe A1",
			en: "Stiffener A1"
		}
		,A2V00002317315 : {
			de: "Rippe LT 1",
			en: "Stiffener LB 1"
		}
		,A2V00002317346 : {
			de: "Rippe LT 2",
			en: "Stiffener LB 2"
		}
		,A2V00001163385 : {
			de: "Fahr-/Bremsschalter",
			en: "Master controller"
		}
		,A2V00002323097 : {
			de: "Zentrierbolzen",
			en: "Centering bolt"
		}
		,A2V00002324258 : {
			de: "Schienenraeumer",
			en: "life guard"
		}
		,A2V00002325076 : {
			de: "Erdungslasche",
			en: "Groundingplate"
		}
		,A2V00002331056 : {
			de: "Zentrierzapfen",
			en: "Spigot"
		}
		,A2V00002333294 : {
			de: "Primaer Daempferkonsole 2 - Roh",
			en: "Primary damper sup. 2 - raw"
		}
		,A2V00002333356 : {
			de: "Primaer Daempferkonsole 1 - Roh",
			en: "Primary damper support 1 - raw"
		}
		,A2V00002333754 : {
			de: "Schlauchhalter",
			en: "Hosebracket"
		}
		,A2V00002333998 : {
			de: "Halter 2 WG",
			en: "Bracket 2 AD"
		}
		,A2V00002334001 : {
			de: "Halter 2 SPG",
			en: "Bracket 2 OAD"
		}
		,A2V00002334326 : {
			de: "Schottblech - Roh SF6500 Ã–BB",
			en: "Bulkhead plate - raw SF6500 Ã–BB"
		}
		,A2V00002335402 : {
			de: "Halterblech LFST",
			en: "Bracket"
		}
		,A2V00002335403 : {
			de: "Halterblech UEBV",
			en: "Bracket overblow control"
		}
		,A2V00002335405 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002335669 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00002337219 : {
			de: "Steckerblech WG",
			en: "Bracket for plug AD"
		}
		,A2V00002337220 : {
			de: "Steckerblech SPG",
			en: "Bracket for plug OAD"
		}
		,A2V00002338495 : {
			de: "Halter Kabelabgang",
			en: "Bracket cabling"
		}
		,A2V00002338761 : {
			de: "Abgangshalter",
			en: "Bracket cabling end"
		}
		,A2V00002338763 : {
			de: "Halter T-Verteiler",
			en: "Bracket T-distributor"
		}
		,A2V00002338826 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002338829 : {
			de: "Schellenhalter",
			en: "Bracket for clamp"
		}
		,A2V00002339560 : {
			de: "Klmh.Paar-LKP-G-213_35",
			en: "Clamp-LKP-G-213_35"
		}
		,A2V00002339739 : {
			de: "Halter WG",
			en: "Bracket AD"
		}
		,A2V00002339740 : {
			de: "Halter SPG",
			en: "Bracket OAD"
		}
		,A2V00002343046 : {
			de: "Grundplatte",
			en: "Plate"
		}
		,A2V00397161806 : {
			de: "DREHSTABFEDER LACK.",
			en: "torsion bar spring varn."
		}
		,A2V00002343564 : {
			de: "Kopftraegerrohr",
			en: "Pipe for head beam"
		}
		,A2V00002344031 : {
			de: "Halteblech WG",
			en: "Retaining plate AD"
		}
		,A2V00002344153 : {
			de: "Rippe 3",
			en: "Stiffener 3"
		}
		,A2V00002344227 : {
			de: "Rippe 2",
			en: "Stiffener 2"
		}
		,A2V00002344363 : {
			de: "Blech 3",
			en: "plate 3"
		}
		,A2V00002344364 : {
			de: "Rippe 1 WG",
			en: "Stiffener 1 AD"
		}
		,A2V00002344403 : {
			de: "Rippe 4",
			en: "Stiffener 4"
		}
		,A2V00002344450 : {
			de: "Halteblech SPG",
			en: "Retaining plate OAD"
		}
		,A2V00002344456 : {
			de: "Rippe 1 SPG",
			en: "Stiffener 1 OAD"
		}
		,A2V00002346070 : {
			de: "Rippe innen 1",
			en: "Stiffener 1"
		}
		,A2V00002346121 : {
			de: "Versteifungsrippe",
			en: "Stiffener"
		}
		,A2V00002348030 : {
			de: "Halterblech",
			en: "bracket plate"
		}
		,A2V00002358023 : {
			de: "Steg Langtraeger roh",
			en: "Web longitudinal beam raw"
		}
		,A2V00002359836 : {
			de: "Buchse",
			en: "Bush"
		}
		,A2V00113130720 : {
			de: "DECKENKLAPPE      VOLLST.              .",
			en: "DECKENKLAPPE      VOLLST.              ."
		}
		,A2V00002397303 : {
			de: "Stator fuer Motor A5E00150973",
			en: "Stator frame complete for motor"
		}
		,A2V00002390969 : {
			de: "Radsatzlagerdeckel fuer Geber, lackiert",
			en: "WS-Bearing cover for sensor, painted"
		}
		,A2V00002392252 : {
			de: "Druckkappe",
			en: "end cap"
		}
		,A2V00001681258 : {
			de: "ERS 7470117 Crashkoerper geschw.",
			en: "Crash Part Welded"
		}
		,A2V00001287882 : {
			de: "Deflektor Anbau",
			en: "Deflector mount."
		}
		,A2V00002410286 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002411030 : {
			de: "Standard Halter F 120",
			en: "Standard Bracket F 120"
		}
		,A2V00002411197 : {
			de: "Schellenhalter 7",
			en: "Clip collar bracket 7"
		}
		,A2V00002412489 : {
			de: "Rohr-Anschweissteil",
			en: "Pipe-weld on part"
		}
		,A2V00002418187 : {
			de: "Steg QT",
			en: "Web CB"
		}
		,A2V00002418457 : {
			de: "Halter 1",
			en: "Bracket 1"
		}
		,A2V00002418460 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002418889 : {
			de: "Steg Federtopf SPG",
			en: "Web Suspension Pot OAD"
		}
		,A2V00002418890 : {
			de: "Steg Federtopf WG",
			en: "Web Suspension Pot AD"
		}
		,A2V00002418891 : {
			de: "Rippe Querdaempferkonsole",
			en: "Rib Lateral Damper Bracket"
		}
		,A2V00002419149 : {
			de: "Rippe Querpufferkonsole",
			en: "Rib Lat. Buffer Bracket"
		}
		,A2V00002419165 : {
			de: "Platte Ausdrehanschlag",
			en: "Plate Rotation Stop"
		}
		,A2V00002419254 : {
			de: "Rippe Queranschlag",
			en: "Rib Lateral Stop"
		}
		,A2V00002419280 : {
			de: "Drehdaempferkonsole WG",
			en: "Yaw damper bracket AD"
		}
		,A2V00002419281 : {
			de: "Drehdaempferkonsole SPG",
			en: "Yaw damper bracket OAD"
		}
		,A2V00002419348 : {
			de: "Bremsbefestigung WG",
			en: "Brake Support AD"
		}
		,A2V00002419351 : {
			de: "Rippe Ausdrehanschlag",
			en: "Rib Rotation Stop"
		}
		,A2V00002419424 : {
			de: "Rippe Drehd. Kons. WG",
			en: "Rib Yaw Damp. Bracket AD"
		}
		,A2V00002419425 : {
			de: "Rippe Drehd. Kons. SPG",
			en: "Rib Yaw Damp. Bracket OAD"
		}
		,A2V00002421759 : {
			de: "Rippe Luftfeder Auflage",
			en: "Rib Air Suspension Supp."
		}
		,A2V00002424358 : {
			de: "Notfangplatte, Getriebe",
			en: "EMERGENCY CATCH, GEAR BOX"
		}
		,A2V00002424518 : {
			de: "Prim. Daempferkonsole",
			en: "Prim. Damper Bracket"
		}
		,A2V00002424838 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002427382 : {
			de: "Halter 3",
			en: "Bracket 3"
		}
		,A2V00002440405 : {
			de: "Anlenkbolzen",
			en: "Wrist pin"
		}
		,A2V00002440414 : {
			de: "Abstandscheibe",
			en: "Distance plate"
		}
		,A2V00002443881 : {
			de: "Halter 1",
			en: "Holder 1"
		}
		,A2V00002443882 : {
			de: "Halter 2",
			en: "Holder 2"
		}
		,A2V00002445886 : {
			de: "RadsatzfÃ¼hrung Zuschnitt WG / SPG",
			en: "Wheelset steering cutt AD / OAD"
		}
		,A2V00002445887 : {
			de: "Steg quer Zuschnitt WG / SPG",
			en: "Web lateral cutt AD / OAD"
		}
		,A2V00002445888 : {
			de: "Stirnplatte Zuschnitt",
			en: "Head plate cutt"
		}
		,A2V00002451309 : {
			de: "Erdungslasche",
			en: "Earthing strap"
		}
		,A2V00002452272 : {
			de: "Halter 3",
			en: "Holder 3"
		}
		,A2V00002453155 : {
			de: "Erdungskontakt mit Gebermitnahme",
			en: "earthing contact with transmitter attatc"
		}
		,A2V00002458805 : {
			de: "Bremsbefestigung SPG",
			en: "Brake Support OAD"
		}
		,A2V00002516955 : {
			de: "Rippe 3 SPG",
			en: "Rib 3 OAD"
		}
		,A2V00002516958 : {
			de: "Rippe QP",
			en: "Rib CB"
		}
		,A2V00002516976 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002516977 : {
			de: "Rippe QD",
			en: "Rib CD"
		}
		,A2V00002516978 : {
			de: "Rippe 2",
			en: "Rib 2"
		}
		,A2V00002516987 : {
			de: "Rippe 1",
			en: "Rib 1"
		}
		,A2V00002516988 : {
			de: "Rippe 3 WG",
			en: "Rib 3 AD"
		}
		,A2V00002517363 : {
			de: "Schweissbadsicherung",
			en: "Backing strip"
		}
		,A2V00002518142 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00002519310 : {
			de: "Schweissbadsicherung 2",
			en: "Backing strip 2"
		}
		,A2V00002521760 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002522052 : {
			de: "Wiegenende Zuschnitt",
			en: "Bolsterend cutting"
		}
		,A2V00002522053 : {
			de: "Daempferkonsole Zuschnitt",
			en: "Damper bracket cutting"
		}
		,A2V00002522054 : {
			de: "Lenkerkonsole Zuschnitt",
			en: "Linkage bracket AD cutting"
		}
		,A2V00002523690 : {
			de: "Rippe 3",
			en: "Rib 3"
		}
		,A2V00002523720 : {
			de: "Rippe 1 SPG",
			en: "Rib 1 OAD"
		}
		,A2V00002523721 : {
			de: "Daempferkonsole WG",
			en: "Damper bracket AD"
		}
		,A2V00002523727 : {
			de: "Steg 3, LT WG",
			en: "Web 2, Lb AD"
		}
		,A2V00002523728 : {
			de: "Rippe 5",
			en: "Rib 5"
		}
		,A2V00002523743 : {
			de: "Rippe 1 WG",
			en: "Rib 1 a.d"
		}
		,A2V00002523751 : {
			de: "Steg 2, LT SPG",
			en: "Web 2 OAD, LB"
		}
		,A2V00002523756 : {
			de: "Rippe 2",
			en: "Rib 2"
		}
		,A2V00002523763 : {
			de: "Steg 4",
			en: "Web 4"
		}
		,A2V00002523768 : {
			de: "Konsolenhalterung WG",
			en: "Bracket holder AD"
		}
		,A2V00002523786 : {
			de: "Steg 5",
			en: "Web 5"
		}
		,A2V00002523787 : {
			de: "Schweissbadsicherung",
			en: "Backing strip"
		}
		,A2V00002525105 : {
			de: "Leiste MG Bremse",
			en: "Schoulder MG brake"
		}
		,A2V00002525382 : {
			de: "Halter MG Bremse",
			en: "Bracket MG brake"
		}
		,A2V00002525528 : {
			de: "Steg 6",
			en: "Web 6"
		}
		,A2V00002529846 : {
			de: "Grundblech Typenschild",
			en: "Base plate specification"
		}
		,A2V00002529967 : {
			de: "Obergurt aussen Roh",
			en: "Outer upper flange raw"
		}
		,A2V00002533445 : {
			de: "Untergurt BK WG",
			en: "Lower Flange BS AD"
		}
		,A2V00002533458 : {
			de: "Zentrierring",
			en: "Center ring"
		}
		,A2V00002533460 : {
			de: "Anhebeoese",
			en: "Lift Ear"
		}
		,A2V00002533461 : {
			de: "Daempferkonsole SPG",
			en: "Damper Bracket OAD"
		}
		,A2V00002533462 : {
			de: "Steg Bremsk. innen WG",
			en: "Web Brake Supp. ins. AD"
		}
		,A2V00002533463 : {
			de: "Rippe fuer Motor",
			en: "Rib for motor"
		}
		,A2V00002533536 : {
			de: "Steg fuer Getriebe",
			en: "Web for gear"
		}
		,A2V00002533537 : {
			de: "Rippe SPG",
			en: "Rib OAD"
		}
		,A2V00002533538 : {
			de: "Rippe WG",
			en: "Rib AD"
		}
		,A2V00002533539 : {
			de: "Verbindungssteg aussen SPG",
			en: "Connection Web outer OAD"
		}
		,A2V00002533540 : {
			de: "Rippe Motorkonsole innen",
			en: "Stiffener engine bracket"
		}
		,A2V00002533541 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002533542 : {
			de: "Rippe Motorkonsole aussen",
			en: "Outer stiffener engine bracket"
		}
		,A2V00002533544 : {
			de: "Verbindungssteg aussen WG",
			en: "Connection Web outer AD"
		}
		,A2V00002533545 : {
			de: "Steg Bremsk. aussen SPG",
			en: "Web brake Supp. out. OAD"
		}
		,A2V00002533546 : {
			de: "Rippe WG",
			en: "Rib AD"
		}
		,A2V00002533548 : {
			de: "Daempferkonsole WG",
			en: "Damper Bracket AD"
		}
		,A2V00002533549 : {
			de: "Rippe fuer Luftfeder WG",
			en: "Rib for Air Spring AD"
		}
		,A2V00002533550 : {
			de: "Steg Bremsk. aussen WG",
			en: "Web Brake Supp. out. AD"
		}
		,A2V00002533551 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002533553 : {
			de: "Rippe SPG",
			en: "Rip OAD"
		}
		,A2V00002533555 : {
			de: "Rippe fuer Luftfeder SPG",
			en: "Rib for Air Spring OAD"
		}
		,A2V00002533558 : {
			de: "Steg fuer Getriebe",
			en: "Web for gear"
		}
		,A2V00002533559 : {
			de: "Rippe Motorkonsole",
			en: "Stiffener engine bracket"
		}
		,A2V00002533560 : {
			de: "Verbindungssteg innen SPG",
			en: "Connection Web inner OAD"
		}
		,A2V00002533563 : {
			de: "Verbindungssteg innen WG",
			en: "Connect Web inner AD"
		}
		,A2V00002533565 : {
			de: "Steg Bremsk. innen SPG",
			en: "Web Brake Supp. ins. OAD"
		}
		,A2V00002533566 : {
			de: "Abhebesicherung",
			en: "Lift stop"
		}
		,A2V00002533574 : {
			de: "Untergurt BK SPG",
			en: "Lower Flange BS OAD"
		}
		,A2V00002533681 : {
			de: "Daempferkonsole SPG",
			en: "Damper bracket OAD"
		}
		,A2V00002533832 : {
			de: "Steg 3, LT SPG",
			en: "Web 3, Lb OAD"
		}
		,A2V00002534386 : {
			de: "Rippe 1",
			en: "Rib 1"
		}
		,A2V00002535084 : {
			de: "Konsolenhalterung SPG",
			en: "Bracket holder OAD"
		}
		,A2V00002535235 : {
			de: "Versteifung",
			en: "Stiffener"
		}
		,A2V00002535390 : {
			de: "Steg 2, LT WG",
			en: "Web 2 AD, LB"
		}
		,A2V00002535429 : {
			de: "Blech 2",
			en: "Plate 2"
		}
		,A2V00002535452 : {
			de: "Blech 1",
			en: "Plate 1"
		}
		,A2V00002535475 : {
			de: "Rippe 3",
			en: "Rib 3"
		}
		,A2V00002538731 : {
			de: "Luftfederrohr",
			en: "Air spring tube"
		}
		,A2V00002540697 : {
			de: "Rippe QD",
			en: "Rip QD"
		}
		,A2V00002541035 : {
			de: "Flansch wg",
			en: "flange ad"
		}
		,A2V00002543193 : {
			de: "Flanschplatte",
			en: "Flange plate"
		}
		,A2V00002544093 : {
			de: "Laengsmitnahmepuffer Avenio N",
			en: "Longitudinal buffer  Avenio N"
		}
		,A2V00002548565 : {
			de: "FEY-Ring FK6 ISD 110",
			en: "FEY-Ring FK6 ISD 110"
		}
		,A2V00002548760 : {
			de: "Rippe Motorkons. oben",
			en: "Rip motor bracket"
		}
		,A2V00002548761 : {
			de: "Rippe LT Untergurt",
			en: "Rip LT Lower Flange"
		}
		,A2V00002549114 : {
			de: "Rippe quer SPG",
			en: "Rib cross OAD"
		}
		,A2V00002549115 : {
			de: "Rippe quer WG",
			en: "Rib cross AD"
		}
		,A2V00002552356 : {
			de: "Untergurt BK WG",
			en: "Lower flange BS AD"
		}
		,A2V00002552357 : {
			de: "Untergurt BK SPG",
			en: "Lower flange BS OAD"
		}
		,A2V00002553935 : {
			de: "Federteller unten",
			en: "Spring plate bottom"
		}
		,A2V00002554206 : {
			de: "Federteller oben",
			en: "Spring plate top"
		}
		,A2V00002556287 : {
			de: "Unterlegscheibe",
			en: "Shim"
		}
		,A2V00002556850 : {
			de: "Anschweissteil",
			en: "welding part"
		}
		,A2V00002558733 : {
			de: "Halter HAN3",
			en: "Holder HAN3"
		}
		,A2V00002558886 : {
			de: "Blech Kabeltraeger TDG",
			en: "Sheet Cable beam mb."
		}
		,A2V00002559008 : {
			de: "Halter 4 wg",
			en: "Holder 4 ad"
		}
		,A2V00002559055 : {
			de: "Halter 3",
			en: "Holder 3"
		}
		,A2V00002559147 : {
			de: "Erdungslasche wg",
			en: "grounding lug ad"
		}
		,A2V00002559149 : {
			de: "Halter 1",
			en: "Holder 1"
		}
		,A2V00002559150 : {
			de: "Erdungslasche spg",
			en: "grounding lug oad"
		}
		,A2V00002561354 : {
			de: "Bolzen",
			en: "Bolzen"
		}
		,A2V00002562551 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397165788 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397166539 : {
			de: "FUEHRUNGSBUCHSE",
			en: "Bushing"
		}
		,A2V00397165787 : {
			de: "Winkelblech",
			en: "Angle plate"
		}
		,A2V00397249737 : {
			de: "Halter rechts",
			en: "Holder right"
		}
		,A2V00397249734 : {
			de: "Halter links",
			en: "Holder left"
		}
		,A2V00370068552 : {
			de: "KLAPPTISCH- FENSTERSEITE            31B",
			en: "KLAPPTISCH- FENSTERSEITE            31B"
		}
		,A2V00207470553 : {
			de: "Nischenboden",
			en: "niche floor"
		}
		,A2V00002563872 : {
			de: "Halter 4",
			en: "Holder 4"
		}
		,A2V00002567277 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002567281 : {
			de: "Obergurt",
			en: "Upper Flange"
		}
		,A2V00002568048 : {
			de: "Beilage 2mm",
			en: "Shim 2mm"
		}
		,A2V00002568049 : {
			de: "Beilage 1mm",
			en: "Shim 1mm"
		}
		,A2V00002573970 : {
			de: "Luftfederrohr Roh",
			en: "Air spring tube raw"
		}
		,A2V00002573971 : {
			de: "Platte Roh",
			en: "Plate raw"
		}
		,A2V00002574107 : {
			de: "Flanschplatte",
			en: "Flange plate"
		}
		,A2V00002574143 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002574148 : {
			de: "Flansch spg",
			en: "Flange oad"
		}
		,A2V00002574180 : {
			de: "Halter 4 spg",
			en: "Holder 4 oad"
		}
		,A2V00002574784 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00002574792 : {
			de: "Druckring",
			en: "Pressure ring"
		}
		,A2V00002575146 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002575881 : {
			de: "FDUE Diagnosemodul A-Muster",
			en: "BDM diagnostic unit A sample"
		}
		,A2V00002578360 : {
			de: "Halter LFST TDG",
			en: "Holder ALV MB"
		}
		,A2V00002578529 : {
			de: "Erdungslasche",
			en: "Ground point"
		}
		,A2V00002580819 : {
			de: "Beilage f. Festanschlag, 10MM",
			en: "SHIM F. LATERAL STOP, 10MM"
		}
		,A2V00002583500 : {
			de: "Erdungslasche 2",
			en: "Ground point 2"
		}
		,A2V00002584399 : {
			de: "Blech Kabeltraeger",
			en: "Sheet cable beam"
		}
		,A2V00002585732 : {
			de: "HÃ¼lse",
			en: "sleeve"
		}
		,A2V00002586585 : {
			de: "Verdrehsicherung",
			en: "Anti-twist device"
		}
		,A2V00002586899 : {
			de: "Schellenhalter 1",
			en: "Clip collar bracket 1"
		}
		,A2V00002587236 : {
			de: "Schellenhalter 4",
			en: "Clip collar bracket 4"
		}
		,A2V00002587259 : {
			de: "Schellenhalter 2",
			en: "Clip collar bracket 2"
		}
		,A2V00002590711 : {
			de: "Schellenhalter 8",
			en: "Clip collar bracket 8"
		}
		,A2V00002592799 : {
			de: "Schellenhalter 6",
			en: "Clip collar bracket 6"
		}
		,A2V00002593244 : {
			de: "Schaekel Adapter",
			en: "Shackle adapter"
		}
		,A2V00002594326 : {
			de: "Schellenhalter 5",
			en: "Clip collar bracket 5"
		}
		,A2V00002594368 : {
			de: "Schellenhalter 3",
			en: "Clip collar bracket 3"
		}
		,A2V00002595404 : {
			de: "Nabe",
			en: "Hub"
		}
		,A2V00002598084 : {
			de: "Rippe 2",
			en: "Rib T4"
		}
		,A2V00002598119 : {
			de: "Steg aussen",
			en: "Web outside"
		}
		,A2V00002598150 : {
			de: "Stirnplatte",
			en: "Head plate"
		}
		,A2V00002598180 : {
			de: "Lasche",
			en: "Link"
		}
		,A2V00002598181 : {
			de: "Rippe 1",
			en: "Rib 1"
		}
		,A2V00002598207 : {
			de: "Steg innen",
			en: "Web inside"
		}
		,A2V00002598865 : {
			de: "Abhebelasche",
			en: "Lift off buttstrap"
		}
		,A2V00002600693 : {
			de: "Scheibe 5-13-45",
			en: "Shim 5-13-45"
		}
		,A2V00002602584 : {
			de: "Beilage Notfang 1mm",
			en: "Shim emergency catch 1mm"
		}
		,A2V00002604314 : {
			de: "Schweissbadsicherung 3",
			en: "Backing strip 3"
		}
		,A2V00002606097 : {
			de: "Schellenhalter 9",
			en: "Clip collar bracket 9"
		}
		,A2V00002606960 : {
			de: "Gewindebolzen",
			en: "Stud bolt"
		}
		,A2V00002610084 : {
			de: "Halter Han3 Sofia",
			en: "Holder Han3 Sofia"
		}
		,A2V00002611639 : {
			de: "Seil vollst.",
			en: "rope compl."
		}
		,A2V00002613544 : {
			de: "Matte",
			en: "Mat"
		}
		,A2V00002613600 : {
			de: "Rahmen",
			en: "Frame"
		}
		,A2V00002613668 : {
			de: "Leiste",
			en: "Flat rod"
		}
		,A2V00002614611 : {
			de: "Getriebenotfang",
			en: "Gear emerg. stop"
		}
		,A2V00002615187 : {
			de: "Getriebestuetze SPG",
			en: "Gear support OAD"
		}
		,A2V00002615188 : {
			de: "Getriebestuetze WG",
			en: "Gear support AD"
		}
		,A2V00002617479 : {
			de: "Gurt",
			en: "Cord"
		}
		,A2V00002617514 : {
			de: "Halter Tritt",
			en: "Tread support"
		}
		,A2V00002618241 : {
			de: "Befestigung Stromabnehmer",
			en: "Mounting current collector"
		}
		,A2V00002618287 : {
			de: "Befestigungsbolzen",
			en: "Mounting bolt"
		}
		,A2V00002619023 : {
			de: "Versteifung",
			en: "Stiffener"
		}
		,A2V00002621179 : {
			de: "Queranschlagkonsole w.g.",
			en: "Bracket for cross a.D"
		}
		,A2V00002621180 : {
			de: "Queranschlagkonsole SPG",
			en: "Bracket for cross OAD"
		}
		,A2V00002621825 : {
			de: "Flansch",
			en: "Flange"
		}
		,A2V00002621915 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002349770 : {
			de: "Rahmen kpl.",
			en: "frame complete"
		}
		,A2V00002624555 : {
			de: "Halter Verkabelung 2",
			en: "holder cabling 2"
		}
		,A2V00002625536 : {
			de: "Motorhalter 3",
			en: "motor holder 3"
		}
		,A2V00002625537 : {
			de: "Motorhalter 4",
			en: "motor holder 4"
		}
		,A2V00002625538 : {
			de: "Motorhalter 5",
			en: "motor holder 5"
		}
		,A2V00002626907 : {
			de: "Gewindebolzen",
			en: "Stud bolt"
		}
		,A2V00002626974 : {
			de: "Bolzen f. Notfang",
			en: "Bolt f. emercency catch"
		}
		,A2V00002628635 : {
			de: "Beilage Motorfeder 6 mm",
			en: "Shim f. spring 6 mm"
		}
		,A2V00002629016 : {
			de: "Beilage Motorfeder 5 mm",
			en: "Shim f. spring 5 mm"
		}
		,A2V00002632020 : {
			de: "Primaerfederzentrierung",
			en: "Primary Spring Centering"
		}
		,A2V00002632023 : {
			de: "Primaerfederunterlage",
			en: "Primary Spring Washer"
		}
		,A2V00002632024 : {
			de: "Beilage Pur-Elastomer",
			en: "Washer polyurethane elastomer"
		}
		,A2V00002660299 : {
			de: "Bolzen ISO 13918 - ID M8x20 - A4-50",
			en: "stud ISO 13918 - ID M8x20 - A4-70"
		}
		,A2V00002668284 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002669048 : {
			de: "Rippe Laengsmitnahme 1",
			en: "Rib traction link 1"
		}
		,A2V00002669052 : {
			de: "Rippe Laengsmitnahme 2",
			en: "Rib traction link 2"
		}
		,A2V00002669233 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002670389 : {
			de: "Beilage 5mm",
			en: "Shim 5mm"
		}
		,A2V00002670390 : {
			de: "Beilage 1mm",
			en: "Shim 1mm"
		}
		,A2V00002670824 : {
			de: "Grundbeilage",
			en: "basic shim"
		}
		,A2V00002670845 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002673911 : {
			de: "Beilage 10mm",
			en: "Shim 10mm"
		}
		,A2V00002262861 : {
			de: "Gehaeuse kompl II Spindelz 8755961 O. DB",
			en: "Housing compl. II Spindelz 8755961 O. DB"
		}
		,A2V00002679696 : {
			de: "Flexball DZ160,G=3430,H=100+HZ9",
			en: "Flexball DZ160,G=3430,H=100+HZ9"
		}
		,A2V00002681938 : {
			de: "Abzweigkasten ZL 156 Q-2",
			en: "Junction box ZL 156 Q-2"
		}
		,A2V00002682776 : {
			de: "Beilage Festanschlag, 1mm",
			en: "Shim lateral stop, 1mm"
		}
		,A2V00002682777 : {
			de: "Beilage Festanschlag, 3mm",
			en: "Shim hard stop, 3mm"
		}
		,A2V00002683142 : {
			de: "Kronenmutter",
			en: "Castle nut"
		}
		,A2V00002683555 : {
			de: "Stiftschraube",
			en: "Stud"
		}
		,A2V00002683604 : {
			de: "Rippe quer",
			en: "Rib cross"
		}
		,A2V00002683646 : {
			de: "Abhebesicherung primÃ¤r",
			en: "Lifting stop primary"
		}
		,A2V00002687507 : {
			de: "Anlenkstange L Zuschnitt",
			en: "Tie Rod L Cutting"
		}
		,A2V00002688737 : {
			de: "Rippe 1 WG",
			en: "Stiffener 1 AD"
		}
		,A2V00002688748 : {
			de: "Rippe 1 SPG",
			en: "Stiffener 1 OAD"
		}
		,A2V00002688813 : {
			de: "Beilage Motorfeder 10 mm",
			en: "Shim f. spring 10 mm"
		}
		,A2V00002688814 : {
			de: "Beilage Motorfeder 7 mm",
			en: "Shim f. spring 7 mm"
		}
		,A2V00002688815 : {
			de: "Beilage Motorfeder 11 mm",
			en: "Shim f. spring 11 mm"
		}
		,A2V00002688818 : {
			de: "Mutterstueck",
			en: "Coupling part"
		}
		,A2V00002688846 : {
			de: "Beilage Motorfeder 9 mm",
			en: "Shim f. spring 9 mm"
		}
		,A2V00002688847 : {
			de: "Beilage Motorfeder 8 mm",
			en: "Shim f. spring 8 mm"
		}
		,A2V00002688871 : {
			de: "Scheibe f. Motorfeder",
			en: "Washer f. motor spring"
		}
		,A2V00002689178 : {
			de: "Gurt, sp.g.",
			en: "Flange, opp. as drawn"
		}
		,A2V00002691189 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002691291 : {
			de: "Federblatt LDG 362mm",
			en: "Leaf spring trailer bogie 362mm"
		}
		,A2V00002691375 : {
			de: "Lasche EK",
			en: "Hanger EB"
		}
		,A2V00002691510 : {
			de: "Lasche Rahmen",
			en: "Hanger Frame"
		}
		,A2V00002691613 : {
			de: "Halter w.g.",
			en: "Holder a.d."
		}
		,A2V00002691857 : {
			de: "Halter spg.",
			en: "Holder o.a.d."
		}
		,A2V00002691944 : {
			de: "Platte",
			en: "Cake"
		}
		,A2V00002692116 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002692953 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002693583 : {
			de: "Queranschlag WI SPG",
			en: "Lateral stop wi oad"
		}
		,A2V00002693584 : {
			de: "Queranschlag WI WG",
			en: "Lateral stop wi ad"
		}
		,A2V00002693634 : {
			de: "Daempferkonsole",
			en: "Damper bracket"
		}
		,A2V00002693775 : {
			de: "Halter 4",
			en: "Holder 4"
		}
		,A2V00002694245 : {
			de: "Halteblech Rohr 1",
			en: "Bracket pipe 1"
		}
		,A2V00002695293 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screw"
		}
		,A2V00002695634 : {
			de: "Halter QuertrÃ¤ger 3",
			en: "holder crossbeam 3"
		}
		,A2V00002696488 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002696492 : {
			de: "Spannstift ISO13337-21x22-St",
			en: "clamping pin ISO13337-21x22-St"
		}
		,A2V00002696545 : {
			de: "Zylinderschraube",
			en: "hexagon socket head cap screwÂ "
		}
		,A2V00002696566 : {
			de: "Zylinderschraube",
			en: "hexagon socket head cap screwÂ "
		}
		,A2V00002696567 : {
			de: "Zylinderschraube",
			en: "hexagon socket head cap screwÂ "
		}
		,A2V00002697240 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002697585 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002697631 : {
			de: "Halter 1",
			en: "Holder 1"
		}
		,A2V00002697718 : {
			de: "Schellenhalter 5",
			en: "clamp holder 5"
		}
		,A2V00002697835 : {
			de: "Halter 1 w.g.",
			en: "Holder 1 a.d."
		}
		,A2V00002698306 : {
			de: "Halter 1 spg.",
			en: "Holder o.a.d."
		}
		,A2V00002698494 : {
			de: "Halter 6",
			en: "Holder 6"
		}
		,A2V00002698597 : {
			de: "Halter 2",
			en: "Holder 2"
		}
		,A2V00002698677 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002698708 : {
			de: "Halter 3",
			en: "Holder 3"
		}
		,A2V00002698750 : {
			de: "Halter 5",
			en: "Holder 5"
		}
		,A2V00002698766 : {
			de: "Halter 7",
			en: "Holder 7"
		}
		,A2V00002698905 : {
			de: "Halteblech Rohr 2",
			en: "Bracket pipe 2"
		}
		,A2V00002699220 : {
			de: "Beilage Sek. 2",
			en: "Shim second. 2"
		}
		,A2V00002699293 : {
			de: "Beilage Sek.1",
			en: "Shim second. 1"
		}
		,A2V00002699309 : {
			de: "Federbeilage 5mm",
			en: "Spring shim 5mm"
		}
		,A2V00002699310 : {
			de: "Federbeilage 10mm",
			en: "Spring shim 10mm"
		}
		,A2V00002699311 : {
			de: "Notfederbeilage 5mm",
			en: "Spring f. emergency spring 5mm"
		}
		,A2V00002699312 : {
			de: "Notfederbeilage 10mm",
			en: "Spring f. emergency spring 10mm"
		}
		,A2V00002699314 : {
			de: "Notfederbeilage 5mm",
			en: "Spring f. emergency spring 5mm"
		}
		,A2V00002699315 : {
			de: "Notfederbeilage 10mm",
			en: "Spring f. emergency spring 10mm"
		}
		,A2V00002699338 : {
			de: "Beilage Sek. 3",
			en: "Shim second. 3"
		}
		,A2V00002699357 : {
			de: "Beilage f. Querpuffer 10mm",
			en: "Shim f. lateral stop 10mm"
		}
		,A2V00002699358 : {
			de: "Beilage f. Querpuffer 5mm",
			en: "Shim f. lateral stop 5mm"
		}
		,A2V00002699366 : {
			de: "Beilage Sek. 4",
			en: "Shim second. 4"
		}
		,A2V00002699431 : {
			de: "Klotz RSF",
			en: "Block WSG"
		}
		,A2V00002699833 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002699979 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002700053 : {
			de: "Halter 1 w.g.",
			en: "Holder 1 a.d."
		}
		,A2V00002700054 : {
			de: "Halter 1 spg.",
			en: "Holder 1 o.a.d."
		}
		,A2V00002700061 : {
			de: "Halter 2 w.g",
			en: "Holder 2 a.d."
		}
		,A2V00002700062 : {
			de: "Halter 2 spg",
			en: "Holder o.a.d."
		}
		,A2V00002700328 : {
			de: "Halter 3",
			en: "Holder 3"
		}
		,A2V00002700415 : {
			de: "Halter 7",
			en: "Holder 7"
		}
		,A2V00002700452 : {
			de: "Halter 5",
			en: "Holder 5"
		}
		,A2V00002700496 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002700568 : {
			de: "Halter 6",
			en: "Holder 6"
		}
		,A2V00002700636 : {
			de: "Halter 8",
			en: "Holder 8"
		}
		,A2V00002700642 : {
			de: "Halter 9",
			en: "Holder 9"
		}
		,A2V00002700706 : {
			de: "Halter 10",
			en: "Holder 10"
		}
		,A2V00002700721 : {
			de: "Tragschiene DIN 3015-1 - L TS-11x230-W4",
			en: "Mounting rail DIN 3015-1 -L TS-11x230-W4"
		}
		,A2V00002700723 : {
			de: "Halter 12",
			en: "Holder 12"
		}
		,A2V00002700735 : {
			de: "Halter 13",
			en: "Holder 13"
		}
		,A2V00002700822 : {
			de: "Halter 11",
			en: "Holder 11"
		}
		,A2V00002700835 : {
			de: "Halter 14",
			en: "Holder 14"
		}
		,A2V00002701682 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00002701683 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002701817 : {
			de: "Gewindeeinsatz KEENSERTS",
			en: "Thread insert KEENSERTS"
		}
		,A2V00002703880 : {
			de: "Halter 16",
			en: "Holder 16"
		}
		,A2V00002704415 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002704710 : {
			de: "Belch w.g.",
			en: "Bracket a.d."
		}
		,A2V00002704711 : {
			de: "Blech spg.",
			en: "Bracket oad."
		}
		,A2V00002704981 : {
			de: "Deckel",
			en: "Cover"
		}
		,A2V00002705713 : {
			de: "Halter 4",
			en: "Bracket 4"
		}
		,A2V00002706049 : {
			de: "Abhebesicherung",
			en: "Lifting stop"
		}
		,A2V00002706828 : {
			de: "Verbindungslasche",
			en: "connection lug"
		}
		,A2V00002707102 : {
			de: "Halter 3",
			en: "Bracket 3"
		}
		,A2V00002707227 : {
			de: "Halter 1",
			en: "Bracket 1"
		}
		,A2V00002707382 : {
			de: "Halter 5",
			en: "Bracket 5"
		}
		,A2V00002707397 : {
			de: "Halter 1",
			en: "holder 1"
		}
		,A2V00002707406 : {
			de: "Halter 2",
			en: "Bracket 2"
		}
		,A2V00002707409 : {
			de: "Abdeckplatte",
			en: "Cover plate"
		}
		,A2V00002707428 : {
			de: "Halter 4 semi",
			en: "Bracket 4 semi"
		}
		,A2V00002707458 : {
			de: "Halter 2",
			en: "holder 2"
		}
		,A2V00002707567 : {
			de: "Notfangplatte",
			en: "Emergency catch"
		}
		,A2V00002709209 : {
			de: "Winkel w.g.",
			en: "Angle a.d."
		}
		,A2V00002709276 : {
			de: "Winkel spg.",
			en: "Angle o.a.d."
		}
		,A2V00002709843 : {
			de: "Beilage Abhebes. 10mm",
			en: "Shim lift stop 10mm"
		}
		,A2V00002709869 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00002710318 : {
			de: "Abhebesicherung",
			en: "lift stop"
		}
		,A2V00002710775 : {
			de: "Rippe 3 SPG",
			en: "Rib 3 OAD"
		}
		,A2V00002710783 : {
			de: "Obergurt 3 WG",
			en: "Upper flange 3 AD"
		}
		,A2V00002710784 : {
			de: "Obergurt 3 SPG",
			en: "Upper flange 3 OAD"
		}
		,A2V00002710865 : {
			de: "Rippe 4",
			en: "Rib 4"
		}
		,A2V00002710936 : {
			de: "Rippe 3 WG",
			en: "Rib 3 AD"
		}
		,A2V00002710979 : {
			de: "Platte 1",
			en: "Board 1"
		}
		,A2V00002711056 : {
			de: "Rippe 5",
			en: "Rib 5"
		}
		,A2V00002711107 : {
			de: "Schweissbadsicherung 1",
			en: "Weld pool backing 1"
		}
		,A2V00002711108 : {
			de: "Schweissbadsicherung 2",
			en: "Weld pool backing 2"
		}
		,A2V00002711415 : {
			de: "Halter 2",
			en: "Holder 2"
		}
		,A2V00002711450 : {
			de: "Halter 1",
			en: "Holder 1"
		}
		,A2V00002711561 : {
			de: "Halter 3 spg",
			en: "Holder 3 opp"
		}
		,A2V00002711562 : {
			de: "Halter 3 wg",
			en: "Holder 3 ad"
		}
		,A2V00002711758 : {
			de: "Scheibe",
			en: "Disk"
		}
		,A2V00002711982 : {
			de: "Rippe 6",
			en: "Rib 6"
		}
		,A2V00002712025 : {
			de: "Sechskantschraube",
			en: "hexagon screw"
		}
		,A2V00002712057 : {
			de: "Rippe 7",
			en: "Rib 7"
		}
		,A2V00002712075 : {
			de: "Steg 1 SPG",
			en: "Web 1 OAD"
		}
		,A2V00002712133 : {
			de: "Zahnplatte",
			en: "Connecting plate"
		}
		,A2V00002712246 : {
			de: "Steg 1 WG",
			en: "Web 1 AD"
		}
		,A2V00002713180 : {
			de: "Winkel 2",
			en: "Angle 2"
		}
		,A2V00002713251 : {
			de: "Tragarm w.g.",
			en: "Supporting arm a.d."
		}
		,A2V00002713272 : {
			de: "Deckel",
			en: "Cover"
		}
		,A2V00002713313 : {
			de: "Formrohr",
			en: "Hollow sections"
		}
		,A2V00002713343 : {
			de: "Tragarm spg.",
			en: "Supporting arm o.a.d."
		}
		,A2V00002713345 : {
			de: "Winkel 1",
			en: "Angle 1"
		}
		,A2V00002713360 : {
			de: "Rippe",
			en: "Stiffener"
		}
		,A2V00002713362 : {
			de: "Blech",
			en: "Plate"
		}
		,A2V00002713539 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00002713559 : {
			de: "Beilage 10mm",
			en: "Shim 10mm"
		}
		,A2V00002713576 : {
			de: "Rohr 2",
			en: "Tube 2"
		}
		,A2V00002714422 : {
			de: "Rippe 8",
			en: "Rib 8"
		}
		,A2V00002714427 : {
			de: "Halteblech",
			en: "Retaining plate"
		}
		,A2V00002714580 : {
			de: "Anlaufblech 2",
			en: "Starting sheet 2"
		}
		,A2V00002714582 : {
			de: "Anlaufblech 1",
			en: "Starting sheet 1"
		}
		,A2V00002714655 : {
			de: "Bolzen",
			en: "Pin"
		}
		,A2V00002714886 : {
			de: "Beilage Querpuffer 4mm",
			en: "shim lateral buffer 4mm"
		}
		,A2V00002714887 : {
			de: "Beilage Querpuffer 3mm",
			en: "shim lateral buffer 3mm"
		}
		,A2V00002715646 : {
			de: "Bolzen",
			en: "Pin"
		}
		,A2V00002715987 : {
			de: "Konsole SVD",
			en: "Console SVD"
		}
		,A2V00002716141 : {
			de: "Gleitplatte",
			en: "Sliding plate"
		}
		,A2V00002716795 : {
			de: "Notfang",
			en: "Emergency catcher"
		}
		,A2V00002716933 : {
			de: "Halter Junction Box",
			en: "plate antenna as drawn"
		}
		,A2V00002717634 : {
			de: "Keil",
			en: "Key"
		}
		,A2V00002717950 : {
			de: "Rohrhalter",
			en: "Pipe holder"
		}
		,A2V00002717975 : {
			de: "Notfang",
			en: "Emergency catcher"
		}
		,A2V00002718886 : {
			de: "Halter LuftfedergestÃ¤nge EDG w.g.",
			en: "Holder leveling valve rod EB a.d."
		}
		,A2V00002718887 : {
			de: "Halter LuftfedergestÃ¤nge EDG sp.g.",
			en: "Holder leveling valve rod EB o.a.d."
		}
		,A2V00002719103 : {
			de: "Anschlagscheibe",
			en: "Stop washer"
		}
		,A2V00002719379 : {
			de: "Abdeckscheibe",
			en: "Cover plate"
		}
		,A2V00002719565 : {
			de: "Adapter Getriebestuetze",
			en: "Adapter gear rod"
		}
		,A2V00002720167 : {
			de: "TDG Laengstraeger links",
			en: "TDG Longitudinal Member Rear LH"
		}
		,A2V00002720172 : {
			de: "TDG Laengstraeger rechts",
			en: "TDG Longitudinal Member RH"
		}
		,A2V00002720355 : {
			de: "Steg 3 UR",
			en: "Web 3 LF"
		}
		,A2V00002720623 : {
			de: "Rippe, aeussere WG",
			en: "Rib, outer AD"
		}
		,A2V00002720624 : {
			de: "Rippe, innere WG",
			en: "Rib, inner AD"
		}
		,A2V00002720625 : {
			de: "Stuetzblech SPG",
			en: "Supporting plate OAD"
		}
		,A2V00002720687 : {
			de: "Rippe 1 SPG",
			en: "Rib 1 OAD"
		}
		,A2V00002720688 : {
			de: "Rippe 5 WG",
			en: "Rib 5 AD"
		}
		,A2V00002720690 : {
			de: "Rippe 2 WG",
			en: "Rib 2 AD"
		}
		,A2V00002720691 : {
			de: "Rippe Querpuffer",
			en: "Rib transversal bumper"
		}
		,A2V00002720693 : {
			de: "Rippe 1 WG",
			en: "Rib 1 AD"
		}
		,A2V00002720694 : {
			de: "Rippe 5 SPG",
			en: "Rib 5 OAD"
		}
		,A2V00002720696 : {
			de: "Rippe 4",
			en: "Rib 4"
		}
		,A2V00002720697 : {
			de: "Rippe 3",
			en: "Rib 3"
		}
		,A2V00002720698 : {
			de: "Platte Querpuffer",
			en: "Plate transversal bumper"
		}
		,A2V00002720700 : {
			de: "Hebeoese",
			en: "Lifting lug"
		}
		,A2V00002720756 : {
			de: "Rippe, innere SPG",
			en: "Rib, inner OAD"
		}
		,A2V00002720758 : {
			de: "Platte Laengsmitnahme",
			en: "Plate traction link"
		}
		,A2V00002720759 : {
			de: "Rippe Laengsmitnahme",
			en: "Rib traction link"
		}
		,A2V00002720760 : {
			de: "Rippe, aeussere SPG",
			en: "Rib, outer OAD"
		}
		,A2V00002720761 : {
			de: "Stuetzblech WG",
			en: "Supporting plate AD"
		}
		,A2V00002721377 : {
			de: "Montageplatte",
			en: "mounting plate"
		}
		,A2V00002721378 : {
			de: "Klemmplatte",
			en: "clamping plate"
		}
		,A2V00002721379 : {
			de: "Beilagenplatte",
			en: "washer plate"
		}
		,A2V00002722148 : {
			de: "Untergurt 3 WG",
			en: "Lower flange 3 AD"
		}
		,A2V00002722149 : {
			de: "Untergurt 3 SPG",
			en: "Lower flange 3 OAD"
		}
		,A2V00002722180 : {
			de: "Blech 1 w.g.",
			en: "metal sheet 1 as drawn"
		}
		,A2V00002722181 : {
			de: "Blech 1 sp.g.",
			en: "metal sheet 1 sym.opp."
		}
		,A2V00002722182 : {
			de: "Blech 2 w.g.",
			en: "metal sheet 2 as drawn"
		}
		,A2V00002722183 : {
			de: "Blech 2 sp.g.",
			en: "metal sheet 2 sym.opp."
		}
		,A2V00002722184 : {
			de: "Rippe 1 w.g.",
			en: "rib 1 as drawn"
		}
		,A2V00002722185 : {
			de: "Rippe 1 sp.g.",
			en: "rib 1 sym.opp."
		}
		,A2V00002722196 : {
			de: "Platte Sand WG",
			en: "Plate sand AD"
		}
		,A2V00002722197 : {
			de: "Untergurt SPG",
			en: "Lower flange OAD"
		}
		,A2V00002722198 : {
			de: "Steg 1 SPG",
			en: "Web 1 OAD"
		}
		,A2V00002722199 : {
			de: "Obergurt SPG",
			en: "Upper flange OAD"
		}
		,A2V00002722200 : {
			de: "Steg 2 SPG",
			en: "Web 2 OAD"
		}
		,A2V00002722201 : {
			de: "Rippe WG",
			en: "Rib AD"
		}
		,A2V00002722202 : {
			de: "Steg 1 WG",
			en: "Web 1 AD"
		}
		,A2V00002722203 : {
			de: "Platte Sand SPG",
			en: "Plate sand OAD"
		}
		,A2V00002722204 : {
			de: "Obergurt WG",
			en: "Upper flange AD"
		}
		,A2V00002722205 : {
			de: "Steg 2 WG",
			en: "Web 2 AD"
		}
		,A2V00002722206 : {
			de: "Platte SPG",
			en: "Plate OAD"
		}
		,A2V00002722207 : {
			de: "Flanschplatte",
			en: "Flange plate"
		}
		,A2V00002722208 : {
			de: "Platte WG",
			en: "Plate AD"
		}
		,A2V00002722209 : {
			de: "Untergurt WG",
			en: "Lower flange AD"
		}
		,A2V00002722210 : {
			de: "Rippe SPG",
			en: "Rib OAD"
		}
		,A2V00002722226 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002722382 : {
			de: "Rippe 2 SPG",
			en: "Rib 2 OAD"
		}
		,A2V00002722731 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002722835 : {
			de: "Deckel, Anlenkstange",
			en: "Cover, link rod"
		}
		,A2V00002722977 : {
			de: "Rohr fuer Anlenkstange",
			en: "Tube for link rod"
		}
		,A2V00002722980 : {
			de: "Scheibe fuer Anlenkstange",
			en: "Disc for link rod"
		}
		,A2V00002722981 : {
			de: "Sechskant fÃ¼r Anlenkst.",
			en: "Hexagon for link rod"
		}
		,A2V00002722983 : {
			de: "Huelse fuer Anlenkstange",
			en: "Spacer for link rod"
		}
		,A2V00002723596 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screws - full thread"
		}
		,A2V00002723605 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screws - full thread"
		}
		,A2V00002723658 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screws - full thread"
		}
		,A2V00002723659 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screws - full thread"
		}
		,A2V00002723662 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screws - full thread"
		}
		,A2V00002723663 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screws - full thread"
		}
		,A2V00002723664 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screws - full thread"
		}
		,A2V00002723665 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screws - full thread"
		}
		,A2V00002723746 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screws - full thread"
		}
		,A2V00002723950 : {
			de: "Kontaktplatte",
			en: "Contact plate"
		}
		,A2V00002723956 : {
			de: "Scheibe Wanksystem 4mm",
			en: "shim anti roll system 4mm"
		}
		,A2V00002723957 : {
			de: "Scheibe Wanksystem 2mm",
			en: "shim anti roll system 2mm"
		}
		,A2V00002724034 : {
			de: "Halter 8",
			en: "Holder 8"
		}
		,A2V00002724047 : {
			de: "Halter Revisionsschild",
			en: "holder revision label"
		}
		,A2V00002724115 : {
			de: "Halter 7",
			en: "Holder 7"
		}
		,A2V00002724128 : {
			de: "Halter 10",
			en: "Holder 10"
		}
		,A2V00002724143 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002724199 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002724221 : {
			de: "Halter 9",
			en: "Holder 9"
		}
		,A2V00002724277 : {
			de: "Halter 12",
			en: "Holder 12"
		}
		,A2V00002724287 : {
			de: "Halter 6",
			en: "Holder 6"
		}
		,A2V00002724288 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002725356 : {
			de: "Halter 13",
			en: "holder 13"
		}
		,A2V00002725424 : {
			de: "Erdungslasche",
			en: "Grounding sheet"
		}
		,A2V00002725594 : {
			de: "Beilage Y-Verteiler",
			en: "shim y-distributer"
		}
		,A2V00002725606 : {
			de: "Radsatzmarke, roh",
			en: "Wheelset label, raw"
		}
		,A2V00002725766 : {
			de: "Beilage fÃ¼r Schelle",
			en: "shim for clamp"
		}
		,A2V00002725823 : {
			de: "Lager Oberteil",
			en: "Bearing upper part"
		}
		,A2V00002725829 : {
			de: "bl15_m8_ng2",
			en: "sh15_m8_ng2"
		}
		,A2V00002725910 : {
			de: "Abdeckkappe",
			en: "Cover cap"
		}
		,A2V00002726632 : {
			de: "Anschlussplatte",
			en: "Ground plate"
		}
		,A2V00002726719 : {
			de: "Klemmring",
			en: "Clamp ring"
		}
		,A2V00002726741 : {
			de: "Zylinderstift",
			en: "Parakkek pin"
		}
		,A2V00002726811 : {
			de: "Erdungslasche 3",
			en: "Grounding sheet 3"
		}
		,A2V00002726812 : {
			de: "Erdungslasche 1",
			en: "Grounding sheet 1"
		}
		,A2V00002726822 : {
			de: "Anschlag LP EDG",
			en: "Bump stop LB EB"
		}
		,A2V00002726850 : {
			de: "Sechskantmutter selbstsichernd",
			en: "Prevailing torque type hexagon nut"
		}
		,A2V00002726851 : {
			de: "Sechskantmutter selbstsichernd",
			en: "Prevailing torque type hexagon nut"
		}
		,A2V00002726853 : {
			de: "Sechskantmutter selbstsichernd",
			en: "Prevailing torque type hexagon nut"
		}
		,A2V00002726931 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screws - full thread"
		}
		,A2V00002727169 : {
			de: "IsolierhÃ¼lse",
			en: "Insulation sleeve"
		}
		,A2V00002727172 : {
			de: "Adapter",
			en: "adapter"
		}
		,A2V00002727260 : {
			de: "Isolierscheibe",
			en: "Insulation disc"
		}
		,A2V00002727654 : {
			de: "Temperatursensor f. Radlager 2xPT100",
			en: "Temperature sensor f. bearing 2xPT100"
		}
		,A2V00002728123 : {
			de: "Halter F 70",
			en: "holder F 70"
		}
		,A2V00002728124 : {
			de: "Halter F 90",
			en: "holder F 90"
		}
		,A2V00002728125 : {
			de: "Gewindebutzen 1",
			en: "Threaded bush 1"
		}
		,A2V00002728186 : {
			de: "Halter Verrohrung 1",
			en: "holder piping 1"
		}
		,A2V00002728187 : {
			de: "AnschweiÃŸbolzen 3",
			en: "welding bolt 3"
		}
		,A2V00002728188 : {
			de: "Halter Motorkabel",
			en: "holder motor cable"
		}
		,A2V00002728189 : {
			de: "Halter Han3",
			en: "holder han3"
		}
		,A2V00002728190 : {
			de: "Halter Schutzblech",
			en: "holder protection sheet"
		}
		,A2V00002728191 : {
			de: "Halter Schutzblech 2",
			en: "holder protection sheet 2"
		}
		,A2V00002728192 : {
			de: "Halter 1",
			en: "holder 1"
		}
		,A2V00002728193 : {
			de: "Erdungsplatte",
			en: "holder earthing contact"
		}
		,A2V00002728378 : {
			de: "Indusi Adapter 2",
			en: "Indusi adapter 2"
		}
		,A2V00002728544 : {
			de: "Indusi Platte 1",
			en: "Indusi plate 1"
		}
		,A2V00002728545 : {
			de: "Indusi Platte 2",
			en: "Indusi plate 2"
		}
		,A2V00002728686 : {
			de: "Anzeiger",
			en: "indicator"
		}
		,A2V00002728831 : {
			de: "AchsschlÃ¼ssel 1",
			en: "Axle rod 1"
		}
		,A2V00002728924 : {
			de: "SchweiÃŸdraht 1,0mm - Fass",
			en: "Welding rod 1,0mm - Barrel"
		}
		,A2V00002728925 : {
			de: "SchweiÃŸdraht 1,0mm - Spule",
			en: "Welding rod 1,0mm - Spool"
		}
		,A2V00002728998 : {
			de: "Gewindebutzen 2",
			en: "Threaded bush 2"
		}
		,A2V00002729111 : {
			de: "Rundauflage M16",
			en: "Special nut M16"
		}
		,A2V00002729402 : {
			de: "Rundstahl blank 20h9 42CrMOS4+C",
			en: "Round steel bright 20h9 42CrMOS4+C"
		}
		,A2V00002729950 : {
			de: "Beilage f. Querpuffer 1mm",
			en: "Shim f- lateral buffer 1mm"
		}
		,A2V00002729952 : {
			de: "Beilage f. Querpuffer 2mm",
			en: "Shim f- lateral buffer 2mm"
		}
		,A2V00002729992 : {
			de: "Druckkappe",
			en: "end cap"
		}
		,A2V00002730026 : {
			de: "Halter ATC Verkab. 1",
			en: "holder ATC cabling 1"
		}
		,A2V00002730027 : {
			de: "Halter ATC Verkab. 2",
			en: "holder ATC cabling 2"
		}
		,A2V00002730028 : {
			de: "Halter ATC Verkab. 3",
			en: "holder ATC cabling 3"
		}
		,A2V00002730725 : {
			de: "Spannstift CONNEX",
			en: "Spring pin CONNEX"
		}
		,A2V00002731017 : {
			de: "Schliessringbolzen HuckBolt C50L",
			en: "Lockbolt"
		}
		,A2V00002731089 : {
			de: "Federblatt",
			en: "Flat spring"
		}
		,A2V00002731173 : {
			de: "Halter EK spg.",
			en: "Holder gk oad."
		}
		,A2V00002731240 : {
			de: "Schweissbadsi. 1 JDG",
			en: "Weld pool back. 1 JB"
		}
		,A2V00002731500 : {
			de: "Wankbock Oberteil",
			en: "ARS bracket top"
		}
		,A2V00002731501 : {
			de: "Wankbock Unterteil",
			en: "ARS bracket bottom"
		}
		,A2V00002732140 : {
			de: "Huelse vertikal",
			en: "Sleeve vertical"
		}
		,A2V00002732142 : {
			de: "Huelse horizontal",
			en: "Sleeve horizontal"
		}
		,A2V00002732143 : {
			de: "Lagerplatte",
			en: "bearing plate"
		}
		,A2V00002732144 : {
			de: "Fangblech",
			en: "catching metal sheet"
		}
		,A2V00002732380 : {
			de: "Spannstift CONNEX",
			en: "Spring pin CONNEX"
		}
		,A2V00002732670 : {
			de: "Axialschelle",
			en: "Axial clamp"
		}
		,A2V00002732772 : {
			de: "Scheibe Rohr",
			en: "shim pipe"
		}
		,A2V00002732773 : {
			de: "Rohr horizontal",
			en: "Tube horizontal"
		}
		,A2V00002732931 : {
			de: "AchsschlÃ¼ssel 2",
			en: "Axle rod 2"
		}
		,A2V00002733366 : {
			de: "Anschlag LP JDG",
			en: "Bump stop LB JB"
		}
		,A2V00002733456 : {
			de: "Beilage 2.5mm",
			en: "Shim 2.5mm"
		}
		,A2V00002733457 : {
			de: "Beilage 2mm",
			en: "Shim 2mm"
		}
		,A2V00002733458 : {
			de: "Beilage 1.5mm",
			en: "Shim 1.5mm"
		}
		,A2V00002733459 : {
			de: "Beilage 1mm",
			en: "Shim 1mm"
		}
		,A2V00002733460 : {
			de: "Beilage 5mm",
			en: "Shim 5mm"
		}
		,A2V00002733957 : {
			de: "Nutmutter M8",
			en: "locknut M8"
		}
		,A2V00002734236 : {
			de: "Sechskantmutter selbstsichernd",
			en: "Prevailing torque type hexagon nut"
		}
		,A2V00002734446 : {
			de: "Halter 2",
			en: "Holder 2"
		}
		,A2V00002734447 : {
			de: "Halter 1",
			en: "Holder 1"
		}
		,A2V00002735054 : {
			de: "Sechskantschraube Verbus Ripp",
			en: "Hexagon head screw Verbus Ripp"
		}
		,A2V00002735735 : {
			de: "Adapter SVD",
			en: "Adapter SVD"
		}
		,A2V00002735908 : {
			de: "Adapter Rahmen",
			en: "Adapter frame"
		}
		,A2V00002736250 : {
			de: "Blech 3",
			en: "sheet 3"
		}
		,A2V00002736256 : {
			de: "Blech 1",
			en: "sheet 1"
		}
		,A2V00002736287 : {
			de: "Blech 2",
			en: "sheet 2"
		}
		,A2V00002736478 : {
			de: "Blech 4",
			en: "sheet 4"
		}
		,A2V00002736595 : {
			de: "Halter 6 spg.",
			en: "Holder 6 oad."
		}
		,A2V00002736700 : {
			de: "Scheibe Getriebest.",
			en: "disk gear susp."
		}
		,A2V00002736701 : {
			de: "Getriebe Rohr",
			en: "gear box pipe"
		}
		,A2V00002736702 : {
			de: "Huelse Getriebe",
			en: "Sleeve gear box"
		}
		,A2V00002736728 : {
			de: "Halter 4",
			en: "Holder 4"
		}
		,A2V00002736730 : {
			de: "Halter 6 wg.",
			en: "Holder 6 ad."
		}
		,A2V00002736746 : {
			de: "Halter 12 wg.",
			en: "Holder 12 ad."
		}
		,A2V00002736747 : {
			de: "Halter 8",
			en: "Holder 8"
		}
		,A2V00002736748 : {
			de: "Halter 7 spg.",
			en: "Holder 7 oad."
		}
		,A2V00002736749 : {
			de: "Halter 7 wg.",
			en: "Holder 7 ad."
		}
		,A2V00002736750 : {
			de: "Halter 5",
			en: "Holder 5"
		}
		,A2V00002736751 : {
			de: "Halter 3",
			en: "Holder 3"
		}
		,A2V00002736752 : {
			de: "Halter 10",
			en: "Holder 10"
		}
		,A2V00002736753 : {
			de: "Halter 9 spg.",
			en: "Holder 9 oad."
		}
		,A2V00002736754 : {
			de: "Halter 9 wg.",
			en: "Holder 9 ad."
		}
		,A2V00002736755 : {
			de: "Halter 12 spg.",
			en: "Holder 12 oad."
		}
		,A2V00002736756 : {
			de: "Halter 11",
			en: "Holder 11"
		}
		,A2V00002736813 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002736864 : {
			de: "Blech 1",
			en: "sheet 1"
		}
		,A2V00002736865 : {
			de: "Blech 2",
			en: "sheet 2"
		}
		,A2V00002737034 : {
			de: "Konsole SHD JDG",
			en: "Console SHD ATB"
		}
		,A2V00002737136 : {
			de: "Buchse fÃ¼r Wiege",
			en: "Bushing for bolster"
		}
		,A2V00002737769 : {
			de: "Deckplatte",
			en: "Cover plate"
		}
		,A2V00002738802 : {
			de: "Beilage Abhebeplatte 1,91mm",
			en: "shim anti-lifting plate 1,91mm"
		}
		,A2V00002738820 : {
			de: "Beilage Abhebeplatte 0,9mm",
			en: "shim anit-tifting plate 0,9mm"
		}
		,A2V00002738894 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screws - full thread"
		}
		,A2V00002738983 : {
			de: "Drehzahlgeber GEL 2475DI - 2 KanÃ¤le",
			en: "Speed sensor GEL 2475DI - 2 signals"
		}
		,A2V00002739034 : {
			de: "Senkschraube",
			en: "Hexagon socket countersunk head screw"
		}
		,A2V00002739894 : {
			de: "Scheibe 1 Motorst.",
			en: "Disk 1 motor susp."
		}
		,A2V00002739992 : {
			de: "FÃ¼hrungsbeilage 1",
			en: "Guide washer 1"
		}
		,A2V00002740078 : {
			de: "FÃ¼hrungsbeilage 2",
			en: "Guide washer 2"
		}
		,A2V00002740345 : {
			de: "Bolzen 12 mit Gewinde M10",
			en: "Bolt 12 with thread M10"
		}
		,A2V00002740357 : {
			de: "Halter 2 Bremse JDG",
			en: "Holder 2 Brake JB"
		}
		,A2V00002740363 : {
			de: "Halter 2 SPG Bremse JDG",
			en: "Holder 2 oad Brake JB"
		}
		,A2V00002740454 : {
			de: "Konsole w.g.",
			en: "Console a.d."
		}
		,A2V00002740474 : {
			de: "Konsole spg.",
			en: "Console o.a.d."
		}
		,A2V00002740477 : {
			de: "Halter 1 Bremse JDG",
			en: "Holder 1 Brake JB"
		}
		,A2V00002740487 : {
			de: "Gewindebolzen",
			en: "threaded bolt"
		}
		,A2V00002740498 : {
			de: "Distanzring",
			en: "Distance ring"
		}
		,A2V00002740577 : {
			de: "Rippe spg.",
			en: "Web"
		}
		,A2V00002740596 : {
			de: "Rippe w.g.",
			en: "Web a.d."
		}
		,A2V00002740656 : {
			de: "Halter LFST JDG",
			en: "Holder LFST JB"
		}
		,A2V00002740715 : {
			de: "Erdungslasche 1",
			en: "Grounding sheet 1"
		}
		,A2V00002740823 : {
			de: "Halter 15",
			en: "Holder 15"
		}
		,A2V00002740854 : {
			de: "Sicherungsplatte",
			en: "safety plate"
		}
		,A2V00002740862 : {
			de: "Halter 21",
			en: "Holder 21"
		}
		,A2V00002740878 : {
			de: "Halter 14",
			en: "Holder 14"
		}
		,A2V00002740910 : {
			de: "Halter 20",
			en: "Holder 20"
		}
		,A2V00002741192 : {
			de: "Federzentrierung 1",
			en: "Spring centering 1"
		}
		,A2V00002741315 : {
			de: "Federzentrierung 2",
			en: "Spring centering 2"
		}
		,A2V00002742108 : {
			de: "Beilage 0,9mm",
			en: "shim 0,9mm"
		}
		,A2V00002742300 : {
			de: "Beilage 6,35mm",
			en: "shim 1/4inch"
		}
		,A2V00002742376 : {
			de: "Beilage 3,05mm",
			en: "shim gauge 11"
		}
		,A2V00002742379 : {
			de: "Beilage 1,91mm",
			en: "shim gauge 14"
		}
		,A2V00002742488 : {
			de: "Zentrierung Drehzapfen",
			en: "Centering pivot"
		}
		,A2V00002742662 : {
			de: "Halter 1",
			en: "Holder 1"
		}
		,A2V00002742727 : {
			de: "Halter 9",
			en: "Holder 9"
		}
		,A2V00002742728 : {
			de: "Halter 4",
			en: "Holder 4"
		}
		,A2V00002742729 : {
			de: "Halter 3",
			en: "Holder 3"
		}
		,A2V00002742730 : {
			de: "Halter 6",
			en: "Holder 6"
		}
		,A2V00002742840 : {
			de: "Deckplatte",
			en: "Cover plate"
		}
		,A2V00002743453 : {
			de: "AnschlagstÃ¼ck",
			en: "hard stop"
		}
		,A2V00002743454 : {
			de: "Adapter",
			en: "adapter"
		}
		,A2V00002743493 : {
			de: "Scheibe",
			en: "Washer"
		}
		,A2V00002743509 : {
			de: "Scheibe",
			en: "Washer"
		}
		,A2V00002743529 : {
			de: "Scheibe",
			en: "Washer"
		}
		,A2V00002743540 : {
			de: "Beilage",
			en: "Washer"
		}
		,A2V00002743619 : {
			de: "VerschleiÃŸanschlag",
			en: "Wear stop"
		}
		,A2V00002743649 : {
			de: "Halter 8",
			en: "Holder 8"
		}
		,A2V00002743650 : {
			de: "Halter 10",
			en: "Holder 10"
		}
		,A2V00002743651 : {
			de: "Halter 7",
			en: "Holder 7"
		}
		,A2V00002744085 : {
			de: "Blech 2",
			en: "Plate 2"
		}
		,A2V00002744099 : {
			de: "Beilage 1mm",
			en: "shim 1mm"
		}
		,A2V00002744114 : {
			de: "Abstandshalter 2",
			en: "Spacer 2"
		}
		,A2V00002744162 : {
			de: "Abstandshalter 1",
			en: "Spacer 1"
		}
		,A2V00002744196 : {
			de: "Blech 1",
			en: "Plate 1"
		}
		,A2V00002744255 : {
			de: "Gelenk 1",
			en: "Joint 1"
		}
		,A2V00002744316 : {
			de: "Gelenk 2",
			en: "Joint 2"
		}
		,A2V00002744317 : {
			de: "Gelenk 3",
			en: "Joint 3"
		}
		,A2V00002745172 : {
			de: "Beilage 3mm",
			en: "shim 3mm"
		}
		,A2V00002745235 : {
			de: "Distanzblech",
			en: "Distance sheet"
		}
		,A2V00002745334 : {
			de: "Drehhebel 1",
			en: "Rotating lever 1"
		}
		,A2V00002745344 : {
			de: "Beilage 1,5mm",
			en: "shim 1,5mm"
		}
		,A2V00002745872 : {
			de: "Dreharm 2",
			en: "Rotating arm 2"
		}
		,A2V00002745888 : {
			de: "Beilage 0,9mm",
			en: "shim gauge 20"
		}
		,A2V00002745971 : {
			de: "Dreharm 1",
			en: "Rotating arm 1"
		}
		,A2V00002746333 : {
			de: "Beilage 2 oben",
			en: "Shim 2 above"
		}
		,A2V00002746468 : {
			de: "Beilage 1 oben",
			en: "Shim 1 above"
		}
		,A2V00002746574 : {
			de: "Gewindestift",
			en: "set screw"
		}
		,A2V00002746588 : {
			de: "Beilage 1 unten",
			en: "Shim 1 down"
		}
		,A2V00002746990 : {
			de: "Drehhebel 2",
			en: "Rotating lever 2"
		}
		,A2V00002747264 : {
			de: "DistanzstÃ¼ck",
			en: "Spacer"
		}
		,A2V00002747296 : {
			de: "Dreharm 3",
			en: "Rotating arm 3"
		}
		,A2V00002747399 : {
			de: "Verbindungsblech 1",
			en: "Connecting plate 1"
		}
		,A2V00002747400 : {
			de: "Beilage 2mm",
			en: "shim 2mm"
		}
		,A2V00002747494 : {
			de: "Verbindungsstange 1",
			en: "Connecting rod 1"
		}
		,A2V00002747816 : {
			de: "Sechskantmutter selbstsichernd",
			en: "Prevailing torque type hexagon nut"
		}
		,A2V00002747818 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screws - full thread"
		}
		,A2V00002747825 : {
			de: "Bedienhebel 1",
			en: "Operating lever 1"
		}
		,A2V00002747970 : {
			de: "Griffstueck",
			en: "Grip"
		}
		,A2V00002748003 : {
			de: "Bedienhebel 2",
			en: "Operating lever 2"
		}
		,A2V00002748813 : {
			de: "Verbindungsblech 2",
			en: "Connecting plate 2"
		}
		,A2V00002749052 : {
			de: "Schotterschutz Blech",
			en: "Stone protection sheet"
		}
		,A2V00002749053 : {
			de: "Halter Schutzblech 1",
			en: "Bracket protection sheet 1"
		}
		,A2V00002749069 : {
			de: "Halter Schotterschutz",
			en: "Bracket stone protection"
		}
		,A2V00002749098 : {
			de: "Halter 2",
			en: "Holder 2"
		}
		,A2V00002749168 : {
			de: "Halter 1",
			en: "Holder 1"
		}
		,A2V00002749277 : {
			de: "Anlenkstange Zuschnitt",
			en: "Tie rod cutting"
		}
		,A2V00002749593 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screws - full thread"
		}
		,A2V00002751194 : {
			de: "Halter Verrohrung",
			en: "holder piping"
		}
		,A2V00002606517 : {
			de: "X4 BG DC/DC-Wandler 24/24V 50W",
			en: "X4 asm DC/DC-Converter 24/24V 50W"
		}
		,A2V00002751944 : {
			de: "Halter 3",
			en: "Holder 3"
		}
		,A2V00002752399 : {
			de: "Sechskantmutter selbstsichernd",
			en: "Prevailing torque type hexagon nut"
		}
		,A2V00002752816 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002752838 : {
			de: "Sechskantmutter selbstsichernd",
			en: "Prevailing torque type hexagon nut"
		}
		,A2V00002710330 : {
			de: "Hilfsbetriebeumrichter 90kVA+10kVA+14kW",
			en: "Auxiliary Converter 90kVA+10kVA+14kW"
		}
		,A2V00002677444 : {
			de: "2-flgl. SchwenkschiebetÃ¼rsystem",
			en: "Double panel sliding-plug door system"
		}
		,A2V00002677445 : {
			de: "2-flgl. SST Behinderteneinstieg",
			en: "Double panel sliding-plug door system"
		}
		,A2V00002677526 : {
			de: "1-flgl. SST (rechts) mit AuÃŸennotentrieg",
			en: "Single panel sliding plug door system"
		}
		,A2V00002677527 : {
			de: "1-flgl. SST (links)",
			en: "Single panel sliding plug door system"
		}
		,A2V00113146017 : {
			de: "PULTGERIPPE VOLL.",
			en: "PULTGERIPPE VOLL."
		}
		,A2V00117413253 : {
			de: "SANDKASTEN RECHTS  VOLLST.",
			en: "SANDKASTEN RECHTS  VOLLST."
		}
		,A2V00002753058 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002753555 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002753704 : {
			de: "Halter 01",
			en: "Holder 01"
		}
		,A2V00002753748 : {
			de: "Sechskantmutter selbstsichernd",
			en: "Style NE nylon type hexagon nut"
		}
		,A2V00002753749 : {
			de: "Sechskantmutter selbstsichernd",
			en: "Style NE nylon type hexagon nut"
		}
		,A2V00002753826 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00103012603 : {
			de: "Obere Lagerung",
			en: "Upper Guiding Device"
		}
		,A2V00002754357 : {
			de: "Motorblech 1",
			en: "Motor plate 1"
		}
		,A2V00002755431 : {
			de: "Steg 2",
			en: "Web 2"
		}
		,A2V00002755835 : {
			de: "Halter fuer Kabel",
			en: "Holder for cable"
		}
		,A2V00370060729 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001370466 : {
			de: "Rollenlager verschieblich 804116",
			en: "Roller bearing relocatable 804116"
		}
		,A2V00001267637 : {
			de: "Gelenk unten      vollst.",
			en: "Hinge below complete"
		}
		,A2V00002759729 : {
			de: "Fraesteil SPG",
			en: "Milled part OAD"
		}
		,A2V00002759730 : {
			de: "Fraesteil WG",
			en: "Milled part AD"
		}
		,A2V00002759736 : {
			de: "Steg Wankstabkonsole",
			en: "Web anti roll bar bracket"
		}
		,A2V00002759890 : {
			de: "Halter",
			en: "holder"
		}
		,A2V00002760017 : {
			de: "Distanz",
			en: "Distance"
		}
		,A2V00002760142 : {
			de: "Schelle",
			en: "Clamp"
		}
		,A2V00002760205 : {
			de: "Beilage Kabelschuhe",
			en: "Shim for cable shoe"
		}
		,A2V00201318502 : {
			de: "Mittelflanschpuffer mit Hydr.Kapsel",
			en: "middle flange buffer mit hydr cap"
		}
		,A2V00201303714 : {
			de: "Frontscheibe",
			en: "Front window"
		}
		,A2V00002771650 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00201309953 : {
			de: "Leuchtenglas Mitte",
			en: "illuminaton glass middle"
		}
		,A2V00001689492 : {
			de: "Kuehlerluefter Komplett",
			en: "Cooler ventilator  complete"
		}
		,A2V00397164645 : {
			de: "Deckel fuer Langtraeger und Quertraeger",
			en: "Cover f. longitudinal beam a.cross beam"
		}
		,A2V00201176684 : {
			de: "Scharblech re.",
			en: "troop plate right"
		}
		,A2V00109400284 : {
			de: "SEITENSCHEIBE",
			en: "Side window"
		}
		,A2V00109400280 : {
			de: "SEITENSCHEIBE",
			en: "Side window"
		}
		,A2V00109400281 : {
			de: "SEITENSCHEIBE",
			en: "Side window"
		}
		,A2V00109400282 : {
			de: "SEITENSCHEIBE",
			en: "Side window"
		}
		,A2V00109400283 : {
			de: "SEITENSCHEIBE",
			en: "Side window"
		}
		,A2V00002788102 : {
			de: "Rippe w.g.",
			en: "rib a.d."
		}
		,A2V00002788103 : {
			de: "Konsolenblech w.g.",
			en: "bracket plate a.d."
		}
		,A2V00002788137 : {
			de: "Rippe sp.g.",
			en: "rib o.a.d."
		}
		,A2V00002788138 : {
			de: "Konsolenblech sp.g.",
			en: "bracket plate o.a.d."
		}
		,A2V00397192325 : {
			de: "Rolle",
			en: "roll"
		}
		,A2V00397165400 : {
			de: "KLEMMENKASTEN,GEBOHRTER",
			en: "terminal box drilled"
		}
		,A2V00397171684 : {
			de: "Exenterbolzen",
			en: "excentric bolt"
		}
		,A2V00001171695 : {
			de: "Grundrahmen Stromabnehmer SSS87",
			en: "Base frame curr.collector SSS87"
		}
		,A2V00113041603 : {
			de: "SCHUERZE RECHTS VOLLST.",
			en: "SCHUERZE RECHTS VOLLST."
		}
		,A2V00207315671 : {
			de: "Zugeinrichtung ZE 450-1500",
			en: "Zugeinrichtung ZE 450-1500"
		}
		,A2V00397163995 : {
			de: "Unterlage 3mm f. Querpuffer",
			en: "shim 3mm f. cross bumper"
		}
		,A2V00207473919 : {
			de: "Schuerze lackiert",
			en: "apron lacquered"
		}
		,A2V00207280024 : {
			de: "Fuehrerbremsventil",
			en: "Drivers brake valve"
		}
		,A2V00370072871 : {
			de: "SPIEGEL UEBER WASCHBECKEN A-MODUL",
			en: "mirror above washbasin A-module"
		}
		,A2V00201176685 : {
			de: "Scharblech li.",
			en: "troop plate left"
		}
		,A2V00100271586 : {
			de: "Breitflachstahl 15Bx300X3000-R-S-L",
			en: "wide flat steel 15Bx300X3000-R-S-L"
		}
		,A2V00102081997 : {
			de: "FLACHSTAHL",
			en: "FLACHSTAHL"
		}
		,A2V00102739682 : {
			de: "Breitflachstahl 12Bx200X3000-R-S-L",
			en: "wide flat steel 12Bx200X3000-R-S-L"
		}
		,A2V00001026403 : {
			de: "Saugkreisdrossel",
			en: "draw circuit inductor"
		}
		,A2V00201192324 : {
			de: "Luftkanal z.Fahr",
			en: "Air duct to cab"
		}
		,A2V00207323533 : {
			de: "Blechschale",
			en: "metal bowl"
		}
		,A2V00207323539 : {
			de: "Flanschplatte",
			en: "flange plate"
		}
		,A2V00311101348 : {
			de: "RD DIN671-16-ST37K",
			en: "RD DIN671-16-ST37K"
		}
		,A2V00311400018 : {
			de: "4KT DIN178-20-ST37-2K",
			en: "4KT DIN178-20-ST37-2K"
		}
		,A2V00311400117 : {
			de: "4KT DIN1014-20-S355JO",
			en: "SQUARE DIN1014-20-S355JO"
		}
		,A2V00311500441 : {
			de: "FL DIN1017-60x30-S355JO",
			en: "FL DIN1017-60x30-S355JO"
		}
		,A2V00311500639 : {
			de: "FL DIN1017-70x30-S235JRG2",
			en: "FL DIN1017-70x30-S235JRG2"
		}
		,A2V00311500739 : {
			de: "FL DIN1017-90x10-S355JO",
			en: "FL DIN1017-90x10-S355JO"
		}
		,A2V00311500828 : {
			de: "FL DIN1017-60x10-S355JO",
			en: "FL DIN1017-60x10-S355JO"
		}
		,A2V00316700267 : {
			de: "HP DIN59411-80X80X4-ST52-3        22",
			en: "HP DIN59411-80X80X4-ST52-3        22"
		}
		,A2V00316700319 : {
			de: "RO DIN2395-B-40X40X4-S235JRG2",
			en: "RO DIN2395-B-40X40X4-S235JRG2"
		}
		,A2V00316700447 : {
			de: "HP DIN59410-150x100x8-ST52-3      22",
			en: "HP DIN59410-150x100x8-ST52-3      22"
		}
		,A2V00001715180 : {
			de: "Schneeraeumer geschweisst",
			en: "Snow clearing machine welded"
		}
		,A2V00370006452 : {
			de: "HP DIN59410-250x150x10-ST52-3     22",
			en: "HP DIN59410-250x150x10-ST52-3     22"
		}
		,A2V00370018178 : {
			de: "HP DIN59411-60x40x4-ST52-3        31B",
			en: "HP DIN59411-60x40x4-ST52-3        31B"
		}
		,A2V00370024792 : {
			de: "HP DIN59410-100X60X8-ST52-3",
			en: "HP DIN59410-100X60X8-ST52-3"
		}
		,A2V00370026891 : {
			de: "4KT DIN1014-30-S355JO",
			en: "SQUARE DIN1014-30-S355JO"
		}
		,A2V00370031160 : {
			de: "HP DIN59410-200X100X12,5-ST52-3   31B",
			en: "HP DIN59410-200X100X12,5-ST52-3   31B"
		}
		,A2V00370032801 : {
			de: "HFRHS EN10210-100x60x5,6-S355J2H",
			en: "HFRHS EN10210-100x60x5,6-S355J2H"
		}
		,A2V00001035452 : {
			de: "FahrmotorlÃ¼fter EMU Taiwan",
			en: "Traction motor blower"
		}
		,A2V00397164639 : {
			de: "Federzentrierung",
			en: "Spring centring device"
		}
		,A2V00001063715 : {
			de: "Seitenscheibe links",
			en: "Side window left"
		}
		,A2V00001220451 : {
			de: "BUGSCHEIBE",
			en: "FRONTPANE"
		}
		,A2V00370038992 : {
			de: "HP DIN59410-200x100x8-ST52-3      31B",
			en: "HP DIN59410-200x100x8-ST52-3      31B"
		}
		,A2V00370040170 : {
			de: "Duese, linke",
			en: "Nozzle, left"
		}
		,A2V00370060520 : {
			de: "U DIN1026-100-S355J2G3",
			en: "U DIN1026-100-S355J2G3"
		}
		,A2V00000216274 : {
			de: "TRENNDOSE, 12-POL. PG 16/21",
			en: "seperating outlet, 12-polePG 16/21"
		}
		,A2V00002049098 : {
			de: "Rad-Greifer 120-140 fÃ¼r RÃ¤der mit RBS",
			en: "wheel lifting equipment 120-140 with WBD"
		}
		,A2V00397008440 : {
			de: "Rohrhalter gerade",
			en: "Pipe Holder straight"
		}
		,A2V00397100051 : {
			de: "Kopftraegerrohr",
			en: "Head beam tube"
		}
		,A2V00397100576 : {
			de: "Rohr",
			en: "Pipe"
		}
		,A2V00397100746 : {
			de: "Verschlussblech",
			en: "Closing plate"
		}
		,A2V00397100785 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00397100788 : {
			de: "Gurt, w.g.",
			en: "Flange, as drawn"
		}
		,A2V00397100789 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397100792 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397101398 : {
			de: "Steg-Langtraeger",
			en: "Web-longitudinal beam"
		}
		,A2V00397104500 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397106747 : {
			de: "Stegblech, innen",
			en: "web, inside"
		}
		,A2V00397106763 : {
			de: "Versteifungsblech",
			en: "Stiffening plate"
		}
		,A2V00397109847 : {
			de: "Stegblech",
			en: "Web plate"
		}
		,A2V00397113562 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397113566 : {
			de: "Befestigungsbuegel",
			en: "Mounting clamp"
		}
		,A2V00397115067 : {
			de: "Kranoese",
			en: "Lifting lug"
		}
		,A2V00397121199 : {
			de: "Distanzhalter",
			en: "Distance support"
		}
		,A2V00397121440 : {
			de: "Buegel",
			en: "Clamp"
		}
		,A2V00397121441 : {
			de: "Buegel",
			en: "Clamp"
		}
		,A2V00397121467 : {
			de: "Winkel f. Erdungsband",
			en: "Angle f. grounding strip"
		}
		,A2V00397122550 : {
			de: "Buegel",
			en: "Clamp"
		}
		,A2V00112518923 : {
			de: "HALTER VOLLST.",
			en: "HALTER VOLLST."
		}
		,A2V00397126283 : {
			de: "Beilage 2mm",
			en: "Shim 2mm"
		}
		,A2V00001343389 : {
			de: "Eckverbinder aussen links 0,25",
			en: "Corner connector, outside left 0,25"
		}
		,A2V00397126285 : {
			de: "Beilage 1mm",
			en: "Shim 1mm"
		}
		,A2V00397128601 : {
			de: "Anschlagplatte",
			en: "Stop plate"
		}
		,A2V00397128602 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00397128658 : {
			de: "Lasche",
			en: "Metal strip"
		}
		,A2V00397128700 : {
			de: "Halter w.g.",
			en: "Holder a.d."
		}
		,A2V00397128702 : {
			de: "Halter sp.g.",
			en: "Holder o.a.d."
		}
		,A2V00397128716 : {
			de: "Sicherungsblech",
			en: "Locking plate"
		}
		,A2V00397128733 : {
			de: "Beilage 4mm",
			en: "Shim 4mm"
		}
		,A2V00397128734 : {
			de: "Beilage 3mm",
			en: "Shim 3mm"
		}
		,A2V00397161709 : {
			de: "LAGERBOCK M.EINGEPRESS.BUCHSE W.G.",
			en: "bearing  block  w. press in bush a.d."
		}
		,A2V00397161710 : {
			de: "LAGERBOCK M.EINGEPRESS.BUCHSE SP.G.",
			en: "bearing  block  w. press in bush o.a.d."
		}
		,A2V00397134529 : {
			de: "Joch - Montagesicherung",
			en: "Yoke - safety plate"
		}
		,A2V00397134777 : {
			de: "Sicherungsbolzen",
			en: "Safety bolt"
		}
		,A2V00397144444 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397147202 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397147203 : {
			de: "Sicherungsblech",
			en: "locking plate"
		}
		,A2V00397147515 : {
			de: "Konsole f. Antenne",
			en: "Bracket f. antenna"
		}
		,A2V00397150884 : {
			de: "BEILAGSCHEIBE 8MM",
			en: "SECONDARY SUSPENSION SHIM (8MM)"
		}
		,A2V00397150936 : {
			de: "Beilagscheibe 4mm",
			en: "Primary suspension shim 4mm"
		}
		,A2V00397150937 : {
			de: "Beilagscheibe 8mm",
			en: "Primary suspension shim 8mm"
		}
		,A2V00397150938 : {
			de: "Beilagscheibe 10mm",
			en: "Pprimary suspension shim 10mm"
		}
		,A2V00397150958 : {
			de: "Zentrierbuchse",
			en: "Centering bush"
		}
		,A2V00397150959 : {
			de: "Abhebesicherung",
			en: "Lift lock"
		}
		,A2V00397151021 : {
			de: "Lasche f. Motornotfang",
			en: "Safety bar f. motor"
		}
		,A2V00397151024 : {
			de: "Lasche f. Getriebenotfang",
			en: "Safety bar f. gear box"
		}
		,A2V00397151151 : {
			de: "Stegblech WG",
			en: "Web plate AD"
		}
		,A2V00397151152 : {
			de: "Stegblech SPG",
			en: "Web plate OAD"
		}
		,A2V00397151154 : {
			de: "DAEMPFERPLATTE",
			en: "DAMPER PLATE"
		}
		,A2V00397151174 : {
			de: "Obergurt WG",
			en: "Top flange AD"
		}
		,A2V00397151180 : {
			de: "Konsolenblech",
			en: "Bracket plate"
		}
		,A2V00397151185 : {
			de: "Obergurt SPG",
			en: "Top flange OAD"
		}
		,A2V00397151188 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397151271 : {
			de: "Quertraegerrohr",
			en: "Tube"
		}
		,A2V00397151276 : {
			de: "Gurt",
			en: "Plate"
		}
		,A2V00397151292 : {
			de: "Halter WG",
			en: "Bracket AD"
		}
		,A2V00397153373 : {
			de: "Konsole f. Antenne",
			en: "Bracket f. antenna"
		}
		,A2V00397153382 : {
			de: "Zahnplatte",
			en: "Toothed plate"
		}
		,A2V00397153383 : {
			de: "Traeger, w.g.",
			en: "Support, a.d."
		}
		,A2V00397153384 : {
			de: "Traeger, sp.g.",
			en: "Support, o.a.d."
		}
		,A2V00397153387 : {
			de: "Traverse",
			en: "Center part"
		}
		,A2V00397153388 : {
			de: "Versteifung",
			en: "Stiffening"
		}
		,A2V00397153389 : {
			de: "Versteifung",
			en: "Stiffening"
		}
		,A2V00397153394 : {
			de: "Traverse",
			en: "Center part"
		}
		,A2V00397153395 : {
			de: "Versteifung",
			en: "Stiffening"
		}
		,A2V00001445331 : {
			de: "Versteifung",
			en: "Stiffening"
		}
		,A2V00001445329 : {
			de: "Auflage",
			en: "Support"
		}
		,A2V00001445328 : {
			de: "Auflage",
			en: "Support"
		}
		,A2V00001445327 : {
			de: "Rippe",
			en: "Rip"
		}
		,A2V00001445326 : {
			de: "Versteifung",
			en: "Stiffening"
		}
		,A2V00001445324 : {
			de: "Rippe",
			en: "Rip"
		}
		,A2V00001445317 : {
			de: "Auflage",
			en: "Support"
		}
		,A2V00001445316 : {
			de: "Rippe",
			en: "Rip"
		}
		,A2V00001445095 : {
			de: "Auflage",
			en: "Support"
		}
		,A2V00397153396 : {
			de: "L-Profil, rechts",
			en: "L-Profile, right"
		}
		,A2V00397153397 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397153398 : {
			de: "L-Profil, links",
			en: "L-Profile, left"
		}
		,A2V00397153492 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397153495 : {
			de: "Lagerblech",
			en: "Bearing plate"
		}
		,A2V00397153726 : {
			de: "Distanzblech",
			en: "Distance plate"
		}
		,A2V00397153729 : {
			de: "Distanzblech",
			en: "Distance plate"
		}
		,A2V00397153732 : {
			de: "Distanzblech",
			en: "Distance plate"
		}
		,A2V00397153733 : {
			de: "Distanzblech",
			en: "Distance plate"
		}
		,A2V00397153753 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397154380 : {
			de: "Stegblech WG",
			en: "Web plate AD"
		}
		,A2V00397154383 : {
			de: "Stegblech WG",
			en: "Web plate AD"
		}
		,A2V00397154384 : {
			de: "Stegblech SPG",
			en: "Web plate OAD"
		}
		,A2V00397154385 : {
			de: "Stegblech SPG",
			en: "Web plate OAD"
		}
		,A2V00397154581 : {
			de: "Distanzblech",
			en: "Distance plate"
		}
		,A2V00397154845 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397155439 : {
			de: "Halteblech",
			en: "Support plate"
		}
		,A2V00397156138 : {
			de: "Buegel",
			en: "Clamp"
		}
		,A2V00397156139 : {
			de: "Buegel",
			en: "Clamp"
		}
		,A2V00397156148 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00397156312 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397156313 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397156314 : {
			de: "Rippe",
			en: "rib"
		}
		,A2V00397156318 : {
			de: "Halter SPG",
			en: "Bracket OAD"
		}
		,A2V00001198577 : {
			de: "DOPPELECKVERBINDER, breit",
			en: "Double leak connector, broad"
		}
		,A2V00397158115 : {
			de: "Lagerblech",
			en: "Bearing plate"
		}
		,A2V00397161262 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00397161263 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00397161264 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00397162293 : {
			de: "Scheibe 150x3",
			en: "Washer 150x3-S355J2G3C"
		}
		,A2V00397162801 : {
			de: "ROHRHALTER,GERADER",
			en: "PIPE SUPPORT STRAIGHT"
		}
		,A2V00397162976 : {
			de: "Buegel",
			en: "Clamp"
		}
		,A2V00397163342 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00397163974 : {
			de: "Anschlagblech",
			en: "posting sheet metal"
		}
		,A2V00397163981 : {
			de: "Winkelblech",
			en: "Angle plate"
		}
		,A2V00397282668 : {
			de: "Verbindungslasche fuer Radsatzanlenker",
			en: "Connection Ear for Wheelset Guiding"
		}
		,A2V00397164646 : {
			de: "Meszmarke",
			en: "gauge mark"
		}
		,A2V00397164649 : {
			de: "Blech",
			en: "Plate"
		}
		,A2V00397164650 : {
			de: "Quersteg",
			en: "cross  web"
		}
		,A2V00397164655 : {
			de: "Untergurt, Mitte",
			en: "Lower flange, middle"
		}
		,A2V00397164656 : {
			de: "Untergurt, vord.",
			en: "Lower flange, front"
		}
		,A2V00397164657 : {
			de: "Untergurt, hint.",
			en: "Lower flange, rear"
		}
		,A2V00397164658 : {
			de: "Steg, aussen",
			en: "Web, external"
		}
		,A2V00397164659 : {
			de: "Steg, innen",
			en: "Web, internal"
		}
		,A2V00397164660 : {
			de: "Mittelrippe",
			en: "Middle rib"
		}
		,A2V00397164661 : {
			de: "Querrippe",
			en: "Rib"
		}
		,A2V00397164662 : {
			de: "Quersteg, vorne",
			en: "Web, ifront"
		}
		,A2V00397164663 : {
			de: "Quersteg, hinten",
			en: "Web, back"
		}
		,A2V00397164665 : {
			de: "Rippe fuer Lenkeranschluss",
			en: "Rib for guide rod connection"
		}
		,A2V00397164666 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397164667 : {
			de: "Rippe fuer Primaerfeder",
			en: "Rib for primary spring"
		}
		,A2V00397164668 : {
			de: "Rippe fuer Primaerdaempfer",
			en: "Rib for primary damper"
		}
		,A2V00397164669 : {
			de: "Versteifung, innen",
			en: "Stiffener, inside"
		}
		,A2V00397164670 : {
			de: "Laengsrippe",
			en: "longitudinal rip"
		}
		,A2V00397164672 : {
			de: "Obergurt, Mitte",
			en: "Upper flange, middle"
		}
		,A2V00397164673 : {
			de: "Obergurt, vord.",
			en: "Upper flange, front"
		}
		,A2V00397164674 : {
			de: "Obergurt, hint.",
			en: "Upper flange, rear"
		}
		,A2V00397164676 : {
			de: "Rippe fuer Drehdaempfer",
			en: "Web for rotational damper"
		}
		,A2V00397164677 : {
			de: "Platte f. Drehdaempfer",
			en: "Plate for rotary damper"
		}
		,A2V00397164678 : {
			de: "Anschluss,hint. li. fuer Primaerfeder",
			en: "Connection left for primary spring"
		}
		,A2V00002156414 : {
			de: "Crashmodul 2550 geschw.",
			en: "Crash module 2550 welded"
		}
		,A2V00397164682 : {
			de: "Auflage f. Primaerfeder",
			en: "primary spring layer"
		}
		,A2V00397164685 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397164687 : {
			de: "Blech",
			en: "Plate"
		}
		,A2V00397164688 : {
			de: "Versteifung, aussen",
			en: "Stiffener, external"
		}
		,A2V00397164694 : {
			de: "Stegblech, hinten",
			en: "Web plate, back"
		}
		,A2V00397164695 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397164697 : {
			de: "Daempferrippe",
			en: "Damper rib"
		}
		,A2V00397164698 : {
			de: "Stegblech, vorne",
			en: "Web plate, front"
		}
		,A2V00397164699 : {
			de: "Notfangrippe",
			en: "Emergency arrestor"
		}
		,A2V00397164700 : {
			de: "Querversteifung",
			en: "Cross stiffener"
		}
		,A2V00397164701 : {
			de: "Querversteifung",
			en: "Cross stiffener"
		}
		,A2V00397164702 : {
			de: "Versteifung",
			en: "Stiffening"
		}
		,A2V00397164703 : {
			de: "Eckblech",
			en: "Corner sheet"
		}
		,A2V00397164704 : {
			de: "Eckblech",
			en: "Corner sheet"
		}
		,A2V00397164705 : {
			de: "Versteifung",
			en: "Stiffening"
		}
		,A2V00397164708 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00001814229 : {
			de: "Langtraeger",
			en: "longitudinal girder"
		}
		,A2V00001814237 : {
			de: "Langtraeger",
			en: "longitudinal girder"
		}
		,A2V00001814330 : {
			de: "Kopfquertraeger, vollst.",
			en: "Cross bar, cpl."
		}
		,A2V00001814729 : {
			de: "Drehzapfenquertraeger",
			en: "Bogie pin cross-bearer"
		}
		,A2V00002448797 : {
			de: "Klapprampe, manuell",
			en: "Ramp, manual"
		}
		,A2V00397164710 : {
			de: "U-Blech",
			en: "U-plate"
		}
		,A2V00397164711 : {
			de: "Verstaerkung",
			en: "Stiffener"
		}
		,A2V00397164712 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397164713 : {
			de: "Verstaerkung",
			en: "Stiffener"
		}
		,A2V00397164715 : {
			de: "Pendelauflage",
			en: "Support plate"
		}
		,A2V00397164716 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00397164717 : {
			de: "Stegblech",
			en: "Websheet"
		}
		,A2V00397164718 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397164721 : {
			de: "Stegblech",
			en: "Web plate"
		}
		,A2V00397164722 : {
			de: "Deckblech",
			en: "Coverplate"
		}
		,A2V00397164723 : {
			de: "Obergurt",
			en: "Upper flange"
		}
		,A2V00397164724 : {
			de: "Stegblech, hinten",
			en: "Websheet, back"
		}
		,A2V00397164729 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397164730 : {
			de: "Stegblech",
			en: "Websheet"
		}
		,A2V00397164731 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397164732 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397164733 : {
			de: "Rippe",
			en: "Web"
		}
		,A2V00397164734 : {
			de: "Stegblech",
			en: "Websheet"
		}
		,A2V00397164735 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397164736 : {
			de: "Stegblech",
			en: "Websheet"
		}
		,A2V00397164740 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397164741 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397164742 : {
			de: "Deckblech",
			en: "Coverplate"
		}
		,A2V00397165219 : {
			de: "Schweissbadsicherung",
			en: "Backing strip"
		}
		,A2V00397165223 : {
			de: "Deckblech",
			en: "Coverplate"
		}
		,A2V00397165242 : {
			de: "Schweissbadsicherung",
			en: "Backing strip"
		}
		,A2V00397165799 : {
			de: "Federfuehrung",
			en: "Sring guide"
		}
		,A2V00397165800 : {
			de: "Federunterlage",
			en: "Spring pad"
		}
		,A2V00397165801 : {
			de: "Federunterlage",
			en: "Spring pad"
		}
		,A2V00397165802 : {
			de: "Federunterlage",
			en: "Spring pad"
		}
		,A2V00397165803 : {
			de: "Federunterlage",
			en: "Spring pad"
		}
		,A2V00397165804 : {
			de: "Federunterlage",
			en: "Spring pad"
		}
		,A2V00397165805 : {
			de: "Federunterlage",
			en: "Spring pad"
		}
		,A2V00397171365 : {
			de: "Winkel",
			en: "Angel"
		}
		,A2V00397172932 : {
			de: "Schweissbadsicherung",
			en: "Backing strip"
		}
		,A2V00002719354 : {
			de: "Laengstraeger aussen",
			en: "Longitudinal Member Outer"
		}
		,A2V00002719355 : {
			de: "Laengstraeger innen 1 WG",
			en: "Longitudinal Member Inner 1 AD"
		}
		,A2V00002719636 : {
			de: "Laengstraeger innen 2 SPG",
			en: "Longitudinal Member Inner 2 OAD"
		}
		,A2V00002719637 : {
			de: "Quertraeger 1 WG",
			en: "Cross Member 1 AD"
		}
		,A2V00002719640 : {
			de: "Quertraeger 2 SPG",
			en: "Cross Member 2 OAD"
		}
		,A2V00002719641 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00002719642 : {
			de: "Schlossquertraeger",
			en: "Cross Member Latch"
		}
		,A2V00002719643 : {
			de: "Querrippe",
			en: "Cross Rib"
		}
		,A2V00002719644 : {
			de: "Anbindung Querrippe",
			en: "Connection Cross Rib"
		}
		,A2V00002719645 : {
			de: "Scharnierkonsole",
			en: "Hinge Console"
		}
		,A2V00002719666 : {
			de: "Laengstraeger mitte",
			en: "Longitudinal Member Middel"
		}
		,A2V00002719667 : {
			de: "Grundplatte",
			en: "Main Plate"
		}
		,A2V00002719690 : {
			de: "Grundplatte unten",
			en: "Main Plate Lower"
		}
		,A2V00002719692 : {
			de: "Grundplatte oben",
			en: "Main Plate Upper"
		}
		,A2V00002719693 : {
			de: "Distanzring",
			en: "Spacer"
		}
		,A2V00002719694 : {
			de: "Gewindeplatte",
			en: "Threaded Plate"
		}
		,A2V00002720098 : {
			de: "Scharnier",
			en: "Side Panel Hinge"
		}
		,A2V00002720099 : {
			de: "Distanzring",
			en: "Spacer"
		}
		,A2V00002720100 : {
			de: "Seitenblende",
			en: "Side Panel"
		}
		,A2V00002720103 : {
			de: "Unterbodenfahrschutz Grundplatte",
			en: "Underrun Protection Main Plate"
		}
		,A2V00002720104 : {
			de: "Seitenblech links WG",
			en: "Side Panel LH AD"
		}
		,A2V00002720105 : {
			de: "Seitenblech rechts SPG",
			en: "Side Panel RH OAD"
		}
		,A2V00002720109 : {
			de: "U-Profil Quertraeger vorne groÃŸ",
			en: "U-Profile Cross Member Front Large"
		}
		,A2V00002720110 : {
			de: "LDG U-Profil Quertraeger vorne klein",
			en: "LDG U-Profile Cross Member Front Small"
		}
		,A2V00002720111 : {
			de: "LDG U-Profil Quertraeger vorne",
			en: "LDG U-Profile Cross Member Front"
		}
		,A2V00002720113 : {
			de: "LDG Quertraeger mitte",
			en: "LDG Cross Member Middle"
		}
		,A2V00002720114 : {
			de: "LDG Schottblech Quertraeger mitte",
			en: "LDG Rib Cross Member Middle"
		}
		,A2V00002720115 : {
			de: "LDG Quertraeger hinten",
			en: "LDG Cross Member Rear"
		}
		,A2V00002720116 : {
			de: "LDG Laengstraeger vorne aussen links WG",
			en: "LDG Longitud. Member Front OuterLH AD"
		}
		,A2V00002720117 : {
			de: "LDG Laengstraeger vorn aussen recht SPG",
			en: "LDG Longitud.Member Front OuterRH OAD"
		}
		,A2V00002720118 : {
			de: "LDG Laengstraeger vorne innen links WG",
			en: "LDG Longitud. Member Front Inner LH AD"
		}
		,A2V00002720119 : {
			de: "LDG Laengstr. vorn innen rechts  SPG",
			en: "LDG Longitud. Member Front Inner RH OAD"
		}
		,A2V00002720120 : {
			de: "LDG Laengstraeger hinten",
			en: "LDG Longitudinal Member Rear"
		}
		,A2V00002720121 : {
			de: "LDG Verstaerkungsrippe Quertraeger mitte",
			en: "LDG Reinforcement Rib Cross Member Middl"
		}
		,A2V00002720122 : {
			de: "LDG Stirnblech Rippe",
			en: "LDG Front Plate Rib"
		}
		,A2V00002720124 : {
			de: "LDG Verstaerkungswinkel hinten links WG",
			en: "LDG Reinforcement Rear LH AD"
		}
		,A2V00002720125 : {
			de: "LDG Verstaerkungswinkel Rippe",
			en: "LDG Reinforcement Rib"
		}
		,A2V00002720127 : {
			de: "LDG Verstaerkungwinkel hinten rechts SPG",
			en: "LDG Reinforcement Rear RH OAD"
		}
		,A2V00002720129 : {
			de: "LDG Verstaerkungswinkel vorne links WG",
			en: "LDG Reinforcement Front LH AD"
		}
		,A2V00002720131 : {
			de: "LDG Verstaerkungswinkel vorne rechts SPG",
			en: "LDG Reinforcement Front RH OAD"
		}
		,A2V00002720132 : {
			de: "LDG Stirnblech",
			en: "LDG Front Plate"
		}
		,A2V00002720133 : {
			de: "Lagerkonsole links WG",
			en: "Suspension LH AD"
		}
		,A2V00002720134 : {
			de: "Lagerkonsole rechts SPG",
			en: "Suspension RH OAD"
		}
		,A2V00002720135 : {
			de: "Huelse Lager",
			en: "Shell Suspension"
		}
		,A2V00002720139 : {
			de: "TDG Unterbodenfahrschutz Grundplatte",
			en: "TDG Underrun Protection Main Plate"
		}
		,A2V00002720140 : {
			de: "TDG Seitenblech links",
			en: "TDG Side Panel LH"
		}
		,A2V00002720141 : {
			de: "TDG Seitenblech rechts",
			en: "TDG Side Panel RH"
		}
		,A2V00002720144 : {
			de: "TDG U-Profil Quertraeger vorne",
			en: "TDG U-Profile Cross Member Front"
		}
		,A2V00002720145 : {
			de: "TDG U-Profil Quertraeger vorne links",
			en: "TDG U-Profile Cross Member Front LH"
		}
		,A2V00002720146 : {
			de: "TDG U-Profil Quertraeger vorne rechts",
			en: "TDG U-Profile Cross Member Front RH"
		}
		,A2V00002720147 : {
			de: "TDG Laengstraeger vorne aussen links WG",
			en: "TDG Longitud. Member Front Outer LH AD"
		}
		,A2V00002720148 : {
			de: "TDG Laengstr. vorne aussen rechts SPG",
			en: "TDG Longitud. Member Front Outer RH OAD"
		}
		,A2V00002720149 : {
			de: "TDG Verstaerkungswinkel vorne",
			en: "TDG Reinforcement Front"
		}
		,A2V00002720151 : {
			de: "TDG Quertraeger mitte",
			en: "TDG Cross Member Middle"
		}
		,A2V00002720152 : {
			de: "TDG Schottblech Quertraeger mitte",
			en: "TDG Rib Cross Member Middle"
		}
		,A2V00002720154 : {
			de: "TDG Quertraeger hinten",
			en: "TDG Cross Member Rear"
		}
		,A2V00002720155 : {
			de: "TDG Schottblech Quertraeger hinten",
			en: "TDG Rib Cross Member Rear"
		}
		,A2V00002720156 : {
			de: "TDG Laengstraeger vorne innen",
			en: "TDG Longitudinal Member Front Inner"
		}
		,A2V00002720168 : {
			de: "TDG Seitenblech Laengstraeger links rech",
			en: "TDG Side Panel Longitudinal Member LH RH"
		}
		,A2V00002720169 : {
			de: "TDG Seitenblech Laengstraeger links link",
			en: "TDG Side Panel Longitudinal Member LH LH"
		}
		,A2V00002720170 : {
			de: "TDG Laengsrippe",
			en: "TDG Longitudinal Rib"
		}
		,A2V00002720173 : {
			de: "TDG Seitenblech Laengstraeger rechts",
			en: "TDG Side Panel Longitudinal Member RH"
		}
		,A2V00002720174 : {
			de: "TDG Verstaerkungswinkel Laengsrippe vorn",
			en: "TDG Reinforcement Longitudinal Rib Front"
		}
		,A2V00002720175 : {
			de: "TDG Verstaerkungswinkel Laengsrippe hint",
			en: "TDG Reinforcement Longitudinal Rib Rear"
		}
		,A2V00397174992 : {
			de: "Messmarke",
			en: "Gauge mark"
		}
		,A2V00397182714 : {
			de: "Fuehrungsbuchse",
			en: "Guide bush"
		}
		,A2V00397182718 : {
			de: "Fuehrungsbuchse",
			en: "Guide bush"
		}
		,A2V00397183151 : {
			de: "Anschlag",
			en: "Stop"
		}
		,A2V00397183157 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00397183158 : {
			de: "Strebe",
			en: "Bracket"
		}
		,A2V00397183160 : {
			de: "Strebe",
			en: "Rod"
		}
		,A2V00397183173 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00397183370 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397183686 : {
			de: "Steg",
			en: "web"
		}
		,A2V00397183726 : {
			de: "Sicherungsblech",
			en: "Lock washer"
		}
		,A2V00397185454 : {
			de: "Balken",
			en: "Girder"
		}
		,A2V00397186387 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397186388 : {
			de: "Flanschplatte",
			en: "Flange plate"
		}
		,A2V00002115073 : {
			de: "Beilage Gr.6-2",
			en: "Shim size 6-2"
		}
		,A2V00002720176 : {
			de: "TDG Verstaerkungswinkel Quertraeger mitt",
			en: "TDG Reinforcement Cross Member Middle"
		}
		,A2V00002720177 : {
			de: "TDG Verstaerkungswinkel Laengstraeger re",
			en: "TDG Reinforcement Longitudinal Member RH"
		}
		,A2V00002720178 : {
			de: "TDG U-Laengstraeger mitte",
			en: "TDG U-Longitudinal Member Middle"
		}
		,A2V00002720179 : {
			de: "TDG U-Profil Quertraeger mitte",
			en: "TDG U-Profile Cross Member Middle"
		}
		,A2V00002720181 : {
			de: "TDG Verstaerkungswinkel hinten links WG",
			en: "TDG Reinforcement Rear LH AD"
		}
		,A2V00002720182 : {
			de: "TDG Verstaerkungswinkel Rippe",
			en: "TDG Reinforcement Rib"
		}
		,A2V00002720184 : {
			de: "TDG Verstaerkungswinkel hint. recht SPG",
			en: "TDG Reinforcement Rear RH OAD"
		}
		,A2V00002720185 : {
			de: "TDG U-Laengstraeger hinten",
			en: "TDG U-Longitudinal Member Rear"
		}
		,A2V00002720186 : {
			de: "TDG Stirnblech",
			en: "TDG Front Plate"
		}
		,A2V00002720187 : {
			de: "TDG Lagerkonsole links WG",
			en: "TDG Suspension LH AD"
		}
		,A2V00002720188 : {
			de: "TDG Lagerkonsole rechts SPG",
			en: "TDG Suspension RH OAD"
		}
		,A2V00002720191 : {
			de: "LDG Notfangschelle Buegel",
			en: "LDG Rescue Bracket"
		}
		,A2V00002720192 : {
			de: "Noftangschelle Rippe",
			en: "Rescue Rib"
		}
		,A2V00002720193 : {
			de: "NFS Gewindeplatte",
			en: "RB Threaded Plate"
		}
		,A2V00002720196 : {
			de: "TDG Notfangschelle Buegel",
			en: "TDG Rescue Bracket"
		}
		,A2V00397186389 : {
			de: "Anbindungsprofil",
			en: "Connection profile"
		}
		,A2V00397186391 : {
			de: "Rippe",
			en: "Web"
		}
		,A2V00397188711 : {
			de: "Anschweiszkopf",
			en: "weld on head"
		}
		,A2V00397188729 : {
			de: "Klemmrohr",
			en: "clamping pipe"
		}
		,A2V00397190730 : {
			de: "Anschlag",
			en: "Stop"
		}
		,A2V00397190861 : {
			de: "Daempferkonsole",
			en: "Damper bracket"
		}
		,A2V00397191107 : {
			de: "Bremsrohr",
			en: "Braking pipe"
		}
		,A2V00397192528 : {
			de: "Schweissbadsicherung 1",
			en: "Backing strip 1"
		}
		,A2V00397196678 : {
			de: "Schweissbadsicherung 2",
			en: "Backing strip 2"
		}
		,A2V00001948369 : {
			de: "Abdeckblech",
			en: "Cover plate"
		}
		,A2V00001933396 : {
			de: "Passfeder",
			en: "Feather key"
		}
		,A2V00397197300 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397198368 : {
			de: "Schutzrohr",
			en: "Tube for protection"
		}
		,A2V00397201526 : {
			de: "Winkel",
			en: "Angle"
		}
		,A2V00397207046 : {
			de: "Winkelblech",
			en: "Angle plate"
		}
		,A2V00397214219 : {
			de: "BLECH LINKS",
			en: "sheet left"
		}
		,A2V00397215043 : {
			de: "BLECH RECHTS",
			en: "sheet right"
		}
		,A2V00397218167 : {
			de: "DECKPLATTE",
			en: "COVERPLATE"
		}
		,A2V00397235455 : {
			de: "BEILAGE 4MM",
			en: "SHIM 4MM"
		}
		,A2V00397245648 : {
			de: "Konsole f. Spritzschutz",
			en: "Bracket for protective cover"
		}
		,A2V00397247507 : {
			de: "Klotz f. Haengelasche WG",
			en: "Bracket AD"
		}
		,A2V00397247508 : {
			de: "Klotz f. Haengelasche SPG",
			en: "Bracket OAD"
		}
		,A2V00397247705 : {
			de: "Buchse",
			en: "Bush"
		}
		,A2V00397248181 : {
			de: "Distanzhuelse",
			en: "Distance sleeve"
		}
		,A2V00397250870 : {
			de: "Halter f. Luftfedersteuerung",
			en: "Holder f. susp. level valve"
		}
		,A2V00397251206 : {
			de: "Untergurt Mittelteil WG",
			en: "Lower flange centre part AD"
		}
		,A2V00397251207 : {
			de: "Untergurt Mittelteil SPG",
			en: "Lower flange centre part OAD"
		}
		,A2V00397251214 : {
			de: "DECKEL",
			en: "CAP"
		}
		,A2V00397251219 : {
			de: "Abhebesicherung",
			en: "Lift lock"
		}
		,A2V00397252367 : {
			de: "LAGERGEHAEUSE",
			en: "BEARING HOUSING"
		}
		,A2V00397252372 : {
			de: "LAGERDECKEL",
			en: "BEARING COVER"
		}
		,A2V00397257647 : {
			de: "STEG",
			en: "WEB"
		}
		,A2V00397286214 : {
			de: "Beilage 5mm",
			en: "Shim 5mm"
		}
		,A2V00397288733 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002030490 : {
			de: "Grundbl. Typenschild LDG",
			en: "Base pl. specification TB"
		}
		,A2V00002548002 : {
			de: "Radverschleissbeilage 5mm",
			en: "Shim sec.spring wheel wear"
		}
		,A2V00001673317 : {
			de: "Halter",
			en: "Support"
		}
		,A2V00001437000 : {
			de: "ZD-Stange, Kopf 1",
			en: "PP-Rod, Head 1"
		}
		,A2V00001437001 : {
			de: "ZD-Stange, Kopf 2",
			en: "PP-Rod, Head 2"
		}
		,A2V00001241095 : {
			de: "ERDUNGSLASCHE",
			en: "GRUND POINT"
		}
		,A2V00002621436 : {
			de: "FEY Lamellenring",
			en: "FEY multi plate ring"
		}
		,A2V00001280771 : {
			de: "Abhebesicherung",
			en: "Lift stop"
		}
		,A2V00001894377 : {
			de: "Dehnhuelse M24",
			en: "Anti-fatigue sleeve M24"
		}
		,A2V00001351833 : {
			de: "Abhebesicherung",
			en: "Lift stop"
		}
		,A2V00002688736 : {
			de: "Rippe 2",
			en: "Stiffener 2"
		}
		,A2V00001016612 : {
			de: "LZB-Empfangsantenne 36kHz",
			en: "CATR-receiving aerial 36kHz"
		}
		,A2V00002076750 : {
			de: "Schutzrohr geschweisst",
			en: "Conduit welded"
		}
		,A2V00002076751 : {
			de: "Schutzrohr geschweisst",
			en: "Conduit welded"
		}
		,A2V00002055322 : {
			de: "Beilage 1 mm",
			en: "Shim 1 mm"
		}
		,A2V00002055379 : {
			de: "Beilage 2 mm",
			en: "Shim 2 mm"
		}
		,A2V00002063554 : {
			de: "Beilage 5 mm",
			en: "Shim 5 mm"
		}
		,A2V00002063744 : {
			de: "Beilage 2 mm",
			en: "Shim 2 mm"
		}
		,A2V00002064368 : {
			de: "Beilage 1 mm",
			en: "Shim 1 mm"
		}
		,A2V00002064376 : {
			de: "Beilage 5 mm",
			en: "Shim 5 mm"
		}
		,A2V00002067194 : {
			de: "V  Platte pneu. Blockzylinder",
			en: "plate for pneu. cylinder"
		}
		,A2V00001016644 : {
			de: "LZB-Sendeantenne 56kHz",
			en: "CATR-transmitting aerial 56kHz"
		}
		,A2V00002065082 : {
			de: "Bremskonsole",
			en: "Break bracket"
		}
		,A2V00002108287 : {
			de: "Blech",
			en: "plate"
		}
		,A2V00002698341 : {
			de: "Blech 1",
			en: "Plate 1"
		}
		,A2V00002108527 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002700325 : {
			de: "Halter 4",
			en: "Holder 4"
		}
		,A2V00002700866 : {
			de: "Halter 15",
			en: "Holder 15"
		}
		,A2V00001429378 : {
			de: "Schlingerdaempfer Anbindung SPG",
			en: "Lurch damper connection OAD"
		}
		,A2V00001429379 : {
			de: "Schlingerdaempfer Anbindung WG",
			en: "Lurch damper connection AD"
		}
		,A2V00001434024 : {
			de: "Anschluss Joch",
			en: "Connection Piling"
		}
		,A2V00001437002 : {
			de: "ZD-Stange, Mitte",
			en: "PP-Rod, Middle"
		}
		,A2V00001438524 : {
			de: "Konsole fuer Sekundaerdaempfer SPG",
			en: "Bracket for secondary damper OAD"
		}
		,A2V00001452478 : {
			de: "Obergurt, Joch",
			en: "Upper Flange, Balancer"
		}
		,A2V00001452479 : {
			de: "Steg, Joch",
			en: "Rip , Balancer"
		}
		,A2V00001452480 : {
			de: "Drehzapfenaufnahme , Joch",
			en: "Center Pin Support, Balancer"
		}
		,A2V00001461578 : {
			de: "Fuehrungsplatte , Joch",
			en: "Girderplate,  Balancer"
		}
		,A2V00001461583 : {
			de: "Oese , Joch",
			en: "Eye,  Balancer"
		}
		,A2V00002548001 : {
			de: "Radverschleissbeilage 10mm",
			en: "Shim sec.spring whell wear 10mm"
		}
		,A2V00002548004 : {
			de: "Stift fuer 5mm Beilage",
			en: "Bolt for 5 mm shim"
		}
		,A2V00002078381 : {
			de: "Klemmblech",
			en: "clamping plate"
		}
		,A2V00002078028 : {
			de: "Dehnhuelse M6x13x20",
			en: "Extension sleeve M6x13x20"
		}
		,A2V00001673105 : {
			de: "Bolzen fuer Stuetzstange",
			en: "Bolt for vertical rod"
		}
		,A2V00001673196 : {
			de: "Buchse fuer Stuetzstange",
			en: "bush for vertical support device"
		}
		,A2V00002172462 : {
			de: "Gewindebolzen",
			en: "Stud bolt"
		}
		,A2V00002339564 : {
			de: "Schellenbeilage 1",
			en: "Clamp washer 1"
		}
		,A2V00002597218 : {
			de: "Versteifung w.g.",
			en: "Stiffener a.d."
		}
		,A2V00002597217 : {
			de: "Versteifung sp.g.",
			en: "Stiffener i.m.i."
		}
		,A2V00002110027 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002109470 : {
			de: "Halter ZSB",
			en: "Bracket assy."
		}
		,A2V00002096138 : {
			de: "Trennsteg",
			en: "Separating strip"
		}
		,A2V00002096136 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002095634 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002095632 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002095631 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002095630 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002095617 : {
			de: "Befestigungsbuegel",
			en: "Fastening hanger"
		}
		,A2V00002137024 : {
			de: "Buegel vollst.",
			en: "Holder compl."
		}
		,A2V00002073330 : {
			de: "Sockel geschw.",
			en: "Base welded"
		}
		,A2V00002734138 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002426143 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00001758479 : {
			de: "Ef: (RS) Buchse",
			en: "Sp: (WS) Bushing"
		}
		,A2V00002088670 : {
			de: "Langtraeger UG, links",
			en: "Longitudinal beam UF, left"
		}
		,A2V00002088669 : {
			de: "Langtraeger UG, rechts",
			en: "Longitudinal beam UF, right"
		}
		,A2V00002087651 : {
			de: "Querprofil WE1 sp.g.",
			en: "Cross profile CE1 i.m.i."
		}
		,A2V00002087181 : {
			de: "Bodenplatte WE1 w.g.",
			en: "Floor plate CE1 a.d."
		}
		,A2V00002087112 : {
			de: "Bodenplatte WE1 sp.g.",
			en: "Floor plate CE1 i.m.i."
		}
		,A2V00002083085 : {
			de: "Gurtblech I-Profil",
			en: "Flangeplate I-profile"
		}
		,A2V00002082683 : {
			de: "Querprofil WE1 w.g.",
			en: "Cross profile CE1 a.d."
		}
		,A2V00002080819 : {
			de: "Buegel vollst.",
			en: "Holder compl."
		}
		,A2V00002047233 : {
			de: "Zwischenprofil",
			en: "Intermediate Profile"
		}
		,A2V00002095348 : {
			de: "Durchfuehrung",
			en: "Duct"
		}
		,A2V00002090334 : {
			de: "Verzurrlasche",
			en: "Lash plate"
		}
		,A2V00002086038 : {
			de: "Versteifung geschw. sp.g.",
			en: "Reinforcement welded i.m.i."
		}
		,A2V00002086037 : {
			de: "Versteifung geschw. w.g.",
			en: "Reinforcement welded a.d."
		}
		,A2V00002086016 : {
			de: "Versteifung geschw. sp.g.",
			en: "Reinforcement welded i.m.i."
		}
		,A2V00002085783 : {
			de: "Versteifung geschw. w.g.",
			en: "Reinforcement welded a.d."
		}
		,A2V00002084193 : {
			de: "Unterzug Pult ZSB sp.g.",
			en: "Trussing console assy. i.m.i."
		}
		,A2V00002084192 : {
			de: "Unterzug Pult ZSB w.g.",
			en: "Trussing console assy. a.d."
		}
		,A2V00002083452 : {
			de: "Deckblech sp.g.",
			en: "Cover plate i.m.i."
		}
		,A2V00002083449 : {
			de: "Deckblech w.g.",
			en: "Cover plate a.d."
		}
		,A2V00001813924 : {
			de: "Messingbuchse",
			en: "Sleeve"
		}
		,A2V00001841426 : {
			de: "Schutzwinkel",
			en: "Protection angel"
		}
		,A2V00001862724 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheetpiece for Work samples"
		}
		,A2V00002560418 : {
			de: "Energieverzehrelement",
			en: "Absorbtion element"
		}
		,A2V00002165112 : {
			de: "Schweissbadsicherung",
			en: "Weld pool protection"
		}
		,A2V00002117730 : {
			de: "Deckenquertr. 2",
			en: "Transverse beam 2"
		}
		,A2V00001983324 : {
			de: "Deckenquertraeger 1 bearb.",
			en: "Ceiling cross beam 1 mach."
		}
		,A2V00002534830 : {
			de: "Konsole 2 F-Schrank",
			en: "Bracket 2 d-cabinet"
		}
		,A2V00002542790 : {
			de: "Rippe sp.g.",
			en: "Rib i.m.i."
		}
		,A2V00002497847 : {
			de: "Tuersaeule 1, vollst. sp.g.",
			en: "Door post 1, compl. i.m.i."
		}
		,A2V00002497855 : {
			de: "Tuersaeule 1, vollst. w.g.",
			en: "Door post 1, compl. a.d."
		}
		,A2V00002611149 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002611148 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002611146 : {
			de: "Halter ZSB",
			en: "Bracket ASSY"
		}
		,A2V00002591750 : {
			de: "Plattform f. Blitzschutz",
			en: "Base f. surge arrestor"
		}
		,A2V00002591749 : {
			de: "Plattform f. Handkurbelbedienung",
			en: "Base f. handle operation"
		}
		,A2V00002547370 : {
			de: "Abfluss",
			en: "Drainage"
		}
		,A2V00002542806 : {
			de: "Durchfuehrung",
			en: "Sleeve"
		}
		,A2V00002495717 : {
			de: "Schweissprobe 1Steg 55Grad",
			en: "Weld test part 1bar 55degree"
		}
		,A2V00002495883 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002495884 : {
			de: "Profil fuer Arbeitsproben",
			en: "Profile for work samples"
		}
		,A2V00002496068 : {
			de: "Schweissprobe 4Steg 35Grad",
			en: "Weld test part 4bar 35degree"
		}
		,A2V00002496069 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002496412 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002496413 : {
			de: "Schweissprobe 1Steg 55Grad",
			en: "Weld test part 1bar 55degree"
		}
		,A2V00002496511 : {
			de: "Profil fuer Arbeitsproben",
			en: "Profile for work samples"
		}
		,A2V00002496549 : {
			de: "Profil fuer Arbeitsproben",
			en: "Profile for work samples"
		}
		,A2V00002497271 : {
			de: "Profil fuer AP",
			en: "Profile for WS"
		}
		,A2V00002497919 : {
			de: "Profil fuer AP",
			en: "Profile for WS"
		}
		,A2V00002498088 : {
			de: "Schweissprobe 1Steg 55Grad",
			en: "Weld test part 1bar 55degree"
		}
		,A2V00002498095 : {
			de: "Schweissprobe 2Steg 55Grad",
			en: "Weld test part 2bar 55degree"
		}
		,A2V00002498151 : {
			de: "Blechteil fuer AP",
			en: "Sheet piece for WS"
		}
		,A2V00002498152 : {
			de: "Schweissprobe 1Steg 50Grad",
			en: "Weld test part 1bar 50degree"
		}
		,A2V00002498185 : {
			de: "Profil fuer AP",
			en: "Profile for WS"
		}
		,A2V00002498197 : {
			de: "Blechteil fuer AP",
			en: "Sheet piece for WS"
		}
		,A2V00002498367 : {
			de: "Schweissprobe 1Steg 40Grad",
			en: "Weld test part 1bar 40degree"
		}
		,A2V00002498368 : {
			de: "Profil fuer AP",
			en: "Profile for WS"
		}
		,A2V00002498524 : {
			de: "Profil fuer AP",
			en: "Profile for WS"
		}
		,A2V00002498617 : {
			de: "Blechteil fuer AP",
			en: "Sheet piece for WS"
		}
		,A2V00002498690 : {
			de: "Blechteil fuer AP",
			en: "Sheet piece for WS"
		}
		,A2V00002498691 : {
			de: "Blechteil fuer AP",
			en: "Sheet piece for WS"
		}
		,A2V00002498951 : {
			de: "Profil fuer AP",
			en: "Profile for WS"
		}
		,A2V00002499022 : {
			de: "Blechteil fuer AP",
			en: "Sheet piece for WS"
		}
		,A2V00002499023 : {
			de: "Profil fuer AP",
			en: "Profile for WS"
		}
		,A2V00002499164 : {
			de: "Blechteil fuer AP",
			en: "Sheet piece for WS"
		}
		,A2V00002499165 : {
			de: "Profil fuer AP",
			en: "Profile for WS"
		}
		,A2V00002083255 : {
			de: "Vorbau KKE",
			en: "Underframe extension SCE"
		}
		,A2V00002082603 : {
			de: "Seitenwand Cabend li.",
			en: "Sidewall cabend le."
		}
		,A2V00002064070 : {
			de: "Vorbau FST",
			en: "Underframe extension cab end"
		}
		,A2V00002084827 : {
			de: "Seitenwand, bearb.",
			en: "Side wall, machined"
		}
		,A2V00002626101 : {
			de: "Untergestell Mittelteil",
			en: "Underframe middle part"
		}
		,A2V00002389368 : {
			de: "Radkasten 2650 sp.g.",
			en: "Wheel case 2650 i.m.i."
		}
		,A2V00002389361 : {
			de: "Radkasten 2650 w.g.",
			en: "Wheel case 2650 a.d."
		}
		,A2V00002389300 : {
			de: "Haupquertraeger vollst.",
			en: "Main cross beam compl."
		}
		,A2V00002389450 : {
			de: "Seitenwandfeld bearb. sp.g.",
			en: "Side wall plate machined i.m.i."
		}
		,A2V00002389453 : {
			de: "Seitenwandfeld bearb. w.g.",
			en: "Side wall plate machined a.d."
		}
		,A2V00002389495 : {
			de: "Gurt sp.g.",
			en: "Belt i.m.i."
		}
		,A2V00002389494 : {
			de: "Gurt w.g.",
			en: "Belt a.d."
		}
		,A2V00002389429 : {
			de: "Versteifungblech",
			en: "Stiffening sheet"
		}
		,A2V00002389423 : {
			de: "Rippe sp.g.",
			en: "Rib i.m.i."
		}
		,A2V00002389422 : {
			de: "Abschlussblech sp.g.",
			en: "Close sheet i.m.i."
		}
		,A2V00002389375 : {
			de: "Gurt sp.g.",
			en: "Belt i.m.i."
		}
		,A2V00002389374 : {
			de: "Gurt w.g.",
			en: "Belt a.d."
		}
		,A2V00002389370 : {
			de: "Kasten vollst.",
			en: "Box compl."
		}
		,A2V00002389343 : {
			de: "Gurt sp.g.",
			en: "Belt i.m.i."
		}
		,A2V00002522751 : {
			de: "Pruefstueck T=10 C=1 W=55Â°",
			en: "Weld test part T=10 C=1 W=55Â°"
		}
		,A2V00002523065 : {
			de: "Pruefstueck T=7 C=0,5 W=35Â°",
			en: "Weld test part T=7 C=0,5 W=35Â°"
		}
		,A2V00002389373 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002546536 : {
			de: "Sickenblech Dach B4-Wagen",
			en: "Corrugated sheet roof B4-car"
		}
		,A2V00002550283 : {
			de: "Sickenblech Dach D4-Wagen",
			en: "Corrugated sheet roof D4-car"
		}
		,A2V00002550632 : {
			de: "Sickenblech Dach C4-Wagen",
			en: "Corrugated sheet roof C4-car"
		}
		,A2V00002537442 : {
			de: "Sickenblech Dach A4-Wagen",
			en: "Corrugated sheet roof A4-car"
		}
		,A2V00002630518 : {
			de: "Seitenwandprofil, geschweisst",
			en: "Sidewall profile, welded"
		}
		,A2V00002630523 : {
			de: "Seitenwandprofil, geschweisst",
			en: "Sidewall profile, welded"
		}
		,A2V00002630570 : {
			de: "Seitenwandprofil, geschweisst",
			en: "Sidewall profile, welded"
		}
		,A2V00002630700 : {
			de: "Seitenwandprofil, geschweisst",
			en: "Sidewall profile, welded"
		}
		,A2V00002542554 : {
			de: "Pult Unterzug sp.g.",
			en: "Console Beam i.m.i."
		}
		,A2V00002542549 : {
			de: "Pult Unterzug w.g.",
			en: "Console Beam a.d."
		}
		,A2V00002582715 : {
			de: "Wippe 1950/AC inkl. WLB",
			en: "Collector head 1950/AC incl. spoiler"
		}
		,A2V00002610938 : {
			de: "Blattfeder mit Schrumpfschlauch",
			en: "Flat spring with shrink hose"
		}
		,A2V00002038839 : {
			de: "Federteller oben",
			en: "Spring base plate top"
		}
		,A2V00002040095 : {
			de: "Federteller unten",
			en: "spring plate below"
		}
		,A2V00002040290 : {
			de: "Grundbeilage",
			en: "basic shim"
		}
		,A2V00002041243 : {
			de: "Adapterplatte",
			en: "Adapter plate"
		}
		,A2V00002445296 : {
			de: "Kabelmodul MWC kompl.",
			en: "Cable module MWC compl. under floor"
		}
		,A2V00002052806 : {
			de: "Zwischenbolzen",
			en: "intermediate pin"
		}
		,A2V00002064455 : {
			de: "Dehnhuelse M20x40x20, lack.",
			en: "Extension sleeve M20x40x20, paint."
		}
		,A2V00002064584 : {
			de: "Dehnhuelse M20x35x25, lack.",
			en: "Extension sleeve M20x35x25, paint."
		}
		,A2V00002066437 : {
			de: "Notfangstab",
			en: "Emergency slat"
		}
		,A2V00002076423 : {
			de: "Schellenkonsole 1",
			en: "Console for clamps 1"
		}
		,A2V00002077412 : {
			de: "Schellenplatte",
			en: "Clamp plate"
		}
		,A2V00002077795 : {
			de: "Beilage 2",
			en: "shim 2"
		}
		,A2V00002077931 : {
			de: "Haltearm",
			en: "retaining bracket"
		}
		,A2V00002077972 : {
			de: "Haltearm",
			en: "retaining bracket"
		}
		,A2V00002078382 : {
			de: "Klemmblech Gr.6",
			en: "clamping plate Size 6"
		}
		,A2V00002033095 : {
			de: "Dach Zusb., MC",
			en: "Roof assy., MC"
		}
		,A2V00002033094 : {
			de: "Untergestell MC",
			en: "Underframe MC"
		}
		,A2V00002560850 : {
			de: "Seitenwand D, bearb.",
			en: "Side wall D, mach."
		}
		,A2V00002560854 : {
			de: "Seitenwand E, bearb.",
			en: "Side wall E, mach."
		}
		,A2V00002607085 : {
			de: "Seitenwand C2, bearb.",
			en: "Sidewall C2, mach."
		}
		,A2V00002606432 : {
			de: "Seitenwand F, bearb.",
			en: "Side wall F, mach."
		}
		,A2V00002606431 : {
			de: "Seitenwand H, bearb.",
			en: "Side wall H, mach."
		}
		,A2V00002606430 : {
			de: "Seitenwand I, bearb.",
			en: "Side wall I, mach."
		}
		,A2V00002606421 : {
			de: "Seitenwand G, bearb.",
			en: "Side wall G, mach."
		}
		,A2V00002560852 : {
			de: "Seitenwand C1, bearb.",
			en: "Side wall C1, mach."
		}
		,A2V00002737926 : {
			de: "Regenrinnenverkleidung vollst.",
			en: "Cover of rain gutter compl."
		}
		,A2V00002540764 : {
			de: "Seitenwand 2",
			en: "side wall 2"
		}
		,A2V00002540341 : {
			de: "Seitenwand 2 WE2 HF geschw.",
			en: "side wall 2 ce2 hf welded"
		}
		,A2V00002540406 : {
			de: "Seitenwand 2 WE1",
			en: "side wall 2 ce1"
		}
		,A2V00002542479 : {
			de: "Seitenwand 1 WE2 HF geschw.",
			en: "side wall 1 ce2 hf welded"
		}
		,A2V00002542826 : {
			de: "Seitenwand 1 WE1 HF geschw.",
			en: "side wall 1 ce1 hf welded"
		}
		,A2V00002540319 : {
			de: "Seitenwand 1",
			en: "side wall 1"
		}
		,A2V00002560853 : {
			de: "Seitenwand B, bearb.",
			en: "Side wall B, mach."
		}
		,A2V00002560851 : {
			de: "Seitenwand A, bearb.",
			en: "Side wall A, mach."
		}
		,A2V00002497265 : {
			de: "Untergestell Mittelteil",
			en: "Underframe middle part"
		}
		,A2V00002605885 : {
			de: "Befestigung f. ATC ZSB w.g.",
			en: "Mounting f. ATC ASSY a.d."
		}
		,A2V00002101814 : {
			de: "Steg w.g.",
			en: "Bar a.d."
		}
		,A2V00002101877 : {
			de: "Winkel w.g.",
			en: "Angle a.d."
		}
		,A2V00002101878 : {
			de: "Rippe w.g.",
			en: "Web a.d."
		}
		,A2V00002101917 : {
			de: "Rippe w.g.",
			en: "Web a.d."
		}
		,A2V00001289607 : {
			de: "SHP-Elektromagnet Zugsicherung",
			en: "SHP-electric magnet f. aut. train prot."
		}
		,A2V00002115074 : {
			de: "Erdungsblech",
			en: "Earthing sheet"
		}
		,A2V00002122213 : {
			de: "V Getriebeadapter Velaro UK",
			en: "J gear box adapter  Velaro UK"
		}
		,A2V00002719408 : {
			de: "Profil 6005A T6 15 FSW",
			en: "Profile 6005A T6 15 FSW"
		}
		,A2V00002719378 : {
			de: "Blech 5083 15 FSW",
			en: "Plate 5083 15 FSW"
		}
		,A2V00002719409 : {
			de: "Blech 5083 H321 15 FSW",
			en: "Plate 5083 H321 15 FSW"
		}
		,A2V00002719411 : {
			de: "Blech 6082 T6 15 FSW",
			en: "Plate 6082 T6 15 FSW"
		}
		,A2V00002719656 : {
			de: "Blech 5083 H321 15 MIG",
			en: "Plate 5083 H321 15 MIG"
		}
		,A2V00002719413 : {
			de: "Blech 6082 T6 MIG",
			en: "Plate 6082 T6 MIG"
		}
		,A2V00002719412 : {
			de: "Blech 5083 15 MIG",
			en: "Plate 5083 15 MIG"
		}
		,A2V00002719407 : {
			de: "Blech 6082 T6 48 FSW",
			en: "Plate 6082 T6 48 FSW"
		}
		,A2V00002719358 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002141700 : {
			de: "Antennenhalter 1",
			en: "Antenna holder 1"
		}
		,A2V00002156621 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002229854 : {
			de: "Sandungsrohr WG",
			en: "Sander pipe AD"
		}
		,A2V00002554958 : {
			de: "Schweissbadsicherung",
			en: "Weld pool backing"
		}
		,A2V00002552524 : {
			de: "Schweissbadsicherung",
			en: "Weld pool backing"
		}
		,A2V00002564052 : {
			de: "C-Schiene Nosta",
			en: "C-Rail Nosta"
		}
		,A2V00002523958 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002377134 : {
			de: "Treibradsatzwelle, lackiert",
			en: "Motor axle, lacquered"
		}
		,A2V00002406707 : {
			de: "Daempfer sekundaer horizontal",
			en: "Secondary lateral shock absorber"
		}
		,A2V00002573016 : {
			de: "Schweissbadsicherung",
			en: "Weld pool backing"
		}
		,A2V00001692939 : {
			de: "Puffer lackiert",
			en: "buffer lacquered"
		}
		,A2V00002232887 : {
			de: "Tuerfluegel rechts, Leuchte",
			en: "Door leaf right light"
		}
		,A2V00002201550 : {
			de: "Tuerfluegel links Beh. Taste, Leuchte",
			en: "Door leaf left HC, light"
		}
		,A2V00002201549 : {
			de: "Tuerfluegel rechts",
			en: "Door leaf right"
		}
		,A2V00113028980 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002696369 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002696487 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002696489 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002696493 : {
			de: "Lamellenring FK6 ASD 150/6/2",
			en: "lamella ring FK6 ASD 150/6/2"
		}
		,A2V00002696495 : {
			de: "Lamellenring FK6 ISD 110/6/3",
			en: "lamella ring FK6 ISD 110/6/3"
		}
		,A2V00001786065 : {
			de: "Aufsteckstromwandler GSOFB 1908 TSI",
			en: "Window type current tran. GSOFB 1908 TSI"
		}
		,A2V00002725671 : {
			de: "Bodenleiste FSW",
			en: "Kick plate FSW"
		}
		,A2V00002725670 : {
			de: "Bodenleiste FSW",
			en: "Kick plate FSW"
		}
		,A2V00002725669 : {
			de: "Bodenleiste FSW",
			en: "Kick plate FSW"
		}
		,A2V00002735808 : {
			de: "Seitenwand mitte 2",
			en: "Sidewall middle 2"
		}
		,A2V00001794936 : {
			de: "Traktionsstromr. SIBAC E28-3000 A.1-1",
			en: "Traction converter SIBAC E28-3000 A.1-1"
		}
		,A2V00001809035 : {
			de: "Hilfsbetriebegeruest vormontiert",
			en: "Auxiliaries frame pre-assembled"
		}
		,A2V00001809958 : {
			de: "Niederspannungsgeruest vormontiert",
			en: "Low voltage cubicle pre-assembled"
		}
		,A2V00001810391 : {
			de: "Geraetetafel 21 Zus., mech., Basis",
			en: "Equipment panel 21 Asm, mech., base"
		}
		,A2V00001812925 : {
			de: "Trafooelleitung Ruecklauf",
			en: "Oil pipe return"
		}
		,A2V00001812956 : {
			de: "Trafooelleitung Pumpe",
			en: "Oil pipe pump"
		}
		,A2V00001814138 : {
			de: "Sandkasten 2/3 RB",
			en: "sandbox 2/3 bs"
		}
		,A2V00001819883 : {
			de: "HBG-Rahmen lackiert",
			en: "Aux.com.inst-fr. laquered"
		}
		,A2V00001822841 : {
			de: "Oberspannungswandler Innenraum AC+DC TSI",
			en: "Voltage transformer indoor AC+DC TSI"
		}
		,A2V00001823846 : {
			de: "Ventiltafel fuer Stromabnehmer A/D",
			en: "Valve panel for pantograph A/D"
		}
		,A2V00001825255 : {
			de: "Stromabnehmer SA AC1 b",
			en: "Pantograph SA AC1 b"
		}
		,A2V00001825516 : {
			de: "Stromabnehmer SA AC3 b",
			en: "Pantograph SA AC3 b"
		}
		,A2V00001825517 : {
			de: "Stromabnehmer SA AC2 b",
			en: "Pantograph SA AC2 b"
		}
		,A2V00001825520 : {
			de: "Stromabnehmer SA DC1 bC",
			en: "Pantograph SA DC1 bC"
		}
		,A2V00001825522 : {
			de: "Stromabnehmer SA DC2 bC",
			en: "Pantograph SA DC2 bC"
		}
		,A2V00001825523 : {
			de: "Stromabnehmer SA DC3 aC",
			en: "Pantograph SA DC3 aC"
		}
		,A2V00001834191 : {
			de: "Geraetetafel 31 Zusammenstellung",
			en: "Equipment panel 31 Assembly"
		}
		,A2V00001840325 : {
			de: "Abdeckung 2 Zusammenstellung",
			en: "Cover 2 Assembly"
		}
		,A2V00001840612 : {
			de: "Signalleuchte unten li.",
			en: "signallight left below"
		}
		,A2V00001840613 : {
			de: "Signalleuchte unten re.",
			en: "signallight right below"
		}
		,A2V00001840614 : {
			de: "Scheinwerfer oben",
			en: "Head Light"
		}
		,A2V00001841105 : {
			de: "Druckluftverarbeitungsgeruest",
			en: "compressed air treatment"
		}
		,A2V00001845312 : {
			de: "Frontscheibe 200 re.",
			en: "Windscreen 200 ri."
		}
		,A2V00001846882 : {
			de: "Frontscheibe 200 li.",
			en: "Windscreen 200 le."
		}
		,A2V00001849607 : {
			de: "Kompressoraggregat",
			en: "Compressor aggregate"
		}
		,A2V00002082604 : {
			de: "Seitenwand Cabend re.",
			en: "Sidewall cabend re."
		}
		,A2V00002033596 : {
			de: "Untergestell M/T1",
			en: "Underframe M/T1"
		}
		,A2V00002033015 : {
			de: "Dach Zusb., M/T1",
			en: "Roof assy., M/T1"
		}
		,A2V00002116504 : {
			de: "Quertraeger ZSB.",
			en: "Cross beam, assy."
		}
		,A2V00002093627 : {
			de: "Ecksaeule ZSB w.g.",
			en: "Corner post assy. a.d."
		}
		,A2V00002093626 : {
			de: "Ecksaeule ZSB sp.g.",
			en: "Corner post assy. i.m.i."
		}
		,A2V00002084223 : {
			de: "Quertraeger ZSB",
			en: "Cross beam assy."
		}
		,A2V00002083985 : {
			de: "Verbindung",
			en: "Connection"
		}
		,A2V00002194242 : {
			de: "Halter f. E-Modul",
			en: "Bracket f. E-module"
		}
		,A2V00001849807 : {
			de: "Lufttrockungsanlage LTZ 2.2S-H/BS",
			en: "Air dryer unit LTZ 2.2S-H/BS"
		}
		,A2V00002155169 : {
			de: "Anschweissblock vollst.",
			en: "Welding block compl."
		}
		,A2V00002155168 : {
			de: "Buegel vollst.",
			en: "Bracket compl"
		}
		,A2V00002128963 : {
			de: "C-Schiene",
			en: "c-profile"
		}
		,A2V00002118089 : {
			de: "Schweissbads. w.g.",
			en: "Weld backing a.d."
		}
		,A2V00002107671 : {
			de: "Dichtblech",
			en: "Sealing plate"
		}
		,A2V00002103932 : {
			de: "Stanzplatte",
			en: "Stamping plate"
		}
		,A2V00002096460 : {
			de: "Haltebuegel",
			en: "Bracket"
		}
		,A2V00002096320 : {
			de: "Deckel DLT sp.g.",
			en: "Cover rc i.m.i."
		}
		,A2V00002096319 : {
			de: "Deckel DLT w.g.",
			en: "Cover rc a.d."
		}
		,A2V00002096318 : {
			de: "Deckel DLT sp.g.",
			en: "Cover rc i.m.i."
		}
		,A2V00002095039 : {
			de: "C-Profil SW oben",
			en: "C-profil sw upper"
		}
		,A2V00002093052 : {
			de: "Rippe w.g.",
			en: "Rib a.d."
		}
		,A2V00002093051 : {
			de: "Rippe sp.g.",
			en: "Rib i.m.i."
		}
		,A2V00002092389 : {
			de: "Tuerrahmen sp.g.",
			en: "Door frame i.m.i."
		}
		,A2V00002092388 : {
			de: "Auflage Maske w.g.",
			en: "Support mask a.d."
		}
		,A2V00002092387 : {
			de: "Auflage Maske sp.g.",
			en: "Support mask i.m.i."
		}
		,A2V00002092386 : {
			de: "Rippe Fuehrerstand w.g.",
			en: "Rib driverÂ´s cab a.d."
		}
		,A2V00002092325 : {
			de: "Tuerrahmen w.g.",
			en: "Door frame a.d."
		}
		,A2V00002092324 : {
			de: "Rippe Fuehrerstand w.g.",
			en: "Rib driverÂ´s cab a.d."
		}
		,A2V00002087637 : {
			de: "Butzen Auftritt ZSB",
			en: "Scrap footstep assy."
		}
		,A2V00002083015 : {
			de: "Konsole vollst.",
			en: "Console compl."
		}
		,A2V00002081227 : {
			de: "Rippe DLT",
			en: "Web RC"
		}
		,A2V00002081226 : {
			de: "Rippe DLT",
			en: "Web RC"
		}
		,A2V00002081155 : {
			de: "Rippe DLT",
			en: "Web RC"
		}
		,A2V00002080818 : {
			de: "Platte vollst.",
			en: "Plate compl."
		}
		,A2V00002080817 : {
			de: "Platte vollst.",
			en: "Plate compl."
		}
		,A2V00002080605 : {
			de: "Buegel vollst.",
			en: "Holder compl."
		}
		,A2V00002080603 : {
			de: "Halteblech vollst.",
			en: "Bracket compl."
		}
		,A2V00002080602 : {
			de: "Platte vollst.",
			en: "Plate compl."
		}
		,A2V00002079858 : {
			de: "Rippe DLT sp.g.",
			en: "Web RC i.m.i."
		}
		,A2V00002079857 : {
			de: "Rippe DLT w.g.",
			en: "Web RC a.s."
		}
		,A2V00002072074 : {
			de: "Verstaerkungsrippe",
			en: "Supporting rib"
		}
		,A2V00002072073 : {
			de: "Verstaerkungsrippe",
			en: "Supporting rib"
		}
		,A2V00002087548 : {
			de: "Tuerschwelle ZSB",
			en: "Door sill assy."
		}
		,A2V00002083304 : {
			de: "Rahmen Schiebetritt",
			en: "Frame sliding tread"
		}
		,A2V00002066051 : {
			de: "Haube geschw.",
			en: "Bonnet welded"
		}
		,A2V00001853380 : {
			de: "Hilfsluftpresser Modul MOH-14",
			en: "Auxiliary air compressor module MOH-14"
		}
		,A2V00001854980 : {
			de: "Batteriekasten 1 RB",
			en: "battery box 1 bs"
		}
		,A2V00001857560 : {
			de: "Aussenanzeige Betriebsbremse",
			en: "Service brake indicator"
		}
		,A2V00001860874 : {
			de: "Batteriekasten 2 RB",
			en: "battery box 2 bs"
		}
		,A2V00001861191 : {
			de: "Grundgeruest RB",
			en: "basis rack rb"
		}
		,A2V00001866755 : {
			de: "Konsole RB",
			en: "bracket bs"
		}
		,A2V00001869347 : {
			de: "IO-Geruest mech. Zus.",
			en: "IO-rack mech. asm."
		}
		,A2V00001874155 : {
			de: "AC-Hochspannungsgeruest vormontiert",
			en: "AC-high-voltage cubicle pre-assembled"
		}
		,A2V00001875068 : {
			de: "Sicherheitsventil NHS 12,0 bar",
			en: "Safety valve 12.0 bar"
		}
		,A2V00001875396 : {
			de: "Hilfsluftkompressor D-061",
			en: "Auxiliary compressor D-061"
		}
		,A2V00001877193 : {
			de: "Geraetetafel 43 Zus.,mech., Basis",
			en: "Equipment panel 43 Asm.,mech., base"
		}
		,A2V00001879829 : {
			de: "Geraetetafel 31 mech. Zus. Basis",
			en: "Equipment panel 31 mech. asm. base"
		}
		,A2V00001887526 : {
			de: "Geraetetafel 12 Zusammenstellung mech.",
			en: "Equipment panel 12 Assembly mech."
		}
		,A2V00001887776 : {
			de: "Lueftungsgitter Lufteinlass, RAL 7022",
			en: "air inlet grille, RAL 7022"
		}
		,A2V00001887777 : {
			de: "Lueftungsgitter Luftauslass, RAL 7022",
			en: "air outlet grille, RAL 7022"
		}
		,A2V00001896760 : {
			de: "Tuer mech. vorm.",
			en: "Door mech. pre-asm."
		}
		,A2V00001898557 : {
			de: "Dach 1 lackiert",
			en: "Roof 1 coated"
		}
		,A2V00001898559 : {
			de: "Dach 2 lackiert",
			en: "Roof 2 coated"
		}
		,A2V00370040171 : {
			de: "Duese, rechte",
			en: "Nozzle, right"
		}
		,A2V00370040631 : {
			de: "POLRAD SF5000",
			en: "ROTATING GEAR SF5000"
		}
		,A2V00370043469 : {
			de: "Luftfederungsventil SV 1205 GD1/110",
			en: "AIR SUSPENSION LEVELLING VALVE"
		}
		,A2V00002400350 : {
			de: "Kuppelstange G, H",
			en: "Coupling rod G, H"
		}
		,A2V00002399673 : {
			de: "Oberarm Alu H B790 kompl.",
			en: "Upper arm Alu H W790 compl."
		}
		,A2V00002399399 : {
			de: "Daempferanbau SSS; SBS 82-2498 SP1",
			en: "System damper SSS; SBS 82-2498 SP1"
		}
		,A2V00002399460 : {
			de: "Oberarm H Alu Ausf.3 kompl. m. Laschen",
			en: "Upper arm H Alu vers.3 compl. w. lug"
		}
		,A2V00002400237 : {
			de: "Kuppelstange Tieftemperatur Ausf.2",
			en: "Coupling rod low temperature vers.2"
		}
		,A2V00002400354 : {
			de: "Kuppelstange A1; GH/AC/std",
			en: "Coupling rod A1; GH/AC/Std"
		}
		,A2V00002399178 : {
			de: "Wippe NS Hochstrom Ausf.2",
			en: "Collector head NS high current vers.2"
		}
		,A2V00002399459 : {
			de: "Oberarm H Alu Ausf.1 kompl. m. Laschen",
			en: "Upper arm H Alu vers.1 compl. w. lug"
		}
		,A2V00001916615 : {
			de: "Einstellplatte vst.",
			en: "adjusting plate cpl."
		}
		,A2V00001921435 : {
			de: "Einstellplatte vst.",
			en: "adjusting plate cpl."
		}
		,A2V00001926030 : {
			de: "Energieerzeugung R84 vst",
			en: "Power Pack R84 cpl"
		}
		,A2V00001926365 : {
			de: "Adapter BLG",
			en: "Adapter BC"
		}
		,A2V00001961464 : {
			de: "Geraetetafel 13 mech. Zusammenstellung",
			en: "Equipment panel 13 mech. assembly"
		}
		,A2V00001961793 : {
			de: "Schlosstuer rechts lackiert",
			en: "Lock door right coated"
		}
		,A2V00001962261 : {
			de: "Anschlagtuer rechts lackiert",
			en: "Door stop right coated"
		}
		,A2V00001962490 : {
			de: "Schlosstuer links lackiert",
			en: "Lock door left coated"
		}
		,A2V00001962848 : {
			de: "Anschlagtuer links lackiert",
			en: "Door step left coated"
		}
		,A2V00002729037 : {
			de: "Seitenwand oben 1",
			en: "Sidewall upper 1"
		}
		,A2V00002778161 : {
			de: "Seitenwand unten",
			en: "Sidewall lower"
		}
		,A2V00002005181 : {
			de: "Dach 3 lackiert",
			en: "Roof 3 coated"
		}
		,A2V00002021202 : {
			de: "Geraetetafel 71 Zus. mech",
			en: "Equipment panel 71 Asm. mech."
		}
		,A2V00002021640 : {
			de: "Geraetetafel 21 mech. Zusammenstellung",
			en: "Equipment panel 21 mech. Assembly"
		}
		,A2V00002599281 : {
			de: "DC-Hauptschalter",
			en: "High Speed Circuit Breaker DC"
		}
		,A2V00002682958 : {
			de: "Erdungsblech 2",
			en: "Grounding sheet 2"
		}
		,A2V00002049739 : {
			de: "Bremstafel",
			en: "Brake panel"
		}
		,A2V00201184049 : {
			de: "Querschott",
			en: "in process Querschott"
		}
		,A2V00002465372 : {
			de: "Batteriekasten 2 Basis Ersatzteil",
			en: "battery box 2 basis"
		}
		,A2V00002465371 : {
			de: "Batteriekasten 1 Basis Ersatzteil",
			en: "battery box 1 basis"
		}
		,A2V00002076388 : {
			de: "Hammerschraube aehnlich DIN 188",
			en: "T-head bolt similar to DIN 188"
		}
		,A2V00002084087 : {
			de: "Bedientafel X4E5811 V4",
			en: "Operating panel X4E5811 V4"
		}
		,A2V00397125309 : {
			de: "Federteller",
			en: "Spring plate"
		}
		,A2V00001778723 : {
			de: "Drehzapfen",
			en: "bogie pin"
		}
		,A2V00002780301 : {
			de: "Scharblech geschweisst Ersatzteil",
			en: "Eaves flashing, welded"
		}
		,A2V00002780300 : {
			de: "Scharblech geschweisst Ersatzteil",
			en: "Eaves flashing, welded"
		}
		,A2V00397129790 : {
			de: "ABSTANDSHUELSE                   DRS/LPZ",
			en: "Distance sleeve"
		}
		,A2V00002109074 : {
			de: "Luftauslassgitter lack.",
			en: "air outlet grids laqu."
		}
		,A2V00002353106 : {
			de: "Crashelement lackiert",
			en: "crash element lacquered"
		}
		,A2V00002517750 : {
			de: "Hilfsbetriebetrafoger vst",
			en: "aux. transf.- rack cpl"
		}
		,A2V00397150934 : {
			de: "Beilagscheibe 2mm",
			en: "Primary suspension shim 2mm"
		}
		,A2V00397155079 : {
			de: "Lenkerlager",
			en: "Steering camp"
		}
		,A2V00002129399 : {
			de: "Bedientafel X4E1133 V11",
			en: "Operating panel X4E1133 V11"
		}
		,A2V00397161158 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00397161159 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00397163975 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397163976 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397163985 : {
			de: "Federbeilage 2mm",
			en: "Spring shim 2mm"
		}
		,A2V00397163994 : {
			de: "Unterlage 2mm f.Querpuffer",
			en: "shim 2mm f. cross bumper"
		}
		,A2V00397164664 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397164671 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397164692 : {
			de: "Untergurt",
			en: "Lower flange"
		}
		,A2V00397164693 : {
			de: "Obergurt",
			en: "Upper flange"
		}
		,A2V00397164707 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00397164727 : {
			de: "Untergurt",
			en: "Lower flange"
		}
		,A2V00397164728 : {
			de: "Obergurt",
			en: "Top flange"
		}
		,A2V00397165243 : {
			de: "Schweissbadsicherung",
			en: "Backing strip"
		}
		,A2V00397165394 : {
			de: "KLEMMENKASTEN,VOLLST. MSB TD1",
			en: "terminal box complete rail brake MB1"
		}
		,A2V00397165795 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002161841 : {
			de: "Vertikalanschlag",
			en: "limit stop"
		}
		,A2V00397165888 : {
			de: "Augenschraube",
			en: "Eye Bolt"
		}
		,A2V00397169736 : {
			de: "BOLZEN F.KOPFTRAEGER ANBAU TFW",
			en: "BOLT FOR HEAD BEAM ASSEMBLY"
		}
		,A2V00397169737 : {
			de: "SCHEIBE F.BOLZEN",
			en: "disc  f. bolt"
		}
		,A2V00397169738 : {
			de: "SCHEIBE 2 F.KOPFTRAEGER ANBAU TFW",
			en: "Pane 2 for buffer beam mount TFW"
		}
		,A2V00397172935 : {
			de: "Schweissbadsicherung",
			en: "Backing strip"
		}
		,A2V00397186392 : {
			de: "Unterlage grundiert",
			en: "Washer primed"
		}
		,A2V00002353065 : {
			de: "Crashelement lackiert",
			en: "crash element lacquered"
		}
		,A2V00002205456 : {
			de: "Bedientafel X4E1122 V5",
			en: "Operating panel X4E1122 V5"
		}
		,A2V00001819879 : {
			de: "FR-Rohbau",
			en: "Dc bodyshell"
		}
		,A2V00002239353 : {
			de: "Blech  w.g.",
			en: "sheet  as d."
		}
		,A2V00397208307 : {
			de: "Notfang",
			en: "Emergency catch"
		}
		,A2V00002239497 : {
			de: "Blech  sp.g.",
			en: "sheet  m.i."
		}
		,A2V00397212831 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002250990 : {
			de: "Frontklappe basis rot vst.",
			en: "front flap basic red cpl."
		}
		,A2V00397213003 : {
			de: "Verstellblech f. Sandrohr",
			en: "adjusting sheet for sander"
		}
		,A2V00397213612 : {
			de: "ABHEBESICHERUNG PRIMAERFEDER",
			en: "lift off locking device"
		}
		,A2V00002255350 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00397216951 : {
			de: "Lasche",
			en: "sheet"
		}
		,A2V00397227849 : {
			de: "Stuetze",
			en: "support"
		}
		,A2V00002307236 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00397233640 : {
			de: "LENKER, VOLLST.",
			en: "GUIDING ROD, COMPL."
		}
		,A2V00397234057 : {
			de: "LENKER, VOLLST.",
			en: "GUIDING ROD, COMPL."
		}
		,A2V00397234835 : {
			de: "Anschlagplatte",
			en: "stop plate"
		}
		,A2V00397237273 : {
			de: "HALTER",
			en: "HOLDER"
		}
		,A2V00397239768 : {
			de: "GRUNDBLECH F.SANDUNG U.RAEUMER SP.G.",
			en: "base pl. f. sand. dev. a. tr-sw. o.a.d."
		}
		,A2V00002337097 : {
			de: "Seitenblech",
			en: "side sheet"
		}
		,A2V00002337098 : {
			de: "Seitenblech",
			en: "side sheet"
		}
		,A2V00002337099 : {
			de: "U-Profil  w.g.",
			en: "u-profile  as d."
		}
		,A2V00002337100 : {
			de: "U-Profil  sp.g",
			en: "u-profile  m.i."
		}
		,A2V00397257538 : {
			de: "Abschleppoese",
			en: "PULL LUG"
		}
		,A2V00397257792 : {
			de: "SCHIENENRAEUMER W.G.",
			en: "RAIL CLEARER AS DRAWN"
		}
		,A2V00002362568 : {
			de: "Sechskantschraube",
			en: "Hexagon screw"
		}
		,A2V00002404636 : {
			de: "Kuehlanlage 1 X4C, klein (ohne BHR)",
			en: "Cooling unit 1 X4C, small (without BHR)"
		}
		,A2V00002404637 : {
			de: "Kuehlanlage 2 X4C, klein (mit BHR)",
			en: "Cooling unit 2 X4C, small (with BHR)"
		}
		,A2V00002404640 : {
			de: "Kuehlanlage 1 X4AB, gross (ohne BHR)",
			en: "Cooling unit 1 X4AB, big (without BHR)"
		}
		,A2V00002404642 : {
			de: "Kuehlanlage 2 X4AB, gross (mit BHR)",
			en: "Cooling unit 2 X4AB, big (with BHR)"
		}
		,A2V00002428544 : {
			de: "Klebegurt w.g.",
			en: "Bonding belt as d."
		}
		,A2V00002433192 : {
			de: "Trafooelleitung Pumpe",
			en: "Oil pipe pump"
		}
		,A2V00002092970 : {
			de: "Seitenwand",
			en: "Side wall"
		}
		,A2V00002437076 : {
			de: "Adapter RB",
			en: "Adaptor bs"
		}
		,A2V00002673888 : {
			de: "Schuerzenklappe Zusammenstellung",
			en: "Pinafore flap assembly"
		}
		,A2V00002633578 : {
			de: "Entwaesserungsrohr w.g.",
			en: "Drainage pipe a.d."
		}
		,A2V00002633577 : {
			de: "Entwaesserungsrohr sp.g.",
			en: "Drainage pipe i.m.i."
		}
		,A2V00002438547 : {
			de: "Zubehoerschrank vst.",
			en: "Equipment cubicle cpl."
		}
		,A2V00002439261 : {
			de: "Verkleidung Lack",
			en: "Cover lacq"
		}
		,A2V00002452236 : {
			de: "Kleiderschrank vst.",
			en: "Rack cpl."
		}
		,A2V00002463315 : {
			de: "Dichtung Vorsatzschale",
			en: "Seal facing cover"
		}
		,A2V00002495791 : {
			de: "Klapptritt lackiert",
			en: "Folding step lacquered"
		}
		,A2V00002495859 : {
			de: "Trittadapter lackiert",
			en: "Step adapter lacquered"
		}
		,A2V00002526522 : {
			de: "Schuerze lackiert",
			en: "Shroud lacquered"
		}
		,A2V00002526533 : {
			de: "Schuerze lackiert",
			en: "Shroud lacquered"
		}
		,A2V00002529592 : {
			de: "Konsole RB",
			en: "Bracket BS"
		}
		,A2V00002571633 : {
			de: "Aufhaengung",
			en: "suspension"
		}
		,A2V00002572452 : {
			de: "Kleberand w.g.",
			en: "Bonding egde as d."
		}
		,A2V00002572453 : {
			de: "Kleberand sp.g.",
			en: "Bonding egde r.i."
		}
		,A2V00002610355 : {
			de: "SIBAS32C Master Control Unit (MCU)",
			en: "SIBAS32C Master Control Unit (MCU)"
		}
		,A2V00002700624 : {
			de: "Einstiegstuer re. RAL3020/PE",
			en: "Entrance door ri. RAL3020/PE"
		}
		,A2V00002700625 : {
			de: "Einstiegstuer li. RAL3020/PE",
			en: "Entrance door le. RAL3020/PE"
		}
		,A2V00002735091 : {
			de: "Wellschlauchleitung",
			en: "Flex. metal hose"
		}
		,A2V00002743722 : {
			de: "Tankmodul vst",
			en: "Fuel container cpl"
		}
		,A2V00002747485 : {
			de: "ZS-Trennergeruest mech. Zus.",
			en: "TL-Disconn. cubicle mech. asm."
		}
		,A2V00002747799 : {
			de: "Kuehlanlage",
			en: "Cooling unit"
		}
		,A2V00002762825 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002540204 : {
			de: "Stirnwand ZSB sp.g.",
			en: "Endwall ASSY i.m.i."
		}
		,A2V00002540305 : {
			de: "Stirnwand ZSB w.g.",
			en: "Endwall ASSY a.d."
		}
		,A2V00200200032 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00200200066 : {
			de: "Hohlprofil",
			en: "Hollow profile"
		}
		,A2V00200375360 : {
			de: "Hohlprofil",
			en: "Hollow profile"
		}
		,A2V00200379863 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00200386008 : {
			de: "Rohr",
			en: "pipe"
		}
		,A2V00200386024 : {
			de: "Rohr",
			en: "Pipe"
		}
		,A2V00200386050 : {
			de: "Rohr",
			en: "Pipe"
		}
		,A2V00002114627 : {
			de: "Quertraeger 1 ZSB",
			en: "Cross girder 1 assy."
		}
		,A2V00002116506 : {
			de: "Quertraeger 7 ZSB",
			en: "Cross girder 7 assy."
		}
		,A2V00002116465 : {
			de: "Quertraeger 9 ZSB",
			en: "Cross girder 9 assy."
		}
		,A2V00002116464 : {
			de: "Quertraeger 8 ZSB",
			en: "Cross girder 8 assy."
		}
		,A2V00002116350 : {
			de: "Quertraeger 5  ZSB",
			en: "Cross girder 5 assy."
		}
		,A2V00002116349 : {
			de: "Quertraeger 6 ZSB",
			en: "Cross girder 6 assy."
		}
		,A2V00002115578 : {
			de: "Quertraeger 4 ZSB",
			en: "Cross girder 4 assy."
		}
		,A2V00002115577 : {
			de: "Quertraeger 2 ZSB",
			en: "Cross girder 2 assy."
		}
		,A2V00002115576 : {
			de: "Quertraeger 3  ZSB",
			en: "Cross girder 3 assy."
		}
		,A2V00002397948 : {
			de: "Halter f. Haltestange sp.g.",
			en: "Bracket f. handrail i.m.i."
		}
		,A2V00002733906 : {
			de: "Knotenblech w.g. , Flachdach",
			en: "Gusset plate as d. , flat roof"
		}
		,A2V00002733907 : {
			de: "Knotenblech sp.g. , Flachdach",
			en: "Gusset plate r.i. , flat roof"
		}
		,A2V00002733909 : {
			de: "Knotenblech w.g. , Flachdach",
			en: "Gusset plate as d. , flat roof"
		}
		,A2V00002733910 : {
			de: "Knotenblech sp.g. , Flachdach",
			en: "Gusset plate r.i., flat roof"
		}
		,A2V00002733915 : {
			de: "Flachdach vollstaendig WE1",
			en: "Flat roof comlete CE1"
		}
		,A2V00002733916 : {
			de: "Flachdach vollstaendig WE2",
			en: "Flat roof comlete CE2"
		}
		,A2V00207352713 : {
			de: "Lautsprecher LI6-8K",
			en: "Loudspeaker LI6-8K"
		}
		,A2V00207422328 : {
			de: "Spiegelglas",
			en: "Mirror glass"
		}
		,A2V00002392778 : {
			de: "Stirnwand KK-Ende vollst.",
			en: "front wall compl. short coupler end"
		}
		,A2V00002428545 : {
			de: "Crashkopf",
			en: "crash head absorber"
		}
		,A2V00002316370 : {
			de: "Stirnwand KK-Ende vollst.",
			en: "front wall compl. short coupler end"
		}
		,A2V00002743258 : {
			de: "Kupplungsplatte Zusb.",
			en: "Couplerplate Assy."
		}
		,A2V00002743257 : {
			de: "Kupplungsplatte Zusb.",
			en: "Coupling plate Assy."
		}
		,A2V00002272922 : {
			de: "Stirnwand KK-Ende vollst.",
			en: "front wall compl. short coupler end"
		}
		,A2V00002682736 : {
			de: "Querprofil",
			en: "cross profile"
		}
		,A2V00002715777 : {
			de: "Querprofil Portal",
			en: "cross profile portal"
		}
		,A2V00001002524 : {
			de: "Fahrmotor GT6_NBG 1TB1624",
			en: "Traction motor GT6_NBG 1TB1624"
		}
		,A2V00002621242 : {
			de: "Bedientafel_X4M1138_vst",
			en: "Control panel_X4M1138_cpl"
		}
		,A2V00002551643 : {
			de: "Tafel_5811_V6 E+M",
			en: "Panel_5811_V6 e+m"
		}
		,A2V00002575548 : {
			de: "Schuerze geschw. Ersatzteil",
			en: "Shroud welded spare part"
		}
		,A2V00001823981 : {
			de: "Horn, rechts",
			en: "buffer, right welded"
		}
		,A2V00002505752 : {
			de: "Schneeraeumer lack Ersatzteil",
			en: "snow plough coated"
		}
		,A2V00001929158 : {
			de: "Druckluftgeruest RB",
			en: "Compressed air rack frame"
		}
		,A2V00002615824 : {
			de: "Rahmen geschweisst",
			en: "Frame welded"
		}
		,A2V00002687358 : {
			de: "Zwischenboden 2, vollst.",
			en: "intermediate floor 2, complete"
		}
		,A2V00002313739 : {
			de: "Langtraeger Rechts F-Ende",
			en: "longitudinal beam right front end"
		}
		,A2V00002052967 : {
			de: "Rohrleitung geschweisst",
			en: "Piping welded"
		}
		,A2V00002354002 : {
			de: "Stirnwand KK-Ende vollst.",
			en: "front wall compl. short coupler end"
		}
		,A2V00002355234 : {
			de: "Stirnwand KK-Ende vollst.",
			en: "front wall compl. short coupler end"
		}
		,A2V00002078769 : {
			de: "Stirnwand KK-Ende vollst.",
			en: "front wall compl. short coupler end"
		}
		,A2V00002392781 : {
			de: "Stirnwand KK-Ende vollst.",
			en: "front wall compl. short coupler end"
		}
		,A2V00002313644 : {
			de: "Langtraeger Links F-Ende",
			en: "longitudinal beam left front end"
		}
		,A2V00002021782 : {
			de: "Stirnwand KK-Ende vollst.",
			en: "front wall compl. short coupler end"
		}
		,A2V00002021099 : {
			de: "Stirnwand KK-Ende vollst.",
			en: "front wall compl. short coupler end"
		}
		,A2V00001875410 : {
			de: "Haltestange geschweisst, vollst.",
			en: "holding rod welded, asm."
		}
		,A2V00002361334 : {
			de: "Langtraeger KK-E voll-re",
			en: "longit. girder SC-E"
		}
		,A2V00002686652 : {
			de: "HV Box +76 vormontiert",
			en: "HV Box +76 pre-assembled"
		}
		,A2V00002082113 : {
			de: "Tuersaeule ZSB w.g.",
			en: "Door pillar assy. a.d."
		}
		,A2V00002082112 : {
			de: "Tuersaeule ZSB sp.g.",
			en: "Door pillar assy. i.m.i."
		}
		,A2V00002081002 : {
			de: "Konsole vollst.",
			en: "Bracket assy."
		}
		,A2V00002080504 : {
			de: "Querprofil",
			en: "Cross profile"
		}
		,A2V00002083252 : {
			de: "Bodenblech ZSB",
			en: "Floor plate assy."
		}
		,A2V00002072275 : {
			de: "Stirnwand, Zusb.",
			en: "Endwall, assy."
		}
		,A2V00002066533 : {
			de: "Stirnwand, Zusb.",
			en: "Endwall, assy."
		}
		,A2V00002090041 : {
			de: "Crashelement",
			en: "Crashelement"
		}
		,A2V00002090040 : {
			de: "Crashelement",
			en: "Crashelement"
		}
		,A2V00002540801 : {
			de: "Seitenwand Kurzkuppelende sp.g.",
			en: "Sidewall non cabend i.m.i."
		}
		,A2V00002540923 : {
			de: "Seitenwand FSTD-Ende sp.g.",
			en: "Sidewall cabend i.m.i."
		}
		,A2V00002540924 : {
			de: "Seitenwand FSTD-Ende w.g.",
			en: "Sidewall cabend a.d."
		}
		,A2V00002540774 : {
			de: "Seitenwand Kurzkuppelende w.g.",
			en: "Sidewall non cabend a.d."
		}
		,A2V00002541844 : {
			de: "I-Profil WE2",
			en: "I-profile CE2"
		}
		,A2V00002542250 : {
			de: "I-Profil, ZSB FSTE",
			en: "I-Profile, ASSY CE"
		}
		,A2V00002389481 : {
			de: "Halter vollst. sp.g.",
			en: "Bracket compl. i.m.i."
		}
		,A2V00002389480 : {
			de: "Halter vollst. w.g.",
			en: "Bracket compl. a.d."
		}
		,A2V00002083306 : {
			de: "I-Profil ZSB KKE",
			en: "I-Profile assy. SCE"
		}
		,A2V00002083253 : {
			de: "Hauptquertraeger ZSB",
			en: "Main cross girder assy."
		}
		,A2V00002082847 : {
			de: "Kupplungskasten KKE",
			en: "Coupling-box SCE"
		}
		,A2V00002081728 : {
			de: "Seitenwand Kurzkuppelende sp.g.",
			en: "Sidewall non cab end i.m.i."
		}
		,A2V00002081727 : {
			de: "Seitenwand Kurzkuppelende w.g.",
			en: "Sidewall non cabend a.d."
		}
		,A2V00002077769 : {
			de: "Kupplungskasten WE1",
			en: "Coupling box CE1"
		}
		,A2V00002144788 : {
			de: "Schelle",
			en: "Clamp"
		}
		,A2V00002144787 : {
			de: "Schelle",
			en: "Clamp"
		}
		,A2V00002144786 : {
			de: "Doppelschelle",
			en: "Doubleclamp"
		}
		,A2V00002144780 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002085525 : {
			de: "I-Profil, ZSB FSTE",
			en: "I-Profile, assy. CE"
		}
		,A2V00002084312 : {
			de: "Langtraeger ZSB, sp.g. f. UG  M/T1",
			en: "Longitudinal beam assy., i.m.i. f. UF M/"
		}
		,A2V00002084311 : {
			de: "Langtraeger ZSB, w.g. f. UG  M/T1",
			en: "Longitudinal beam assy., a.d. f. UF M/T1"
		}
		,A2V00002116180 : {
			de: "Stirnwand ZSB",
			en: "Endwall assy."
		}
		,A2V00002116179 : {
			de: "Stirnwand ZSB",
			en: "Endwall assy."
		}
		,A2V00002084937 : {
			de: "Befestigung f. Tuer",
			en: "Fixing f. door"
		}
		,A2V00002630324 : {
			de: "Ecksaeule WE2 w.g.",
			en: "Corner post CE2 a.d."
		}
		,A2V00001135391 : {
			de: "Display SITET 650 MHZ",
			en: "Display SITET 650 MHz"
		}
		,A2V00001136531 : {
			de: "Drehstromtrafo 100kVA SD3000WL-2000V",
			en: "3phase transformer 100kVA SD3000WL-2000V"
		}
		,A2V00001149116 : {
			de: "Vakuum-Leistungsschalter 1pol. 17,5 kV",
			en: "Vacuum power switch unipolar 17,5kV"
		}
		,A2V00001189205 : {
			de: "Fusspult Deckel,   vollst.",
			en: "footrest top, compl."
		}
		,A2V00002081482 : {
			de: "Konsole vollst.",
			en: "Bracket compl."
		}
		,A2V00002080266 : {
			de: "Dachquertraeger Cab",
			en: "Roof cross beam CE1"
		}
		,A2V00002080125 : {
			de: "Dachquertraeger WE2",
			en: "Roof cross beam CE2"
		}
		,A2V00002072432 : {
			de: "Dachlangtraeger Zusb.M/T1",
			en: "Roof cantrail assy.M/T1"
		}
		,A2V00002072431 : {
			de: "Dachlangtraeger Zusb., sp.g. MC",
			en: "Roof cantrail assy., i.m.i. MC"
		}
		,A2V00002072430 : {
			de: "Dachlangtraeger Zusb., w.g. MC",
			en: "Roof cantrail assy., a.d. MC"
		}
		,A2V00002072140 : {
			de: "Klimawanne KKE, Zusb.",
			en: "Acu-pan sce, assy."
		}
		,A2V00002072139 : {
			de: "Klimawanne",
			en: "Air condition unit pan"
		}
		,A2V00002072138 : {
			de: "Dachplatte M/T1 Zusb.",
			en: "Roof plate M/T1 assy."
		}
		,A2V00002072137 : {
			de: "Dachplatte MC",
			en: "Roof plate MC"
		}
		,A2V00002067375 : {
			de: "Stirnwand f. Klimawanne",
			en: "Endwall f. acu pan"
		}
		,A2V00002067374 : {
			de: "Stirnwand f. Klimawanne",
			en: "Endwall f. acu pan"
		}
		,A2V00002108234 : {
			de: "Quertraeger f. Geraetehaengung",
			en: "Cross girder f. equipment support"
		}
		,A2V00002084858 : {
			de: "Langtraeger ZSB, w.g. f. UG  MC",
			en: "Longitudinal beam assy., a.d. f. UF  MC"
		}
		,A2V00002084857 : {
			de: "Langtraeger ZSB, sp.g. f. UG  MC",
			en: "Longitudinal beam assy., i.m.i. f. UF MC"
		}
		,A2V00002145525 : {
			de: "Funk-Batterie-Box, vollst.",
			en: "Radio battery box, compl."
		}
		,A2V00002194241 : {
			de: "Haltewinkel",
			en: "Fixing bracket"
		}
		,A2V00001372585 : {
			de: "Befestigungsplatte",
			en: "Fixingplate"
		}
		,A2V00001373997 : {
			de: "Magnetventil",
			en: "Magnetventil"
		}
		,A2V00001396189 : {
			de: "Saugkreisdrossel ICT2",
			en: "draw circuit inductor"
		}
		,A2V00001418500 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00112506475 : {
			de: "Verbindungsleitung Teil 1",
			en: "Hose assembly part 1"
		}
		,A2V00112410857 : {
			de: "LEUCHTENABDECKUNG                      :",
			en: "light cover"
		}
		,A2V00112508862 : {
			de: "EINFUELLSTUTZEN   RECHTS",
			en: "filler right"
		}
		,A2V00112227100 : {
			de: "GLASTRENNWAND,    VOLLST.",
			en: "GLASTRENNWAND,    VOLLST."
		}
		,A2V00112054904 : {
			de: "NUTENSTEIN  FUER  M16                  .",
			en: "NUTENSTEIN  FUER  M16                  ."
		}
		,A2V00112056320 : {
			de: "NUTENSTEIN  FUER  M12                  .",
			en: "NUTENSTEIN  FUER  M12                  ."
		}
		,A2V00001703376 : {
			de: "Wasserbehaelter",
			en: "water reservoir"
		}
		,A2V00113012404 : {
			de: "PROFIL FUER       ABSCHLUSSRAHMEN",
			en: "PROFIL FUER       ABSCHLUSSRAHMEN"
		}
		,A2V00110040345 : {
			de: "PROFIL                                 .",
			en: "profile"
		}
		,A2V00001758736 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001791730 : {
			de: "Traktionsstromr. SIBAC E23-3000 C.0-1",
			en: "Traction converter SIBAC E23-3000 C.0-1"
		}
		,A2V00001814139 : {
			de: "Sandkasten 1/4 RB",
			en: "sandbox 1/4 bs"
		}
		,A2V00311100981 : {
			de: "RD DIN1013-10-S355JO",
			en: "RD DIN1013-10-S355JO"
		}
		,A2V00316700286 : {
			de: "RERO 30X20X2-6000 E235+CR2-S2 EN10305-5",
			en: "RERO 30X20X2-6000 E235+CR2-S2 EN10305-5"
		}
		,A2V00001835044 : {
			de: "Bremshebel indirekte Bremse",
			en: "Controller indirect brake"
		}
		,A2V00397073573 : {
			de: "BD DIN1016-2x20x104-S235JRG2",
			en: "BD DIN1016-2x20x104-S235JRG2"
		}
		,A2V00370067919 : {
			de: "Schelle  AXIALFIX halb, lack.",
			en: "Clamp AXIALFIX. half, paint."
		}
		,A2V00397249911 : {
			de: "Zentrierbolzen flach",
			en: "Center pin flat"
		}
		,A2V00370015100 : {
			de: "FL DIN1017-70x12-S235JRG2",
			en: "FL DIN1017-70x12-S235JRG2"
		}
		,A2V00001371977 : {
			de: "Mitnehmer, abnehmbarer Bugport. w.g.",
			en: "Actuator, dechatable bow portals a.d."
		}
		,A2V00001369368 : {
			de: "Bremskonsole f. GP, w.g.",
			en: "Brake console f. AP, a.d."
		}
		,A2V00002591444 : {
			de: "Anschlussteil, oberes",
			en: "Connector, upper"
		}
		,A2V00002591442 : {
			de: "Anschlussteil, unteres",
			en: "Connector, lower"
		}
		,A2V00002577576 : {
			de: "Entwaesserungsrohr",
			en: "Drainage pipe"
		}
		,A2V00002573567 : {
			de: "Deckblech w.g.",
			en: "Cover plate a.d."
		}
		,A2V00002542486 : {
			de: "Erdungslasche M6",
			en: "Grounding flap M6"
		}
		,A2V00002590347 : {
			de: "C-Schiene",
			en: "C-profile"
		}
		,A2V00002542807 : {
			de: "Dichtleiste",
			en: "Sealing strip"
		}
		,A2V00001878852 : {
			de: "Lagergehaeuse komplett fuer Antrieb",
			en: "bearing housing complete for drive"
		}
		,A2V00001881402 : {
			de: "Fenster Zusammenbau",
			en: "Window assembly"
		}
		,A2V00001885380 : {
			de: "Klemmkasten, ATB-Geber",
			en: "Terminal box, ATB-Sensor"
		}
		,A2V00370039447 : {
			de: "Abhebesicherung",
			en: "Liftstop"
		}
		,A2V00397163982 : {
			de: "RING,OBERER",
			en: "TOP RING"
		}
		,A2V00397163983 : {
			de: "RING,UNTERER",
			en: "BOTTOM RING"
		}
		,A2V00370014902 : {
			de: "HP DIN59410-100X100X5-ST52-3",
			en: "HP DIN59410-100X100X5-ST52-3"
		}
		,A2V00311100593 : {
			de: "RD DIN671-25-CK35K",
			en: "RD DIN671-25-CK35K"
		}
		,A2V00397194729 : {
			de: "Leiste",
			en: "Fillet"
		}
		,A2V00397183106 : {
			de: "Befestigungslappen",
			en: "Fastening tab"
		}
		,A2V00397183193 : {
			de: "Konsole f. MG-Bremse sp.g.",
			en: "Bracket f. MT-brake o.a.d."
		}
		,A2V00397201551 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002042332 : {
			de: "Winkel 1",
			en: "angle 1"
		}
		,A2V00370048288 : {
			de: "UNTERLEGBLECH",
			en: "shimming sheet"
		}
		,A2V00370039495 : {
			de: "DRUCKPLATTE",
			en: "compression plate"
		}
		,A2V00002063938 : {
			de: "Schuerze lackiert",
			en: "shroud lacquered"
		}
		,A2V00002063940 : {
			de: "Schuerze lackiert",
			en: "shroud lacquered"
		}
		,A2V00002076747 : {
			de: "Schutzrohr geschweisst",
			en: "Conduit welded"
		}
		,A2V00002090461 : {
			de: "Beilagscheibe",
			en: "Shim"
		}
		,A2V00002111233 : {
			de: "Magnetimpulsventil",
			en: "Magnet impulse valve"
		}
		,A2V00002168754 : {
			de: "Frontblech",
			en: "Front panel"
		}
		,A2V00002168999 : {
			de: "Frontblech",
			en: "Front panel"
		}
		,A2V00001686395 : {
			de: "Befestigung Typ 5",
			en: "Fastener type 5"
		}
		,A2V00002454616 : {
			de: "Konsole WE1 f. Dachverkleidung, sp.g.",
			en: "Bracket CE1 f. roof covering, i.m.i."
		}
		,A2V00002454455 : {
			de: "Konsole WE1 f. Dachverkleidung, w.g.",
			en: "Bracket CE1 f. roof covering, a.d."
		}
		,A2V00002064462 : {
			de: "Dehnhuelse M20x40x20",
			en: "Extension sleeve M20x40x20"
		}
		,A2V00002534436 : {
			de: "Versteifungsblech, oberes sp.g.",
			en: "Bracing sheet, upper i.m.i."
		}
		,A2V00001991670 : {
			de: "Halter F V2 E6 70",
			en: "Holder F V2 E6 70"
		}
		,A2V00001991928 : {
			de: "Halter F V2 D2 40",
			en: "Holder F V2 D2 40"
		}
		,A2V00002192091 : {
			de: "Makrofon                 ZM75/370.1 TSI",
			en: "Macrophone               ZM75/370.1 TSI"
		}
		,A2V00002266741 : {
			de: "Laufrad 38 + Nabe",
			en: "Bogie wheel 38 + hub"
		}
		,A2V00002266941 : {
			de: "Gehaeuse fuer Saugkreisdrosselventilator",
			en: "Housing for absorption circuit valve"
		}
		,A2V00002267017 : {
			de: "Arm LVT 643 AKOF 430/225",
			en: "Arm LVT 643 AKOF 430/225"
		}
		,A2V00002355912 : {
			de: "Klappsitz",
			en: "Folding seat"
		}
		,A2V00002692250 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002692256 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002386330 : {
			de: "Klappe genietet",
			en: "Flap riveted"
		}
		,A2V00156009275 : {
			de: "DROSSEL",
			en: "choke"
		}
		,A2V00002714752 : {
			de: "Passschraube",
			en: "Fit bolt"
		}
		,A2V00002400272 : {
			de: "Sonderschraube T2 M16x90",
			en: "Special screw T2 M16x90"
		}
		,A2V00002423727 : {
			de: "Einstiegstuer re.",
			en: "Entrance door ri."
		}
		,A2V00002423728 : {
			de: "Einstiegstuer li.",
			en: "Entrance door le."
		}
		,A2V00200413876 : {
			de: "Sechskantschraube",
			en: "hexagon head bolt"
		}
		,A2V00207471217 : {
			de: "Bodenpaneel rechts",
			en: "Floor panel right"
		}
		,A2V00207354135 : {
			de: "Vakuumhauptschalter",
			en: "vacuum main switch"
		}
		,A2V00002528039 : {
			de: "Laufrad nach VG18099-11",
			en: "running wheel"
		}
		,A2V00207310251 : {
			de: "Klimageraet",
			en: "Air condition unit"
		}
		,A2V00397237616 : {
			de: "Radsatzlenker vollst.",
			en: "Wheelset Steering compl."
		}
		,A2V00370072235 : {
			de: "Beilage",
			en: "SHIM"
		}
		,A2V00397240152 : {
			de: "Konsole Laengslenker",
			en: "bracket guiding rod"
		}
		,A2V00370061165 : {
			de: "Puffer",
			en: "buffer"
		}
		,A2V00370061100 : {
			de: "Puffer",
			en: "buffer"
		}
		,A2V00112510466 : {
			de: "Gelenklager-Gehaeuse, Kompl.",
			en: "joint bearing-housing, cpl."
		}
		,A2V00112501753 : {
			de: "SANDKASTEN",
			en: "sand box"
		}
		,A2V00112504482 : {
			de: "Nutenstein M12",
			en: "Slot nut M12"
		}
		,A2V00001106491 : {
			de: "Panoramascheibe",
			en: "Panorama window"
		}
		,A2V00397166542 : {
			de: "BOLZEN F.LAENGSLENKER LFW",
			en: "Bolt for control arm LFW"
		}
		,A2V00002596702 : {
			de: "Kegelhuelse",
			en: "conical sleeve"
		}
		,A2V00002602313 : {
			de: "Rohrleitung",
			en: "TUBES,NT,COOLING UNIT"
		}
		,A2V00200678302 : {
			de: "Blech EN 485-4 EN AW-5083-H22",
			en: "Sheet EN 485-4 EN AW-5083-H22"
		}
		,A2V00002627997 : {
			de: "Verschraubungssatz Kupplung",
			en: "Coupling screw set"
		}
		,A2V00002669589 : {
			de: "Aufkletterschutz geschw.",
			en: "Anticlimber welded"
		}
		,A2V00002669590 : {
			de: "Crashmodul, geschw.",
			en: "Crash module, welded"
		}
		,A2V00370082029 : {
			de: "Megi-Ringfeder",
			en: "megi ring spring"
		}
		,A2V00002704010 : {
			de: "Fahrmotor",
			en: "Motor"
		}
		,A2V00397207013 : {
			de: "Kopfkonsole",
			en: "Headbracket"
		}
		,A2V00397127034 : {
			de: "LENKER,VOLLST.",
			en: "connecting rod, compl."
		}
		,A2V00397163946 : {
			de: "LENKERHEBEL,VOLLST.",
			en: "suspension arm"
		}
		,A2V00370044365 : {
			de: "Puffer",
			en: "buffer"
		}
		,A2V00397163988 : {
			de: "Sicherungsblech",
			en: "Locking plate"
		}
		,A2V00002738204 : {
			de: "Gehaeuse ASEF 380 VELARO-D AC42100 3.2 6",
			en: "Housing ASEF 380 VELARO-D AC42100 3.2 6"
		}
		,A2V00002738205 : {
			de: "Stuetz-Drehmoment VELARO-D 3.2 45,7",
			en: "Backup Torque VELARO-D 3.2 45,7"
		}
		,A2V00002561604 : {
			de: "Ablaufhorn",
			en: "Horn"
		}
		,A2V00106010563 : {
			de: "ROHRRAHMEN,(FUER ERSATZTEILVERKAUF)",
			en: "Pipe frame for spare part distribution"
		}
		,A2V00002717668 : {
			de: "Arbeitprobe Mireo_010",
			en: "work sample Mireo_010"
		}
		,A2V00207472811 : {
			de: "BACC4 Halter",
			en: "BACC4 attachment"
		}
		,A2V00201179279 : {
			de: "Schuerzenhalter",
			en: "Bracket"
		}
		,A2V00201185095 : {
			de: "Aussengitter",
			en: "Cover"
		}
		,A2V00200413885 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00200422965 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00397109846 : {
			de: "Grundplatte",
			en: "Base plate"
		}
		,A2V00397156311 : {
			de: "Halteblech",
			en: "Retaining plate"
		}
		,A2V00397100793 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00201103381 : {
			de: "Sandtreppe, Gussteil re m. AuÃŸengewinde",
			en: "Sand staircase casing"
		}
		,A2V00201131867 : {
			de: "Sandtreppe, Gussteil gerade Form",
			en: "Sand staircase casing"
		}
		,A2V00201172337 : {
			de: "Halter vollst.",
			en: "Halter vollst."
		}
		,A2V00002667110 : {
			de: "Grundrahmen, SWT",
			en: "Base frame, wp"
		}
		,A2V00201304497 : {
			de: "Kupplungstraegerblech",
			en: "centre sill sheet"
		}
		,A2V00205394152 : {
			de: "Impulsgeber KMG-2H komplett",
			en: "Pulse generator KMG-2H complete"
		}
		,A2V00207230007 : {
			de: "Zugeinr. 450-1500",
			en: "Zugeinr. 450-1500"
		}
		,A2V00002094251 : {
			de: "Beilage vertikal",
			en: "shim vertical"
		}
		,A2V00207310538 : {
			de: "L - Profil",
			en: "L - Profil"
		}
		,A2V00207310539 : {
			de: "U - Profil",
			en: "U - Profil"
		}
		,A2V00207318892 : {
			de: "Bodenpaneel rechts",
			en: "Floor panel right"
		}
		,A2V00001823952 : {
			de: "Rippe 2  SP.G. Mittelrahmen",
			en: "rib 2 O.A.D. centre beam"
		}
		,A2V00001823953 : {
			de: "Rippe 2  W.G. Mittelrahmen",
			en: "rib 2 A.D. centre beam"
		}
		,A2V00001823954 : {
			de: "Rippe 1 Mittelrahmen",
			en: "rib 1 centre beam"
		}
		,A2V00001823998 : {
			de: "Schienenbremskonsole Mittelrahmen",
			en: "rail brake bracket centre frame"
		}
		,A2V00001823999 : {
			de: "Platte Mittelrahmen",
			en: "plate centre frame"
		}
		,A2V00001824420 : {
			de: "Steg 9 Mittelrahmen",
			en: "web 9 centre frame"
		}
		,A2V00001824421 : {
			de: "Steg 6 Mittelrahmen",
			en: "web 6 centre frame"
		}
		,A2V00001824423 : {
			de: "Steg 5 Mittelrahmen",
			en: "web 5 centre frame"
		}
		,A2V00001824424 : {
			de: "Steg 4 Mittelrahmen",
			en: "web 4 centre frame"
		}
		,A2V00001824425 : {
			de: "Steg 7 Mittelrahmen",
			en: "web 7 centre frame"
		}
		,A2V00207353031 : {
			de: "Leitungsbruecke",
			en: "cable link"
		}
		,A2V00001669869 : {
			de: "Stuetzring Schlitzbuchse",
			en: "Retaining ring slot bush"
		}
		,A2V00001471562 : {
			de: "Konsole gebogen w.g.",
			en: "Bracket bended as drawn"
		}
		,A2V00002134400 : {
			de: "Beilage Notfang 15mm",
			en: "Shim emergency catch 15mm"
		}
		,A2V00001471563 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00001471561 : {
			de: "Konsole w.g.",
			en: "Konsole as drawn"
		}
		,A2V00001471565 : {
			de: "Konsole sp.g.",
			en: "Bracket opp. as drawn"
		}
		,A2V00001471756 : {
			de: "Konsole gebogen sp.g",
			en: "Bracket bended opp. as drawn"
		}
		,A2V00002740636 : {
			de: "Halter LFST JDG SPG",
			en: "holder LFST JB OAD"
		}
		,A2V00001960634 : {
			de: "Konsole Querspielb. w.g.",
			en: "Console lateral stop a.d."
		}
		,A2V00001429382 : {
			de: "Transportoese",
			en: "Eyebolt"
		}
		,A2V00002333999 : {
			de: "Halter 1 WG",
			en: "Bracket 1 AD"
		}
		,A2V00002334000 : {
			de: "Halter 1 SPG",
			en: "Bracket 1 OAD"
		}
		,A2V00001428662 : {
			de: "Lagerbock vollstaendig rechts",
			en: "Bearing complete right"
		}
		,A2V00001428661 : {
			de: "Lagerbock vollstaendig llinks",
			en: "Bearing complete left"
		}
		,A2V00002255825 : {
			de: "Halter fuer Schelle",
			en: "Bracket for clamp"
		}
		,A2V00002426500 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002117956 : {
			de: "Halter 2",
			en: "Bracket 2"
		}
		,A2V00002118359 : {
			de: "Schellenhalter 4",
			en: "Clip collar bracket 4"
		}
		,A2V00002118398 : {
			de: "Schellenhalter 2",
			en: "Clip collar bracket 2"
		}
		,A2V00002084310 : {
			de: "Bodenplatte, vollst.",
			en: "Floor plate, compl."
		}
		,A2V00001897500 : {
			de: "Abdeckung IBS lackiert",
			en: "Cover commissioning lq."
		}
		,A2V00002539045 : {
			de: "Gussteil",
			en: "Cast part"
		}
		,A2V00002729352 : {
			de: "Schottblech komplett",
			en: "partition sheet complete"
		}
		,A2V00002325309 : {
			de: "A-Saeule w.g.",
			en: "a-post as.d."
		}
		,A2V00002328859 : {
			de: "A-Saeule sp.g.",
			en: "a-post r.i."
		}
		,A2V00002722838 : {
			de: "Stahlkopf geschweisst",
			en: "steel head welded"
		}
		,A2V00002699538 : {
			de: "Untergurt w.g.",
			en: "bottom beam as d."
		}
		,A2V00002699537 : {
			de: "Untergurt sp.g.",
			en: "bottom beam r.i."
		}
		,A2V00002702014 : {
			de: "Absorber links",
			en: "absorber left"
		}
		,A2V00002702015 : {
			de: "Absorber rechts",
			en: "absorber right"
		}
		,A2V00002707722 : {
			de: "Bodengerippe li",
			en: "floor frame left"
		}
		,A2V00002707724 : {
			de: "Bodengerippe re",
			en: "floor frame right"
		}
		,A2V00002700025 : {
			de: "Bruestung geschweisst",
			en: "parapet welded"
		}
		,A2V00002703352 : {
			de: "Seitenwand geschweisst",
			en: "side wall welded"
		}
		,A2V00002704698 : {
			de: "Seitenwand geschweisst",
			en: "side wall welded"
		}
		,A2V00002707723 : {
			de: "Untergestell Kopf",
			en: "underframe head"
		}
		,A2V00002439235 : {
			de: "Laengsprofil, Zwischenboden",
			en: "longitudal beam profile, intermed. floor"
		}
		,A2V00002699771 : {
			de: "Kabeldurchfuehrung",
			en: "cable feedthrough"
		}
		,A2V00002757179 : {
			de: "Crash Modul vollst.",
			en: "crash modul complete"
		}
		,A2V00002757180 : {
			de: "Unterfahrschutz vollst.",
			en: "underrun bumper complete"
		}
		,A2V00002768579 : {
			de: "Arbeitprobe UNI_029",
			en: "work sample UNI_029"
		}
		,A2V00002768580 : {
			de: "Arbeitprobe UNI_030",
			en: "work sample UNI_030"
		}
		,A2V00002732124 : {
			de: "Nutenstein M20X1",
			en: "thread block 2XM16X140"
		}
		,A2V00002737620 : {
			de: "Nutenstein 2XM16",
			en: "thread block 2XM16"
		}
		,A2V00002726503 : {
			de: "Stahlkopf vollst.",
			en: "steel front complete"
		}
		,A2V00002537426 : {
			de: "Kupplungsplatte",
			en: "Coupling plate"
		}
		,A2V00002684389 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002713462 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002713463 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002713540 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002715719 : {
			de: "Motorhalter",
			en: "Engine bracket"
		}
		,A2V00002724422 : {
			de: "Geraetetraeger Profil",
			en: "Equipment rack profile"
		}
		,A2V00002743270 : {
			de: "Kugelscheibe",
			en: "Spherical washer"
		}
		,A2V00002728660 : {
			de: "Seitenwandprofil unten",
			en: "Sidewall profile lower"
		}
		,A2V00002699548 : {
			de: "Nutenstein M10",
			en: "Sliding block M10"
		}
		,A2V00002713116 : {
			de: "Anschweissteile Fix UG MT",
			en: "Weld-on parts fix UF MT"
		}
		,A2V00002719410 : {
			de: "Profil 6005A T6 15 MIG",
			en: "Profile 6005A T6 15 MIG"
		}
		,A2V00002717837 : {
			de: "Sitzrahmen links",
			en: "Seat frame left"
		}
		,A2V00002717594 : {
			de: "Sitzrahmen rechts",
			en: "Seat frame right"
		}
		,A2V00002497328 : {
			de: "Verkleidungsblech, vertikal w.g.",
			en: "Covering sheet, vertical a.d."
		}
		,A2V00002497327 : {
			de: "Verkleidungsblech, vertikal sp.g.",
			en: "Covering sheet, vertical i.m.i."
		}
		,A2V00002497316 : {
			de: "Deckel F-Nische sp.g.",
			en: "Cover f-space i.m.i."
		}
		,A2V00002497313 : {
			de: "Deckel F-Nische w.g.",
			en: "Cover f-space a.d."
		}
		,A2V00002607084 : {
			de: "Seitenwand C2, ZSB",
			en: "Side wall C2, ASSY"
		}
		,A2V00002606470 : {
			de: "Seitenwand G, ZSB",
			en: "Side wall G, ASSY"
		}
		,A2V00002606469 : {
			de: "Seitenwand H, ZSB",
			en: "Side wall H, ASSY"
		}
		,A2V00002606468 : {
			de: "Seitenwand I, ZSB",
			en: "Side wall I, ASSY"
		}
		,A2V00002606467 : {
			de: "Seitenwand F, ZSB",
			en: "Side wall F, ASSY"
		}
		,A2V00002571069 : {
			de: "Seitenwand A, ZSB",
			en: "Side wall A, ASSY"
		}
		,A2V00002570832 : {
			de: "Seitenwand E, ZSB",
			en: "Side wall E, ASSY"
		}
		,A2V00002570831 : {
			de: "Seitenwand D, ZSB",
			en: "Side wall D, ASSY"
		}
		,A2V00002570821 : {
			de: "Seitenwand B, ZSB",
			en: "Side wall B, ASSY"
		}
		,A2V00002570820 : {
			de: "Seitenwand C1, ZSB",
			en: "Sidewall C1, ASSY"
		}
		,A2V00002371373 : {
			de: "Huelse Motorstecker",
			en: "Bushing motor connector"
		}
		,A2V00002526780 : {
			de: "Bodenplatte",
			en: "Floor plate"
		}
		,A2V00002708292 : {
			de: "Haltevorrichtung vormontiert",
			en: "Mounting device pre-assembled"
		}
		,A2V00002737518 : {
			de: "Verbindungsstueck",
			en: "Connection piece"
		}
		,A2V00002743972 : {
			de: "Maske Verbindungsgurt sp.g.",
			en: "Cab connection i.m.i."
		}
		,A2V00002743973 : {
			de: "Maske Verbindungssteg sp.g.",
			en: "Cab connection i.m.i."
		}
		,A2V00002743974 : {
			de: "Maske Verbindungssteg w.g.",
			en: "Cab connection a.d."
		}
		,A2V00002743975 : {
			de: "Maske Verbindungsgurt w.g.",
			en: "Cab connection a.d."
		}
		,A2V00002744101 : {
			de: "Wischerkonsole",
			en: "Support wiper"
		}
		,A2V00002744129 : {
			de: "Maske Teil C",
			en: "Cab part c"
		}
		,A2V00002778574 : {
			de: "FuE Seitenwand Paneele",
			en: "RaD Sidewall Panels"
		}
		,A2V00002720732 : {
			de: "Hauptquertraeger bearb.",
			en: "Main cross girder mach."
		}
		,A2V00002031039 : {
			de: "Blech EN 485-4 EN AW-5083",
			en: "Sheet EN 485-4 EN AW-5083"
		}
		,A2V00102188848 : {
			de: "GELENKKOPF",
			en: "PIVOTING BEARING"
		}
		,A2V00397150885 : {
			de: "BEILAGSCHEIBE 10MM",
			en: "SECONDARY SUSPENSION SHIM (10MM)"
		}
		,A2V00397101395 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397101396 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397102045 : {
			de: "Strebe",
			en: "Plate"
		}
		,A2V00002115299 : {
			de: "Schaltschrank AP 500x500x210 Scharnier",
			en: "Cabinet AP 500x500x210 hinge"
		}
		,A2V00080074747 : {
			de: "Gehaeuse",
			en: "case"
		}
		,A2V00082040947 : {
			de: "Gehaeuse (Schweissteil)",
			en: "Housing (Weldment)"
		}
		,A2V00002334472 : {
			de: "Schweiszbadsicherung",
			en: "Weld backing"
		}
		,A2V00002713211 : {
			de: "Untergestell Mittelteil C1",
			en: "Underframe middle part C1"
		}
		,A2V00002712824 : {
			de: "Untergestell Mittelteil D1",
			en: "Underframe middle part D1"
		}
		,A2V00002712823 : {
			de: "Untergestell Mittelteil A",
			en: "Underframe middle part A"
		}
		,A2V00002712822 : {
			de: "Untergestell Mittelteil B",
			en: "Underframe middle part B"
		}
		,A2V00002711269 : {
			de: "Tuersaeule 1, vollst. w.g.",
			en: "Door pillar 1, compl. a.d."
		}
		,A2V00002711191 : {
			de: "Tuersaeule 1, vollst. sp.g.",
			en: "Door pillar 1, compl. i.m.i."
		}
		,A2V00002632816 : {
			de: "Stirnwand vollst. EZ sp.g.",
			en: "Bulkhead compl. TR i.m.i."
		}
		,A2V00002632584 : {
			de: "Stirnwand vollst. EZ w.g.",
			en: "Bulkhead compl. TR a.d."
		}
		,A2V00002626965 : {
			de: "Dachabschluss 3, vollst.",
			en: "Roof end 3, compl."
		}
		,A2V00002626961 : {
			de: "Dachlangtraeger, vollst.",
			en: "Roof main beam, compl."
		}
		,A2V00002626944 : {
			de: "Gelenkendtraeger DG",
			en: "Support double artic."
		}
		,A2V00002626941 : {
			de: "Endteil DG",
			en: "End part double artic"
		}
		,A2V00002626936 : {
			de: "Endteil EG",
			en: "End part single artic"
		}
		,A2V00002626587 : {
			de: "Dachabschluss 1, vollst.",
			en: "Roof end 1, compl."
		}
		,A2V00002626581 : {
			de: "Dachlangtraeger, vollst. sp.g.",
			en: "Roof main beam, compl. i.m.i."
		}
		,A2V00002626577 : {
			de: "Dachlangtraeger, vollst. w.g.",
			en: "Roof main beam, compl. a.d."
		}
		,A2V00002626469 : {
			de: "Tuersaeule 3A, vollst. sp.g.",
			en: "Door post 3A, compl. i.m.i."
		}
		,A2V00002626467 : {
			de: "Tuersaeule 3A, vollst. w.g.",
			en: "Door post 3A, compl. a.d."
		}
		,A2V00002626453 : {
			de: "Fenstersaeule geschw.",
			en: "Window-post welded"
		}
		,A2V00002626452 : {
			de: "Tuersaeule 2, geschw. sp.g.",
			en: "Door-post 2, welded i.m.i."
		}
		,A2V00002626449 : {
			de: "Tuersaeule 2, geschw. w.g.",
			en: "Door-post 2, welded a.d."
		}
		,A2V00002626220 : {
			de: "Endteil EG Klapprampe",
			en: "End part single artic. folding ramp"
		}
		,A2V00002626197 : {
			de: "Endteil Kopf geschw.",
			en: "End part front end welded"
		}
		,A2V00002626167 : {
			de: "Kopf Vorbau",
			en: "Head front end"
		}
		,A2V00002626071 : {
			de: "Gelenktraeger EG",
			en: "Support single artic."
		}
		,A2V00002535506 : {
			de: "Rippe 2",
			en: "Rib 2"
		}
		,A2V00002574157 : {
			de: "Erdungsbutzen",
			en: "Earthing button"
		}
		,A2V00002724935 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00001422644 : {
			de: "Steg, innen",
			en: "Web, inside"
		}
		,A2V00001470023 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002712506 : {
			de: "Uebergangsleiste",
			en: "cover strip profile"
		}
		,A2V00002712315 : {
			de: "Uebergangsleiste",
			en: "cover strip profile"
		}
		,A2V00002712314 : {
			de: "Uebergangsleiste",
			en: "cover strip profile"
		}
		,A2V00002743339 : {
			de: "Klimadach, FSTE, Zusb.",
			en: "AC-roof Plate, Assy."
		}
		,A2V00002743340 : {
			de: "Klimadach, KKE, Zusb.",
			en: "AC-roof, SCE, Assy."
		}
		,A2V00001841501 : {
			de: "Halter, gerade",
			en: "Holder, straight"
		}
		,A2V00001861488 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheetpiece for Work samples"
		}
		,A2V00001861490 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheetpiece for Work samples"
		}
		,A2V00001917465 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00001917589 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheetpiece for Work samples"
		}
		,A2V00001917464 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheetpiece for Work samples"
		}
		,A2V00001861458 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheetpiece for Work samples"
		}
		,A2V00001861456 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheetpiece for Work samples"
		}
		,A2V00001807330 : {
			de: "Tragarm 2",
			en: "supporting arm 2"
		}
		,A2V00001397061 : {
			de: "Rippe",
			en: "rib"
		}
		,A2V00001397093 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001242773 : {
			de: "GehÃ¤use aus Kunststoff",
			en: "Housing out of plastic"
		}
		,A2V00397204832 : {
			de: "Haengelasche",
			en: "Fixing plate"
		}
		,A2V00201176979 : {
			de: "Heizflansch komplett",
			en: "heat flange complete"
		}
		,A2V00370060179 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001239737 : {
			de: "Profile aus Stahl",
			en: "Profil from steel"
		}
		,A2V00002420112 : {
			de: "Erdungsblech",
			en: "earthing plate"
		}
		,A2V00001859765 : {
			de: "HB-Geruest und BB-Anlage kpl.",
			en: "A-Compartment and FF-system. cpl."
		}
		,A2V00002455719 : {
			de: "Flanschplatte vollst",
			en: "flange plate compl"
		}
		,A2V00105548497 : {
			de: "BOLZEN",
			en: "BOLT"
		}
		,A2V00001026570 : {
			de: "Senkantrieb, vollstÃ¤ndig",
			en: "Pantograph, complete"
		}
		,A2V00001750406 : {
			de: "Druckluftger. kpl VD",
			en: "Comp. air rack cpl. VD"
		}
		,A2V00201333819 : {
			de: "Radsatzlenker lack.",
			en: "AXLE GUIDE ROD PAINTED"
		}
		,A2V00001984826 : {
			de: "Mehrstoffschleifstueck SK907",
			en: "Collector strip SK 907"
		}
		,A2V00002719958 : {
			de: "Bedientafel +14.3 komplett",
			en: "Control panel +14.3 complete"
		}
		,A2V00001283715 : {
			de: "Drehzahlgeber GEL247 V1C- M200-Y064 4m",
			en: "Drehzahlgeber GEL247 V1C- M200-Y064 4m"
		}
		,A2V00001421039 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002517607 : {
			de: "Tuerfluegel, vollst., links",
			en: "Door panel, compl., left"
		}
		,A2V00002517606 : {
			de: "Antrieb, vollstaendig",
			en: "Drive, complete"
		}
		,A2V00109413601 : {
			de: "GFK-Fahrzeugkopf mit Innenschale",
			en: "GRP vehicle head w inner shell"
		}
		,A2V00207352868 : {
			de: "Fahrmotorventilator BR189",
			en: "Fahrmotorventilator BR189"
		}
		,A2V00200423882 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002106717 : {
			de: "Scharnier",
			en: "Hinge"
		}
		,A2V00201338111 : {
			de: "Display (K-Monitor, EBuLa)",
			en: "Display (K-Monitor, EBuLa)"
		}
		,A2V00001708781 : {
			de: "Montagematerial Druckkappe",
			en: "mounting material end cap"
		}
		,A2V00156022297 : {
			de: "Luefter",
			en: "fan"
		}
		,A2V00207450680 : {
			de: "Sandtreppe KM Ed",
			en: "Sanding step KM Ed"
		}
		,A2V00001784481 : {
			de: "Zylinderrollenlager",
			en: "cylindrical roller bearing"
		}
		,A2V00207426834 : {
			de: "Leuchtenglas oben",
			en: "Light glass, top"
		}
		,A2V00000333296 : {
			de: "Kontaktscheibe SK-K10-Niro-1.4401",
			en: "Contact disc SK-K10 stainless-1.4401"
		}
		,A2V00370036274 : {
			de: "Sechskantmutter ISO 4032 M16 8 A3C",
			en: "Hexagon nut ISO 4032 M16 8 A3C"
		}
		,A2V00001420995 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00102563215 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002455644 : {
			de: "DLA Adapter",
			en: "DLA Adaptor"
		}
		,A2V00002134403 : {
			de: "Hilfskompressor",
			en: "Auxiliary compressor"
		}
		,A2V00002466455 : {
			de: "Traktionsstromrichter mit HBU",
			en: "Traction converter with ACU"
		}
		,A2V00001779289 : {
			de: "Fahrmotor M2 - 1TB2723-0GB02",
			en: "Traction motor M2 - 1TB2723-0GB02"
		}
		,A2V00001421014 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002735188 : {
			de: "US-Geruest +49.1 KW komplett",
			en: "US-Framework +49.1 KW complete"
		}
		,A2V00002096269 : {
			de: "Blindfeld 07 vollst.",
			en: "blind field 07 compl."
		}
		,A2V00112509995 : {
			de: "Geblaese, doppelt radial 24V",
			en: "Double radial blower 24V"
		}
		,A2V00002743783 : {
			de: "Pulttafel +11.3 komplett",
			en: "Desk panel +11.3 complete"
		}
		,A2V00002743876 : {
			de: "Pulttafel +11.4 komplett",
			en: "Desk panel +11.4 complete"
		}
		,A2V00002743877 : {
			de: "Pulttafel +11.6 komplett",
			en: "Desk panel +11.6 complete"
		}
		,A2V00002734938 : {
			de: "HV-Box MC1 +A75.2 komplett",
			en: "HV-Box MC1 +A75.2 complete"
		}
		,A2V00002766897 : {
			de: "ATC Geraetetafel +C51.1 komplett",
			en: "ATC Equipm. panel +C51.1 complete"
		}
		,A2V00002111317 : {
			de: "Doppelmanometer",
			en: "double pressure gauge"
		}
		,A2V00001662250 : {
			de: "Bediengeraet FIS-E1 SL",
			en: "Operating unit FIS-E1 SL"
		}
		,A2V00001803474 : {
			de: "Getriebe",
			en: "Gearbox"
		}
		,A2V00002541973 : {
			de: "Tuersteuerung MTB3",
			en: "Door control MTB3"
		}
		,A2V00002728226 : {
			de: "Rueckwandschr. RFA +14 komplett",
			en: "Rear wall cab. RFA +14 complete"
		}
		,A2V00002743785 : {
			de: "Pulttafel +11.2 komplett",
			en: "Desk panel +11.2 complete"
		}
		,A2V00002728523 : {
			de: "WE2-Schrank re. RFC +49 komplett",
			en: "WE2-Cabinet ri. RFC +49 complete"
		}
		,A2V00002734749 : {
			de: "LV-Box T +C77 komplett",
			en: "LV-Box T +C77 complete"
		}
		,A2V00002728385 : {
			de: "Rueckwandschr. RFA +15 komplett",
			en: "Rear wall cab. RFA +15 complete"
		}
		,A2V00002533818 : {
			de: "Luftversorgungsanlage UBNG1",
			en: "Air supply unit UBNG1"
		}
		,A2V00000318948 : {
			de: "Sechskantschraube DIN 933 M6x16 A4-70 f",
			en: "Hexagon Head Screw DIN 933 M6x16 A4-70 f"
		}
		,A2V00001165883 : {
			de: "Wippenkasten 2",
			en: "Rocker box 2"
		}
		,A2V00001956648 : {
			de: "Netzfilterdrossel-Container",
			en: "Line filter reactor container"
		}
		,A2V00002391369 : {
			de: "Wellenbremszange mit Federspeicher",
			en: "axle mount. brake calliper w. park. br."
		}
		,A2V00001424909 : {
			de: "Gleitschutzventil NW12, 110VDC",
			en: "Dump valve NW12, 110VDC"
		}
		,A2V00002704640 : {
			de: "Druckluftmodul",
			en: "Compressed air module"
		}
		,A2V00397201193 : {
			de: "Obergurt, aeusserer WG",
			en: "Upper chord, outside AD"
		}
		,A2V00397201214 : {
			de: "Obergurt",
			en: "Upper chord"
		}
		,A2V00397201887 : {
			de: "Getriebestuetze lang",
			en: "gear support long"
		}
		,A2V00397203411 : {
			de: "Obergurt",
			en: "upper belt"
		}
		,A2V00397203699 : {
			de: "Steg",
			en: "web"
		}
		,A2V00397205531 : {
			de: "Anschlagplatte",
			en: "stop plate"
		}
		,A2V00397203231 : {
			de: "Daempferkonsole,sekun.vert.",
			en: "damper bracket, secondary vert."
		}
		,A2V00370033717 : {
			de: "Blech 12x2000x4000 S355J2W+N",
			en: "Sheet 12x2000x4000 S355J2W+N"
		}
		,A2V00200412185 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00207351873 : {
			de: "Bremsanzeige EP",
			en: "DESSICANT CHARGE"
		}
		,A2V00001847989 : {
			de: "AC-Netzstromwandler",
			en: "AC Line current transducer"
		}
		,A2V00002005112 : {
			de: "Luftfederventil",
			en: "Air spring valve"
		}
		,A2V00001708485 : {
			de: "Montagematerial Radsatzlagerdeckel",
			en: "mounting material wheelset front cover"
		}
		,A2V00001824245 : {
			de: "Untergurt vorne SPG",
			en: "Lower chord in front OAD"
		}
		,A2V00001824396 : {
			de: "Untergurt vorne WG",
			en: "Lower chord in front AD"
		}
		,A2V00001879430 : {
			de: "Huelse",
			en: "Sleeve"
		}
		,A2V00001812969 : {
			de: "Zwischenprofil Dach",
			en: "Intermediate profile roof"
		}
		,A2V00397239521 : {
			de: "SCHIENENRAEUMER W.G.",
			en: "rail guard a.d."
		}
		,A2V00001684272 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001169350 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001416362 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001742052 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370043688 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001809073 : {
			de: "Fahrmotor",
			en: "Traction motor"
		}
		,A2V00001161993 : {
			de: "Wippenkasten 1",
			en: "Rocker box 1"
		}
		,A2V00001165889 : {
			de: "Horn kpl. Li.",
			en: "Horn complete left"
		}
		,A2V00370033715 : {
			de: "Blech 10x2000x4000 S355J2W+N",
			en: "Sheet 10x2000x4000 S355J2W+N"
		}
		,A2V00002276122 : {
			de: "Gleitschutzeinheit N8.1",
			en: "Wheel slide protection unit N8.1"
		}
		,A2V00002304625 : {
			de: "Differenzdruckausgleichsventil 1,5bar",
			en: "Differential valve 1,5bar"
		}
		,A2V00370036273 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001778886 : {
			de: "Zentralrechner",
			en: "Central Processing Unit"
		}
		,A2V00001428452 : {
			de: "Bedienpanel aussen, Endeinstieg",
			en: "operation panel   exterior, end entrance"
		}
		,A2V00156032852 : {
			de: "3AC SPG-Erfassung mit Gehaeuse",
			en: "3AC voltage-registration with housing"
		}
		,A2V00001165618 : {
			de: "Auflaufhorn 8WY0 007-6YB38 fÃ¼r SA",
			en: "Horn 8WY0 007-6YB38 of the pantograph"
		}
		,A2V00207477443 : {
			de: "Lueftungsgitter Luftauslass, lackiert",
			en: "Air grille, air outlet, coated"
		}
		,A2V00001663103 : {
			de: "GetriebegehÃ¤use",
			en: "Transmission casing"
		}
		,A2V00001700122 : {
			de: "Scheibe DIN 7349 21 300HV GEO321B",
			en: "Washer DIN 7349 21 300HV GEO321B"
		}
		,A2V00112520718 : {
			de: "HALTEZUNGE,       VOLLST.",
			en: "securing tang, complete"
		}
		,A2V00112530718 : {
			de: "HALTEZUNGE,       VOLLST.",
			en: "securing tang, complete"
		}
		,A2V00207311997 : {
			de: "Fahrersitz",
			en: "Driver's seat"
		}
		,A2V00207352540 : {
			de: "Batterieladegeraet DC 110V 100A",
			en: "Battery charger DC 110V 100A"
		}
		,A2V00207450687 : {
			de: "Sandtreppe KM Ed",
			en: "Sand stairs KM Ed"
		}
		,A2V00002083956 : {
			de: "Hohlbuchse",
			en: "spheribloc"
		}
		,A2V00001429990 : {
			de: "Druckkappe",
			en: "end cap"
		}
		,A2V00001813931 : {
			de: "MAK-Schichtfeder",
			en: "layer spring"
		}
		,A2V00001821164 : {
			de: "Drehdaempfer",
			en: "yaw damper"
		}
		,A2V00001777372 : {
			de: "Getriebe 2LB8125-9CA47 fuer 1TB2723",
			en: "Gear 2LB8125-9CA47 for motor 1TB2723"
		}
		,A2V00001816810 : {
			de: "Motorhaengung oben",
			en: "Engine support top"
		}
		,A2V00109414322 : {
			de: "SCHWINGUNGSDAEMPFER  (108/108)",
			en: "vibration absorber (108/108)"
		}
		,A2V00002466870 : {
			de: "Fahrmotor m. Kupplungshaelfte",
			en: "Traction motor"
		}
		,A2V00370060284 : {
			de: "Puffer",
			en: "buffer"
		}
		,A2V00370075245 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical damper"
		}
		,A2V00100619191 : {
			de: "Kappenblech, fÃ¼r obere Lagerung",
			en: "REINFORCING CAP"
		}
		,A2V00370006773 : {
			de: "Grundierung Epoxy 41 Universal RAL1002",
			en: "Primer EPOXY41 RAL1002"
		}
		,A2V00370075236 : {
			de: "Daempfer sekundaer vertikal",
			en: "Secondary vertical damper"
		}
		,A2V00370084946 : {
			de: "Hydrogeraet HZY-K100-DPA",
			en: "Hydraulic device HZY-K100-DPA"
		}
		,A2V00397210059 : {
			de: "Blech SPG",
			en: "sheet opp as drawn"
		}
		,A2V00370033725 : {
			de: "Blech 20x2000x4000 S355J2W+N",
			en: "Sheet 20x2000x4000 S355J2W+N"
		}
		,A2V00370033719 : {
			de: "Blech 14x2000x4000 S355J2W+N",
			en: "Sheet 14x2000x4000 S355J2W+N"
		}
		,A2V00370033710 : {
			de: "Blech 6x2000x4000 S355J2W+N",
			en: "Sheet 6x2000x4000 S355J2W+N"
		}
		,A2V00001265895 : {
			de: "Daempfer primaer vertikal",
			en: "primary vertikal damper"
		}
		,A2V00001742051 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00112503421 : {
			de: "GLASTUER VOLLST.  (FAHRERRAUM)",
			en: "glass door drivers cabin"
		}
		,A2V00112504729 : {
			de: "VSG-GLASSCHEIBE   WERBETAFEL           .",
			en: "Glass pane for advertising board"
		}
		,A2V00370043687 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370077463 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370033721 : {
			de: "Blech 16x2000x4000 S355J2W+N",
			en: "Sheet 16x2000x4000 S355J2W+N"
		}
		,A2V00370033713 : {
			de: "Blech 8x2000x4000 S355J2W+N",
			en: "Sheet 8x2000x4000 S355J2W+N"
		}
		,A2V00370033709 : {
			de: "Blech 5x2000x4000 S355J2W+N",
			en: "Sheet 5x2000x4000 S355J2W+N"
		}
		,A2V00397210055 : {
			de: "Rohr f. Tragarm",
			en: "Pipe f. lug"
		}
		,A2V00370033723 : {
			de: "Blech 18x2000x4000 S355J2W+N",
			en: "Sheet 18x2000x4000 S355J2W+N"
		}
		,A2V00207471216 : {
			de: "Bodenpaneel links",
			en: "Floor panel left"
		}
		,A2V00002535337 : {
			de: "Ablaufhorn kompl.",
			en: "Horn compl."
		}
		,A2V00002535338 : {
			de: "Ablaufhorn kompl.",
			en: "Horn compl."
		}
		,A2V00001829715 : {
			de: "Batterieladegeraet 110V 120A",
			en: "Battery charger 110V 120A"
		}
		,A2V00370078706 : {
			de: "Radschutzkasten w.g.",
			en: "Wheel guard a.d."
		}
		,A2V00370078707 : {
			de: "Radschutzkasten sp.g.",
			en: "Wheel guard o.a.d"
		}
		,A2V00001111904 : {
			de: "Stromrichter",
			en: "Traction Converter"
		}
		,A2V00102634082 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001684848 : {
			de: "Kuehlerluefter, klein",
			en: "Blower cooling unit, small"
		}
		,A2V00002216623 : {
			de: "Blech 10X2000X4000 S355NL+N",
			en: "Sheet 10X2000X4000 S355NL+N"
		}
		,A2V00002170074 : {
			de: "Daempfer sekundaer vertikal",
			en: "Secondary vertical shock absorber"
		}
		,A2V00002140417 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002075347 : {
			de: "Mg - Bremse Schwarzer",
			en: "em - brake Schwarzer"
		}
		,A2V00370042145 : {
			de: "Flanschbremsscheibe",
			en: "Flange brake disc"
		}
		,A2V00001860047 : {
			de: "AC Hochspann.Geruest kpl.",
			en: "AC HV-com. cpl."
		}
		,A2V00001856775 : {
			de: "Baugruppentraeger FIS-Slave",
			en: "Rack PIS-Slave"
		}
		,A2V00002113214 : {
			de: "Getriebe R200B 2LB8125-9CC47",
			en: "Gear R200B 2LB8125-9CC47"
		}
		,A2V00002064359 : {
			de: "Fahrmotor U-Bahn Muenchen C2",
			en: "driving motor Metro Munich C2"
		}
		,A2V00112548974 : {
			de: "KRAFTSTOFFTANK UNGETEILT",
			en: "fuel tank undivided"
		}
		,A2V00207315012 : {
			de: "Faltenbalg",
			en: "Motor bellow"
		}
		,A2V00001134448 : {
			de: "Hydrogeraet HZY-K100-DP mit Schlauch",
			en: "Hydro unit HZY-K10-DPA with hose"
		}
		,A2V00001148802 : {
			de: "Bremssattel HYA 26/50 links",
			en: "Brake caliper HYA 26/50 left"
		}
		,A2V00205394085 : {
			de: "Steckplatte N9141",
			en: "Printed circuit board N9141"
		}
		,A2V00207280025 : {
			de: "Heizflansch vollst",
			en: "Heating flange cpl"
		}
		,A2V00355404024 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00370072930 : {
			de: "Daempfer sekundaer horizontal",
			en: "secondary horizontal damper"
		}
		,A2V00370072931 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical damper"
		}
		,A2V00200506501 : {
			de: "Sechskantmutter",
			en: "Prevailing torque type hexagon nut"
		}
		,A2V00207250241 : {
			de: "ATB-Ventil",
			en: "ATB-valve"
		}
		,A2V00001433580 : {
			de: "Schlauchleitung 2TE 10 NN90-450P",
			en: "hose assembly 2TE 10 NN90-450P"
		}
		,A2V00001856774 : {
			de: "Baugruppentraeger FIS-Master f. VED",
			en: "Rack              PIS-Master for VED"
		}
		,A2V00001435509 : {
			de: "Zugfk. Kabel ANSU zu Durchgangsisol.",
			en: "train radio cable ANSU to pass isol."
		}
		,A2V00002518694 : {
			de: "Entgleisungssimulationsgeraet",
			en: "Drailmentdetector test rig"
		}
		,A2V00112500554 : {
			de: "Konsole fuer Lenker TDG",
			en: "console for guide TDG"
		}
		,A2V00114211207 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002138845 : {
			de: "Stromabnehmer AC",
			en: "Pantograph AC"
		}
		,A2V00002132474 : {
			de: "Laufdrehgestell vollstaendig",
			en: "Trailerbogie complete"
		}
		,A2V00001077152 : {
			de: "Oberarm H Alu SWT m. Laschen",
			en: "Upper arm H Alu SWT with fishplate"
		}
		,A2V00113009395 : {
			de: "Magnetventil",
			en: "Magnet valve"
		}
		,A2V00370071528 : {
			de: "Rohradapter",
			en: "Conduit adapter"
		}
		,A2V00370068078 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00001684916 : {
			de: "Sechskantmutter ISO 4032 M12 10",
			en: "Hexagon nut ISO 4032 M12 10"
		}
		,A2V00001687313 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002103933 : {
			de: "Distanzbuchse",
			en: "Distance socket"
		}
		,A2V00002263152 : {
			de: "Gewindeplatte",
			en: "Threaded plate"
		}
		,A2V00082044978 : {
			de: "Radarsensor DRS05/1S1a mit SW51-023J",
			en: "Radar sensor DRS05/1S1a with SW 51-023J"
		}
		,A2V00001878699 : {
			de: "FahrzeuggerÃ¤t 110V LZB80E MVB-Trafo",
			en: "Vehicle Device 110V LZB80E MVB-Transf."
		}
		,A2V00082040214 : {
			de: "Antenna 5A",
			en: "Antenna 5A"
		}
		,A2V00001815992 : {
			de: "Double Display DDU8 24...110VDC Gr. 8",
			en: "Double Display DDU8 24...110 VDC size 8"
		}
		,A2V00002417619 : {
			de: "Versteifung links",
			en: "Stiffener left"
		}
		,A2V00002419985 : {
			de: "Toleranzausgleich 1 +12",
			en: "Tolerance adjustm. 1 +12"
		}
		,A2V00002426610 : {
			de: "Toleranzausgleich +13 Teil 1",
			en: "Tolerance-adjustm. +13 Part 1"
		}
		,A2V00002426918 : {
			de: "Halter E-Kette unten",
			en: "Holder e-chain down"
		}
		,A2V00002420041 : {
			de: "Toleranzausgleich Maske",
			en: "Tolerance adjustm. mask"
		}
		,A2V00200200122 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00002347196 : {
			de: "Verschleissplatte schwarz",
			en: "Wearing plate black"
		}
		,A2V00201351884 : {
			de: "Stromwandler LT4000-T/SP40",
			en: "Current transducer LT4000-T/SP40"
		}
		,A2V00000011791 : {
			de: "Isolator 0,8KV",
			en: "Insulator 0,8KV"
		}
		,A2V00001434971 : {
			de: "Zugsicher.  Ansch. kasten f. DPS-Geb.",
			en: "train protection  connec.bo.f. DPS-U"
		}
		,A2V00002110334 : {
			de: "Notrufeinrichtung Rollstuhlstellplatz 1",
			en: "Emergency Call Unit Wheel Chair Area 1"
		}
		,A2V00002178790 : {
			de: "Notrufeinrichtung Rollstuhlstellplatz 2",
			en: "Emergency Call Unit Wheel Chair Area 2"
		}
		,A2V00002233183 : {
			de: "Balg f. Einfachgelenk",
			en: "Bellows f. single artic."
		}
		,A2V00002233184 : {
			de: "Einfachgelenk unten",
			en: "Lower single articulation"
		}
		,A2V00002442293 : {
			de: "CS Klemmring",
			en: "CS Clamp ring"
		}
		,A2V00002097625 : {
			de: "Fahrmotor inkl. Sensorstecker",
			en: "Traction motor incl. sensor connector"
		}
		,A2V00102678225 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001046806 : {
			de: "Luftfederbalg",
			en: "Air spring bellow"
		}
		,A2V00001064469 : {
			de: "Daempfer 82-2498 SP1",
			en: "Damper 82-2498 SP1"
		}
		,A2V00001077014 : {
			de: "Wippenkastenaufnahme 2",
			en: "Rocker box receiver 2"
		}
		,A2V00001077095 : {
			de: "Wippenkastenaufnahme 1",
			en: "Rocker box receiver 1"
		}
		,A2V00002420209 : {
			de: "Matte",
			en: "mat"
		}
		,A2V00207426002 : {
			de: "LuftausstrÃ¶mer RD 100.75",
			en: "air injector RD 100.75"
		}
		,A2V00001857210 : {
			de: "Notrufeinrichtung",
			en: "Emergency device"
		}
		,A2V00001995364 : {
			de: "Blattfeder, vollst.",
			en: "Flat spring, compl."
		}
		,A2V00001874002 : {
			de: "Bremsscheibe W640B080PSUP II71119/2150",
			en: "Brake Disc W640B080PSUP II71119/2150"
		}
		,A2V00002535368 : {
			de: "Drehwechselrichter",
			en: "Auxiliary Power Unit"
		}
		,A2V00002515893 : {
			de: "Geraeteschrank +D59.1 komplett",
			en: "Equipment cabinet +D59.1 complete"
		}
		,A2V00002516356 : {
			de: "Geraeteschrank +B59.1 komplett",
			en: "Equipment cabinet +B59.1 complete"
		}
		,A2V00002516103 : {
			de: "Geraeteschrank +D51.1 komplett",
			en: "Equipment cabinet +D51.1 complete"
		}
		,A2V00002516374 : {
			de: "Steuerstrom Kasten +D77.2 komplett",
			en: "LV Box +D77.2 complete"
		}
		,A2V00002516386 : {
			de: "Hauptstrom Kasten +B 77.2 komplett",
			en: "HV Box +B 77.2 complete"
		}
		,A2V00002594608 : {
			de: "Tuerholm rechts",
			en: "Door pillar right"
		}
		,A2V00002598973 : {
			de: "Tuerholm links",
			en: "Door pillar left"
		}
		,A2V00002695675 : {
			de: "Pultunterschrank li +B12 E+M",
			en: "Console cabinet le +B12 E+M"
		}
		,A2V00002695868 : {
			de: "Pultunterschrank mit. E+M +B13.5",
			en: "Console cabinet cent. E+M +B13.5"
		}
		,A2V00002695923 : {
			de: "Pultunterschrank re +B13 E+M",
			en: "Console cabinet ri +B13 E+M"
		}
		,A2V00002696010 : {
			de: "Hilfsfahrerpult +49.11",
			en: "Aux. drivers desk +49.11"
		}
		,A2V00002706117 : {
			de: "Tuerholm rechts",
			en: "Door pillar right"
		}
		,A2V00002706131 : {
			de: "Tuerholm links vollst.",
			en: "Door pillar left compl."
		}
		,A2V00002535490 : {
			de: "Einzel-Bremswiderstand",
			en: "Single braking resistor"
		}
		,A2V00002535491 : {
			de: "Doppel-Bremswiderstand",
			en: "Double braking resistor"
		}
		,A2V00002705929 : {
			de: "Pultplatte 11.2 vollst.",
			en: "DriverÂ´s desk panel left 11.2 compl."
		}
		,A2V00002705930 : {
			de: "Pultplatte 11.6 vollst.",
			en: "DriverÂ´s desk pla. 11.6 compl."
		}
		,A2V00002706075 : {
			de: "Pultplatte +11.5 komplett",
			en: "Drivers desk plate +11.5 compl."
		}
		,A2V00002706089 : {
			de: "Pultplatte 11.3 vollst.",
			en: "DriverÂ´s desk plate 11.3 compl."
		}
		,A2V00002706107 : {
			de: "Pultplatte 11.4 vollst.",
			en: "DriverÂ´s desk panel 11.4 compl."
		}
		,A2V00002706126 : {
			de: "Pultplatte 11.1 vollst.",
			en: "DriverÂ´s desk plate 11.1 compl."
		}
		,A2V00001003126 : {
			de: "Magnetventil WMV-30ZG KNO:",
			en: "MAGNET VALVE WMV-30ZG"
		}
		,A2V00001127650 : {
			de: "Schleifstueck SK 1560 -SK85W- Integral",
			en: "Carbon strip SK 1560 -SK85W- integral"
		}
		,A2V00001011586 : {
			de: "StrÃ¶mungsdrossel R 1/2 Zoll KNO",
			en: "flow restrictor R 1/2 Zoll KNO"
		}
		,A2V00001136465 : {
			de: "MVB-Compact I/O EXT DC110V",
			en: "MVB-Compact I/O EXT DC110V"
		}
		,A2V00001165891 : {
			de: "Wippenkasten",
			en: "Wippenkasten"
		}
		,A2V00001108722 : {
			de: "Gleitschuh",
			en: "sliding shoe"
		}
		,A2V00002173856 : {
			de: "Einspeisevorrichtung mit Ausgleichsbeh.",
			en: "Hydraulic feeding device with compens."
		}
		,A2V00002260093 : {
			de: "Wagenbedientafel kpl., ++RAH,+94.10",
			en: "Car control panel cpl., ++RAH,+94.10"
		}
		,A2V00002051866 : {
			de: "Puffer",
			en: "buffer"
		}
		,A2V00002587952 : {
			de: "Rueckwandschr. RFA +15 komplett",
			en: "Rear wall cab. RFA +15 complete"
		}
		,A2V00002587953 : {
			de: "Rueckwandschr. RFA +14 komplett",
			en: "Rear wall cab. RFA +14 complete"
		}
		,A2V00002587955 : {
			de: "WE2 PIS-Geruest RFA +49 komplett",
			en: "WE2 PIS Frame RFA +49 complete"
		}
		,A2V00002588146 : {
			de: "HV-Box MC1 +A76 komplett",
			en: "HV-Box MC1 +A76 complete"
		}
		,A2V00002588167 : {
			de: "WE1 PIS-Geruest RFC +51 komplett",
			en: "WE1 PIS Frame RFC +51 complete"
		}
		,A2V00002588169 : {
			de: "WE2-Schrank li. RFC +59 komplett",
			en: "WE2-Cabinet le. RFC +59 complete"
		}
		,A2V00002588170 : {
			de: "WE2-Schrank re. RFC +49 komplett",
			en: "WE2-Cabinet ri. RFC +49 complete"
		}
		,A2V00002588171 : {
			de: "LV-Box T1 +C77 komplett",
			en: "LV-Box T1 +C77 komplett"
		}
		,A2V00002588172 : {
			de: "WE1 PIS-Geruest RFD +51 komplett",
			en: "WE1 PIS Frame RFD +51 complete"
		}
		,A2V00002588174 : {
			de: "WE2-Schrank li. RFD +59 komplett",
			en: "WE2-Cabinet le. RFD +59 complete"
		}
		,A2V00002588175 : {
			de: "WE2-Schrank re. RFD +49 komplett",
			en: "WE2-Cabinet ri. RFD +49 complete"
		}
		,A2V00002588176 : {
			de: "LV-Box T2 +D77 komplett",
			en: "LV-Box T2 +D77 complete"
		}
		,A2V00002759834 : {
			de: "Loeschmodul mit E-Teilen",
			en: "Fire-extinguishing module with E-parts"
		}
		,A2V00002328558 : {
			de: "Sicherungskasten RH",
			en: "Fuse box RH"
		}
		,A2V00002328557 : {
			de: "Sicherungskasten LH",
			en: "Fuse box LH"
		}
		,A2V00002706353 : {
			de: "Dachverkabelung 29A/29B",
			en: "Roof cabling 29A/29B"
		}
		,A2V00002722673 : {
			de: "KB Kopf Unterflur A74 ZSB",
			en: "CH front underfloor A74 ASSY"
		}
		,A2V00002727559 : {
			de: "KB Kopf Unterflur A74 T",
			en: "CH front underfloor A74 T"
		}
		,A2V00002780103 : {
			de: "KB Kopf Unterflur B74T",
			en: "CH front underfloor B74T"
		}
		,A2V00002780214 : {
			de: "KB Kopf Unterflur B74 ZSB",
			en: "CH front underfloor B74 ASSY"
		}
		,A2V00001788010 : {
			de: "Filtereinh. m. 12 bar Sicherheitsventil",
			en: "Filter unit w. 12 bar safety valve"
		}
		,A2V00001133113 : {
			de: "Steckbare Klemme, 5-pol",
			en: "Plug-in connector, 5-pole"
		}
		,A2V00001169339 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001169349 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001169375 : {
			de: "Senkschraube ISO 10642 M4X12 A4-70",
			en: "Hexagon socket countersunk head screw"
		}
		,A2V00001176586 : {
			de: "Sechskantmutter ISO 7040 M16 A4-70",
			en: "Hexagon nut ISO 7040 M16 A4-70"
		}
		,A2V00001181775 : {
			de: "Tyfon MKT75/660HZ",
			en: "Horn MKT75/660HZ"
		}
		,A2V00001191489 : {
			de: "Erdungslasche",
			en: "Earthing Bracket"
		}
		,A2V00002260118 : {
			de: "E-Geraetecontainer, kpl. ++RAD1, +98.10",
			en: "E-Container, cpl. ++RAD1, +98.10"
		}
		,A2V00002260119 : {
			de: "E-Geraetecontainer, kpl. ++RAE1, +98.10",
			en: "E-Container, cpl. ++RAE1, +98.10"
		}
		,A2V00002260128 : {
			de: "USC 4 komplett, ++RAC2, +95.40",
			en: "USC 4 complete, ++RAC2, +95.40"
		}
		,A2V00002192344 : {
			de: "Firebox komplett",
			en: "Firebox complete"
		}
		,A2V00002260120 : {
			de: "Wagenbedientafel kpl., ++RACx,+94.10",
			en: "Car control panel cpl., ++RACx,+94.10"
		}
		,A2V00002260117 : {
			de: "E-Geraetecontainer, kpl. ++RAC1, +98.10",
			en: "E-Container, cpl. ++RAC1, +98.10"
		}
		,A2V00002260095 : {
			de: "E-Geraetecontainer, kpl. ++RAF1, +98.10",
			en: "E-Container, cpl. ++RAF1, +98.10"
		}
		,A2V00002160335 : {
			de: "Halter 1 vollst.",
			en: "Holder 1 compl."
		}
		,A2V00002160436 : {
			de: "Halter 2 vollst.",
			en: "Holder 2 compl."
		}
		,A2V00002160437 : {
			de: "Halter 3 vollst.",
			en: "Holder 3 compl."
		}
		,A2V00002160438 : {
			de: "Halter 4 vollst.",
			en: "Holder 4 compl."
		}
		,A2V00002146126 : {
			de: "Wagenbedientafel kpl., ++RAE1,+94.10",
			en: "Car control panel cpl., ++RAE1,+94.10"
		}
		,A2V00002146869 : {
			de: "Wagenbedientafel kpl., ++RAD1,+94.10",
			en: "Car control panel cpl., ++RAD1,+94.10"
		}
		,A2V00002168029 : {
			de: "E-Container KS, kpl. ++RAF1, +76.40",
			en: "E-Container KS, cpl. ++RAF1, +76.40"
		}
		,A2V00002260121 : {
			de: "Wagenbedientafel kpl., ++RAF1,+94.10",
			en: "Car control panel cpl., ++RAF1,+94.10"
		}
		,A2V00002260124 : {
			de: "Wagenbedientafel kpl., ++RAC1,+94.10",
			en: "Car control panel cpl., ++RAC1,+94.10"
		}
		,A2V00002515894 : {
			de: "Geraeteschrank +C59.1 komplett",
			en: "Equipment cabinet +C59.1 complete"
		}
		,A2V00002515895 : {
			de: "Geraeteschrank +A59.1 komplett",
			en: "Equipment cabinet +A59.1 complete"
		}
		,A2V00002516102 : {
			de: "Geraeteschrank +C51.1 komplett",
			en: "Equipment cabinet +C51.1 complete"
		}
		,A2V00002516373 : {
			de: "Steuerstrom Kasten +C77.2 komplett",
			en: "LV Box +C77.2 complete"
		}
		,A2V00002516375 : {
			de: "Hauptstrom Kasten +A 77.2 komplett",
			en: "HV Box +A 77.2 complete"
		}
		,A2V00002566885 : {
			de: "Pultplatte +11.2 vollst.",
			en: "Drivers desk plate +11.2 compl."
		}
		,A2V00002567236 : {
			de: "Pultplatte +11.4 kompl.",
			en: "Drivers desk plate +11.4 compl."
		}
		,A2V00002567237 : {
			de: "Pultplatte +11.6 vollst.",
			en: "Drivers desk plate +11.6 compl."
		}
		,A2V00002567238 : {
			de: "Pultplatte +11.3 vollst.",
			en: "Drivers desk plate +11.3 compl."
		}
		,A2V00002567239 : {
			de: "Pultplatte +11.1 vollst.",
			en: "Drivers desk plate +11.1 compl."
		}
		,A2V00002567240 : {
			de: "Pultplatte +11.5 kompl.",
			en: "Drivers desk plate +11.5 compl."
		}
		,A2V00002386087 : {
			de: "O-Ring 100x6",
			en: "O-Ring 100x6"
		}
		,A2V00001469769 : {
			de: "Rohrschelle",
			en: "pipe clamp"
		}
		,A2V00002727405 : {
			de: "Frontsteuertafel +17.1 K komplett",
			en: "Front control panel +17.1 K complete"
		}
		,A2V00002729538 : {
			de: "Hauptstromkasten +67 K komplett",
			en: "HV-Box +67 K complete"
		}
		,A2V00002729667 : {
			de: "Steuerstromkasten +86 KW komplett",
			en: "LV-Box +86 K complete"
		}
		,A2V00002729782 : {
			de: "Geraetekasten +66 M komplett",
			en: "Equipment box +66 M complete"
		}
		,A2V00002729843 : {
			de: "Fahrerstandstafel +13.1 komplett",
			en: "Driver frame board +13.1 complete"
		}
		,A2V00002735129 : {
			de: "Geraetetafel +17.3 KN komplett",
			en: "Equipment panel +17.3 KN complete"
		}
		,A2V00002735132 : {
			de: "Geraetetafel +17.3 KS komplett",
			en: "Equipment panel +17.3 KS complete"
		}
		,A2V00002735135 : {
			de: "BMZ-Container +83 KN komplett",
			en: "Fire control box +83 KN complete"
		}
		,A2V00002735202 : {
			de: "US-Geruest +59.1 KW komplett",
			en: "US-Framework +59.1 KW complete"
		}
		,A2V00002735205 : {
			de: "US-Geruest  +49.1 MW komplett",
			en: "Framework  +49.1 MW complete"
		}
		,A2V00002735211 : {
			de: "US-Geruest +59.1 MW komplett",
			en: "US-Framework +59.1 MW complete"
		}
		,A2V00002735220 : {
			de: "KS-Tafel 59.2 KW komplett",
			en: "KS-Board 59.2 KW complete"
		}
		,A2V00002740875 : {
			de: "KS-Tafel 59.2 MW komplett",
			en: "KS-Board 59.2 MW complete"
		}
		,A2V00001816458 : {
			de: "Schlauchschelle DIN 3017-A-40-60x13",
			en: "Clamp DIN 3017-A-40-60x13"
		}
		,A2V00002594854 : {
			de: "Fahrmotor mit Kupplungsh.",
			en: "Traction motor"
		}
		,A2V00002735946 : {
			de: "Rangierpult komplett",
			en: "Shunting desk complete"
		}
		,A2V00002111316 : {
			de: "Manometer",
			en: "pressure gauge"
		}
		,A2V00370030412 : {
			de: "Dichtstoff (MS) Terostat-MS 935 schwarz",
			en: "Sealant (MS) Terostat-MS 935 black"
		}
		,A2V00002556180 : {
			de: "DC-Schnellsch. UR10-41 Ids=1.5-3.2/1.5kA",
			en: "DC-HSCB UR10-41 Ids=1.5-3.2/1.5kA"
		}
		,A2V00002583424 : {
			de: "Hydrogeraet HZY-K100-DP",
			en: "Hydraulic unit HZY-K100-DP"
		}
		,A2V00001172649 : {
			de: "Polrad",
			en: "pole wheel"
		}
		,A2V00001202511 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001211618 : {
			de: "Leitung WTB BL-02XS(ST)CHXOE 1x2xAWG 20",
			en: "Wire  WTB BL-02XS(ST)CHXOE 1x2xAWG 20"
		}
		,A2V00001181641 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001221790 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001237487 : {
			de: "Stutzen EO-EO2-WSV18LOMDCF",
			en: "Socket EO-EO2-WSV18LOMDCF"
		}
		,A2V00001193600 : {
			de: "Parallelfuehrung 3-W08.4840",
			en: "Parallel guide 3-W08.4840"
		}
		,A2V00001193601 : {
			de: "Flachfeder 4-1038.1239",
			en: "Flat spring 4-1038.1239"
		}
		,A2V00001193621 : {
			de: "Balgtraverse Stromabnehmer SBS",
			en: "Bellow traverse pantograph SBS"
		}
		,A2V00001266705 : {
			de: "Ueberstromventil",
			en: "Overflow valve"
		}
		,A2V00001274817 : {
			de: "Mutter EO-M28LCFX",
			en: "Nut"
		}
		,A2V00001195224 : {
			de: "Gewindebolzen Basis Ausf.1",
			en: "Threaded bolt base vers.1"
		}
		,A2V00001195957 : {
			de: "WIPPENKASTEN 2, DB",
			en: "WIPPENKASTEN 2, DB"
		}
		,A2V00001195958 : {
			de: "HORN KOMPL. LINKS",
			en: "HORN KOMPL. LINKS"
		}
		,A2V00001664543 : {
			de: "Radbremsscheibe",
			en: "Wheel brake disc"
		}
		,A2V00002561781 : {
			de: "Fahrmotor m. Kupplungshaelfte",
			en: "Traction motor"
		}
		,A2V00002453659 : {
			de: "Kabelhalter",
			en: "Cable bracket"
		}
		,A2V00002167268 : {
			de: "Doppelmanometer",
			en: "double pressure gauge"
		}
		,A2V00370019324 : {
			de: "Puffer",
			en: "Buffer"
		}
		,A2V00001195962 : {
			de: "Wippenkastenaufnahme",
			en: "Suspension box support"
		}
		,A2V00001195973 : {
			de: "Isolierhorn",
			en: "Insulating horn"
		}
		,A2V00001281805 : {
			de: "DC/DC Wandler 60 W, 110 V, 24 V, 2,5 A",
			en: "DC/DC Converter 60 W, 110 V, 24 V, 2,5 A"
		}
		,A2V00001294456 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001295485 : {
			de: "Zylinderschraube ISO 4762 M6X50 A4-70",
			en: "Hexagon socket head cap screw ISO 4762"
		}
		,A2V00001202705 : {
			de: "WIPPENKASTEN 2 FS",
			en: "Suspension box 2 FS"
		}
		,A2V00001205689 : {
			de: "Leitung 120 (N)HXSGAFHXOE EN 1800V 16",
			en: "Cable 120 (N)HXSGAFHXOE EN 1800V 16"
		}
		,A2V00001315510 : {
			de: "Saugkreisdrossel",
			en: "Series RC Inductor"
		}
		,A2V00001345200 : {
			de: "Display SITET XL KF b",
			en: "Drivers Desk Display SITET XL KF b"
		}
		,A2V00001348144 : {
			de: "Stutzen EO-EO2-GR18/12LCFX",
			en: "Socket EO-EO2-GR18/12LCFX"
		}
		,A2V00001355088 : {
			de: "C-Schiene, schweissen",
			en: "C-rail, welding"
		}
		,A2V00001870149 : {
			de: "RIU2 Kabelsatz mit Stecker, vorkonf.",
			en: "RIU2 cable kit with connector, pre-ass."
		}
		,A2V00002053983 : {
			de: "Halter Abhebesicherung lackiert",
			en: "holder lift lock painted"
		}
		,A2V00002073482 : {
			de: "Zugsicherung Geschw.sens. DPS-U-10.05",
			en: "train protection  speed sensor DPS-U"
		}
		,A2V00002095092 : {
			de: "Schienenraeumer lack. sp.g.",
			en: "rail guard painted o.a.d."
		}
		,A2V00002095094 : {
			de: "Schienenraeumer lack. w.g.",
			en: "rail guard painted a.d."
		}
		,A2V00002095593 : {
			de: "Schlauchleitung",
			en: "Hose assembly"
		}
		,A2V00002095595 : {
			de: "Schlauchleitung",
			en: "Hose assembly"
		}
		,A2V00002382936 : {
			de: "Daempfer primaer vertikal",
			en: "primary vertical shock absorber"
		}
		,A2V00001374578 : {
			de: "Druckschalter DS-2004",
			en: "PRESSURE SWITCH DS-2004"
		}
		,A2V00001374582 : {
			de: "Notbremsschlagtaster SBV2",
			en: "EMERG.APPLIC.VALVE SBV2"
		}
		,A2V00001376750 : {
			de: "Blattfeder",
			en: "Leaf spring"
		}
		,A2V00001245923 : {
			de: "AS-automatische Senkeinrichtung",
			en: "Automatic panto lowering unit"
		}
		,A2V00001874004 : {
			de: "Bremsscheibe W640B080PSUP II71119/2160",
			en: "Brake Disc W640B080PSUP II71119/2160"
		}
		,A2V00002355613 : {
			de: "Schellenhalter w.g.",
			en: "Clamp bracket a.d."
		}
		,A2V00001916431 : {
			de: "Montagemat. f. RSL-A.D.",
			en: "mounting mat. f. axle box end cover"
		}
		,A2V00002382939 : {
			de: "Daempfer sekundaer horizontal",
			en: "Secondary lateral shock absorber"
		}
		,A2V00002098583 : {
			de: "Wellenbremsscheibe 640/350X110-20P",
			en: "axle mounted brakedisc 640/350x110-20P"
		}
		,A2V00002410352 : {
			de: "Gewindeplatte f. Vertikaldaempfer",
			en: "Threaded plate f. vertical damper"
		}
		,A2V00002382937 : {
			de: "Drehdaempfer",
			en: "yaw damper"
		}
		,A2V00002147982 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002307805 : {
			de: "Wankbock",
			en: "ARB bracket"
		}
		,A2V00002313412 : {
			de: "Queranschlag lack",
			en: "lateral stop painted"
		}
		,A2V00002314576 : {
			de: "Isolierscheibe",
			en: "insulating washer"
		}
		,A2V00002320286 : {
			de: "Beilage 2mm",
			en: "Shim 2mm"
		}
		,A2V00002320287 : {
			de: "Beilage 1mm",
			en: "Shim 1mm"
		}
		,A2V00002320288 : {
			de: "Beilage 0.5mm",
			en: "Shim 0.5mm"
		}
		,A2V00002322833 : {
			de: "Halter lackiert",
			en: "Bracket lacquered"
		}
		,A2V00002324495 : {
			de: "Querbalken",
			en: "Cross beam"
		}
		,A2V00002324636 : {
			de: "Vormontage Hinderniserkennung",
			en: "Pre assembly obstacle detection"
		}
		,A2V00002328434 : {
			de: "Stromabnehmer LH",
			en: "Current collector LH"
		}
		,A2V00002328435 : {
			de: "Konsole LH",
			en: "Bracket LH"
		}
		,A2V00002328556 : {
			de: "Konsole RH",
			en: "Bracket RH"
		}
		,A2V00002405639 : {
			de: "Beilage t=6mm",
			en: "Shim t=6mm"
		}
		,A2V00002407723 : {
			de: "Beilage t=5mm",
			en: "Shim t=5mm"
		}
		,A2V00002407737 : {
			de: "Beilage t=7mm",
			en: "Shim t=7mm"
		}
		,A2V00002407738 : {
			de: "Beilage t=8mm",
			en: "Shim t=8mm"
		}
		,A2V00002407739 : {
			de: "Beilage t=9mm",
			en: "Shim t=9mm"
		}
		,A2V00001420992 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002273142 : {
			de: "Steuergeraet",
			en: "control device"
		}
		,A2V00002427617 : {
			de: "TPWS 4 Steuergeraet",
			en: "TPWS 4 Control Unit"
		}
		,A2V00001258884 : {
			de: "Deckel f. FÃ¼hrungsrohre",
			en: "Cap f. guide tube"
		}
		,A2V00001397266 : {
			de: "NOTBREMSVENTIL NB11",
			en: "EMERG BRAKE VALVE NB-11"
		}
		,A2V00001274814 : {
			de: "Mutter EO-M18LCFX",
			en: "Nut"
		}
		,A2V00001399932 : {
			de: "Sicherheitsventil NHS",
			en: "Safety valve NHS"
		}
		,A2V00001282886 : {
			de: "Spachtelmasse Polyester-Spachtel 5",
			en: "putty Polyester-Spachtel 5"
		}
		,A2V00001282940 : {
			de: "Haerter Haerter 5",
			en: "Hardener hardener 5"
		}
		,A2V00001285610 : {
			de: "Erdungslasche M12",
			en: "Grounding connection"
		}
		,A2V00002052963 : {
			de: "Knochenbuchse",
			en: "spheribloc"
		}
		,A2V00002058198 : {
			de: "Wellenbremszange",
			en: "Brake disc calliper / axle m."
		}
		,A2V00370079998 : {
			de: "Erdungskontakt Gerken, 720A",
			en: "Earthing contact Gerken, 720A"
		}
		,A2V00002058199 : {
			de: "Wellenbremszange",
			en: "Brake disc calliper / axle m."
		}
		,A2V00002051870 : {
			de: "Puffer",
			en: "buffer"
		}
		,A2V00002089314 : {
			de: "Konusfeder",
			en: "Conical Spring"
		}
		,A2V00001781251 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002469055 : {
			de: "Schubgelenk Avenio Meterspur",
			en: "Surge Articular Avenio metertrace"
		}
		,A2V00002092569 : {
			de: "Fenster schmal",
			en: "Window small"
		}
		,A2V00002504391 : {
			de: "Halter sp.g.",
			en: "Bracket o.a.d."
		}
		,A2V00002504537 : {
			de: "Halter w.g.",
			en: "Bracket a.d."
		}
		,A2V00002615842 : {
			de: "Stromabnehmerbalken links",
			en: "Beam for current collector left"
		}
		,A2V00002615843 : {
			de: "Stromabnehmerbalken rechts",
			en: "Beam for current collector right"
		}
		,A2V00001828326 : {
			de: "Ventiltraegergruppe",
			en: "manifold assembly"
		}
		,A2V00002093635 : {
			de: "Gleitschutzventileinheit N8.1 UBMC2",
			en: "Anti skid valve unit N8.1"
		}
		,A2V00001421036 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001423578 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002433673 : {
			de: "Schleifschuhtraeger RH",
			en: "Collector shoe support RH"
		}
		,A2V00002433674 : {
			de: "Schleifschuhtraeger LH",
			en: "Collector shoe support LH"
		}
		,A2V00002516696 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00001344150 : {
			de: "Abhebesicherung",
			en: "Lift-off safety protection"
		}
		,A2V00001416357 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001416360 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001416361 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001416363 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001416364 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001416386 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001416387 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001416388 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001416389 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001416391 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001416392 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001416708 : {
			de: "Buchse",
			en: "bush"
		}
		,A2V00001418434 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001418435 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001418497 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001418498 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001418505 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001418576 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001418577 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001419295 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001421013 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001421037 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001422684 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001422960 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001362186 : {
			de: "Gelenklager",
			en: "Spherical bearing"
		}
		,A2V00001381571 : {
			de: "Erdungskontakt AB 438",
			en: "Grounding Device AB 438"
		}
		,A2V00001437155 : {
			de: "Leitung 3MAZ0,75 CHXOE 300V",
			en: "Cable 3MAZ0,75 CHXOE 300V"
		}
		,A2V00001440633 : {
			de: "Schnellbremsventil SBV1",
			en: "Emergency Brake Valve SBV1"
		}
		,A2V00001397590 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001448848 : {
			de: "Scheibe ISO 7089 16 200HV GEO321B",
			en: "Washer ISO 7089 16 200HV GEO321B"
		}
		,A2V00001449748 : {
			de: "Sechskantmutter ISO 7040 M16 8",
			en: "Hexagon nut ISO 7040 M16 8"
		}
		,A2V00001450519 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001415216 : {
			de: "Pfette",
			en: "Purline"
		}
		,A2V00001470417 : {
			de: "Anzeigevorrichtung AZ31",
			en: "Indicator Device AZ31"
		}
		,A2V00001471820 : {
			de: "Rauchmelder",
			en: "Smoke Detector"
		}
		,A2V00001416393 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001416395 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001474052 : {
			de: "Sechskantmutter",
			en: "hexagon nut"
		}
		,A2V00001418431 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001418504 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001420986 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001420988 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001420993 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001421006 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001421009 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001421038 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001665735 : {
			de: "Drosselventil",
			en: "Throttle Valve ED-1/6-K adjustable"
		}
		,A2V00001422683 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002170205 : {
			de: "Daempfer sekundaer horizontal",
			en: "Secondary lateral shock absorber"
		}
		,A2V00002171189 : {
			de: "Daempfer sekundaer horizontal",
			en: "Secondary lateral shock absorber"
		}
		,A2V00082026015 : {
			de: "FM kl. Bauf., 3m Kabel, Abgang seitlich",
			en: "Vehicle magnet small configuration"
		}
		,A2V00002414376 : {
			de: "Befestigung, hoehenverstellbar",
			en: "Fastening, high adjustment"
		}
		,A2V00001423526 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00201351906 : {
			de: "Stromwandler GAR 0,5e",
			en: "Current transformer GAR 0,5e"
		}
		,A2V00207476531 : {
			de: "Zyklonfilter",
			en: "Cyclone filter"
		}
		,A2V00001423576 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001671808 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001678149 : {
			de: "Befestigungselement 15",
			en: "Element for fastening 15"
		}
		,A2V00001435904 : {
			de: "Deckel fuer Gleitschutz, rechts",
			en: "front cover, speed sensor right"
		}
		,A2V00001684547 : {
			de: "Haerter PU-Haerter 418/H1",
			en: "Hardener PU-Hardener 418/H1"
		}
		,A2V00001688810 : {
			de: "Sechskantmutter ISO 4032 M16 8",
			en: "Hexagon nut ISO 4032 M16 8"
		}
		,A2V00001690735 : {
			de: "Luftbehaelter DIN 5580-C1-100",
			en: "Air reservoir DIN 5580-C1-100"
		}
		,A2V00001691803 : {
			de: "Sechskantmutter ISO 7040 M20 8",
			en: "Hexagon nut ISO 7040 M20 8 GEO321APlusVL"
		}
		,A2V00001341094 : {
			de: "Knochenbuchse",
			en: "spheribloc"
		}
		,A2V00001445347 : {
			de: "Doppelmanometer HB/HL",
			en: "Pressure gauge HB/HL"
		}
		,A2V00001794740 : {
			de: "Kuehlerluefter, gross",
			en: "cooling unit fan, small"
		}
		,A2V00002028498 : {
			de: "IS BS Montagekit f. Radbremsscheibe",
			en: "IS BS Mounting kit f. wheel brake disc"
		}
		,A2V00002344046 : {
			de: "Fahrmotor Metro Riyadh",
			en: "Driving motor Metro Riyadh"
		}
		,A2V00002344048 : {
			de: "Getriebe Metro Riyadh",
			en: "Gearbox Metro Riyadh"
		}
		,A2V00001695356 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001448847 : {
			de: "Sechskantmutter ISO 4032 M16 10",
			en: "Hexagon nut ISO 4032 M16 10"
		}
		,A2V00001448913 : {
			de: "Integra Elektro-Erregermagnet WAB 1-11",
			en: "Integra transmitter magnet WAB 1-11"
		}
		,A2V00001701233 : {
			de: "Sechskantmutter ISO 7042 M20 10",
			en: "Hexagon nut ISO 7042 M20 10"
		}
		,A2V00001702834 : {
			de: "Sechskantmutter ISO 7042 M16 8",
			en: "Hexagon nut ISO 7042 M16 8"
		}
		,A2V00002121834 : {
			de: "DC HSG kpl.",
			en: "DC HVC cpl."
		}
		,A2V00002178498 : {
			de: "AC Hochspann.Geruest kpl.",
			en: "AC HV-com. complete"
		}
		,A2V00002178497 : {
			de: "DC HSG kpl.",
			en: "DC HVC cpl."
		}
		,A2V00370047940 : {
			de: "Buchse",
			en: "Bush"
		}
		,A2V00001468177 : {
			de: "Sechskantmutter ISO 4032 M10 10",
			en: "Hexagon nut ISO 4032 M10 10"
		}
		,A2V00001470793 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screwÂ "
		}
		,A2V00001709738 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001716622 : {
			de: "Gewindeplatte geschw.",
			en: "thread base plate welded"
		}
		,A2V00001664902 : {
			de: "Senkschraube",
			en: "Hexagon socket countersunk head screw"
		}
		,A2V00001722293 : {
			de: "Verkleidungsteile Fueherraum",
			en: "Cover parts Drivers cap"
		}
		,A2V00001381652 : {
			de: "Bremsbelag geteilt",
			en: "Brake pad split"
		}
		,A2V00200900388 : {
			de: "Leitung (N)HXAF EN 600V 4 GNGE",
			en: "Cable (N)HXAF EN 600V 4 GNYE"
		}
		,A2V00002255552 : {
			de: "Traktionsstromrichter SIBAC Fahrtwindkue",
			en: "Traction convertor SIBAC natural cooled"
		}
		,A2V00002164962 : {
			de: "HB-Geruest und BB-Anlage kpl.",
			en: "A-Compartment and FF-system. cpl."
		}
		,A2V00370033088 : {
			de: "Schrumpfschlauch RAUCROSS DS3 12/4-SW",
			en: "Shrunk-on hose RAUCROSS DS3 12/4-SW"
		}
		,A2V00112506417 : {
			de: "VerstÃ¤rkungsblech, unten",
			en: "Entforcement sheet"
		}
		,A2V00002143248 : {
			de: "Radschallabsorber",
			en: "Wheel noise absorber"
		}
		,A2V00002363037 : {
			de: "Fahrmotor M2 - 1TB2723-1GB02",
			en: "Traction motor M2 - 1TB2723-1GB02"
		}
		,A2V00002264121 : {
			de: "Getriebe mit Kupplungshaelfte",
			en: "Gearbox"
		}
		,A2V00002154172 : {
			de: "EP 2002 Gateway Valve",
			en: "EP 2002 Gateway Valve"
		}
		,A2V00001669268 : {
			de: "Grundbeilage2",
			en: "priming washer2"
		}
		,A2V00001756030 : {
			de: "Luftbehaelter Stahl 4l",
			en: "Air reservoir steel 4l"
		}
		,A2V00001781765 : {
			de: "Sechskantmutter ISO 4032 M20 8",
			en: "Hexagon nut ISO 4032 M20 8"
		}
		,A2V00001799862 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00001801765 : {
			de: "Haube hinten w.g.",
			en: "Cover back a.d."
		}
		,A2V00001801837 : {
			de: "Haube hinten sp.g.",
			en: "Cover back rev.im."
		}
		,A2V00001801838 : {
			de: "Haube vorne a.d.",
			en: "Cover front a.d."
		}
		,A2V00001801839 : {
			de: "Haube vorne sp.g.",
			en: "Cover front rev.im."
		}
		,A2V00001801840 : {
			de: "Seitenblech w.g.",
			en: "Side panel a.d."
		}
		,A2V00001801841 : {
			de: "Seitenblech sp.g.",
			en: "Side panel rev.im."
		}
		,A2V00001684570 : {
			de: "Sechskantmutter ISO 7042 M20 8",
			en: "Hexagon nut ISO 7042 M20 8"
		}
		,A2V00001684904 : {
			de: "Sechskantmutter ISO 4032 M24 10",
			en: "Hexagon nut ISO 4032 M24 10"
		}
		,A2V00001815263 : {
			de: "Kurzkupplungshaelfte Kurz",
			en: "Short coupler part  A1-LK1"
		}
		,A2V00001815416 : {
			de: "Kurzkupplungshaelfte lang",
			en: "Short coupler part  2 / LK1"
		}
		,A2V00001815597 : {
			de: "Notkupplung Typ 10 auf UIC",
			en: "emergency coupler typ 10 to UIC"
		}
		,A2V00001817651 : {
			de: "Verbindungsprofil",
			en: "Connection profile"
		}
		,A2V00001817652 : {
			de: "Langtraeger, FE",
			en: "Longitudinal girder, front end"
		}
		,A2V00001817760 : {
			de: "T-Profil",
			en: "T-profile"
		}
		,A2V00001817762 : {
			de: "Eckprofil",
			en: "Corner profile"
		}
		,A2V00001817764 : {
			de: "Sickenprofil",
			en: "crimping profile"
		}
		,A2V00001817777 : {
			de: "Sickenprofil",
			en: "crimping profile"
		}
		,A2V00001817868 : {
			de: "Schuerze",
			en: "Apron"
		}
		,A2V00001817875 : {
			de: "Seitenwandprofil, unten",
			en: "Lower sidewall,   profile"
		}
		,A2V00001817878 : {
			de: "Fensteruntergurt",
			en: "Window lower chord"
		}
		,A2V00001817880 : {
			de: "Fensterstiel",
			en: "window web profile"
		}
		,A2V00001817881 : {
			de: "Fensterstiel",
			en: "window web profile"
		}
		,A2V00001817882 : {
			de: "Fensterstiel",
			en: "window web profile"
		}
		,A2V00001817883 : {
			de: "Fensterstiel",
			en: "window web profile"
		}
		,A2V00001817884 : {
			de: "Fensterstiel",
			en: "window web profile"
		}
		,A2V00001817888 : {
			de: "Fensterstiel",
			en: "window web profile"
		}
		,A2V00001817891 : {
			de: "Fensterobergurt",
			en: "Window upper chord"
		}
		,A2V00001818125 : {
			de: "Seitenwandprofil, oben",
			en: "Upper sidewall    profile"
		}
		,A2V00001818207 : {
			de: "Obergurt",
			en: "Upper chord profile"
		}
		,A2V00001818208 : {
			de: "Dachplattenprofil",
			en: "Roof panel profile"
		}
		,A2V00001818209 : {
			de: "Dachplattenprofil",
			en: "roof panel profile"
		}
		,A2V00001818215 : {
			de: "Obergurtprofil, Dachgarten",
			en: "upper chord profile, roof garden"
		}
		,A2V00001818216 : {
			de: "Dachgartenprofil",
			en: "Roof garden profile"
		}
		,A2V00001818217 : {
			de: "Dachgartenprofil",
			en: "Roof garden profile"
		}
		,A2V00001818219 : {
			de: "Dachgartenprofil",
			en: "Roof garden profile"
		}
		,A2V00001818220 : {
			de: "Dachgartenprofil",
			en: "roof garden       profile"
		}
		,A2V00001818239 : {
			de: "Bodenplattenprofil",
			en: "Floor plate profile"
		}
		,A2V00001818241 : {
			de: "Bodenplattenprofil",
			en: "Floor plate profile"
		}
		,A2V00001818243 : {
			de: "Bodenplattenprofil",
			en: "Floor plate profile"
		}
		,A2V00001689225 : {
			de: "Sechskantmutter ISO 4032 M24 8",
			en: "Hexagon nut ISO 4032 M24 8"
		}
		,A2V00207425007 : {
			de: "Signalscheinwerfer",
			en: "Signal spotlight"
		}
		,A2V00114211902 : {
			de: "Hydrogeraet HZY-K100-DP",
			en: "hydro device HZY-K100-DP"
		}
		,A2V00207355509 : {
			de: "Kompaktklimageraet",
			en: "Compact air conditioner"
		}
		,A2V00001818499 : {
			de: "Abstuetzungsprofil",
			en: "Brace support profile"
		}
		,A2V00001826010 : {
			de: "Haube hinten Mitte",
			en: "Cover back  in centre"
		}
		,A2V00001826013 : {
			de: "Haube vorne Mitte",
			en: "Cover front in centre"
		}
		,A2V00001827691 : {
			de: "Drehsaeulenverrieg. kpl., re Einstieg",
			en: "Locking rotary column cpl., ri. entrance"
		}
		,A2V00001827692 : {
			de: "Drehsaeulenverrieg. kpl., li. Einstieg",
			en: "Locking rotary column cpl., le. entrance"
		}
		,A2V00001827734 : {
			de: "Bowdenzug",
			en: "bowdencable emergency device,exterior"
		}
		,A2V00002186471 : {
			de: "Stromabnehmer SBE 920.326",
			en: "Pantograph Typ SBE 920.326"
		}
		,A2V00370037640 : {
			de: "Schlauchleitung",
			en: "Hose assembly"
		}
		,A2V00370041923 : {
			de: "Radbremsscheibe 640/350",
			en: "Wheel brake disc 640/350"
		}
		,A2V00001700121 : {
			de: "Sechskantmutter ISO 4032 M20 10",
			en: "Hexagon nut ISO 4032 M20 10"
		}
		,A2V00001830321 : {
			de: "E-Schrankverkleidung, vollst.",
			en: "E-cabinet panelling, compl."
		}
		,A2V00001830642 : {
			de: "Kompaktklimageraet",
			en: "Air conditioning unit"
		}
		,A2V00207313225 : {
			de: "Adapterplatte 1 Sbb",
			en: "Adapterplatte 1 Sbb"
		}
		,A2V00002263551 : {
			de: "Fahrmotor m. Kupplungshaelfte",
			en: "Traction motor"
		}
		,A2V00002136886 : {
			de: "Notentriegelung, innere",
			en: "Internal emergency device"
		}
		,A2V00207357324 : {
			de: "Waermesteuergeraet",
			en: "heating control unit"
		}
		,A2V00001314730 : {
			de: "Schlauchleitung 2TE 16 NN90 500P",
			en: "hose assembly 2TE 16 NN90 500P"
		}
		,A2V00001421434 : {
			de: "Kombikuehler",
			en: "Cooling System"
		}
		,A2V00001835710 : {
			de: "Seitenblech w.g.",
			en: "side panel a.d."
		}
		,A2V00001835711 : {
			de: "Seitenblech sp.g.",
			en: "side panel rev.im."
		}
		,A2V00001835713 : {
			de: "Seitenblech sp.g.",
			en: "side panel rev.im."
		}
		,A2V00001835714 : {
			de: "Seitenblech w.g.",
			en: "side panel a.d."
		}
		,A2V00001835715 : {
			de: "Seitenblech sp.g.",
			en: "side panel rev.im."
		}
		,A2V00001835756 : {
			de: "Seitenblech w.g.",
			en: "side panel a.d."
		}
		,A2V00001836145 : {
			de: "Verbindungsprofil, mech. bearbeitet",
			en: "connection profile, machined"
		}
		,A2V00001839050 : {
			de: "Schalenmuffenverbindung",
			en: "Muff coupling"
		}
		,A2V00001242417 : {
			de: "Seilpaar LVT642 LD Var 2",
			en: "rope couple LVT642 TB var.2"
		}
		,A2V00001290428 : {
			de: "Notfang f. Radsatzanlenkung",
			en: "emergency catch f.wheelset guiding"
		}
		,A2V00001383862 : {
			de: "Daempfer, sek., horizontal",
			en: "Damper, sec., horizontal"
		}
		,A2V00001374555 : {
			de: "Zugsicherung Drucktaster RB-80",
			en: "train control pushbutton RB-80"
		}
		,A2V00001839312 : {
			de: "Frontfenster, vollst.",
			en: "Windscreen, compl."
		}
		,A2V00001840444 : {
			de: "Bodenplatte geschweisst",
			en: "Floor plate  welded"
		}
		,A2V00001840511 : {
			de: "Bodenplatte       geschw.",
			en: "floor plate       welded"
		}
		,A2V00001840896 : {
			de: "SRRII Weg- und Geschwindigkeitsradar",
			en: "SRRII Speed and Distance Radar"
		}
		,A2V00001841141 : {
			de: "Schuerze Langtraeger, rechts",
			en: "apron longitutinal girder,  right"
		}
		,A2V00001841142 : {
			de: "Schuerze Langtraeger,  links",
			en: "apron longitutinal girder,  left"
		}
		,A2V00001841216 : {
			de: "Dachplattenprofil",
			en: "Roof panel profile"
		}
		,A2V00001841217 : {
			de: "Dachplattenprofil",
			en: "Roof panel profile"
		}
		,A2V00001841218 : {
			de: "Obergurtprofil",
			en: "Upper chord  profile"
		}
		,A2V00370058027 : {
			de: "Drehdaempfer",
			en: "yaw damper"
		}
		,A2V00370036751 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370058095 : {
			de: "Schlauchleitung",
			en: "Hose assembly"
		}
		,A2V00001841698 : {
			de: "Luftkanal lang",
			en: "air duct long"
		}
		,A2V00001844420 : {
			de: "Gliederbruecke vollst.",
			en: "Chain bridge compl."
		}
		,A2V00082060152 : {
			de: "WIG 16P, 1,75m Ltg./Harting/Kupplg. kurz",
			en: "Odometer pulse generator"
		}
		,A2V00001845433 : {
			de: "Vorlaufkoerper    Klimaanlage",
			en: "Forward body      air conditioner"
		}
		,A2V00002076277 : {
			de: "Polrad",
			en: "pulse wheel"
		}
		,A2V00001848591 : {
			de: "Impulsgeber FS01A",
			en: "Pulse generator FS01A"
		}
		,A2V00002077817 : {
			de: "Stromabnehmer links, komplett",
			en: "Current collector left, complete"
		}
		,A2V00002077818 : {
			de: "Stromabnehmer rechts, komplett",
			en: "Current collector right, complete"
		}
		,A2V00002096975 : {
			de: "Radsatzlager",
			en: "Wheelset bearing"
		}
		,A2V00002143237 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical shock absorber"
		}
		,A2V00002143238 : {
			de: "Daempfer sekundaer horizontal",
			en: "Secondary lateral shock absorber"
		}
		,A2V00002243983 : {
			de: "AC-Vakuumhauptschalter",
			en: "AC vacuum circuit breaker"
		}
		,A2V00002114242 : {
			de: "Auflaufhorn links",
			en: "Horn of the pantograph left"
		}
		,A2V00001845933 : {
			de: "Kabelverschraubung",
			en: "Cable gland"
		}
		,A2V00001846759 : {
			de: "Luftbehaelter DIN 5590-A-3",
			en: "Air reservoir DIN 5590-A-3"
		}
		,A2V00001848048 : {
			de: "Stirnwandverkleidung links, vollst.",
			en: "End wall panelling left, compl."
		}
		,A2V00001848050 : {
			de: "Stirnwandverkleidung links, vollst.",
			en: "End wall panelling left, compl."
		}
		,A2V00001848096 : {
			de: "Blende links, vollst.",
			en: "cover left, compl."
		}
		,A2V00001848097 : {
			de: "Blende rechts, vollst.",
			en: "cover right, compl."
		}
		,A2V00001848098 : {
			de: "Blende links, vollst.",
			en: "cover left, compl."
		}
		,A2V00001848099 : {
			de: "Blende rechts, vollst.",
			en: "cover right, compl."
		}
		,A2V00001849125 : {
			de: "Dachleitungstrennschalter AC",
			en: "Disconnector AC for  roof cable"
		}
		,A2V00001852686 : {
			de: "Anzeigevorrichtung",
			en: "Display"
		}
		,A2V00001759892 : {
			de: "Sechskantmutter ISO 7042 M16 10",
			en: "Hexagon nut ISO 7042 M16 10"
		}
		,A2V00002608989 : {
			de: "Wellenbremszange ohne Federspeicher",
			en: "axle mount. brake calliper wo. park. br."
		}
		,A2V00002538016 : {
			de: "Energieverzehrelement",
			en: "Absorbtionelement"
		}
		,A2V00002538026 : {
			de: "Fuehrung",
			en: "Guide"
		}
		,A2V00001416054 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001468590 : {
			de: "Spachtelmasse PE-Faserspachtel P51 A+B",
			en: "Putty PE-Faserspachtel P51 A+B"
		}
		,A2V00001857405 : {
			de: "LED-Aussenanzeige 144x64-2,5",
			en: "LED-Outer display 144x64-2,5"
		}
		,A2V00001858144 : {
			de: "Auflageblech",
			en: "supporting plate"
		}
		,A2V00001780042 : {
			de: "Balgantrieb kompl. Alu",
			en: "Lifting device compl. Alu"
		}
		,A2V00001780047 : {
			de: "Wippe komplett DC",
			en: "Collector head compl. DC"
		}
		,A2V00001858533 : {
			de: "Rohr geschweisst",
			en: "pipe welded"
		}
		,A2V00001860393 : {
			de: "Sechskantmutter ISO 7040 M12 A4-80",
			en: "Hexagon nut ISO 7040 M12 A4-80"
		}
		,A2V00001785498 : {
			de: "Bremswiderstand",
			en: "Brake resistor"
		}
		,A2V00001860554 : {
			de: "Gleitsch.ventil GV 12-3 m. Ventiltraeger",
			en: "Anti-skid valve GV 12-3 with base plate"
		}
		,A2V00001863945 : {
			de: "Bedientafel X4E1112 V2",
			en: "Operating panel X4E1112 V2"
		}
		,A2V00001865270 : {
			de: "Schweissauflage",
			en: "weld support plate"
		}
		,A2V00001867739 : {
			de: "Kugelhahn",
			en: "Isolating Cock    SK-DN8E"
		}
		,A2V00001448849 : {
			de: "Scheibe ISO 7089 12 200HV GEO321B",
			en: "Washer ISO 7089 12 200HV GEO321B"
		}
		,A2V00001857137 : {
			de: "Sprechstelle      Zugbegleiter",
			en: "Handset conductor"
		}
		,A2V00002345337 : {
			de: "Halter LZB",
			en: "Bracket LZB"
		}
		,A2V00001869206 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001869238 : {
			de: "Logmod Seitenfenster",
			en: "Logmod Seitenfenster"
		}
		,A2V00001869491 : {
			de: "Schottblech",
			en: "partition plate"
		}
		,A2V00001869496 : {
			de: "Seitenwinkel sp.g.",
			en: "side angle rev.im."
		}
		,A2V00001869497 : {
			de: "Seitenwinkel w.g.",
			en: "side angle a.d."
		}
		,A2V00001870079 : {
			de: "Flansch",
			en: "range"
		}
		,A2V00001872643 : {
			de: "Kantenschutz",
			en: "edge protection"
		}
		,A2V00002104412 : {
			de: "Hilfsbetriebeumrichter",
			en: "Auxiliary Power Unit"
		}
		,A2V00002054794 : {
			de: "Heiz- / Lueftungsgeraet",
			en: "Heating- /  Ventilation unit"
		}
		,A2V00002118403 : {
			de: "Aussenlautsprecher LA7S mit Kabel",
			en: "external loud speaker LA7S with cable"
		}
		,A2V00002092638 : {
			de: "Fenster normal",
			en: "Window normal"
		}
		,A2V00002060188 : {
			de: "Batterie",
			en: "Battery"
		}
		,A2V00207311488 : {
			de: "Schachtfenster rechts",
			en: "Shaft window"
		}
		,A2V00002224634 : {
			de: "Fahrtenschreiber MC-B31, Den Haag",
			en: "Tachograph MC-B31, Den Haag"
		}
		,A2V00002132353 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screwÂ "
		}
		,A2V00001886833 : {
			de: "Mutter EO2-FM28LSS1",
			en: "Nut EO2-FM28LSS1"
		}
		,A2V00002118964 : {
			de: "Antriebseinheit",
			en: "Drive unit"
		}
		,A2V00001722421 : {
			de: "Erdungsbuerste S21",
			en: "earthing brush S21"
		}
		,A2V00001875584 : {
			de: "Beilage",
			en: "Supplement"
		}
		,A2V00001875979 : {
			de: "Leitung BETAtrans 4 GKW-AXplus 1800V 240",
			en: "Cable BETAtrans 4 GKW-AXplus 1800V 240"
		}
		,A2V00002393546 : {
			de: "Hauptquertraeger Mitte",
			en: "main cross girder centre"
		}
		,A2V00002393547 : {
			de: "Hauptquertraeger F-Ende",
			en: "main cross girder front end"
		}
		,A2V00002393558 : {
			de: "Hauptquertraeger Mitte",
			en: "main cross girder centre"
		}
		,A2V00001879511 : {
			de: "Motorplatte vollst",
			en: "Equipped motor plate complete"
		}
		,A2V00001879691 : {
			de: "Frischwassertank   vollst. Galley",
			en: "Fresh water tank  assy. Galley"
		}
		,A2V00001831120 : {
			de: "Wippe 1450/AC",
			en: "Collector head 1450/AC"
		}
		,A2V00001831125 : {
			de: "Huelse",
			en: "bush"
		}
		,A2V00001880588 : {
			de: "Deckenbefestigung TFT 2x19Zoll",
			en: "Ceiling mounting  TFT 2x19inch"
		}
		,A2V00001882270 : {
			de: "Rahmen Fortluftgeraet Schweissbaugruppe",
			en: "frame exhaust air unit welding assembly"
		}
		,A2V00001836042 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002255362 : {
			de: "Schleifstueck",
			en: "Carbon Strip"
		}
		,A2V00370027774 : {
			de: "Tellerfeder",
			en: "Plate washer"
		}
		,A2V00156022298 : {
			de: "LUEFTER",
			en: "fan"
		}
		,A2V00001977168 : {
			de: "Kombinierter F/B-Hebel PBC Pos 10",
			en: "Combined Drive-/Brake Controller"
		}
		,A2V00102062186 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001884978 : {
			de: "Ueberlaufrohr, vollst.",
			en: "Overflow pipe, compl."
		}
		,A2V00001854874 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001886929 : {
			de: "Mutter EO-M18LSS1X",
			en: "Nut EO-M18LSS1X"
		}
		,A2V00001887815 : {
			de: "Vkl. Schwenkfenster vollst. w.g.",
			en: "cov. swing window complete as d."
		}
		,A2V00001887916 : {
			de: "Vkl. Schwenkfenster vollst. sp.g.",
			en: "cov. swing window complete r.i."
		}
		,A2V00001887960 : {
			de: "Distanzscheibe 10mm",
			en: "spacer 10mm"
		}
		,A2V00001888476 : {
			de: "Aufstieg lackiert",
			en: "entry step coated"
		}
		,A2V00001888964 : {
			de: "Rohr, vollst.",
			en: "Pipe, complete"
		}
		,A2V00397278618 : {
			de: "Seil vollstaendig",
			en: "Robe complete"
		}
		,A2V00001889139 : {
			de: "Temp. Sensor NTC               belueftet",
			en: "Temperature sensor NTC ventilated"
		}
		,A2V00001165402 : {
			de: "Scherendaempfer komplett mit Zugstange",
			en: "system damper complete w. connecting rod"
		}
		,A2V00001890638 : {
			de: "Flansch",
			en: "flange"
		}
		,A2V00001890784 : {
			de: "Vkl. Seitenwand   links vollst. w.g.",
			en: "cov. side wall    left compl. as d."
		}
		,A2V00001891089 : {
			de: "Vkl. Seitenwand   re.vollst. sp.g.",
			en: "cov. side wall    right compl. r.i."
		}
		,A2V00001891300 : {
			de: "Vkl. Frontsch.    links vollst.w.g.",
			en: "Cov. windscreen   left compl.as.d."
		}
		,A2V00001891302 : {
			de: "Vkl. Frontsch.    rechts vollst.sp.g.",
			en: "Cov. windscreen   right compl.r.i."
		}
		,A2V00001892167 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001892168 : {
			de: "Scheibe DIN 7349 6,4 200HV-A4",
			en: "Washer DIN 7349 6,4 200HV-A4"
		}
		,A2V00001892217 : {
			de: "Anschlusskabel mit Stecker",
			en: "connecting cable with plug"
		}
		,A2V00001892778 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001891906 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001896009 : {
			de: "Pneumatikpumpe 13L  mit Magnetventilen",
			en: "pneumativ pump 13L with solenoid velve"
		}
		,A2V00001896010 : {
			de: "Steuerschrank EB1555 mit FluiCurve",
			en: "control unit EB1555 with FluiCurve"
		}
		,A2V00397152639 : {
			de: "ANLAUFRING                            ET",
			en: "WEARING RING OF PUMPS"
		}
		,A2V00001693331 : {
			de: "Federspeicherbremssattel HYS 101/04",
			en: "Spring applied brake caliper HYS 101/04"
		}
		,A2V00370032733 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00102157160 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370032435 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370061829 : {
			de: "KLEMMRING",
			en: "Driving and trailer wheel ring"
		}
		,A2V00001896976 : {
			de: "Sechskantmutter ISO 7040 M10 10",
			en: "Hexagon nut ISO 7040 M10 10"
		}
		,A2V00001897450 : {
			de: "Sandkasten, geschweisst",
			en: "sandreservoir, welded"
		}
		,A2V00001899116 : {
			de: "Transformator     Galley",
			en: "transformer       galley"
		}
		,A2V00001899933 : {
			de: "Trennwand, vollstaendig",
			en: "partition wall, complete"
		}
		,A2V00000517361 : {
			de: "FZG-Wagenkasten",
			en: "FZG-Railcar body"
		}
		,A2V00001903695 : {
			de: "Sandkasten, geschweisst",
			en: "sandreservoir     welded"
		}
		,A2V00001904340 : {
			de: "C-Schiene",
			en: "c-rail"
		}
		,A2V00001922252 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001906827 : {
			de: "Druckluftbehaelter kpl.",
			en: "Air reservoir compl"
		}
		,A2V00370052571 : {
			de: "ZENTRIEREINRICHTUNG",
			en: "centering device"
		}
		,A2V00001437489 : {
			de: "Magnetventil WMV1-NZT",
			en: "Magnet valve WMV1-NZT"
		}
		,A2V00370036260 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00370043857 : {
			de: "Dritte Schiene Stromabnehmer STEMMANN",
			en: "Third rail current collector STEMMANN"
		}
		,A2V00001908097 : {
			de: "Logmod FuÃŸbodenplatten MW5",
			en: "Logmod floor plate MW05"
		}
		,A2V00397149970 : {
			de: "Knotenstueck, mech. bearbeitet  WG",
			en: "Node part, machined AD"
		}
		,A2V00001661196 : {
			de: "Tempor. Anstuetzung fuer Kurzkupplung",
			en: "tempor. support short coupler"
		}
		,A2V00001249768 : {
			de: "KU-Kern DCC750FA (SITRAC, MVB-8) SRR-K",
			en: "Converter Core"
		}
		,A2V00104648293 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001933155 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001248753 : {
			de: "GEBERKABEL M.STECKER EZ75R L=3M",
			en: "GENERATOR WITH PLUG EZ75R L=3M"
		}
		,A2V00001909887 : {
			de: "Grauwasserbehaelter vollst.",
			en: "waste water tank assy."
		}
		,A2V00002334411 : {
			de: "E-Geraeteschrank +X15 vormontiert",
			en: "Electrical cabinet +X15 pre-assembled"
		}
		,A2V00002090798 : {
			de: "Delimon Verteiler",
			en: "delimon distributor"
		}
		,A2V00002051193 : {
			de: "Knochenbuchse",
			en: "spheribloc"
		}
		,A2V00002051189 : {
			de: "Hohlbuchse",
			en: "cylindrical bush"
		}
		,A2V00002149785 : {
			de: "E-Tafel 1, kpl.  ++RAE1, +93.10",
			en: "E-panel 1, cpl.  ++RAE1, +93.10"
		}
		,A2V00002260137 : {
			de: "E-Tafel 1 komplett,  ++RAC1,+93.10",
			en: "E-panel 1 complete,  ++RAC1,+93.10"
		}
		,A2V00002260141 : {
			de: "USC 1 komplett, ++RAC1,+95.10",
			en: "USC 1 complete, ++RAC1,+95.10"
		}
		,A2V00002260140 : {
			de: "USC 1 komplett, ++RAF1, +95.10",
			en: "USC 1 complete, ++RAF1, +95.10"
		}
		,A2V00002260139 : {
			de: "USC 1 komplett, ++RAC2, +95.10",
			en: "USC 1 complete, ++RAC2, +95.10"
		}
		,A2V00002260136 : {
			de: "USC 2 komplett, ++RAC1,+95.20",
			en: "USC 2 complete, ++RAC1,+95.20"
		}
		,A2V00002260135 : {
			de: "USC 2 komplett, ++RAF1, +95.20",
			en: "USC 2 complete, ++RAF1, +95.20"
		}
		,A2V00002260134 : {
			de: "USC 2 komplett, ++RAC2, +95.20",
			en: "USC 2 complete, ++RAC2, +95.20"
		}
		,A2V00002260133 : {
			de: "USC 3 komplett, ++RAC2, +95.30",
			en: "USC 3 complete, ++RAC2, +95.30"
		}
		,A2V00002260132 : {
			de: "USC 3 komplett, ++RAF1, +95.30",
			en: "USC 3 complete, ++RAF1, +95.30"
		}
		,A2V00002260130 : {
			de: "USC 4 komplett, ++RAC1,+95.40",
			en: "USC 4 complete, ++RAC1,+95.40"
		}
		,A2V00002260129 : {
			de: "USC 4 komplett, ++RAF1, +95.40",
			en: "USC 4 complete, ++RAF1, +95.40"
		}
		,A2V00002168209 : {
			de: "E-Geraeteschrank 3, kpl.  ++RAD, +97.30",
			en: "E-Cabinet 3, cpl.  ++RAD, +97.30"
		}
		,A2V00002260138 : {
			de: "E-Tafel 2, kpl.  ++RAC1,+93.20",
			en: "E-panel 2, cpl.  ++RAC1,+93.20"
		}
		,A2V00002163102 : {
			de: "E-Tafel 1, kpl. ++RAF1, +93.10",
			en: "E-panel 1, cpl. ++RAF1, +93.10"
		}
		,A2V00002163629 : {
			de: "E-Tafel 1 kpl.  ++RAC2, +93.10",
			en: "E-panel 1 cpl.  ++RAC2, +93.10"
		}
		,A2V00002245994 : {
			de: "Wagenbedientafel, kpl.  ++RAA2,+94.10",
			en: "Car control panel, cpl. ++RAA2,+94.10"
		}
		,A2V00002245995 : {
			de: "Wagenbedientafel, kpl.  ++RAB1,+94.10",
			en: "Car control panel, cpl. ++RAB1,+94.10"
		}
		,A2V00002150144 : {
			de: "E-Tafel 2, kpl.  ++RAE1, +93.20",
			en: "E-panel 2, cpl.  ++RAE1, +93.20"
		}
		,A2V00002164358 : {
			de: "E-Tafel 2, kpl. ++RAF1, +93.20",
			en: "E-panel 2, cpl. ++RAF1, +93.20"
		}
		,A2V00002164421 : {
			de: "E-Tafel 2, kpl.  ++RAC, +93.20",
			en: "E-panel 2, cpl.  ++RAC, +93.20"
		}
		,A2V00002167452 : {
			de: "FIS-Schrank 4, kpl.  ++RAD, +96.40",
			en: "PIS-Cabinet 4, cpl.  ++RAD, +96.40"
		}
		,A2V00002167453 : {
			de: "FIS-Schrank 3, kpl. ++RAE, ++96.30",
			en: "PIS-Cabinet  3, cpl. ++RAE, ++96.30"
		}
		,A2V00002090818 : {
			de: "Delimon DÃ¼senblock links",
			en: "Delimon spray nozzle right"
		}
		,A2V00002276409 : {
			de: "Vollrad, lackiert",
			en: "solid wheel, lacquered"
		}
		,A2V00002232512 : {
			de: "Schutzblech ATBL",
			en: "Protection plate atbl"
		}
		,A2V00002233224 : {
			de: "Schutzwinkel 2 ATBL",
			en: "Protection angel 2 ATBL"
		}
		,A2V00002233320 : {
			de: "Schutzwinkel 1 ATBL",
			en: "Protection angel 1 ATBL"
		}
		,A2V00002232505 : {
			de: "Halter Schutzmatte",
			en: "Bracket guard mat"
		}
		,A2V00002077591 : {
			de: "Sechskantmutter ISO 7042 M12 10",
			en: "Hexagon nut ISO 7042 M12 10"
		}
		,A2V00002305080 : {
			de: "Zug -/ Druckstange",
			en: "Connection Rod"
		}
		,A2V00002317723 : {
			de: "Konsole spg. lackiert",
			en: "Bracket oad. painted"
		}
		,A2V00002317724 : {
			de: "Konsole wg. lackiert",
			en: "Bracket ad. painted"
		}
		,A2V00002323548 : {
			de: "Luftfedergestaenge, 400mm",
			en: "air spring leverage, 400mm"
		}
		,A2V00002329341 : {
			de: "Konsole lackiert",
			en: "Bracket coated"
		}
		,A2V00397133352 : {
			de: "SCHEIBE DA56/DI31x3-200HV-A2",
			en: "WASHER DA56/DI31x3-200HV-A2"
		}
		,A2V00002138030 : {
			de: "IDC +165.80 komplett 12-Teiler FLU",
			en: "IDC +165.80 complete 12-car FLU"
		}
		,A2V00002156519 : {
			de: "Luftbehaeltermodul QA_U84, vorm.",
			en: "air reservoir mod. QA_U84, pre-installed"
		}
		,A2V00002355879 : {
			de: "USK +42 Komplett",
			en: "USB +42 Complete"
		}
		,A2V00002378063 : {
			de: "Verschleissteilpaket",
			en: "Wear parts kit"
		}
		,A2V00002219732 : {
			de: "Beilage 3mm",
			en: "Shim 3mm"
		}
		,A2V00001911326 : {
			de: "Trennwand",
			en: "partition wall"
		}
		,A2V00001911353 : {
			de: "Luftkanal kurz",
			en: "air duct short"
		}
		,A2V00001913527 : {
			de: "Luftkanal kurz",
			en: "air duct short"
		}
		,A2V00001913668 : {
			de: "Platte",
			en: "plate"
		}
		,A2V00001959667 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001959671 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001916753 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screwÂ "
		}
		,A2V00001964177 : {
			de: "Isolierscheibe",
			en: "insulating shim"
		}
		,A2V00001964193 : {
			de: "Isolierhuelse",
			en: "insulating sleeve"
		}
		,A2V00001964911 : {
			de: "Zylinderschraube",
			en: "hexagon socket head cap screwÂ "
		}
		,A2V00001919346 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00001972053 : {
			de: "Schleifstueck SK 1298 -SK85ACu-",
			en: "Carbon strip SK 1298 -SK85ACu-"
		}
		,A2V00001921925 : {
			de: "Abdeckung 76",
			en: "cover 76"
		}
		,A2V00001922154 : {
			de: "Bodenbelag",
			en: "floor covering"
		}
		,A2V00001976950 : {
			de: "Luftfederbalg",
			en: "Bellows"
		}
		,A2V00001977016 : {
			de: "Wippe kompl. 1450/DC",
			en: "Collector head compl. 1450/DC"
		}
		,A2V00370018954 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00001666178 : {
			de: "CS Kegelrollenlager, stromisoliert",
			en: "CS Tapper roller bearing, isolated"
		}
		,A2V00001666179 : {
			de: "CS Kegelrollenlager, stromisoliert",
			en: "CS Tapper roller bearing, isolated"
		}
		,A2V00001666184 : {
			de: "Radnabe",
			en: "Wheel hub"
		}
		,A2V00001666186 : {
			de: "Radnabe",
			en: "Wheel hub"
		}
		,A2V00397124611 : {
			de: "Blech fuer Firmenschild",
			en: "Plate for Serial Number"
		}
		,A2V00001434988 : {
			de: "Zugsicherung      Block KON",
			en: "train protection  module KON"
		}
		,A2V00001438643 : {
			de: "Zugfunk Nussisolator",
			en: "train radio nut   isolator"
		}
		,A2V00001438644 : {
			de: "Zugfunk Schraubenzugeinrichtung",
			en: "train radio ant.  spanner nut"
		}
		,A2V00001785706 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00100195880 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001984659 : {
			de: "Verstellhebel",
			en: "Control lever"
		}
		,A2V00001988184 : {
			de: "Sechskantmutter ISO 7040 M16 10",
			en: "Hexagon nut ISO 7040 M16 10"
		}
		,A2V00001926285 : {
			de: "Platte",
			en: "plate"
		}
		,A2V00001926304 : {
			de: "Halter sp. g.",
			en: "fastener rev. im."
		}
		,A2V00001926305 : {
			de: "Halter w. g.",
			en: "fastener a. d."
		}
		,A2V00001989068 : {
			de: "Blech EN 10029 12X2000X4000 S355J2W+N",
			en: "Sheet EN 10029 12X2000X4000 S355J2W+N"
		}
		,A2V00001992457 : {
			de: "Sechskantmutter ISO 7040 M12 10",
			en: "Hexagon nut ISO 7040 M12 10"
		}
		,A2V00001927923 : {
			de: "Bodenbelag",
			en: "floor covering"
		}
		,A2V00001992484 : {
			de: "Wippe komplett (AC - 1600)",
			en: "Collector head complete (AC - 1600)"
		}
		,A2V00001930204 : {
			de: "Vkl. Dach rechts  elektr. vollst.",
			en: "cov. ceiling right elec. complete"
		}
		,A2V00370080139 : {
			de: "SCHRAUBENDRUCKFEDER",
			en: "HELICAL COMPRESSION SPRING"
		}
		,A2V00207353042 : {
			de: "Kuehlturm BR189 mit Buchholtzrelais",
			en: "Cooling tower BR189 with Buchholtzrelay"
		}
		,A2V00001930840 : {
			de: "Vkl. C Saeule rechts 02  sp.g. vollst.",
			en: "cov. C pillar right 02    r.i. complete"
		}
		,A2V00001930841 : {
			de: "Vkl. Schrank hinten w.g.    links",
			en: "cov. Lining back as d.   left"
		}
		,A2V00001930842 : {
			de: "Vkl. C Saeule rechts 03  sp.g. vollst.",
			en: "cov. C pillar right 03   r.i. complete"
		}
		,A2V00001930843 : {
			de: "Vkl. C Saeule links 01  w.g. GFK vollst.",
			en: "cov. C pillar left 01   as d. GRP compl."
		}
		,A2V00001930845 : {
			de: "Vkl. C Saeule links 03   w.g. vollst.",
			en: "cov. C pillar left 03    as d. complete"
		}
		,A2V00001930866 : {
			de: "Vkl. C Saeule links 02    w.g. vollst.",
			en: "cov. C pillar left 02    as d. complete"
		}
		,A2V00001930867 : {
			de: "Vkl. Schrank hinten sp.g. rechts",
			en: "cov. lining back r.i.       right"
		}
		,A2V00370080461 : {
			de: "DISTANZBUEGEL",
			en: "BRACKET DISTANCE"
		}
		,A2V00207354134 : {
			de: "Erdungstrenner",
			en: "Earthing disconnector"
		}
		,A2V00207354828 : {
			de: "2/2-Magnetventil",
			en: "2/2 way magnet valve"
		}
		,A2V00001414619 : {
			de: "Notkupplung Typ B",
			en: "emergency coupler typ B"
		}
		,A2V00397121905 : {
			de: "Schraubensicherung",
			en: "Screw locking"
		}
		,A2V00397162024 : {
			de: "ANLENKSTANGE,LACKIERTE",
			en: "TRACTION ROD FORGED"
		}
		,A2V00001232007 : {
			de: "Fahrmotorventilator",
			en: "Traction motor blower"
		}
		,A2V00002154173 : {
			de: "EP2002 Gateway Ventil",
			en: "EP2002 Gateway Valve"
		}
		,A2V00002154517 : {
			de: "EP2002 Smart Ventil",
			en: "EP2002 Smart Valve"
		}
		,A2V00370036723 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002431403 : {
			de: "Flanschplatte vollst",
			en: "flange plate compl"
		}
		,A2V00001932479 : {
			de: "Vkl. Dach links   elektr. vollst.",
			en: "cov. ceiling left elec. complete"
		}
		,A2V00001933100 : {
			de: "Druckbehaelter A25-300-U",
			en: "Pressure vessel A25-300-U"
		}
		,A2V00001933147 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002006342 : {
			de: "Blech EN 10029 30X2000X4000 S355J2+N",
			en: "Sheet EN 10029 30X2000X4000 S355J2+N"
		}
		,A2V00002006918 : {
			de: "Blech EN 10029 16X2000X4000 S355J2W+N",
			en: "Sheet EN 10029 16X2000X4000 S355J2W+N"
		}
		,A2V00002019962 : {
			de: "Hohlbuchse",
			en: "cylindrical bush"
		}
		,A2V00001935304 : {
			de: "Bodenbelag",
			en: "floor covering"
		}
		,A2V00001935569 : {
			de: "Gusshalter",
			en: "Cast support"
		}
		,A2V00002022460 : {
			de: "Sechskantmutter ISO 7040 M8 A4-80",
			en: "Hexagon nut ISO 7040 M8 A4-80"
		}
		,A2V00001935979 : {
			de: "Blende Frontrollo vollst.",
			en: "cov. roller blind complete"
		}
		,A2V00001936746 : {
			de: "Gang Decke el.",
			en: "cov. ceiling el."
		}
		,A2V00001937148 : {
			de: "Deflektor Wagenuebergang",
			en: "Deflector Gangway"
		}
		,A2V00001937568 : {
			de: "Verkl. Dach mitte vollst.",
			en: "lining roof middle compl."
		}
		,A2V00001939036 : {
			de: "Bodenbelag",
			en: "floor covering"
		}
		,A2V00001940363 : {
			de: "Bodenbelag",
			en: "floor covering"
		}
		,A2V00001940605 : {
			de: "Blende SW rechts   mech. vollst.",
			en: "Cover SW right     mech. compl."
		}
		,A2V00001940756 : {
			de: "Blende SW links   mech. vollst.",
			en: "Cover SW left     mech. compl."
		}
		,A2V00001977463 : {
			de: "Traktionsstromrichter  (DC-Umrichter)",
			en: "Traction converter, DC"
		}
		,A2V00001941748 : {
			de: "Auflageblech",
			en: "supporting plate"
		}
		,A2V00001747389 : {
			de: "Stromversorgung 14,4-154V/5V+-15V, 50W",
			en: "Converter 14,4V-154V/5V+-15V, 50W"
		}
		,A2V00002045899 : {
			de: "Bremssteuergeraet Typ 3 mit MVB",
			en: "brake control unit Type 3 with MVB"
		}
		,A2V00002126394 : {
			de: "Kolbenkompressor KK15",
			en: "piston compressor  KK15"
		}
		,A2V00002144669 : {
			de: "Luftbehaelter DIN 5590-A-15 lackiert",
			en: "Air reservoir DIN 5590-A-15 coated"
		}
		,A2V00370053225 : {
			de: "Hohlbuchse",
			en: "Cylindrical bush"
		}
		,A2V00370082323 : {
			de: "Rueckschlagventil G1/2",
			en: "Check valve G1/2"
		}
		,A2V00002048500 : {
			de: "Display Anzeigesystem CaViS12 mit MVB",
			en: "Display System CaViS12 with MVB"
		}
		,A2V00001954088 : {
			de: "Nasszelle links, vollst.",
			en: "Sanitary facilities left, compl."
		}
		,A2V00002051320 : {
			de: "Bremssteuergeraet Typ 3 mit PN",
			en: "brake control unit Type 3 with PN"
		}
		,A2V00001954677 : {
			de: "Hochspannungs-Dachuebergang",
			en: "HV - Double Spiral Coil"
		}
		,A2V00002096304 : {
			de: "Luftfedersystem",
			en: "air spring system"
		}
		,A2V00002115705 : {
			de: "Wellenbremszange mit Federspeicher",
			en: "axle mount. brake calliper w. park. br."
		}
		,A2V00002115826 : {
			de: "Wellenbremszange ohne Federspeicher",
			en: "axle mount. brake calliper wo. park. br."
		}
		,A2V00001955093 : {
			de: "Luftbehaelter DIN 5580-A-19",
			en: "Air reservoir DIN 5580-A-19"
		}
		,A2V00001955377 : {
			de: "Halter kpl",
			en: "holder compl"
		}
		,A2V00002564987 : {
			de: "Luftversorgungsanlage Metro Sofia ML3",
			en: "Air supply unit Metro Sofia ML3"
		}
		,A2V00002556943 : {
			de: "Drehzahlgeber GEL2475Y192",
			en: "speed sensor GEL2475Y192"
		}
		,A2V00002564986 : {
			de: "Bremsgeraeteeinheit Sofia",
			en: "Brake equipment unit Sofia"
		}
		,A2V00001660257 : {
			de: "Beilage 0,5 mm",
			en: "Shim 0,5 mm"
		}
		,A2V00370065104 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001958374 : {
			de: "Abfallbehaelter,  voll.",
			en: "waste bin, compl."
		}
		,A2V00002059400 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002059626 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00000378782 : {
			de: "FM kl. Bauf., 3m Kabel, Abgang oben",
			en: "Vehicle magnet small configuration"
		}
		,A2V00001960417 : {
			de: "Deflektor Bug, schraeg",
			en: "Deflector front-end,      sloping"
		}
		,A2V00370037709 : {
			de: "LENKERBUCHSE",
			en: "LENKERBUCHSE"
		}
		,A2V00370037710 : {
			de: "Anschlag",
			en: "Stop"
		}
		,A2V00002102398 : {
			de: "E.1600 Triebdrehgestell 5",
			en: "E.1600 Motorbogie 5"
		}
		,A2V00001806156 : {
			de: "DC-Schnellschalter IR6040SV 24V",
			en: "High speed circuit breaker IR6040SV 24V"
		}
		,A2V00002266382 : {
			de: "Kompressormodul TA6 KVMRT",
			en: "Compressor module TA6 KVMRT"
		}
		,A2V00001849355 : {
			de: "Klappe lang, komplett",
			en: "Cover long, complete"
		}
		,A2V00001929099 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001857367 : {
			de: "TFT-Vorraumanzeige 15Zoll",
			en: "TFT-Foyer Display 15inch"
		}
		,A2V00002094280 : {
			de: "Beilage 1mm",
			en: "Shim 1mm"
		}
		,A2V00370036729 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001966239 : {
			de: "Deflektor Bug, vollst.2",
			en: "Deflector front-end,      compl.2"
		}
		,A2V00002067496 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002076758 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001973970 : {
			de: "Verstaerkung      w.g.",
			en: "reinforcement     a.d."
		}
		,A2V00001973971 : {
			de: "Verstaerkung      sp.g.",
			en: "reinforcement     rev.im."
		}
		,A2V00001974797 : {
			de: "Seitenfenster Mittel",
			en: "Side window"
		}
		,A2V00207350835 : {
			de: "Fahrzeugmagnet kleine Bauform",
			en: "Vehicle magnet small version"
		}
		,A2V00207450785 : {
			de: "Heizflansch komplett",
			en: "Heating flange complete"
		}
		,A2V00201334715 : {
			de: "Druckwaechter",
			en: "Pressure control device"
		}
		,A2V00207351463 : {
			de: "Wegimpulsgeber 16P",
			en: "Speed sensor 16P"
		}
		,A2V00207352700 : {
			de: "Elektromagn. Aufschlaghorn 335 Hz",
			en: "Electric impact horn 335 Hz"
		}
		,A2V00001976600 : {
			de: "Klimageraet Fuehrerraum",
			en: "Air condition unit cab"
		}
		,A2V00001976602 : {
			de: "Fortluftgeraet",
			en: "Exhaust air unit"
		}
		,A2V00001978208 : {
			de: "Frontfenster",
			en: "Wind screen"
		}
		,A2V00370008222 : {
			de: "Haerter PU 401",
			en: "Hardener PU 401"
		}
		,A2V00370010719 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370040193 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002528896 : {
			de: "Traktionsstromrichter SIBAC C2G-750 15-C",
			en: "Traction convertor SIBAC C2G-750 15-C"
		}
		,A2V00001170821 : {
			de: "Stromabnehmer 1DSA250.12 m Schleifleiste",
			en: "Pantograph  1DSA250.12 with contact rail"
		}
		,A2V00001170896 : {
			de: "Stromabnehmer 1DSA250.14 m Schleifleiste",
			en: "Pantograph 1DSA250.14 with contact rail"
		}
		,A2V00001984190 : {
			de: "Druckbehaelter A57-350",
			en: "Pressure vessel A57-350"
		}
		,A2V00002090610 : {
			de: "Delimon DÃ¼senblock rechts",
			en: "Delimon spray nozzle left"
		}
		,A2V00002091381 : {
			de: "Sechskantmutter ISO 7040 M20 10",
			en: "Hexagon nut ISO 7040 M20 10"
		}
		,A2V00002092716 : {
			de: "Drehzapfen, mech. bearb.",
			en: "Pivot pin, machined"
		}
		,A2V00102275490 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002536520 : {
			de: "Bremswiderstand 3PQ4-B00960",
			en: "Brake resistor 3PQ4-B00960"
		}
		,A2V00001991859 : {
			de: "Fuehrerraumrueckwand",
			en: "driver's cab backwall"
		}
		,A2V00397200493 : {
			de: "Getriebestuetze kurz",
			en: "Gear support short"
		}
		,A2V00397200637 : {
			de: "Versteifung",
			en: "stiffening"
		}
		,A2V00397202790 : {
			de: "Getriebestuetze",
			en: "gear support"
		}
		,A2V00002120225 : {
			de: "DUW GerÃ¤t 6FH4947-1A",
			en: "DM, 6FH4947-1A"
		}
		,A2V00002197374 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370058394 : {
			de: "Schelle Axialfixierung halb R=25,5",
			en: "Clamp axial fixed half R=25,5"
		}
		,A2V00002100955 : {
			de: "Dehnhuelse",
			en: "Extension sleeve"
		}
		,A2V00002102549 : {
			de: "Dehnhuelse",
			en: "Extension sleeve"
		}
		,A2V00001171621 : {
			de: "DC / DC-Wandler",
			en: "DC / DC-Converter"
		}
		,A2V00002103860 : {
			de: "Scheibe PTFE",
			en: "Disk PTFE"
		}
		,A2V00002151593 : {
			de: "Stuetzlager",
			en: "supporting bearing"
		}
		,A2V00001915702 : {
			de: "Hilfsbetriebeumrichter",
			en: "Auxiliary Power Unit"
		}
		,A2V00002141498 : {
			de: "Bremsmodul TW ICx",
			en: "Brakemodul motor car ICx"
		}
		,A2V00002219347 : {
			de: "Bremsmodul LW3 ICx",
			en: "Brakemodul trailer car ICx"
		}
		,A2V00002219187 : {
			de: "Bremsmodul EW ICx",
			en: "Brakemodul trailer car ICx"
		}
		,A2V00002219357 : {
			de: "Bremsmodul LW4 ICx",
			en: "Brakemodul trailer car ICx"
		}
		,A2V00002431542 : {
			de: "Stromabnehmereinheit",
			en: "Current collector module"
		}
		,A2V00002154442 : {
			de: "Erweiterungsbaugruppe TP12",
			en: "extension board TP12"
		}
		,A2V00207352635 : {
			de: "Handapparat Funk 75B",
			en: "Hand set funk HA 75B"
		}
		,A2V00207352877 : {
			de: "Dachdurchfuehrung",
			en: "Roof bushing"
		}
		,A2V00002566275 : {
			de: "Kabeldurchfuehrung Batterie",
			en: "cable feedthrough battery"
		}
		,A2V00002018124 : {
			de: "Antrieb,  w.g.",
			en: "drive unit, as d."
		}
		,A2V00002018125 : {
			de: "Antrieb, sp. g.",
			en: "drive unit, r. i."
		}
		,A2V00002018241 : {
			de: "Portalrahmen sp.gl.",
			en: "Portal frame sym.opp."
		}
		,A2V00001669460 : {
			de: "Pufferschalter",
			en: "limit switch"
		}
		,A2V00156004652 : {
			de: "Leitung RADOX 4GKW-AX 1800V M 1x70",
			en: "Cable RADOX 4GKW-AX 1800V M 1x70"
		}
		,A2V00200506556 : {
			de: "Sechskantmutter ISO 7042 M20 8 A3C",
			en: "Hexagon nut ISO 7042 M20 8 A3C"
		}
		,A2V00002141415 : {
			de: "Bremsmodul LW2 ICx",
			en: "Brakemodul trailer car ICx"
		}
		,A2V00114211010 : {
			de: "Ventilsteuerstufe VSS",
			en: "Valve control unit VSS"
		}
		,A2V00002110925 : {
			de: "Halter Zielanzeige vollst.",
			en: "Holder destination display compl."
		}
		,A2V00001170944 : {
			de: "Drehzahlgeber GEL 247",
			en: "Speed sensor GEL247"
		}
		,A2V00002111677 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002111682 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002112127 : {
			de: "Blechteil 3mm, 1/55Â°",
			en: "Sheetpiece 3mm, 1/55Â°"
		}
		,A2V00002112701 : {
			de: "Sechskant-Passschraube",
			en: "Hexagon fit bolt"
		}
		,A2V00397186109 : {
			de: "Anschlag",
			en: "STOP"
		}
		,A2V00200319304 : {
			de: "Blech ISO 9445  X5CrNi18-10",
			en: "Sheet ISO 9445  X5CrNi18-10"
		}
		,A2V00397179805 : {
			de: "Rohrhalter, gerade",
			en: "Holder f. pipe, straight"
		}
		,A2V00002147974 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002124675 : {
			de: "Isolierplatte",
			en: "insulating plate"
		}
		,A2V00002124781 : {
			de: "Isolierscheibe",
			en: "insulating disc"
		}
		,A2V00002127559 : {
			de: "Isolierhuelse",
			en: "insulating ferrule"
		}
		,A2V00002434773 : {
			de: "RSL-Deckel vorne lackiert EK",
			en: "Axle box front cover painted GB"
		}
		,A2V00370055566 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00397193340 : {
			de: "ERDUNG MG-BREMSE",
			en: "GROUNDING TRACK BRACK"
		}
		,A2V00370060211 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001416390 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001421015 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001425109 : {
			de: "Decklack Etokat Aqua DS Typ DB RAL 7016",
			en: "Top coat Etokat Aqua DS Typ DB RAL 7016"
		}
		,A2V00370029389 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002037152 : {
			de: "Knotenblech w.g.",
			en: "gusset plate a.d."
		}
		,A2V00002037153 : {
			de: "Knotenblech sp.g.",
			en: "gusset plate      rev.im."
		}
		,A2V00002037518 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002039293 : {
			de: "Fensterstiel",
			en: "window web profile"
		}
		,A2V00002082971 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002117821 : {
			de: "Pult vollst.",
			en: "Driver desk complete"
		}
		,A2V00002044449 : {
			de: "Langtraeger sp.g.",
			en: "longitudinal girder rev.im."
		}
		,A2V00002044450 : {
			de: "Langtraeger w.g.",
			en: "longitudinal girder a.d."
		}
		,A2V00001210289 : {
			de: "Erdungsauge",
			en: "Grounding lug"
		}
		,A2V00002096931 : {
			de: "Schweissbadsicherung 1",
			en: "Weld backing 1"
		}
		,A2V00002344732 : {
			de: "FDUE Interfacemodul A-Muster",
			en: "BDM interface unit A-sample"
		}
		,A2V00002720468 : {
			de: "Antenne vollst. - RechtsausfÃ¼hrung",
			en: "antenna compl. - right hand"
		}
		,A2V00002720466 : {
			de: "Antenne vollst. - LinksausfÃ¼hrung",
			en: "antenna compl. - left hand"
		}
		,A2V00370033089 : {
			de: "Schrumpfschlauch Raucross DS3 24/8-SW",
			en: "Heat shrinking tube Raucross DS3 24/8-SW"
		}
		,A2V00002683333 : {
			de: "Lager Stromabnehmer",
			en: "Bushing current collector"
		}
		,A2V00002711453 : {
			de: "Fahrmotor",
			en: "Traction motor"
		}
		,A2V00002746997 : {
			de: "Bremsscheibe vollstÃ¤ndig",
			en: "Disc-brake complete"
		}
		,A2V00002746714 : {
			de: "Bremszange vollstÃ¤ndig",
			en: "Brake Caliper complete"
		}
		,A2V00002118639 : {
			de: "Bolzentraeger, vollst.",
			en: "Bolt support, compl."
		}
		,A2V00002120389 : {
			de: "Bremsgeraetetafel fuer Park- und MTB",
			en: "brake panel for parking brake and MTB"
		}
		,A2V00002120390 : {
			de: "Bremsgeraetetafel direkte u. ind. Bremse",
			en: "brake panel for direct and ind. brake"
		}
		,A2V00002052888 : {
			de: "Fensterstiel",
			en: "window web profile"
		}
		,A2V00002057038 : {
			de: "Luftfederungsventil SV1205-GD",
			en: "Leveling valve SV1205-GD"
		}
		,A2V00002057039 : {
			de: "Luftfederungsventil SV1205-G",
			en: "Level control valve SV1205-G"
		}
		,A2V00002059908 : {
			de: "Sitzbefestigung",
			en: "seat attachment"
		}
		,A2V00002062446 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002067151 : {
			de: "Befestigungsplatte",
			en: "mounting plate"
		}
		,A2V00002067153 : {
			de: "Befestigungsplatte",
			en: "mounting plate"
		}
		,A2V00002067154 : {
			de: "Befestigungsplatte",
			en: "mounting plate"
		}
		,A2V00002132518 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002074164 : {
			de: "Netzfilterdrossel",
			en: "Line reactor"
		}
		,A2V00002133385 : {
			de: "Nutenstein m. Feder",
			en: "Slot nut with spring"
		}
		,A2V00002134120 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002079007 : {
			de: "Befestigungsplatte",
			en: "mounting plate"
		}
		,A2V00002079008 : {
			de: "Befestigungsplatte",
			en: "mounting plate"
		}
		,A2V00002079009 : {
			de: "Befestigungsplatte",
			en: "mounting plate"
		}
		,A2V00002079010 : {
			de: "Befestigungsplatte",
			en: "mounting plate"
		}
		,A2V00002079011 : {
			de: "Befestigungsplatte",
			en: "mounting plate"
		}
		,A2V00002079013 : {
			de: "Befestigungsplatte",
			en: "mounting plate"
		}
		,A2V00002079434 : {
			de: "Bremswiderstand",
			en: "Braking resistor"
		}
		,A2V00002080525 : {
			de: "Blech sp.g. KK-Ende",
			en: "plate r.i. short coupler end"
		}
		,A2V00002080734 : {
			de: "Verstaerkung",
			en: "support"
		}
		,A2V00002080735 : {
			de: "Verstaerkung w.g.",
			en: "support as d."
		}
		,A2V00002080836 : {
			de: "Blech w.g.        KK-Ende",
			en: "plate as d.       short coupler end"
		}
		,A2V00002080837 : {
			de: "Blech KK-Ende",
			en: "plate short coupler end"
		}
		,A2V00002080896 : {
			de: "Verstaerkung sp.gl.",
			en: "support r.i."
		}
		,A2V00002081295 : {
			de: "Schweissbadsicherung",
			en: "weld pool baking"
		}
		,A2V00002081296 : {
			de: "Quertraeger",
			en: "cross girder"
		}
		,A2V00002081347 : {
			de: "Knotenblech",
			en: "support plate"
		}
		,A2V00002081442 : {
			de: "Abdeckblech",
			en: "cover sheet"
		}
		,A2V00002081476 : {
			de: "Seitenblech sp.gl. F-Ende",
			en: "side sheet r.i.   front end"
		}
		,A2V00002081477 : {
			de: "Seitenblech w.g. F-Ende",
			en: "side sheet as d. front end"
		}
		,A2V00002674813 : {
			de: "Erdungskontakt",
			en: "Grounding device"
		}
		,A2V00370072760 : {
			de: "Wellrohrverschraubung, 90Â°",
			en: "Conduit connector, 90Â°"
		}
		,A2V00001959669 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screwÂ "
		}
		,A2V00002696373 : {
			de: "Passschraube",
			en: "Fit bolt"
		}
		,A2V00002333922 : {
			de: "Bolzen ISO 13918 - RD M8x50 - A4-50",
			en: "Stud ISO 13918 - RD M8x50 - A4-50"
		}
		,A2V00002517530 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00002084093 : {
			de: "Halter geschweisst",
			en: "holder welded"
		}
		,A2V00002140155 : {
			de: "Halterung sp.g.",
			en: "Support i.m.i."
		}
		,A2V00002140156 : {
			de: "Halterung w.g.",
			en: "Support a.d."
		}
		,A2V00002085543 : {
			de: "Tuerfluegel, vollst.",
			en: "Door leaf, compl."
		}
		,A2V00002087065 : {
			de: "Tuerfluegel,2-fl",
			en: "Door leaf, 2-lf"
		}
		,A2V00002140519 : {
			de: "Halter 2 sp.g.",
			en: "Bracket 2 i.m.i."
		}
		,A2V00002140520 : {
			de: "Halter 1 sp.g.",
			en: "Bracket 1 i.m.i."
		}
		,A2V00002087457 : {
			de: "Tuerfluegel, 2-fl",
			en: "Door leaf, 2-lf"
		}
		,A2V00002140521 : {
			de: "Halter 2 w.g.",
			en: "Bracket 2 a.d."
		}
		,A2V00002140522 : {
			de: "Halter 1 w.g.",
			en: "Bracket 1 a.d."
		}
		,A2V00370068860 : {
			de: "Knochenbuchse",
			en: "spheribloc"
		}
		,A2V00370059082 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001421010 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370006354 : {
			de: "Sechskantschraube",
			en: "Hexagon screw"
		}
		,A2V00370026242 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370036750 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370037469 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370052517 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00397241854 : {
			de: "Achsgeberleitung Teil A TDG",
			en: "speedsensor cable part A PT"
		}
		,A2V00370003461 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370076752 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002141736 : {
			de: "Halter 4 w.g.",
			en: "Bracket 4 a.d."
		}
		,A2V00002141738 : {
			de: "Halter 3 w.g.",
			en: "Bracket 3 a.d."
		}
		,A2V00002141805 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002141834 : {
			de: "Halter 3 sp.g.",
			en: "Bracket 3 i.m.i."
		}
		,A2V00002141836 : {
			de: "Halter 4 sp.g.",
			en: "Bracket 4 i.m.i."
		}
		,A2V00002089902 : {
			de: "Querprofil KK-Ende",
			en: "cross profile short coupler end"
		}
		,A2V00002089966 : {
			de: "Kupplungsplatte KK-Ende",
			en: "coupler plate short coupler end"
		}
		,A2V00002089969 : {
			de: "Anschlagprofil    KK-Ende",
			en: "stop profile       short coupler end"
		}
		,A2V00002090036 : {
			de: "Querprofil F-Ende",
			en: "cross profile front end"
		}
		,A2V00002090852 : {
			de: "Dachprofil        aussen",
			en: "roof panel        outside"
		}
		,A2V00002143375 : {
			de: "Dichtblech sp.g.",
			en: "Seal sheet i.m.i."
		}
		,A2V00002143956 : {
			de: "Dichtblech w.g.",
			en: "Seal sheet a.d."
		}
		,A2V00002143957 : {
			de: "Dichtblech sp.g.",
			en: "Seal sheet .i.m.i."
		}
		,A2V00002143958 : {
			de: "Dichtblech w.g.",
			en: "Seal sheet a.d."
		}
		,A2V00207280022 : {
			de: "Heizflansch komplett",
			en: "Heating flange complete"
		}
		,A2V00002614698 : {
			de: "Wellenbremszange mit Federspeicher",
			en: "Axle mount. brake calliper w. park. br."
		}
		,A2V00002146322 : {
			de: "Fernbetaetigung 1616mm",
			en: "Emergency release device 1500mm"
		}
		,A2V00002146321 : {
			de: "Fernbetaetigung 2310mm",
			en: "Emergency release device 2500mm"
		}
		,A2V00207352641 : {
			de: "Zugfunk SE-Geraet TFZ 21",
			en: "Train radio SE-unit TFZ 21"
		}
		,A2V00002614946 : {
			de: "Wellenbremszange ohne Federspeicher",
			en: "Axle mount. brake calliper wo. park. br."
		}
		,A2V00002092918 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00205394920 : {
			de: "Impulsgeber KMG-2H",
			en: "Pulse generator KMG-2H"
		}
		,A2V00002093234 : {
			de: "Leiter geschweisst",
			en: "Ladder welded"
		}
		,A2V00002144547 : {
			de: "Sekundaerfeder mittel 25mm hoeher",
			en: "Secondary spring middle 25mm higher"
		}
		,A2V00002144621 : {
			de: "Halter 7",
			en: "Bracket 7"
		}
		,A2V00002144652 : {
			de: "Geraetetraeger sp.g.",
			en: "Carrier i.m.i."
		}
		,A2V00002144653 : {
			de: "Geraetetraeger w.g.",
			en: "Holder a.d."
		}
		,A2V00002144666 : {
			de: "Geraetetraeger, vorne",
			en: "Holder, front"
		}
		,A2V00002093273 : {
			de: "Querprofil",
			en: "cross profile"
		}
		,A2V00002140795 : {
			de: "Stromrichterrechner 4QS",
			en: "Inverter control unit 4QS"
		}
		,A2V00002174107 : {
			de: "Endlagenschalter",
			en: "Final position switch"
		}
		,A2V00002096272 : {
			de: "Blindfeld 09 vollst.",
			en: "Blind field 09 compl."
		}
		,A2V00002145766 : {
			de: "Nutenstein",
			en: "Sliding block"
		}
		,A2V00002145805 : {
			de: "Kabeleinfuehrungsplatte 2 66 M",
			en: "Gland plate 2 66 M"
		}
		,A2V00002146046 : {
			de: "Kabeleinfuehrungsplatte 1 66 M",
			en: "Gland plate 1 66 M"
		}
		,A2V00002097858 : {
			de: "Traktionsstromrichter",
			en: "Traction converter"
		}
		,A2V00002097887 : {
			de: "Anschlag sp.g.",
			en: "stop r.i."
		}
		,A2V00002097888 : {
			de: "Anschlag w.g.",
			en: "stop as.d."
		}
		,A2V00002097890 : {
			de: "Blech w.g.",
			en: "plate as d."
		}
		,A2V00002097892 : {
			de: "Querprofil KK-Ende",
			en: "cross profile short coupler end"
		}
		,A2V00002098798 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002099254 : {
			de: "Langtraeger       mech.bearb.",
			en: "longitudinal girder machined"
		}
		,A2V00002147801 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002147970 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002147980 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002101474 : {
			de: "Untergurt KK-Ende",
			en: "bottom boom short coupler end"
		}
		,A2V00002101662 : {
			de: "Blech sp.gl.",
			en: "sheet r.i."
		}
		,A2V00002101663 : {
			de: "Blech w.g.",
			en: "sheet as d."
		}
		,A2V00002101775 : {
			de: "Befestigungsplatte",
			en: "mounting plate"
		}
		,A2V00002102581 : {
			de: "Erweiterungsbaugruppe Multi-I/O",
			en: "Extension board Multi-I/O"
		}
		,A2V00001857977 : {
			de: "TGBI-BGR, 24V-Variante, MVB-Trafo -TCC",
			en: "TGBI-subrack, 24V-version  -TCC"
		}
		,A2V00002399677 : {
			de: "Gewindestange Hochstrom SWT",
			en: "Thread rod high current WP"
		}
		,A2V00002103464 : {
			de: "Dichtblech w.g.",
			en: "Sealing sheet as d."
		}
		,A2V00002103603 : {
			de: "Kantenschutz",
			en: "edge protection"
		}
		,A2V00207350936 : {
			de: "BediengerÃ¤t BG90 klein",
			en: "FUNKGERÃ„T TELECAR 9D1-160"
		}
		,A2V00207354138 : {
			de: "Fahrschaltereinheit",
			en: "Master controller"
		}
		,A2V00002104714 : {
			de: "Schweissauflage",
			en: "Welding support"
		}
		,A2V00370051629 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002150033 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002150205 : {
			de: "Stromabnehmer, Ausklinkstab",
			en: "Current collector, release bar"
		}
		,A2V00002105299 : {
			de: "Grauwassertank, vollst., Galley",
			en: "waste water tank, compl., galley"
		}
		,A2V00002105300 : {
			de: "Wassermodul, vollst., Galley",
			en: "water modul, compl., galley"
		}
		,A2V00002171825 : {
			de: "Dachverkabelung 49A/49B ZSB",
			en: "Roof cabling 49A/49B ASSY"
		}
		,A2V00002169820 : {
			de: "Dachverkabelung A8V 24V ZSB",
			en: "Roof cabling A8V 24V ASSY"
		}
		,A2V00002169817 : {
			de: "Dachverkabelung A8A 750V ZSB",
			en: "Roof cabling A8A 750V ASSY"
		}
		,A2V00002158475 : {
			de: "Dachverkabelung B8A ZSB",
			en: "Roof cabling B8A ASSY"
		}
		,A2V00002158474 : {
			de: "Dachverkabelung B8V ZSB",
			en: "Roof cabling B8V ASSY"
		}
		,A2V00002155181 : {
			de: "HV Erdungsrueckleiter C7A ZSB",
			en: "HV grounding C7A ASSY"
		}
		,A2V00002154062 : {
			de: "HV Kabelbaum D7A ZSB",
			en: "HV cable harness D7A ASSY"
		}
		,A2V00002153908 : {
			de: "HV Kabelbaum B7A ZSB",
			en: "HV cable harness B7A ASSY"
		}
		,A2V00002153555 : {
			de: "Dachverkabelung 19A/19B ZSB",
			en: "Roof cabling 19A/19B ASSY"
		}
		,A2V00002153071 : {
			de: "HV Kabelbaum A7A ZSB",
			en: "HV cable harness A7A ASSY"
		}
		,A2V00002158472 : {
			de: "Dachverkabelung WGT B-D ZSB",
			en: "Roof cabling car B-D ASSY"
		}
		,A2V00002152888 : {
			de: "Stromabnehmercontainer",
			en: "Pantograph container"
		}
		,A2V00002165103 : {
			de: "Panto mit Geruest",
			en: "panto with rack"
		}
		,A2V00002176561 : {
			de: "Pult vollst.",
			en: "Driver desk complete"
		}
		,A2V00002171592 : {
			de: "Pultunterschrank",
			en: "Desk rack"
		}
		,A2V00002171551 : {
			de: "Pultunterschrank",
			en: "Desk rack"
		}
		,A2V00002152750 : {
			de: "Zentralcontainer +A3",
			en: "Power container +A3"
		}
		,A2V00002152569 : {
			de: "Zentralcontainer +A2",
			en: "Power container +A2"
		}
		,A2V00002152460 : {
			de: "Zentralcontainer +A1",
			en: "Power container +A1"
		}
		,A2V00002105464 : {
			de: "Deckenmodul M7, vollst.",
			en: "ceiling module M7, comp."
		}
		,A2V00002105495 : {
			de: "Abteildecke 1, vollst.",
			en: "compartment ceil. 1, comp."
		}
		,A2V00002105496 : {
			de: "Abteildecke 2, vollst.",
			en: "compartment ceil. 2, comp."
		}
		,A2V00002105497 : {
			de: "Abteildecke 3, vollst.",
			en: "compartment ceil. 3, comp."
		}
		,A2V00002105498 : {
			de: "Abteildecke 4, vollst.",
			en: "compartment ceil. 4, comp."
		}
		,A2V00002105500 : {
			de: "Abteildecke 6, vollst.",
			en: "compartment ceil. 6, comp."
		}
		,A2V00002105501 : {
			de: "Abteildecke 7, vollst.",
			en: "compartment ceil. 7, comp."
		}
		,A2V00002105776 : {
			de: "Blech sp.g.",
			en: "plate r.i."
		}
		,A2V00002152545 : {
			de: "Pultplatte C2 11.1",
			en: "Desk panel C2 11.1"
		}
		,A2V00002106192 : {
			de: "C-Schiene, vollst.",
			en: "C-rail, compl."
		}
		,A2V00002106208 : {
			de: "C-Schiene, vollst.",
			en: "C-rail, compl."
		}
		,A2V00002106218 : {
			de: "C-Schiene, vollst.",
			en: "C-rail, compl."
		}
		,A2V00002152757 : {
			de: "Pultplatte C2 11.3",
			en: "Desk panel C2 11.3"
		}
		,A2V00002106636 : {
			de: "Abwasserbehaelter, 1050L o. Bodenplatte",
			en: "Waste water tank 1050l. wit. Baseplate"
		}
		,A2V00002107014 : {
			de: "Druckluftbehaelter",
			en: "Air reservoir"
		}
		,A2V00001422283 : {
			de: "Quertraeger",
			en: "Transverse girders"
		}
		,A2V00001422404 : {
			de: "Quertraeger",
			en: "Transverse girders"
		}
		,A2V00002199903 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002109034 : {
			de: "Tuerholmverkleidung",
			en: "Door panelling"
		}
		,A2V00002155291 : {
			de: "Wasserbevorratungstank WMT-85a",
			en: "Watertank WMT-85a"
		}
		,A2V00002711502 : {
			de: "Schmierstift",
			en: "Lubricant stick"
		}
		,A2V00002155875 : {
			de: "Nutenstein m. Feder",
			en: "Slot nut with spring"
		}
		,A2V00002110539 : {
			de: "C-Schiene, vollst.",
			en: "C-rail, compl."
		}
		,A2V00002110540 : {
			de: "C-Schiene, vollst.",
			en: "C-rail, compl."
		}
		,A2V00002110669 : {
			de: "C-Schiene, vollst.",
			en: "C-rail, compl."
		}
		,A2V00002110904 : {
			de: "Einsyst.-Haupttrafo mit Kuehlanl. + Mon.",
			en: "Single system maintransform. with. mount"
		}
		,A2V00002110913 : {
			de: "Nasszelle rechts, vollst.",
			en: "Sanitary facilities right, compl."
		}
		,A2V00002110914 : {
			de: "Nasszelle Caterer vollst.",
			en: "Sanitary facilities Caterer compl."
		}
		,A2V00002110915 : {
			de: "Nasszelle Uni vollst.",
			en: "Sanitary facilities Uni compl."
		}
		,A2V00002112411 : {
			de: "Bodenbelag",
			en: "Floor cover"
		}
		,A2V00002156688 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002112488 : {
			de: "Schweissbadsicherung",
			en: "weld pool baking"
		}
		,A2V00002112873 : {
			de: "Kombi Sensor komplett",
			en: "multi sensor compl."
		}
		,A2V00002399662 : {
			de: "Windleitblech komplett, SWT",
			en: "Spoiler complete, wp"
		}
		,A2V00002113699 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002113700 : {
			de: "Stange",
			en: "Stick"
		}
		,A2V00002115034 : {
			de: "Glastrennwand ASM",
			en: "Glass part.wall ASM"
		}
		,A2V00002115035 : {
			de: "Glastrennwand ASM",
			en: "Glass part.wall ASM"
		}
		,A2V00002115069 : {
			de: "Glastrennwand",
			en: "Glass part.wall"
		}
		,A2V00156015526 : {
			de: "ANSTEUERUNG IGBT",
			en: "activation    IGBT"
		}
		,A2V00001719790 : {
			de: "SIBCOS M9000",
			en: "control unit M9000"
		}
		,A2V00002116216 : {
			de: "Tuerblech sp.gl.",
			en: "door plate r.i."
		}
		,A2V00002117417 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002623115 : {
			de: "Liefergarnitur Luftfuehrungen DMOSB",
			en: "Delivering set Air ducts DMOSB"
		}
		,A2V00002623196 : {
			de: "Liefergarnitur Luftfuehrungen PTOSB",
			en: "Delivering set Air ducts PTOSB"
		}
		,A2V00002623197 : {
			de: "Liefergarnitur Luftfueh. MOS/TOS/TOS(L)W",
			en: "Delivering set Air ducts MOS/TOS/TOS(L)W"
		}
		,A2V00002160074 : {
			de: "Zugidentifikatssensor geschw.",
			en: "Train identification sensor welded"
		}
		,A2V00001908526 : {
			de: "Rippe f. Getriebekonsole",
			en: "Rib f. gearbox support"
		}
		,A2V00001908879 : {
			de: "Deckel",
			en: "Cap"
		}
		,A2V00001910772 : {
			de: "Rippe 6 SPG",
			en: "Rib 6 OAD"
		}
		,A2V00001910773 : {
			de: "Rippe 5 SPG",
			en: "Rib 5 OAD"
		}
		,A2V00002119825 : {
			de: "Batterieladegeraet",
			en: "battery charger"
		}
		,A2V00001910847 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00001915572 : {
			de: "Steg kurz f. Querpufferkonsole",
			en: "Sheet short f. lateral bumper support"
		}
		,A2V00001915601 : {
			de: "Untergurt",
			en: "Lower flange"
		}
		,A2V00002120338 : {
			de: "Hilfslufttafel",
			en: "air supply panel for panthograph"
		}
		,A2V00002120340 : {
			de: "Luftversorgungsanlage",
			en: "air supply unit"
		}
		,A2V00002723770 : {
			de: "Bowdenzug, vollst.",
			en: "Bowden cable compl."
		}
		,A2V00002120393 : {
			de: "Luftversorgungsanlage",
			en: "air supply unit"
		}
		,A2V00002120400 : {
			de: "Druckbehaelter Alu 25L",
			en: "Air receiver aluminium 25L"
		}
		,A2V00002120401 : {
			de: "Druckbehaelter Alu 40L",
			en: "Air receiver aluminium 40L"
		}
		,A2V00002120402 : {
			de: "Druckbehaelter Alu 60L",
			en: "air receiver aluminium 60L"
		}
		,A2V00002120403 : {
			de: "Druckbehaelter Alu 100L",
			en: "air receiver aluminium 100L"
		}
		,A2V00002121937 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002121977 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002122004 : {
			de: "Winkelblech",
			en: "angle plate"
		}
		,A2V00002122034 : {
			de: "Seitenblech",
			en: "side sheet"
		}
		,A2V00002122162 : {
			de: "Verstaerkung",
			en: "support"
		}
		,A2V00002122173 : {
			de: "Ventiltafel einstufig f. zwei Stromabn.",
			en: "valve plate single stage for two pantog."
		}
		,A2V00001989121 : {
			de: "Achsgetriebe ohne Welle und Montagemat.",
			en: "Axle gear without axle and mounting mat."
		}
		,A2V00002122314 : {
			de: "Hauptschalter AC inkl. einpoliger Erdung",
			en: "main circuit break. AC unipolar incl. ES"
		}
		,A2V00200434539 : {
			de: "Zylinderschraube",
			en: "CYLIND.SCREW"
		}
		,A2V00207353090 : {
			de: "Scheibe",
			en: "Washer"
		}
		,A2V00002122315 : {
			de: "Hauptschalter AC inkl zweipoliger Erdung",
			en: "main circuit breaker AC bipolar incl. ES"
		}
		,A2V00002122448 : {
			de: "Blech sp.g.",
			en: "sheet r.i."
		}
		,A2V00002122449 : {
			de: "Blech w.g.",
			en: "sheet as d."
		}
		,A2V00002122499 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002122529 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002122907 : {
			de: "TDG vollst. TW2.2-R4P-ZUB WE1",
			en: "MB compl. TW2.2-R4P-ZUB CE1"
		}
		,A2V00002122908 : {
			de: "TDG vollst. TW1.2, TW2.2",
			en: "MB compl.  TW1.2, TW2.2"
		}
		,A2V00002122970 : {
			de: "Energiezaehler",
			en: "energy meter"
		}
		,A2V00002123016 : {
			de: "MUB-Widerstand",
			en: "instantaneous voltage limiting resistor"
		}
		,A2V00002123018 : {
			de: "Batteriebehaelter, vollst.",
			en: "battery container,, compl."
		}
		,A2V00002162420 : {
			de: "KB Aussenbeleuchtung A73 ZSB",
			en: "CH exterior lighting A73 ASSY"
		}
		,A2V00002399786 : {
			de: "Horn kpl. rechts       1-W08.11389",
			en: "Horn kpl. rechts       1-W08.11389"
		}
		,A2V00002162513 : {
			de: "KB FR Versorgung A72 ZSB",
			en: "CH DC supply A72 ASSY"
		}
		,A2V00002124866 : {
			de: "Dachblech",
			en: "roof sheet"
		}
		,A2V00002127152 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002128535 : {
			de: "Kurzkupplung lang A",
			en: "Semi permanent coupler A"
		}
		,A2V00002128666 : {
			de: "Kurzkupplung kurz B",
			en: "Semi permanent coupler B"
		}
		,A2V00002128837 : {
			de: "Aufnahme",
			en: "receiver"
		}
		,A2V00002129305 : {
			de: "Halter, vollst.",
			en: "holder, compl."
		}
		,A2V00002129348 : {
			de: "Halter, vollst.",
			en: "holder, compl."
		}
		,A2V00001415357 : {
			de: "Spriegel",
			en: "Stick"
		}
		,A2V00001419053 : {
			de: "Oberrahmen",
			en: "Cantrail"
		}
		,A2V00002130387 : {
			de: "Frischwasserbehaelter, vollst.",
			en: "Fresh water reservoir, compl."
		}
		,A2V00002131516 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002330765 : {
			de: "Rippenblech w.g.",
			en: "rib plate a.d."
		}
		,A2V00002330673 : {
			de: "Verstaerkung w.g.",
			en: "reinforcement as d."
		}
		,A2V00370082914 : {
			de: "Leitung RADOX 3 GKW 600V 2,5 GNGE",
			en: "Cable RADOX 3 GKW 600V 2,5 GNYE"
		}
		,A2V00002165593 : {
			de: "Konsole2 geschw. sp.g.",
			en: "Bracket2 welded i.m.i."
		}
		,A2V00002165594 : {
			de: "Konsole2 geschw. w.g.",
			en: "Bracket2 welded a.d."
		}
		,A2V00002387631 : {
			de: "Feder 10          vollstaendig",
			en: "Spring 10         complete"
		}
		,A2V00002366616 : {
			de: "Abschlussleiste   links",
			en: "End plate         left"
		}
		,A2V00002366565 : {
			de: "Abschlussleiste   rechts",
			en: "End plate         right"
		}
		,A2V00002387628 : {
			de: "Abschlussleiste   links",
			en: "End plate         left"
		}
		,A2V00002165619 : {
			de: "Konsole geschw. sp.g.",
			en: "Bracket welded i.m.i."
		}
		,A2V00002132539 : {
			de: "Halter R",
			en: "holder R"
		}
		,A2V00002387629 : {
			de: "Abschlussleiste   rechts",
			en: "End plate         right"
		}
		,A2V00002165620 : {
			de: "Konsole geschw. w.g.",
			en: "Bracket welded a.d."
		}
		,A2V00002132540 : {
			de: "Halter L",
			en: "holder L"
		}
		,A2V00002387511 : {
			de: "Blindfeld 07 volllstaendig",
			en: "Blind field 07 complete"
		}
		,A2V00002387630 : {
			de: "Feder 09          vollstaendig",
			en: "Spring 09         complete"
		}
		,A2V00002368521 : {
			de: "Blindfeld 14 volllstaendig",
			en: "Blind field 14 complete"
		}
		,A2V00002134427 : {
			de: "Wanne",
			en: "Basin"
		}
		,A2V00002218837 : {
			de: "Fahrersitzarmlehne +AL rechts",
			en: "Armrest drivers seat +AL right"
		}
		,A2V00002218701 : {
			de: "Fahrlehrerpult Zusammenstellung",
			en: "Instructor control panel assembly"
		}
		,A2V00002134511 : {
			de: "Galley-Transformator",
			en: "Galley transformer"
		}
		,A2V00001227078 : {
			de: "Bremsbelag",
			en: "Brake pad"
		}
		,A2V00001443047 : {
			de: "(IS  BS) Federunterlage TDG",
			en: "(IS  BS) Shim MB"
		}
		,A2V00002136123 : {
			de: "Bowdenzug Notentriegelung",
			en: "Bowdencable emergency"
		}
		,A2V00002137160 : {
			de: "Halteblech, vollst",
			en: "Bracket, complete"
		}
		,A2V00002137496 : {
			de: "Gepaeckablage 1",
			en: "Luggage rack 1"
		}
		,A2V00002137500 : {
			de: "Gepaeckablage 2",
			en: "luggage rack 2"
		}
		,A2V00002600837 : {
			de: "Drehzahlgeber GEL2475Y228",
			en: "Speed sensor GEL2475Y228"
		}
		,A2V00002139412 : {
			de: "Umluftheizer                       Typ 1",
			en: "Recirculating air heater          type 1"
		}
		,A2V00002139413 : {
			de: "Umluftheizer                       Typ 2",
			en: "Recirculating air heater          type 2"
		}
		,A2V00397227035 : {
			de: "Abschlussplatte",
			en: "closing plate"
		}
		,A2V00397210056 : {
			de: "Platte",
			en: "plate"
		}
		,A2V00397210054 : {
			de: "Blech W.G.",
			en: "sheet as drawn"
		}
		,A2V00397210052 : {
			de: "Rippe",
			en: "rib"
		}
		,A2V00002206641 : {
			de: "KB FR Versorgung B72 ZSB",
			en: "CH DC supply B72 ASSY"
		}
		,A2V00002206640 : {
			de: "KB Aussenbeleuchtung B73 ZSB",
			en: "CH exterior lighting  B73 ASSY"
		}
		,A2V00002206558 : {
			de: "KB Kopf Unterflur B74 ZSB",
			en: "CH front underfloor B74 ASSY"
		}
		,A2V00002193244 : {
			de: "KB Kopf Unterflur A74 ZSB",
			en: "CH front underfloor  A74 ASSY"
		}
		,A2V00002175005 : {
			de: "KB Antenne Kopf ZSB",
			en: "CH Antenna front ASSY"
		}
		,A2V00002139465 : {
			de: "KKG MW",
			en: "AC unit centre coach"
		}
		,A2V00002139950 : {
			de: "Durchfuehrung",
			en: "feedthrough"
		}
		,A2V00002140332 : {
			de: "Kabeldurchfuehrung KK-Ende",
			en: "cable feedthrough short coupler end"
		}
		,A2V00002140370 : {
			de: "Trittleiste vollst.",
			en: "bead complete"
		}
		,A2V00370067009 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001702228 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00207352795 : {
			de: "Obere Signalleuchte",
			en: "Train head light"
		}
		,A2V00207352796 : {
			de: "Signalscheinwerfer",
			en: "Signal Headlight"
		}
		,A2V00113145441 : {
			de: "Stutzen VEZ-DN8",
			en: "Socket VEZ-DN8"
		}
		,A2V00002610828 : {
			de: "KB Heck Unterflur B74 ZSB",
			en: "CH rear underfloor B74 ASSY"
		}
		,A2V00002143576 : {
			de: "Rahmen vollst. rechts",
			en: "frame compl. right"
		}
		,A2V00002144184 : {
			de: "Rahmen vollst. links",
			en: "frame compl. left"
		}
		,A2V00001069998 : {
			de: "Zunge",
			en: "Point blade"
		}
		,A2V00000216728 : {
			de: "FM kl. Bauform, o.Kabel, Abgang seitlich",
			en: "Vehicle magnet small configuration"
		}
		,A2V00113034276 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001418502 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002148069 : {
			de: "Abteildecke 10, vollst.",
			en: "compartment ceil. 10, comp."
		}
		,A2V00002148089 : {
			de: "Dichtplatte links, geschweisst",
			en: "Sealing plate left, welded"
		}
		,A2V00001379430 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00001379453 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00001379710 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00001226013 : {
			de: "Scheibe DA72/DI40x3",
			en: "Washer OD72/ID40x3"
		}
		,A2V00370025450 : {
			de: "Hohlbuchse",
			en: "cylindrical bush"
		}
		,A2V00370025695 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw ISO 4014 M16X100 8.8"
		}
		,A2V00370036724 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370060209 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002148652 : {
			de: "Seitenfenster klein mit Halter",
			en: "Side window small with plates"
		}
		,A2V00002149247 : {
			de: "Wanne",
			en: "Basin"
		}
		,A2V00002149623 : {
			de: "Bowdenzug Notentriegelung,rechts",
			en: "Bowdencable emergency, right"
		}
		,A2V00002171329 : {
			de: "Scheibe",
			en: "Shim"
		}
		,A2V00002150899 : {
			de: "Fuehrung",
			en: "guidance"
		}
		,A2V00001379664 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00370024817 : {
			de: "POLRAD INCL.VERSCHRAUBUNG         31B",
			en: "Pulse wheel incl. screwing"
		}
		,A2V00370074209 : {
			de: "Wellrohr-Endtuelle PMA",
			en: "Conduit terminal sleeve PMA"
		}
		,A2V00370026228 : {
			de: "VERSCHLUSZSTUTZEN                 31B",
			en: "closing union"
		}
		,A2V00001379431 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00001379451 : {
			de: "Blech EN 10029 8X2000X4000 S355J2C+N",
			en: "Sheet EN 10029 8X2000X4000 S355J2C+N"
		}
		,A2V00001379452 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00001420989 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002153204 : {
			de: "Stromabnehmer",
			en: "Pantograph"
		}
		,A2V00002154516 : {
			de: "EP2002 Smart Ventil",
			en: "EP2002 Smart Valve"
		}
		,A2V00370073468 : {
			de: "Zylinderschraube ISO4762 M8x100 A4-70",
			en: "Hexagon socket head cap screw  ISO4762"
		}
		,A2V00002155507 : {
			de: "2Platz 2Klasse Reihe ausgeruestet",
			en: "2places 2class raw equipped"
		}
		,A2V00002155508 : {
			de: "2Platz 2Klasse Reihe ausgeruestet",
			en: "2places 2class raw equipped"
		}
		,A2V00002155509 : {
			de: "2Platz 2Klasse Reihe n.a. mit Haltegriff",
			en: "2place 2class raw n. eq. with handle"
		}
		,A2V00002155510 : {
			de: "2Platz 2Klasse Reihe n.a. mit Haltegriff",
			en: "2place 2class raw n. eq. with handle"
		}
		,A2V00002155511 : {
			de: "2Platz 2Klasse li DAD n.a. m. Haltegriff",
			en: "2place 2class left DAD n.eq.w.handle"
		}
		,A2V00002155512 : {
			de: "2Platz 2Klasse re DAD n.a. o. Haltegriff",
			en: "2place 2class right DAD n.eq.wth.Handle"
		}
		,A2V00002155515 : {
			de: "2Platz 2Klasse USC n.a. ohne Griffmulde",
			en: "2Place 2Class Box n.eq. wo. handle"
		}
		,A2V00002155516 : {
			de: "2Platz 2Klasse rechst USC n.a.o.Griffmu.",
			en: "2Place 2Class right Box n.eq.wth.handle"
		}
		,A2V00001444050 : {
			de: "Drehzapfen lackiert",
			en: "Pivot painted"
		}
		,A2V00370082903 : {
			de: "Hilfsschalterblock",
			en: "Auxiliary switch block"
		}
		,A2V00002156785 : {
			de: "Halter Anhebestellen",
			en: "holder lifting point"
		}
		,A2V00002174412 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002174443 : {
			de: "Zylinderschraube",
			en: "hexagon socket head cap screwÂ "
		}
		,A2V00002158433 : {
			de: "Manometer PG-80S einfach",
			en: "gauge PG-80S"
		}
		,A2V00002174485 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screwÂ "
		}
		,A2V00370052232 : {
			de: "GELENK M.RECHTSGEWINDE",
			en: "JOINT WITH RIGHT-HAND THREAD"
		}
		,A2V00370028168 : {
			de: "BETAETIGUNGSVENTIL BV-3          II33501",
			en: "actuating valve BV-3          II33501"
		}
		,A2V00370054479 : {
			de: "EINSCHRAUBSTUECK      AMR AC 12 G1/4B JN",
			en: "SCREW-IN PEACE        AMR AC 12 G1/4B JN"
		}
		,A2V00370078988 : {
			de: "Sechskantmutter ISO 4032 M16 A4-80",
			en: "Hexagon nut ISO 4032 M16 A4-80"
		}
		,A2V00002364519 : {
			de: "Fensterfeld 13  vollstaendig",
			en: "Window field 13 complete"
		}
		,A2V00002175491 : {
			de: "Pruefstueck 19 8x150x400 / 8 / 0",
			en: "Test Piece 19 8x150x400 / 8 / 0"
		}
		,A2V00370023445 : {
			de: "GLEITPLATTE",
			en: "GLIDING PLATE"
		}
		,A2V00002175566 : {
			de: "Unterfahrschutz vollst.",
			en: "Underride guard compl."
		}
		,A2V00001401545 : {
			de: "Blech EN 10029 6X2000X4000 S355NC",
			en: "Sheet EN 10029 6X2000X4000 S355NC"
		}
		,A2V00002367930 : {
			de: "Fensterfeld 22  vollstaendig",
			en: "Window field 22  complete"
		}
		,A2V00002367948 : {
			de: "Fensterfeld 23  vollstaendig",
			en: "Window field 23  complete"
		}
		,A2V00200200076 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00200423883 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002052357 : {
			de: "Geraeteventilator",
			en: "Unit ventilator"
		}
		,A2V00001129918 : {
			de: "Sicherheitsventil",
			en: "Safety valve"
		}
		,A2V00200423886 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001401756 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00002387510 : {
			de: "Fensterfeld 27  vollstaendig",
			en: "Window field 27  complete"
		}
		,A2V00001134088 : {
			de: "Handpumpe transportabel mit Filter",
			en: "Hand pump transportable with filter"
		}
		,A2V00002368522 : {
			de: "Blindfeld 13 volllstaendig",
			en: "Blind field 13 complete"
		}
		,A2V00002176583 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00002350448 : {
			de: "Fensterfeld 24 vollstaendig",
			en: "Window field 24 complete"
		}
		,A2V00001745857 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002521966 : {
			de: "Blech EN 10051 5x1500x4000 S460MC",
			en: "Sheet EN 10051 5x1500x4000 S460MC"
		}
		,A2V00002521965 : {
			de: "Blech EN 10051 4x1500x4000 S460MC",
			en: "Sheet EN 10051 4x1500x4000 S460MC"
		}
		,A2V00002521961 : {
			de: "Blech EN 10051 2,5x1170x4000 S460MC",
			en: "Sheet EN 10051 2,5x1170x4000 S460MC"
		}
		,A2V00001700896 : {
			de: "Fuehrerbremsventil FS3-2 mit Traeger",
			en: "Drivers brake valve FS3-2 with bracket"
		}
		,A2V00001415683 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00001426217 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00002350449 : {
			de: "Fensterfeld 25  vollstaendig",
			en: "Window field 25  complete"
		}
		,A2V00002350450 : {
			de: "Blindfeld 12 vollstaendig",
			en: "Blindfield 12 complete"
		}
		,A2V00002178399 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002350750 : {
			de: "Fensterfeld 26  vollstaendig",
			en: "Window field 26 complete"
		}
		,A2V00002342234 : {
			de: "Fensterfeld 16  vollstaendig",
			en: "Window field 16 complete"
		}
		,A2V00002556169 : {
			de: "Traktionsstromrichter C2G-1500 16-A",
			en: "Traction convertor C2G-1500 16-A"
		}
		,A2V00001418820 : {
			de: "Langtraegerprofil",
			en: "Side sill profile"
		}
		,A2V00001419069 : {
			de: "Dachlangtraegerprofil",
			en: "side sole bar profile"
		}
		,A2V00001419099 : {
			de: "Dachwellblechprofil",
			en: "Corrugated roof profile"
		}
		,A2V00001419101 : {
			de: "Dachwellblechprofil",
			en: "Corrugated roof profile"
		}
		,A2V00001419103 : {
			de: "Dachwellblechprofil",
			en: "Corrugated roof profile"
		}
		,A2V00001419105 : {
			de: "Dachwellblechprofil",
			en: "Corrugated roof profile"
		}
		,A2V00370020158 : {
			de: "Tragschiene DIN 3015-1 - L TS-30x2000-W4",
			en: "Mounting rail DIN 3015-1 - L TS-30x2000"
		}
		,A2V00001419067 : {
			de: "Dachlangtraegerprofil",
			en: "side sole bar profile"
		}
		,A2V00002342409 : {
			de: "Fensterfeld 17  vollstaendig",
			en: "Window field 17  complete"
		}
		,A2V00002535370 : {
			de: "Batterieladegeraet",
			en: "Battery charger"
		}
		,A2V00002231691 : {
			de: "Ventilsteuerstufe VSS LFW",
			en: "Valve control unit VSS LFW"
		}
		,A2V00002231690 : {
			de: "Ventilsteuerstufe VSS TFW",
			en: "Valve control unit VSS FTW"
		}
		,A2V00002573881 : {
			de: "Konusfeder SF1000",
			en: "Conical Spring"
		}
		,A2V00002573672 : {
			de: "Luftfedersystem",
			en: "air spring system"
		}
		,A2V00002193274 : {
			de: "Sicherheitsventil 1/4 DN12 10,5bar",
			en: "safety valve 1/4 DN12 10,5bar"
		}
		,A2V00002592970 : {
			de: "Daempfer sekundaer vertikal",
			en: "Secondary vertical shock absorber"
		}
		,A2V00002595024 : {
			de: "Knochenbuchse SF1000",
			en: "Spheribloc SF1000"
		}
		,A2V00002342414 : {
			de: "Fensterfeld 19  vollstaendig",
			en: "Window field 19  complete"
		}
		,A2V00002342591 : {
			de: "Fensterfeld 20  vollstaendig",
			en: "Window field 20  complete"
		}
		,A2V00002342647 : {
			de: "Fensterfeld 21  vollstaendig",
			en: "Window field 21  complete"
		}
		,A2V00002160139 : {
			de: "Auflage",
			en: "support"
		}
		,A2V00001316343 : {
			de: "Scheibe fuer Festfenster groÃŸ",
			en: "glass panel"
		}
		,A2V00001418578 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002231054 : {
			de: "Magnetventil",
			en: "Magnetic valve"
		}
		,A2V00001442058 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00001442059 : {
			de: "Blech ISO 9445 3X1500X3000 X5CrNi1810+2B",
			en: "Sheet ISO 9445 3X1500X3000 X5CrNi1810+2B"
		}
		,A2V00113004646 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002162877 : {
			de: "Tuerfluegel, vollst.",
			en: "Door leaf,  compl."
		}
		,A2V00002162925 : {
			de: "Rohrdurchfuehrung links, vollst.",
			en: "Intersection left, compl."
		}
		,A2V00002604153 : {
			de: "Klimaanlage FGR",
			en: "VAC-unit saloon"
		}
		,A2V00002537260 : {
			de: "Langtraeger 1 oberer, f. MC Untergestell",
			en: "Longit. beam 1 upper, f. MC underframe"
		}
		,A2V00002457295 : {
			de: "Zylinderschraube",
			en: "hexagon socket head cap screwÂ "
		}
		,A2V00001430890 : {
			de: "Drehstabfeder",
			en: "anti roll bar"
		}
		,A2V00002163579 : {
			de: "Transformator",
			en: "Transformer"
		}
		,A2V00397224821 : {
			de: "SCHEIBE",
			en: "WASHER"
		}
		,A2V00397192802 : {
			de: "KLEMMPLATTE F.RAEUMERGUMMI",
			en: "CLAMPING PLATE"
		}
		,A2V00397177774 : {
			de: "Anschweissblech f. Schlauchbefestigung",
			en: "Weld on sheet f. hose fastening"
		}
		,A2V00397282556 : {
			de: "Steg MG-Konsole w.g.",
			en: "Web MG-brake a.d."
		}
		,A2V00002165825 : {
			de: "Traverse vollst.",
			en: "traverse compl."
		}
		,A2V00002167359 : {
			de: "Abschlussblech",
			en: "end plate"
		}
		,A2V00002167424 : {
			de: "Abschlussblech",
			en: "end plate"
		}
		,A2V00002167847 : {
			de: "Matte",
			en: "Mat"
		}
		,A2V00002189556 : {
			de: "Wippe 1950/DC 1-24508.13231",
			en: "Collector head 1950/DC 1-24508.13231"
		}
		,A2V00002168515 : {
			de: "Doppelwellenbalghaelfte",
			en: "Double corrugated bellows half"
		}
		,A2V00002168599 : {
			de: "Gliederbruecke, mit Schlitten",
			en: "Linking bridge, with sledge"
		}
		,A2V00397164283 : {
			de: "Unterlagsplatte",
			en: "Support plate"
		}
		,A2V00397120092 : {
			de: "Steg f. Abhebesicherung",
			en: "web f. lift off protection"
		}
		,A2V00000216705 : {
			de: "Wegimpulsgeber-BST -LZB und ZUB100",
			en: "Odometer pulse generator component"
		}
		,A2V00397137769 : {
			de: "ROLLKRANZTRAEGER,LACKIERTER",
			en: "Bolster, painted"
		}
		,A2V00397120093 : {
			de: "Stirnblech f. Abhebesicherung",
			en: "Lug for lift-off protection"
		}
		,A2V00397126978 : {
			de: "Untergurt W.G.",
			en: "Lower flange A.D."
		}
		,A2V00397119411 : {
			de: "Kastenblech",
			en: "Box plate"
		}
		,A2V00397119412 : {
			de: "Deckelblech",
			en: "Cover plate"
		}
		,A2V00397134455 : {
			de: "Steg fuer Laengsmitnahme",
			en: "Web for traction link"
		}
		,A2V00397125665 : {
			de: "Montageblech fuer Querpufferkonsole",
			en: "assembling sheet for tr. bumper bracket"
		}
		,A2V00397130975 : {
			de: "KONSOLBLECH 1 F.KLEMMENKASTENKONSOLE",
			en: "bracket sheet 1 f. clamping box bracket"
		}
		,A2V00397130550 : {
			de: "Konsolenblech fuer Bremszangeneinheit",
			en: "Bracket plate for brake gripper device"
		}
		,A2V00397130551 : {
			de: "Halterplatte fuer Haengelasche",
			en: "Holding plate for hanging ear"
		}
		,A2V00397130552 : {
			de: "Halterplatte fuer Bremszange",
			en: "Holding plate for brake gripper"
		}
		,A2V00397133696 : {
			de: "Rippe fuer Bremskonsole",
			en: "Rib for brake bracket"
		}
		,A2V00397124231 : {
			de: "Obergurt Mittelteil SP.G.",
			en: "Upper flange center part O.A.D."
		}
		,A2V00002169494 : {
			de: "Konsole",
			en: "bracket"
		}
		,A2V00001197905 : {
			de: "Buerste",
			en: "Brush"
		}
		,A2V00100616877 : {
			de: "LAGERPLATTE",
			en: "BEARING PLATE"
		}
		,A2V00109063862 : {
			de: "LENKER",
			en: "Connecting rod"
		}
		,A2V00109404587 : {
			de: "STAHLPLATTE                            .",
			en: "STAHLPLATTE                            ."
		}
		,A2V00207473875 : {
			de: "Spannhalter geschw",
			en: "Spannhalter geschw"
		}
		,A2V00207473876 : {
			de: "Spannhalter geschw",
			en: "Spannhalter geschw"
		}
		,A2V00001745690 : {
			de: "Erdungsblech",
			en: "Grounding sheet"
		}
		,A2V00397148105 : {
			de: "DRUCKLUFTLEITUNG",
			en: "Pneumatic pipe"
		}
		,A2V00001414926 : {
			de: "Kegelhuelse",
			en: "tapper bush"
		}
		,A2V00001170287 : {
			de: "Datenspeichereinheit DSE 3200",
			en: "Data storage unit DSE 3200"
		}
		,A2V00370082899 : {
			de: "Motorschutzschalter 40 A, 50 A",
			en: "Circuit breaker 40 A, 50 A"
		}
		,A2V00200676915 : {
			de: "Blech EN 485-4 EN AW-5754-H22",
			en: "Sheet EN 485-4 EN AW-5754-H22"
		}
		,A2V00200676917 : {
			de: "Blech EN 485-4 EN AW-5754-H22",
			en: "Sheet EN 485-4 EN AW-5754-H22"
		}
		,A2V00200676909 : {
			de: "Blech EN 485-4 EN AW-5754-H22",
			en: "Sheet EN 485-4 EN AW-5754-H22"
		}
		,A2V00200329351 : {
			de: "Blech ISO 9445  X5CrNi18-10",
			en: "Sheet ISO 9445  X5CrNi18-10"
		}
		,A2V00002191748 : {
			de: "Druckplatte",
			en: "Pressure plate"
		}
		,A2V00002171981 : {
			de: "Drehgestell vst. Typ A1",
			en: "Bogie cpl. Type A1"
		}
		,A2V00002173844 : {
			de: "Haltestange, hinten rechts",
			en: "handrail, rear right"
		}
		,A2V00002174864 : {
			de: "Gelenkhalter, geschw.",
			en: "Joint-holder, welded"
		}
		,A2V00002175655 : {
			de: "Deckengeruest, komplett",
			en: "Ceiling frame, complete"
		}
		,A2V00002177932 : {
			de: "Abdeckwinkel",
			en: "cover corner"
		}
		,A2V00002177933 : {
			de: "Abdeckwinkel",
			en: "cover corner"
		}
		,A2V00002177934 : {
			de: "Abdeckwinkel",
			en: "cover corner"
		}
		,A2V00002185310 : {
			de: "Schweissbadsicherung sp.g.",
			en: "weld pool backing r.i."
		}
		,A2V00002185974 : {
			de: "Schattenfuge kompl. Seitendecke",
			en: "Shadow gap compl. side ceiling"
		}
		,A2V00002186029 : {
			de: "Schattenfuge kompl. Seitendecke Einstieg",
			en: "Shadow gap compl. side ceiling entrance"
		}
		,A2V00002186066 : {
			de: "Lochblech kompl. 1900",
			en: "perforated plate compl. 1900"
		}
		,A2V00001689018 : {
			de: "Scheibe ISO 7089 16 300HV GEO321B",
			en: "Washer ISO 7089 16 300HV GEO321B"
		}
		,A2V00002186076 : {
			de: "Lochblech kompl. 638",
			en: "perforated plate compl. 638"
		}
		,A2V00002197925 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical shock absorber"
		}
		,A2V00002186233 : {
			de: "Gelenkhalter, vollst.",
			en: "Support, compl."
		}
		,A2V00002186357 : {
			de: "Grundhalter, vollst.",
			en: "Base holder, compl."
		}
		,A2V00002186358 : {
			de: "Grundhalter, vollst.",
			en: "Base holder, compl."
		}
		,A2V00002186799 : {
			de: "Steckerblindblech 24B",
			en: "Plug dummy panel 24B"
		}
		,A2V00002188567 : {
			de: "Platte",
			en: "plate"
		}
		,A2V00002189529 : {
			de: "C-Schiene, Kabelhalter",
			en: "C-rail cable holder"
		}
		,A2V00002189530 : {
			de: "C-Schiene, Stromschiene",
			en: "C-rail, powertrail"
		}
		,A2V00002189596 : {
			de: "Abdeckblech 2, F-Ende",
			en: "cover sheet 2, front end"
		}
		,A2V00002189597 : {
			de: "Abdeckblech F-Ende",
			en: "cover sheet front end"
		}
		,A2V00002189825 : {
			de: "Platte vollst., Beleuchtung",
			en: "plate compl., lighting"
		}
		,A2V00001719154 : {
			de: "Blech EN 10029 3X1500X3000 S355NC",
			en: "Sheet EN 10029 3X1500X3000 S355NC"
		}
		,A2V00001401543 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00370036366 : {
			de: "Zylinderschraube ISO 4762 M12X35 A4-70",
			en: "Hexagon socket head cap screw ISO 4762"
		}
		,A2V00001401542 : {
			de: "Blech EN 10029 3X1500X4000 S355NC",
			en: "Sheet EN 10029 3X1500X4000 S355NC"
		}
		,A2V00370062648 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00001897379 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screwÂ "
		}
		,A2V00002193563 : {
			de: "Haltestange kompl.",
			en: "Holding rod compl."
		}
		,A2V00002193665 : {
			de: "Haltestange kompl.",
			en: "Holding rod compl."
		}
		,A2V00002193669 : {
			de: "Haltestange kompl.",
			en: "Holding rod compl."
		}
		,A2V00002193680 : {
			de: "Haltestange kompl.",
			en: "Holding rod compl."
		}
		,A2V00002193684 : {
			de: "Haltestange kompl.",
			en: "Holding rod compl."
		}
		,A2V00002193740 : {
			de: "Haltestange kompl.",
			en: "Holding rod compl."
		}
		,A2V00002206787 : {
			de: "Lackierung Crashmodul",
			en: "Paintwork crash module"
		}
		,A2V00002197363 : {
			de: "Deckengeruest, komplett",
			en: "Ceiling frame, complete"
		}
		,A2V00002197491 : {
			de: "Deckengeruest, komplett",
			en: "Ceiling frame, complete"
		}
		,A2V00002197516 : {
			de: "Haltestange, lackiert",
			en: "Grab handle, coated"
		}
		,A2V00002198271 : {
			de: "Blech sp.g.",
			en: "sheet r.i."
		}
		,A2V00002199812 : {
			de: "Glastrennwand ASM",
			en: "Glass part.wall ASM"
		}
		,A2V00001192996 : {
			de: "Distanzhuelse h=10mm",
			en: "Distance bush h=10mm"
		}
		,A2V00200421933 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002218287 : {
			de: "Nutenstein",
			en: "Sliding block"
		}
		,A2V00002202006 : {
			de: "Platte geschweisst rechts",
			en: "Plate welded right"
		}
		,A2V00002202007 : {
			de: "Platte geschweisst links",
			en: "Plate welded left"
		}
		,A2V00002203133 : {
			de: "Hilfsluftkompressor",
			en: "compressor"
		}
		,A2V00002220422 : {
			de: "Stirnwand, vollst.",
			en: "endwall, compl."
		}
		,A2V00002206351 : {
			de: "Befestigung unten, vollst.",
			en: "Fastening lower, complt."
		}
		,A2V00002209068 : {
			de: "Handlauf vollst.  geschweisst",
			en: "handrail compl.   welded"
		}
		,A2V00002223404 : {
			de: "Blech 20X2000X4000 S355J2C+N",
			en: "Sheet 20X2000X4000 S355J2C+N"
		}
		,A2V00001750601 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001750604 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001750605 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001750667 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001754175 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001754286 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001754289 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001754290 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001754427 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001754428 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001754429 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001754433 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001754614 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001754618 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001754619 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001754620 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001754621 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001754622 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001754624 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001755049 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001755050 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001755051 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001755052 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001755053 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001755054 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001755076 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001755077 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001755322 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001755323 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001755357 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001755358 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001757742 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001757743 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001757744 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001757745 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001757787 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001757789 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001757791 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001757794 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00002116254 : {
			de: "LED-Modul 940 mit Notlicht",
			en: "LED module 940 with emergency light"
		}
		,A2V00397218770 : {
			de: "Beilage 1mm f. Querpuffer",
			en: "Shim 1mm f. lateral buffer"
		}
		,A2V00397218771 : {
			de: "Beilage 2mm f. Querpuffer",
			en: "Shim 2mm f. lateral buffer"
		}
		,A2V00397215008 : {
			de: "Haltebuegel",
			en: "Wharf shackle"
		}
		,A2V00397282582 : {
			de: "ROLLE F.QUERSPIELBEGRENZUNG",
			en: "Roll f. cross clearance limiting"
		}
		,A2V00001120751 : {
			de: "Motor inklusive Bremsscheibe mit Kuppl.",
			en: "motor incl brake disc with coupling"
		}
		,A2V00002148770 : {
			de: "Halter EK sp.g.",
			en: "bracket EK op.d"
		}
		,A2V00001785326 : {
			de: "Antriebseinheit 2KF1422-0GB10 COMBINO",
			en: "Drive Unit 2KF1422-0GB10 COMBINO"
		}
		,A2V00002341876 : {
			de: "Fensterfeld 14  vollstaendig",
			en: "Window field 14 complete"
		}
		,A2V00002341873 : {
			de: "Fensterfeld 15  vollstaendig",
			en: "Window field 15 complete"
		}
		,A2V00002342411 : {
			de: "Fensterfeld 18  vollstaendig",
			en: "Window field 18  complete"
		}
		,A2V00002528916 : {
			de: "Fahrersitz, vollst.",
			en: "Driver seat, compl."
		}
		,A2V00002218980 : {
			de: "Fuehrung",
			en: "Conduction"
		}
		,A2V00002219670 : {
			de: "Halter geschweisst",
			en: "Bracket welded"
		}
		,A2V00002220356 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002221338 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001988182 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001834845 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002616767 : {
			de: "Fernbetaetigung 1800mm",
			en: "Emergency release device 1800mm"
		}
		,A2V00002616768 : {
			de: "Fernbetaetigung 890mm",
			en: "Emergency release device 890mm"
		}
		,A2V00002222583 : {
			de: "Seitenklappe vollst. w.g.",
			en: "side flap complete a.d"
		}
		,A2V00002222586 : {
			de: "Seitenklappe vollst.",
			en: "side flap complete"
		}
		,A2V00002231454 : {
			de: "Trapezrahmen, vollst.",
			en: "Trapezoid frame, compl."
		}
		,A2V00002231588 : {
			de: "Trapezrahmen, vollst.",
			en: "Trapezoid frame, compl."
		}
		,A2V00002226410 : {
			de: "Haltestange vollst.",
			en: "Grab handle compl."
		}
		,A2V00002226420 : {
			de: "Buchse",
			en: "Bushing"
		}
		,A2V00002226425 : {
			de: "Konsole",
			en: "mounting bracket"
		}
		,A2V00002228936 : {
			de: "C-Schiene, vollst.",
			en: "C-rail, compl."
		}
		,A2V00002231486 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002236372 : {
			de: "Huelse",
			en: "Bushing"
		}
		,A2V00002233899 : {
			de: "Haltestange kompl.",
			en: "Holding rod compl."
		}
		,A2V00002233900 : {
			de: "Haltestange kompl.",
			en: "Holding rod compl."
		}
		,A2V00002556172 : {
			de: "Getriebe 2LB8085-5CA75",
			en: "Gear box 2LB8085-5CA75"
		}
		,A2V00002555181 : {
			de: "Kupplungsadapter",
			en: "Coupler adapter"
		}
		,A2V00002371810 : {
			de: "Bremsbelag Becorit B36 rechts",
			en: "Pair of brake pads Becorit B36 rightside"
		}
		,A2V00002371648 : {
			de: "Bremsbelag Becorit B36 links",
			en: "Pair of brake pads Becorit B36 leftside"
		}
		,A2V00002094277 : {
			de: "Steuergeraet 34TE mit MVB/Ethernet",
			en: "Control unit 34TE with MVB/ethernet"
		}
		,A2V00002503826 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002238948 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00002235641 : {
			de: "Traeger geschweisst",
			en: "Support welded"
		}
		,A2V00002242001 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00001056645 : {
			de: "Steuereinheit f. Stromabnehmer SBE920",
			en: "Control unit, pantograph"
		}
		,A2V00002236224 : {
			de: "Haltestange, vorne links",
			en: "handrail, front left"
		}
		,A2V00002236355 : {
			de: "Reservierungsanzeige Kopfstuetze rechts",
			en: "Reservation Display Head Rest right"
		}
		,A2V00002236730 : {
			de: "Abdeckung",
			en: "Cover"
		}
		,A2V00002244947 : {
			de: "Schutzblech",
			en: "Guard plate"
		}
		,A2V00002257259 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00001177900 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002240421 : {
			de: "RSDL-Halter, kpl. Var.A",
			en: "RSDL-bracked, complete mod.A"
		}
		,A2V00002242291 : {
			de: "Tuersturz innen",
			en: "Door lintel inside"
		}
		,A2V00002242292 : {
			de: "Tuersturz aussen",
			en: "Door lintel outside"
		}
		,A2V00002242795 : {
			de: "Abdeckung geschweisst",
			en: "Cover welded"
		}
		,A2V00370075555 : {
			de: "Duese DSP-2, Winkelduese 1,2X53",
			en: "Jet DSP-2, anglejet 1,2X53"
		}
		,A2V00001251836 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002256949 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screwÂ "
		}
		,A2V00002244951 : {
			de: "C-Schiene Klappsitz",
			en: "c-rail folding seat"
		}
		,A2V00002248215 : {
			de: "Tragschiene geschweisst",
			en: "Mounting rail welded"
		}
		,A2V00002250216 : {
			de: "Gewindeklotz",
			en: "Threaded cube"
		}
		,A2V00002250539 : {
			de: "Tragschiene geschweisst",
			en: "Mounting rail welded"
		}
		,A2V00002250923 : {
			de: "Blech w.g.",
			en: "Sheet as d."
		}
		,A2V00002251157 : {
			de: "Blech sp.g.",
			en: "Sheet r.i."
		}
		,A2V00002251163 : {
			de: "Schweissrohr",
			en: "Weldment pipe"
		}
		,A2V00002251165 : {
			de: "Schweissrohr",
			en: "Weldment pipe"
		}
		,A2V00002251301 : {
			de: "Schweissrohr",
			en: "Weldment pipe"
		}
		,A2V00001672055 : {
			de: "Spannungswandler QPSW 1000/600/400",
			en: "voltage transducer QPSW 1000/600/400"
		}
		,A2V00001856028 : {
			de: "IGBT-DRV-3K3-1200-04 mit CPLD-Konfig.",
			en: "IGBT-DRV-3K3-1200-04 with CPLD config."
		}
		,A2V00001856027 : {
			de: "IGBT-DRV-3K3-1200-03 mit CPLD-Konfig.",
			en: "IGBT-DRV-3K3-1200-03 with CPLD config."
		}
		,A2V00001428354 : {
			de: "Doppelwellenbalg, vollst.",
			en: "Double corrugated bellows, compl."
		}
		,A2V00002174198 : {
			de: "Stirnwandverkleidung links EZ, vollst.",
			en: "End wall panelling left, compl."
		}
		,A2V00002105458 : {
			de: "Deckenmodul M1, vollst.",
			en: "ceiling module M1, comp."
		}
		,A2V00002222581 : {
			de: "Seitenklappe vollst. w.g.",
			en: "side flap complete a.d."
		}
		,A2V00002148068 : {
			de: "Deckenmodul M14, vollst.",
			en: "ceiling module M14, comp."
		}
		,A2V00002321225 : {
			de: "Luftbehaelter",
			en: "Air reservoir"
		}
		,A2V00002321298 : {
			de: "Luftbehaelter",
			en: "Air reservoir"
		}
		,A2V00002225614 : {
			de: "Seitenklappe vollst.",
			en: "side flap complete"
		}
		,A2V00002226062 : {
			de: "Seitenklappe vollst. w.g.",
			en: "side flap complete a.d"
		}
		,A2V00002226064 : {
			de: "Seitenklappe vollst. sp.g.",
			en: "side flap complete r.i."
		}
		,A2V00002236807 : {
			de: "Seitenklappe vollst.",
			en: "side flap complete"
		}
		,A2V00002263275 : {
			de: "Bodenplatte Komplett",
			en: "Bottom Plate Compl."
		}
		,A2V00002096271 : {
			de: "Blindfeld 10 vollst.",
			en: "Window blind cover 10 complete"
		}
		,A2V00002096273 : {
			de: "Blindfeld 06 vollst.",
			en: "Blind cover 06 compl."
		}
		,A2V00002337520 : {
			de: "Wagenkasten teilausgebaut MW2-H-P",
			en: "Car box partially expanded MW2-H-P"
		}
		,A2V00002337522 : {
			de: "Wagenkasten teilausgebaut TW2.2-R4P ZUB",
			en: "Car box partially expanded TW2.2-R4P ZUB"
		}
		,A2V00002337523 : {
			de: "Wagenkasten teilausgebaut RW",
			en: "Car box partially expanded RW"
		}
		,A2V00002376640 : {
			de: "Erweiterungsbaugruppe Multi-I/O",
			en: "Extension board Multi-I/O"
		}
		,A2V00001848295 : {
			de: "Fensterfeld 10    vollstaendig",
			en: "Window field 10   complete"
		}
		,A2V00001848391 : {
			de: "Blindfeld 11 vollstaendig",
			en: "Blindfield 11 complete"
		}
		,A2V00002382368 : {
			de: "DREHZAPFEN               EINBAUFERTIG",
			en: "PIVOT                    READY FOR ASSEM"
		}
		,A2V00002574091 : {
			de: "Fahrsperre",
			en: "Tripcock"
		}
		,A2V00001684295 : {
			de: "Keillager",
			en: "v-mount"
		}
		,A2V00002059495 : {
			de: "Sechskantmutter ISO 7042 M36 8",
			en: "Hexagon nut ISO 7042 M36 8"
		}
		,A2V00002222574 : {
			de: "Seitenklappe vollst.",
			en: "side flap complete"
		}
		,A2V00002222576 : {
			de: "Seitenklappe vollst. sp.g.",
			en: "side flap complete r.i."
		}
		,A2V00002263411 : {
			de: "Bodenplatte kpl. w.g.",
			en: "Bottom Plate cpl. as dra."
		}
		,A2V00002263412 : {
			de: "Bodenplatte kpl. sp.g.",
			en: "Bottom Plate cpl. re. im."
		}
		,A2V00002175172 : {
			de: "Seitenklappe vollst.",
			en: "side flap complete"
		}
		,A2V00002327410 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002209090 : {
			de: "Relaisventil VCAV",
			en: "relay valve VCAV"
		}
		,A2V00002209087 : {
			de: "Relaisventil",
			en: "relay valve"
		}
		,A2V00002209086 : {
			de: "Relaisventil",
			en: "relay valve"
		}
		,A2V00002208975 : {
			de: "Relaisventil",
			en: "relay valve"
		}
		,A2V00002251592 : {
			de: "Schnellbremsventil",
			en: "application valve"
		}
		,A2V00002251472 : {
			de: "Sicherheitsventil 12 bar",
			en: "safety valve 12 bar"
		}
		,A2V00002251470 : {
			de: "Sicherheitsventil 9,5 bar",
			en: "safety valve 9,5 bar"
		}
		,A2V00002348287 : {
			de: "Tuerscheibe",
			en: "Glass"
		}
		,A2V00001441813 : {
			de: "Kohlebuerste",
			en: "Carbon brush"
		}
		,A2V00370083466 : {
			de: "Puffer",
			en: "Buffer"
		}
		,A2V00002052623 : {
			de: "Sechskantmutter DIN982 M16",
			en: "Hexagon nut DIN982 M16"
		}
		,A2V00002252053 : {
			de: "Winkel geschweisst",
			en: "Angle welded"
		}
		,A2V00001468109 : {
			de: "Drehzahlgeber",
			en: "Speed Sensor"
		}
		,A2V00370075237 : {
			de: "Drehdaempfer",
			en: "yawdamper"
		}
		,A2V00001946964 : {
			de: "Halter Erdungskontakt",
			en: "bracket earth return bru"
		}
		,A2V00002037219 : {
			de: "Drehdaempfer / Koppeldaempfer",
			en: "yaw damper / coupling damper"
		}
		,A2V00397213957 : {
			de: "RADSCHUTZKASTEN",
			en: "Wheel Housing"
		}
		,A2V00001376804 : {
			de: "Sicherheitsventil NHS",
			en: "SAFETY VALVE NHS"
		}
		,A2V00001870145 : {
			de: "Wiegand Radsensor",
			en: "Wiegand Wheel Sensor 8S"
		}
		,A2V00001887961 : {
			de: "Distanzscheibe 1mm",
			en: "spacer 1mm"
		}
		,A2V00001974959 : {
			de: "Seitenfenster Mittel-teilw.blind",
			en: "Side window partial blind"
		}
		,A2V00002041193 : {
			de: "Distanzhuelse fuer Primaerfed. lackiert",
			en: "Distance sleeve for prim. susp. painted"
		}
		,A2V00002049738 : {
			de: "Antennenhalter",
			en: "Aerial holder"
		}
		,A2V00002262135 : {
			de: "Winkel links",
			en: "Angle left"
		}
		,A2V00002337524 : {
			de: "Wagenkasten teilausgebaut TW1.2",
			en: "Car box partially expanded TW1.2"
		}
		,A2V00112500595 : {
			de: "Nutenstein  M8",
			en: "Grore stone"
		}
		,A2V00116205692 : {
			de: "Fussauslass",
			en: "Foot outlet"
		}
		,A2V00001994323 : {
			de: "Scharfenbergkupplung",
			en: "Scharfenberg coupler"
		}
		,A2V00002040921 : {
			de: "Beilage 1,5 mm",
			en: "spring shim 1,5 mm"
		}
		,A2V00002232204 : {
			de: "Halter",
			en: "holder"
		}
		,A2V00002024771 : {
			de: "Frontmaske vollst.",
			en: "front mask complete"
		}
		,A2V00002257788 : {
			de: "Stoepsel",
			en: "plug"
		}
		,A2V00002093045 : {
			de: "Fensterfeld 1724",
			en: "window panel 1724"
		}
		,A2V00002566624 : {
			de: "Fahr- / Bremsschalter NSB",
			en: "Master control switch NSB"
		}
		,A2V00002615797 : {
			de: "Fuehrerbremsventil FB11-4S",
			en: "driverÂ´s brake valve FB11-4S"
		}
		,A2V00002598360 : {
			de: "Gleitschutzventil N8.1",
			en: "anti skid valve N8.1"
		}
		,A2V00207356555 : {
			de: "Stutzen G1/2 - M26x1,5 Form N",
			en: "Nipple G1/2 - M26x1,5 form N"
		}
		,A2V00002136880 : {
			de: "Dichtrahmen, rechts",
			en: "Sealing frame, right"
		}
		,A2V00002136884 : {
			de: "Dichtrahmen, links",
			en: "Sealing frame, left"
		}
		,A2V00002137222 : {
			de: "Endstueck mit Bohrung, rechts vollst.",
			en: "End part with hole, right compl."
		}
		,A2V00001684535 : {
			de: "Zug-/ Druckstange",
			en: "pull-/ push rod"
		}
		,A2V00001949501 : {
			de: "Luftfedersystem",
			en: "Air spring system"
		}
		,A2V00001938255 : {
			de: "Erdungskontakt Frost AB 438 B",
			en: "Earthing brush AB 438 B"
		}
		,A2V00002037218 : {
			de: "Daempfer sekundaer horizontal",
			en: "Secondary lateral shock absorber"
		}
		,A2V00116200830 : {
			de: "Eiinlage Tritt",
			en: "Insert for step"
		}
		,A2V00001971883 : {
			de: "Endstueck, rechts vollst.",
			en: "End part, right compl."
		}
		,A2V00001971881 : {
			de: "Endstueck kurz, links vollst.",
			en: "End part short, left compl."
		}
		,A2V00001971877 : {
			de: "Endstueck, links vollst.",
			en: "End part, left compl."
		}
		,A2V00001757318 : {
			de: "Tischplatte-BG",
			en: "Table top, assembly"
		}
		,A2V00002252984 : {
			de: "Schotterschutzblech",
			en: "protection plate"
		}
		,A2V00001750600 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001750602 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001750603 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00107115300 : {
			de: "ROHR",
			en: "ROHR"
		}
		,A2V00370074728 : {
			de: "Schutzhuelse",
			en: "Cover bush"
		}
		,A2V00001279064 : {
			de: "Schlauchleitung",
			en: "Hose assembly"
		}
		,A2V00000216103 : {
			de: "Zunge",
			en: "point blade"
		}
		,A2V00001258914 : {
			de: "Beilage t=5",
			en: "Shim t=5"
		}
		,A2V00001258915 : {
			de: "Buegel",
			en: "Bracket"
		}
		,A2V00002253110 : {
			de: "RSDL-Halter, kpl. Var.B",
			en: "RSDL-bracked, complete mod.B"
		}
		,A2V00002273498 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00397120789 : {
			de: "DREHGESTELLRAHMEN WG.808 WE1",
			en: "bogie frame car 808 CE1"
		}
		,A2V00370060724 : {
			de: "Drehdaempfer",
			en: "yaw damper"
		}
		,A2V00001068608 : {
			de: "Sicherungsschraube W158 M8x30-10.9",
			en: "Safety screw  W158 M8x30-10.9"
		}
		,A2V00370061830 : {
			de: "Sechskantschraube M16X75 (Radsatz)",
			en: "Hexagon bolt M16X75 (wheelset)"
		}
		,A2V00370081141 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical damper"
		}
		,A2V00397234457 : {
			de: "Federteller unten, lackiert",
			en: "Spring plate bellow, painted"
		}
		,A2V00370067622 : {
			de: "Vollrad 1250/1170-22-236",
			en: "solid wheel 1250/1170-22-236"
		}
		,A2V00397247680 : {
			de: "HALTER. VOLLST.",
			en: "HOLDER, COMPL"
		}
		,A2V00002253865 : {
			de: "Scheinwerferkombination, rechts",
			en: "headlight combination, right"
		}
		,A2V00002255243 : {
			de: "Bodenprofil 3 aussen",
			en: "floor profile 3 outside"
		}
		,A2V00002255244 : {
			de: "Bodenprofil 2",
			en: "floor profile 2"
		}
		,A2V00002255245 : {
			de: "Bodenprofil 1 mitte",
			en: "floor profile 1 centre"
		}
		,A2V00000316618 : {
			de: "Druckscheibe",
			en: "pressure disc"
		}
		,A2V00001806554 : {
			de: "Fahrmotor 1TB2822-0SD02",
			en: "Traction drive"
		}
		,A2V00370072493 : {
			de: "Zug-/ Druckstange",
			en: "pull-/ push rod"
		}
		,A2V00001396507 : {
			de: "Isolierbuchse fuer Erdungskontakt",
			en: "insulating bush for ground brush"
		}
		,A2V00002255545 : {
			de: "Rollstuhlrampe",
			en: "wheelchair ramp"
		}
		,A2V00001440593 : {
			de: "Schichtfeder",
			en: "layer spring"
		}
		,A2V00001743493 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001184006 : {
			de: "Lagergehause Unterteil, lack.",
			en: "Bearing housing lower part, coated"
		}
		,A2V00001184005 : {
			de: "Lagergehause Oberteil, lack.",
			en: "Bearing housing upper part, coated"
		}
		,A2V00002256134 : {
			de: "Bodenprofil 1 mitte",
			en: "floor profile 1 centre"
		}
		,A2V00002256147 : {
			de: "Bodenprofil 3 aussen",
			en: "floor profile 3 outside"
		}
		,A2V00002256148 : {
			de: "Bodenprofil 2",
			en: "floor profile 2"
		}
		,A2V00370026926 : {
			de: "4KT DIN1014-12-S235JRG2",
			en: "SQUARE DIN1014-12-S235JRG2"
		}
		,A2V00002256198 : {
			de: "Dachprofil 2",
			en: "Roof profile 2"
		}
		,A2V00002256199 : {
			de: "Dachprofil 2",
			en: "Roof profile"
		}
		,A2V00002256200 : {
			de: "Dachprofil 3",
			en: "roof profile 3"
		}
		,A2V00002256201 : {
			de: "Dachprofil 3",
			en: "roof profile 3"
		}
		,A2V00002256504 : {
			de: "C-Schiene Klappsitz",
			en: "c-rail folding seat"
		}
		,A2V00002256911 : {
			de: "Voute",
			en: "concrete haunch"
		}
		,A2V00002256913 : {
			de: "Profil Saeule     Einzug",
			en: "profile column    heeling"
		}
		,A2V00002256915 : {
			de: "Portalrahmenprofil",
			en: "portal frame profile"
		}
		,A2V00002256968 : {
			de: "A-Saeule, Profil",
			en: "A-post, profile"
		}
		,A2V00001139444 : {
			de: "Fahrmotor 1TB2006-0GA02",
			en: "traction motor 1TB2006-0GA02"
		}
		,A2V00002077592 : {
			de: "Sechskantmutter ISO 7042 M24 10",
			en: "Hexagon nut ISO 7042 M24 10"
		}
		,A2V00001421045 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002256971 : {
			de: "C-Schiene, Profil",
			en: "c-rail, profile"
		}
		,A2V00002257261 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00002305215 : {
			de: "Anschluss Bodenplatte",
			en: "Floor connection"
		}
		,A2V00002305275 : {
			de: "Bodenplatte Profil",
			en: "Part floor plate profile"
		}
		,A2V00002306032 : {
			de: "UG-Langtraeger Profil",
			en: "UF-beam profile"
		}
		,A2V00002308002 : {
			de: "Hauptquertraeger Profil",
			en: "Main cross beam profile"
		}
		,A2V00002259921 : {
			de: "Bremssteuergeraet Endwagen",
			en: "Control unit"
		}
		,A2V00002259922 : {
			de: "Bremssteuergeraet Mittelwagen",
			en: "Control unit"
		}
		,A2V00002260242 : {
			de: "Drucklufterzeugungsanlage",
			en: "air copressed rack"
		}
		,A2V00370074665 : {
			de: "Schutzwiderstand",
			en: "Protective resistor"
		}
		,A2V00002261160 : {
			de: "Gepaeckablage 3",
			en: "luggage rack 3"
		}
		,A2V00002261163 : {
			de: "Gepaeckablage 6",
			en: "Luggage rack 6"
		}
		,A2V00002261165 : {
			de: "Gepaeckablage 8",
			en: "Luggage rack 8"
		}
		,A2V00002261828 : {
			de: "Gepaeckablage 11",
			en: "Luggage rack 11"
		}
		,A2V00002261832 : {
			de: "Gepaeckablage 15",
			en: "Luggage rack 15"
		}
		,A2V00002261833 : {
			de: "Gepaeckablage 16",
			en: "Luggage rack 16"
		}
		,A2V00002261842 : {
			de: "Gepaeckablage 25",
			en: "Luggage rack 25"
		}
		,A2V00002261843 : {
			de: "Gepaeckablage 26",
			en: "Luggage rack 26"
		}
		,A2V00002262223 : {
			de: "U-Profil",
			en: "U-Profile"
		}
		,A2V00002262287 : {
			de: "Verbindungsrohr",
			en: "Connector pipe"
		}
		,A2V00002262288 : {
			de: "Verbindungsrohr",
			en: "Connector pipe"
		}
		,A2V00002262325 : {
			de: "Bolzenplatte vollst.",
			en: "Sheet comp."
		}
		,A2V00002313253 : {
			de: "Bodenprofil f. Vorbau",
			en: "Floor profile f. front end"
		}
		,A2V00002313255 : {
			de: "Bodenprofil f. Vorbau",
			en: "Floor profile f. front end"
		}
		,A2V00002263552 : {
			de: "BLG-Container mit 3AC400V",
			en: "BLG-container with 3AC400V"
		}
		,A2V00002263603 : {
			de: "Traktionsstromrichter mit HBU",
			en: "Traction converter with ACU"
		}
		,A2V00002269641 : {
			de: "Kurzkupplungshaelfte,    kpl. kurz",
			en: "Semip. coupler half, cpl. short"
		}
		,A2V00002269642 : {
			de: "Kurzkupplungshaelfte, kpl. lang",
			en: "Semipermanent coupler half, lcpl. long"
		}
		,A2V00002269643 : {
			de: "Uebergangskupplung kpl.",
			en: "Coupling Adapter UIC / SD10"
		}
		,A2V00002117742 : {
			de: "Drehzahlgeber GEL 247",
			en: "Speed sensor GEL247"
		}
		,A2V00002110020 : {
			de: "Fernbetaetigung 900mm",
			en: "Emergency release device 900mm"
		}
		,A2V00002110021 : {
			de: "Fernbetaetigung 2000mm",
			en: "Emergency release device 2000mm"
		}
		,A2V00002269640 : {
			de: "Automatische Kupplung,  kpl. TYP SD10",
			en: "Automatic coupler, cpl. TYPE SD10"
		}
		,A2V00002328225 : {
			de: "Schiebetritt, vollst.",
			en: "Sliding step, compl."
		}
		,A2V00002109832 : {
			de: "Puffer",
			en: "buffer"
		}
		,A2V00002574047 : {
			de: "Bremswiderstand 3PQ4-B00813 BTSC",
			en: "Brake resistor 3PQ4-B00813 BTSC"
		}
		,A2V00002604407 : {
			de: "Stromabnehmermodul",
			en: "Current collector module"
		}
		,A2V00002606117 : {
			de: "Luftfederventil RV4",
			en: "Air-spring valve RV4"
		}
		,A2V00002580828 : {
			de: "Drehzahlgeber GEL 2475Y213",
			en: "Speed sensor GEL 2475Y213"
		}
		,A2V00002599946 : {
			de: "Stromabnehmer Bangkok GL rechts komplett",
			en: "Shoegear Bangkok GL right compl."
		}
		,A2V00002599947 : {
			de: "Stromabnehmer Bangkok GL links komplett",
			en: "Shoegear Bangkok GL left compl."
		}
		,A2V00370053226 : {
			de: "Knochenbuchse",
			en: "Spheribloc"
		}
		,A2V00002598484 : {
			de: "Gleitschutzventileinheit BTSC-GL",
			en: "Protection unit slip metro BTSC-GL"
		}
		,A2V00002604154 : {
			de: "Lueftereinheit FR",
			en: "Ventilator-unit cab"
		}
		,A2V00002586356 : {
			de: "Erweiterungsbaugruppe SPIO",
			en: "Sub Unit SPIO"
		}
		,A2V00002604155 : {
			de: "Schalttafel",
			en: "Control panel"
		}
		,A2V00002264170 : {
			de: "Einstiegsprofil",
			en: "entry area profile"
		}
		,A2V00001350881 : {
			de: "Puffer",
			en: "buffer"
		}
		,A2V00001669005 : {
			de: "Luftfederbeilage 2mm",
			en: "washer air spring 2mm"
		}
		,A2V00001669270 : {
			de: "Luftfederbeilage 5mm",
			en: "washer air spring 5mm"
		}
		,A2V00001680104 : {
			de: "Luftfederbeilage 1mm",
			en: "washer air spring 1mm"
		}
		,A2V00002363370 : {
			de: "Abdeckung mitte",
			en: "Covering middle"
		}
		,A2V00002309189 : {
			de: "Luftfedersystem",
			en: "Airspring System"
		}
		,A2V00370081054 : {
			de: "Beilage 2mm",
			en: "Shim 2mm"
		}
		,A2V00370081053 : {
			de: "Beilage 1mm",
			en: "Shim 1mm"
		}
		,A2V00002273630 : {
			de: "Mg-Bremse Hochaufhaengung",
			en: "Magnetic track brake"
		}
		,A2V00002273645 : {
			de: "Radbremszange RZSS41M24F135",
			en: "brake caliper/wheel m. RZSS41M24F135"
		}
		,A2V00002281039 : {
			de: "Radbremszange",
			en: "Brake caliper/wheel m."
		}
		,A2V00002280277 : {
			de: "Drehsaeule, vollst.",
			en: "Locking column, compl."
		}
		,A2V00002280278 : {
			de: "Drehsaeule, vollst.",
			en: "Locking column, compl."
		}
		,A2V00001254189 : {
			de: "Puffer",
			en: "Buffer"
		}
		,A2V00002094809 : {
			de: "Massenspeicher (NAS 4GB)",
			en: "Mass storage  (NAS 4GB)"
		}
		,A2V00002575633 : {
			de: "RS Lagerdeckel f. Erdunkskontakt lack.",
			en: "AB Cover for earthing painted"
		}
		,A2V00002592972 : {
			de: "Daempfer sekundaer horizontal",
			en: "Secondary lateral shock absorber"
		}
		,A2V00002557596 : {
			de: "Bodenklappe 19 kompl.",
			en: "Floor hatch 19 completed"
		}
		,A2V00370025505 : {
			de: "Schmierstift",
			en: "Interlocking stick"
		}
		,A2V00370080022 : {
			de: "Erdungskontakt",
			en: "Grounding device"
		}
		,A2V00002560692 : {
			de: "Hilfsbetriebeumrichter",
			en: "Auxiliary inverter"
		}
		,A2V00001096091 : {
			de: "Eingangsstufe potenzialfrei binaer 110V",
			en: "Input Converter Binary 110V"
		}
		,A2V00001447652 : {
			de: "Drehstabfeder LDG vollst.",
			en: "Torsion bar TB complete"
		}
		,A2V00001891140 : {
			de: "Drehstabfeder TDG vollst.",
			en: "Torsion bar MB complete"
		}
		,A2V00002557604 : {
			de: "Bodenklappe 1 kompl.",
			en: "Floor hatch 1 completed"
		}
		,A2V00002376581 : {
			de: "Master Control Unit (MCU)",
			en: "Master control unit (MCU)"
		}
		,A2V00002684004 : {
			de: "T1 Kontrolleinheit ICU2",
			en: "T1 Inverter Control Unit ICU2"
		}
		,A2V00002684005 : {
			de: "T2 Kontrolleinheit ICU2",
			en: "T2 Inverter Control Unit ICU2"
		}
		,A2V00002683997 : {
			de: "T1 Eingang, Baustein  mit ICU2",
			en: "T1 Input converter module with ICU2"
		}
		,A2V00002683998 : {
			de: "Gleichrichter kpl. T4",
			en: "Rectifier module T4"
		}
		,A2V00002684000 : {
			de: "Intrexis Notstart IC506_1",
			en: "Dead Battery Start IC506_1"
		}
		,A2V00002684001 : {
			de: "T2 Ausgang, Baustein  mit ICU2",
			en: "T2 Output converter module with ICU2"
		}
		,A2V00000211225 : {
			de: "WIG 16P, 3,0m Ltg./Kupplung kurz",
			en: "Odometer pulse generator"
		}
		,A2V00002557605 : {
			de: "Bodenklappe 3 kompl.",
			en: "Floor hatch 3 completed"
		}
		,A2V00112506903 : {
			de: "Handapparat HA2002",
			en: "Hand set HA2002"
		}
		,A2V00002557788 : {
			de: "Bodenklappe 4 kompl.",
			en: "Floor hatch 4 completed"
		}
		,A2V00002557790 : {
			de: "Bodenklappe 5 kompl.",
			en: "Floor hatch 5 completed"
		}
		,A2V00113039245 : {
			de: "Drucksensor",
			en: "pressure sensor"
		}
		,A2V00002557795 : {
			de: "Bodenklappe 6 kompl.",
			en: "Floor hatch 6 completed"
		}
		,A2V00103010945 : {
			de: "GLEITPLATTE",
			en: "SLIDING PLATE"
		}
		,A2V00002096582 : {
			de: "Montagemat. Lagergehaeuse und Druckkappe",
			en: "Mounting material axle box and end cap"
		}
		,A2V00001877771 : {
			de: "Bremsbelag geteilt",
			en: "Brake pad split"
		}
		,A2V00002557978 : {
			de: "Bodenklappe 7 kompl.",
			en: "Floor hatch 7 completed"
		}
		,A2V00002304375 : {
			de: "Gleitschutzventil DAKO-N8.1",
			en: "WSP dump valve DAKO-N8.1"
		}
		,A2V00397161988 : {
			de: "Zug - / Druckstange, lack.",
			en: "Guide bar, lacquered"
		}
		,A2V00002142855 : {
			de: "Schlauchleitung 2TE 8 N90N90-550-0 P",
			en: "Hose assembly 2TE 8 N90N90-550-0 P"
		}
		,A2V00002143106 : {
			de: "Schlauchleitung 2TE 16 N90N90-620-0 P",
			en: "Hose assembly 2TE 16 N90N90-620-0 P"
		}
		,A2V00002265455 : {
			de: "Anschweissanbau",
			en: "Welding group"
		}
		,A2V00002266161 : {
			de: "Aussenwand 1",
			en: "Outer wall 1"
		}
		,A2V00002557981 : {
			de: "Bodenklappe 8 kompl.",
			en: "Floor hatch 8 completed"
		}
		,A2V00002266162 : {
			de: "Aussenwand 2",
			en: "Outer wall 2"
		}
		,A2V00002266163 : {
			de: "Seitenwand 1",
			en: "Side wall 1"
		}
		,A2V00002266164 : {
			de: "Innenwand mit Garderobe und Geschraenke",
			en: "Inner wall with wardrobe and boxes"
		}
		,A2V00002266165 : {
			de: "Seitenwand 2",
			en: "Side wall 2"
		}
		,A2V00002558309 : {
			de: "Bodenklappe 10 kompl.",
			en: "Floor hatch 10 completed"
		}
		,A2V00001008935 : {
			de: "Luefterzeile SIBAS 32",
			en: "fan subassembly 28 seP 1U"
		}
		,A2V00002558497 : {
			de: "Bodenklappe 13 kompl.",
			en: "Floor hatch 13 completed"
		}
		,A2V00002013035 : {
			de: "Kabelbaum Scheinwerfer u. Hydraulik li",
			en: "wiring harness headlight and hydr. le."
		}
		,A2V00002013036 : {
			de: "Kabelbaum Scheinwerfer u. Hydraulik re",
			en: "wiring harness headlight and hydr. ri."
		}
		,A2V00002558501 : {
			de: "Bodenklappe 15 kompl.",
			en: "Floor hatch 15 completed"
		}
		,A2V00107609760 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370071543 : {
			de: "PMAFIX Anschlussarmatur 90 Winkel",
			en: "PMAFIX Connector 90 elbow"
		}
		,A2V00002558551 : {
			de: "Bodenklappe 18 kompl.",
			en: "Floor hatch 18 completed"
		}
		,A2V00002005777 : {
			de: "Schraubendruckfedersatz 1",
			en: "Helical Compression Spring TB 1"
		}
		,A2V00370030614 : {
			de: "Zylinderschraube",
			en: "Hexagon sockt head cap screw"
		}
		,A2V00002082065 : {
			de: "Huelse",
			en: "bush"
		}
		,A2V00002720243 : {
			de: "Einschub Batterietrog 1",
			en: "drawer for batteries  1"
		}
		,A2V00002720244 : {
			de: "Einschub Batterietrog 2",
			en: "drawer for batteries 2"
		}
		,A2V00002073333 : {
			de: "Achsstrebengelenk",
			en: "Axle stay joint"
		}
		,A2V00002170046 : {
			de: "Abziehvorrichtung",
			en: "Pull-off device"
		}
		,A2V00002152556 : {
			de: "Luftfedersystem",
			en: "Air spring system"
		}
		,A2V00002155191 : {
			de: "Fahrmotor vollstaendig",
			en: "Traction motor complete"
		}
		,A2V00002605484 : {
			de: "Luftversorgungsanlage VV90-T",
			en: "Compressor module VV90-T"
		}
		,A2V00001895837 : {
			de: "Gehaeusering",
			en: "Housing ring"
		}
		,A2V00001995456 : {
			de: "Vollrad, lackiert",
			en: "solid wheel painted 920/840-19-214"
		}
		,A2V00002185132 : {
			de: "Bediengeraet CHI6200",
			en: "Control panel CHI6200"
		}
		,A2V00002590841 : {
			de: "E-Container IDC +61 DMOSB vormontiert",
			en: "E-Container IDC +61 DMOSB pre-assembled"
		}
		,A2V00002590842 : {
			de: "E-Container IDC +62 DMOSB vormontiert",
			en: "E-Container IDC +62 DMOSB pre-assembled"
		}
		,A2V00002110870 : {
			de: "Druckluftbehaelter 60l",
			en: "compressed air reservoir 60l"
		}
		,A2V00002152449 : {
			de: "Treibradsatzwelle mit Getriebe",
			en: "Motor axle with gearbox"
		}
		,A2V00002256247 : {
			de: "Langtaregerprofil NF",
			en: "longit. beam profile LF"
		}
		,A2V00002309149 : {
			de: "Tuerfluegel",
			en: "Door leaf assemb. driver"
		}
		,A2V00002168131 : {
			de: "EBULA Display",
			en: "EBULA display"
		}
		,A2V00002052048 : {
			de: "Luftfedersystem",
			en: "Airspring System"
		}
		,A2V00002230853 : {
			de: "Beschleunigungssensor 10g PCB 363A04",
			en: "Accelerometer 10g PCB 363A04"
		}
		,A2V00002194826 : {
			de: "GSM-R Box 6112 -  CGB6112",
			en: "GSM-R Box 6112 -  CGB6112"
		}
		,A2V00002037654 : {
			de: "automatische Kupplung Velaro Rus 2",
			en: "Automatic coupler Velaro Rus 2"
		}
		,A2V00001838358 : {
			de: "Transformator",
			en: "transformer"
		}
		,A2V00002201614 : {
			de: "Daempfer sekundaer horizontal",
			en: "Secondary lateral shock absorber"
		}
		,A2V00002558491 : {
			de: "WC-Kabine vollst.",
			en: "WC cabin compl."
		}
		,A2V00001714100 : {
			de: "Luftbehaelter DIN 5580-C1-75",
			en: "Air reservoir DIN 5580-C1-75"
		}
		,A2V00001714954 : {
			de: "Luftbehaelter DIN 5580-C1-40",
			en: "Air reservoir DIN 5580-C1-40"
		}
		,A2V00001290844 : {
			de: "TCN 6U Gateway (TCN GW)",
			en: "TCN 6U Gateway (TCN GW)"
		}
		,A2V00117621019 : {
			de: "VERKLEIDUNG        C-SAEULE SP.G.",
			en: "Covering C-column r. i."
		}
		,A2V00117621005 : {
			de: "Verkleidung        B-Saeule sp.g.",
			en: "covering B-column  r. i."
		}
		,A2V00117621002 : {
			de: "Verkleidung       A-Saeule",
			en: "Cover C-column"
		}
		,A2V00117611002 : {
			de: "Verkleidung       A-Saeule",
			en: "Cover C-column"
		}
		,A2V00001293823 : {
			de: "Laufradsatz, vollst.",
			en: "Trailer wheel set compl."
		}
		,A2V00112510718 : {
			de: "HALTEZUNGE,       VOLLST.",
			en: "securing tang, complete"
		}
		,A2V00370060327 : {
			de: "Impulsgeber zweikanal",
			en: "Speed Sensor dual channel"
		}
		,A2V00001373616 : {
			de: "Buchholzrelais    fuer Haupttransf",
			en: "Buchholz Relay for Main Transformer"
		}
		,A2V00370036276 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00370068204 : {
			de: "Daempfer primaer vertikal",
			en: "primary vertical damper"
		}
		,A2V00001396956 : {
			de: "Anlaufscheibe",
			en: "Washer disc"
		}
		,A2V00114242059 : {
			de: "Flachkopfschraube",
			en: "Hexagon socket button head screw"
		}
		,A2V00001209141 : {
			de: "Schlauchleitung 2TE 8 NN90 750 P",
			en: "Hose assembly 2TE 8 NN90 750 P"
		}
		,A2V00001208050 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370062272 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002267338 : {
			de: "Kabelpeitsche 4-pol, vollstaendig",
			en: "breakout cable 4-pin, complete"
		}
		,A2V00002326630 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00370059475 : {
			de: "Zug-/ Druckstange",
			en: "pull-/ push rod"
		}
		,A2V00150268003 : {
			de: "4QS-MODUL          ZSU",
			en: "4QS-MODUL          ZSU"
		}
		,A2V00370067887 : {
			de: "NH-Sicherungsunterteil",
			en: "LV HRC fuse base"
		}
		,A2V00370072826 : {
			de: "BL 5X1500X3000-X5CRNI18-10+1D",
			en: "BL 5X1500X3000-X5CRNI18-10+1D"
		}
		,A2V00002268004 : {
			de: "Kabelpeitsche 3-pol, vollstaendig",
			en: "breakout cable 3-pin, complete"
		}
		,A2V00001285241 : {
			de: "Bajonettring      vollst. 20W",
			en: "bajonet mount     compl. 20W"
		}
		,A2V00397163623 : {
			de: "FEDERBEILAGE 5MM",
			en: "SHIM 5MM"
		}
		,A2V00001262080 : {
			de: "Drossel",
			en: "choke"
		}
		,A2V00002251471 : {
			de: "Sicherheitsventil 10,5 bar",
			en: "safety valve 10,5 bar"
		}
		,A2V00002190998 : {
			de: "RSL-Deckel vorne lh lackiert",
			en: "Axle box front cover lh painted"
		}
		,A2V00002075089 : {
			de: "DC-Schnellschalter",
			en: "High Speed Circuit Breaker"
		}
		,A2V00002080051 : {
			de: "Daempfer sekundaer horizontal",
			en: "Secondary lateral shock absorber"
		}
		,A2V00002255342 : {
			de: "Schleifstueck mit AS-Anschluss",
			en: "Carbon Strip"
		}
		,A2V00002201307 : {
			de: "Luftbehaelter DIN 5590- A-100-UIC",
			en: "Air reservoir DIN 5590-  A-100-UIC"
		}
		,A2V00001903667 : {
			de: "Klappsitz",
			en: "tip-up-seat"
		}
		,A2V00002150235 : {
			de: "Radbremszange",
			en: "Brake disc caliper"
		}
		,A2V00002278631 : {
			de: "Fahrschalter Zugkraftsteller S334 GM20A",
			en: "Contr.tractive effort contr.S334 GM20A"
		}
		,A2V00002278630 : {
			de: "Fahrschalter V-Sollsteller S334 GM20B",
			en: "Power contr.V-preset speed S334 GM20B"
		}
		,A2V00001834654 : {
			de: "GMT-Schichtfeder AZ002445",
			en: "GMT-Schichtfeder AZ002445"
		}
		,A2V00370064008 : {
			de: "Kontaktscheibe",
			en: "CONTACT DISK"
		}
		,A2V00370036748 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370035877 : {
			de: "Deckel",
			en: "Cover"
		}
		,A2V00002730128 : {
			de: "Rohrstueck",
			en: "pipe"
		}
		,A2V00002562455 : {
			de: "Beschleunigungssensor B&K 8349 B",
			en: "Accelerometer B&K 8349 B"
		}
		,A2V00002612728 : {
			de: "Bremsgeraeteeinheit 2P",
			en: "brake control unit 2P"
		}
		,A2V00002687482 : {
			de: "Bremsgeraeteeinheit 2P",
			en: "brake control unit 2P"
		}
		,A2V00002612726 : {
			de: "Bremsgeraeteeinheit 2P",
			en: "brake control unit 2P"
		}
		,A2V00002603542 : {
			de: "Batteriesystem",
			en: "battery system"
		}
		,A2V00001878223 : {
			de: "Kegelrollenlager 150/250",
			en: "tapered roller bearing 150/250"
		}
		,A2V00001824806 : {
			de: "Treibradsatz vollst.",
			en: "Motor wheelset compl."
		}
		,A2V00002005276 : {
			de: "Schellenhalter lack.",
			en: "Pipe clamp bracket paint."
		}
		,A2V00002624585 : {
			de: "Fremdeinspeisedose, ETBv/ komplett",
			en: "External power supply complete"
		}
		,A2V00001970756 : {
			de: "Halter lackiert",
			en: "bracket painted"
		}
		,A2V00001999512 : {
			de: "Schutzblech Rohr voll spg",
			en: "Protection plate comp oad"
		}
		,A2V00002000012 : {
			de: "Schutzblech geschw.",
			en: "protection plate weld."
		}
		,A2V00002000093 : {
			de: "Schutzblech rechts",
			en: "protection plate right"
		}
		,A2V00002000185 : {
			de: "Schutzblech links",
			en: "protection plate left"
		}
		,A2V00002000718 : {
			de: "Zwischenblech 2 vollst.",
			en: "adjustment shim 2 compl."
		}
		,A2V00002000747 : {
			de: "Zwischenblech 1 vollst.",
			en: "adjustment shim 1 compl."
		}
		,A2V00002000882 : {
			de: "Schutzblech Rohr voll wg",
			en: "Protection plate comp ad"
		}
		,A2V00002599620 : {
			de: "Bremswiderstand",
			en: "Braking resistor"
		}
		,A2V00002626635 : {
			de: "Geraeteschr.++RBA4D+15 komplett",
			en: "Equip.cabinet ++RBA4D+15 complete"
		}
		,A2V00002626836 : {
			de: "Geraeteschr.++RBA4A+15 komplett",
			en: "Equip. cabinet ++RBA4A+15 complete"
		}
		,A2V00002626837 : {
			de: "Geraetecontainer  kpl., ++RBC+69.10",
			en: "Equipment container  cpl., ++RBC+69.10"
		}
		,A2V00002626838 : {
			de: "Geraetecontainer  kpl., ++RBA+69.10",
			en: "Equipment container  cpl., ++RBA+69.10"
		}
		,A2V00002547108 : {
			de: "Geraetecontainer +76.20 kpl. ++RBA4A/D",
			en: "Equipment contain. +76.20 cpl. ++RBA4A/D"
		}
		,A2V00002547122 : {
			de: "Geraetecontainer  +73.31 kpl., ++RBC4B/C",
			en: "Equipment cont. +73.31 cpl., ++RBC4B/C"
		}
		,A2V00002626839 : {
			de: "Geraetecont.+73.31,kpl.,++RBA2A,++RBA4A",
			en: "E-cont.+73.31, cpl.,++RBA2A,++RBA4A"
		}
		,A2V00001949710 : {
			de: "Schlauchleitung 2TE",
			en: "Hose Pipe 2TE"
		}
		,A2V00002547121 : {
			de: "Geraetecontainer +73.31  kpl.,++RBB2B",
			en: "Equipment cont.+73.31 cpl.,++RBB2B"
		}
		,A2V00002547113 : {
			de: "Geraetecontainer   kpl., ++RBC+61.10",
			en: "Equipment container  cpl., ++RBC+61.10"
		}
		,A2V00207356886 : {
			de: "Drucksensor DG10-S",
			en: "Pressure sensor DG10-S"
		}
		,A2V00002327148 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00201475587 : {
			de: "Sandtreppe KM-ED lackiert",
			en: "Sanding step KM-ED coated"
		}
		,A2V00002269739 : {
			de: "Leiste kurz",
			en: "Ledge short"
		}
		,A2V00002270830 : {
			de: "Abdeckblech",
			en: "cover plate"
		}
		,A2V00002270991 : {
			de: "Verstaerkungsprofil, Kasten",
			en: "slide step profile"
		}
		,A2V00002270996 : {
			de: "Glastrennwand 15, montiert",
			en: "Glass partition wall 15, assembled"
		}
		,A2V00002271050 : {
			de: "Gelenkhalter, ges.",
			en: "Support, welded"
		}
		,A2V00002271087 : {
			de: "Rohrsystem Teil 1",
			en: "Tube system part 1"
		}
		,A2V00002271088 : {
			de: "Rohrsystem Teil 2",
			en: "tube system part 2"
		}
		,A2V00002271154 : {
			de: "Abdeckblech",
			en: "cover plate"
		}
		,A2V00002271367 : {
			de: "Entstoerfilter",
			en: "supressor filter"
		}
		,A2V00002271473 : {
			de: "Abdeckblech, geschweisst",
			en: "Cover sheet, welded"
		}
		,A2V00002271504 : {
			de: "Schweissauflage Tuerblech w. g.",
			en: "welding support door sheet as d."
		}
		,A2V00002336756 : {
			de: "Treibradsatz vollst.",
			en: "Motor wheelset compl."
		}
		,A2V00002464295 : {
			de: "Schellenhaelfte",
			en: "Clamp half"
		}
		,A2V00002464666 : {
			de: "Schellenhaelfte",
			en: "Clamp half"
		}
		,A2V00002120366 : {
			de: "Notbremsschlagknopf",
			en: "emergency push button"
		}
		,A2V00002120383 : {
			de: "Fuehrerbremsventil",
			en: "driverÂ´s brake valve"
		}
		,A2V00002120388 : {
			de: "Bremsgeraetetafel direkte u. ind. Bremse",
			en: "brake panel for direct and ind. brake"
		}
		,A2V00002120385 : {
			de: "HL-Steuertafel",
			en: "BP panel"
		}
		,A2V00002120396 : {
			de: "Schnellbremsventil mit Traeger",
			en: "application valve with bracket"
		}
		,A2V00002374548 : {
			de: "SIBAC BB AC-1800W (TBK)",
			en: "SIBAC BB AC-1800W (TBK)"
		}
		,A2V00002564818 : {
			de: "Hilfsbetriebeumrichter",
			en: "Auxilary power supply"
		}
		,A2V00002565569 : {
			de: "Traktionsumrichter",
			en: "traction converter"
		}
		,A2V00355104212 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002338376 : {
			de: "LED Abdeckung",
			en: "LED Covering"
		}
		,A2V00002338377 : {
			de: "LED Abdeckung",
			en: "LED Covering"
		}
		,A2V00370062144 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002315601 : {
			de: "Befuellstutzen vst.",
			en: "Filler neck  cpl."
		}
		,A2V00001862617 : {
			de: "Schraube f. Drehmomentstuetze",
			en: "Screw for torque bracket"
		}
		,A2V00001880460 : {
			de: "Notrufeinrichtung  Halteschale",
			en: "Emergency device holding frame"
		}
		,A2V00002234678 : {
			de: "Luftfederbeilage 2 mm",
			en: "Shim for air spring 2 mm"
		}
		,A2V00002234679 : {
			de: "Luftfederbeilage 5 mm",
			en: "Shim for air spring 5 mm"
		}
		,A2V00002234680 : {
			de: "Luftfederbeilage 10 mm",
			en: "Shim for air spring 10 mm"
		}
		,A2V00001064880 : {
			de: "Senkeinrichtung automatisch",
			en: "Countersunkattachment automatic"
		}
		,A2V00370081292 : {
			de: "HOURGLASS FEDER",
			en: "HOURGLASS SPRING"
		}
		,A2V00370061289 : {
			de: "Buchse",
			en: "Bushing"
		}
		,A2V00002235746 : {
			de: "Blech 45X2000X4000 S355J2+N+Z25+100%UT",
			en: "Sheet 45X2000X4000 S355J2+N+Z25+100%UT"
		}
		,A2V00002235753 : {
			de: "Blech 130X1000X2000 S355J2+N+Z25+100%UT",
			en: "Sheet 130X1000X2000 S355J2+N+Z25+100%UT"
		}
		,A2V00001056277 : {
			de: "GTO-Bremsbaustein",
			en: "GTO Control"
		}
		,A2V00002167176 : {
			de: "Blech 35X2000X4000 S355J2+N+Z25+100%UT",
			en: "Sheet 35X2000X4000 S355J2+N+Z25+100%UT"
		}
		,A2V00002228074 : {
			de: "Blech 25X2000X4000 S355J2C+N",
			en: "Sheet 25X2000X4000 S355J2C+N"
		}
		,A2V00002106135 : {
			de: "Blech 30X2000X4000 S355J2C+N+Z25+100%UT",
			en: "Sheet 30X2000X4000 S355J2C+N+Z25+100%UT"
		}
		,A2V00002235748 : {
			de: "Blech 60X2000X4000 S355J2+N+Z25+100%UT",
			en: "Sheet 60X2000X4000 S355J2+N+Z25+100%UT"
		}
		,A2V00355404025 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00114229949 : {
			de: "Sicherungsmutter VARGAL",
			en: "Vargal-Nut, standard"
		}
		,A2V00114247991 : {
			de: "Sicherungsmutter VARGAL",
			en: "nut VARGAL"
		}
		,A2V00002167177 : {
			de: "Blech 40X2000X4000 S355J2+N+Z25+100%UT",
			en: "Sheet 40X2000X4000 S355J2+N+Z25+100%UT"
		}
		,A2V00002272590 : {
			de: "Distanzstueck",
			en: "Distance piece"
		}
		,A2V00002349425 : {
			de: "Dachlangtraeger Profil",
			en: "Roof main beam profile"
		}
		,A2V00002349657 : {
			de: "Dachquerplatte Profil",
			en: "Roof cross plate profile"
		}
		,A2V00107115156 : {
			de: "Stahlrohr",
			en: "Steel tube"
		}
		,A2V00200329353 : {
			de: "Blech ISO 9445  X5CrNi18-10",
			en: "Sheet ISO 9445  X5CrNi18-10"
		}
		,A2V00207353918 : {
			de: "Schlauchleitung",
			en: "flexible cord"
		}
		,A2V00001049528 : {
			de: "Magnetschienenbremse EMS 60",
			en: "Electromagnetic rail brake EMS 60"
		}
		,A2V00000315700 : {
			de: "ZUNGE",
			en: "point blade"
		}
		,A2V00001880306 : {
			de: "GMT-Schichtfeder Z.62035903  St/Gummi",
			en: "GMT-layer spring Z.62035903  st/rubber"
		}
		,A2V00001962350 : {
			de: "Impulsgeber HS22 mit Kabel 58cm",
			en: "transmitter HS22 with cable 58cm"
		}
		,A2V00001306378 : {
			de: "Leiterplatte DIO",
			en: "printed ciruit board (PCB);"
		}
		,A2V00370062512 : {
			de: "SCHLAUCHLEITUNG 2TE 16 N45N90 520 P 0",
			en: "HOSE ASSEMBLY 2TE 16 NN45 500 P"
		}
		,A2V00370083124 : {
			de: "SCHLAUCHLEITUNG 2TE 12 NN90 420  P",
			en: "HOSE ASSEMBLY 2TE 12 NN 400 P"
		}
		,A2V00001097016 : {
			de: "Bremsscheibe",
			en: "Brake disc"
		}
		,A2V00200400021 : {
			de: "Sechskantschraube",
			en: "hexagon head screw"
		}
		,A2V00001923860 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001924221 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00080139296 : {
			de: "Nutmutter DIN 981-M40x1,5-11H-Fe/Znphr5",
			en: "Slotted nutDIN 981-M40x1,5-11H-Fe/Znphr5"
		}
		,A2V00370062502 : {
			de: "SCHLAUCHLEITUNG 2TE 16 NN90 500 P",
			en: "HOSE ASSEMBLY 2TE 16 NN90 500 P"
		}
		,A2V00001857879 : {
			de: "Schichtfeder",
			en: "layer spring"
		}
		,A2V00001447796 : {
			de: "Distanzscheibe",
			en: "Distance washer"
		}
		,A2V00002354287 : {
			de: "Daempferprofil",
			en: "Bufferprofile"
		}
		,A2V00002354555 : {
			de: "Federtopf, vollst.",
			en: "Spring pot, compl."
		}
		,A2V00002272944 : {
			de: "Abdeckung komplett",
			en: "Cover compl."
		}
		,A2V00002355837 : {
			de: "Halfen-Montageschiene",
			en: "Halfen-mounting rail"
		}
		,A2V00002356336 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002360374 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00159506954 : {
			de: "BEILAGE",
			en: "BEILAGE"
		}
		,A2V00002360818 : {
			de: "Seitenwandfeld Profil",
			en: "Side wall plate profile"
		}
		,A2V00370083163 : {
			de: "Buchse fuer Wankstabilisiator",
			en: "Bush for roll bar stabiliser"
		}
		,A2V00112503832 : {
			de: "Druckkegel fuer   Motorlagerung",
			en: "pressure cone for engine mounting"
		}
		,A2V00370031811 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002360820 : {
			de: "Seitenwandfeld Profil",
			en: "Side wall plate profile"
		}
		,A2V00002065083 : {
			de: "Bremskonsole, lackiert",
			en: "Break bracket, painted"
		}
		,A2V00002024757 : {
			de: "Montagematerial RSL-Deckel",
			en: "mounting material ws back cover"
		}
		,A2V00002102108 : {
			de: "Traeger f. Sandung sp.g. lackiert",
			en: "Support f. sander o.a.d. paited"
		}
		,A2V00002382002 : {
			de: "Fahrmotorluefter V3b",
			en: "Traction motor blower v3"
		}
		,A2V00002360886 : {
			de: "Seitenwandsaeule Profil",
			en: "Side wall pillar profile"
		}
		,A2V00002360892 : {
			de: "Tuersaeule Profil",
			en: "Door pillar profile"
		}
		,A2V00370060853 : {
			de: "Daempfer primaer vertikal",
			en: "primary vertical damper"
		}
		,A2V00370026383 : {
			de: "FL DIN1017-20x8-S235JRG2",
			en: "FL DIN1017-20x8-S235JRG2"
		}
		,A2V00002275416 : {
			de: "Klappfenster, NEA",
			en: "Hinged window, emergency exit"
		}
		,A2V00002360895 : {
			de: "Ecksaeule Profil",
			en: "Corner pillar profile"
		}
		,A2V00002118810 : {
			de: "Motorbefestigungsschraube unten",
			en: "Hexagon bolt bottom"
		}
		,A2V00002701528 : {
			de: "Matte",
			en: "Mat"
		}
		,A2V00001127679 : {
			de: "Stromversorgung 72V-110V/+-15V, 60W",
			en: "Converter 72V-110V/+-15V, 60W"
		}
		,A2V00397167681 : {
			de: "Verbindungslache f. Radsatzlenkung",
			en: "Connecting strap f. wheelset guiding"
		}
		,A2V00156028466 : {
			de: "Leitung RADOX GKW-LW 600V MM S 2x2,5",
			en: "Cable RADOX GKW-LW 600V MM S 2x2,5"
		}
		,A2V00001096095 : {
			de: "Eingangsstufe Temperatur PT100",
			en: "Input Temperature Sensor PT100"
		}
		,A2V00001244066 : {
			de: "Kuehlelement Alu  1,3kW 460V",
			en: "Heat exchanger and fan"
		}
		,A2V00001073606 : {
			de: "Ausgangsstufe Schuetz binaer 110V",
			en: "Binary Output Contactor Drive 110V"
		}
		,A2V00397233023 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002276672 : {
			de: "Rohr",
			en: "Pipe"
		}
		,A2V00002366385 : {
			de: "Nutenstein",
			en: "Sliding block"
		}
		,A2V00002277433 : {
			de: "Seitenfenster gross",
			en: "Side window big"
		}
		,A2V00001663608 : {
			de: "Digitalgeber            .",
			en: "digital transducer"
		}
		,A2V00001289044 : {
			de: "Stromversorgung 72V-110V/5V, 60W",
			en: "Converter 72V-110V/5V, 60W"
		}
		,A2V00001660654 : {
			de: "Steckerblech",
			en: "bracket for plug"
		}
		,A2V00001191337 : {
			de: "Adapter Latch TYP 12",
			en: "Adapter latch TYP 12"
		}
		,A2V00370036272 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00397250988 : {
			de: "Passfeder",
			en: "Fit-in key"
		}
		,A2V00370036727 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370033013 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002372141 : {
			de: "Frontkupplung 4-Teiler",
			en: "Front coupler 4-car"
		}
		,A2V00002372142 : {
			de: "Kurzkupplungshaelfte A",
			en: "Short coupler half A"
		}
		,A2V00002372143 : {
			de: "Kurzkupplungshaelfte B",
			en: "Short coupler half B"
		}
		,A2V00002765338 : {
			de: "Blech EN 10051 2,5X1330X6000 S355J2W+N",
			en: "Sheet EN 10051 2,5X1330X6000 S355J2W+N"
		}
		,A2V00002765337 : {
			de: "Blech EN 10051 2X1170X4000 S355J2W+N",
			en: "Sheet EN 10051 2X1170X4000 S355J2W+N"
		}
		,A2V00002765323 : {
			de: "Blech EN 10131 1,5X1500X4000 HC380LA",
			en: "Sheet EN 10131 1,5X1500X4000 HC380LA"
		}
		,A2V00002765321 : {
			de: "Blech EN 10131 1,2X1500X7000 HC380LA",
			en: "Sheet EN 10131 1,2X1500X7000 HC380LA"
		}
		,A2V00002372144 : {
			de: "Kurzkupplungshaelfte C",
			en: "Short coupler half C"
		}
		,A2V00002372145 : {
			de: "Kurzkupplungshaelfte D",
			en: "Short coupler half D"
		}
		,A2V00002375140 : {
			de: "Stirnwandprofil",
			en: "End wall profile"
		}
		,A2V00002280224 : {
			de: "Tuerfluegel links",
			en: "Door panel left"
		}
		,A2V00002280225 : {
			de: "Tuerfluegel rechts",
			en: "Door panel right"
		}
		,A2V00002280244 : {
			de: "Antrieb, vollst. (mit Master-DCU)",
			en: "Drive, compl. (with Master-DCU)"
		}
		,A2V00002280276 : {
			de: "Antrieb, vollst. (mit Slave-DCU)",
			en: "Drive, compl. (with Slave-DCU)"
		}
		,A2V00002280436 : {
			de: "Tuerfluegel rechts KiWa",
			en: "Door panel right BC"
		}
		,A2V00002281003 : {
			de: "Rohr",
			en: "Pipe"
		}
		,A2V00002385990 : {
			de: "Dachbekleidung Profil",
			en: "Roof ceiling profile"
		}
		,A2V00002303658 : {
			de: "Fuehrerpult vst.",
			en: "Driver's desk cpl."
		}
		,A2V00002389236 : {
			de: "Kabelkanal UF 01 geschw.",
			en: "Cable duct UF 01 welded"
		}
		,A2V00002389237 : {
			de: "Kabelkanal UF 08 geschw.",
			en: "Cable duct UF 08 welded"
		}
		,A2V00002304502 : {
			de: "KK-Ende vollst.",
			en: "short coupler end complete"
		}
		,A2V00002389238 : {
			de: "Kabelkanal UF 05 geschw.",
			en: "Cable duct UF 05 welded"
		}
		,A2V00002389259 : {
			de: "Kabelkanal UF 03 geschw.",
			en: "Cable duct UF 03 welded"
		}
		,A2V00002389260 : {
			de: "Kabelkanal UF 04 geschw.",
			en: "Cable duct UF 04 welded"
		}
		,A2V00002389261 : {
			de: "Kabelkanal UF 02 geschw.",
			en: "Cable duct UF 02 welded"
		}
		,A2V00002389262 : {
			de: "Kabelkanal UF 07 geschw.",
			en: "Cable duct UF 07 welded"
		}
		,A2V00002389263 : {
			de: "Kabelkanal UF 06 geschw.",
			en: "Cable duct UF 06 welded"
		}
		,A2V00002389326 : {
			de: "Konsole vollst.",
			en: "Console compl."
		}
		,A2V00002389371 : {
			de: "Trittleiste vollst.",
			en: "Step strip compl."
		}
		,A2V00002389410 : {
			de: "Abschluss Langtraeger geschw. sp.g.",
			en: "Longitudinal closing beam welded i.m.i."
		}
		,A2V00002389411 : {
			de: "Abschluss Langtraeger geschw. w.g.",
			en: "Longitudinal closing beam welded a.d."
		}
		,A2V00002390983 : {
			de: "Frontscheibe REB",
			en: "Windscreen REB"
		}
		,A2V00002307668 : {
			de: "Schiebetritt, vollst., Kabelabg. nach li",
			en: "Sliding step, compl. cable out. to the l"
		}
		,A2V00002307669 : {
			de: "Schiebetritt, vollst.",
			en: "Sliding step, compl."
		}
		,A2V00002308160 : {
			de: "Scharfenbergkupplung",
			en: "Scharfenberg coupler"
		}
		,A2V00002308616 : {
			de: "Trafolager vst.",
			en: "Trafo bearing cpl."
		}
		,A2V00002308975 : {
			de: "Fuehrungsschiene         oben, rechts",
			en: "Upper guide assembly     right"
		}
		,A2V00002309110 : {
			de: "Fuehrungsarm",
			en: "Drive arm assembly"
		}
		,A2V00002309112 : {
			de: "Stabilisator",
			en: "Stabilizer"
		}
		,A2V00002232263 : {
			de: "Halter 2 Eurobalise",
			en: "bracket 2 eurobalise"
		}
		,A2V00002232057 : {
			de: "Halter 1 Eurobalise",
			en: "bracket 1 eurobalise"
		}
		,A2V00370003860 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002309116 : {
			de: "Tuerfluegel",
			en: "Door leaf assemb. endside"
		}
		,A2V00002309118 : {
			de: "Stabilisator",
			en: "Stabilizer"
		}
		,A2V00002309119 : {
			de: "Freizugeinrichtung",
			en: "Delayed release device"
		}
		,A2V00002309124 : {
			de: "Schalttafel innen",
			en: "Internal control panel"
		}
		,A2V00002309132 : {
			de: "Schalttafel innen",
			en: "Internal control panel"
		}
		,A2V00002309146 : {
			de: "Stabilisator",
			en: "Stabilizer"
		}
		,A2V00002309148 : {
			de: "Tuerfluegel",
			en: "Door leaf assemb. endside"
		}
		,A2V00002309270 : {
			de: "C-Schiene, vollst.",
			en: "C-rail, compl."
		}
		,A2V00002309447 : {
			de: "Adapter",
			en: "adapter"
		}
		,A2V00002309448 : {
			de: "Adapter",
			en: "adapter"
		}
		,A2V00002309449 : {
			de: "Adapter",
			en: "adapter"
		}
		,A2V00002309450 : {
			de: "Adapter",
			en: "adapter"
		}
		,A2V00002309451 : {
			de: "Adapter",
			en: "adapter"
		}
		,A2V00100001040 : {
			de: "SCHWEISSSTAB",
			en: "SCHWEISSSTAB"
		}
		,A2V00001394125 : {
			de: "Druckwaechter",
			en: "Pressure control device"
		}
		,A2V00001824612 : {
			de: "Laufradsatz vollst.",
			en: "Trailer wheelset compl."
		}
		,A2V00002051873 : {
			de: "Drehdaempfer",
			en: "yaw damper"
		}
		,A2V00002159799 : {
			de: "Matte",
			en: "mat"
		}
		,A2V00002159949 : {
			de: "Klemmteil",
			en: "clamping"
		}
		,A2V00001225119 : {
			de: "Stromversorgung 72V-110V/+-15V, 100W",
			en: "Converter 72V-110V/+-15V, 100W"
		}
		,A2V00002124281 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370071414 : {
			de: "NH-Sicherungseinsatz 3NA3132",
			en: "NH-fuse link 3NA3132"
		}
		,A2V00370036721 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00156019078 : {
			de: "Uebertrager",
			en: "transducer"
		}
		,A2V00156026371 : {
			de: "ZS-MEL-02",
			en: "ZS-MEL-02"
		}
		,A2V00201470678 : {
			de: "Sandtreppe KM 1 Sp",
			en: "Sanding step KM 1 Sp"
		}
		,A2V00001741935 : {
			de: "Konsole w.g. lackiert",
			en: "Console a.d. painted"
		}
		,A2V00001742020 : {
			de: "Konsole sp.g. lackiert",
			en: "Console o.a.d. painted"
		}
		,A2V00001087077 : {
			de: "Eingangsstufe potenzialfrei binaer 110V",
			en: "Input Converter Binary 110V"
		}
		,A2V00002247852 : {
			de: "Wegimpulsgeber ATBL, LZB",
			en: "Speed sensor for ATBL, LZB"
		}
		,A2V00002206011 : {
			de: "Bodenblech",
			en: "floor plate"
		}
		,A2V00002206579 : {
			de: "Schraubblech",
			en: "screwed plate"
		}
		,A2V00002379535 : {
			de: "Beilage Verteilerkasten",
			en: "Shim connection box"
		}
		,A2V00002781151 : {
			de: "Winkel",
			en: "Bracket"
		}
		,A2V00102162822 : {
			de: "Sechskantschraube",
			en: "Sechskantschraube"
		}
		,A2V00002781155 : {
			de: "Adapterblech",
			en: "Adapter plate"
		}
		,A2V00370003363 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002310727 : {
			de: "Blech F-Ende",
			en: "sheet front end"
		}
		,A2V00001068409 : {
			de: "Ausgangsstufe SchÃ¼tz binÃ¤r 110V",
			en: "binary output contactor drive"
		}
		,A2V00001095436 : {
			de: "Ausgangsstufe Schuetz binaer 110V",
			en: "Binary Output Contactor Drive 110V"
		}
		,A2V00201100295 : {
			de: "Sandtreppe KM-1Sp",
			en: "Sand staircase KM 1Sp"
		}
		,A2V00397192445 : {
			de: "SCHRAUBENFEDERSATZ R=309 N/MM",
			en: "HELICAL SPRING SET R=309 N/MM"
		}
		,A2V00002310952 : {
			de: "Gleitschutzventil N8.1 ICx Triebwagen",
			en: "anti skid valve N8.1 ICx motor car"
		}
		,A2V00002398088 : {
			de: "Stufenrohr bearbeitet",
			en: "Step pipe, machined"
		}
		,A2V00002311193 : {
			de: "Gleitschutzventil N8.1 ICx Laufwagen",
			en: "anti skid valve N8.1 ICx tailer car"
		}
		,A2V00001091376 : {
			de: "Zwischenflansch",
			en: "Intermediate flange"
		}
		,A2V00112508910 : {
			de: "GELENKWELLE",
			en: "hinge wave"
		}
		,A2V00397216950 : {
			de: "Notfang lackiert",
			en: "Emergency holder painted"
		}
		,A2V00001814977 : {
			de: "Autom. Kupplung O-1 / O-2 / O-4 / O-5",
			en: "Automatic coupler O-1 / O-2 / O-4 / O-5"
		}
		,A2V00001747816 : {
			de: "Rillenkugellager DIN625 6016-85/105/150Â°",
			en: "Grooved ball bearing  DIN625 6016-85/105"
		}
		,A2V00002761811 : {
			de: "Bus-Stromabnehmer 405A",
			en: "Bus-Pantograph 405A"
		}
		,A2V00002732668 : {
			de: "Gleitschutzeinheit N8.1 BLE",
			en: "Protection unit slip N8.1 BLE"
		}
		,A2V00001345309 : {
			de: "Klappe,geschweisst",
			en: "Cover,welded"
		}
		,A2V00002094774 : {
			de: "Belagtreagersatz BISO400TSX19",
			en: "Pad Carrier Set BISO400TSX19"
		}
		,A2V00002576520 : {
			de: "LED Wagenuebergangsbeleuchtung",
			en: "LED car pass lighting"
		}
		,A2V00002778731 : {
			de: "Druckluftbehaelter",
			en: "Water tank"
		}
		,A2V00370060725 : {
			de: "Daempfer sekundaer horizontal",
			en: "secondary horizontal damper"
		}
		,A2V00370060723 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical damper"
		}
		,A2V00397200439 : {
			de: "Federauflage",
			en: "Spring console"
		}
		,A2V00001346232 : {
			de: "Signalprozessor SHARC",
			en: "Signal Processing Unit SHARC"
		}
		,A2V00113149471 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002398259 : {
			de: "Anschlussklotz",
			en: "Current conection"
		}
		,A2V00001942447 : {
			de: "Schutzwinkel",
			en: "Protection Angle"
		}
		,A2V00207356554 : {
			de: "Stutzen G1/4 - M16x1,5 Form N",
			en: "Nipple G1/4 - M16x1,5 form N"
		}
		,A2V00397160679 : {
			de: "Beilagscheibe 8mm lackiert",
			en: "Primary suspension shim 8mm painted"
		}
		,A2V00397160678 : {
			de: "Beilagscheibe 4mm lackiert",
			en: "Primary suspension shim 4mm painted"
		}
		,A2V00370059789 : {
			de: "LUFTFEDERVENTIL",
			en: "air spring valve"
		}
		,A2V00002440934 : {
			de: "EP2002 Smart Ventil",
			en: "EP2002 Smart Valve"
		}
		,A2V00001947157 : {
			de: "Halter Erdungskontakt",
			en: "bracket earth return bru"
		}
		,A2V00370057631 : {
			de: "RADREIFEN LK-600",
			en: "wheel tyre"
		}
		,A2V00397226522 : {
			de: "Sandrohrhalter, rechts lackiert",
			en: "sand pipe holder right varnished"
		}
		,A2V00397226521 : {
			de: "Sandrohrhalter, links lackiert",
			en: "sand pipe holder left varnished"
		}
		,A2V00397163955 : {
			de: "Puffer",
			en: "buffer"
		}
		,A2V00397176083 : {
			de: "SCHEIBE FUER LUFTFEDER",
			en: "DISK FOR AIR SPRING"
		}
		,A2V00001953357 : {
			de: "LZB Winkel",
			en: "lzb angle"
		}
		,A2V00002230450 : {
			de: "Drehzahlgeber mit Kabel",
			en: "Speed sensor with cable"
		}
		,A2V00002037656 : {
			de: "Schutzblech S3",
			en: "protection plate s3"
		}
		,A2V00002312365 : {
			de: "Klimaanlage FGR",
			en: "HVAC unit passenger room"
		}
		,A2V00002312398 : {
			de: "Kurzkupplungshaelfte     mit Crashelemen",
			en: "Semipermanent coupler    with crash elem"
		}
		,A2V00002398385 : {
			de: "Stuetzblech",
			en: "Support"
		}
		,A2V00399100836 : {
			de: "Gelenkwelle",
			en: "Joint shaft"
		}
		,A2V00370075233 : {
			de: "Daempfer sekundaer horizontal",
			en: "secondary horizontal damper"
		}
		,A2V00370067682 : {
			de: "Knochenbuchse",
			en: "spheribloc"
		}
		,A2V00370067681 : {
			de: "Hohlbuchse",
			en: "cylindrical bush"
		}
		,A2V00370065346 : {
			de: "Puffer",
			en: "Buffer"
		}
		,A2V00370057384 : {
			de: "Schichtfeder",
			en: "layer spring"
		}
		,A2V00002398410 : {
			de: "Lagerbock T1",
			en: "Bearing support T1"
		}
		,A2V00002398415 : {
			de: "Nabe",
			en: "Hub"
		}
		,A2V00002398417 : {
			de: "Lagerklotz",
			en: "Bearing support"
		}
		,A2V00002312512 : {
			de: "Winkel",
			en: "Angle"
		}
		,A2V00002398445 : {
			de: "Gabel Ausf.2",
			en: "Fork Vers.2"
		}
		,A2V00002398448 : {
			de: "Anschlusslasche",
			en: "Terminal link"
		}
		,A2V00002398484 : {
			de: "DistanzhÃ¼lse",
			en: "Spacer sleeve"
		}
		,A2V00002398510 : {
			de: "Stellmutter",
			en: "Adjusting nut"
		}
		,A2V00001971216 : {
			de: "SIBAC BB SP-3000WL2",
			en: "SIBAC BB SP-3000WL2 phase moduel"
		}
		,A2V00370035762 : {
			de: "Faltenbalg",
			en: "gaiter"
		}
		,A2V00207356854 : {
			de: "3/2-Magnetventil DC 24 V",
			en: "3/2 magnet valve DC 24 V"
		}
		,A2V00397242454 : {
			de: "Schraubendruckfedersatz",
			en: "Helical compression spring set"
		}
		,A2V00001677196 : {
			de: "Radbremsscheibe",
			en: "Wheel mounted brake disc"
		}
		,A2V00001750767 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002398518 : {
			de: "Lagerwelle LR, Basis Ausf.3",
			en: "Bearing shaft LR, base vers.3"
		}
		,A2V00002398530 : {
			de: "Gewindestange",
			en: "Thread rod"
		}
		,A2V00001857581 : {
			de: "OLED-Reservierungs-Anzeige",
			en: "OLED-Reservation display"
		}
		,A2V00002398543 : {
			de: "Blattfeder",
			en: "Leaf Spring"
		}
		,A2V00002398546 : {
			de: "Gewindestange",
			en: "Thread rod"
		}
		,A2V00002398560 : {
			de: "Rohr fuer Unterarm",
			en: "Pipe for lower arm"
		}
		,A2V00001421041 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002313131 : {
			de: "Schiebetuer",
			en: "Sliding door"
		}
		,A2V00002313132 : {
			de: "Schiebetuer",
			en: "Sliding door"
		}
		,A2V00002398704 : {
			de: "Lenker Ausf.1 kompl. SBS",
			en: "Guide vers.1 compl. SBS"
		}
		,A2V00002037216 : {
			de: "Daempfer primaer vertikal",
			en: "Primary vertical shock absorber"
		}
		,A2V00001093449 : {
			de: "Zunge",
			en: "guide"
		}
		,A2V00002313347 : {
			de: "Fensterstiel",
			en: "window web profile"
		}
		,A2V00002313348 : {
			de: "Fensterstiel",
			en: "window web profile"
		}
		,A2V00002313349 : {
			de: "Fensterstiel",
			en: "window web profile"
		}
		,A2V00001815797 : {
			de: "SHP-Elektromagnet Zugsicherung",
			en: "SHP-electric magnet f. aut. train prot."
		}
		,A2V00001844214 : {
			de: "SHP-Baugruppe Zugsicherung 24V",
			en: "SHP-unit automatic train protection 24V"
		}
		,A2V00002426716 : {
			de: "WegimpulsgeberÂ optisch 5.8604.015/04",
			en: "Speed sensor optical 5.8604.015/04"
		}
		,A2V00002538570 : {
			de: "Beschleunigungssensor 363M02A",
			en: "Accelerometer 363M02A"
		}
		,A2V00002314006 : {
			de: "Halter vollst. geschweisst",
			en: "holder compl. welded"
		}
		,A2V00002314056 : {
			de: "Halter vollst. geschweisst",
			en: "holder compl. welded"
		}
		,A2V00002314147 : {
			de: "Halter vollst. geschweisst",
			en: "holder compl. welded"
		}
		,A2V00002399109 : {
			de: "Verbindungsrohr",
			en: "Connecting pipe"
		}
		,A2V00002399110 : {
			de: "Lagerlasche",
			en: "Bearing strap"
		}
		,A2V00002399126 : {
			de: "Aufstellwinkel H=20",
			en: "Support bracket H=20"
		}
		,A2V00002399170 : {
			de: "Stuetzlasche",
			en: "Support plate"
		}
		,A2V00002399192 : {
			de: "Oberarm SchweiÃŸteil",
			en: "Upper arm welding part"
		}
		,A2V00002399197 : {
			de: "Wange",
			en: "Cheek"
		}
		,A2V00002399199 : {
			de: "Knoten",
			en: "Gusset plate"
		}
		,A2V00002315277 : {
			de: "Blech w.g.",
			en: "plate a.d."
		}
		,A2V00002315285 : {
			de: "F-Ende vollst",
			en: "front end complete"
		}
		,A2V00002315368 : {
			de: "Blech sp.g.",
			en: "plate r.i."
		}
		,A2V00002315719 : {
			de: "Halter vollst.",
			en: "holder compl."
		}
		,A2V00002399366 : {
			de: "Horn komplett links 2-918.3180",
			en: "Horn complete left 2-918.3180"
		}
		,A2V00002399370 : {
			de: "Stuetzblech",
			en: "Support plate"
		}
		,A2V00002399398 : {
			de: "Oberarm H Alu SWT ohne Lasche",
			en: "Upper arm H Alu WP without fishplate"
		}
		,A2V00002315885 : {
			de: "Kurzkupplungshaelfte",
			en: "Semipermanent coupler"
		}
		,A2V00002315916 : {
			de: "Schalenmuffenverbindung",
			en: "Muff coupling"
		}
		,A2V00002316081 : {
			de: "Blech sp.g.",
			en: "plate r.i."
		}
		,A2V00002316202 : {
			de: "Blech w.g.",
			en: "plate as d."
		}
		,A2V00002399475 : {
			de: "Knoten",
			en: "Gusset plate"
		}
		,A2V00002316377 : {
			de: "Futterblech",
			en: "filler"
		}
		,A2V00002316378 : {
			de: "Halter, vollst.",
			en: "Holder, compl."
		}
		,A2V00002399492 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002399493 : {
			de: "Knotenblech",
			en: "Junction plate"
		}
		,A2V00002399494 : {
			de: "Lagerlasche",
			en: "Bearing strap"
		}
		,A2V00002399495 : {
			de: "Anschlusslasche T1",
			en: "Terminal link T1"
		}
		,A2V00002399496 : {
			de: "Anschlusslasche T2",
			en: "Terminal link T2"
		}
		,A2V00002399499 : {
			de: "Lagerlaschen T2",
			en: "Bearing link T2"
		}
		,A2V00002399511 : {
			de: "Tragkonsole  T1",
			en: "Support bracketT1"
		}
		,A2V00002399512 : {
			de: "Seilbefestigung",
			en: "Rope fixation"
		}
		,A2V00002399531 : {
			de: "Lagerlasche",
			en: "Bearing strap"
		}
		,A2V00002316548 : {
			de: "Futterblech",
			en: "Filler"
		}
		,A2V00002399536 : {
			de: "Stuetze T3",
			en: "Support P3"
		}
		,A2V00002399538 : {
			de: "Traglasche",
			en: "supporting link"
		}
		,A2V00002399540 : {
			de: "Tragkonsole  T2",
			en: "Support bracket T2"
		}
		,A2V00002399550 : {
			de: "Anschlusslasche",
			en: "Current connection plate"
		}
		,A2V00002316678 : {
			de: "Haupttransformator",
			en: "Main transformer"
		}
		,A2V00002399551 : {
			de: "Anschlusslasche",
			en: "Current connection plate"
		}
		,A2V00002399552 : {
			de: "Anbaulasche",
			en: "Mounting link"
		}
		,A2V00002399554 : {
			de: "Hebelasche",
			en: "Lifting plate"
		}
		,A2V00002399564 : {
			de: "Schaltnocke 1",
			en: "Actuating cam 1"
		}
		,A2V00002399565 : {
			de: "Schaltnocke 2",
			en: "Actuating cam 2"
		}
		,A2V00002399566 : {
			de: "Stuetzlasche",
			en: "Support plate"
		}
		,A2V00002316897 : {
			de: "Einleger",
			en: "fixing plate"
		}
		,A2V00002399584 : {
			de: "Horn komplett rechts 2-918.3182",
			en: "Horn complete right 2-918.3182"
		}
		,A2V00002317017 : {
			de: "Entkopplungsrahmen       fuer Hauptschal",
			en: "Frame for LCB"
		}
		,A2V00002441104 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002317329 : {
			de: "Handlauf vollst.",
			en: "Handrail compl."
		}
		,A2V00002399639 : {
			de: "Balgplatte",
			en: "Bellow plate"
		}
		,A2V00002317345 : {
			de: "Bremssteuermodul EC01",
			en: "brake control module EC01"
		}
		,A2V00002317462 : {
			de: "Handlauf vollst.",
			en: "Handrail compl."
		}
		,A2V00002399656 : {
			de: "Lagerkopf m. Rechtsgewinde",
			en: "Bearing head with right-hand thread"
		}
		,A2V00002317466 : {
			de: "Bremssteuermodul TC02",
			en: "brake control module TC02"
		}
		,A2V00002399657 : {
			de: "Lagerkopf m. Linksgewinde",
			en: "Bearing head with left-hand thread"
		}
		,A2V00002317467 : {
			de: "Bremssteuermodul CC03",
			en: "brake control module CC03"
		}
		,A2V00002317468 : {
			de: "Bremssteuermodul MC04",
			en: "brake control module MC04"
		}
		,A2V00002317469 : {
			de: "Bremssteuermodul EC08",
			en: "brake control module EC08"
		}
		,A2V00002317470 : {
			de: "Bremssteuermodul TC07",
			en: "brake control module TC07"
		}
		,A2V00002317471 : {
			de: "Bremssteuermodul CC06",
			en: "brake control module CC06"
		}
		,A2V00002317472 : {
			de: "Bremssteuermodul MC05",
			en: "brake control module MC05"
		}
		,A2V00002399674 : {
			de: "Oberarm Alu H B790 SWT",
			en: "Upper arm Alu H W790 wp"
		}
		,A2V00002317596 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317597 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002399733 : {
			de: "Scheitelrohr",
			en: "Appex tupe"
		}
		,A2V00002317598 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317600 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317603 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317612 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317884 : {
			de: "Uebergangsprofil  w.g.",
			en: "Cover strip profile as d."
		}
		,A2V00002317885 : {
			de: "Uebergangsprofil  sp.g.",
			en: "Cover strip profile r.i."
		}
		,A2V00002317987 : {
			de: "Uebergangsleiste",
			en: "Cover strip profile"
		}
		,A2V00002399810 : {
			de: "Ablaufhorn kpl. rechts 2-3718.8735",
			en: "Ablaufhorn kpl. rechts 2-3718.8735"
		}
		,A2V00002399837 : {
			de: "Rotationsfederwippe 1950/AC - HGV",
			en: "Collector head 1950/AC - HS"
		}
		,A2V00002399853 : {
			de: "Laengsholm T2",
			en: "Longitudinal beam T2"
		}
		,A2V00002399864 : {
			de: "Verbindungswelle 3-918.144",
			en: "Connection Shaft 3-918.144"
		}
		,A2V00002399895 : {
			de: "Membranschnellsenkventil 3/4 - Zoll",
			en: "Diaphragm valve 3/4 - Zoll"
		}
		,A2V00002399898 : {
			de: "Parallelfuehrungsstange HGV/AC kompl.",
			en: "Collector head guide HGV / AC compl."
		}
		,A2V00002318407 : {
			de: "Uebergangsprofil  w.g.",
			en: "Cover strip profile as. d."
		}
		,A2V00002318408 : {
			de: "Uebergangsprofil  sp.g.",
			en: "Cover strip profile r.i."
		}
		,A2V00002399910 : {
			de: "Rohrbogen",
			en: "Pipe bend"
		}
		,A2V00002318759 : {
			de: "C-Schiene, vollst.",
			en: "C-rail, compl."
		}
		,A2V00002399941 : {
			de: "KohleschleifstÃ¼ck      2-10671",
			en: "KohleschleifstÃ¼ck      2-10671"
		}
		,A2V00002399945 : {
			de: "Ventilblock T1",
			en: "Valve block p1"
		}
		,A2V00002319053 : {
			de: "Seitenwand, Dachhaube 2",
			en: "side wall, roof box 2"
		}
		,A2V00002319054 : {
			de: "Seitenwand, Dachhaube 1",
			en: "side wall, roof box 1"
		}
		,A2V00002319055 : {
			de: "Deckel, Dachhaube 2",
			en: "cover, roof box 2"
		}
		,A2V00002319103 : {
			de: "Deckel, Dachhaube 1",
			en: "cover, roof box 1"
		}
		,A2V00002400002 : {
			de: "ParallelfÃ¼hrung Wippe",
			en: "collector head guide"
		}
		,A2V00002400003 : {
			de: "Parallelfuehrungsstange komplett",
			en: "Collector head guide complete"
		}
		,A2V00002400021 : {
			de: "Wippenkasten links",
			en: "Collector head box left"
		}
		,A2V00002400022 : {
			de: "Wippenkasten rechts",
			en: "Colllector head box right"
		}
		,A2V00002400023 : {
			de: "Ventileinheit AS",
			en: "Valve unit ADD"
		}
		,A2V00002400081 : {
			de: "Balgantrieb kpl.",
			en: "Lifting device cpl."
		}
		,A2V00002400098 : {
			de: "Wippe DC FS kompl. 1-W08.13469",
			en: "Collector head DC FS compl. 1-W08.13469"
		}
		,A2V00002400135 : {
			de: "Versteifungsrippe",
			en: "Stiffening rib"
		}
		,A2V00002400136 : {
			de: "Versteifungsrippe",
			en: "Stiffening rib"
		}
		,A2V00002400157 : {
			de: "Schwinghebel SWT RAL9006",
			en: "Rocker arm WP RAL9006"
		}
		,A2V00002400230 : {
			de: "Lagerbolzen T3",
			en: "Bearing bolt p3"
		}
		,A2V00002400263 : {
			de: "Gewindemuffe G1/4",
			en: "Screw socket G1/4"
		}
		,A2V00002400304 : {
			de: "Wippenkasten f. Wippe 1-1408.11759",
			en: "C-head box f. C-head 1-1408.11759"
		}
		,A2V00002400436 : {
			de: "Stufenrohr",
			en: "Step pipe"
		}
		,A2V00002400437 : {
			de: "Querversteifung",
			en: "Cross bracing"
		}
		,A2V00002400499 : {
			de: "Torsionsstab T1 10mm",
			en: "Torsion bar P1 10mm"
		}
		,A2V00002400530 : {
			de: "Flachstahl f. Lasche",
			en: "Flat sheet f. strap"
		}
		,A2V00002400532 : {
			de: "Fuehrungsstange T1",
			en: "Guiding rod p1"
		}
		,A2V00002400533 : {
			de: "Fuehrungsstange T2",
			en: "Guiding rod p2"
		}
		,A2V00002400534 : {
			de: "Distanzhuelse",
			en: "Spacer"
		}
		,A2V00002400638 : {
			de: "Wippenkasten m. AS vormontiert",
			en: "Rocker box w. ADD pre-assembled"
		}
		,A2V00002400642 : {
			de: "Wippenkasten 2-W08.13019",
			en: "Rocker box 2-W08.13019"
		}
		,A2V00002400656 : {
			de: "Wippe 1950/AC",
			en: "Collector head 1950/AC"
		}
		,A2V00105734681 : {
			de: "Megi-Anschlagpuffer",
			en: "Megi-buffer"
		}
		,A2V00114221941 : {
			de: "GFK-KOPF (WGT. 1)                      .",
			en: "glass fibre head (WGT 1);"
		}
		,A2V00002400692 : {
			de: "Wippe 1950/DC 1-12108.17003",
			en: "Collector head 1950/DC 1-12108.17003"
		}
		,A2V00002400704 : {
			de: "Grundrahmen A4 SWT RAL9006",
			en: "Base frame vers.4 wp RAL9006"
		}
		,A2V00002400710 : {
			de: "Lagerbock T2",
			en: "Bearing support T2"
		}
		,A2V00002400711 : {
			de: "Lagerbock T1",
			en: "Bearing support T1"
		}
		,A2V00002400773 : {
			de: "Unterarm, SSS/A2/RAL3000",
			en: "Lower arm, SSS/A2/RAL3000"
		}
		,A2V00082044983 : {
			de: "Radarsensor DRS05/1S1a mit SW51-031M",
			en: "Radar sensor DRS05/1S1a with SW51-031M"
		}
		,A2V00002320590 : {
			de: "Schrankadapter",
			en: "Cupboard Adapter"
		}
		,A2V00002320638 : {
			de: "Fensterstiel",
			en: "window web profile"
		}
		,A2V00002320640 : {
			de: "Halter vollst.",
			en: "Holder compl."
		}
		,A2V00001009412 : {
			de: "Zentralrechner",
			en: "Central Processing Unit"
		}
		,A2V00002410299 : {
			de: "Schweissprobe 1Steg 55Grad",
			en: "Weld test part 1bar 55degree"
		}
		,A2V00002410300 : {
			de: "Blechteil fuer AP",
			en: "Sheet piece for WS"
		}
		,A2V00002419408 : {
			de: "Schleifstueck SK 1810 SK85W Integral",
			en: "Carbon strip SK 1810 SK85W integral"
		}
		,A2V00002322634 : {
			de: "Abdeckblech",
			en: "cover plate"
		}
		,A2V00002322635 : {
			de: "Abdeckblech w.g.",
			en: "cover plate a.d."
		}
		,A2V00002322799 : {
			de: "Tischsaeule geschweisst",
			en: "Table column welded"
		}
		,A2V00002322893 : {
			de: "Langtraeger links vollst.",
			en: "longitudinal beam left compl."
		}
		,A2V00002322980 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002322981 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002322982 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002428829 : {
			de: "Halter 4",
			en: "Bracket 4"
		}
		,A2V00002428859 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002323190 : {
			de: "Langtraeger rechts vollst.",
			en: "longitudinal beam right compl."
		}
		,A2V00002323414 : {
			de: "Langtraeger rechts vollst.",
			en: "longitudinal beam right compl."
		}
		,A2V00002323458 : {
			de: "Langtraeger links vollst.",
			en: "longitudinal beam left compl."
		}
		,A2V00002432116 : {
			de: "Gewindebolzen Ausf.2",
			en: "Threaded bolt vers.2"
		}
		,A2V00002433104 : {
			de: "Beilage",
			en: "Spacer"
		}
		,A2V00002433191 : {
			de: "Beilage",
			en: "Spacer"
		}
		,A2V00002323863 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002323864 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002435389 : {
			de: "Gehaeuse geschw. sp.g.",
			en: "Case welded i.m.i."
		}
		,A2V00002435401 : {
			de: "Gehaeuse geschw. w.g.",
			en: "Case welded a.d."
		}
		,A2V00002435443 : {
			de: "Deckel geschw.",
			en: "Cover welded"
		}
		,A2V00002324203 : {
			de: "Tuergurt",
			en: "door flange"
		}
		,A2V00002324204 : {
			de: "Dachverstaerkung sp.g.",
			en: "reinforcement roof m.d."
		}
		,A2V00002324253 : {
			de: "Rahmen Hauptschalter",
			en: "frame master switch"
		}
		,A2V00002324328 : {
			de: "Dachverstaerkung w.g.",
			en: "reinforcement roof a.d."
		}
		,A2V00002438658 : {
			de: "Schuerzenklappe ZSB",
			en: "Pinafore flap ASSY"
		}
		,A2V00002324329 : {
			de: "Rahmen Trennschalter",
			en: "frame disconnector"
		}
		,A2V00002438659 : {
			de: "Schuerzenklappe ZSB sp.g.",
			en: "Pinafore flap ASSY i.m.i."
		}
		,A2V00002438660 : {
			de: "Schuerzenklappe ZSB w.g.",
			en: "Pinafore flap ASSY a.d."
		}
		,A2V00002324367 : {
			de: "Platte Panto. vollst",
			en: "plate panto. comp."
		}
		,A2V00002324368 : {
			de: "Platte OSW vollst",
			en: "plate HTT comp."
		}
		,A2V00002324381 : {
			de: "Platte Halter vollst",
			en: "plate holder comp."
		}
		,A2V00002324382 : {
			de: "Platte USA vollst",
			en: "plate insulator comp."
		}
		,A2V00002324383 : {
			de: "Platte Isolator vollst",
			en: "plate insulator comp."
		}
		,A2V00002324701 : {
			de: "Profil",
			en: "Profile"
		}
		,A2V00002324794 : {
			de: "Profil",
			en: "Profile"
		}
		,A2V00002439746 : {
			de: "Nutenstein 2xM24",
			en: "slot nut 2xM24"
		}
		,A2V00002440691 : {
			de: "Rohr 80x5x566",
			en: "Pipe 80x5x566"
		}
		,A2V00002441546 : {
			de: "Windleitblechhalter SA AC2 b",
			en: "Spoiler support SA AC2 b"
		}
		,A2V00002442717 : {
			de: "Dachrinne bearb.",
			en: "Gutter mach."
		}
		,A2V00002442721 : {
			de: "Dachrinnenende vollst. sp.g.",
			en: "Gutter end compl. i.m.i."
		}
		,A2V00002442724 : {
			de: "Dachrinnenende vollst. w.g.",
			en: "Gutter end compl. a.d."
		}
		,A2V00002442775 : {
			de: "Dachbekleidung",
			en: "Roof ceiling"
		}
		,A2V00002325955 : {
			de: "Konsole,geschw.",
			en: "Bracket,weld."
		}
		,A2V00002326008 : {
			de: "Winkel, geschw.",
			en: "Angle, welded"
		}
		,A2V00002443722 : {
			de: "Nutenstein",
			en: "Sliding block"
		}
		,A2V00002326361 : {
			de: "Winkel, geschweisst",
			en: "Angle, welded"
		}
		,A2V00002096010 : {
			de: "Impulsgeber KMG-2HS mit Wellrohr, 2m",
			en: "Pulse generator KMG-2HS w. corr. tube 2m"
		}
		,A2V00002326540 : {
			de: "Seitenblech w.g.",
			en: "sideplate as d."
		}
		,A2V00002326593 : {
			de: "Abfallbehaelter,  voll.",
			en: "waste bin, compl."
		}
		,A2V00002447662 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002448761 : {
			de: "Drehsaeule links, vollst.",
			en: "Locking column left, compl."
		}
		,A2V00002448762 : {
			de: "Drehsaeule rechts, vollst.",
			en: "Locking column right, compl."
		}
		,A2V00002449011 : {
			de: "Buegel",
			en: "Clamp"
		}
		,A2V00002450064 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002450065 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002450820 : {
			de: "Sickenblech kurz, KK-Ende",
			en: "beading sheet short, short coupler end"
		}
		,A2V00001371763 : {
			de: "Antennenkabel VV229-1",
			en: "Antenna cable VV229-1"
		}
		,A2V00002328420 : {
			de: "Abdeckung, voll.",
			en: "Cover, compl."
		}
		,A2V00002454612 : {
			de: "Distanzstueck",
			en: "Spacer block"
		}
		,A2V00002329106 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002329109 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002329110 : {
			de: "Blech sp.g.",
			en: "sheet r.i."
		}
		,A2V00002329161 : {
			de: "Blech w.g.",
			en: "sheet as d."
		}
		,A2V00002329256 : {
			de: "Sperrkantring",
			en: "spring washer"
		}
		,A2V00002329337 : {
			de: "Langtraeger MW vollst. links",
			en: "longit.girder MC comp. left"
		}
		,A2V00002329338 : {
			de: "Langtraeger MW vollst re",
			en: "longit. girder MC comp. right"
		}
		,A2V00002329394 : {
			de: "Schweissbadsicherung w.g.",
			en: "weld pool backing as d."
		}
		,A2V00002329471 : {
			de: "Schweissbadsicherung",
			en: "weld pool backing"
		}
		,A2V00002329571 : {
			de: "Schweissbadsicherung",
			en: "weld pool backing"
		}
		,A2V00002329592 : {
			de: "Schweissbadsicherung w.g.",
			en: "weld pool backing as d."
		}
		,A2V00002329643 : {
			de: "Schweissbadsicherung s.p.",
			en: "weld pool backing r.i."
		}
		,A2V00002329724 : {
			de: "Schweissbadsicherung sp.g.",
			en: "weld pool backing r.i."
		}
		,A2V00002329749 : {
			de: "Knotenblech w.g.",
			en: "junction plate as d."
		}
		,A2V00002330030 : {
			de: "Durchbruch WC",
			en: "lead through WC"
		}
		,A2V00002460528 : {
			de: "Haltestange Tuer geschw.",
			en: "Handrail door welded"
		}
		,A2V00002330404 : {
			de: "Verstaerkung w.g.",
			en: "reinforcement as d."
		}
		,A2V00002461015 : {
			de: "Haltestange Decke geschw.",
			en: "Handrails ceiling welded"
		}
		,A2V00002461044 : {
			de: "Haltestange, doppelt",
			en: "Handrail, double"
		}
		,A2V00002330459 : {
			de: "Verstaerkung sp.g.",
			en: "reinforcement r.i."
		}
		,A2V00002330660 : {
			de: "Verstaerkung sp.g.",
			en: "reinforcement r.i."
		}
		,A2V00002330720 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00002461948 : {
			de: "Mittellangtraeger Profil, KK-Ende",
			en: "middle girdr profile, SC-end"
		}
		,A2V00002330796 : {
			de: "Knotenblech 2, A-Saeule",
			en: "reinforcement 2, A-post"
		}
		,A2V00002330797 : {
			de: "Rippenblech sp.g.",
			en: "rib plate m.d."
		}
		,A2V00002330800 : {
			de: "Schottblech",
			en: "partition plate"
		}
		,A2V00002462034 : {
			de: "Klemmplatte sp.g.",
			en: "Terminal plate i.m.i."
		}
		,A2V00002330919 : {
			de: "Tuerrippe lang sp.g.",
			en: "door rib long m.d."
		}
		,A2V00002462045 : {
			de: "Haltestange bei Uebergang w.g.",
			en: "Handrail by gangway a.d."
		}
		,A2V00002330920 : {
			de: "Knotenblech oben w.g.",
			en: "reinforcement upper a.d."
		}
		,A2V00002462061 : {
			de: "Haltestange bei Uebergang sp.g.",
			en: "Handrail by gangway i.m.i."
		}
		,A2V00002330921 : {
			de: "Knotenblech oben sp.g.",
			en: "reinforcement upper m.d."
		}
		,A2V00002330922 : {
			de: "Tuerrippe lang w.g.",
			en: "door rib long a.d."
		}
		,A2V00002462195 : {
			de: "Gewindeplatte",
			en: "Thread plate"
		}
		,A2V00002462213 : {
			de: "Klemmplatte w.g.",
			en: "Terminal plate a.d."
		}
		,A2V00002462226 : {
			de: "Halter vollst.",
			en: "Bracket compl."
		}
		,A2V00002462415 : {
			de: "Toleranzausgleich +11 Z-Profil",
			en: "Tolerance adjustment +11 Z-profile"
		}
		,A2V00002331478 : {
			de: "Luftversorgungsanlage",
			en: "Pneumatic system installation"
		}
		,A2V00002462479 : {
			de: "Dichtung f. Haltestange",
			en: "Sealing gasket f. handrail"
		}
		,A2V00002331861 : {
			de: "Bugnase elektr. vst.",
			en: "Front end nose,   electr. compl."
		}
		,A2V00002332092 : {
			de: "Seitenblech sp.g.",
			en: "sideplate r.i."
		}
		,A2V00002332126 : {
			de: "Knotenblech sp.g.",
			en: "junction plate r.i."
		}
		,A2V00002332395 : {
			de: "Blech",
			en: "plate"
		}
		,A2V00002462647 : {
			de: "Kegelfuss f. Haltestange",
			en: "Taper foot f. handrail"
		}
		,A2V00002462728 : {
			de: "Kegelfuss Abdeckung",
			en: "Taper foot cover"
		}
		,A2V00002463083 : {
			de: "Haltestange bei Fenster",
			en: "Window handrail"
		}
		,A2V00002463318 : {
			de: "Haltestange bei Frontmaske",
			en: "Handrail by front mask"
		}
		,A2V00002463390 : {
			de: "Flanschplatte vollst.",
			en: "Flange plate compl."
		}
		,A2V00002463545 : {
			de: "Montageschieneprofil 15",
			en: "Assembly rail profile 15"
		}
		,A2V00002464094 : {
			de: "Haltestange bei Sitz",
			en: "Handrail at seat"
		}
		,A2V00002464157 : {
			de: "Haltestange bei Sitz",
			en: "Handrail at seat"
		}
		,A2V00002464968 : {
			de: "Dachprofil 1",
			en: "roof profile 1"
		}
		,A2V00002464970 : {
			de: "Dachprofil 2",
			en: "roof profile 2"
		}
		,A2V00002464971 : {
			de: "Dachprofil 3",
			en: "roof profile 3"
		}
		,A2V00002464972 : {
			de: "Dachprofil 4",
			en: "roof profile 4"
		}
		,A2V00002465513 : {
			de: "Haltestange Decke geschw.",
			en: "Handrails ceiling welded"
		}
		,A2V00002465595 : {
			de: "Seitenwandprofil 1 Niederflur MW",
			en: "sidewall profile 1 low-floor mc"
		}
		,A2V00002465801 : {
			de: "Haltestange bei Sitz",
			en: "Handrail by seat"
		}
		,A2V00002466096 : {
			de: "Seitenwandprofil 2 Niederflur MW",
			en: "side wall profile 2 low-floor mc"
		}
		,A2V00002466098 : {
			de: "Seitenwandprofil 2 Niederflur MW",
			en: "side wall profile 2 low-floor mc"
		}
		,A2V00002466099 : {
			de: "Seitenwandprofil 3 Niederflur MW",
			en: "side wall profile 3 low-floor mc"
		}
		,A2V00002466100 : {
			de: "Seitenwandprofil 5 Niederflur MW",
			en: "side wall profile 5 low-floor mc"
		}
		,A2V00002466101 : {
			de: "Seitenwandprofil 1 Hochflur MW",
			en: "side wall profile 1 height-floor mc"
		}
		,A2V00002466102 : {
			de: "Seitenwandprofil 2 Hochflur MW",
			en: "sidewall profile 2 height-floor mc"
		}
		,A2V00002466103 : {
			de: "Seitenwandprofil 3 Hochflur MW",
			en: "side wall profile 3 height-floor mc"
		}
		,A2V00002466104 : {
			de: "Seitenwandprofil 3 Hochflur MW",
			en: "side wall profile 3 height-floor mc"
		}
		,A2V00002466156 : {
			de: "Seitenwandprofil 3 Hochflur MW",
			en: "side wall profile 3 height-floor mc"
		}
		,A2V00002466157 : {
			de: "Seitenwandprofil 3 Hochflur MW",
			en: "side wall profile 3 height-floor mc"
		}
		,A2V00002466158 : {
			de: "Seitenwandprofil 3 Hochflur MW",
			en: "side wall profile 3 height-floor mc"
		}
		,A2V00002466159 : {
			de: "Seitenwandprofil 4 Hochflur MW",
			en: "side wall profile 4 height-floor mc"
		}
		,A2V00002466657 : {
			de: "Kegelhuelse fuer Haltestange",
			en: "Taper bush for handrail"
		}
		,A2V00002466658 : {
			de: "Kegelhuelse fuer Haltestange",
			en: "Taper bush for handrail"
		}
		,A2V00002333453 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002333588 : {
			de: "Flansch Rohrdurchgang",
			en: "flange pipe lead through"
		}
		,A2V00002333589 : {
			de: "Kabeldurchfuehrung vollst",
			en: "cable lead through comp."
		}
		,A2V00002470338 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00002470340 : {
			de: "Profil",
			en: "Profile"
		}
		,A2V00002470344 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00002334824 : {
			de: "Keil Uebergang",
			en: "Wedge strip"
		}
		,A2V00002496546 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002496547 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002496548 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002335129 : {
			de: "Gewindeplatte",
			en: "threaded plate"
		}
		,A2V00002497382 : {
			de: "Laufdrehgestell vollstaendig A",
			en: "Trailerbogie complete A"
		}
		,A2V00002369649 : {
			de: "Winkel",
			en: "angle"
		}
		,A2V00002276635 : {
			de: "Distanzscheibe lackiert",
			en: "spacer shim painted"
		}
		,A2V00002276728 : {
			de: "Beilagscheibe lackiert",
			en: "shim painted"
		}
		,A2V00002712003 : {
			de: "Schwenkarm unten vollst.",
			en: "Swivel arm bottom, compl."
		}
		,A2V00002712004 : {
			de: "Abdichtwinkel, oben vollst.",
			en: "Sealing frame, top"
		}
		,A2V00002712026 : {
			de: "Abdichtwinkel, NSK vollst.",
			en: "Sealing frame secondary closing edge"
		}
		,A2V00002712027 : {
			de: "Abdichtwinkel, HSK vollst.",
			en: "Sealing frame main closing edge"
		}
		,A2V00002712028 : {
			de: "Fangbolzen",
			en: "Catch bolt"
		}
		,A2V00002712029 : {
			de: "Fangkeil, vollst.",
			en: "catch wedge, compl."
		}
		,A2V00002712030 : {
			de: "Notentriegelung, innen",
			en: "Emergency release (EED)"
		}
		,A2V00002712031 : {
			de: "Notentriegelung, aussen",
			en: "Emergency release (EAD)"
		}
		,A2V00002712032 : {
			de: "Ausserbetriebnahme UZS",
			en: "Lock out device"
		}
		,A2V00002712035 : {
			de: "Klemmleistenhalter, vollst.",
			en: "Connectorbox bracket, compl"
		}
		,A2V00002712046 : {
			de: "Abdeckung Steuerung",
			en: "Control unit cover, compl."
		}
		,A2V00002712047 : {
			de: "Schwenkarm unten vollst.",
			en: "Swivel arm bottom, compl."
		}
		,A2V00002712048 : {
			de: "Abdichtwinkel, oben vollst.",
			en: "Sealing frame, top"
		}
		,A2V00002712049 : {
			de: "Abdichtwinkel, unten",
			en: "Sealing frame, bottom"
		}
		,A2V00002712050 : {
			de: "Abdichtwinkel, NSK vollst.",
			en: "Sealing frame secondary closing edge"
		}
		,A2V00002712051 : {
			de: "Abdichtwinkel, HSK vollst.",
			en: "Sealing frame main closing edge"
		}
		,A2V00002723766 : {
			de: "Uebertragungszug ZLFL",
			en: "Bowden cable ZLFL"
		}
		,A2V00002723767 : {
			de: "Uebertragungszug ZLFL 2",
			en: "Bowden cable ZLFL 2"
		}
		,A2V00002723769 : {
			de: "Uebertragungszug ZLFV",
			en: "Bowden cable ZLFV"
		}
		,A2V00002723771 : {
			de: "Uebertragungszug ZLFL L=1200",
			en: "Bowden cable ZLFL L=1200"
		}
		,A2V00002336281 : {
			de: "Gewindeplatte",
			en: "threaded plate"
		}
		,A2V00002497882 : {
			de: "Tragbuegel vollst.",
			en: "Mounting bracket compl."
		}
		,A2V00002497924 : {
			de: "Lampenhalter",
			en: "Lamp holder"
		}
		,A2V00002498622 : {
			de: "Seitenwandprofil 4 Niederflur MW",
			en: "side wall profile 4 low-floor mc"
		}
		,A2V00002499088 : {
			de: "Sickenblech, KK-Ende",
			en: "beading sheet, short coupler end"
		}
		,A2V00002337521 : {
			de: "Wagenkasten teilausgebaut TW2.2",
			en: "Car box partially expanded TW2.2"
		}
		,A2V00002337824 : {
			de: "Schottblech li",
			en: "bulkhead sheet li"
		}
		,A2V00002337825 : {
			de: "Schottblech re",
			en: "bulkhead sheet re"
		}
		,A2V00002337828 : {
			de: "Adapterprofil     sp.g.",
			en: "Adapter profile   r.i."
		}
		,A2V00002337843 : {
			de: "Adapterprofil     w.g.",
			en: "Adapter profile   as d."
		}
		,A2V00002337956 : {
			de: "Blech",
			en: "plate"
		}
		,A2V00002337995 : {
			de: "Schottblech Dach",
			en: "bulkhead sheet roof"
		}
		,A2V00002338012 : {
			de: "Endprofil Dach",
			en: "End profile roof"
		}
		,A2V00002338013 : {
			de: "Endprofil Dach",
			en: "End profile roof"
		}
		,A2V00002338605 : {
			de: "Rahmen",
			en: "frame"
		}
		,A2V00002504590 : {
			de: "Nutenstein M6",
			en: "Slot nut M6"
		}
		,A2V00002504621 : {
			de: "Kurzkupplungshaelfte, kpl. kurz",
			en: "Semipermanent coupler half, cpl. short"
		}
		,A2V00002504622 : {
			de: "Kurzkupplungshaelfte lang",
			en: "Semipermanent coupler half, cpl. long"
		}
		,A2V00002504734 : {
			de: "U-Kappe Profil, Untergestell",
			en: "U-cap profile, underframe"
		}
		,A2V00002506039 : {
			de: "Titanus MicroSens Montageeinheit",
			en: "Titanus MicroSens As. unit"
		}
		,A2V00002341011 : {
			de: "Konsole",
			en: "Console"
		}
		,A2V00002506513 : {
			de: "Zwischenplatte",
			en: "Intermediate plate"
		}
		,A2V00002507297 : {
			de: "Fangkonsole SWT",
			en: "Catching console WP"
		}
		,A2V00002507314 : {
			de: "Halter vollst.",
			en: "Bracket compl."
		}
		,A2V00002341315 : {
			de: "Befestigung       Barhocker",
			en: "fastening         barstool"
		}
		,A2V00002507736 : {
			de: "Klimageraet Fahrgastraum",
			en: "HVAC unit saloon"
		}
		,A2V00002341588 : {
			de: "Befestigung       Barhocker",
			en: "fastening         barstool"
		}
		,A2V00002509386 : {
			de: "Kragen vollst.",
			en: "Flange compl."
		}
		,A2V00002449099 : {
			de: "Mitteldecke kompl. Container 1900",
			en: "Center ceiling compl. Container 1900"
		}
		,A2V00002449094 : {
			de: "Mitteldecke kompl. Container 1510",
			en: "Center ceiling compl. Container 1510"
		}
		,A2V00002426959 : {
			de: "Mitteldecke kompl. Container 1900",
			en: "Center ceiling compl. Container 1900"
		}
		,A2V00002449093 : {
			de: "Mitteldecke kompl. Container 1510",
			en: "Center ceiling compl. Container 1510"
		}
		,A2V00002449096 : {
			de: "Mitteldecke kompl. Container 1900",
			en: "Center ceiling compl. container 1900"
		}
		,A2V00002545255 : {
			de: "Seitendecke 1571 Lautsprecher",
			en: "Side ceiling 1571 Speaker"
		}
		,A2V00002426715 : {
			de: "Mitteldecke kompl. 1900",
			en: "Center ceiling compl. 1900"
		}
		,A2V00002545254 : {
			de: "Seitendecke 1571 Einstieg",
			en: "Side ceiling 1571 entrance"
		}
		,A2V00002342052 : {
			de: "Profil",
			en: "profile"
		}
		,A2V00002508991 : {
			de: "Festfenster gross",
			en: "Fixed wiindow big"
		}
		,A2V00002509008 : {
			de: "NEA-Fenster gross",
			en: "NEA-window big"
		}
		,A2V00002457964 : {
			de: "Hilfs-Haltestange",
			en: "Support Handrail"
		}
		,A2V00002558547 : {
			de: "Bodenklappe 16 kompl.",
			en: "Floor hatch 16 completed"
		}
		,A2V00002628416 : {
			de: "Geraeteschrank +57.10 komplett",
			en: "Equipment cabinet +57.10 complete"
		}
		,A2V00002628417 : {
			de: "Geraeteschrank +56.10 komplett",
			en: "Equipment cabinet +56.10 complete"
		}
		,A2V00002667978 : {
			de: "Geraeteschrank +55.10 komplett",
			en: "Equipment cabinet +55.10 complete"
		}
		,A2V00002105502 : {
			de: "Abteildecke 8, vollst.",
			en: "compartment ceil. 8, comp."
		}
		,A2V00002669696 : {
			de: "Antrieb vollst.",
			en: "Drive unit complete"
		}
		,A2V00002669698 : {
			de: "Tuerfluegel TSI vollst.",
			en: "Door panel TSI complete"
		}
		,A2V00002669699 : {
			de: "Drehfallenverriegelung vollst. allg.",
			en: "Rotary latch compl. univ."
		}
		,A2V00002669702 : {
			de: "Tuerfluegel HE vollst.",
			en: "Door panel  height of raised floor"
		}
		,A2V00002669704 : {
			de: "Drehfallenverriegelung vollst. unten",
			en: "Rotary latch compl. bottom"
		}
		,A2V00002774419 : {
			de: "Tuerfluegel, vollst.",
			en: "Door panel, compl."
		}
		,A2V00002774418 : {
			de: "Tuerfluegel, vollst.",
			en: "Door panel, compl."
		}
		,A2V00002105460 : {
			de: "Deckenmodul M3, vollst.",
			en: "ceiling module M3, comp."
		}
		,A2V00002510578 : {
			de: "Abschlussprofil Dach",
			en: "profile cross girder roof"
		}
		,A2V00002510579 : {
			de: "Abschlussprofil   Dach",
			en: "end profil       short coupler end"
		}
		,A2V00002342365 : {
			de: "Rohr",
			en: "tube"
		}
		,A2V00002511860 : {
			de: "Befestigungsleiste 02 geschweisst",
			en: "Mounting rail 02 welded"
		}
		,A2V00002511861 : {
			de: "Befestigungsleiste 03 geschweisst",
			en: "Mounting rail 03 welded"
		}
		,A2V00002511942 : {
			de: "Fuehrungsblech 01",
			en: "Guiding plate 01"
		}
		,A2V00002511944 : {
			de: "Fuehrungsblech 02",
			en: "Guiding plate 02"
		}
		,A2V00002512026 : {
			de: "Befestigungsleiste 01 geschweisst",
			en: "Mounting rail 01 welded"
		}
		,A2V00002342589 : {
			de: "WC Konsole 1",
			en: "Console for Toilet 1"
		}
		,A2V00002342592 : {
			de: "WC Konsole breit",
			en: "console for toilet wide"
		}
		,A2V00002342594 : {
			de: "WC Konsole 3",
			en: "console for toilet 3"
		}
		,A2V00002512088 : {
			de: "Befestigungsblech 08 vollst. sp.g.",
			en: "Fixing plate 08 compl. i.m.i."
		}
		,A2V00002512089 : {
			de: "Befestigungsblech 08 vollst. w.g.",
			en: "Fixing plate 08 compl. a.d."
		}
		,A2V00002342644 : {
			de: "WC Konsole 4 vollst",
			en: "console for toilet 4"
		}
		,A2V00002342645 : {
			de: "WC Konsole 5 vollst",
			en: "console for toilet 5"
		}
		,A2V00002342666 : {
			de: "WC Konsole 2 vollst",
			en: "console for toilet 2"
		}
		,A2V00002342668 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002342888 : {
			de: "Schelle 2",
			en: "Clamp 2"
		}
		,A2V00002512264 : {
			de: "Befestigungsblech 07",
			en: "Fixing plate 07"
		}
		,A2V00002512269 : {
			de: "Halter sp.g.",
			en: "Support i.m.i."
		}
		,A2V00002342914 : {
			de: "Flanschblech",
			en: "flange"
		}
		,A2V00002512270 : {
			de: "Halter w.g.",
			en: "Support a.d."
		}
		,A2V00002343553 : {
			de: "Platte Halter vollst",
			en: "plate holder comp."
		}
		,A2V00002512949 : {
			de: "Crashrohr ZSB w.g.",
			en: "Crashpipe assy. a.d."
		}
		,A2V00002513365 : {
			de: "Crashrohr ZSB, sp.g.",
			en: "Crashpipe assy. i.m.i."
		}
		,A2V00002513430 : {
			de: "Wellenbalg, vollst.",
			en: "Corrugated bellows, assy."
		}
		,A2V00002513431 : {
			de: "Konsole, vollst., geschweisst",
			en: "Console, assy., welded"
		}
		,A2V00002513432 : {
			de: "Brueckenblech, vollst.",
			en: "Bridge plate, assy."
		}
		,A2V00002513986 : {
			de: "Befestigung UF",
			en: "Fastener UF"
		}
		,A2V00002515234 : {
			de: "Halter vollst.",
			en: "Bracket compl."
		}
		,A2V00002516351 : {
			de: "Querprofil",
			en: "Cross profile"
		}
		,A2V00002516518 : {
			de: "Versteifungsprofil w.g.",
			en: "Bracing profile a.d."
		}
		,A2V00002516519 : {
			de: "Versteifungsprofil sp.g.",
			en: "Bracing profile i.m.i."
		}
		,A2V00002528267 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002528268 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002528270 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002528271 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002528272 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002528274 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002553546 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002553415 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002528275 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002528273 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002568337 : {
			de: "Bremsmodul BM2-3B, vollst.",
			en: "Brake module BM2-3B, compl."
		}
		,A2V00002110211 : {
			de: "Reservierungsanzeige Kopfstuetze links",
			en: "Reservation Display Head Rest left"
		}
		,A2V00002528276 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002601382 : {
			de: "Bedienpanel",
			en: "Control element"
		}
		,A2V00002528269 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002534833 : {
			de: "Abdeckung",
			en: "Covering"
		}
		,A2V00002345578 : {
			de: "Tuerfluegel, w.g.",
			en: "Door leaf, as shown"
		}
		,A2V00002345579 : {
			de: "Tuerfluegel, sp.gl",
			en: "Door leaf, sym.opp."
		}
		,A2V00002516935 : {
			de: "Langtraeger NF",
			en: "longit. beam lf"
		}
		,A2V00002517301 : {
			de: "Schweissbadsicherung",
			en: "Weldbacking"
		}
		,A2V00002517319 : {
			de: "Entwaesserungsrohr",
			en: "Drainage pipe"
		}
		,A2V00002517420 : {
			de: "Adapter",
			en: "adapter"
		}
		,A2V00002517421 : {
			de: "Langtraeger NF",
			en: "Longit. beam lf"
		}
		,A2V00002517422 : {
			de: "Langtraeger NF sp.g.",
			en: "Longitudinal beam lf r.i."
		}
		,A2V00002346014 : {
			de: "Klapptritt, kompl., sp.g.",
			en: "hinged step,compl. mirror inverted"
		}
		,A2V00002517423 : {
			de: "Langtraeger NF w.g.",
			en: "longit. beam lf as drawn"
		}
		,A2V00002346015 : {
			de: "Klapptritt, kompl., w.g.",
			en: "hinged step,compl. as drawn"
		}
		,A2V00002517608 : {
			de: "Tuerfluegel, vollst., rechts",
			en: "Door panel, compl., right"
		}
		,A2V00002517609 : {
			de: "Drehsaeule, vollst., links",
			en: "Rotating column, compl., left"
		}
		,A2V00002517610 : {
			de: "Drehsaeule, vollst., rechts",
			en: "Å”otating column, compl., right"
		}
		,A2V00002517987 : {
			de: "Druckbehaelter aus Aluminium",
			en: "air receiver alu 75L D=350"
		}
		,A2V00002517990 : {
			de: "Druckbehaelter aus Aluminium",
			en: "Air reservoir alu 75L D=400"
		}
		,A2V00002518484 : {
			de: "Tuerverstaerkungsprofil",
			en: "cross profile, floor plate"
		}
		,A2V00002518645 : {
			de: "Pruefstueck T=10 C=1 W=25Â°",
			en: "Test piece T=10 C=1 W=25Â°"
		}
		,A2V00002518670 : {
			de: "Laufdrehgestell vollstaendig B",
			en: "Trailerbogie complete B"
		}
		,A2V00002518912 : {
			de: "Schiebetritt, vollst.",
			en: "Sliding step, compl."
		}
		,A2V00002518960 : {
			de: "Pruefstueck T=2",
			en: "Test piece T=2"
		}
		,A2V00002518961 : {
			de: "Pruefstueck T=4 C=1 W=25",
			en: "Test piece T=4 C=1 W=25"
		}
		,A2V00002346757 : {
			de: "Gepaeckablage 33",
			en: "Luggage rack 33"
		}
		,A2V00002346758 : {
			de: "Gepaeckablage 34",
			en: "Luggage rack 34"
		}
		,A2V00002521405 : {
			de: "Kupplungsplatte vst.",
			en: "Coupling plate complete"
		}
		,A2V00002521548 : {
			de: "Quertraeger vollst.",
			en: "Cross beam compl."
		}
		,A2V00002521743 : {
			de: "Blech sp.g.",
			en: "Sheet i.m.i."
		}
		,A2V00002521745 : {
			de: "Blech w.g.",
			en: "Sheet a.d."
		}
		,A2V00002521963 : {
			de: "Blech EN 10051 3x1500x4000 S460MC",
			en: "Sheet EN 10051 3x1500x4000 S460MC"
		}
		,A2V00002521964 : {
			de: "Blech EN 10051 2x1170x4800 S460MC",
			en: "Sheet EN 10051 2x1170x4800 S460MC"
		}
		,A2V00002521967 : {
			de: "Blech EN 10051 16x1500x4000 S460MC",
			en: "Sheet EN 10051 16x1500x4000 S460MC"
		}
		,A2V00002522055 : {
			de: "Entwaesserungstopf",
			en: "drainage pot"
		}
		,A2V00002348388 : {
			de: "Monitorhalter, kpl.",
			en: "Monitor holder, compl."
		}
		,A2V00002522354 : {
			de: "Abschlussprofil Stirnwand",
			en: "end profile end wall"
		}
		,A2V00002522415 : {
			de: "Wellenbalg, vollst. endmontiert",
			en: "Corrugated bellows, assy final mounted"
		}
		,A2V00002524376 : {
			de: "Klemmplatte",
			en: "Terminal plate"
		}
		,A2V00002524381 : {
			de: "Zwischenblech oben",
			en: "Intermediate plate  top"
		}
		,A2V00002524382 : {
			de: "Badsicherung Quergurt",
			en: "Weld pool cross beam"
		}
		,A2V00002524385 : {
			de: "Knotenblech w.g.",
			en: "Junction plate as drawn"
		}
		,A2V00002524389 : {
			de: "Abdeckung Vorbau",
			en: "Cover front end"
		}
		,A2V00002525274 : {
			de: "U-Halter",
			en: "U-holder"
		}
		,A2V00002525275 : {
			de: "Knotenblech KK-Ende sp.g.",
			en: "Short coupler end sym.opp."
		}
		,A2V00002525288 : {
			de: "Kupplungsmaul sp.g.",
			en: "Hitch r.i."
		}
		,A2V00002525289 : {
			de: "Verstaerkung",
			en: "Reinforcement"
		}
		,A2V00002525295 : {
			de: "Kupplungsmaul w.g.",
			en: "Hitch as drawn"
		}
		,A2V00002349851 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002525297 : {
			de: "Knotenblech KK-Ende w.g.",
			en: "Gusset plate short coupler end as drawn"
		}
		,A2V00002349911 : {
			de: "Abdichtblech",
			en: "seal sheet"
		}
		,A2V00002526054 : {
			de: "Halteblech, vollst",
			en: "Bracket, complete"
		}
		,A2V00002349932 : {
			de: "Abdichtblech",
			en: "seal sheet"
		}
		,A2V00002349933 : {
			de: "Abdichtblech",
			en: "seal sheet"
		}
		,A2V00002349940 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002526117 : {
			de: "H-Profil vollst. sp.g.",
			en: "H-profile compl. r.i."
		}
		,A2V00002349950 : {
			de: "Abdeckung",
			en: "Covering"
		}
		,A2V00002526118 : {
			de: "H-Profil vollst. w.g.",
			en: "H-profile compl. as drawm"
		}
		,A2V00002349976 : {
			de: "Abdichtblech",
			en: "seal sheet"
		}
		,A2V00002349982 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002349991 : {
			de: "Abdeckung Profil w.g.",
			en: "Covering  profile a.d."
		}
		,A2V00002349992 : {
			de: "Abdeckung Profil sp.g.",
			en: "Covering  profile r.i."
		}
		,A2V00002349993 : {
			de: "Abdeckung Profil sp.g.",
			en: "Covering  profile r.i."
		}
		,A2V00002526288 : {
			de: "Haltestange bei Klappsitz vollst. w.g.",
			en: "Handrail by tip-up seat compl. a.d."
		}
		,A2V00002526289 : {
			de: "Haltestange bei Klappsitz vollst. sp.g.",
			en: "Handrail by tip-up seat compl. i.m.i."
		}
		,A2V00002349994 : {
			de: "Abdeckung Profil w.g.",
			en: "Covering  profile a.d."
		}
		,A2V00002349995 : {
			de: "Abdeckung Profil sp.g.",
			en: "Covering  profile r.i."
		}
		,A2V00002349997 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002349998 : {
			de: "Abdeckung Profil w.g.",
			en: "Covering  profile a.d."
		}
		,A2V00002349999 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002350003 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002350060 : {
			de: "Abdeckung Profil w.g.",
			en: "Covering  profile a.d."
		}
		,A2V00002350061 : {
			de: "Abdeckung Profil sp.g.",
			en: "Covering  profile r.i."
		}
		,A2V00002350177 : {
			de: "Abdeckung Profil w.g.",
			en: "Covering  profile a.d."
		}
		,A2V00002350178 : {
			de: "Abdeckung Profil sp.g.",
			en: "Covering  profile r.i."
		}
		,A2V00002350207 : {
			de: "Abdeckung Profil sp.g.",
			en: "Covering  profile r.i."
		}
		,A2V00002350208 : {
			de: "Abdeckung Profil w.g.",
			en: "Covering  profile a.d."
		}
		,A2V00002350344 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002526778 : {
			de: "Hauptquertraeger vollst.",
			en: "main cross girder compl."
		}
		,A2V00002526805 : {
			de: "Bodenplatte vollst.",
			en: "Baseplate compl."
		}
		,A2V00355404023 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002526850 : {
			de: "Zwischenboden, lackiert",
			en: "intermediate floor, painted"
		}
		,A2V00002351645 : {
			de: "Kanalabdeckung kompl.",
			en: "Air duct cover compl."
		}
		,A2V00002351661 : {
			de: "Kanalabdeckung kompl.",
			en: "Air duct cover compl."
		}
		,A2V00002351931 : {
			de: "Kanalabdeckung kompl.",
			en: "Air duct cover compl."
		}
		,A2V00002351986 : {
			de: "Kanalabdeckung kompl.",
			en: "Air duct cover compl."
		}
		,A2V00002352280 : {
			de: "Kanalabdeckung kompl.",
			en: "Air duct cover compl."
		}
		,A2V00002527120 : {
			de: "Klemmplatte",
			en: "Terminal plate"
		}
		,A2V00100195863 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002348051 : {
			de: "Tuerscheibe",
			en: "Glass"
		}
		,A2V00002353446 : {
			de: "Kanalabdeckung kompl.",
			en: "Air duct cover compl."
		}
		,A2V00002353731 : {
			de: "Kantenprofil sp. g.",
			en: "profil r. i."
		}
		,A2V00002353852 : {
			de: "Kantenprofil w. g.",
			en: "profil  as dr."
		}
		,A2V00002354071 : {
			de: "Kantenprofil w. g.",
			en: "profil  as dr."
		}
		,A2V00002354479 : {
			de: "Kantenprofil sp. g.",
			en: "profil r. i."
		}
		,A2V00002354599 : {
			de: "Kantenprofil",
			en: "profil"
		}
		,A2V00002527646 : {
			de: "Endsaeule KK-Ende",
			en: "end pillar short coupler end"
		}
		,A2V00002527673 : {
			de: "Abstuetzung, Frontmaske sp.g.",
			en: "Support, front mask i.m.i."
		}
		,A2V00002527690 : {
			de: "Abstuetzung, Frontmaske w.g.",
			en: "Support, front mask a.d."
		}
		,A2V00002527847 : {
			de: "Konsole Jumper 02",
			en: "Console jumper 02"
		}
		,A2V00002197092 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical shock absorber"
		}
		,A2V00002550066 : {
			de: "Tauchzapfen vollst.",
			en: "Center pivot compl."
		}
		,A2V00002550145 : {
			de: "Kombikonsole TDG SPG lack.",
			en: "Combined console MB OAD paint."
		}
		,A2V00002550236 : {
			de: "Kombikonsole TDG WG lack.",
			en: "Combined console MB AD paint."
		}
		,A2V00002546521 : {
			de: "Laufdrehgestell 4,vollstaendig",
			en: "Trailerbogie 4,complete"
		}
		,A2V00002538525 : {
			de: "Automatische Kupplung",
			en: "Automatic coupler"
		}
		,A2V00002545458 : {
			de: "ZSB Voute L=1279 Flachdecke",
			en: "Cove ass. L=1279 c. panto."
		}
		,A2V00002545464 : {
			de: "ZSB Voute L=1122 Flachdecke",
			en: "Cove assembled L=1122 ceiling pantograph"
		}
		,A2V00002673551 : {
			de: "Kabelstrang Beschl. Sensor MW",
			en: "Cable loom acc. sensor MC"
		}
		,A2V00002120327 : {
			de: "Steuerventil kompakt, CDV",
			en: "Distibution valve campact CDV"
		}
		,A2V00002670531 : {
			de: "Sicherheitsventil 7 bar",
			en: "safety valve 7 bar"
		}
		,A2V00002670533 : {
			de: "Sicherheitsventil 4,5 bar",
			en: "safety valve 4,5 bar"
		}
		,A2V00002684400 : {
			de: "Halter 1 vst.",
			en: "Bracket 1 cpl."
		}
		,A2V00002610540 : {
			de: "Tuersteuerung",
			en: "Door control unit"
		}
		,A2V00002222575 : {
			de: "Seitenklappe vollst. w.g.",
			en: "side flap complete a.d"
		}
		,A2V00002234989 : {
			de: "Seitenklappe vollst. sp.g.",
			en: "side flap complete r.i."
		}
		,A2V00002528149 : {
			de: "Formrohr f. Kabelfuehrung",
			en: "Cable guiding pipe"
		}
		,A2V00002528299 : {
			de: "Verstaerkungsprofil w.g.",
			en: "reinforcement profile a.d."
		}
		,A2V00002358733 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002358845 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002528518 : {
			de: "Langtraeger vollst. sp.g.",
			en: "Longitudinal beam complete r.i."
		}
		,A2V00002528519 : {
			de: "Langtraeger vollst. w.g.",
			en: "Longitudinal beam complete as d."
		}
		,A2V00002529175 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002529264 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002359772 : {
			de: "Winkel",
			en: "Angle"
		}
		,A2V00002530598 : {
			de: "C-Schiene",
			en: "C-rail"
		}
		,A2V00002530599 : {
			de: "C-Schiene",
			en: "C-rail"
		}
		,A2V00002530601 : {
			de: "C-Schiene",
			en: "C-rail"
		}
		,A2V00002530602 : {
			de: "C-Schiene",
			en: "C-rail"
		}
		,A2V00002360727 : {
			de: "Befestigung",
			en: "fastening"
		}
		,A2V00002360742 : {
			de: "Befestigung",
			en: "fastening"
		}
		,A2V00002532167 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002532168 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002532169 : {
			de: "U-Profil",
			en: "U-profile"
		}
		,A2V00002360851 : {
			de: "Bundbuchse",
			en: "collar bush"
		}
		,A2V00002361005 : {
			de: "Schale kpl.",
			en: "cup compl."
		}
		,A2V00002533719 : {
			de: "Haltestange, zentrale",
			en: "Handrail, central"
		}
		,A2V00002533721 : {
			de: "Huelse, zentrale",
			en: "Bush, central"
		}
		,A2V00002533741 : {
			de: "Flachdach vollstaendig WE1",
			en: "Flat roof comlete CE1"
		}
		,A2V00002533744 : {
			de: "Flachdach vollstaendig WE2",
			en: "Flat roof complete CE2"
		}
		,A2V00002361052 : {
			de: "Schale kpl.",
			en: "cup compl."
		}
		,A2V00002533862 : {
			de: "Knotenblech sp. g.",
			en: "Gusset plate r. i."
		}
		,A2V00002533864 : {
			de: "Knotenblech w.g.",
			en: "Gusset plate as drawn"
		}
		,A2V00002533865 : {
			de: "Eckprofil oben",
			en: "Corner profile top"
		}
		,A2V00002533960 : {
			de: "Dichtblech w. g.",
			en: "Sealing plate as d."
		}
		,A2V00002533965 : {
			de: "Eckprofil unten sp. g.",
			en: "Corner profile under r. i."
		}
		,A2V00002533966 : {
			de: "Fraesteil Dachabschluss w. g.",
			en: "Milled part roof ending as d."
		}
		,A2V00002533967 : {
			de: "Knotenblech w. g.",
			en: "Gusset plate as d."
		}
		,A2V00002533968 : {
			de: "Fraesteil Dachabschluss sp. g.",
			en: "Milled part roof ending r. i."
		}
		,A2V00002533969 : {
			de: "Quertraeger",
			en: "Cross girder"
		}
		,A2V00002533972 : {
			de: "Dach",
			en: "Roof"
		}
		,A2V00002533973 : {
			de: "Eckprofil unten w. g.",
			en: "Corner profile under as d."
		}
		,A2V00002533977 : {
			de: "Dachschottblech sp.g.",
			en: "Partition plate roof r.i."
		}
		,A2V00002533980 : {
			de: "Dachschottblech w.g.",
			en: "Roof plate as drawn"
		}
		,A2V00002363471 : {
			de: "Schirmbuegel 10B Stecker Anschl.Kasstte",
			en: "Shield lever 10B Plug Connection cartrid"
		}
		,A2V00002536819 : {
			de: "Gewindeplatte fuer Antrieb",
			en: "Threaded plate for door drive"
		}
		,A2V00002536837 : {
			de: "Gewindeplatte fuer Drehsaeule",
			en: "Threaded plate for lock column"
		}
		,A2V00002537096 : {
			de: "Knotenblech sp.g.",
			en: "Gusset plate sym.opp"
		}
		,A2V00002537216 : {
			de: "Seitenwandprofil 3",
			en: "Side wall profile 3"
		}
		,A2V00002537221 : {
			de: "Seitenwandprofil 2",
			en: "Side wall profile 2"
		}
		,A2V00002370036 : {
			de: "Schweissplatte",
			en: "welding plate"
		}
		,A2V00002538114 : {
			de: "Scheibe Crashelement",
			en: "Washer crash element"
		}
		,A2V00002370665 : {
			de: "Tuerverriegelung links",
			en: "Locking assembly eft"
		}
		,A2V00002370666 : {
			de: "Tuerverriegelung rechts",
			en: "Locking assembly right"
		}
		,A2V00002370667 : {
			de: "Tuerverriegelung Ladetuer",
			en: "Loading locking assembly"
		}
		,A2V00002539546 : {
			de: "Passfeder",
			en: "Parallel key"
		}
		,A2V00002539597 : {
			de: "Klemmplatte",
			en: "Terminal plate"
		}
		,A2V00002540276 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002540411 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002540412 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002540494 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002440626 : {
			de: "TFT Display 1 x 10 1 x 28 Master",
			en: "TFT Display 1 x 10 1 x 28 Master"
		}
		,A2V00002440935 : {
			de: "EP2002 Gateway Ventil",
			en: "EP2002 Gateway Valve"
		}
		,A2V00002109032 : {
			de: "Tuerholmverkleidung",
			en: "Door panelling"
		}
		,A2V00002549995 : {
			de: "Drehzapfen vollst.",
			en: "Center pivot compl."
		}
		,A2V00002440933 : {
			de: "EP 2002 Gateway Ventil",
			en: "EP 2002 Gateway Valve"
		}
		,A2V00002541322 : {
			de: "Schweissbadsicherung",
			en: "Weldbacking"
		}
		,A2V00002541323 : {
			de: "Schweissbadsicherung",
			en: "Weldbacking"
		}
		,A2V00002541806 : {
			de: "Batterie",
			en: "Battery"
		}
		,A2V00002373574 : {
			de: "Kanalabdeckung kompl.",
			en: "Air duct cover compl."
		}
		,A2V00002373575 : {
			de: "Kanalabdeckung kompl.",
			en: "Air duct cover compl."
		}
		,A2V00002541964 : {
			de: "Sickenblech w.g,",
			en: "beading sheet as d."
		}
		,A2V00002542293 : {
			de: "Gurtblech I-Profil",
			en: "Belt plate I-profile"
		}
		,A2V00002542380 : {
			de: "Versteifung geschw. sp.g.",
			en: "Reinforcement welded i.m.i."
		}
		,A2V00002542391 : {
			de: "Langtraeger UG, links",
			en: "Longitudinal beam UF, left"
		}
		,A2V00002542395 : {
			de: "Langtraeger UG, rechts",
			en: "Longitudinal beam UF, right"
		}
		,A2V00002542400 : {
			de: "Sockel geschw.",
			en: "Base welded"
		}
		,A2V00002542445 : {
			de: "Stanzplatte",
			en: "Stamping plate"
		}
		,A2V00002542591 : {
			de: "Stirnwandblech w.g.",
			en: "Endwall sheet a.d."
		}
		,A2V00002542592 : {
			de: "Stirnwandblech sp.g.",
			en: "Endwall sheet i.m.i."
		}
		,A2V00002542593 : {
			de: "Gurt Endplatte sp.g.",
			en: "Belt endsheet i.m.i."
		}
		,A2V00002542617 : {
			de: "UG Haengung",
			en: "UF Bracket"
		}
		,A2V00002542641 : {
			de: "Tuersaeule sp.g.",
			en: "door pillar sym.opp."
		}
		,A2V00002542643 : {
			de: "Tuersaeule sp.g.",
			en: "door pillar sym.opp."
		}
		,A2V00002542644 : {
			de: "Tuersaeule w.g.",
			en: "door pillar as drawn"
		}
		,A2V00002542662 : {
			de: "Tuersaeule w.g.",
			en: "door pillar as drawn"
		}
		,A2V00002375502 : {
			de: "Schweissbadsicherung",
			en: "weld pool backing"
		}
		,A2V00002543287 : {
			de: "Rahmen, KK-Ende",
			en: "frame, sc-end"
		}
		,A2V00002543856 : {
			de: "Frisch und Abwassertank, vollst.",
			en: "Fresh and waste water tank, compl."
		}
		,A2V00002544232 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002544366 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002544735 : {
			de: "Stirnwand f. Klimawanne, Kurzkuppelende",
			en: "ACU pan endwall, short coupler end"
		}
		,A2V00002544773 : {
			de: "Rippe, Dachquertraeger, Ecke, sp.g.",
			en: "Rib, roof cross beam, corner, i.m.i."
		}
		,A2V00002544864 : {
			de: "Schweissbadsicherung DLT, ob. WE1, w.g.",
			en: "Weld backing RC, upper CE1, a.d."
		}
		,A2V00002544899 : {
			de: "Profil fuer Arbeitsproben",
			en: "Profile for work samples"
		}
		,A2V00002544900 : {
			de: "Profil fuer Arbeitsproben",
			en: "Profile for work samples"
		}
		,A2V00002545227 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002546049 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00001114961 : {
			de: "Impulsgeber LFW COMBINO",
			en: "Pulse generator LFW Combino"
		}
		,A2V00370080326 : {
			de: "GUMMIELEMENT RAD",
			en: "RUBBER BLOCKS"
		}
		,A2V00370052342 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00100026980 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00082025266 : {
			de: "Blechstreifen",
			en: "Metal stripe"
		}
		,A2V00002554837 : {
			de: "Impulsgeber GEL 247BG0319-X-002",
			en: "Speed Sensor GEL 247BG0319-X-002"
		}
		,A2V00002156464 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002576922 : {
			de: "E-Container 11.2 +64.10 komplett",
			en: "E-Container 11.2 +64.10 complete"
		}
		,A2V00002173083 : {
			de: "Sechskantmutter",
			en: "hexagon nut"
		}
		,A2V00002567703 : {
			de: "Schwenkwand +11.30 komplett",
			en: "Rotatable wall +11.30 complete"
		}
		,A2V00001744751 : {
			de: "Temperatursensor  Radlager",
			en: "Temperature sensor wheel bearing"
		}
		,A2V00002201615 : {
			de: "Drehdaempfer",
			en: "yaw damper"
		}
		,A2V00002546259 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002546407 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002547163 : {
			de: "Profil fuer Arbeitsproben",
			en: "Profile for work samples"
		}
		,A2V00002547164 : {
			de: "Profil fuer Arbeitsproben",
			en: "Profile for work samples"
		}
		,A2V00002547371 : {
			de: "Schweissbadsicherung DLT, ob. WE1, sp.g.",
			en: "Weld backing RC, upper, CE1, i.m.i."
		}
		,A2V00002547411 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002547634 : {
			de: "Halter w.g.",
			en: "Bracket a.d."
		}
		,A2V00002547635 : {
			de: "Halter sp.g.",
			en: "Bracket i.m.i."
		}
		,A2V00002547688 : {
			de: "Gleitschutzventil N8.1",
			en: "protection unit slip N8.1"
		}
		,A2V00002547691 : {
			de: "Blech sp.g.",
			en: "plate r.i"
		}
		,A2V00002547692 : {
			de: "Tuerverstaerkung w.g.",
			en: "door reinforcment as d."
		}
		,A2V00002547694 : {
			de: "Tuerverstaerkung sp.g.",
			en: "door reinforcment r.i."
		}
		,A2V00002547706 : {
			de: "Bremsgeruest +51.90 genietet",
			en: "Brake frame +51.90 riveted"
		}
		,A2V00002547798 : {
			de: "Verstaerkungsprofil sp.g.",
			en: "reinforcement profile r.i."
		}
		,A2V00002547866 : {
			de: "Flansch",
			en: "flange"
		}
		,A2V00002547867 : {
			de: "Blech w.g.",
			en: "plate as d."
		}
		,A2V00002548040 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00000315707 : {
			de: "Zunge",
			en: "point blade"
		}
		,A2V00000333493 : {
			de: "Becherrahmen",
			en: "cup framework"
		}
		,A2V00001135010 : {
			de: "WINKEL",
			en: "Angle"
		}
		,A2V00001686829 : {
			de: "Schlauchleitung 2TE 8 NN-750P",
			en: "hose assembly 2TE 8 NN-750P"
		}
		,A2V00000315705 : {
			de: "Zunge 90 mm",
			en: "Point blade"
		}
		,A2V00000334427 : {
			de: "Blindscheibe",
			en: "blind spade"
		}
		,A2V00000333483 : {
			de: "Scheibe",
			en: "washer"
		}
		,A2V00000315703 : {
			de: "ZUNGE",
			en: "point blade"
		}
		,A2V00082023983 : {
			de: "ZUNGE",
			en: "ZUNGE"
		}
		,A2V00001382369 : {
			de: "Schlauchleitung 1STC 6 NN90 670P",
			en: "hose assembly  1STC 6 NN90 670P"
		}
		,A2V00002548508 : {
			de: "Buchse",
			en: "bush"
		}
		,A2V00002548670 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002548679 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002548729 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002379977 : {
			de: "Kondensatrohr, vollst.",
			en: "Condensation pipe, compl."
		}
		,A2V00002379979 : {
			de: "Grauwasserrohr, vollst.",
			en: "Grey water pipe, compl."
		}
		,A2V00002380850 : {
			de: "Hutprofil Tuer",
			en: "cap profile door"
		}
		,A2V00002380867 : {
			de: "Hutprofil",
			en: "cap profile"
		}
		,A2V00000315696 : {
			de: "Zunge",
			en: "point blade"
		}
		,A2V00000315698 : {
			de: "Zunge",
			en: "point blade"
		}
		,A2V00001442420 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001892777 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002546506 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002549644 : {
			de: "Langtraeger we2 vollst. w.g.",
			en: "longit. beam ce2 complete as drawn"
		}
		,A2V00002549645 : {
			de: "Langtraeger we2 vollst. sp.g.",
			en: "longit. beam ce2 complete r.i."
		}
		,A2V00002549749 : {
			de: "Dichtblech",
			en: "sealing plate"
		}
		,A2V00002549750 : {
			de: "Dichtblech",
			en: "saeling plate"
		}
		,A2V00002549763 : {
			de: "Dichtblech w. g.",
			en: "saeling plate as d."
		}
		,A2V00002549764 : {
			de: "Dichtblech sg. g.",
			en: "saeling plate r. i."
		}
		,A2V00002549829 : {
			de: "Halter sp. g.",
			en: "holder r. i."
		}
		,A2V00002549830 : {
			de: "Halter w. g.",
			en: "holder as d."
		}
		,A2V00002549850 : {
			de: "Abdeckung w. g.",
			en: "faceplate as d."
		}
		,A2V00002550087 : {
			de: "Schlingerdaempferkonsole SPG vollst.",
			en: "Anti rolling device OAD compl."
		}
		,A2V00002550088 : {
			de: "Schlingerdaempferkonsole WG vollst.",
			en: "Anti rolling device AD compl."
		}
		,A2V00002550098 : {
			de: "Kombikonsole LDG WG lack.",
			en: "Combined console TB AD paint."
		}
		,A2V00002550099 : {
			de: "Kombikonsole LDG SPG lack.",
			en: "Combined console TB OAD paint."
		}
		,A2V00002382227 : {
			de: "LZB-Sendeantenne 56kHz Flansch",
			en: "CATR transmitting aerial 56kHz flange"
		}
		,A2V00002550237 : {
			de: "Konsole Nasszelle 1",
			en: "Console toilet 1"
		}
		,A2V00002550299 : {
			de: "Halter Nasszelle 2",
			en: "support toilet 2"
		}
		,A2V00002384416 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002384426 : {
			de: "Uebergangsprofil",
			en: "crossing profile"
		}
		,A2V00002550599 : {
			de: "Querprofil",
			en: "cross profile"
		}
		,A2V00002550696 : {
			de: "Eckprofil sp.g.",
			en: "corner r.i."
		}
		,A2V00002550697 : {
			de: "Eckprofil w.g.",
			en: "corner as d."
		}
		,A2V00002550709 : {
			de: "Zwischendecke WE1",
			en: "intermediate ceiling CE1"
		}
		,A2V00002550780 : {
			de: "Zwischendecke WE2",
			en: "intermediate ceiling CE2"
		}
		,A2V00002386793 : {
			de: "Abdeckung Bodenkanal",
			en: "Cover airchannel"
		}
		,A2V00002386794 : {
			de: "Abdeckung Bodenkanal",
			en: "Cover airchannel"
		}
		,A2V00002700324 : {
			de: "Endglied innen rechts MG-Bremse",
			en: "Magnetic track brake"
		}
		,A2V00002700323 : {
			de: "Endglied innen links MG-Bremse",
			en: "Magnetic track brake"
		}
		,A2V00002700322 : {
			de: "Endglied aussen rechts MG-Bremse",
			en: "Magnetic track brake"
		}
		,A2V00002700321 : {
			de: "Endglied aussen links MG-Bremse",
			en: "Magnetic track brake"
		}
		,A2V00002700319 : {
			de: "Polschuh rechts MG-Bremse",
			en: "Magnetic track brake"
		}
		,A2V00002700318 : {
			de: "Polschuh links MG-Bremse",
			en: "pole shoe left Magnetic track brake"
		}
		,A2V00002701673 : {
			de: "Vollrad 920/830-19-190",
			en: "Solid wheel 920/830-19-190"
		}
		,A2V00002692092 : {
			de: "Fahrsperre, vollst.",
			en: "rearelease device"
		}
		,A2V00002552141 : {
			de: "Kanalabdeckung geschw.",
			en: "Cover welded"
		}
		,A2V00002552142 : {
			de: "Kanalabdeckung geschw.",
			en: "Cover welded"
		}
		,A2V00002552546 : {
			de: "Dachlangtraeger Einzug, w.g.",
			en: "Roof cantrail margin, a.d."
		}
		,A2V00002553279 : {
			de: "Abdeckung geschw.",
			en: "Cover welden"
		}
		,A2V00002553280 : {
			de: "Abdeckung geschw.",
			en: "Cover welded"
		}
		,A2V00002553281 : {
			de: "Abdeckung geschw.",
			en: "Cover welded"
		}
		,A2V00002553282 : {
			de: "Abdeckung geschw.",
			en: "Cover welded"
		}
		,A2V00002391788 : {
			de: "Beilage",
			en: "Attachment"
		}
		,A2V00002553490 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002553894 : {
			de: "Badsich. Tuer oben 1",
			en: "weld p. b. door upper 1"
		}
		,A2V00002393412 : {
			de: "Hauptquertraeger aussen",
			en: "main cross girder outside"
		}
		,A2V00002553895 : {
			de: "Badsich. Tuer oben 2",
			en: "weld p. b. door upper 1"
		}
		,A2V00002393492 : {
			de: "Hauptquertraeger aussen",
			en: "main cross girder outside"
		}
		,A2V00002554026 : {
			de: "Badsich. Tuer unten 1",
			en: "weld p.b. door bottom 1"
		}
		,A2V00001431486 : {
			de: "Buchse",
			en: "Bush"
		}
		,A2V00001706083 : {
			de: "O-Ring",
			en: "O-ring"
		}
		,A2V00001796357 : {
			de: "Treibradsatzwelle, lackiert",
			en: "motor axle, painted"
		}
		,A2V00001411973 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00200422492 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370085025 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00200324013 : {
			de: "Blech EN 10029 4X1500X4000 S355J2C+N",
			en: "Sheet EN 10029 4X1500X4000 S355J2C+N"
		}
		,A2V00200316126 : {
			de: "Blech EN 10029 15X2000X4000 S355J2C+N",
			en: "Sheet EN 10029 15X2000X4000 S355J2C+N"
		}
		,A2V00200300085 : {
			de: "Blech EN 10029  S460NL",
			en: "Sheet EN 10029  S460NL"
		}
		,A2V00002056900 : {
			de: "Blech EN 10051 2X1500X4000 S355J2C+N",
			en: "Sheet EN 10051 2X1500X4000 S355J2C+N"
		}
		,A2V00370046048 : {
			de: "FEDERTRAEGER M. 2 ROLLBANDFEDERN",
			en: "SPRINGHOLDER W. 2 SPRINGS"
		}
		,A2V00001696533 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002691426 : {
			de: "Befestigungslasche",
			en: "Fixing plate"
		}
		,A2V00002691473 : {
			de: "Querholm",
			en: "Transversal beam"
		}
		,A2V00002693733 : {
			de: "Stuetzholm wg",
			en: "Support beam ad"
		}
		,A2V00002693789 : {
			de: "Stuetzholm spg",
			en: "Support beam oad"
		}
		,A2V00002695299 : {
			de: "Holm",
			en: "Beam"
		}
		,A2V00002695781 : {
			de: "Holm",
			en: "Beam"
		}
		,A2V00002695784 : {
			de: "Versteifungsrippe T1",
			en: "Stiffening rib T1"
		}
		,A2V00002695888 : {
			de: "Versteifungsrippe T2",
			en: "Stiffening rib T2"
		}
		,A2V00002696012 : {
			de: "Tragblech Ueberspanngsableiter",
			en: "Mounting plate overvoltage arrester"
		}
		,A2V00002696014 : {
			de: "Halteblech",
			en: "Bracket"
		}
		,A2V00002696324 : {
			de: "Lasche Kuppelstange",
			en: "Link coupling rod"
		}
		,A2V00002696399 : {
			de: "Lasche Gummipuffer",
			en: "Link rubber stop"
		}
		,A2V00002698873 : {
			de: "Anschlusslasche Erdung",
			en: "Terminal link grounding"
		}
		,A2V00002699100 : {
			de: "Winkel",
			en: "Bracket"
		}
		,A2V00002699149 : {
			de: "Anschlussklotz",
			en: "Connector clump"
		}
		,A2V00002699288 : {
			de: "Winkel",
			en: "Bracket"
		}
		,A2V00002699457 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002700901 : {
			de: "Winkel",
			en: "Bracket"
		}
		,A2V00002528890 : {
			de: "Stiftschraube",
			en: "Stud bolt"
		}
		,A2V00002393832 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002554027 : {
			de: "Badsich. Tuer unten 3",
			en: "weld p.b. door bottom 3"
		}
		,A2V00002554028 : {
			de: "Badsich. Tuer unten 2",
			en: "weld p.b. door bottom 2"
		}
		,A2V00002554029 : {
			de: "Badsich. Tuer unten 1",
			en: "weld p.b. door bottom 1"
		}
		,A2V00002554046 : {
			de: "Radkasten WE1 sp.g.",
			en: "Wheel well CE1 i.m.i."
		}
		,A2V00002554337 : {
			de: "Daempfer primaer vertikal",
			en: "Primary vertical shock absorber"
		}
		,A2V00002554607 : {
			de: "Durchfuehrung",
			en: "Lead - through"
		}
		,A2V00002554790 : {
			de: "Seitenwandprofil, unteres",
			en: "Side wall profile, bottom"
		}
		,A2V00002554791 : {
			de: "Seitenwandprofil, unteres",
			en: "Side wall profile, bottom"
		}
		,A2V00002554792 : {
			de: "Seitenwandprofil, oberes",
			en: "Side wall profile, top"
		}
		,A2V00002554794 : {
			de: "Seitenwandprofil, unteres",
			en: "Side wall profile, bottom"
		}
		,A2V00002554795 : {
			de: "Seitenwandprofil, unteres",
			en: "Side wall profile, bottom"
		}
		,A2V00002554896 : {
			de: "Seitenwandprofil, oberes",
			en: "Side wall profile, top"
		}
		,A2V00002554898 : {
			de: "Seitenwandprofil, unteres",
			en: "Side wall profile, bottom"
		}
		,A2V00002554899 : {
			de: "Seitenwandprofil, unteres",
			en: "Side wall profile, bottom"
		}
		,A2V00002554900 : {
			de: "Seitenwandprofil, oberes",
			en: "Side wall profile, top"
		}
		,A2V00002555059 : {
			de: "Treibradsatzwelle, lackiert",
			en: "Motor wheelset axle, painted"
		}
		,A2V00002555108 : {
			de: "Badsich. Tuer unten 2",
			en: "weld p.b. door bottom 2"
		}
		,A2V00002555109 : {
			de: "Badsich. Tuer oben 2",
			en: "weld p. b. door upper 2"
		}
		,A2V00001403461 : {
			de: "Antriebsflansch (f. PA 103292)",
			en: "Input flange (f. PA 103292)"
		}
		,A2V00001744750 : {
			de: "Temperatursensor  Radlager",
			en: "Temperature sensor wheel bearing"
		}
		,A2V00002621099 : {
			de: "Bremsklotz Sinter",
			en: "Brake block Sinter"
		}
		,A2V00002555383 : {
			de: "Seitenwandprofil, unteres",
			en: "Side wall profile, bottom"
		}
		,A2V00002555384 : {
			de: "Seitenwandprofil, unteres",
			en: "Side wall profile, bottom"
		}
		,A2V00002555385 : {
			de: "Seitenwandprofil,oberes",
			en: "Side wall profile, top"
		}
		,A2V00002083984 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002696372 : {
			de: "Passschraube",
			en: "Fit bolt"
		}
		,A2V00002705805 : {
			de: "Distanzstueck",
			en: "Spacer"
		}
		,A2V00002706247 : {
			de: "Scheibe d50",
			en: "Washer d50"
		}
		,A2V00104648315 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002696486 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002705016 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00002696371 : {
			de: "Bolzen",
			en: "bolt"
		}
		,A2V00002555678 : {
			de: "Konsole geschweisst",
			en: "Console welded"
		}
		,A2V00002555703 : {
			de: "Befestigungswinkel 13",
			en: "Mounting angle 13"
		}
		,A2V00002555705 : {
			de: "Befestigungswinkel 09",
			en: "Mounting angle 09"
		}
		,A2V00002555758 : {
			de: "Befestigungswinkel 14",
			en: "Mounting angle 14"
		}
		,A2V00002408793 : {
			de: "Badsicherung w.g.",
			en: "weld pool backing as d."
		}
		,A2V00002408794 : {
			de: "Badsicherung sp.g.",
			en: "weld pool backing r.i."
		}
		,A2V00002408795 : {
			de: "Badsicherung sp.g.",
			en: "weld pool backing r.i."
		}
		,A2V00002409076 : {
			de: "Badsicherung w.g.",
			en: "weld pool backing as d."
		}
		,A2V00002556130 : {
			de: "Traeger geschweisst",
			en: "Carrier welded"
		}
		,A2V00002556994 : {
			de: "Nutenstein 2xM16",
			en: "slot nut 2xM16"
		}
		,A2V00002556995 : {
			de: "Nutenstein M16",
			en: "slot nut M16"
		}
		,A2V00002411658 : {
			de: "Tuersteuerung",
			en: "Door control unit"
		}
		,A2V00002557215 : {
			de: "Halter vollst",
			en: "Holder, compl."
		}
		,A2V00000519385 : {
			de: "RunddrahtDIN46431-0,5-E-CU58 F21-feuerSn",
			en: "Round wireD46431-0,5-E-CU58 F21-fireTin"
		}
		,A2V00397282999 : {
			de: "LAENGSLENKER GESAMT",
			en: "TRAILING LINK COMPLETE"
		}
		,A2V00397155309 : {
			de: "ZWISCHENSTUECK",
			en: "Adaptor"
		}
		,A2V00397216915 : {
			de: "Stift",
			en: "Bolt"
		}
		,A2V00001295422 : {
			de: "Spannstift",
			en: "spring pin"
		}
		,A2V00002557299 : {
			de: "Radsatzlager",
			en: "Axle box bearing"
		}
		,A2V00002557609 : {
			de: "Badsich. Verstaerkung",
			en: "weld p.b. reinforcement"
		}
		,A2V00002558738 : {
			de: "Bolzenblech komplett",
			en: "Sheet metal complete"
		}
		,A2V00002558739 : {
			de: "Bolzenblech komplett",
			en: "Sheet metal complete"
		}
		,A2V00002559248 : {
			de: "Anschlusstopf",
			en: "Connection pot"
		}
		,A2V00002274663 : {
			de: "Abdeckung E04, vollst.",
			en: "Covering E04, comp"
		}
		,A2V00002558499 : {
			de: "Bodenklappe 14 kompl.",
			en: "Floor hatch 14 completed"
		}
		,A2V00002558777 : {
			de: "Bodenklappe 20 kompl.",
			en: "Floor hatch 20 completed"
		}
		,A2V00002558780 : {
			de: "Bodenklappe 21 kompl.",
			en: "Floor hatch 21 completed"
		}
		,A2V00370043267 : {
			de: "Sechskantschraube",
			en: "Hexagon screw"
		}
		,A2V00370052684 : {
			de: "Schichtfeder",
			en: "layer spring"
		}
		,A2V00002419720 : {
			de: "Abdeckblech",
			en: "cover plate"
		}
		,A2V00002559748 : {
			de: "Druckschalter",
			en: "Pressure switch"
		}
		,A2V00002560083 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002560127 : {
			de: "Halter geschweisst",
			en: "Bracket welded"
		}
		,A2V00002560128 : {
			de: "Halter geschweisst",
			en: "Bracket welded"
		}
		,A2V00002560569 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002560682 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002560770 : {
			de: "Kabelrohr 01 vollst.",
			en: "Cable pipe 01 compl."
		}
		,A2V00002424816 : {
			de: "Steckerblech vollstaendig",
			en: "Plug sheet compl."
		}
		,A2V00002561254 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002561356 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002561357 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002271884 : {
			de: "Badsicherung",
			en: "weld pool backing"
		}
		,A2V00002675688 : {
			de: "Seitenklappe geschw.",
			en: "Side flap welded"
		}
		,A2V00002675696 : {
			de: "Seitenklappe geschw.",
			en: "Side flap welded"
		}
		,A2V00002597325 : {
			de: "Beilage 10mm",
			en: "shim 10mm"
		}
		,A2V00001701396 : {
			de: "ZPD43, ST,  Metall, 500mm",
			en: "ZPD43, ST, metal, 500mm"
		}
		,A2V00002561758 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002562569 : {
			de: "Tuersaeule ZSB, sp.g.",
			en: "Door post ASSY, i.m.i."
		}
		,A2V00002435639 : {
			de: "Gleitschutzventil GV221",
			en: "Anti skid valve  GV221"
		}
		,A2V00002435640 : {
			de: "Gleitschutzventil GV221",
			en: "Anti skid valve  GV221"
		}
		,A2V00002562625 : {
			de: "Ecksaeule ZSB, w.g.",
			en: "Corner post ASSY, a.d."
		}
		,A2V00002562703 : {
			de: "Tuersaeule ZSB, w.g.",
			en: "Door post ASSY, a.d."
		}
		,A2V00002562778 : {
			de: "Ecksaeule ZSB, sp.g.",
			en: "Corner post ASSY, i.m.i."
		}
		,A2V00002563190 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002563338 : {
			de: "Schweissbadsicherung",
			en: "Weld pool backing"
		}
		,A2V00002563349 : {
			de: "Gewindeplatte M20",
			en: "Thread plate M20"
		}
		,A2V00002563353 : {
			de: "Gewindeplatte M20",
			en: "Thread plate M20"
		}
		,A2V00002563398 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002563635 : {
			de: "Durchfuehrung geschw.",
			en: "Fairlead welded"
		}
		,A2V00002563779 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002564073 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002564268 : {
			de: "Profil fuer Arbeitsproben",
			en: "Profile for work samples"
		}
		,A2V00002446451 : {
			de: "E-Schrank ++RBA+14 komplett",
			en: "E-cabinet ++RBA+14 complete"
		}
		,A2V00002446452 : {
			de: "E-Schrank ++RBB+14 komplett",
			en: "E-cabinet ++RBB+14 complete"
		}
		,A2V00002446453 : {
			de: "E-Schrank ++RBA+15 komplett",
			en: "E-cabinet ++RBA+15 complete"
		}
		,A2V00002446454 : {
			de: "E-Schrank ++RBB+15 komplett",
			en: "E-cabinet ++RBB+15 complete"
		}
		,A2V00002446558 : {
			de: "Bremscontainer ++RBA+76.70 komplett",
			en: "Brake container ++RBA+76.70 complete"
		}
		,A2V00002447281 : {
			de: "Seitenwandfeld 1 links   EWA",
			en: "side wall module 1, left  EWA"
		}
		,A2V00002447285 : {
			de: "Seitenwandfeld 2 links   EWA",
			en: "side wall module 2, left  EWA"
		}
		,A2V00002447416 : {
			de: "Seitenwandfeld 3 links   EWA",
			en: "side wall module 3, left  EWA"
		}
		,A2V00002447422 : {
			de: "Seitenwandfeld 1 rechts   EWA",
			en: "side wall module 1 right  EWA"
		}
		,A2V00002447423 : {
			de: "Seitenwandfeld 2 rechts   EWA",
			en: "side wall module 2 right  EWA"
		}
		,A2V00002447424 : {
			de: "Seitenwandfeld 3 rechts   EWA",
			en: "side wall module 3 right  EWA"
		}
		,A2V00002447450 : {
			de: "Seitenwandfeld 1 links   MW",
			en: "side wall module 1, left   MW"
		}
		,A2V00002447451 : {
			de: "Seitenwandfeld 2 links   MW",
			en: "side wall module 2, left   MW"
		}
		,A2V00002447452 : {
			de: "Seitenwandfeld 3 links   MW",
			en: "side wall module 3, left   MW"
		}
		,A2V00002447454 : {
			de: "Seitenwandfeld 1 rechts   MW",
			en: "side wall module 1 right   MW"
		}
		,A2V00002447507 : {
			de: "Seitenwandfeld 2 rechts   MW",
			en: "side wall module 2 right   MW"
		}
		,A2V00002447511 : {
			de: "Seitenwandfeld 3 rechts   MW",
			en: "side wall module 3 right   MW"
		}
		,A2V00002186110 : {
			de: "Seitendecke mit Lautsprecher",
			en: "side ceiling with speaker"
		}
		,A2V00002447532 : {
			de: "Seitenwandfeld 1 links   EWB",
			en: "side wall module 1, left  EWB"
		}
		,A2V00002447533 : {
			de: "Seitenwandfeld 2 links   EWB",
			en: "side wall module 2, left  EWB"
		}
		,A2V00002447534 : {
			de: "Seitenwandfeld 3 links   EWB",
			en: "side wall module 3, left  EWB"
		}
		,A2V00002447646 : {
			de: "Seitenwandfeld 1 rechts   EWB",
			en: "side wall module 1 right  EWB"
		}
		,A2V00002447648 : {
			de: "Seitenwandfeld 2 rechts   EWB",
			en: "side wall module 2 right  EWB"
		}
		,A2V00002447649 : {
			de: "Seitenwandfeld 3 rechts   EWB",
			en: "side wall module 3 right  EWB"
		}
		,A2V00002564949 : {
			de: "Profil fuer Arbeitsproben",
			en: "Profile for work samples"
		}
		,A2V00002564951 : {
			de: "Profil fuer Arbeitsproben",
			en: "Profile for work samples"
		}
		,A2V00002449057 : {
			de: "Klimaunterbau 1",
			en: "substructure 1"
		}
		,A2V00002449058 : {
			de: "Klimaunterbau 2",
			en: "substructure 2"
		}
		,A2V00002449196 : {
			de: "Langtraeger vollst, links",
			en: "longitudinal girder comp., left"
		}
		,A2V00002565277 : {
			de: "UBNG1 Frontkupplung A",
			en: "UBNG1 Front coupler A"
		}
		,A2V00002565278 : {
			de: "UBNG1 Frontkupplung B",
			en: "UBNG1 Front coupler B"
		}
		,A2V00002565279 : {
			de: "UBNG1 ZAK E_KUP links",
			en: "UBNG1 ZAK E_KUP left"
		}
		,A2V00002565280 : {
			de: "UBNG1 ZAK E_KUP rechts",
			en: "UBNG1 ZAK E_KUP right"
		}
		,A2V00002565281 : {
			de: "UBNG1 Kurzkupplungshaelfte A mit SM",
			en: "UBNG1 Short coupler half B with MC"
		}
		,A2V00002565282 : {
			de: "UBNG1 Kurzkupplungshaelfte B ohne SM",
			en: "UBNG1 Short coupler half A without MC"
		}
		,A2V00002566029 : {
			de: "Querdaempferkonsole bearb.",
			en: "Cross buffer console mach."
		}
		,A2V00002451927 : {
			de: "Haltestange A, vst.",
			en: "Grab handle A, cpl."
		}
		,A2V00002453538 : {
			de: "Fahrerpult komplett",
			en: "Drivers desk complete"
		}
		,A2V00002453687 : {
			de: "Kabelhalter 1",
			en: "Cable bracket 1"
		}
		,A2V00002567106 : {
			de: "Klebschottwand 2, voll.",
			en: "partition wall 2, comp."
		}
		,A2V00002567108 : {
			de: "Klebschottwand 1, voll.",
			en: "partition wall 1, comp."
		}
		,A2V00002567657 : {
			de: "Abhebesicherung lackiert",
			en: "Lift lock painted"
		}
		,A2V00002457279 : {
			de: "Kombikuehlanlage",
			en: "Cooling unit"
		}
		,A2V00002567846 : {
			de: "Klimaplatte KKE ZSB",
			en: "Plate ACU SCE Assy."
		}
		,A2V00002568962 : {
			de: "Ganzmetalldaempfer",
			en: "All-metal mount"
		}
		,A2V00002569628 : {
			de: "Endsaeule w. g. vollst.",
			en: "end pillar as d. compl."
		}
		,A2V00002569632 : {
			de: "Endsaeule sp.g. vollst.",
			en: "end pillar r. i. compl."
		}
		,A2V00002569860 : {
			de: "Adapter vollst. rechts",
			en: "adapter complete right"
		}
		,A2V00002569861 : {
			de: "Adapter vollst. links",
			en: "adapter complete left"
		}
		,A2V00002570089 : {
			de: "Mittel-LT ZSB WE1",
			en: "Sole-bar ASSY CE1"
		}
		,A2V00002570170 : {
			de: "Bodenabschlussprofil",
			en: "Floor end beam"
		}
		,A2V00002459645 : {
			de: "Automatische Kupplung, kpl.",
			en: "Automatic coupler, cpl. TYPE SD10"
		}
		,A2V00002459667 : {
			de: "Untergurt bearbeitet",
			en: "bottom flange machined"
		}
		,A2V00002570250 : {
			de: "Winkel",
			en: "bracket"
		}
		,A2V00002150466 : {
			de: "Radbremszange",
			en: "Brake disc caliper"
		}
		,A2V00002570540 : {
			de: "Kupplungskasten WE1",
			en: "Coupling box CE1"
		}
		,A2V00002460796 : {
			de: "Abfallbehaelter EW, vollst.",
			en: "Waste Bin EC, compl."
		}
		,A2V00002460809 : {
			de: "Schweissbadsicherung 2 w.g.",
			en: "welding pool backing 2"
		}
		,A2V00002460811 : {
			de: "Schweissbadsicherung 1 w.g.",
			en: "welding pool backing 1"
		}
		,A2V00002460812 : {
			de: "Schweissbadsicherung 1 sp.g",
			en: "welding pool backing 1"
		}
		,A2V00002460813 : {
			de: "Schweissbadsicherung 2 sp.g",
			en: "welding pool backing 2"
		}
		,A2V00002710048 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002710107 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002710108 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002710109 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002710110 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002710111 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002710112 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002710113 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002710025 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002710079 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002710176 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002710177 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002566690 : {
			de: "Luftkanal, vollst.",
			en: "Air guide, compl."
		}
		,A2V00002506586 : {
			de: "Querprofil 2, Boden KK-Ende",
			en: "cross profile 2, floor plate, SC-end"
		}
		,A2V00002506345 : {
			de: "Querprofil 3, Boden KK-Ende",
			en: "cross profile 3, floor plate, SC-end"
		}
		,A2V00002506344 : {
			de: "Querprofil 2, Boden KK-Ende",
			en: "cross profile 2, floor plate, SC-end"
		}
		,A2V00002506343 : {
			de: "Querprofil 1, Boden KK-Ende",
			en: "cross profile 1, floor plate, SC-end"
		}
		,A2V00002496990 : {
			de: "Langtr. Profil KK-E, unten 2, MW",
			en: "Longit. beam CC-E, bottom 2, MC"
		}
		,A2V00002469204 : {
			de: "Langtr. Profil KK-E, unten 1, MW",
			en: "Longit. beam CC-E, bottom 1, MC"
		}
		,A2V00002469203 : {
			de: "Langtr. Profil KK-E, oben, MW",
			en: "Longit. beam SC-E, middle, MC"
		}
		,A2V00002465016 : {
			de: "Langtr. Profil Mitte, unten, MW",
			en: "Longit. beam center, bottom, MC"
		}
		,A2V00002464885 : {
			de: "Langtr. Profil Mitte, oben, MW",
			en: "Longit. beam center, upper, MC"
		}
		,A2V00002516685 : {
			de: "Profil Tuersaeule Niederflur MW",
			en: "profile door pillar low-floor mc"
		}
		,A2V00002570678 : {
			de: "Klimaplatte Mitte ZSB",
			en: "Plate ACU middle Assy."
		}
		,A2V00002570679 : {
			de: "Klimawanne FSTE ZSB",
			en: "ACU pan CE Assy."
		}
		,A2V00002571057 : {
			de: "Frontmaske, vollst., grundiert",
			en: "Front mask, compl., primered"
		}
		,A2V00002571077 : {
			de: "Beilage Luftfeder 5mm",
			en: "Shim air spring 5mm"
		}
		,A2V00002571078 : {
			de: "Beilage Luftfeder",
			en: "Shim air spring"
		}
		,A2V00002571079 : {
			de: "Beilage Luftfeder 10mm",
			en: "Shim air spring 10mm"
		}
		,A2V00002571080 : {
			de: "Beilage Luftfeder",
			en: "Shim air spring"
		}
		,A2V00002571081 : {
			de: "Fuehrerstandsaeule ZSB, sp.g.",
			en: "Cab post ASSY, i.m.i."
		}
		,A2V00002571082 : {
			de: "Tuersaeule ZSB, w.g.",
			en: "Door pillar ASSY, a.d."
		}
		,A2V00002571291 : {
			de: "Luftkanal, vollst.",
			en: "Air guide, compl."
		}
		,A2V00002571825 : {
			de: "Luftkanal, vollst.",
			en: "Air guide, compl."
		}
		,A2V00002572518 : {
			de: "Dachlangtraegerprofil oben",
			en: "Roof long bracket profile top"
		}
		,A2V00002572560 : {
			de: "Dachlangtraegerprofil unten",
			en: "Roof long bracket profile bottom"
		}
		,A2V00002572762 : {
			de: "Tuersaeule ZSB, sp.g.",
			en: "Door pillar ASSY, i.m.i."
		}
		,A2V00002572787 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002573010 : {
			de: "Fuehrerstandsaeule ZSB, w.g.",
			en: "Cab post ASSY, a.d."
		}
		,A2V00002464345 : {
			de: "Profil Endsaeule, Kupplung",
			en: "end profile, coupler"
		}
		,A2V00002573175 : {
			de: "Bodenprofil, aeusseres",
			en: "Floor profile, outside"
		}
		,A2V00002573233 : {
			de: "Bodenprofil, aeusseres",
			en: "Floor profile, outside"
		}
		,A2V00002573235 : {
			de: "Bodenblechprofil 2",
			en: "Floor plate profile 2"
		}
		,A2V00002573246 : {
			de: "Bodenprofil 2, mittleres",
			en: "Profile floor 2, middle"
		}
		,A2V00002573247 : {
			de: "Bodenprofil 1, mittleres",
			en: "Floor Profile 1, middle"
		}
		,A2V00002573248 : {
			de: "Langtraegerprofil f. Untergestell oberer",
			en: "Longitudinal profile f. underframe upper"
		}
		,A2V00002573249 : {
			de: "Langtraegerprofil f. Untergestell unten",
			en: "Longitudinal profile f. underfr. bottom"
		}
		,A2V00002464974 : {
			de: "Dachprofil 5",
			en: "roof profile 5"
		}
		,A2V00002465009 : {
			de: "Dachprofil aussen",
			en: "roof profile outside"
		}
		,A2V00002465011 : {
			de: "Dachprofil innen",
			en: "roof profile inside"
		}
		,A2V00002465013 : {
			de: "Dachprofil Mitte",
			en: "roof profile centre"
		}
		,A2V00002573307 : {
			de: "Bodenblechprofil 2",
			en: "Floor plate profile 2"
		}
		,A2V00002573310 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002465813 : {
			de: "Luftfuehrung",
			en: "Air duct"
		}
		,A2V00002573566 : {
			de: "Deckblech sp.g.",
			en: "Cover plate i.m.i."
		}
		,A2V00002466125 : {
			de: "Einholmstromabnehmer 1",
			en: "pantograph 420.011 1950 mm (D)"
		}
		,A2V00002558313 : {
			de: "Bodenklappe 11 kompl.",
			en: "Floor hatch 11 completed"
		}
		,A2V00002534186 : {
			de: "U-Kappe",
			en: "u-cap"
		}
		,A2V00002572204 : {
			de: "Abhebeplatte lackiert",
			en: "Lifting plate painted"
		}
		,A2V00002571708 : {
			de: "Langtraeger links vollst.",
			en: "longitudinal girder left, complete"
		}
		,A2V00002571956 : {
			de: "Langtraeger rechts vollst.",
			en: "longitudinal girder right, complete"
		}
		,A2V00002557386 : {
			de: "Bodenklappe 12 kompl.",
			en: "Floor hatch 12 completed"
		}
		,A2V00001798901 : {
			de: "Blindfeld 05      vollstaendig",
			en: "Blind field 05    complete"
		}
		,A2V00001805655 : {
			de: "Seitendecke 05    vollst.",
			en: "sideceiling 05    compl."
		}
		,A2V00001805690 : {
			de: "Seitendecke 07    vollst.",
			en: "sideceiling 07    compl."
		}
		,A2V00001891290 : {
			de: "Seitendecke 28    vollst.",
			en: "sideceiling 28    compl."
		}
		,A2V00001919719 : {
			de: "Lochdecke L17 kpl.",
			en: "perforated cover L17 complete"
		}
		,A2V00002424138 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002424216 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002709820 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002709851 : {
			de: "Dachschuerze kpl.",
			en: "Roof skirt cpl."
		}
		,A2V00002709858 : {
			de: "Eckprofil",
			en: "rib"
		}
		,A2V00002516684 : {
			de: "Profil Tuersaeule Hochflur MW",
			en: "profile door pillar hight-floor mc"
		}
		,A2V00002714891 : {
			de: "Verschlussblech",
			en: "cover plate"
		}
		,A2V00002725345 : {
			de: "Verschlussblech",
			en: "cover plate"
		}
		,A2V00002466427 : {
			de: "Einholmstromabnehmer 2",
			en: "single arm pantograph 420.012 1950mm (D)"
		}
		,A2V00002466453 : {
			de: "Profil Hauptquertraeger",
			en: "profile main cross girder outside"
		}
		,A2V00002467944 : {
			de: "Abdeckung",
			en: "cover"
		}
		,A2V00002469205 : {
			de: "Langtr. Profil Uebergang, unten, MW",
			en: "Longit. beam joint, bottom, MC"
		}
		,A2V00002469829 : {
			de: "Transformator",
			en: "Transformer"
		}
		,A2V00002470000 : {
			de: "Batteriesystem",
			en: "Battery system"
		}
		,A2V00002575656 : {
			de: "Schweissbadsicherung",
			en: "Weldbacking"
		}
		,A2V00002575725 : {
			de: "Badsich. 3 Verstaerkung",
			en: "weld p.b. 3 reinforcement"
		}
		,A2V00002470254 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002470255 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002470346 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002470429 : {
			de: "Frontmaske 3-farbig",
			en: "Front cabin tricolor"
		}
		,A2V00002470430 : {
			de: "Frontmaske 2-farbig",
			en: "Front cabin bicolor"
		}
		,A2V00002470431 : {
			de: "Kleb-Muster grundiert GFK",
			en: "paste pattern primed GRP"
		}
		,A2V00002575976 : {
			de: "Badsich. 2 Verstaerkung",
			en: "weld p.b. 2 reinforcement"
		}
		,A2V00002575977 : {
			de: "Badsich. 1 Blech Tuer",
			en: "weld p.b. 1 plate door"
		}
		,A2V00002575978 : {
			de: "Badsich. 2 Blech Tuer",
			en: "weld p.b. 2 plate door"
		}
		,A2V00002495647 : {
			de: "Unterlegplatte",
			en: "Shim"
		}
		,A2V00002576196 : {
			de: "Langtraegerprofil f. Untergestell oberer",
			en: "Longitudinal profile f. underframe upper"
		}
		,A2V00002576197 : {
			de: "Langtraegerprofil f. Untergestell unten",
			en: "Longitudinal profile f. underfr. bottom"
		}
		,A2V00002576201 : {
			de: "Dachlangtraegerprofil oben",
			en: "Roof long bracket profile top"
		}
		,A2V00002576202 : {
			de: "Dachlangtraegerprofil unten",
			en: "Roof long bracket profile bottom"
		}
		,A2V00002576785 : {
			de: "Badsich. 4 Verstaerkung",
			en: "weld p.b. 4 reinforcement"
		}
		,A2V00002496403 : {
			de: "Stromabnehmer",
			en: "Pantograph"
		}
		,A2V00002577086 : {
			de: "Schweissbadsicherung",
			en: "Weldbacking"
		}
		,A2V00002496619 : {
			de: "Hauptschalter",
			en: "AC-circuit breaker"
		}
		,A2V00002496620 : {
			de: "Netzstromwandler Energie",
			en: "Current Transducer at Lin"
		}
		,A2V00002497289 : {
			de: "Querprofil 1, Kupplungskasten KK-E",
			en: "cross profile 1, coupler box SC-E"
		}
		,A2V00002497290 : {
			de: "Querprofil 2, Kupplungskasten KK-E",
			en: "cross profile 2, coupler box SC-E"
		}
		,A2V00002497380 : {
			de: "Triebdrehgestell vollstaendig WE1",
			en: "Motorbogie complete CE1"
		}
		,A2V00002497381 : {
			de: "Triebdrehgestell vollstaendig WE2",
			en: "Motorbogie complete CE2"
		}
		,A2V00002498465 : {
			de: "Blech w.g.",
			en: "sheet as.d."
		}
		,A2V00002498501 : {
			de: "Wechselrahmen",
			en: "Change framework"
		}
		,A2V00002498553 : {
			de: "Blech sp.g.",
			en: "sheet re.i."
		}
		,A2V00002498561 : {
			de: "Blech w.g.",
			en: "sheet as.d."
		}
		,A2V00002498575 : {
			de: "Blech sp.g.",
			en: "sheet re.i."
		}
		,A2V00002578187 : {
			de: "Anschlaghebel/SWT RAL3000",
			en: "Guiding lever/WP RAL3000"
		}
		,A2V00002781615 : {
			de: "Abdeckblech",
			en: "cover sheet"
		}
		,A2V00002781751 : {
			de: "Deckel",
			en: "cover"
		}
		,A2V00002503556 : {
			de: "TDG vollst. TW2.2-R4P-ZUB WE2",
			en: "MB compl. TW2.2-R4P-ZUB CE2"
		}
		,A2V00001904491 : {
			de: "Antennenhalter lack. WG",
			en: "Antenna beam paint. AD"
		}
		,A2V00001894326 : {
			de: "Antennentraeger lack.",
			en: "Antenna Beam Paint."
		}
		,A2V00001904490 : {
			de: "Antennenhalter lack. SPG",
			en: "Antenna beam paint. OAD"
		}
		,A2V00001995195 : {
			de: "Treibradsatzwelle, lackiert",
			en: "motor wheelset axle painted 214-150-0-65"
		}
		,A2V00397203470 : {
			de: "Motorkonsole",
			en: "motor  bracket"
		}
		,A2V00397203512 : {
			de: "Motorkonsole, aussen",
			en: "motor  bracket, outside"
		}
		,A2V00397157288 : {
			de: "GETRIEBELAGERBOCK",
			en: "gear bearing block"
		}
		,A2V00001899528 : {
			de: "Konsole, lackiert",
			en: "Bracket, painted"
		}
		,A2V00002066438 : {
			de: "Notfangstab, lackiert",
			en: "Emergency slat, painted"
		}
		,A2V00001946519 : {
			de: "Treibradsatzwelle, lackiert",
			en: "Motor axle, lacquered"
		}
		,A2V00002466329 : {
			de: "Querprofil 2, Zwischendecke",
			en: "cross profile 2, intermediate ceiling"
		}
		,A2V00002466328 : {
			de: "Querprofil 1, Zwischendecke",
			en: "cross profile 1, intermediate floor"
		}
		,A2V00002466327 : {
			de: "Profil 4, Zwischendecke",
			en: "profile 4, intermediate floor"
		}
		,A2V00002466326 : {
			de: "Profil 3, Zwischendecke",
			en: "profile 3, intermediate floor"
		}
		,A2V00002465815 : {
			de: "Profil 2, Zwischendecke",
			en: "profile 2, intermediate floor"
		}
		,A2V00002465814 : {
			de: "Profil 1, Zwischendecke",
			en: "profile 1, intermediate ceiling"
		}
		,A2V00002228831 : {
			de: "Stiftschraube",
			en: "stud"
		}
		,A2V00002581017 : {
			de: "Ringleitungsrohr 04 geschw.",
			en: "Circleline pipe 04 welded"
		}
		,A2V00002581018 : {
			de: "Ringleitungsrohr 02 geschw.",
			en: "Circleline pipe 02 welded"
		}
		,A2V00002507505 : {
			de: "KKG FGR",
			en: "HVAC unit saloon"
		}
		,A2V00002507738 : {
			de: "KKG FST",
			en: "Air conditioning unit cab"
		}
		,A2V00002510141 : {
			de: "Halter geschweisst",
			en: "Holder welded"
		}
		,A2V00002582752 : {
			de: "Kabeldom geschw.",
			en: "Cable housing welded"
		}
		,A2V00002583641 : {
			de: "Bodenblech Kopf",
			en: "Floorsheet head"
		}
		,A2V00002514367 : {
			de: "Fahrersitz",
			en: "drivers seat"
		}
		,A2V00002583970 : {
			de: "Knochenbuchse",
			en: "Sphaerbloc"
		}
		,A2V00002584193 : {
			de: "Trittplatte 2, gsw.",
			en: "Kickplate 2, welded"
		}
		,A2V00001938352 : {
			de: "Lagerdeckel lackiert",
			en: "Bearing cap painted"
		}
		,A2V00002001258 : {
			de: "Adapter mech. spg.",
			en: "Adapter mech. o.a.d."
		}
		,A2V00002215569 : {
			de: "Gewindeleiste lackiert",
			en: "Thread plate painted"
		}
		,A2V00002461628 : {
			de: "CS Radreifen V60 LK 600 S5T2",
			en: "CS wheel rim V60 LK 600 S5T2"
		}
		,A2V00001949221 : {
			de: "Schienenraeumerblech, lackiert sp.g.",
			en: "Plate rail guard, painted o.a.d."
		}
		,A2V00001893767 : {
			de: "Schienenraeumer lackiert   w.g.",
			en: "Track sweeper  varnished a.d."
		}
		,A2V00002047818 : {
			de: "Halbschale kurz",
			en: "Half-shell short"
		}
		,A2V00002006679 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002022436 : {
			de: "Bremsbefestigung",
			en: "Brake mounting"
		}
		,A2V00002064568 : {
			de: "Rippe Wankkonsole TDG",
			en: "Rip antirollbar bracket MB"
		}
		,A2V00002031245 : {
			de: "Halbschale lang",
			en: "Half-shell long"
		}
		,A2V00002031300 : {
			de: "Halbschale kurz",
			en: "Half-shell short"
		}
		,A2V00002047819 : {
			de: "Halbschale lang",
			en: "Half-shell long"
		}
		,A2V00002078147 : {
			de: "Hauptklemmenkasteneinheit",
			en: "Main junction box assembly"
		}
		,A2V00002032589 : {
			de: "Klotzbremse rechts",
			en: "Tread brake assembly right"
		}
		,A2V00002044908 : {
			de: "Klotzbremse links",
			en: "Tread brake assembly left"
		}
		,A2V00001796358 : {
			de: "Vollrad, lackiert",
			en: "solid wheel, painted"
		}
		,A2V00002006676 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002006677 : {
			de: "Obergurt",
			en: "Upper Flange"
		}
		,A2V00002006678 : {
			de: "Steg Aussen",
			en: "Web outside"
		}
		,A2V00002006680 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002006683 : {
			de: "Steg Innen",
			en: "Web inside"
		}
		,A2V00002006684 : {
			de: "Schottblech",
			en: "Stiffening plate"
		}
		,A2V00002021589 : {
			de: "Bremskonsole, innen",
			en: "Brake bracket, inner"
		}
		,A2V00002021591 : {
			de: "Bremskonsole, aussen",
			en: "Brake bracket, outer"
		}
		,A2V00002021688 : {
			de: "Rippe 2 w.g.",
			en: "Rib 2 a.d."
		}
		,A2V00002021823 : {
			de: "Primaeranschlag",
			en: "Primary stop"
		}
		,A2V00002022544 : {
			de: "Untergurt, TDG",
			en: "Lower flange, MBF"
		}
		,A2V00002022546 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00002022724 : {
			de: "Steg, aussen",
			en: "Web, outside"
		}
		,A2V00002023014 : {
			de: "Steg, innen",
			en: "Web, inside"
		}
		,A2V00002023581 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002023600 : {
			de: "Steg SPG",
			en: "Web OAD"
		}
		,A2V00002023601 : {
			de: "Steg WG",
			en: "Web AD"
		}
		,A2V00002023603 : {
			de: "Befestigung f. Daempfer",
			en: "Mounting f. damper"
		}
		,A2V00002023775 : {
			de: "Obergurt, TDG",
			en: "Upper flange, MBF"
		}
		,A2V00002062889 : {
			de: "Wankkonsole TDG WG",
			en: "Antirollbar bracket MB AD"
		}
		,A2V00002062890 : {
			de: "Wankkonsole TDG SPG",
			en: "Antirollbar bracket MB OAD"
		}
		,A2V00002063558 : {
			de: "Steg WG",
			en: "Web AD"
		}
		,A2V00002063631 : {
			de: "Rippe WG",
			en: "Rib AD"
		}
		,A2V00002063634 : {
			de: "Drehkonsole SPG",
			en: "Bracket OAD"
		}
		,A2V00002063646 : {
			de: "Steg SPG",
			en: "Web OAD"
		}
		,A2V00002063647 : {
			de: "Obergurt SPG",
			en: "Upper flange AD"
		}
		,A2V00002063650 : {
			de: "Obergurt WG",
			en: "Upper flange AD"
		}
		,A2V00002063652 : {
			de: "Drehkonsole WG",
			en: "Bracket AD"
		}
		,A2V00002063654 : {
			de: "Rippe SPG",
			en: "Rib OAD"
		}
		,A2V00002063656 : {
			de: "Block",
			en: "Block"
		}
		,A2V00002065642 : {
			de: "Badsicherung unten TDG",
			en: "Pool backing bottom MB"
		}
		,A2V00002065643 : {
			de: "Badsicherung oben TDG",
			en: "Pool backing top MB"
		}
		,A2V00002065646 : {
			de: "Halter Box",
			en: "Holder box"
		}
		,A2V00002065647 : {
			de: "Deckel TDG",
			en: "Cover MB"
		}
		,A2V00002085555 : {
			de: "Schweissbadsicherung",
			en: "Pool backing"
		}
		,A2V00002089081 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002089925 : {
			de: "Notfang Getriebe",
			en: "Safety catch gear box"
		}
		,A2V00002102368 : {
			de: "Klemmenkasten 101608 TDG2, gebohrt",
			en: "Terminal box 101608 MB2, drilled"
		}
		,A2V00002547287 : {
			de: "Verteilkasten HK BT bearb. und besch.",
			en: "Connection box HK BT machined and paint"
		}
		,A2V00397147204 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00397147205 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002517612 : {
			de: "Tuerfluegel vollst. links",
			en: "Door panel compl. left"
		}
		,A2V00002517613 : {
			de: "Tuerfluegel vollst. rechts",
			en: "Door panel compl. right"
		}
		,A2V00002517991 : {
			de: "Druckbehaelter Stahl 25L D=256",
			en: "air receiver steel 25L D=256"
		}
		,A2V00002518534 : {
			de: "Abdeckblech",
			en: "cover plate"
		}
		,A2V00002518883 : {
			de: "Abdeckblech w.g.",
			en: "cover plate as d."
		}
		,A2V00002518884 : {
			de: "Abdeckblech sp. g.",
			en: "cover plate r. i."
		}
		,A2V00002585139 : {
			de: "Dachplatte aussen KW w.g.",
			en: "Roof plate, outer KW a.d."
		}
		,A2V00002585280 : {
			de: "Dachplatte aussen KW sp.g.",
			en: "Roof plate, outer KW i.m.i."
		}
		,A2V00002518923 : {
			de: "Schiebetritt, vollst.",
			en: "Sliding step, compl."
		}
		,A2V00002585386 : {
			de: "Dachplatte KKE KW w.g.",
			en: "Roof plate SCE DC a.d."
		}
		,A2V00002585387 : {
			de: "Dachplatte KKE KW sp.g.",
			en: "Roof plate SCE DC i.m.i."
		}
		,A2V00002585410 : {
			de: "Dachplatte Mitte KKE KW",
			en: "Roof plate, middle SCE DC"
		}
		,A2V00002585417 : {
			de: "Dachplatte Mitte FST KW",
			en: "Roof plate, middle CE DC"
		}
		,A2V00002585635 : {
			de: "Dachplatte innen FST KW sp.g.",
			en: "Roof plate, inner CE DC i.m.i."
		}
		,A2V00002585726 : {
			de: "Dachplatte innen FST KW w.g.",
			en: "Roof plate, inner CE DC a.d."
		}
		,A2V00002585827 : {
			de: "Pantoleitungsrohr 02 geschw.",
			en: "Pantoline pipe 02 welded"
		}
		,A2V00002585829 : {
			de: "Pantoleitungsrohr 01 geschw.",
			en: "Pantoline pipe 01 welded"
		}
		,A2V00002585883 : {
			de: "Dachplatte innen MW sp.g.",
			en: "Roof plate, inner MW i.m.i."
		}
		,A2V00002585884 : {
			de: "Dachplatte innen MW w.g.",
			en: "Roof plate, inner MW a.d."
		}
		,A2V00002585906 : {
			de: "Dachplatte aussen MW sp.g.",
			en: "Roof plate outer MW i.m.i."
		}
		,A2V00002585907 : {
			de: "Dachplatte aussen MW w.g.",
			en: "Roof plate outer MW a.d."
		}
		,A2V00002585923 : {
			de: "Dachplatte Mitte MW",
			en: "Roof plate middle MW"
		}
		,A2V00002586555 : {
			de: "Wannenrahmen FSTE ZSB w.g.",
			en: "Panframe CE Assy. a.d."
		}
		,A2V00002586561 : {
			de: "Wannenrahmen ZSB",
			en: "Panframe Assy."
		}
		,A2V00002586697 : {
			de: "Schweissbadsicherung sp.g.",
			en: "Weld backing i.m.i."
		}
		,A2V00002586698 : {
			de: "Schweissbadsicherung w.g.",
			en: "Weld backing a.d."
		}
		,A2V00002586716 : {
			de: "Wannenrahmen FSTE ZSB sp.g.",
			en: "Panframe CE Assy. i.m.i."
		}
		,A2V00002586921 : {
			de: "Stirnwand ZSB f. Klimawanne FGR",
			en: "End wall Assy. f. ACU pan PA"
		}
		,A2V00001979025 : {
			de: "Antenntraeger Rohr 1 lack",
			en: "Antenna pipe 1 painted"
		}
		,A2V00001949696 : {
			de: "Antenne Rohr 2 lackiert",
			en: "antenna pipe 2 painted"
		}
		,A2V00002373715 : {
			de: "Vollrad, lackiert 920/830-19-214",
			en: "solid wheel, varnished 920/830-19-214"
		}
		,A2V00002336757 : {
			de: "Laufradsatz vollst.",
			en: "Trailer wheelset compl."
		}
		,A2V00001867595 : {
			de: "Huelse",
			en: "compression piece"
		}
		,A2V00002216156 : {
			de: "Kegelhuelse 24",
			en: "taper bush 24"
		}
		,A2V00002216260 : {
			de: "Huelse 16",
			en: "bush 16"
		}
		,A2V00002140821 : {
			de: "Radsatzlagergehaeuse lh. vollst.",
			en: "WS box lh. complete"
		}
		,A2V00002140836 : {
			de: "Radsatzlagergehaeuse rh. vollst.",
			en: "WS box rh. complete"
		}
		,A2V00397275344 : {
			de: "Anschlagbuegel lackiert SP.G.",
			en: "Stop hanger varnished O.A.D"
		}
		,A2V00397275345 : {
			de: "Anschlagbuegel lackiert W.G.",
			en: "Stop hanger varnished A.D"
		}
		,A2V00001934442 : {
			de: "Antenne Integra kpl.",
			en: "Antenna Integra cpl."
		}
		,A2V00002555164 : {
			de: "Zahnplatte lackiert",
			en: "Tooth plate varnished"
		}
		,A2V00001933498 : {
			de: "Antenne SHP kompl.",
			en: "Antenna SHP cpl."
		}
		,A2V00002038270 : {
			de: "Wanklagerbock Unterteil, lackiert",
			en: "Anti roll bar bracket lower housing, laq"
		}
		,A2V00001893768 : {
			de: "Schienenraeumer lackiert  sp.g.",
			en: "Track sweeper varnished o.a.d."
		}
		,A2V00001863198 : {
			de: "Indusikonsole lack. WG",
			en: "Indusi bracket paint. AD"
		}
		,A2V00001863199 : {
			de: "Indusikonsole lack. SPG",
			en: "Indusi bracket paint. OAD"
		}
		,A2V00001898720 : {
			de: "Konsole 1 lack.",
			en: "Bracket 1 paint."
		}
		,A2V00001898722 : {
			de: "Konsole 2 Indusi lack.",
			en: "Bracket 2 Indusi paint."
		}
		,A2V00001828864 : {
			de: "Antriebsflansch lackiert",
			en: "driving flange painted"
		}
		,A2V00001896119 : {
			de: "Schienenraeumerblech lackiert w.g.",
			en: "rail guard plate painted a.d."
		}
		,A2V00001899873 : {
			de: "Konsole, lackiert",
			en: "bracket, painted"
		}
		,A2V00002335490 : {
			de: "Stellschraube",
			en: "adjusting screw"
		}
		,A2V00002572384 : {
			de: "Getriebe Metro Bangkok",
			en: "Gearbox Metro Bangkok"
		}
		,A2V00002712769 : {
			de: "Wegimpulsgeber optisch",
			en: "Pulse generator optical"
		}
		,A2V00002577198 : {
			de: "Halter Gleitschutzkabel",
			en: "holder slip sensor cable"
		}
		,A2V00001896117 : {
			de: "Schienenraeumer lackiert  w.g.",
			en: "rail guard  painted a.d."
		}
		,A2V00001896118 : {
			de: "Schienenraeumer lackiert  sp.g.",
			en: "rail guard painted o.a.d."
		}
		,A2V00002379386 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002470122 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002587195 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002587264 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002587274 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002587347 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002587351 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002587366 : {
			de: "Nutenstein",
			en: "Groove stone"
		}
		,A2V00002587831 : {
			de: "Halter, gsw.",
			en: "Holder, welded"
		}
		,A2V00002588185 : {
			de: "Stirnwand ZSB f. Klimawanne FST",
			en: "End wall Assy. f. ACU pan CE"
		}
		,A2V00002588306 : {
			de: "Kupplungskasten WE2",
			en: "Coupling-box CE2"
		}
		,A2V00002527895 : {
			de: "Z-Winkel",
			en: "Z-angle"
		}
		,A2V00002588680 : {
			de: "Dachquertraeger KKE ZSB",
			en: "Roof cross beam SCE Assy."
		}
		,A2V00002588880 : {
			de: "Stirnwand ZSB f. Klimawanne KKE",
			en: "End wall Assy. f. ACU pan SCE"
		}
		,A2V00002588951 : {
			de: "Tuerecke sp.g.",
			en: "Door corner i.m.i."
		}
		,A2V00002588952 : {
			de: "Tuerecke w.g.",
			en: "Door corner a.d."
		}
		,A2V00002588978 : {
			de: "Einzugslangtraeger w.g.",
			en: "Short beam a.d."
		}
		,A2V00002588979 : {
			de: "Einzugslangtraeger sp.g.",
			en: "Short beam i.m.i."
		}
		,A2V00002529463 : {
			de: "Behaeltermodul",
			en: "Tank unit,Assembly"
		}
		,A2V00002589208 : {
			de: "Horn w.g.",
			en: "Horn a.d."
		}
		,A2V00002589209 : {
			de: "Horn sp.g.",
			en: "Horn i.m.i."
		}
		,A2V00002589225 : {
			de: "Erdungsleitungsrohr 01 geschw.",
			en: "Grounding line pipe 01 weld."
		}
		,A2V00002630464 : {
			de: "Bodenblechauflage 2, sp.g.",
			en: "Floor plate support 2, i.m.i."
		}
		,A2V00002589307 : {
			de: "Pufferbrust bearb.",
			en: "Bufferplate milled"
		}
		,A2V00002589358 : {
			de: "Tasche WE2",
			en: "Bin CE2"
		}
		,A2V00002529929 : {
			de: "E-Box Trainserver ++RBA+67.12 komplett",
			en: "E-Box Trainserver ++RBA+67.12 complete"
		}
		,A2V00002589376 : {
			de: "Erdungsleitungsrohr 02 geschw.",
			en: "Grounding line pipe 02 weld."
		}
		,A2V00002589377 : {
			de: "Erdungsleitungsrohr 03 geschw.",
			en: "Grounding line pipe 03 weld."
		}
		,A2V00002589454 : {
			de: "Quertraeger, ZSB",
			en: "Cross beam, assy."
		}
		,A2V00002589550 : {
			de: "Dachquertraeger KKE ZSB",
			en: "Roof cross beam SCE Assy."
		}
		,A2V00002590310 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002590341 : {
			de: "Beilage WK lackiert",
			en: "Washer WK painted"
		}
		,A2V00002590821 : {
			de: "Flachfederwippe 1950/AC",
			en: "Collector head 1950/AC"
		}
		,A2V00002590827 : {
			de: "Trittplatte Halter 1,gsw.",
			en: "Kickplate holder 1,wld."
		}
		,A2V00002590893 : {
			de: "Trittplatte Halter 2,gsw.",
			en: "Kickplate holder 2,wld."
		}
		,A2V00002590922 : {
			de: "Trittplatte Halter 3,gsw.",
			en: "Kickplate holder 3,wld."
		}
		,A2V00002591166 : {
			de: "Profil",
			en: "Profile"
		}
		,A2V00002591167 : {
			de: "Profil",
			en: "Profile"
		}
		,A2V00002533929 : {
			de: "Langtr. Profil F-Ende",
			en: "Longit. beam"
		}
		,A2V00002533931 : {
			de: "Langtr. Profil F-Ende",
			en: "Longit. beam"
		}
		,A2V00002591364 : {
			de: "Schweissbadsicherung DLT, obere, w.g.",
			en: "Weld backing RC, upper, a.d."
		}
		,A2V00002591425 : {
			de: "Schweissbadsicherung DLT, obere, sp.g.",
			en: "Weld backing RC, upper, i.m.i."
		}
		,A2V00002591438 : {
			de: "Schweissbadsicherung WE1, obere",
			en: "Weld backing CE1, upper"
		}
		,A2V00002417061 : {
			de: "Anschlagkonsole lack. WG",
			en: "stop bracket painted AD"
		}
		,A2V00002417062 : {
			de: "Anschlagkonsole lack. SPG",
			en: "stop bracket painted OAD"
		}
		,A2V00001217184 : {
			de: "FEDERTELLER UNTEN",
			en: "spring plate below"
		}
		,A2V00001906501 : {
			de: "Halter lack.",
			en: "Bracket paint."
		}
		,A2V00002345095 : {
			de: "Halter LZB lackiert",
			en: "Bracket LZB painted"
		}
		,A2V00001978746 : {
			de: "Abstuetzung re lackiert",
			en: "support ri varnished"
		}
		,A2V00001978585 : {
			de: "Abstuetzung li lackiert",
			en: "Support le varnished"
		}
		,A2V00001206777 : {
			de: "Abstandshuelse M12x19",
			en: "Spacer M12x19"
		}
		,A2V00002562729 : {
			de: "Kabelstrang GSG TDG",
			en: "Cable loom WSP MBG"
		}
		,A2V00002562732 : {
			de: "Kabelstrang GSG LDG",
			en: "Cable loom WSP TBG"
		}
		,A2V00002559185 : {
			de: "Verteilkasten HK BT vollst.",
			en: "Connection box BT assembly"
		}
		,A2V00002198261 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00001827187 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00001827249 : {
			de: "Halter w.g.",
			en: "Holder a.d."
		}
		,A2V00001827777 : {
			de: "Halter sp.g.",
			en: "Holder o.a.drawn"
		}
		,A2V00001949334 : {
			de: "Konsole LZB 2 lack.",
			en: "Bracket LZB 2 varn."
		}
		,A2V00001979118 : {
			de: "Konsole TBL lackiert",
			en: "Bracket TBL painted"
		}
		,A2V00002323665 : {
			de: "Halter f. Erdung",
			en: "Holder f. grounding"
		}
		,A2V00002591439 : {
			de: "Schweissbadsicherung WE1, untere",
			en: "Weld backing CE1, lower"
		}
		,A2V00002320893 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002312843 : {
			de: "Queranschlag",
			en: "lateral stop"
		}
		,A2V00002316926 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00001913476 : {
			de: "Gewindebolzen DIN 976-1-M20 x 860-B-8.8",
			en: "Stud bolt DIN 976-1-M20 x 860-B-8.8"
		}
		,A2V00001955484 : {
			de: "Gewindebolzen DIN 976-1-M20 x 900-B-8.8",
			en: "Stud bolt DIN 976-1-M20 x 900-B-8.8"
		}
		,A2V00002591585 : {
			de: "Schweissbadsicherung, kurze",
			en: "Weld backing, short"
		}
		,A2V00002533996 : {
			de: "Mittellangtraeger",
			en: "middle girdr"
		}
		,A2V00002591746 : {
			de: "Schweissbadsicherung, lange",
			en: "Weld backing, long"
		}
		,A2V00002533997 : {
			de: "Querprofil",
			en: "cross profile"
		}
		,A2V00002533998 : {
			de: "Laengsprofil FE",
			en: "longitudinal profile fe"
		}
		,A2V00002533999 : {
			de: "Bodenplatte innen",
			en: "floor profile, inside"
		}
		,A2V00002534000 : {
			de: "Bodenplatte aussen",
			en: "floor profile, outside"
		}
		,A2V00002534005 : {
			de: "Bodenprofil, aussen",
			en: "roof profile, outside"
		}
		,A2V00002534016 : {
			de: "Bodenprofil, innen",
			en: "roof profile, inside"
		}
		,A2V00002534017 : {
			de: "Bodenprofil, Mitte",
			en: "floor profile, centre"
		}
		,A2V00002592240 : {
			de: "Auflage Schiebetritt 2",
			en: "Support slide 2"
		}
		,A2V00002592241 : {
			de: "Auflage Schiebetritt 1 3",
			en: "Support slide 1 3"
		}
		,A2V00002534040 : {
			de: "Profil Kappe",
			en: "Profile cap"
		}
		,A2V00002534045 : {
			de: "A-Saeule",
			en: "A-post"
		}
		,A2V00002592635 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002592842 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002534172 : {
			de: "Tuersaeulenprofil HF",
			en: "door pillar profile hf"
		}
		,A2V00002592843 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002534191 : {
			de: "Dachprofil aussen",
			en: "roof profile outside"
		}
		,A2V00002593114 : {
			de: "Profil fuer Arbeitsproben",
			en: "Profile for work samples"
		}
		,A2V00002593115 : {
			de: "Profil fuer Arbeitsproben",
			en: "Profile for work samples"
		}
		,A2V00002593127 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00002593261 : {
			de: "Profil fuer Arbeitsproben, abgelaengt",
			en: "Profile for work samples, cut-away"
		}
		,A2V00002593286 : {
			de: "Profil fuer Arbeitsproben",
			en: "Profile for work samples"
		}
		,A2V00002593464 : {
			de: "Profil fuer Arbeitsproben",
			en: "Profile for work samples"
		}
		,A2V00002534519 : {
			de: "Verstaerkungsprofil",
			en: "slide step profile"
		}
		,A2V00002593550 : {
			de: "Profil fuer Arbeitsproben",
			en: "Profile for work samples"
		}
		,A2V00002534562 : {
			de: "Hakenprofil",
			en: "hook profile"
		}
		,A2V00002534900 : {
			de: "Abdeckung",
			en: "Covering"
		}
		,A2V00002593710 : {
			de: "Nutenstein",
			en: "groove stone"
		}
		,A2V00002535878 : {
			de: "Hakenprofil",
			en: "hook profile"
		}
		,A2V00002535879 : {
			de: "Hakenprofil",
			en: "hook profile"
		}
		,A2V00002535880 : {
			de: "Hakenprofil",
			en: "hook profile"
		}
		,A2V00002535881 : {
			de: "Hakenprofil",
			en: "hook profile"
		}
		,A2V00002594609 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002594624 : {
			de: "Abhebekonsole",
			en: "Lifting stop"
		}
		,A2V00002539135 : {
			de: "Liefergarnitur Fussbodenplatte",
			en: "Delivering set Floor plate"
		}
		,A2V00397254147 : {
			de: "FEDERTELLER OBEN",
			en: "SPRING PLATE TOP"
		}
		,A2V00002022967 : {
			de: "Laufradsatzwelle, lackiert",
			en: "trailer axle, lacquered"
		}
		,A2V00002081128 : {
			de: "Huelse f. RBS",
			en: "bushing f. discbrake"
		}
		,A2V00001979595 : {
			de: "Zentrierelement",
			en: "Centering device"
		}
		,A2V00001846827 : {
			de: "Halter fuer Memor-Buerste lackiert",
			en: "Bracket for Memor brush painted"
		}
		,A2V00002156726 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002081065 : {
			de: "Mutter f. RBS",
			en: "nut f. discbrake"
		}
		,A2V00002234996 : {
			de: "Treibradsatz vollst. K3S ER9",
			en: "Motor wheelset K3S, compl. ER9"
		}
		,A2V00001898334 : {
			de: "Konsole lack.",
			en: "Bracket paint."
		}
		,A2V00001902810 : {
			de: "Deckel lackiert fuer Siemens16P",
			en: "Cover painted for Siemens16P"
		}
		,A2V00001447659 : {
			de: "Beilage, lackiert",
			en: "Shim, lacqu."
		}
		,A2V00002682635 : {
			de: "Ersatzkabel fÃ¼r Indusi DG1 u. DG2",
			en: "Spare cable for Indusi B1 and B2"
		}
		,A2V00002226408 : {
			de: "Abhebesicherung lackiert",
			en: "Lift lock painted"
		}
		,A2V00002140844 : {
			de: "Radsatzlager, Anbau",
			en: "Wheelset bearing, assembly"
		}
		,A2V00002594689 : {
			de: "Gewindebolzen vollst.",
			en: "Stud bolt compl."
		}
		,A2V00001942463 : {
			de: "Beilage 3mm",
			en: "Shim 3mm"
		}
		,A2V00001942464 : {
			de: "Beilage 2mm",
			en: "Shim 2mm"
		}
		,A2V00001775906 : {
			de: "Abhebesicherung",
			en: "Lifting stop"
		}
		,A2V00001935785 : {
			de: "Antenne SHP kompl.",
			en: "Antenna SHP cpl."
		}
		,A2V00002722263 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00001394830 : {
			de: "Beilage 2mm",
			en: "Washer 2mm"
		}
		,A2V00001972278 : {
			de: "Spritzschutz  fuer Drehstabfeder",
			en: "splash guard for torsion bar spring"
		}
		,A2V00002596819 : {
			de: "Flanschbuchse",
			en: "Flange sleve"
		}
		,A2V00002596820 : {
			de: "Quertraeger gross",
			en: "Cross beam large"
		}
		,A2V00002596822 : {
			de: "Quertraeger klein",
			en: "Cross beam small"
		}
		,A2V00001471981 : {
			de: "Lager T2DC220",
			en: "Bearing"
		}
		,A2V00002277359 : {
			de: "Zylinderrollenlager 215 NJ E N1 MPA C3 H",
			en: "Cylinder roller bearing"
		}
		,A2V00002539737 : {
			de: "Liefergarnitur Aussenwaende",
			en: "Delivering set Outer walls"
		}
		,A2V00002540286 : {
			de: "Liefergarnitur Fussbodenplatte",
			en: "Delivering set Floor plate"
		}
		,A2V00002540287 : {
			de: "Liefergarnitur Fussbodenplatte",
			en: "Delivering set Floor plate"
		}
		,A2V00002595599 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002595669 : {
			de: "Schweissbadsicherung w.g.",
			en: "Weld backing a.d."
		}
		,A2V00002595670 : {
			de: "Schweissbadsicherung w.g.",
			en: "Weld backing a.d."
		}
		,A2V00002595671 : {
			de: "Schweissbadsicherung sp.g.",
			en: "Weld backing i.m.i."
		}
		,A2V00002631014 : {
			de: "Leiste",
			en: "Strip"
		}
		,A2V00002595908 : {
			de: "Klimavers.rohr 01 geschw.",
			en: "Aircon. pipe 01 weld."
		}
		,A2V00002595909 : {
			de: "Klimavers.rohr 01 steigl. geschw.",
			en: "Aircon. rising pipe 01 weld."
		}
		,A2V00002595910 : {
			de: "Klimavers.rohr 02 geschw.",
			en: "Aircon. pipe 02 weld."
		}
		,A2V00002595931 : {
			de: "Steigleitungsrohr HSA geschw.",
			en: "Rising pipe CE2 weld."
		}
		,A2V00002595933 : {
			de: "Wischwassertank",
			en: "Wiper fluid tank"
		}
		,A2V00002595935 : {
			de: "Erdungsleitungsrohr 04 geschw.",
			en: "Grounding line pipe 04 weld."
		}
		,A2V00002595954 : {
			de: "Ringleitungsrohr 03 geschw.",
			en: "Circleline pipe 03 weld."
		}
		,A2V00002595966 : {
			de: "Erdungsleitungsrohr 05 geschw.",
			en: "Grounding line pipe 05 weld."
		}
		,A2V00002540966 : {
			de: "E-Trennstelle 1",
			en: "Coupling jumper 1"
		}
		,A2V00002540967 : {
			de: "E-Trennstelle 2",
			en: "Coupling jumper 2"
		}
		,A2V00002541538 : {
			de: "Haltevorrichtung vormontiert",
			en: "Mounting device pre-assembled"
		}
		,A2V00002596420 : {
			de: "C-Schiene f. Rohr",
			en: "C-bar f. pipe"
		}
		,A2V00002596511 : {
			de: "Kabelkanal UF 02 geschw.",
			en: "Cable duct UF 02 welded"
		}
		,A2V00002596522 : {
			de: "Kabelkanal UF 01 geschw.",
			en: "Cable duct UF 01 welded"
		}
		,A2V00105690706 : {
			de: "ZUSATZFEDER,KOMPLETT",
			en: "ZUSATZFEDER,KOMPLETT"
		}
		,A2V00002081030 : {
			de: "Dehnschraube",
			en: "Extension bolt"
		}
		,A2V00001695973 : {
			de: "Rohr f. Kuehlwasser",
			en: "Pipe f. cooling water"
		}
		,A2V00002040837 : {
			de: "Grundbeilage lackiert",
			en: "basic shim painted"
		}
		,A2V00002052839 : {
			de: "Zwischenbolzen lackiert",
			en: "intermediate pin painted"
		}
		,A2V00002072314 : {
			de: "Druckluftrohr 1",
			en: "Pneumatic pipe 1"
		}
		,A2V00002072526 : {
			de: "Druckluftrohr 2",
			en: "Pneumatic pipe 2"
		}
		,A2V00002072527 : {
			de: "Druckluftrohr 5",
			en: "Pneumatic pipe 5"
		}
		,A2V00002072528 : {
			de: "Druckluftrohr 3",
			en: "Pneumatic pipe 3"
		}
		,A2V00002072529 : {
			de: "Druckluftrohr 4",
			en: "Pneumatic pipe 4"
		}
		,A2V00001834836 : {
			de: "Kupplunghaelfte Getriebeseitig",
			en: "Coupling half gear box side"
		}
		,A2V00002627060 : {
			de: "Traeger lackiert",
			en: "Carrier painted"
		}
		,A2V00002079794 : {
			de: "Schutzrohr TDG",
			en: "Protective pipe MB"
		}
		,A2V00002079795 : {
			de: "Schutzrohr TDG",
			en: "Protective pipe MB"
		}
		,A2V00002079998 : {
			de: "Schutzrohr LDG",
			en: "Protective pipe TB"
		}
		,A2V00002079999 : {
			de: "Schutzrohr LDG",
			en: "Protective pipe TB"
		}
		,A2V00002005156 : {
			de: "Schellen Elem. B la. wg",
			en: "Piepe clamp B pa. ad"
		}
		,A2V00001897712 : {
			de: "Konsole f. Antenne SHP 2003, lackiert",
			en: "Bracket for antenna SHP 2003, painted"
		}
		,A2V00002038615 : {
			de: "Federteller oben lackiert",
			en: "Spring base plate top painted"
		}
		,A2V00002040391 : {
			de: "Federteller unten lackiert",
			en: "spring plate below paint"
		}
		,A2V00001778131 : {
			de: "Radlagerdeckel LFW",
			en: "cap for bearing trailer bogie"
		}
		,A2V00001778134 : {
			de: "Radlagerdeckel TFW",
			en: "cap for bearing motor bogie"
		}
		,A2V00001896120 : {
			de: "Schienenraeumerblech lackiert sp.g.",
			en: "rail guard plate painted o.a.d."
		}
		,A2V00002005135 : {
			de: "Schellenhalter-Winkel",
			en: "Pipe clamp bracket"
		}
		,A2V00002037657 : {
			de: "Schutzblech S2",
			en: "protection plate s2"
		}
		,A2V00002231452 : {
			de: "Halter Brosse",
			en: "Bracket brosse"
		}
		,A2V00002701674 : {
			de: "Vollrad",
			en: "solid wheel"
		}
		,A2V00001309518 : {
			de: "Huelse fuer Puffer",
			en: "sleeve for the buffer"
		}
		,A2V00001309519 : {
			de: "Distanzscheibe",
			en: "distance disc"
		}
		,A2V00002101250 : {
			de: "Funkenschildkonsole SPG",
			en: "Bracket for arcshield oad"
		}
		,A2V00002702323 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002702324 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002702398 : {
			de: "Beilagenhaelfte f. Primaerfeder",
			en: "Half shim f. primary spring"
		}
		,A2V00002702400 : {
			de: "Beilagenhaelfte f. Primaerfeder",
			en: "Half shim f. primary spring"
		}
		,A2V00002702424 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002702506 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002702792 : {
			de: "Beilagenhaelfte",
			en: "Half shim"
		}
		,A2V00002702793 : {
			de: "Beilagenhaelfte",
			en: "Half shim"
		}
		,A2V00002702794 : {
			de: "Beilagenhaelfte",
			en: "Half shim"
		}
		,A2V00002702795 : {
			de: "Beilagenhaelfte",
			en: "Half shim"
		}
		,A2V00002704560 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002704561 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002140195 : {
			de: "Treibradsatzwelle, lackiert",
			en: "Motor axle, painted"
		}
		,A2V00001420994 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001421008 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002229853 : {
			de: "Sandungsrohr SPG",
			en: "Sander pipe OAD"
		}
		,A2V00002597717 : {
			de: "Hauptquertraeger ZSB",
			en: "Main cross girder ASSY"
		}
		,A2V00002544758 : {
			de: "FIS - Schrank 2 kom. +96.20",
			en: "PIS - cabinet 2 com. +96.20"
		}
		,A2V00002597778 : {
			de: "Schweissbadsicherung 2",
			en: "Weld backing 2"
		}
		,A2V00002597779 : {
			de: "Schweissbadsicherung 3",
			en: "Weld backing 3"
		}
		,A2V00002546182 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002546184 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002598786 : {
			de: "Dachquertraeger FSTE ZSB",
			en: "Roof cross beam CE Assy."
		}
		,A2V00002546397 : {
			de: "Abdeckung Profil",
			en: "Covering  profile"
		}
		,A2V00002599770 : {
			de: "Steg sp.g.",
			en: "Web i.m.i."
		}
		,A2V00002599771 : {
			de: "Gurtblech, sp.g.",
			en: "Belt plate, i.m.i."
		}
		,A2V00002599772 : {
			de: "Steg w.g.",
			en: "Web a.d."
		}
		,A2V00002599839 : {
			de: "Rohr 20x1.5",
			en: "Pipe 20x1.5"
		}
		,A2V00002599865 : {
			de: "Rippe, w.g.",
			en: "Rib, a.d."
		}
		,A2V00002599907 : {
			de: "Rippe, sp.g.",
			en: "Rib, i.m.i."
		}
		,A2V00002599916 : {
			de: "Gurtblech, w.g.",
			en: "Belt plate, a.d."
		}
		,A2V00002600920 : {
			de: "Schweissbadsicherung",
			en: "Weldbacking"
		}
		,A2V00002600921 : {
			de: "Schweissbadsicherung",
			en: "Weldbacking"
		}
		,A2V00002601252 : {
			de: "Schweissbadsicherung, WE1, sp.g.",
			en: "Weld backing, CE1, i.m.i."
		}
		,A2V00002601253 : {
			de: "Schweissbadsicherung, WE1, w.g.",
			en: "Weld backing, CE1, a.d."
		}
		,A2V00002601313 : {
			de: "Schweissbadsicherung, WE1 lange",
			en: "Weld backing, CE1 long"
		}
		,A2V00002601536 : {
			de: "Schweissbadsicherung, Stirnwand, w.g.",
			en: "Weld backing, endwall, a.d."
		}
		,A2V00370029255 : {
			de: "Kugeldrehverbindung",
			en: "Ball bearing slewing ring"
		}
		,A2V00002138358 : {
			de: "Huelse 16",
			en: "bush 16"
		}
		,A2V00001854818 : {
			de: "Motorstuetze lackiert",
			en: "motor column painted"
		}
		,A2V00001898330 : {
			de: "Konsole lack. SPG",
			en: "Bracket paint. OAD"
		}
		,A2V00002165106 : {
			de: "Drehgestell SF4-08B",
			en: "bogie SF4-08B"
		}
		,A2V00002165105 : {
			de: "Drehgestell SF4-08A",
			en: "bogie SF4-08A"
		}
		,A2V00002140780 : {
			de: "Treibradsatzwelle, lackiert",
			en: "Motor wheelset axle, lacquered"
		}
		,A2V00001902812 : {
			de: "Deckel lackiert fuer Faiveley ATESS",
			en: "Cover painted for Faiveley ATESS"
		}
		,A2V00002562727 : {
			de: "Kabelstrang z-BS TDG",
			en: "Cable loom z-acc. MBG"
		}
		,A2V00002562728 : {
			de: "Kabelstrang y-BS TDG",
			en: "Cable loom y-acc. MBG"
		}
		,A2V00002562730 : {
			de: "Kabelstrang z-BS LDG",
			en: "Cable loom z-acc. TBG"
		}
		,A2V00002562731 : {
			de: "Kabelstrang y-BS LDG",
			en: "Cable loom y-acc. TBG"
		}
		,A2V00002566485 : {
			de: "TDG Rahmen lackiert",
			en: "Motor bogie frame lacquered"
		}
		,A2V00002591895 : {
			de: "Anlenkstange L vollst.",
			en: "Tie Rod L compl."
		}
		,A2V00002598968 : {
			de: "Schienenraeumer lack",
			en: "Rail guard coated"
		}
		,A2V00002140783 : {
			de: "Vollrad ER9, lackiert",
			en: "Solid wheel ER9, painted"
		}
		,A2V00001853320 : {
			de: "Motortragarm, lackiert",
			en: "motor beam, painted"
		}
		,A2V00001894866 : {
			de: "Antennentraeger 2, lackiert",
			en: "Antenna beam 2, painted"
		}
		,A2V00001905463 : {
			de: "Schichtfeder Gehaeuse 1",
			en: "Layer spring housing 1"
		}
		,A2V00002601537 : {
			de: "Schweissbadsicherung, Stirnwand, sp.g.",
			en: "Weld backing, endwall, i.m.i."
		}
		,A2V00001905465 : {
			de: "Schichtfeder Gehaeuse 2",
			en: "Layer spring housing 2"
		}
		,A2V00002129349 : {
			de: "Platte lackiert",
			en: "plate painted"
		}
		,A2V00002129374 : {
			de: "Scheibe lackiert",
			en: "disc painted"
		}
		,A2V00002317944 : {
			de: "Montagematerial Druckkappe",
			en: "Mounting material axle end cap"
		}
		,A2V00002362528 : {
			de: "Konsole - ROH",
			en: "Bracket - RAW"
		}
		,A2V00002602079 : {
			de: "Huelse, Kupplung",
			en: "Bush, coupler"
		}
		,A2V00002602131 : {
			de: "Auflage Schiebetritt",
			en: "Support slide"
		}
		,A2V00002602144 : {
			de: "Abdeckung Radkasten",
			en: "Cover wheelbox"
		}
		,A2V00002602150 : {
			de: "Bodenblech",
			en: "Floor plate"
		}
		,A2V00002323141 : {
			de: "Bolzen Ã˜20h9x110x103",
			en: "bolt Ã˜20h9x110x103"
		}
		,A2V00002603984 : {
			de: "Schweissbadsicherung f. Schlitz",
			en: "Weld backing f. slot"
		}
		,A2V00002604430 : {
			de: "Schweissbadsicherung DLT, un. WE2, w.g.",
			en: "Weld backing RC, lower, CE2, a.d."
		}
		,A2V00002551630 : {
			de: "Liefergarnitur Schottwand",
			en: "Delivering set bulkhead sheet"
		}
		,A2V00002604431 : {
			de: "Schweissbadsicherung DLT, un. WE1, sp.g.",
			en: "Weld backing RC, lower, CE1, i.m.i."
		}
		,A2V00002604432 : {
			de: "Schweissbadsicherung DLT, un. WE1, w.g.",
			en: "Weld backing RC, lower, CE1, a.d."
		}
		,A2V00002551632 : {
			de: "Liefergarnitur Schottwand",
			en: "Delivering set bulkhead sheet"
		}
		,A2V00002604433 : {
			de: "Schweissbadsicherung DLT, un. WE2, sp.g.",
			en: "Weld backing RC, lower, CE2, i.m.i."
		}
		,A2V00002604489 : {
			de: "Druckbehaelter 50l",
			en: "Air reservoir 50l"
		}
		,A2V00002604490 : {
			de: "Druckbehaelter 100l",
			en: "Air reservoir 100l"
		}
		,A2V00002043464 : {
			de: "Uebergangskupplung (Typ-10 Modul)",
			en: "Adapter coupler (Type-10 module)"
		}
		,A2V00379990920 : {
			de: "Schweissstab 2x1000 BOEHLER CN 23/12-IG",
			en: "Welding rod 2x1000 BOEHLER CN 23/12-IG"
		}
		,A2V00002102107 : {
			de: "Traeger f. Sandung w.g. lackiert",
			en: "Support f. sander a.d. painted"
		}
		,A2V00002046567 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical shock absorber"
		}
		,A2V00002053554 : {
			de: "Verschraubungssatz Kuppl. mit Dichtband",
			en: "Coupling screw set with sealing tape"
		}
		,A2V00102200260 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002054938 : {
			de: "Sensorkit links fÃ¼r Radsatzlager",
			en: "sensorkit left for axle box bearing"
		}
		,A2V00370037242 : {
			de: "Erdungswiderstand",
			en: "Grounding resistor"
		}
		,A2V00002364526 : {
			de: "Dichtkappe fÃ¼r Kuppelstange (Tieftemp.)",
			en: "Sealing cap for coupling rod (low temp.)"
		}
		,A2V00110127246 : {
			de: "Schweissdraht",
			en: "welding wire"
		}
		,A2V00001087230 : {
			de: "Stromwandler LF2005-S/SP20",
			en: "Current transducer LF2005-S/SP20"
		}
		,A2V00102204169 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001932456 : {
			de: "Lageraufnahme komplett",
			en: "Bearing support complete"
		}
		,A2V00002112044 : {
			de: "Faltenbalg",
			en: "gaiter"
		}
		,A2V00001734399 : {
			de: "Beilage f Druckluftver. 1",
			en: "Shim f. pneumatic p. 1"
		}
		,A2V00002605328 : {
			de: "Deckel DLT unten KKE w.g.",
			en: "Cover RC down SCE a.d."
		}
		,A2V00002605329 : {
			de: "Deckel DLT oben FSTE w.g.",
			en: "Cover RC upper CE a.d."
		}
		,A2V00002605330 : {
			de: "Deckel DLT unten KKE sp.g.",
			en: "Cover RC down SCE i.m.i."
		}
		,A2V00002605331 : {
			de: "Deckel DLT unten FSTE w.g.",
			en: "Cover RC down CE a.d."
		}
		,A2V00002605332 : {
			de: "Deckel DLT oben FSTE sp.g.",
			en: "Cover RC upper CE i.m.i."
		}
		,A2V00002605333 : {
			de: "Deckel DLT oben KKE sp.g.",
			en: "Cover RC upper SCE i.m.i."
		}
		,A2V00002605334 : {
			de: "Deckel DLT oben KKE w.g.",
			en: "Cover RC upper SCE a.d."
		}
		,A2V00002605335 : {
			de: "Deckel DLT unten FSTE sp.g.",
			en: "Cover RC down CE i.m.i."
		}
		,A2V00002605511 : {
			de: "Deckel Wanne FSTE sp.g.",
			en: "Cover pan CE i.m.i."
		}
		,A2V00002605512 : {
			de: "Deckel Wanne FSTE w.g.",
			en: "Cover pan CE a.d."
		}
		,A2V00002605546 : {
			de: "Anticlimber",
			en: "Anticlimber"
		}
		,A2V00002605589 : {
			de: "Rippe Klima oben",
			en: "Plate climate upper"
		}
		,A2V00002605630 : {
			de: "Auflage w.g.",
			en: "Support a.d."
		}
		,A2V00002552495 : {
			de: "Crash Modul vollst.",
			en: "crash modul complete"
		}
		,A2V00002605631 : {
			de: "Auflage sp.g.",
			en: "Support i.m.i."
		}
		,A2V00002605677 : {
			de: "Schweissbadsicherung sp.g.",
			en: "Weld backing i.m.i."
		}
		,A2V00002605678 : {
			de: "Schweissbadsicherung w.g.",
			en: "Weld backing a.d."
		}
		,A2V00002605757 : {
			de: "Dachschott",
			en: "Bulkhead"
		}
		,A2V00002605792 : {
			de: "Schweissbadsicherung sp.g.",
			en: "Weld backing i.m.i."
		}
		,A2V00002605793 : {
			de: "Schweissbadsicherung w.g.",
			en: "Weld backing a.d."
		}
		,A2V00002605878 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00002606127 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002606389 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002606428 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002554186 : {
			de: "Abdeckblech",
			en: "cover plate"
		}
		,A2V00002607655 : {
			de: "Deckel",
			en: "Cap"
		}
		,A2V00002609828 : {
			de: "Dichtblech MC, sp.g.",
			en: "Sealing plate mc, i.m.i."
		}
		,A2V00002609961 : {
			de: "Tasche WE2 C",
			en: "Bin CE2 C"
		}
		,A2V00002610329 : {
			de: "Wellenbalg, vollst.",
			en: "Corrugated bellows, assy."
		}
		,A2V00002610341 : {
			de: "Wellenbalg, vollst. endmontiert",
			en: "Corugated bellows, assy., final mounted"
		}
		,A2V00002147484 : {
			de: "Klemmplatte",
			en: "clamping plate"
		}
		,A2V00002147282 : {
			de: "Buegel",
			en: "bracket"
		}
		,A2V00002369184 : {
			de: "Winkel",
			en: "angle"
		}
		,A2V00002610768 : {
			de: "Schweissbadsicherung f. Deckel, w.g.",
			en: "Weld pool backing f. Cover, a.d."
		}
		,A2V00002280367 : {
			de: "Luftkanal 1900 Variante PTOSL",
			en: "air duct 1900 type PTOSL"
		}
		,A2V00002610769 : {
			de: "Schweissbadsicherung f. Deckel, sp.g.",
			en: "Weld pool backing f. Cover, i.m.i."
		}
		,A2V00002280365 : {
			de: "Luftkanal 1900 DMOC/DMOCB Variante 1",
			en: "air duct 1900 DMOC/DMOCB type 1"
		}
		,A2V00002253648 : {
			de: "Endwand links kpl.",
			en: "Endwall left cpl."
		}
		,A2V00002024057 : {
			de: "Schutzblech",
			en: "protecting plate"
		}
		,A2V00002610881 : {
			de: "Abdeckblech 4",
			en: "Cover plate 4"
		}
		,A2V00002610882 : {
			de: "Abdeckblech 3",
			en: "Cover plate 3"
		}
		,A2V00002157434 : {
			de: "Schotterschutzblech",
			en: "protection plate"
		}
		,A2V00002610885 : {
			de: "Deckel, w.g.",
			en: "Cover, a.d."
		}
		,A2V00002610933 : {
			de: "Deckel 2, sp.g.",
			en: "Cover plate 2, i.m.i."
		}
		,A2V00002610934 : {
			de: "Deckel 2, w.g.",
			en: "Cover plate 2, a.d."
		}
		,A2V00002611080 : {
			de: "Schweissbadsicherung",
			en: "Weld pool backing"
		}
		,A2V00002272066 : {
			de: "Thermische Entkopplung",
			en: "Thermally shim"
		}
		,A2V00002191187 : {
			de: "Platte",
			en: "plate"
		}
		,A2V00002191188 : {
			de: "Platte",
			en: "plate"
		}
		,A2V00002611097 : {
			de: "Abdeckblech 1, w.g.",
			en: "Cover plate 1, a.d."
		}
		,A2V00002611098 : {
			de: "Abdeckblech 1, sp.g.",
			en: "Cover plate 1, i.m.i."
		}
		,A2V00002611099 : {
			de: "Abdeckblech 2, sp.g.",
			en: "Cover plate 2, i.m.i."
		}
		,A2V00002611100 : {
			de: "Abdeckblech 2, w.g.",
			en: "Cover plate 2, a.d."
		}
		,A2V00002611116 : {
			de: "Deckel, sp.g.",
			en: "Cover, i.m.i."
		}
		,A2V00002611284 : {
			de: "Entwaesserungsrohr, schraeges",
			en: "Drainage pipe, angle"
		}
		,A2V00002611338 : {
			de: "Einschweissrohr 2",
			en: "Weld-in pipe 2"
		}
		,A2V00002611358 : {
			de: "Feuerschutzblech 2",
			en: "Fire protection panel 2"
		}
		,A2V00002611359 : {
			de: "Einschweissrohr 1",
			en: "Weld-in pipe 1"
		}
		,A2V00002611386 : {
			de: "Anschweissgewindeblock",
			en: "Welding thread-block"
		}
		,A2V00002611387 : {
			de: "Entwaesserungsrohr, langes",
			en: "Drainage pipe, long"
		}
		,A2V00002611388 : {
			de: "Entwaesserungsrohr",
			en: "Drainage pipe"
		}
		,A2V00002611538 : {
			de: "Blattfeder L584",
			en: "Leaf spring L584"
		}
		,A2V00002611975 : {
			de: "Auflage Schiebetritt C",
			en: "Support slide C"
		}
		,A2V00002612226 : {
			de: "Auflage Schiebetritt B",
			en: "Support slide B"
		}
		,A2V00002612363 : {
			de: "C-Schiene 10",
			en: "C-Rail 10"
		}
		,A2V00002612889 : {
			de: "Blech f. Stockerl",
			en: "Sheet f. Guidance"
		}
		,A2V00002612898 : {
			de: "Rippe w.g.",
			en: "Rib a.d."
		}
		,A2V00002612899 : {
			de: "Gurtblech",
			en: "Chord plate"
		}
		,A2V00002612900 : {
			de: "Rippe sp.g.",
			en: "Rib i.m.i."
		}
		,A2V00002612976 : {
			de: "Kupplungskasten WE2 C",
			en: "Coupling-box WE2 C"
		}
		,A2V00002612977 : {
			de: "Kupplungskasten WE2 B",
			en: "Coupling-box WE2 B"
		}
		,A2V00002613375 : {
			de: "Schweissbadsicherung w.g.",
			en: "Weld backing a.d."
		}
		,A2V00002613524 : {
			de: "Schweissbadsicherung w.g.",
			en: "Weld backing a.d."
		}
		,A2V00002613525 : {
			de: "Schweissbadsicherung sp.g.",
			en: "Weld backing i.m.i."
		}
		,A2V00002613556 : {
			de: "Schweissbadsicherung sp.g.",
			en: "Weld backing i.m.i."
		}
		,A2V00002613721 : {
			de: "Tasche WE2 B",
			en: "Bin CE2 B"
		}
		,A2V00002613822 : {
			de: "Kathedrale ZSB, w.g.",
			en: "Cathedral ASSY, a.d."
		}
		,A2V00002613823 : {
			de: "Kathedrale ZSB, sp.g.",
			en: "Cathedral ASSY, i.m.i."
		}
		,A2V00002614012 : {
			de: "Rippe, w.g.",
			en: "Rib, a.d."
		}
		,A2V00002614013 : {
			de: "Gurt Endplatte, sp.g.",
			en: "Flange endsheet, i.m.i."
		}
		,A2V00002614014 : {
			de: "Gurt Endplatte, w.g.",
			en: "Flange endsheet, a.d."
		}
		,A2V00002614081 : {
			de: "Blech f. Dom, sp.g.",
			en: "Sheet f. dome, i.m.i."
		}
		,A2V00002614082 : {
			de: "Kastenblech, w.g.",
			en: "Box plate, a.d."
		}
		,A2V00002614083 : {
			de: "Blech f. Dom, w.g.",
			en: "Sheet f. dome, a.d."
		}
		,A2V00002614084 : {
			de: "Rippe Endplatte, w.g.",
			en: "Rib endsheet, a.d."
		}
		,A2V00002614085 : {
			de: "Dichtblech",
			en: "Sealing plate"
		}
		,A2V00002614106 : {
			de: "Stirnwandblech, aeusseres, w.g.",
			en: "Endwall sheet, outside, a.d."
		}
		,A2V00002614108 : {
			de: "Stirnwandblech, aeusseres, sp.g.",
			en: "Endwall sheet, outside, i.m.i."
		}
		,A2V00002614109 : {
			de: "Rippe, sp.g.",
			en: "Rib, i.m.i."
		}
		,A2V00002614110 : {
			de: "Auflage Schiebetritt",
			en: "Support slide"
		}
		,A2V00002614117 : {
			de: "Rippe Endplatte, sp.g.",
			en: "Rib endsheet, i.m.i."
		}
		,A2V00002614518 : {
			de: "Anschweissgewindeblock 2",
			en: "Welding thread-block 2"
		}
		,A2V00002557702 : {
			de: "Abdichtung",
			en: "sealing plate"
		}
		,A2V00002557914 : {
			de: "Tankeinheit",
			en: "Tank, assy"
		}
		,A2V00002092223 : {
			de: "Klemmplatte",
			en: "clamping plate"
		}
		,A2V00002096274 : {
			de: "Blindfeld 08 vollst.",
			en: "blind field 08 compl."
		}
		,A2V00002312965 : {
			de: "Tuer, vollst.",
			en: "Door, compl."
		}
		,A2V00002558592 : {
			de: "Halter WLAN AP mechanisch",
			en: "Holder WLAN AP mechanical"
		}
		,A2V00002615153 : {
			de: "Quertraeger 03 ZSB",
			en: "Cross girder 03 ASSY"
		}
		,A2V00002558630 : {
			de: "Endprofil Dach",
			en: "End profile roof"
		}
		,A2V00002615161 : {
			de: "Einschweissrohr kurz",
			en: "Weld-in pipe short"
		}
		,A2V00002615204 : {
			de: "Einschweissrohr kurz",
			en: "Weld-in pipe short"
		}
		,A2V00002246320 : {
			de: "Zusatzstuetzplatte",
			en: "add supporting plate"
		}
		,A2V00370068155 : {
			de: "Bandanzeigegeraet",
			en: "band signalling unit"
		}
		,A2V00002615223 : {
			de: "DurchfuehrungsplatteÂ Stern",
			en: "Pass through plate star"
		}
		,A2V00002615324 : {
			de: "Quertraeger 05 ZSB",
			en: "Cross girder 05 ASSY"
		}
		,A2V00002615373 : {
			de: "Quertraeger 04 MC ZSB",
			en: "Cross girder 04 MC ASSY"
		}
		,A2V00002615392 : {
			de: "Quertraeger 02 ZSB",
			en: "Cross girder 02 ASSY"
		}
		,A2V00002615440 : {
			de: "Quertraeger 01 ZSB",
			en: "Cross girder 01 ASSY"
		}
		,A2V00002615442 : {
			de: "Quertraeger 06 ZSB",
			en: "Cross girder 06 ASSY"
		}
		,A2V00002615451 : {
			de: "Quertraeger 04 ZSB",
			en: "Cross girder 04 ASSY"
		}
		,A2V00002615561 : {
			de: "Quertraeger 07 ZSB",
			en: "Cross girder 07 ASSY"
		}
		,A2V00002615787 : {
			de: "Trennblech",
			en: "Separate plate"
		}
		,A2V00002615844 : {
			de: "Kurzschliesserterminal links",
			en: "Short circuiting device left"
		}
		,A2V00002615845 : {
			de: "Kurzschliesserterminal rechts",
			en: "Short circuiting device right"
		}
		,A2V00002615966 : {
			de: "Ausklinkstab fuer Seitenstromabnehmer",
			en: "Insulated lever for shoegear"
		}
		,A2V00002615970 : {
			de: "Entwaesserung ZSB CS w.g.",
			en: "Drainage ASSY CP a.d."
		}
		,A2V00002615983 : {
			de: "Entwaesserung ZSB TS",
			en: "Drainage ASSY DP"
		}
		,A2V00002616018 : {
			de: "Entwaesserung ZSB CS sp.g.",
			en: "Drainage ASSY CP i.m.i."
		}
		,A2V00002560249 : {
			de: "Abdeckblech",
			en: "cover plate"
		}
		,A2V00002560251 : {
			de: "Abdeckblech",
			en: "cover plate"
		}
		,A2V00002617451 : {
			de: "Klimavers.rohr 02 steigl. geschw.",
			en: "Aircon. rising pipe 02 weld."
		}
		,A2V00002617546 : {
			de: "Regenrinne",
			en: "Eaves gutter"
		}
		,A2V00002617555 : {
			de: "Rippe Seitenwand UG sp.g.",
			en: "Rib sidewall underframe i.m.i."
		}
		,A2V00002617836 : {
			de: "Rippe Seitenwand UG w.g.",
			en: "Rib sidewall underframe a.d."
		}
		,A2V00002617878 : {
			de: "Tuerleiste",
			en: "Doorsill"
		}
		,A2V00002561175 : {
			de: "Blech Kupplung w.g.",
			en: "Sheet coupling as d."
		}
		,A2V00002618163 : {
			de: "Fussbodenauflage",
			en: "Floorsupport"
		}
		,A2V00002561306 : {
			de: "Blech Kupplung sp. gl.",
			en: "Sheet coupling r.i."
		}
		,A2V00002561641 : {
			de: "Deckblech3 F-Ende",
			en: "cover plate 3 front end"
		}
		,A2V00002561642 : {
			de: "Deckblech 2 F-Ende",
			en: "cover plate 2 front end"
		}
		,A2V00002619333 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002222572 : {
			de: "Seitenklappe vollst. sp.g.",
			en: "side flap complete r.i."
		}
		,A2V00002619334 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002136883 : {
			de: "Drehsaeule, rechts",
			en: "Conjunction bar, right"
		}
		,A2V00002136101 : {
			de: "Tuerantrieb",
			en: "Door operator"
		}
		,A2V00002136124 : {
			de: "Tuerblatt, links",
			en: "Door leaf, left"
		}
		,A2V00002136125 : {
			de: "Drehsaeule, links",
			en: "Conjunction bar, left"
		}
		,A2V00002136877 : {
			de: "Tuerantrieb, links",
			en: "Door operator, left"
		}
		,A2V00002136878 : {
			de: "Tuerblatt, rechts",
			en: "Door leaf, right"
		}
		,A2V00002222579 : {
			de: "Seitenklappe vollst.",
			en: "side flap complete"
		}
		,A2V00002619383 : {
			de: "Beilage 0,5",
			en: "Shim 0,5"
		}
		,A2V00002619384 : {
			de: "Beilage 1",
			en: "Shim 1"
		}
		,A2V00002619385 : {
			de: "Beilage 0,5",
			en: "Shim 0,5"
		}
		,A2V00002619478 : {
			de: "Fuehrungswinkel w.g.",
			en: "Guide angle a.d."
		}
		,A2V00002619479 : {
			de: "Fuehrungswinkel sp.g.",
			en: "Guide angle i.m.i."
		}
		,A2V00002619480 : {
			de: "Laufflaeche",
			en: "Running surface"
		}
		,A2V00002619487 : {
			de: "Crashelement, ZSB",
			en: "Crashelement, Assy."
		}
		,A2V00002619526 : {
			de: "Beilage 1",
			en: "Shim 1"
		}
		,A2V00002561998 : {
			de: "H-Profil w.g.",
			en: "h-profile as d."
		}
		,A2V00002561999 : {
			de: "H-Profil sp.g.",
			en: "h-profile r.i."
		}
		,A2V00002620152 : {
			de: "Rohr Bremswiderstand geschw.",
			en: "Pipe breakresistor welded"
		}
		,A2V00002620207 : {
			de: "Kabelkanal UF 01 geschw.",
			en: "Cable duct UF 01 welded"
		}
		,A2V00002620208 : {
			de: "Kabelkanal UF 02 geschw.",
			en: "Cable duct UF 02 welded"
		}
		,A2V00002562127 : {
			de: "Rohrhalter",
			en: "pipe holder"
		}
		,A2V00002562128 : {
			de: "Deckblech",
			en: "coverplate"
		}
		,A2V00002128532 : {
			de: "Automatische Kupplung",
			en: "Automatic coupler"
		}
		,A2V00002358385 : {
			de: "Feder   lang",
			en: "spring  long"
		}
		,A2V00002334327 : {
			de: "Gewindeplatte",
			en: "Threaded plate"
		}
		,A2V00002150621 : {
			de: "Gewindeplatte",
			en: "threaded plate"
		}
		,A2V00002151566 : {
			de: "Gewindeplatte",
			en: "threaded plate"
		}
		,A2V00002380945 : {
			de: "Seitenklappe vollst.",
			en: "Side flap complete"
		}
		,A2V00002620478 : {
			de: "Abdeckung Lautsprecher",
			en: "Cover speaker"
		}
		,A2V00002621149 : {
			de: "Konsole Fahrersitz",
			en: "Support driver chair"
		}
		,A2V00002621839 : {
			de: "Dichtblech MC, w.g.",
			en: "Sealing plate mc, a.d."
		}
		,A2V00002621969 : {
			de: "Kastenblech MC, sp.g.",
			en: "Box plate mc, i.m.i."
		}
		,A2V00002621970 : {
			de: "Kastenblech MC, w.g.",
			en: "Box plate mc, a.d."
		}
		,A2V00002622062 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002622131 : {
			de: "Konsole Sollwertgeber",
			en: "Support director"
		}
		,A2V00002622207 : {
			de: "Exzenter",
			en: "Excenter"
		}
		,A2V00002622211 : {
			de: "Distanzbuchse",
			en: "Spacer"
		}
		,A2V00002622212 : {
			de: "Achse",
			en: "Axle"
		}
		,A2V00002622213 : {
			de: "Klemme",
			en: "Clamp"
		}
		,A2V00002563725 : {
			de: "Langtraeger vollst. Untergestell EW",
			en: "longitudinal girder compl. underframe ec"
		}
		,A2V00002563956 : {
			de: "Unterfahrschutz",
			en: "underrun bumper"
		}
		,A2V00002564456 : {
			de: "Anschlussprofil sp.gl.",
			en: "connection profile r.i."
		}
		,A2V00002564457 : {
			de: "Anschlussprofil w.g.",
			en: "connection profile as d."
		}
		,A2V00002564994 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002565077 : {
			de: "Knotenblech",
			en: "gusset plate"
		}
		,A2V00002565146 : {
			de: "Knotenblech",
			en: "gusset plate"
		}
		,A2V00002565205 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00113036391 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370060184 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00113051745 : {
			de: "Bandanzeigegeraet",
			en: "water level display"
		}
		,A2V00002565406 : {
			de: "Mittellangtraeger",
			en: "centre longitudinal"
		}
		,A2V00002565426 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00002565473 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00001783981 : {
			de: "BWL-Leitung 3",
			en: "VLR-Line 2"
		}
		,A2V00370074079 : {
			de: "Zylinderschraube ISO 4762 M10X90 A2-70",
			en: "Hexagon screw ISO 4762 M10X90 A2-70"
		}
		,A2V00001676548 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001780662 : {
			de: "Fangseil fuer Drehdaempfer",
			en: "wire rope for yaw damper"
		}
		,A2V00001212599 : {
			de: "Eingangsstufe potfr binÃ¤r 110V",
			en: "Input device"
		}
		,A2V00001714482 : {
			de: "Wagenuebergangs-  kabel 9",
			en: "Cables for Intercar Gangway 9"
		}
		,A2V00001721635 : {
			de: "Mitnahme, vollstaendig",
			en: "Drive piece, complete"
		}
		,A2V00001744752 : {
			de: "Temperatursensor  Radlager",
			en: "Temperature sensor wheel bearing"
		}
		,A2V00001746021 : {
			de: "Winkelgelenk",
			en: "Ball joint"
		}
		,A2V00370058092 : {
			de: "Stutzen VEZ-DN16-M26x1,5-G1/2A II14260/3",
			en: "Socket VEZ-DN16-M26x1,5-G1/2A II14260/3"
		}
		,A2V00001446112 : {
			de: "Wagenuebergangs-  kabel 2",
			en: "Cables for Intercar Gangway 2"
		}
		,A2V00001714485 : {
			de: "BWL-Leitung 2",
			en: "VLR-Line 2"
		}
		,A2V00001714506 : {
			de: "BWL-Leitung 1",
			en: "VLR-Line 1"
		}
		,A2V00001714483 : {
			de: "Wagenuebergangs-  kabel 10",
			en: "Cables for Intercar Gangway 10"
		}
		,A2V00001202826 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00102178990 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00102562596 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00106139680 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00113050274 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370039324 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370064647 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370082481 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001714238 : {
			de: "Ausdrehanschlag",
			en: "Turn stop"
		}
		,A2V00001721173 : {
			de: "RSL-Deckel Wegimpulsgeber",
			en: "WS-Cap pos. tr. for railr. contr. sys."
		}
		,A2V00001721175 : {
			de: "RSL-Deckel Gleitschutzgeber",
			en: "WS-Cap wheel slide sensor"
		}
		,A2V00001775545 : {
			de: "Klapptisch",
			en: "Table"
		}
		,A2V00001780036 : {
			de: "Grundrahmen, kompl. DC",
			en: "Base frame, compl. DC"
		}
		,A2V00113049894 : {
			de: "Luftfilter",
			en: "Air filter R1/2"
		}
		,A2V00001435929 : {
			de: "Sechskantmutter mit Klemmteil",
			en: "Hexaconal nut with clemp component"
		}
		,A2V00001757786 : {
			de: "Bodenklappe       vollst.",
			en: "trough flap       complete"
		}
		,A2V00001795884 : {
			de: "Luftfedergrundbeilage",
			en: "Shim for Air Spring"
		}
		,A2V00001408284 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00113014408 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00113034437 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00113050273 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002565518 : {
			de: "Blech Konsole",
			en: "sheet console"
		}
		,A2V00002108862 : {
			de: "Stirnwandverkleidung rechts, vollst.",
			en: "End wall panelling right, compl."
		}
		,A2V00002274450 : {
			de: "Abdeckung 18, vollst.",
			en: "Covering 18, compl."
		}
		,A2V00002144772 : {
			de: "E-Luftfedersteuerungsventil LDG",
			en: "E-Leveling valve TB"
		}
		,A2V00002144774 : {
			de: "E-Luftfedersteuerungsventil TDG",
			en: "E-Leveling valve MB"
		}
		,A2V00002128167 : {
			de: "Luftversorgungsanlage",
			en: "Pneumatic system installation"
		}
		,A2V00002274381 : {
			de: "Abdeckung 16, vollst.",
			en: "Covering 16, compl."
		}
		,A2V00002565563 : {
			de: "Gewindeplatte M20 Toilettenrack",
			en: "thread plate M20 toilet rack"
		}
		,A2V00001974807 : {
			de: "Seitenfenster Gross",
			en: "Side window"
		}
		,A2V00001902382 : {
			de: "Trennwand, vollstaendig",
			en: "partition wall, complete"
		}
		,A2V00001915515 : {
			de: "Trennwand, vollstaendig",
			en: "partition wall, complete"
		}
		,A2V00002625509 : {
			de: "Schweissbadsicherung, w.g.",
			en: "Weld backing, a.d."
		}
		,A2V00002625510 : {
			de: "Schweissbadsicherung, sp.g.",
			en: "Weld backing, i.m.i."
		}
		,A2V00002625514 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002625528 : {
			de: "Lasche WLB T1",
			en: "Spoiler support p1"
		}
		,A2V00002625530 : {
			de: "Lasche WLB T2",
			en: "Spoiler support p2"
		}
		,A2V00002566111 : {
			de: "Druckluftdurchfuehrung komplett",
			en: "pneumatic feedthrough complete"
		}
		,A2V00002566183 : {
			de: "Radkasten",
			en: "wheel housing"
		}
		,A2V00002567691 : {
			de: "Bremscontainer +76.70 komplett",
			en: "Brake container +76.70 complete"
		}
		,A2V00001420240 : {
			de: "Ventilinsel mech. vst.",
			en: "Valve cluster mech. compl."
		}
		,A2V00002567692 : {
			de: "E-Box Trainserver ++RBB+67.12 komplett",
			en: "E-Box Trainserver ++RBB+67.12 complete"
		}
		,A2V00001930839 : {
			de: "Vkl. C Saule rechts 01 sp.g. GFK vollst.",
			en: "cov. C pillar right 01   r.i. GRP compl."
		}
		,A2V00001863456 : {
			de: "Tuerfuehrung Uebergangstuer automat.",
			en: "automatic gangway door guiding"
		}
		,A2V00002568195 : {
			de: "Zwischenblech oben",
			en: "intermediate plate top"
		}
		,A2V00002568246 : {
			de: "Quertraeger geschweisst",
			en: "cross beam welded"
		}
		,A2V00002568571 : {
			de: "Quertraeger KK-Ende",
			en: "cross girder short coupler end"
		}
		,A2V00002568602 : {
			de: "Blech Kupplungskasten",
			en: "sheet coupler box"
		}
		,A2V00002568787 : {
			de: "H-Profil",
			en: "h-profile"
		}
		,A2V00002568788 : {
			de: "H-Profil",
			en: "h-profile"
		}
		,A2V00002628331 : {
			de: "Befestigungswinkel 07",
			en: "Mounting angle 07"
		}
		,A2V00002628345 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002568885 : {
			de: "Mitteltraeger",
			en: "central beam"
		}
		,A2V00002569142 : {
			de: "Querprofil bearbeitet",
			en: "cross profile machined"
		}
		,A2V00002569322 : {
			de: "Deckblech sp.g. KK-Ende",
			en: "cover plate r.i. short coupler end"
		}
		,A2V00002569323 : {
			de: "Deckblech w.g. KK-Ende",
			en: "cover plate as d. short coupler end"
		}
		,A2V00002569376 : {
			de: "Anschlag sp.g.",
			en: "endstop r.i."
		}
		,A2V00002569377 : {
			de: "Anschlag w.g.",
			en: "endstop as d."
		}
		,A2V00002569408 : {
			de: "Knotenblech w.g. KK-Ende",
			en: "gusset plate as d. short coupler end"
		}
		,A2V00001971878 : {
			de: "Gepaeckablage, links 1752 vollst.",
			en: "Luggage rack, left 1752 compl."
		}
		,A2V00002128134 : {
			de: "Zusatzgeraeteeinheit ZGE-118",
			en: "Auxillary control unit ZGE-118"
		}
		,A2V00001968003 : {
			de: "Spritzschutz  fuer Drehstabfeder",
			en: "splash guard for torsion bar spring"
		}
		,A2V00002110333 : {
			de: "Notrufeinrichtung Universaltoilette",
			en: "Emergency Call Unit Universal Toilet"
		}
		,A2V00001971784 : {
			de: "Gepaeckablage, rechts 2160 vollst.",
			en: "Luggage rack,right 2160 compl."
		}
		,A2V00001971886 : {
			de: "Gepaeckablage, links 2200 vollst.",
			en: "Luggage rack, left 2200 compl."
		}
		,A2V00001971879 : {
			de: "Gepaeckablage, rechts 1900 vollst.",
			en: "Luggage rack, right 1900 compl."
		}
		,A2V00002124155 : {
			de: "Triebdrehgestell 1, MOSL WE1",
			en: "powered bogie 1, MOSL EC1"
		}
		,A2V00002274390 : {
			de: "Abdeckung 17, vollst.",
			en: "Covering 17, compl."
		}
		,A2V00001798815 : {
			de: "Fensterfeld  01   vollstaendig",
			en: "Window field  01  complete"
		}
		,A2V00001798906 : {
			de: "Feder 01          vollstaendig",
			en: "Spring 01         complete"
		}
		,A2V00001798907 : {
			de: "Feder 02          vollstaendig",
			en: "Spring 02         complete"
		}
		,A2V00001805651 : {
			de: "Seitendecke 01    vollst.",
			en: "sideceiling 01    compl."
		}
		,A2V00001805691 : {
			de: "Seitendecke 08    vollst.",
			en: "sideceiling 08    compl."
		}
		,A2V00001840555 : {
			de: "Feder",
			en: "spring"
		}
		,A2V00001840598 : {
			de: "Seitendecke 17    vollst.",
			en: "sideceiling 17    compl."
		}
		,A2V00001891132 : {
			de: "Seitendecke 20    vollst.",
			en: "sideceiling 20     compl."
		}
		,A2V00001891133 : {
			de: "Seitendecke 21    vollst.",
			en: "sideceiling 21     compl."
		}
		,A2V00001891287 : {
			de: "Seitendecke 25    vollst.",
			en: "sideceiling 25    compl."
		}
		,A2V00001911555 : {
			de: "Lochdecke L2 kpl.",
			en: "Perforated cover L2 complete"
		}
		,A2V00001911556 : {
			de: "Lochdecke L3 kpl.",
			en: "perforated cover L3 complete"
		}
		,A2V00001911557 : {
			de: "Lochdecke L4 kpl.",
			en: "perforated cover L4 complete"
		}
		,A2V00001919720 : {
			de: "Lochdecke L20 kpl.",
			en: "Perforated cover L20 complete"
		}
		,A2V00001925757 : {
			de: "Lochdecke L22 kpl.",
			en: "Perforated cover L22 complete"
		}
		,A2V00001911554 : {
			de: "Lochdecke L1 klp.",
			en: "Perforated ceiling L1 complete"
		}
		,A2V00001397125 : {
			de: "ZUSATZGERAETEEINHEIT ZGE-62",
			en: "Assembly ZGE-62"
		}
		,A2V00002569556 : {
			de: "Knotenblech sp.g. KK-Ende",
			en: "gusset plate r.i. short coupler end"
		}
		,A2V00002569726 : {
			de: "Seitenprofil sp.g. Kupplungsplatte",
			en: "side profile r.i. coupler plate"
		}
		,A2V00002569727 : {
			de: "Seitenprofil w.g. Kupplungsplatte",
			en: "side profile as.d. coupler plate"
		}
		,A2V00002569869 : {
			de: "Langtraeger vollst. Untergestell EW",
			en: "longitudinal girder compl. underframe ec"
		}
		,A2V00002569905 : {
			de: "Untergurt sp.g.",
			en: "bottom boom as d."
		}
		,A2V00002570186 : {
			de: "Untergurt w.g.",
			en: "bottom boom w.g."
		}
		,A2V00002570277 : {
			de: "Ecke Stirnwand w.g.",
			en: "corner end wall as d."
		}
		,A2V00002570278 : {
			de: "Ecke Stirnwand sp.gl.",
			en: "corner end wall r.i."
		}
		,A2V00002629629 : {
			de: "Erdungslasche M6",
			en: "Earthing eye M6"
		}
		,A2V00002570576 : {
			de: "Knotenblech w.g.",
			en: "gusset plate as d."
		}
		,A2V00002570577 : {
			de: "Knotenblech sp.g.",
			en: "gusset plate r.i."
		}
		,A2V00002629817 : {
			de: "Quertraeger 06 MC ZSB",
			en: "Cross girder 06 MC ASSY"
		}
		,A2V00002570692 : {
			de: "Knotenblech w.g.",
			en: "gusset plate as d."
		}
		,A2V00002570730 : {
			de: "Knotenblech sp.g.",
			en: "gusset plate r.i."
		}
		,A2V00002630322 : {
			de: "Stirnwand WE2",
			en: "End wall CE2"
		}
		,A2V00002051428 : {
			de: "Fuehrerbremsventil FSB",
			en: "driver brake valve FBS1-2"
		}
		,A2V00001798898 : {
			de: "Blindfeld 01,        vollstaendig",
			en: "Blind field 01,      complete"
		}
		,A2V00001848303 : {
			de: "Blindfeld 08      vollstaendig",
			en: "Blind field 08    complete"
		}
		,A2V00001848304 : {
			de: "Blindfeld 09 vollstaendig",
			en: "Blindfield 09 complete"
		}
		,A2V00001848305 : {
			de: "Blindfeld 10      vollstaendig",
			en: "Blind field 10    complete"
		}
		,A2V00001840600 : {
			de: "Seitendecke 19    vollst.",
			en: "sideceiling 19   compl."
		}
		,A2V00001805652 : {
			de: "Seitendecke 02    vollst.",
			en: "sideceiling 02    compl."
		}
		,A2V00002630350 : {
			de: "Langtraeger, oberer, KW",
			en: "Sole bar, upper, KW"
		}
		,A2V00002630351 : {
			de: "Bodenplatte, aeussere",
			en: "Base plate, outer"
		}
		,A2V00002630352 : {
			de: "Bodenplatte 1, innere",
			en: "Base plate 1, inner"
		}
		,A2V00002630353 : {
			de: "Bodenplatte 2, innere",
			en: "Base plate 2, inner"
		}
		,A2V00002630355 : {
			de: "Langtraeger, innerer f. UG",
			en: "Sole bar, inner f. UF"
		}
		,A2V00002630356 : {
			de: "Langtraeger, unterer, KW",
			en: "Sole bar, bottom, KW"
		}
		,A2V00002630362 : {
			de: "Seitenwand, untere, WE1",
			en: "Side wall, bottom, CE1"
		}
		,A2V00002630363 : {
			de: "Seitenwand, untere, WE2",
			en: "Side wall, bottom, CE2"
		}
		,A2V00002630364 : {
			de: "Seitenwand, untere, mittlere",
			en: "Side wall, bottom, middle"
		}
		,A2V00002630368 : {
			de: "Dachlangtraeger 2, KW",
			en: "Roof sole bar 2, KW"
		}
		,A2V00002630369 : {
			de: "Dachbogen 1, aeusserer, KW",
			en: "Roof bow 1, outside, KW"
		}
		,A2V00002630370 : {
			de: "Dachbogen, mitte, KW",
			en: "Roof bow, middle, KW"
		}
		,A2V00002630371 : {
			de: "Dachbogen, innerer, KW",
			en: "Roof bow, inner, KW"
		}
		,A2V00002630372 : {
			de: "Dachlangtraeger 1, KW",
			en: "Roof sole bar 1, KW"
		}
		,A2V00002630398 : {
			de: "Kupplungskasten",
			en: "Couplerbox"
		}
		,A2V00002572048 : {
			de: "Blech Kupplungskasten",
			en: "Sheet coupler box"
		}
		,A2V00002630414 : {
			de: "Seitenwandprofil sp.g.",
			en: "Sidewall profile i.m.i."
		}
		,A2V00002630415 : {
			de: "Seitenwandprofil w.g.",
			en: "Side wall profile a.d."
		}
		,A2V00002630431 : {
			de: "Hauptquertraeger, vollst.",
			en: "Mainbeam, complete"
		}
		,A2V00002630438 : {
			de: "Entwaesserungsrohr, lang",
			en: "Drainage pipe, long"
		}
		,A2V00002630440 : {
			de: "Auflageblech sp.g.",
			en: "Support sheet i.m.i."
		}
		,A2V00002572341 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00002630441 : {
			de: "Auflageblech w.g.",
			en: "Support sheet a.d."
		}
		,A2V00002630458 : {
			de: "Kragen",
			en: "Collar"
		}
		,A2V00002630459 : {
			de: "Bodenblechauflage 1, w.g.",
			en: "Floor plate support 1, a.d."
		}
		,A2V00002630460 : {
			de: "Bodenblechauflage 2, w.g.",
			en: "Floor plate support 2, a.d."
		}
		,A2V00002630461 : {
			de: "Bodenauflage bearb.",
			en: "Floorsupport machined"
		}
		,A2V00002572480 : {
			de: "Seitenwand, Dachhaube",
			en: "side wall, roof box"
		}
		,A2V00002630468 : {
			de: "Grundplatte",
			en: "Base plate"
		}
		,A2V00002630469 : {
			de: "Blech f. H-Profil sp.g.",
			en: "Plate f. h-profile i.m.i."
		}
		,A2V00002572489 : {
			de: "Deckel, Dachhaube",
			en: "cover, roof box"
		}
		,A2V00002630470 : {
			de: "Blech f. H-Profil w.g.",
			en: "Plate f. h-profile a.d."
		}
		,A2V00002572490 : {
			de: "Deckel, Dachhaube",
			en: "cover, roof box"
		}
		,A2V00002630471 : {
			de: "Bodenblech, vorne 1",
			en: "Floorpanel, front 1"
		}
		,A2V00002572562 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002630472 : {
			de: "I-Profil",
			en: "I-Profile"
		}
		,A2V00002630474 : {
			de: "Bodenblechauflage 1, sp.g.",
			en: "Floor plate support 1, i.m.i."
		}
		,A2V00002714488 : {
			de: "Befestigungswinkel",
			en: "Mounting bracket"
		}
		,A2V00002573319 : {
			de: "Badsicherung HQT",
			en: "weld pool backing HQT"
		}
		,A2V00002630493 : {
			de: "Kupplungskasten KKE",
			en: "Couplerbox SCE"
		}
		,A2V00002714602 : {
			de: "Befestigungswinkel WG",
			en: "Mounting bracket AD"
		}
		,A2V00002714603 : {
			de: "Befestigungswinkel SPG",
			en: "Mounting bracket OAD"
		}
		,A2V00002573584 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002573809 : {
			de: "Halter vollst.",
			en: "holder compl."
		}
		,A2V00002573911 : {
			de: "Platte vollst.",
			en: "plate compl."
		}
		,A2V00002573965 : {
			de: "Flansch vollst.",
			en: "flange compl."
		}
		,A2V00002630521 : {
			de: "Anhebestelle WE1, w.g.",
			en: "Lifting point CE1, a.d."
		}
		,A2V00002630522 : {
			de: "Anhebestelle WE1, sp.g.",
			en: "Lifting point CE1, i.m.i."
		}
		,A2V00002630527 : {
			de: "Bodenblech 1, ZSB",
			en: "Floor plate 1, assy."
		}
		,A2V00002630528 : {
			de: "Versteifung 2, Vorbau WE1",
			en: "Stiffener 2, Advancing CE1"
		}
		,A2V00002630529 : {
			de: "Versteifung 1, Vorbau WE1",
			en: "Stiffener 1, Advancing CE1"
		}
		,A2V00002574641 : {
			de: "Halter w.g.",
			en: "holder as d."
		}
		,A2V00002630535 : {
			de: "Mittellangtraeger vollst.",
			en: "Middle long.beam compl."
		}
		,A2V00002630536 : {
			de: "Bodenblech 2, ZSB",
			en: "Floor plate 2, assy."
		}
		,A2V00002574656 : {
			de: "Halter sp.gl.",
			en: "holder r.i."
		}
		,A2V00002630537 : {
			de: "I-Profil vollst.",
			en: "I-profile complete"
		}
		,A2V00002630539 : {
			de: "Bodenblech 3, ZSB",
			en: "Floor plate 3, assy."
		}
		,A2V00002630544 : {
			de: "Anhebestelle WE2 sp.g.",
			en: "Liftingpoint CE2 i.m.i."
		}
		,A2V00002630546 : {
			de: "Anhebestelle WE2 w.g.",
			en: "Liftingpoint CE2 a.d."
		}
		,A2V00002630548 : {
			de: "Tasche KKE",
			en: "Bin SCE"
		}
		,A2V00002630549 : {
			de: "Bodenblech 4, ZSB",
			en: "Floor plate 4, assy."
		}
		,A2V00002630558 : {
			de: "Bodenplatte, vorne",
			en: "Baseplate, front"
		}
		,A2V00002630559 : {
			de: "Pufferbrust vollst.",
			en: "Bumper compl."
		}
		,A2V00002630566 : {
			de: "Quertraeger WE2, ZSB",
			en: "Quertraeger CE2, Assy."
		}
		,A2V00002630595 : {
			de: "Traeger links ZSB, sp.g.",
			en: "Bar left Assy., i.m.i."
		}
		,A2V00002630596 : {
			de: "Traeger rechts ZSB, w.g.",
			en: "Bar right Assy,a.d."
		}
		,A2V00002630606 : {
			de: "Querverbindung w.g.",
			en: "Connecting plate a.d."
		}
		,A2V00002630607 : {
			de: "Querverbindung sp.g.",
			en: "Connecting plate i.m.i."
		}
		,A2V00002630614 : {
			de: "Rohrhalter ZSB",
			en: "Pipesupport assy."
		}
		,A2V00002630619 : {
			de: "Zwischenprofil",
			en: "Intermediate profile"
		}
		,A2V00002630623 : {
			de: "Deckel LT UG, sp.g.",
			en: "Cover plate, i.m.i."
		}
		,A2V00002630624 : {
			de: "Deckel LT UG, w.g.",
			en: "Cover plate, a.d."
		}
		,A2V00002630630 : {
			de: "I-Profil",
			en: "I-Profile"
		}
		,A2V00002630633 : {
			de: "Rippe, sp.g.",
			en: "Rib, i.m.i."
		}
		,A2V00002630634 : {
			de: "Rippe, w.g.",
			en: "Rib, a.d."
		}
		,A2V00002630639 : {
			de: "Dachlangtraeger, unterer",
			en: "Roof cantrail, lower"
		}
		,A2V00002630640 : {
			de: "Dachlangtraeger, unterer",
			en: "Roof cantrail, lower"
		}
		,A2V00002630641 : {
			de: "Stegblech, w.g.",
			en: "Web plate, a.d."
		}
		,A2V00002630642 : {
			de: "Querprofil",
			en: "Cross profile"
		}
		,A2V00002630644 : {
			de: "Stegblech, w.g.",
			en: "Web plate, a.d."
		}
		,A2V00002630645 : {
			de: "Stegblech, sp.g.",
			en: "Web plate, i.m.i."
		}
		,A2V00002630651 : {
			de: "Stegblech, sp.g.",
			en: "Web plate, i.m.i."
		}
		,A2V00002630656 : {
			de: "Bodenblech, vorne 1",
			en: "Floorpanel, front 1"
		}
		,A2V00002630661 : {
			de: "Zwischenblech, w.g.",
			en: "Connectionssheet, a.d."
		}
		,A2V00002630664 : {
			de: "Zwischenblech, sp.g.",
			en: "Connectionssheet, i.m.i."
		}
		,A2V00002576532 : {
			de: "Blech sp.gl.",
			en: "plate r.i."
		}
		,A2V00002576533 : {
			de: "Blech w.g.",
			en: "plate as d."
		}
		,A2V00002630673 : {
			de: "Querprofil WE1",
			en: "Crossprofile CE1"
		}
		,A2V00002630674 : {
			de: "Stegblech w.g.",
			en: "Web plate a.d."
		}
		,A2V00002630675 : {
			de: "Stegblech w.g.",
			en: "Web plate a.d."
		}
		,A2V00002630676 : {
			de: "Stegblech sp.g.",
			en: "Web plate i.m.i."
		}
		,A2V00002630677 : {
			de: "Querverbindung sp.g.",
			en: "Connecting plate i.m.i"
		}
		,A2V00002630683 : {
			de: "Querverbindung w.g.",
			en: "Connecting plate a.d."
		}
		,A2V00002630684 : {
			de: "Stegblech sp.g.",
			en: "Web plate i.m.i."
		}
		,A2V00002630685 : {
			de: "Versteifungsblech sp.g.",
			en: "Reinforcement sheet i.m.i."
		}
		,A2V00002630686 : {
			de: "Versteifungsblech w.g.",
			en: "Reinforcement sheet as drawn"
		}
		,A2V00002576793 : {
			de: "Verstaerkung sp.gl.",
			en: "reinforcement r.i."
		}
		,A2V00002630691 : {
			de: "Bodenplatte ZSB",
			en: "Baseplate compl."
		}
		,A2V00002576794 : {
			de: "Verstaerkung w.g.",
			en: "reinforcement as d."
		}
		,A2V00002630695 : {
			de: "Basisplatte",
			en: "Basic plate"
		}
		,A2V00002630697 : {
			de: "Handstangenbasis ZSB",
			en: "Handhold basic Assy."
		}
		,A2V00002630709 : {
			de: "Gurtblech, w.g.",
			en: "Plate, as drawn"
		}
		,A2V00002630710 : {
			de: "Gurtblech, sp.g.",
			en: "Plate, i.m.i."
		}
		,A2V00002630711 : {
			de: "Kragen",
			en: "Flange"
		}
		,A2V00002630713 : {
			de: "Zentrierstift",
			en: "Locating pin"
		}
		,A2V00002576921 : {
			de: "Verstaerkung",
			en: "reinforcement"
		}
		,A2V00002630714 : {
			de: "Rippe 4 w.g.",
			en: "Rib 4 sp.g."
		}
		,A2V00002630715 : {
			de: "Rippe 3 w.g.",
			en: "Rib 3 sp.g."
		}
		,A2V00002577014 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002630720 : {
			de: "Verbindung",
			en: "Connection"
		}
		,A2V00002630722 : {
			de: "Deckel w.g.",
			en: "Cover plate a.d."
		}
		,A2V00002630723 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002630725 : {
			de: "Rippe 3 sp.g.",
			en: "Rib 3 sym.opp."
		}
		,A2V00002630726 : {
			de: "Rippe 4 sp.g.",
			en: "Rib 4 sym.opp."
		}
		,A2V00002630727 : {
			de: "Rippe w.g.",
			en: "Rib a.d."
		}
		,A2V00002630728 : {
			de: "Deckblech, sp.g.",
			en: "Cover plate, i.m.i."
		}
		,A2V00002630729 : {
			de: "Deckblech, w.g.",
			en: "Cover plate, a.d."
		}
		,A2V00002630730 : {
			de: "Stegblech w.g.",
			en: "Web plate a.d."
		}
		,A2V00002630731 : {
			de: "Deckel w.g.",
			en: "Cover plate a.d."
		}
		,A2V00002630732 : {
			de: "Deckblech, w.g.",
			en: "Cover plate, a.d."
		}
		,A2V00002630733 : {
			de: "Deckel sp.g.",
			en: "Cover plate i.m.i."
		}
		,A2V00002630734 : {
			de: "Deckblech w.g.",
			en: "Cover plate a.d."
		}
		,A2V00002630735 : {
			de: "Deckblech sp.g.",
			en: "Cover plate i.m.i."
		}
		,A2V00002577264 : {
			de: "Passstueck sp.gl.",
			en: "fitting part r.i."
		}
		,A2V00002630737 : {
			de: "Deckel sp.g.",
			en: "Cover plate i.m.i."
		}
		,A2V00002630738 : {
			de: "Rippe sp.g.",
			en: "Rib i.m.i."
		}
		,A2V00002577265 : {
			de: "Passstueck w.g.",
			en: "fitting part as.d."
		}
		,A2V00002630741 : {
			de: "Dachversteifung, Profil",
			en: "Roof stiffener. profile"
		}
		,A2V00002630746 : {
			de: "Montageschieneprofil 15",
			en: "Assembly rail profile 15"
		}
		,A2V00002630761 : {
			de: "Rauchbarriere",
			en: "Smoke barrier"
		}
		,A2V00002630762 : {
			de: "Eckrippe sp.g.",
			en: "Corner rib i.m.i."
		}
		,A2V00002630763 : {
			de: "Eckrippe w.g.",
			en: "Corner rib a.d."
		}
		,A2V00002630765 : {
			de: "Laengstraeger Cabrahmen w.g.",
			en: "Side member cab frame a.d."
		}
		,A2V00002630767 : {
			de: "Laengstraeger Cabrahmen sp.g.",
			en: "Side member cab frame i.m.i."
		}
		,A2V00002630769 : {
			de: "Tuersaeule sp.g.",
			en: "Door pillar i.m.i."
		}
		,A2V00002630777 : {
			de: "Tuersaeule w.g.",
			en: "Door pillar a.d."
		}
		,A2V00002630795 : {
			de: "Rahmenbogen sp.g.",
			en: "Frame bend i.m.i."
		}
		,A2V00002630797 : {
			de: "Quertraeger Mitte",
			en: "Middle cross member"
		}
		,A2V00002630799 : {
			de: "Rahmenbogen w.g.",
			en: "Frame bend a.d."
		}
		,A2V00002630805 : {
			de: "Verteilerkasten ZSB",
			en: "Junction box assy."
		}
		,A2V00002630816 : {
			de: "Bodenblech 2",
			en: "Floor plate 2"
		}
		,A2V00002630835 : {
			de: "Bodenplatte, KKE, ZSB",
			en: "Floorplate, End 2, Assy."
		}
		,A2V00002630836 : {
			de: "Halter vollst.",
			en: "Holder compl."
		}
		,A2V00002630842 : {
			de: "Kabeldurchfuehrung ZSB",
			en: "Cable penetration assy."
		}
		,A2V00002630844 : {
			de: "Abdeckplatte",
			en: "Cover plate"
		}
		,A2V00002630847 : {
			de: "Deckblech, ZSB, sp.g.",
			en: "Cover plate, Assy., i.m.i."
		}
		,A2V00002630848 : {
			de: "Deckblech, ZSB, w.g.",
			en: "Cover plate, Assy., a.d."
		}
		,A2V00002630849 : {
			de: "Rippe w.g.",
			en: "Rib a.d."
		}
		,A2V00002630850 : {
			de: "Rippe sp.g.",
			en: "Rib i.m.i."
		}
		,A2V00002630851 : {
			de: "Rippe sp.g.",
			en: "Rib i.m.i."
		}
		,A2V00002630852 : {
			de: "Rippe w.g.",
			en: "Rib a.d."
		}
		,A2V00002630855 : {
			de: "Rippe, sp.g.",
			en: "Rib, i.m.i."
		}
		,A2V00002630856 : {
			de: "Rippe, w.g.",
			en: "Rib, a.d."
		}
		,A2V00002630859 : {
			de: "Halter Pultgeruest",
			en: "Holder drivers desk rack"
		}
		,A2V00002630865 : {
			de: "Halter Pultgeruest, ZSB",
			en: "Holder drivers desk rack, Assy."
		}
		,A2V00002630869 : {
			de: "Montageschieneprofil 10",
			en: "Assembly rail profile 10"
		}
		,A2V00002630872 : {
			de: "Stirnwand WE1, ZSB",
			en: "End wall CE1, Assy."
		}
		,A2V00002630884 : {
			de: "Halter, ZSB, w.g.",
			en: "Holder, Assy., a.d."
		}
		,A2V00002630886 : {
			de: "Einstellteil",
			en: "Adjustingpart"
		}
		,A2V00002630887 : {
			de: "Halter, ZSB",
			en: "Holder, Assy."
		}
		,A2V00002630889 : {
			de: "Gewindeteil",
			en: "Nut"
		}
		,A2V00002630891 : {
			de: "Halter, ZSB, w.g.",
			en: "Holder, Assy., a.d."
		}
		,A2V00002630892 : {
			de: "Halter, ZSB, sp.g.",
			en: "Holder, Assy., i.m.i."
		}
		,A2V00002630897 : {
			de: "Markierungsblech",
			en: "Marking plate"
		}
		,A2V00002630902 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002630903 : {
			de: "Rippe w.g.",
			en: "Rib a.d."
		}
		,A2V00002630904 : {
			de: "Verschlussblech sp.g.",
			en: "Closing sheet w.g."
		}
		,A2V00002630905 : {
			de: "Verschlussblech w.g.",
			en: "Closing sheet a.d."
		}
		,A2V00002630909 : {
			de: "L-Blech, ZSB, sp.g.",
			en: "L-Sheet, Assy., i.m.i."
		}
		,A2V00002630910 : {
			de: "L-Blech, ZSB, w.g.",
			en: "L-Sheet, Assy., a.d."
		}
		,A2V00002630911 : {
			de: "Laengenausgleich Dach",
			en: "Length compensation roof"
		}
		,A2V00002630912 : {
			de: "Laengenausgleich Dach w.g.",
			en: "Length compensation roof a.d."
		}
		,A2V00002630913 : {
			de: "Laengenausgleich Dach sp.g.",
			en: "Length compensation roof i.m.i."
		}
		,A2V00002630934 : {
			de: "Montageschieneprofil 15",
			en: "Assembly rail profile 15"
		}
		,A2V00002630935 : {
			de: "Montageschieneprofil 15",
			en: "Assembly rail profile 15"
		}
		,A2V00002630937 : {
			de: "Montageschieneprofil 15",
			en: "Assembly rail profile 15"
		}
		,A2V00002630954 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002630959 : {
			de: "Abdeckung 1",
			en: "Cover 1"
		}
		,A2V00002578371 : {
			de: "Winkel",
			en: "Angle"
		}
		,A2V00002630961 : {
			de: "PlatteÂ ZSB",
			en: "PlateÂ Assy."
		}
		,A2V00002630962 : {
			de: "Befestigungskonsole ZSB",
			en: "Mounting console,Â Assy."
		}
		,A2V00002630963 : {
			de: "Halter vollst.",
			en: "Bracket compl."
		}
		,A2V00002630964 : {
			de: "Platte ZSB",
			en: "Plate Assy."
		}
		,A2V00002630965 : {
			de: "Befestigungskonsole ZSB",
			en: "Mounting console,Â Assy."
		}
		,A2V00002630966 : {
			de: "Befestigungskonsole ZSB",
			en: "Mounting console,Â Assy."
		}
		,A2V00002630967 : {
			de: "Abdeckung",
			en: "Cover"
		}
		,A2V00002630969 : {
			de: "Montageschieneprofil 15",
			en: "Assembly rail profile 15"
		}
		,A2V00002630970 : {
			de: "Montageschieneprofil 15",
			en: "Assembly rail profile 15"
		}
		,A2V00002630971 : {
			de: "Montageschieneprofil 15",
			en: "Assembly rail profile 15"
		}
		,A2V00002630972 : {
			de: "Montageschieneprofil 15",
			en: "Assembly rail profile 15"
		}
		,A2V00002630973 : {
			de: "Montageschieneprofil 15",
			en: "Assembly rail profile 15"
		}
		,A2V00002630980 : {
			de: "Halter Pultgeruest",
			en: "Holder drivers desk rack"
		}
		,A2V00002630982 : {
			de: "Verschlussblech 1",
			en: "Closingsheet 1"
		}
		,A2V00002630983 : {
			de: "Verschlussblech 2",
			en: "Closingsheet 2"
		}
		,A2V00002630984 : {
			de: "Montageschieneprofil 15",
			en: "Assembly rail profile 15"
		}
		,A2V00002630985 : {
			de: "Lasche",
			en: "Strip"
		}
		,A2V00002630986 : {
			de: "Lasche",
			en: "Strip"
		}
		,A2V00002630990 : {
			de: "Rohrdurchfuehrung, Befuellschlauch",
			en: "Pipe fairlead, filler hose"
		}
		,A2V00002630991 : {
			de: "Rohrdurchfuehrung, Entlueftungsschlauch",
			en: "Pipe fairlead, breather hose"
		}
		,A2V00002630993 : {
			de: "Gewindeteil",
			en: "Nut"
		}
		,A2V00002630994 : {
			de: "Halter 2, ZSB",
			en: "Holder 2, Assy."
		}
		,A2V00002630996 : {
			de: "Halter, ZSB, sp.g.",
			en: "Holder, Assy., i.m.i."
		}
		,A2V00002631001 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002631002 : {
			de: "Montageschieneprofil 15",
			en: "Assembly rail profile 15"
		}
		,A2V00002631004 : {
			de: "Platte ZSB",
			en: "Plate, Assy."
		}
		,A2V00002631013 : {
			de: "Montageschieneprofil 15",
			en: "Assembly rail profile 15"
		}
		,A2V00002631019 : {
			de: "Sitzrahmen, ZSB",
			en: "Seat frame, Assy."
		}
		,A2V00002631022 : {
			de: "Rippe 2, sp.g.",
			en: "Rib 2, i.m.i"
		}
		,A2V00002631023 : {
			de: "Rippe 1, sp.g.",
			en: "Rib 1, i.m.i"
		}
		,A2V00002631024 : {
			de: "Rippe 2, w.g.",
			en: "Rib 2, a.d."
		}
		,A2V00355404266 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002579695 : {
			de: "C-Schiene Klappsitz",
			en: "c-rail folding seat"
		}
		,A2V00113051648 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001418433 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00156007899 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002631025 : {
			de: "Rippe 1, w.g.",
			en: "Rib 1, a.d."
		}
		,A2V00001933154 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002178870 : {
			de: "Steg 3",
			en: "Web 3"
		}
		,A2V00002625704 : {
			de: "Anschlussteile f. Flexball -Typ 160",
			en: "connecting parts f. Flexball - type 160"
		}
		,A2V00002600798 : {
			de: "Schlauchverbindung",
			en: "hose connection"
		}
		,A2V00002600799 : {
			de: "Schlauchverbindung",
			en: "Hose connection"
		}
		,A2V00002677832 : {
			de: "Bremsbelag C105737/20A0Z",
			en: "Brake pad, C105737/20A0Z"
		}
		,A2V00001795111 : {
			de: "Druckfederaufhaengung",
			en: "Compression spring suspension unit"
		}
		,A2V00001795110 : {
			de: "Magnetschienenbremse GL 70 S-42",
			en: "Magnetic track brake GL 70 S-42"
		}
		,A2V00001795576 : {
			de: "Bremsbelaghalter, links",
			en: "Holder for brake pad, left"
		}
		,A2V00001795578 : {
			de: "Bremsbelaghalter, rechts",
			en: "Holder for brake pad, right"
		}
		,A2V00001795580 : {
			de: "Haengelasche   I 93347/B",
			en: "Suspension strip   I 93347/B"
		}
		,A2V00001795583 : {
			de: "Bolzen mit Schmiernippel  I84974",
			en: "Bolt with lubrication nipple  I84974"
		}
		,A2V00001795417 : {
			de: "Federspeicher Bremszylinder I70434/2210",
			en: "Brake cylinder I70434/2210LL"
		}
		,A2V00002184779 : {
			de: "Motornotfang, unten",
			en: "Motor emergency stop, low"
		}
		,A2V00002186148 : {
			de: "Buchse f. Motoraufnahme 2",
			en: "Bush for motor bracket 2"
		}
		,A2V00002186418 : {
			de: "Rippe 1",
			en: "Rib 1"
		}
		,A2V00001795255 : {
			de: "Bremsscheibe, geteilt W540/288x90",
			en: "Brake disc, split W540/288x90"
		}
		,A2V00001418501 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002186546 : {
			de: "Steg 2",
			en: "Web 2"
		}
		,A2V00002579948 : {
			de: "Seitenwandmodul Mitte li",
			en: "sidewall middle left"
		}
		,A2V00002631027 : {
			de: "Halter vollst.",
			en: "Holder compl."
		}
		,A2V00002186564 : {
			de: "Steg 4",
			en: "Web 4"
		}
		,A2V00002631031 : {
			de: "Fuehrung vollst.",
			en: "Retainer compl."
		}
		,A2V00002631033 : {
			de: "Fuehrung vollst.",
			en: "Retainer compl."
		}
		,A2V00002631034 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002631035 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002631036 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002631037 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002631038 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002631039 : {
			de: "Deckblech, sp.g.",
			en: "Cover plate, i.m.i."
		}
		,A2V00002631041 : {
			de: "Ersatz L-Traeger 1",
			en: "Replace L-Bar 1"
		}
		,A2V00002631043 : {
			de: "Schweissbadsicherung Handstange",
			en: "Backing strip handrail"
		}
		,A2V00002631044 : {
			de: "Halterohr 2",
			en: "Fixingtube 2"
		}
		,A2V00002631048 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002631049 : {
			de: "Trennblech 1, sp.g.",
			en: "Separating plate 1, i.m.i.."
		}
		,A2V00002631050 : {
			de: "Trennblech 1, w.g.",
			en: "Separating plate 1, a.d."
		}
		,A2V00002631053 : {
			de: "Zwischenblech sp.g.",
			en: "Connectionssheet i.m.i."
		}
		,A2V00002580269 : {
			de: "Schweissbadsicherung Tuersaeule",
			en: "weld pool backing ddor post"
		}
		,A2V00002631056 : {
			de: "Zwischenblech w.g.",
			en: "Connectionssheet a.d."
		}
		,A2V00002580594 : {
			de: "Seitenwand links Modul KK-Ende",
			en: "sidewall left module short coupler end"
		}
		,A2V00002631210 : {
			de: "Langtraeger, oberer, MW",
			en: "Sole bar, upper, MW"
		}
		,A2V00002631211 : {
			de: "Dachlangtraeger 2, MW",
			en: "Roof sole bar 2, MW"
		}
		,A2V00002631212 : {
			de: "Dachbogen 1, aeusserer, MW",
			en: "Roof bow 1, outside, MW"
		}
		,A2V00002631213 : {
			de: "Dachbogen, mitte, MW",
			en: "Roof bow, middle, MW"
		}
		,A2V00002631214 : {
			de: "Dachbogen, innerer, MW",
			en: "Roof bow, inner, MW"
		}
		,A2V00002631215 : {
			de: "Dachlangtraeger 1, MW",
			en: "Roof sole bar 1, MW"
		}
		,A2V00002631216 : {
			de: "Langtraeger, unterer, WM",
			en: "Sole bar, bottom, MW"
		}
		,A2V00002580705 : {
			de: "Seitenwandmodul mitte links vollst.",
			en: "sidewall middel left compl."
		}
		,A2V00002631234 : {
			de: "Bodenplatte, vorne M-Wg, ZSB",
			en: "Floorpanel, front, M-car, Assy."
		}
		,A2V00002631238 : {
			de: "Montageschieneprofil 10",
			en: "Assembly rail profile 10"
		}
		,A2V00002631240 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002580762 : {
			de: "Seitenwand rechts Modul KK-Ende",
			en: "sidewall right module short coupler end"
		}
		,A2V00002631241 : {
			de: "Entwaesserung",
			en: "Drainage"
		}
		,A2V00002631259 : {
			de: "Klimaleitungsrohr geschweisst",
			en: "HVAC supply pipe welded"
		}
		,A2V00002631295 : {
			de: "Zugentlastung doppelt vollst.",
			en: "Strain relief double compl."
		}
		,A2V00002186632 : {
			de: "Untergurt",
			en: "Lower flange"
		}
		,A2V00002186649 : {
			de: "Motoraufnahme 1",
			en: "Motor bracket 1"
		}
		,A2V00002581972 : {
			de: "Verstaerkung links kompl.",
			en: "reinforcment left compl."
		}
		,A2V00002581986 : {
			de: "A-Saeule links kompl.",
			en: "A-post left compl."
		}
		,A2V00002186670 : {
			de: "Rippe 3",
			en: "Rib 3"
		}
		,A2V00002186672 : {
			de: "Obergurt 1",
			en: "Upper flange 1"
		}
		,A2V00002186988 : {
			de: "Obergurt 2",
			en: "Upper flange 2"
		}
		,A2V00002187759 : {
			de: "Buchse f. Motoraufnahme 3",
			en: "Bush for motor bracket 3"
		}
		,A2V00002190648 : {
			de: "Pufferkonsole",
			en: "Transversal stop bracket"
		}
		,A2V00002582028 : {
			de: "A-Saeule rechts kompl.",
			en: "A-post right compl."
		}
		,A2V00002190786 : {
			de: "Halter fuer Steuerventil",
			en: "Control valve bracket"
		}
		,A2V00002582230 : {
			de: "Verstaerkung re. kompl.",
			en: "reinforcment ri. compl."
		}
		,A2V00200900118 : {
			de: "Erdungsanschluss",
			en: "Grounding lug"
		}
		,A2V00001401866 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00002553241 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001418817 : {
			de: "Langtraegerprofil",
			en: "Side sill profile"
		}
		,A2V00002582866 : {
			de: "Seitenwandmodul mitte rechts vollst.",
			en: "sidewall middel right compl."
		}
		,A2V00002583624 : {
			de: "Seitenwandmodul F-Ende links",
			en: "sidewall frontend left"
		}
		,A2V00002583883 : {
			de: "Langtraeger vollst. Untergestell EWB",
			en: "longitudinal girder compl. underframe B"
		}
		,A2V00002584000 : {
			de: "Langtraeger vollst. Untergestell EWB",
			en: "longitudinal girder compl. underframe B"
		}
		,A2V00002584014 : {
			de: "Seitenwand links Modul F-Ende",
			en: "sidewall left module frontend"
		}
		,A2V00002584027 : {
			de: "Seitenwand links Modul KK-Ende",
			en: "sidewall left module short coupler end"
		}
		,A2V00002584030 : {
			de: "Seitenwand rechts Modul KK-Ende",
			en: "sidewall right module short coupler end"
		}
		,A2V00002584130 : {
			de: "Seitenwand rechts Modul F-Ende",
			en: "sidewall right module frontend"
		}
		,A2V00002584411 : {
			de: "Liefergarnitur Vorbau WE",
			en: "Delivering set Frontend CE"
		}
		,A2V00002584413 : {
			de: "Liefergarnitur Vorbau WE",
			en: "Delivering set Frontend CE"
		}
		,A2V00002584414 : {
			de: "Liefergarnitur Vorbau WE",
			en: "Delivering set Frontend CE"
		}
		,A2V00002584415 : {
			de: "Liefergarnitur Vorbau WE",
			en: "Delivering set Frontend CE"
		}
		,A2V00002584606 : {
			de: "Liefergarnitur Vorbau WE",
			en: "Delivering set Frontend CE"
		}
		,A2V00002584629 : {
			de: "Seitenwandmodul F-Ende rechts",
			en: "sidewall frontend right"
		}
		,A2V00001841350 : {
			de: "LZB Winkel",
			en: "LZB angel"
		}
		,A2V00001674151 : {
			de: "Drehzapfen, lackiert",
			en: "centre pivot, painted"
		}
		,A2V00001873172 : {
			de: "Treibradsatzwelle, lackiert",
			en: "motor axle"
		}
		,A2V00001219957 : {
			de: "DECKPLATTE",
			en: "COVER PLATE"
		}
		,A2V00001757889 : {
			de: "Zylinderschraube",
			en: "Hexag. sock. head cap screw"
		}
		,A2V00001780959 : {
			de: "Kardanhohlwelle u. Keilpakete",
			en: "hollow shaft star incl. cover"
		}
		,A2V00397148104 : {
			de: "DRUCKLUFTLEITUNG",
			en: "Pneumatic pipe"
		}
		,A2V00001826638 : {
			de: "Primaerfederbeilageinstellung (N Spur)",
			en: "Adjustment of Shim of primary spring"
		}
		,A2V00001185060 : {
			de: "Kontaktscheibe",
			en: "Contact disk"
		}
		,A2V00001421042 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001841553 : {
			de: "Schutzblech",
			en: "Protection panel"
		}
		,A2V00397191241 : {
			de: "ANLENKUNG",
			en: "Bogie Linkage"
		}
		,A2V00001695675 : {
			de: "Schienenraeumer WG",
			en: "rail guard as drawn"
		}
		,A2V00001777879 : {
			de: "Schienenraeumer SPG",
			en: "lifeguard opposite as drawn"
		}
		,A2V00397144826 : {
			de: "Radschutzkasten",
			en: "wheel guard"
		}
		,A2V00002585158 : {
			de: "Winkel sp.g.",
			en: "angle r.i."
		}
		,A2V00002585159 : {
			de: "Winkel w.g.",
			en: "angle as d."
		}
		,A2V00002656816 : {
			de: "Fangkonsole SWT",
			en: "Catching console WP"
		}
		,A2V00002660151 : {
			de: "Profil",
			en: "Profile"
		}
		,A2V00002667108 : {
			de: "Laengsholm",
			en: "Longitudinal beam"
		}
		,A2V00002667111 : {
			de: "Querholm 1",
			en: "Cross beam 1"
		}
		,A2V00002586067 : {
			de: "Bremswiderstand",
			en: "Brake resistor"
		}
		,A2V00002586071 : {
			de: "Saugkreisdrossel 100 Hz",
			en: "resinance filter joke 100 Hz"
		}
		,A2V00002667327 : {
			de: "Querholm 2",
			en: "Cross beam 2"
		}
		,A2V00002667328 : {
			de: "Holm",
			en: "Beam"
		}
		,A2V00002586213 : {
			de: "Verstaerkung",
			en: "reinforcement"
		}
		,A2V00002667399 : {
			de: "Befestigungslasche",
			en: "Mounting plate"
		}
		,A2V00002586293 : {
			de: "Winkel w.g. Schottwand",
			en: "Bracket as d. partition wall"
		}
		,A2V00002586294 : {
			de: "Winkel sp.g. Schottwand",
			en: "Bracket r.i. partition wall"
		}
		,A2V00002586332 : {
			de: "Blech Schottwand",
			en: "Sheet partition wall"
		}
		,A2V00002667593 : {
			de: "Befestigungslasche fÃ¼r Ventilkasten",
			en: "Support plate for valve box"
		}
		,A2V00002586470 : {
			de: "Riegel Schottwand",
			en: "locking bar partition wall"
		}
		,A2V00002667639 : {
			de: "Aufnahme Kuppelstange",
			en: "Support coupling rod"
		}
		,A2V00002586514 : {
			de: "Badsicherung w.g.",
			en: "weld pool backing as d."
		}
		,A2V00002586535 : {
			de: "Eckprofil w.g.",
			en: "Edge profile a.d."
		}
		,A2V00002667652 : {
			de: "Lasche Schiebetritt 1_3",
			en: "Plate sliding step 1_3"
		}
		,A2V00002586583 : {
			de: "Badsicherung sp.gl.",
			en: "weld pool backing r.i."
		}
		,A2V00002586658 : {
			de: "Eckprofil",
			en: "Edge profile"
		}
		,A2V00002586682 : {
			de: "Eckprofil, w.g.",
			en: "Edge profile, a.d."
		}
		,A2V00002586686 : {
			de: "Eckprofil, sp.g.",
			en: "Edge profile, r.i."
		}
		,A2V00002586696 : {
			de: "Eckprofil, sp.g.",
			en: "Edge profile, r.i."
		}
		,A2V00002667972 : {
			de: "Rippe STW ZSB sp.g.",
			en: "Web EW ASSY i.m.i."
		}
		,A2V00002586707 : {
			de: "Eckprofil",
			en: "Edge profile"
		}
		,A2V00002668146 : {
			de: "Rippe STW ZSB w.g.",
			en: "Web EW ASSY a.d."
		}
		,A2V00002586786 : {
			de: "Eckprofil, sp.g.",
			en: "Edge profile, rev. im."
		}
		,A2V00002586787 : {
			de: "Eckprofil, w.g.",
			en: "Edge profile, as drawn"
		}
		,A2V00113042855 : {
			de: "Senkschraube",
			en: "Hexagon socket countersunk head screw"
		}
		,A2V00001932618 : {
			de: "Klappsitz",
			en: "Tip-up seat"
		}
		,A2V00002317595 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317602 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002187907 : {
			de: "Rohrdurchfuehrung links, vollst.",
			en: "Intersection left, compl."
		}
		,A2V00002188098 : {
			de: "Rohrdurchfuehrung rechts, vollst.",
			en: "Intersection right, compl."
		}
		,A2V00002317601 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317394 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317393 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317586 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317395 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317593 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317609 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317610 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317611 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317156 : {
			de: "Uebergangsleiste",
			en: "Cover strip profile"
		}
		,A2V00002317588 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317590 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317587 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317589 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002274560 : {
			de: "Uebergangsleiste",
			en: "Cover strip profile"
		}
		,A2V00002317591 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317592 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317594 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317613 : {
			de: "Uebergangsleiste",
			en: "Cover strip profile"
		}
		,A2V00002317599 : {
			de: "Uebergangsleiste",
			en: "junction profile"
		}
		,A2V00002317604 : {
			de: "Uebergangsleiste",
			en: "Cover strip profile"
		}
		,A2V00002309153 : {
			de: "Tuerfluegel",
			en: "Door leaf assemb. loading"
		}
		,A2V00002274534 : {
			de: "Uebergangsleiste",
			en: "Cover strip profile"
		}
		,A2V00002587857 : {
			de: "Klimageraet FST",
			en: "HVAC unit cab"
		}
		,A2V00002587858 : {
			de: "Klimageraet FGR",
			en: "HVAC unit saloon"
		}
		,A2V00002669210 : {
			de: "Anschweissgewindeblock 3",
			en: "Welding thread-block 3"
		}
		,A2V00002669213 : {
			de: "Anschweissgewindeblock 2",
			en: "Welding thread-block 2"
		}
		,A2V00002670093 : {
			de: "Gitter Entwaesserung ZSB. w.g.",
			en: "Fence f. drainage ASSY. a.d."
		}
		,A2V00002670299 : {
			de: "Gitter Entwaesserung ZSB. sp.g.",
			en: "Fence f. drainage ASSY. i.m.i."
		}
		,A2V00370043691 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002079033 : {
			de: "Halbschale lang",
			en: "Half-shell long"
		}
		,A2V00002079031 : {
			de: "Halbschale lang",
			en: "Half-shell long"
		}
		,A2V00002079030 : {
			de: "Halbschale kurz",
			en: "Half-shell short"
		}
		,A2V00370035545 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002670329 : {
			de: "Gitter Entwaesserung ZSB w.g. Ausschnitt",
			en: "Fence f. drainage ASSY a.d. cut-out"
		}
		,A2V00002670342 : {
			de: "Schirmblech geschw.",
			en: "Screening shiels welded"
		}
		,A2V00002670656 : {
			de: "Nutenstein M16",
			en: "slot nut M16"
		}
		,A2V00002670899 : {
			de: "Crashmodul 2650",
			en: "Crash module 2650"
		}
		,A2V00156017601 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370055740 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00321680515 : {
			de: "Rundseil 25-0,1 DIN 46438 blank",
			en: "Round rope 25-0,1 DIN 46438 plain"
		}
		,A2V00001452012 : {
			de: "Dichtung",
			en: "gasket"
		}
		,A2V00370061876 : {
			de: "Bolzen ISO 13918 - PD M8x50 - A4-50",
			en: "Stud ISO 13918 - PD M8x50 - A4-50"
		}
		,A2V00002673388 : {
			de: "Halter 1 S1",
			en: "Holder 1 S1"
		}
		,A2V00002673520 : {
			de: "Halter 1 S2",
			en: "Holder 1 S2"
		}
		,A2V00002673564 : {
			de: "SW-Blech 3, geschw.",
			en: "Sidewall sheet  3, welded"
		}
		,A2V00002673566 : {
			de: "SW-Blech 8, geschw. w.g.",
			en: "Sidewall sheet 8, welded, a.d."
		}
		,A2V00002673570 : {
			de: "SW-Blech 4, geschw.",
			en: "Sidewall sheet 4, welded"
		}
		,A2V00002673576 : {
			de: "SW-Blech 8, geschw. sp.g.",
			en: "Sidewall sheet 8, welded, i.m.i."
		}
		,A2V00002673577 : {
			de: "Dachblech 3, geschw.",
			en: "Roof sheet 3, welded"
		}
		,A2V00002673581 : {
			de: "Schuerze unten 1, geschw. w.g.",
			en: "Sheet bottom 1, welded, a.d."
		}
		,A2V00002673582 : {
			de: "SW-Blech innen, geschw.",
			en: "Sidewall sheet inner, welded"
		}
		,A2V00002673583 : {
			de: "Dachblech 1, geschw.",
			en: "Roof sheet 1, welded"
		}
		,A2V00002590964 : {
			de: "LIEFERGARNITUR DACHSCHUERZE AB TEIL 1",
			en: "Delivering set roof skirt assy. part one"
		}
		,A2V00002673584 : {
			de: "SW-Blech Einzug 1, geschw.",
			en: "SW sheet indentation 1, welded"
		}
		,A2V00002590965 : {
			de: "LIEFERGARNITUR DACHSCHUERZE AB TEIL 2",
			en: "Delivering set roof skirt assy. part two"
		}
		,A2V00002673611 : {
			de: "Tuerbeplankung 1, geschw. w.g.",
			en: "Door panel 1, welded, a.d."
		}
		,A2V00002673617 : {
			de: "Schuerze, unten, geschw.",
			en: "Skirt bottom, welded"
		}
		,A2V00002673642 : {
			de: "Tuerbeplankung 1, geschw. sp.g.",
			en: "Door panel 1, welded, i.m.i."
		}
		,A2V00002673651 : {
			de: "Halter 2 S1",
			en: "Holder 2 S1"
		}
		,A2V00002673652 : {
			de: "Halter 2 S2",
			en: "Holder 2 S2"
		}
		,A2V00002673669 : {
			de: "Schuerze unten 1, geschw. sp.g.",
			en: "Sheet bottom 1, welded, i.m.i."
		}
		,A2V00002674126 : {
			de: "Anschraubgewindeblock",
			en: "Threading thread-block"
		}
		,A2V00002000483 : {
			de: "Flachkopfschraube",
			en: "Hexagon socket button head screw"
		}
		,A2V00002431484 : {
			de: "B-Saeule vorne Profil",
			en: "B-pillar front side profile"
		}
		,A2V00002364769 : {
			de: "Seitenwand 2 Profil",
			en: "Side wall 2 profile"
		}
		,A2V00002364767 : {
			de: "Seitenwand 1 Profil",
			en: "Side wall 1 profile"
		}
		,A2V00002364758 : {
			de: "Stirnwandsaeule Profil",
			en: "End wall column profile"
		}
		,A2V00002364752 : {
			de: "Seitenwand Profil",
			en: "Side wall  profile"
		}
		,A2V00002364739 : {
			de: "Vorbau Mitte Profil",
			en: "Front center profile"
		}
		,A2V00002364734 : {
			de: "Bruestungstraeger w.g. Profil",
			en: "Front end beam a.d. profile"
		}
		,A2V00002364733 : {
			de: "Bruestungstraeger Profil",
			en: "Parapet girder profile"
		}
		,A2V00002364732 : {
			de: "Kupplungstraeger Profil",
			en: "Coupling support profile"
		}
		,A2V00002364731 : {
			de: "Bruestungstraeger Profil",
			en: "Grab carrier profile"
		}
		,A2V00002364730 : {
			de: "C-Saeule Profil",
			en: "C-pillar profile"
		}
		,A2V00002364729 : {
			de: "C-Saeule Profil",
			en: "C-pillar profile"
		}
		,A2V00002364728 : {
			de: "Langtraeger Profil",
			en: "Sole bar profile"
		}
		,A2V00002364723 : {
			de: "Seitenwandendsaeule Profil",
			en: "SW-end pillar profile"
		}
		,A2V00002364722 : {
			de: "Tuersaeule Profil",
			en: "Door pillar profile"
		}
		,A2V00002364706 : {
			de: "SW-Endsaeule Profil",
			en: "SW-endpillar profile"
		}
		,A2V00002556299 : {
			de: "Druckbehaelter 10L, lackiert",
			en: "Air reservoir 10L, painted"
		}
		,A2V00002556300 : {
			de: "Druckbehaelter 80L, lackiert",
			en: "Air reservoir 80L, painted"
		}
		,A2V00002556302 : {
			de: "Druckbehaelter 100L, lackiert",
			en: "Air reservoir 100L, painted"
		}
		,A2V00002577140 : {
			de: "Differenzdruckventil 1,6 bar",
			en: "Differential pressure valve 1,6 bar"
		}
		,A2V00002597158 : {
			de: "Luftfederventil RV4",
			en: "Air-spring valve RV4"
		}
		,A2V00002223373 : {
			de: "Flachkopfschraube",
			en: "Hexagon socket button head screw"
		}
		,A2V00002135168 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screwÂ "
		}
		,A2V00002675963 : {
			de: "Kupplungsrutsche ZSB",
			en: "Coupling slide ASSY"
		}
		,A2V00002594069 : {
			de: "Sandkasten rechts, vollst.",
			en: "sandbox right, compl."
		}
		,A2V00002676601 : {
			de: "Triebdrehgestell 1",
			en: "Motorbogie 1"
		}
		,A2V00002594163 : {
			de: "Sandkasten links, vollst.",
			en: "sandbox left, compl."
		}
		,A2V00002676602 : {
			de: "Triebdrehgestell 2",
			en: "Motorbogie 2"
		}
		,A2V00002676603 : {
			de: "Triebdrehgestell 3",
			en: "Motorbogie 3"
		}
		,A2V00002676604 : {
			de: "Triebdrehgestell 4",
			en: "Motorbogie 4"
		}
		,A2V00002676605 : {
			de: "Triebdrehgestell 5",
			en: "Motorbogie 5"
		}
		,A2V00002676723 : {
			de: "Parallelfuehrung",
			en: "Guiding unit"
		}
		,A2V00002594691 : {
			de: "Einsystem-Haupttransformator 15 kV",
			en: "Single system main transformer 15 kV"
		}
		,A2V00002594847 : {
			de: "Netzstromrichter AC",
			en: "line converter AC"
		}
		,A2V00002594896 : {
			de: "Hilfsbetriebeumrichter",
			en: "Auxiliary power supply"
		}
		,A2V00002595054 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002595062 : {
			de: "Blech w.g.",
			en: "sheet as d."
		}
		,A2V00002595063 : {
			de: "Blech sp.g.",
			en: "sheet i.m.i."
		}
		,A2V00002595875 : {
			de: "Hauptschalter RM5213-101",
			en: "main circuit breaker AC"
		}
		,A2V00002677736 : {
			de: "Rippe w.g.",
			en: "Rib a.d."
		}
		,A2V00002677737 : {
			de: "Rippe sp.g.",
			en: "Rib i.m.i."
		}
		,A2V00002677738 : {
			de: "Rippe SW zu Dach w.g.",
			en: "Rib sidew. roof a.d."
		}
		,A2V00002595937 : {
			de: "Stromabnehmer 1950 AC",
			en: "Pantograph 1950 AC"
		}
		,A2V00002677739 : {
			de: "Rippe SW zu Dach sp.g.",
			en: "Rib sidew. roof i.m.i."
		}
		,A2V00002677750 : {
			de: "Klimageraet FGR",
			en: "HVAC unit pass.room"
		}
		,A2V00002677751 : {
			de: "Klimageraet FST",
			en: "HVAC unit driver's cab"
		}
		,A2V00002597361 : {
			de: "Leitung kpl. Fuehrerraum (Luhe)",
			en: "Conduit cpl. driver cab (Luhe)"
		}
		,A2V00002597362 : {
			de: "Leitungen kpl. im Wagen (Luhe)",
			en: "Conduit cpl. in car (Luhe)"
		}
		,A2V00002597363 : {
			de: "Leitungen kpl. uW (Luhe)",
			en: "conduit cpl. uc (Luhe)"
		}
		,A2V00002597364 : {
			de: "Leitungen kpl. Dach (Luhe)",
			en: "Conduit cpl. roof (Luhe)"
		}
		,A2V00002597365 : {
			de: "Leitung kpl. Fuehrerraum (Luhe)",
			en: "Conduit cpl. driver cab (Luhe)"
		}
		,A2V00002597366 : {
			de: "Leitungen kpl. im Wagen (Luhe)",
			en: "Conduit cpl. in car (Luhe)"
		}
		,A2V00002679886 : {
			de: "Schweissbadsicherung 2",
			en: "Weld backing 2"
		}
		,A2V00002597367 : {
			de: "Leitungen kpl. uW (Luhe)",
			en: "conduit cpl. uc (Luhe)"
		}
		,A2V00002597368 : {
			de: "Leitungen kpl. Dach (Luhe)",
			en: "Conduit cpl. roof (Luhe)"
		}
		,A2V00002597830 : {
			de: "Auflage",
			en: "support"
		}
		,A2V00002598653 : {
			de: "Deckblech F-Ende",
			en: "cover plate front end"
		}
		,A2V00002681892 : {
			de: "Halter sp.g.",
			en: "Bracket i.m.i."
		}
		,A2V00002681918 : {
			de: "Halter w.g.",
			en: "Bracket a.d."
		}
		,A2V00002682059 : {
			de: "Lasche Makrofon",
			en: "Lug makrofon"
		}
		,A2V00370069280 : {
			de: "Knochenbuchse",
			en: "Spheribloc"
		}
		,A2V00002682096 : {
			de: "Abdeckblech",
			en: "cover plate"
		}
		,A2V00002533820 : {
			de: "Gleitschutzventileinheit UBNG1",
			en: "Protection unit slip metro Nuernberg G1"
		}
		,A2V00002601850 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002601861 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002602299 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002602354 : {
			de: "Liefergarnitur PTOSB Fenster",
			en: "Delivering set PTOSB windows"
		}
		,A2V00002602769 : {
			de: "Halter geschweisst",
			en: "Bracket welded"
		}
		,A2V00002604583 : {
			de: "Liefergarnitur DMOSB Seitendecke",
			en: "Delivering set DMOSB  side blanket"
		}
		,A2V00002604584 : {
			de: "Liefergarnitur PTOSB Seitendecke",
			en: "Delivering set PTOSB  side blanket"
		}
		,A2V00002604666 : {
			de: "Liefergarnitur MOS/TOS Seitendecke",
			en: "Delivering set MOS/TOS  side blanket"
		}
		,A2V00002604667 : {
			de: "Liefergarnitur TOS(L)W Seitendecke",
			en: "Delivering set TOS(L)W  side blanket"
		}
		,A2V00002683892 : {
			de: "Balglasche",
			en: "Bellow plate"
		}
		,A2V00002605788 : {
			de: "Frischwasserrohr",
			en: "Fresh water pipe"
		}
		,A2V00002607008 : {
			de: "Universal WC vst",
			en: "universal WC cpl"
		}
		,A2V00002684507 : {
			de: "Verdichter Verfluessiger Aggregat, FST",
			en: "Compressor condenser unit, cab"
		}
		,A2V00002684508 : {
			de: "Luftbehandlungsgeraet, FST",
			en: "Evaporator unit, cab"
		}
		,A2V00002684790 : {
			de: "Beilage 1",
			en: "Shim 1"
		}
		,A2V00002684799 : {
			de: "Beilage 2",
			en: "Shim 2"
		}
		,A2V00156020890 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370016340 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00113008861 : {
			de: "Sechskantschraube",
			en: "Hexagon screw"
		}
		,A2V00002613177 : {
			de: "Konsole Spannungswandler",
			en: "console voltage transformator"
		}
		,A2V00002104429 : {
			de: "Dehnhuelse",
			en: "Extension sleeve"
		}
		,A2V00002245663 : {
			de: "Niederhalter",
			en: "blank holder"
		}
		,A2V00002170237 : {
			de: "Halter, geschweisst",
			en: "holder, welded"
		}
		,A2V00002145863 : {
			de: "Dichtplatte rechts",
			en: "Sealing plate right"
		}
		,A2V00002266364 : {
			de: "Dehnhuelse M12",
			en: "extension sleeve M12"
		}
		,A2V00002220806 : {
			de: "Distanzbuchse",
			en: "Distance socket"
		}
		,A2V00002187979 : {
			de: "Profil ballig",
			en: "profile convex"
		}
		,A2V00002274246 : {
			de: "Dehnhuelse",
			en: "Extension sleeve"
		}
		,A2V00002173843 : {
			de: "Haltestange, vorne rechts",
			en: "handrail, front right"
		}
		,A2V00002175236 : {
			de: "Haltestange, hinten links",
			en: "handrail, rear left"
		}
		,A2V00002271314 : {
			de: "Unterlage",
			en: "underlayment"
		}
		,A2V00002271309 : {
			de: "Gewindestueck",
			en: "female union"
		}
		,A2V00002148310 : {
			de: "Druckluftbehaelter",
			en: "Compressed air reservoir"
		}
		,A2V00002211096 : {
			de: "Rohr",
			en: "Pipe"
		}
		,A2V00002217917 : {
			de: "Rohr",
			en: "Pipe"
		}
		,A2V00002216282 : {
			de: "Rohr",
			en: "Pipe"
		}
		,A2V00002219669 : {
			de: "Halter geschweisst",
			en: "Bracket welded"
		}
		,A2V00002236702 : {
			de: "Befuellstutzen vollst.",
			en: "Filling connection compl."
		}
		,A2V00002248053 : {
			de: "Haltestange geschweisst",
			en: "Holding rod welded"
		}
		,A2V00002255859 : {
			de: "Blech oben geschweisst",
			en: "Sheet top welded"
		}
		,A2V00002104428 : {
			de: "Dehnhuelse",
			en: "Extension sleeve"
		}
		,A2V00002250749 : {
			de: "Deckenkonsole",
			en: "Console"
		}
		,A2V00002187528 : {
			de: "Bodenkonsole li",
			en: "Floor console left"
		}
		,A2V00002255811 : {
			de: "Bodenkonsole rechts",
			en: "floor console rh"
		}
		,A2V00002153878 : {
			de: "Batteriesystem Layout 1",
			en: "Battery system"
		}
		,A2V00002255731 : {
			de: "Blende komplett",
			en: "cover plate complete"
		}
		,A2V00002202811 : {
			de: "Haltestange, komplett",
			en: "Grab handle, complete"
		}
		,A2V00002202484 : {
			de: "Haltestange, komplett",
			en: "Grab handle, complete"
		}
		,A2V00002157110 : {
			de: "Glastrennwand 15a, montiert",
			en: "Glass partition wall 15a, assembled"
		}
		,A2V00001433590 : {
			de: "Schlauchleitung 2TE 5 NN90-680P",
			en: "hose assembly 2TE 5 NN90-680P"
		}
		,A2V00001433577 : {
			de: "Schlauchleitung",
			en: "Hose assembly"
		}
		,A2V00001433591 : {
			de: "Schlauchleitung 2TE 5 NN90-930P",
			en: "hose assembly 2TE 5 NN90-930P"
		}
		,A2V00001431487 : {
			de: "Buchse",
			en: "Bush"
		}
		,A2V00370065229 : {
			de: "SCHLAUCHLEITUNG 2TE 16 NN90 750 P",
			en: "HOSE ASSEMBLY 2TE 16 NN90 750 P"
		}
		,A2V00370036749 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002153393 : {
			de: "Bowdenzug Notentriegelung, innen",
			en: "Bowdencable emergency, internal"
		}
		,A2V00002153392 : {
			de: "Bowdenzug Notentriegelung, links",
			en: "Bowdencable emergency, left"
		}
		,A2V00002119511 : {
			de: "Lampenmodul rechts, vollst.",
			en: "Light module RHS, complete"
		}
		,A2V00002133892 : {
			de: "Tuerantrieb",
			en: "Door operator"
		}
		,A2V00002133719 : {
			de: "Klimageraet FGR",
			en: "HVAC unit sallon"
		}
		,A2V00002256246 : {
			de: "Langtraegerprofil NF",
			en: "longit. beam profile LF"
		}
		,A2V00002128961 : {
			de: "Buchse",
			en: "bush"
		}
		,A2V00002134771 : {
			de: "Aeussere Notentriegelung",
			en: "external emergency device"
		}
		,A2V00002133928 : {
			de: "Tuerantriebsadapter",
			en: "Door operator bracket"
		}
		,A2V00002133648 : {
			de: "Aeussere Fuehrungsschiene",
			en: "Threshold external part"
		}
		,A2V00002153375 : {
			de: "Vertikaler Dichtrahmen, rechts",
			en: "Vertical accoustic frame, right"
		}
		,A2V00002153374 : {
			de: "Vertikaler Dichtrahmen, links",
			en: "Vertical accoustic frame, left"
		}
		,A2V00370026221 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002613839 : {
			de: "U-Kappe Profil",
			en: "u-cap profile"
		}
		,A2V00002614351 : {
			de: "Liefergarnitur DMOSB Mitteldecke",
			en: "Delivering set DMOSB centre cloth"
		}
		,A2V00002614352 : {
			de: "Liefergarnitur PTOSB Mitteldecke",
			en: "Delivering set PTOSB centre cloth"
		}
		,A2V00002614353 : {
			de: "Liefergarnitur MOS/TOS/TOS(L)W Mittelde.",
			en: "Delivering set MOS/TOS/TOS(L)W c. cloth"
		}
		,A2V00002617584 : {
			de: "Netzstromwandler",
			en: "Current transducer"
		}
		,A2V00002686707 : {
			de: "Auftritt Fahrerstand ZSB",
			en: "Step drivers cab assy"
		}
		,A2V00001708784 : {
			de: "Druckkappe Gleitschutzgeber",
			en: "end cap speed sensor"
		}
		,A2V00001709235 : {
			de: "Radsatzlagerdeckel, EK+LZB",
			en: "wheelset front cover GD + LZB"
		}
		,A2V00001709233 : {
			de: "RS-Lagerdeckel vorne, EK",
			en: "ws front cover, GD"
		}
		,A2V00001709266 : {
			de: "Radsatzlagerdeckel, EK+IG",
			en: "wheelset box cover, GD+SS"
		}
		,A2V00001719889 : {
			de: "Fahrmotor",
			en: "Traction motor"
		}
		,A2V00001988759 : {
			de: "Puffer",
			en: "buffer"
		}
		,A2V00001988760 : {
			de: "Puffer, Laengsmitnahme",
			en: "Longitudinal buffer"
		}
		,A2V00001390610 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical damper"
		}
		,A2V00001708819 : {
			de: "Druckkappe",
			en: "end cap"
		}
		,A2V00002077315 : {
			de: "Kontaktscheibe",
			en: "Contact Disc"
		}
		,A2V00002188813 : {
			de: "(CS) Sechskantschraube  M20X240",
			en: "(CS) Hexagon head screw  M20X240"
		}
		,A2V00001882269 : {
			de: "Schraubenfedernsatz 1",
			en: "Helical compression spring prim. 1"
		}
		,A2V00001882392 : {
			de: "Schraubenfedernsatz 2",
			en: "Helical compression spring prim. 2"
		}
		,A2V00001882394 : {
			de: "Schraubenfedernsatz 4",
			en: "Helical compression spring prim. 4"
		}
		,A2V00001709231 : {
			de: "Montagematerial hinterer RS-Lagerdeckel",
			en: "mounting material wheelset back cover"
		}
		,A2V00001803357 : {
			de: "Kegelrollenlager phosphatiert",
			en: "tapered roller bearing phosphated"
		}
		,A2V00001708778 : {
			de: "Labyrinthring",
			en: "labyrinth ring"
		}
		,A2V00001803359 : {
			de: "Kegelrollenlager phosphatiert",
			en: "tapered roller bearing phosphated"
		}
		,A2V00002618725 : {
			de: "Abdeckung",
			en: "Covering"
		}
		,A2V00002687448 : {
			de: "Automatische Kupplung",
			en: "Front coupler 2-car"
		}
		,A2V00002687449 : {
			de: "Kurzkupplung A",
			en: "Short coupler A"
		}
		,A2V00002687451 : {
			de: "Kurzkupplung B",
			en: "Short coupler B"
		}
		,A2V00002688213 : {
			de: "Sockel f. Trennwand",
			en: "Support f. partition wall"
		}
		,A2V00002688317 : {
			de: "Feuerschutzblech 3",
			en: "Fire protection panel 3"
		}
		,A2V00002620038 : {
			de: "Konsole w.g.",
			en: "bracket  as d."
		}
		,A2V00002688349 : {
			de: "Kabeleinfuehrung Batterie geschw.",
			en: "Cable panel Battery welded"
		}
		,A2V00002620254 : {
			de: "Liefergarnitur PTOSB Flachdecke",
			en: "Delivering set PTOSB flat blanket"
		}
		,A2V00002620445 : {
			de: "Konsole sp.g.",
			en: "bracket  r.i."
		}
		,A2V00002620453 : {
			de: "Liefergarnitur TOS(L)W Fenster",
			en: "Delivering set TOS(L)W windows"
		}
		,A2V00002621623 : {
			de: "Schott vollst.",
			en: "Partition comp."
		}
		,A2V00002621871 : {
			de: "Schott vollst.",
			en: "Partition comp."
		}
		,A2V00002622351 : {
			de: "Antrieb, vollst.",
			en: "Drive, compl."
		}
		,A2V00002622352 : {
			de: "Tuerfluegel vollst.",
			en: "Door panel, compl."
		}
		,A2V00002622353 : {
			de: "Tuerfluegel vollst.",
			en: "Door panel, compl."
		}
		,A2V00002690733 : {
			de: "Batteriesystem",
			en: "Battery system"
		}
		,A2V00002623102 : {
			de: "Schiebetritt, vollst.",
			en: "Sliding step, compl."
		}
		,A2V00002690901 : {
			de: "MAT WIEN Tuersaeule w.g.",
			en: "MAT VIENNA Door post a.d."
		}
		,A2V00002690902 : {
			de: "MAT WIEN Tuersaeule sp.g.",
			en: "MAT VIENNA Door post i.m.i."
		}
		,A2V00002690903 : {
			de: "MAT WIEN Fenstersaeule ZSB",
			en: "MAT VIENNA Window post assy."
		}
		,A2V00002690905 : {
			de: "MAT WIEN Ecksaeule WE2 w.g.",
			en: "MAT VIENNA Corner post CE2 a.d."
		}
		,A2V00002690996 : {
			de: "MAT WIEN Ecksaeule WE2 sp.g.",
			en: "MAT VIENNA Corner post CE2 i.m.i."
		}
		,A2V00002690997 : {
			de: "MAT WIEN Ecksaeule WE1 w.g.",
			en: "MAT VIENNA Corner post CE1 a.d."
		}
		,A2V00002690998 : {
			de: "MAT WIEN Ecksaeule WE1 sp.g.",
			en: "MAT VIENNA Corner post CE1 i.m.i."
		}
		,A2V00002625268 : {
			de: "Druckluftbehaelter 40L",
			en: "Steel pressure reservoir 40L"
		}
		,A2V00002625270 : {
			de: "Luftbehaelter 25L",
			en: "air reservoir 25l"
		}
		,A2V00002627574 : {
			de: "Luftversorgungsanlage",
			en: "air supply unit"
		}
		,A2V00002628066 : {
			de: "Endtriebdrehgestell 1, vollstaendig",
			en: "Endmotorbogie 1, complete"
		}
		,A2V00002628067 : {
			de: "Jakobs-Laufdrehgestell 1, vollstaendig",
			en: "Jacobs-trailerbogie 1, complete"
		}
		,A2V00002628068 : {
			de: "Jakobs-Laufdrehgestell 2, vollstaendig",
			en: "Jacobs-trailerbogie 2, complete"
		}
		,A2V00002628069 : {
			de: "Endtriebdrehgestell 2, vollstaendig",
			en: "Endmotorbogie 2, complete"
		}
		,A2V00002692422 : {
			de: "Erdungslasche M6",
			en: "Earthing eye M6"
		}
		,A2V00002630079 : {
			de: "Liefergarnitur DMOSB Gepaeckablage",
			en: "Delivering set DMOSB Luggage Rack"
		}
		,A2V00002631139 : {
			de: "Liefergarnitur PTOSB Gepaeckablage",
			en: "Delivering set PTOSB Luggage Rack"
		}
		,A2V00201333741 : {
			de: "Magnetventil WMV01-ZEST",
			en: "Magnet valve"
		}
		,A2V00201335969 : {
			de: "Magnetventi WMV02-ZEST",
			en: "Magnet Valve WMV02-ZEST"
		}
		,A2V00002631140 : {
			de: "Liefergarnitur MOS/TOS Gepaeckablage",
			en: "Delivering set MOS/TOS Luggage Rack"
		}
		,A2V00002631141 : {
			de: "Liefergarnitur TOS(L)W Gepaeckablage",
			en: "Delivering set TOS(L)W Luggage Rack"
		}
		,A2V00397207469 : {
			de: "Radbremsscheibe R1085R805LGUP",
			en: "WHEEL MOUNTED BRAKE DISK"
		}
		,A2V00397216709 : {
			de: "Halter f. Indusi lackiert WG",
			en: "Holder f. indusi painted as drawn"
		}
		,A2V00397241740 : {
			de: "Hohlbuchse vollst.",
			en: "Bush compl."
		}
		,A2V00397206018 : {
			de: "Schienenraeumer lack. W.G.",
			en: "Life guard painted as drawn"
		}
		,A2V00397223296 : {
			de: "Pufferhalter lackiert",
			en: "buffer holder coated"
		}
		,A2V00370041462 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00397237497 : {
			de: "ADAPTER OHNE PASSSTIFTBOHRUNGEN",
			en: "ADAPTER"
		}
		,A2V00397145411 : {
			de: "Teller, geschw.",
			en: "plate, welded"
		}
		,A2V00002694857 : {
			de: "Edelstahlseil d8",
			en: "Wire stainless-steel d8"
		}
		,A2V00002695417 : {
			de: "Crashelement, ZSB",
			en: "Crash element, Assy."
		}
		,A2V00002633816 : {
			de: "Gelenkauge",
			en: "Shackle togg. joint"
		}
		,A2V00002633844 : {
			de: "Gelenkgabel",
			en: "yoke"
		}
		,A2V00002667986 : {
			de: "Brandschutzkappe",
			en: "fire protection cap"
		}
		,A2V00370078853 : {
			de: "Zylinderrollenlager",
			en: "cylindrical roller bearing"
		}
		,A2V00001720619 : {
			de: "Erdungskontakt mit Gebermitnahme",
			en: "earthing contact with transmitter attatc"
		}
		,A2V00370074397 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002668172 : {
			de: "Luftbehaelter",
			en: "Air reservoir"
		}
		,A2V00002669072 : {
			de: "Querprofil F-Ende",
			en: "cross section front end"
		}
		,A2V00002669701 : {
			de: "Schiebetritt TSI",
			en: "Sliding step TSI"
		}
		,A2V00002669724 : {
			de: "Schiebetritt TSI",
			en: "Sliding step TSI"
		}
		,A2V00002457762 : {
			de: "Zylinderschraube",
			en: "hexagon socket head cap screwÂ "
		}
		,A2V00002670527 : {
			de: "Bremsgeraetetafel 1 Konfig. 1",
			en: "brake panel 1 Config. 1"
		}
		,A2V00002670529 : {
			de: "Bremsgeraetetafel 1",
			en: "brake panel 1"
		}
		,A2V00002670530 : {
			de: "Bremsgeraetetafel 2",
			en: "brake panel 2"
		}
		,A2V00002696771 : {
			de: "Traktionskupplung Typ 30/530",
			en: "Traction coupler type 30/530"
		}
		,A2V00002670628 : {
			de: "Langtraeger Profil unten",
			en: "longitud. girder profile"
		}
		,A2V00002670631 : {
			de: "Rippe",
			en: "rib"
		}
		,A2V00002670633 : {
			de: "Querprofil F-Ende",
			en: "cross profile  front end"
		}
		,A2V00002670636 : {
			de: "Langtraeger Profil unten",
			en: "longitud. girder profile"
		}
		,A2V00002670638 : {
			de: "Langtraeger Profil unten",
			en: "longitud. girder profile"
		}
		,A2V00002670639 : {
			de: "Langtraeger Profil Mitte",
			en: "longitud. girder profile center"
		}
		,A2V00002670640 : {
			de: "Langtraeger Profil Mitte",
			en: "longitud. girder profile center"
		}
		,A2V00002670642 : {
			de: "Bodenprofil innen",
			en: "floor profile inside"
		}
		,A2V00370079474 : {
			de: "FLEXBALL DZ125,G=2450,H=100+HZ0",
			en: "FLEXBALL DZ125,G=2450,H=100+HZ0"
		}
		,A2V00156014313 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00116994145 : {
			de: "Buchse",
			en: "Socket"
		}
		,A2V00370068967 : {
			de: "Mitteldruckventil m.Traeger MDV1",
			en: "MEDIUM PRESS. VALVE W.BRKT. MDV1  I22950"
		}
		,A2V00002670643 : {
			de: "Bodenprofil innen",
			en: "floor profile inside"
		}
		,A2V00002670644 : {
			de: "Bodenprofil innen",
			en: "floor profile inside"
		}
		,A2V00002670645 : {
			de: "Bodenprofil innen",
			en: "floor profile inside"
		}
		,A2V00002670666 : {
			de: "Bodenprofil aussen",
			en: "Floor profile outside"
		}
		,A2V00002670667 : {
			de: "Bodenprofil aussen",
			en: "Floor profile outside"
		}
		,A2V00002670668 : {
			de: "Hauptquertraeger Profil aussen",
			en: "Main cross girder profile outside"
		}
		,A2V00002670669 : {
			de: "Hauptquertraeger Profil Mitte",
			en: "Main cross girder profile center"
		}
		,A2V00002697715 : {
			de: "Druckluftversorgung SKS",
			en: "Air supply module WFL"
		}
		,A2V00002670686 : {
			de: "Laengsprofil KK-Ende",
			en: "Longitudinal profile"
		}
		,A2V00002670693 : {
			de: "Tuersaeule HF",
			en: "Door pillar HF"
		}
		,A2V00002670694 : {
			de: "Tuersaeule NF",
			en: "Door post lf"
		}
		,A2V00002670716 : {
			de: "Seitenwandprofil HF 5",
			en: "sidewall profile hf 5"
		}
		,A2V00002670723 : {
			de: "Seitenwandprofil NF 3",
			en: "Sidewall profile lf 3"
		}
		,A2V00002670731 : {
			de: "Dachprofil 1 Mitte",
			en: "roof profile 1 center"
		}
		,A2V00002670732 : {
			de: "Dachprofil 2 Mitte",
			en: "roof profile 2 center"
		}
		,A2V00002670733 : {
			de: "Dachprofil 3 Seite",
			en: "Roof profile 3 side"
		}
		,A2V00002670738 : {
			de: "Abschlussprofil",
			en: "end profile"
		}
		,A2V00002670739 : {
			de: "Saeule horizontal",
			en: "post horizontal"
		}
		,A2V00002670740 : {
			de: "Saeule",
			en: "post"
		}
		,A2V00156019939 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370079012 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370084632 : {
			de: "Kugelhahn G3/4 mit Entlueftung",
			en: "Ballcock G3/4 with Exhaust"
		}
		,A2V00370047838 : {
			de: "DEHNHUELSE DIN2510-E 26x50-25CRMO5",
			en: "EXTENSION SLEEVE DIN2510-E 26x50-25CRMO5"
		}
		,A2V00002670741 : {
			de: "Saeule vert. Profil",
			en: "post vert. profile"
		}
		,A2V00002670742 : {
			de: "Hakenprofil",
			en: "hook profile"
		}
		,A2V00002698437 : {
			de: "Stuetzrohr",
			en: "Support"
		}
		,A2V00002671039 : {
			de: "Dachprofil",
			en: "Roof profile"
		}
		,A2V00002699042 : {
			de: "Tuerbeplankung KM , geschw. sp.g.",
			en: "Door panel HM, welded, i.m.i."
		}
		,A2V00002699074 : {
			de: "Hilfsbetriebeumrichter",
			en: "Auxiliary Power Unit"
		}
		,A2V00002699079 : {
			de: "Tuerbeplankung KM, geschw. w.g.",
			en: "Door panel HM, welded, a.d."
		}
		,A2V00002699502 : {
			de: "Dachblech KM, geschw. w.g.",
			en: "Roof sheet HM, welded, a.d."
		}
		,A2V00002699785 : {
			de: "Stufe Eins geschw.",
			en: "Stage one welded"
		}
		,A2V00002674747 : {
			de: "Seitenwandprofil HF 5",
			en: "sidewall profile hf 5"
		}
		,A2V00002674767 : {
			de: "Seitenwandprofil NF 5",
			en: "Sidewall profile lf 5"
		}
		,A2V00002674769 : {
			de: "Seitenwandprofil NF 4",
			en: "Sidewall profile lf 4"
		}
		,A2V00002674771 : {
			de: "Seitenwandprofil NF 3",
			en: "Sidewall profile lf 3"
		}
		,A2V00002674774 : {
			de: "Seitenwandprofil NF 3",
			en: "sidewall profile lf 3"
		}
		,A2V00002674786 : {
			de: "Seitenwandprofil NF 3",
			en: "Sidewall profile lf 3"
		}
		,A2V00002674788 : {
			de: "Seitenwandprofil NF 2",
			en: "sidewall profile lf 2"
		}
		,A2V00002674789 : {
			de: "Seitenwandprofil NF 1",
			en: "Sidewall profile lf 1"
		}
		,A2V00002674790 : {
			de: "Dachprofil",
			en: "roof profile"
		}
		,A2V00002674794 : {
			de: "Dachprofil 1 Mitte",
			en: "roof profile 1 center"
		}
		,A2V00002674795 : {
			de: "Dachprofil 2 Mitte",
			en: "roof profile 2 center"
		}
		,A2V00002674796 : {
			de: "Dachprofil 3 Seite",
			en: "Roof profile 3 side"
		}
		,A2V00002675509 : {
			de: "Sandwichprofil FS",
			en: "Sandwichprofile FS"
		}
		,A2V00002679487 : {
			de: "Querprofil F-Ende",
			en: "cross profile  front end"
		}
		,A2V00002704497 : {
			de: "Tuerfluegel links, Oeffngs.- u. PRM-Tast",
			en: "Door panel left, open a. PRM button"
		}
		,A2V00002704498 : {
			de: "Tuerfluegel rechts",
			en: "Door panel right"
		}
		,A2V00002704499 : {
			de: "Drehsaeule links, vollst.",
			en: "Locking column left, compl."
		}
		,A2V00002704500 : {
			de: "Drehsaeule rechts, vollst.",
			en: "Locking column right, compl."
		}
		,A2V00200676913 : {
			de: "Blech EN 485-4 EN AW-5754-H22",
			en: "Sheet EN 485-4 EN AW-5754-H22"
		}
		,A2V00370034944 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370068076 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00001989005 : {
			de: "Schichtfeder",
			en: "layer spring"
		}
		,A2V00002167989 : {
			de: "Vollrad, lackiert",
			en: "Solid wheel, lacquered"
		}
		,A2V00114251575 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370026222 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00397120790 : {
			de: "DREHGESTELLRAHMEN WG.808 WE2",
			en: "bogie frame car 808 CE2"
		}
		,A2V00002189391 : {
			de: "Stahlmotortraeger mont",
			en: "Steel motor beam assembl"
		}
		,A2V00002356926 : {
			de: "Leitung EK-ISO",
			en: "grounding cable EK-ISO"
		}
		,A2V00002356927 : {
			de: "Leitung ISO-WK",
			en: "grounding cable ISO-WK"
		}
		,A2V00002356928 : {
			de: "Leitung EK-WK",
			en: "grounding cable EK-WK"
		}
		,A2V00001875516 : {
			de: "Keillager",
			en: "V- mount"
		}
		,A2V00397281948 : {
			de: "ZWISCHENSCHEIBE",
			en: "connector disc"
		}
		,A2V00002683471 : {
			de: "Kombikuehlanlage",
			en: "Combined cooling"
		}
		,A2V00002705314 : {
			de: "Bodenprofil, Mitte",
			en: "floor profile, centre"
		}
		,A2V00002705315 : {
			de: "Bodenprofil, aussen",
			en: "roof profile, outside"
		}
		,A2V00002685304 : {
			de: "E-Tafel 2, kpl.  ++RAE1, +93.20",
			en: "E-panel 2, cpl.  ++RAE1, +93.20"
		}
		,A2V00002685305 : {
			de: "E-Tafel 1, kpl.  ++RAE1, +93.10",
			en: "E-panel 1, cpl.  ++RAE1, +93.10"
		}
		,A2V00002685391 : {
			de: "Wagenbedientafel kpl., ++RAE1,+94.10",
			en: "Car control panel cpl., ++RAE1,+94.10"
		}
		,A2V00002707064 : {
			de: "Lueftereinheit FR",
			en: "Ventilator-unit cab"
		}
		,A2V00002688570 : {
			de: "Schottprofil",
			en: "compartment profile"
		}
		,A2V00002688728 : {
			de: "Dachvoute w.g.",
			en: "roof rounding a.d."
		}
		,A2V00002688803 : {
			de: "Dachvoute sp.g.",
			en: "roof rounding r.i."
		}
		,A2V00002688806 : {
			de: "E-Geraetecontainer, kpl. ++RAE1, +98.10",
			en: "E-Container, cpl. ++RAE1, +98.10"
		}
		,A2V00002087618 : {
			de: "Federteller lackiert",
			en: "spring cap painted"
		}
		,A2V00002156727 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002174143 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002141997 : {
			de: "Klemmenkasten bearbeitet",
			en: "terminal box mech."
		}
		,A2V00002161721 : {
			de: "Anhebestange",
			en: "Lifting tool"
		}
		,A2V00002707360 : {
			de: "Stromabnehmer SBE 920.358",
			en: "Pantograph SBE 920.358"
		}
		,A2V00002707759 : {
			de: "Haltestange, dreiteilige",
			en: "Stanchion, tripole"
		}
		,A2V00002707760 : {
			de: "Haltestange Wandscheibe, geschweisst",
			en: "Handrail draught screen, welded"
		}
		,A2V00002707761 : {
			de: "Haltestange, kurz geschweisst",
			en: "Handrail, short welded"
		}
		,A2V00002690932 : {
			de: "Abdeckung geschw.",
			en: "Cover welden"
		}
		,A2V00002709129 : {
			de: "Tuerbeplankung HUB, geschw.",
			en: "Door panel HUB, welded"
		}
		,A2V00002691241 : {
			de: "Triebdrehgestell A vollstaendig",
			en: "Motorbogie A complete"
		}
		,A2V00002709260 : {
			de: "Dachblech KM, geschw. sp.g.",
			en: "Roof sheet HM, welded, i.m.i."
		}
		,A2V00002691244 : {
			de: "Triebdrehgestell B vollstaendig",
			en: "Motorbogie B complete"
		}
		,A2V00002691245 : {
			de: "Laufdrehgestell A vollstaendig",
			en: "Trailerbogie A complete"
		}
		,A2V00002691246 : {
			de: "Laufdrehgestell B vollstaendig",
			en: "Trailerbogie B complete"
		}
		,A2V00002709856 : {
			de: "Halter Klebeblech",
			en: "Bracket bonding sheet"
		}
		,A2V00002692341 : {
			de: "Antrieb, vollst.",
			en: "drive, compl."
		}
		,A2V00002692342 : {
			de: "Tuerfluegel, vollst.",
			en: "Door panel, compl."
		}
		,A2V00002692343 : {
			de: "Tuerfluegel, vollst.",
			en: "Door panel, compl."
		}
		,A2V00002692344 : {
			de: "Bowdenzug aussen UNI kurz",
			en: "Bowdencable, compl. short external"
		}
		,A2V00002692345 : {
			de: "Bowdenzug aussen UNI lang",
			en: "Bowdencable, compl. long external"
		}
		,A2V00002692476 : {
			de: "Antrieb, vollst.",
			en: "drive, compl."
		}
		,A2V00002692477 : {
			de: "Tuerfluegel, vollst.",
			en: "Door panel, compl."
		}
		,A2V00002692478 : {
			de: "Tuerfluegel, vollst.",
			en: "Door panel, compl."
		}
		,A2V00002692479 : {
			de: "Schiebetritt, vollst.",
			en: "Sliding step, compl."
		}
		,A2V00002692480 : {
			de: "Trittleiste, vollst.",
			en: "Treshold, compl."
		}
		,A2V00002692491 : {
			de: "Querprofil w. g.",
			en: "cross profile a.d."
		}
		,A2V00002128968 : {
			de: "4QS Eingangsstromrichter",
			en: "4QC traction converter"
		}
		,A2V00002363205 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002370664 : {
			de: "Schiebetritt             fuer Endeinstie",
			en: "Foot step                for end side do"
		}
		,A2V00001805694 : {
			de: "Feder",
			en: "Spring"
		}
		,A2V00001821264 : {
			de: "Tuerholmverkl. 2  vollstaendig",
			en: "door panel 2      complete"
		}
		,A2V00001821288 : {
			de: "Tuerholmverkl. 5  vollstaendig",
			en: "door panel 5      complete"
		}
		,A2V00002197883 : {
			de: "Haltestange, lackiert",
			en: "Grab handle, coated"
		}
		,A2V00370037132 : {
			de: "Sechskantmutter ISO 7042 M16 8 A3C",
			en: "Hexagon nut ISO 7042 M16 8 A3C"
		}
		,A2V00002357414 : {
			de: "Halter Kurzschliessschiene",
			en: "Holder Short Circuiting Bar"
		}
		,A2V00002271986 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002280611 : {
			de: "Trainlift TR 800 links",
			en: "Trainlift TR 800 left"
		}
		,A2V00002280181 : {
			de: "Trainlift TR 800 rechts",
			en: "Trainlift TR 800 right"
		}
		,A2V00002173085 : {
			de: "Sechskantmutter",
			en: "hexagon nut"
		}
		,A2V00002109031 : {
			de: "Tuerholmverkleidung",
			en: "Door paneling"
		}
		,A2V00002692564 : {
			de: "Querprofil",
			en: "Cross profile"
		}
		,A2V00002692741 : {
			de: "Abdeckplatte, seitlich, vollst.",
			en: "Cover plate, sidewise, compl."
		}
		,A2V00002692742 : {
			de: "Abdeckplatte, seitlich, vollst.",
			en: "Cover plate, sidewise, compl."
		}
		,A2V00002692743 : {
			de: "Abdeckplatte, seitlich, vollst.",
			en: "Cover plate, sidewise, compl."
		}
		,A2V00002692749 : {
			de: "Querprofil sp. g.",
			en: "cross profile r. i."
		}
		,A2V00002692750 : {
			de: "Blech w.g.",
			en: "sheet as d."
		}
		,A2V00002692796 : {
			de: "Schiebetritt, vollst., Kabelabg. nach re",
			en: "Sliding step, compl. cable out. to the r"
		}
		,A2V00002692799 : {
			de: "Anschlussleitung Unterbautritt, kurz",
			en: "Connecting cable sliding step, short"
		}
		,A2V00002692800 : {
			de: "Schiebetritt, vollst. Kabelabg. nach li.",
			en: "Sliding step, compl. cable out. to the l"
		}
		,A2V00002692954 : {
			de: "Knotenblech",
			en: "junction plate"
		}
		,A2V00002692955 : {
			de: "Blech w.g. Badsicherung",
			en: "sheet a.d. weld pool backing"
		}
		,A2V00002054937 : {
			de: "Sensorkit rechts fÃ¼r Radsatzlager",
			en: "sensorkit right for axle box bearing"
		}
		,A2V00001911759 : {
			de: "Abdeckleiste",
			en: "Cover strip"
		}
		,A2V00002050785 : {
			de: "Sechskantmutter ISO 4032 M12-LH A4-80",
			en: "Hexagon nut ISO 4032 M12-LH A4-80"
		}
		,A2V00002693028 : {
			de: "Blech sp.g.",
			en: "sheet r.i."
		}
		,A2V00002693051 : {
			de: "Kastenprofil w.g.",
			en: "box section as d."
		}
		,A2V00002693146 : {
			de: "Knotenblech w.g.",
			en: "junction plate as d."
		}
		,A2V00002710538 : {
			de: "Adapter Kupplung Typ 30/530 zu Schulz",
			en: "Adapter coupler type 30/530 to Schulz"
		}
		,A2V00002693165 : {
			de: "Deckel w.g.",
			en: "cover as d."
		}
		,A2V00002693197 : {
			de: "Deckel sp.gl.",
			en: "cover r.i."
		}
		,A2V00002693206 : {
			de: "Blech sp.g. Badsicherung",
			en: "sheet rev.im. weld pool backing"
		}
		,A2V00002693227 : {
			de: "Kastenprofil sp.g.",
			en: "box section r.i."
		}
		,A2V00002693232 : {
			de: "Badsicherung",
			en: "weld pool backing"
		}
		,A2V00002693441 : {
			de: "Knotenblech sp.g.",
			en: "junction plate r.i."
		}
		,A2V00002712098 : {
			de: "Schuerze unten 2, geschw.",
			en: "Sheet bottom 2, welded"
		}
		,A2V00002693769 : {
			de: "Seitenwandprofil 2 Niederflur MW",
			en: "side wall profile 2 low-floor mc"
		}
		,A2V00002694770 : {
			de: "Abdeckung geschw.",
			en: "Cover welded"
		}
		,A2V00002694904 : {
			de: "Knotenblech sp. g.",
			en: "junction plate r. i."
		}
		,A2V00002694908 : {
			de: "Knotenblech w.g.",
			en: "junction plate as d."
		}
		,A2V00002695183 : {
			de: "KK-Ende",
			en: "short coupler end"
		}
		,A2V00002157041 : {
			de: "Glastrennwand 9, montiert",
			en: "Glass partition wall 9, assembled"
		}
		,A2V00002553411 : {
			de: "Abdeckblech rechts geschweisst",
			en: "Cover sheet right weldet"
		}
		,A2V00002718555 : {
			de: "Leiste",
			en: "Cover"
		}
		,A2V00002193589 : {
			de: "Unterfahrschutz-KTL",
			en: "underride bar cath. coat."
		}
		,A2V00002717680 : {
			de: "Leiste",
			en: "Cover"
		}
		,A2V00002205296 : {
			de: "Glastrennwand 10, montiert",
			en: "Glass partition wall 10, assembled"
		}
		,A2V00370072776 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cab screw"
		}
		,A2V00002051288 : {
			de: "Daempfer primaer vertikal",
			en: "damper primary vertical"
		}
		,A2V00370009648 : {
			de: "Anschweissplatte DIN 3015-1 - L APK 2 W1",
			en: "Weld plate DIN 3015-1 - L APK 2 W1"
		}
		,A2V00001910851 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002714318 : {
			de: "Faltenbalg",
			en: "Bellows"
		}
		,A2V00002696976 : {
			de: "Langtraeger",
			en: "longitudinal girder"
		}
		,A2V00002256204 : {
			de: "Langtraegerprofil NF",
			en: "longit. beam profile LF"
		}
		,A2V00002563640 : {
			de: "FST Klimageraet",
			en: "Cab HVAC unit"
		}
		,A2V00002563639 : {
			de: "Klimageraet FGR",
			en: "HVAC unit sallon"
		}
		,A2V00001805653 : {
			de: "Seitendecke 03    vollst.",
			en: "sideceiling 03    compl."
		}
		,A2V00001805686 : {
			de: "Seitendecke 06    vollst.",
			en: "sideceiling 06    compl."
		}
		,A2V00002697483 : {
			de: "Gleitschutzventil N8.1",
			en: "anti skid valve N8.1"
		}
		,A2V00002697484 : {
			de: "Gleitschutzventil N8.1",
			en: "anti skid valve N8.1"
		}
		,A2V00002723369 : {
			de: "Radreifen LK-850",
			en: "wheel tire LK-850"
		}
		,A2V00002723368 : {
			de: "Radkoerper D-710",
			en: "wheel centre D-710"
		}
		,A2V00002698424 : {
			de: "Eckprofil, w.g.",
			en: "Edge profile, as drawn"
		}
		,A2V00002134707 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002698536 : {
			de: "Eckprofil, sp.g.",
			en: "Edge profile, rev. im."
		}
		,A2V00002698857 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical shock absorber"
		}
		,A2V00002698858 : {
			de: "Daempfer sekundaer vertikal",
			en: "Secondary vertical shock absorber"
		}
		,A2V00002698863 : {
			de: "Drehdaempfer",
			en: "Yaw damper"
		}
		,A2V00002698865 : {
			de: "Drehdaempfer",
			en: "Yaw damper"
		}
		,A2V00002698877 : {
			de: "Koppeldaempfer",
			en: "couple damper"
		}
		,A2V00002699108 : {
			de: "Rohrdurchfuerung WC",
			en: "pipe feedthrough wc"
		}
		,A2V00002717918 : {
			de: "Lagerbock",
			en: "Bearing support"
		}
		,A2V00002699368 : {
			de: "Endspriegel komplett",
			en: "endgirder complete"
		}
		,A2V00002718509 : {
			de: "Luftversorgungsanlage TA 9 BLE",
			en: "Air supply unit TA 9 BLE"
		}
		,A2V00002718512 : {
			de: "Bremsgeraeteeinheit BLE",
			en: "Brake equipment unit BLE"
		}
		,A2V00002699749 : {
			de: "T-Profil KK-Ende Mehrfachlaenge",
			en: "T-profile short coupler end multi length"
		}
		,A2V00002700016 : {
			de: "Ausgleichsprofil Dach",
			en: "compensation profile roof"
		}
		,A2V00002700037 : {
			de: "C-Schiene",
			en: "c-rail"
		}
		,A2V00002609623 : {
			de: "Magnetschienenbremse",
			en: "Magnetic track brake"
		}
		,A2V00370036734 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002606307 : {
			de: "Wellenbremszange m. Federspeicher",
			en: "Brake Cal./axle m. w. parking brake"
		}
		,A2V00002606310 : {
			de: "Wellenbremszange o. Federspeicher",
			en: "Brake Cal./axle m. wo. parking brake"
		}
		,A2V00002700413 : {
			de: "LZB Konsolenprofil",
			en: "LZB console profile"
		}
		,A2V00002700780 : {
			de: "Seitenwand Modul 2 komplett",
			en: "sidewall module 2 complete"
		}
		,A2V00002700781 : {
			de: "Seitenwand Modul 1 komplett",
			en: "sidewall module 1 complete"
		}
		,A2V00002700796 : {
			de: "Seitenwand Modul 1 komplett",
			en: "sidewall module 1 complete"
		}
		,A2V00002700836 : {
			de: "Seitenwand Modul 2 komplett",
			en: "sidewall module 2 complete"
		}
		,A2V00002700844 : {
			de: "Seitenwand Modul 2 komplett",
			en: "sidewall module 2 complete"
		}
		,A2V00002700858 : {
			de: "Seitenwand Modul 3 komplett",
			en: "sidewall module 3 complete"
		}
		,A2V00002700870 : {
			de: "Seitenwand Modul 3 komplett",
			en: "sidewall module 3 complete"
		}
		,A2V00002700926 : {
			de: "Seitenwand Modul 2 komplett",
			en: "sidewall module 2 complete"
		}
		,A2V00002721791 : {
			de: "Profil mitte, HQT",
			en: "Profile middle, mcb"
		}
		,A2V00002701053 : {
			de: "Fuehrerpult komplett, ++RBA+11",
			en: "Driver's desk complete, ++RBA+11"
		}
		,A2V00002721793 : {
			de: "Profil seitlich, HQT",
			en: "Profile side, mcb"
		}
		,A2V00002721794 : {
			de: "Profil aussen, HQT",
			en: "Profile outside, mcb"
		}
		,A2V00002701192 : {
			de: "Fuehrerpult komplett, ++RBB+11",
			en: "Driver's desk complete, ++RBB+11"
		}
		,A2V00002701211 : {
			de: "Geraeteschrank LZB, kpl. ++RBE +49.50",
			en: "Equipment cab. LZB, cpl. ++RBE +49.50"
		}
		,A2V00002701212 : {
			de: "Geraetetafel HS kpl., ++RBE+72.50",
			en: "Equipm. panel HS cpl., ++RBE+72.50"
		}
		,A2V00002701213 : {
			de: "Container 400V kpl., ++RBA+07.40",
			en: "Container 400V cpl., ++RBA+07.40"
		}
		,A2V00002701215 : {
			de: "Geraetecont. Brem. kpl., ++RBA+71.20",
			en: "Equipm. cont. brake cpl., ++RBA+71.20"
		}
		,A2V00002701216 : {
			de: "Geraetecont. Brem. kpl., ++RBB+71.20",
			en: "Equipm. cont. brake cpl., ++RBB+71.20"
		}
		,A2V00002701217 : {
			de: "Geraetetafel Brem. kpl., ++RBA+79.50",
			en: "Equipm. panel brake cpl., ++RBA+79.50"
		}
		,A2V00002701218 : {
			de: "Geraetetafel Bremse kpl, ++RBB+79.50",
			en: "Equipment panel Brake cpl., ++RBB+79.50"
		}
		,A2V00002560568 : {
			de: "Wegimpulsgeber vorkonfektioniert",
			en: "Distance pulse generator preassembled"
		}
		,A2V00370023539 : {
			de: "KASTENKULISSE W.G. SGP 400-ICE",
			en: "box crank a.d. SGP 400-ICE"
		}
		,A2V00370023540 : {
			de: "KASTENKULISSE SP.G. SGP 400-ICE",
			en: "box crank o.a.d. SGP 400-ICE"
		}
		,A2V00002782968 : {
			de: "Schraubenpaket",
			en: "screw package"
		}
		,A2V00002553471 : {
			de: "Laufradsatz",
			en: "Trailer wheelset"
		}
		,A2V00002553557 : {
			de: "Treibradsatz",
			en: "Motor wheelset"
		}
		,A2V00001821263 : {
			de: "Tuerholmverkl. 1  vollstaendig",
			en: "door panel 1      complete"
		}
		,A2V00113144299 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001420275 : {
			de: "Spoiler aussen vst. sp.g.",
			en: "spoiler, outer compl. rev.i."
		}
		,A2V00001420274 : {
			de: "Spoiler aussen vst. w.g.",
			en: "spoiler left compl. as d."
		}
		,A2V00002133369 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002582006 : {
			de: "Luftfedersystem",
			en: "air spring system"
		}
		,A2V00002701220 : {
			de: "Geraetecont. +03.80 kpl., ++RBE",
			en: "Equipment cont. +03.80 cpl., ++RBE"
		}
		,A2V00002701221 : {
			de: "Geraetecont. +09.80  kpl., ++RBE",
			en: "Equipment cont. +09.80 cpl., ++RBE"
		}
		,A2V00002701222 : {
			de: "Geraetecont. +09.80 komplett, ++RBB",
			en: "Equipment cont. +09.80 complete, ++RBB"
		}
		,A2V00002701499 : {
			de: "Kombikonsole spg. vollstaendig",
			en: "Multi bracket o.a.d. complete"
		}
		,A2V00002701500 : {
			de: "Kombikonsole w.g. vollstaendig",
			en: "Multi bracket a.d. complete"
		}
		,A2V00002701501 : {
			de: "Kombikonsole w.g. vollstaendig",
			en: "Multi bracket a.d. complete"
		}
		,A2V00002701502 : {
			de: "Kombikonsole spg. vollstaendig",
			en: "Multi bracket o.a.d. complete"
		}
		,A2V00002701594 : {
			de: "Tauchzapfen vollstaendig",
			en: "Centre pivot complete"
		}
		,A2V00002701610 : {
			de: "Konsole SVD lackiert",
			en: "Console SVD painted"
		}
		,A2V00002701617 : {
			de: "Konsole SHD lackiert",
			en: "Console SHD painted"
		}
		,A2V00001416436 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370053001 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002703563 : {
			de: "Schweissbadsicherung w.g.",
			en: "weld pool backing as d."
		}
		,A2V00002703901 : {
			de: "Schweissbadsicherung sp.g.",
			en: "weld pool backing rev. i."
		}
		,A2V00200724017 : {
			de: "Schlauch",
			en: "hose, textile-reinforced"
		}
		,A2V00001849121 : {
			de: "Stromabnehmer 1950/DC komplett",
			en: "Pantograph 1950/DC complete"
		}
		,A2V00001849122 : {
			de: "Hauptschalter AC",
			en: "AC vacuum circuit-breaker"
		}
		,A2V00002119510 : {
			de: "Lampenmodul links, vollst.",
			en: "Light module LHS, complete"
		}
		,A2V00370072766 : {
			de: "Gewindeflachdichtung",
			en: "Flat gasket"
		}
		,A2V00002133571 : {
			de: "Innere Fuehrungsschiene",
			en: "Threshold internal part"
		}
		,A2V00002725400 : {
			de: "Endquertraeger KM FSW",
			en: "End Crossbeam HM FSW"
		}
		,A2V00002704822 : {
			de: "Hauptschalter AC",
			en: "main circuit break. AC"
		}
		,A2V00002725440 : {
			de: "Tauchzapfen FSW",
			en: "Center pin FSW"
		}
		,A2V00001097018 : {
			de: "Bremsbelag",
			en: "Brake pad"
		}
		,A2V00001068860 : {
			de: "Bremsbelag LFW COMBINO Meterspur",
			en: "Brakepad"
		}
		,A2V00370082271 : {
			de: "Sicherungsmutter",
			en: "Lock nut"
		}
		,A2V00397160680 : {
			de: "Beilagscheibe 10mm lackiert",
			en: "Primary suspension shim 10mm painted"
		}
		,A2V00002093088 : {
			de: "Fensterfeld 1724",
			en: "window panel 1724"
		}
		,A2V00002096347 : {
			de: "Fensterfeld 1860",
			en: "Window panel 1860"
		}
		,A2V00397161102 : {
			de: "BEILAGE",
			en: "Spacer"
		}
		,A2V00397215555 : {
			de: "Beilage 2mm",
			en: "Shim2mm"
		}
		,A2V00397126501 : {
			de: "RAEUMERBLECH",
			en: "sweeper sheet"
		}
		,A2V00397141330 : {
			de: "BL 8x30x50-S355J2G3C",
			en: "pl 8x30x50-S355J2G3C"
		}
		,A2V00397160410 : {
			de: "Grundhalter",
			en: "holder"
		}
		,A2V00002727482 : {
			de: "Nutenstein",
			en: "Sliding block"
		}
		,A2V00002727545 : {
			de: "Klimaanlage FGR",
			en: "VAC-unit saloon"
		}
		,A2V00001932085 : {
			de: "Wanne komplett 3-W08.11266",
			en: "Tank complete 3-W08.11266"
		}
		,A2V00002727660 : {
			de: "Nutenstein",
			en: "Sliding block"
		}
		,A2V00370036277 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00002728396 : {
			de: "Schalttafel",
			en: "Control panel"
		}
		,A2V00002728489 : {
			de: "WE2-Schrank li. RFC +59 komplett",
			en: "WE2-Cabinet le. RFC +59 complete"
		}
		,A2V00002707706 : {
			de: "Luftbehaelter",
			en: "air reservoir"
		}
		,A2V00002707755 : {
			de: "Bremsgeruest ++RBD+51.90 komplett",
			en: "Brake frame ++RBD+51.90 complete"
		}
		,A2V00002707768 : {
			de: "E-Schrank ++RBB+14 komplett",
			en: "E-cabinet ++RBB+14 complete"
		}
		,A2V00002707769 : {
			de: "E-Schrank ++RBB+15 komplett",
			en: "E-cabinet ++RBB+15 complete"
		}
		,A2V00002707835 : {
			de: "E-Schrank ++RBA+14 komplett",
			en: "E-cabinet ++RBA+14 complete"
		}
		,A2V00002707836 : {
			de: "E-Schrank ++RBA+15 komplett",
			en: "E-cabinet ++RBA+15 complete"
		}
		,A2V00002708258 : {
			de: "E-Schrank ++RBD+59.10 komplett",
			en: "E-cabinet ++RBD+59.10 complete"
		}
		,A2V00002708281 : {
			de: "E-Schrank ++RBC +59.10 komplett",
			en: "E-cabinet ++RBC +59.10 complete"
		}
		,A2V00002708459 : {
			de: "Bremsgeruest ++RBC+51.90 komplett",
			en: "Braking frame ++RBC+51.90 complete"
		}
		,A2V00002708706 : {
			de: "Bremscontainer ++RBA+76.70 komplett",
			en: "Brake container ++RBA+76.70 complete"
		}
		,A2V00002708776 : {
			de: "Bremscontainer +76.70 komplett",
			en: "Brake container +76.70 complete"
		}
		,A2V00002708888 : {
			de: "Fahrerpult komplett",
			en: "Drivers desk complete"
		}
		,A2V00002193412 : {
			de: "Puffer KTL, links",
			en: "buffer cathodic coating, left"
		}
		,A2V00002193411 : {
			de: "Puffer KTL, rechts",
			en: "buffer cathodic coating, rigth"
		}
		,A2V00002151578 : {
			de: "Halter",
			en: "holder"
		}
		,A2V00002151580 : {
			de: "Halter",
			en: "holder"
		}
		,A2V00001831277 : {
			de: "Unterfahrschutz",
			en: "track sweeper"
		}
		,A2V00002156869 : {
			de: "Abstandhuelse",
			en: "distance sleeve"
		}
		,A2V00001872622 : {
			de: "Huelse",
			en: "socket"
		}
		,A2V00002729409 : {
			de: "Basisplatte",
			en: "Base plate"
		}
		,A2V00002730788 : {
			de: "Halter sp.g.",
			en: "Support i.m.i."
		}
		,A2V00002730790 : {
			de: "Halter w.g.",
			en: "Support a.d."
		}
		,A2V00002732619 : {
			de: "Druckbehaelter 80l",
			en: "Air reservoir 80l"
		}
		,A2V00002733358 : {
			de: "Bodenplatte aussen",
			en: "Floorplate, outer"
		}
		,A2V00002733359 : {
			de: "Bodenplatte aussen",
			en: "Floorplate, outer"
		}
		,A2V00002733361 : {
			de: "Bodenplatte, innen 2",
			en: "Floorplate, inner 2"
		}
		,A2V00002733362 : {
			de: "Bodenplatte, innen 2",
			en: "Floorplate, inner 2"
		}
		,A2V00002733363 : {
			de: "Bodenplatte, innen 1",
			en: "Floorplate, inner 1"
		}
		,A2V00002712801 : {
			de: "Titanus MicroSens kompl.",
			en: "intake reducer"
		}
		,A2V00002733364 : {
			de: "Bodenplatte, innen 1",
			en: "Floorplate, inner 1"
		}
		,A2V00002733466 : {
			de: "Zwischenprofil Boden",
			en: "Intermediate floor"
		}
		,A2V00002733838 : {
			de: "Mittellangtraeger oben WE1",
			en: "Middlebeam upper CE1"
		}
		,A2V00002733840 : {
			de: "Mittellangtraeger oben WE2",
			en: "Middlebeam upper CE2"
		}
		,A2V00002733936 : {
			de: "T - Profil 1",
			en: "T - Profile 1"
		}
		,A2V00002733937 : {
			de: "T - Profil 2",
			en: "T - Profile 2"
		}
		,A2V00002734023 : {
			de: "Dachlangtraegerprofil, unteres, mitte",
			en: "Roof cantrail profile, lower, middle"
		}
		,A2V00002734149 : {
			de: "Rammschutzprofil",
			en: "Protection profile"
		}
		,A2V00002734150 : {
			de: "Anhebeplatte",
			en: "Lifting plate"
		}
		,A2V00002734151 : {
			de: "Konsole Schiebetritt",
			en: "Support slide step"
		}
		,A2V00002734152 : {
			de: "Konsole Schiebetritt",
			en: "Support slide step"
		}
		,A2V00002734154 : {
			de: "Konsole Schiebetritt",
			en: "Support slide step"
		}
		,A2V00002714584 : {
			de: "Abhebesicherung lack.",
			en: "Anti lifting device painted"
		}
		,A2V00002734440 : {
			de: "Dachlangtraegerprofil, unteres, FSTE",
			en: "Roof cantrail profile, lower, cab end"
		}
		,A2V00002715048 : {
			de: "Elektrant",
			en: "Elektrant"
		}
		,A2V00002734441 : {
			de: "Dachlangtraegerprofil, unteres, KKE",
			en: "Roof cantrail profile, lower, coup. end"
		}
		,A2V00002734442 : {
			de: "Kupplungsplatte WE 1",
			en: "Couplerplate CE 1"
		}
		,A2V00205394932 : {
			de: "Impulsgeber KMG-2H mit 3,2 M Kabel",
			en: "PULSE GENERATOR KMG-2H WITH 3.2 M CABLE"
		}
		,A2V00201114599 : {
			de: "Einschubduese",
			en: "Einschubduese"
		}
		,A2V00205394860 : {
			de: "Impulsgeber KMG-2H mit 6 m Kabel",
			en: "Pulse generator KMG-2H with cable 6 m"
		}
		,A2V00001220583 : {
			de: "Klebeschleifstueck mit AS-Anschluss",
			en: "Adh. collector strip with AL-connection"
		}
		,A2V00207424771 : {
			de: "Kuehlergitter zum Oeffnen",
			en: "radiator grille"
		}
		,A2V00002734443 : {
			de: "Kupplungsplatte WE2",
			en: "Couplerplate CE2"
		}
		,A2V00002734444 : {
			de: "Dachlangtraegerprofil, oberes, KW",
			en: "Roof cantrail profile, upper, cab car"
		}
		,A2V00001039214 : {
			de: "Dachdurchfuehrung AC25kV 630A 3-716-853",
			en: "Roof bushing AC25kV 630A 3-716-853"
		}
		,A2V00001680555 : {
			de: "Wellenschutz",
			en: "shaft protection"
		}
		,A2V00201110548 : {
			de: "Rollvorhang Rechts",
			en: "Rollvorhang Rechts"
		}
		,A2V00201110549 : {
			de: "Rollvorhang Links",
			en: "Rollvorhang Links"
		}
		,A2V00002734706 : {
			de: "Dachlangtraegerprofil, oberes, MW",
			en: "Roof cantrail profile, upper, MC"
		}
		,A2V00002734728 : {
			de: "Dachquertraegerprofil",
			en: "Roof cross girder profile"
		}
		,A2V00002735031 : {
			de: "Dachplattenprofil, auesseres, KW",
			en: "Roof plate profile, outer, cab car"
		}
		,A2V00002735032 : {
			de: "Dachplattenprofil, auesseres, MW",
			en: "Roof plate profile, outer, MC"
		}
		,A2V00001830748 : {
			de: "Kuehlm./Oel-/Luft-Kuehler",
			en: "Coolant/Oil-/Air radiator"
		}
		,A2V00001921743 : {
			de: "Gleitschutz MGS2",
			en: "Wheel slide control MGS2"
		}
		,A2V00002735033 : {
			de: "Dachplattenprofil, mittleres 1, KW",
			en: "Roof plate profile, middle 1, cab car"
		}
		,A2V00205394516 : {
			de: "Impulsgeber KMG-2H",
			en: "Speed sensor KMG-2H"
		}
		,A2V00205394930 : {
			de: "Impulsgeber KMG-2H mit 3m Kabel",
			en: "Pulse generator KMG-2H with 3m cable"
		}
		,A2V00002175903 : {
			de: "Steuerventil",
			en: "Distributor valve"
		}
		,A2V00002089406 : {
			de: "Frontklappe ZZA vm",
			en: "front flap display pa"
		}
		,A2V00002042546 : {
			de: "Automatische Kupplung UBMC 2.11",
			en: "Automatic Coupler UBMC 2.11"
		}
		,A2V00002519538 : {
			de: "Einstiegstuer re. RAL7037/PE-Schliessg.",
			en: "Entrance door ri. RAL7037/PE-closure"
		}
		,A2V00002519539 : {
			de: "Einstiegstuer li. RAL7037/PE-Schliessg.",
			en: "Entrance door le. RAL7037/PE-closure"
		}
		,A2V00002735034 : {
			de: "Dachplattenprofil, mittleres 1, MW",
			en: "Roof plate profile, middle 1, MC"
		}
		,A2V00002735035 : {
			de: "Dachplattenprofil, mittleres 2, KW",
			en: "Roof plate profile, middle 2, cab car"
		}
		,A2V00002735076 : {
			de: "Dachplattenprofil, mittleres 2, MW",
			en: "Roof plate profile, middle 2, MC"
		}
		,A2V00002735077 : {
			de: "Einfassprofil 1",
			en: "ACU-Profile 1"
		}
		,A2V00002735078 : {
			de: "Einfassprofil 2",
			en: "ACU-Profile 2"
		}
		,A2V00002735080 : {
			de: "Einfassprofil 4",
			en: "ACU-Profile 4"
		}
		,A2V00002735081 : {
			de: "Einfassprofil 5",
			en: "ACU-Profile 5"
		}
		,A2V00002735083 : {
			de: "Klimaplattenprofil",
			en: "AC-plate profile"
		}
		,A2V00002735225 : {
			de: "Bodenprofil einschalig",
			en: "Floorprofile single sheet"
		}
		,A2V00002735246 : {
			de: "Bodenprofil einschalig",
			en: "Floorprofile single sheet"
		}
		,A2V00002715556 : {
			de: "Elektrant",
			en: "Elektrant"
		}
		,A2V00002735247 : {
			de: "Bodenprofil einschalig",
			en: "Floorprofile single sheet"
		}
		,A2V00002715569 : {
			de: "Elektrant",
			en: "Elektrant"
		}
		,A2V00002137322 : {
			de: "Beilage f. Sek.-Feder 1mm",
			en: "Shim f. second. spring 1mm"
		}
		,A2V00201301691 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00002505387 : {
			de: "Einstiegstuer li. RAL9006/PE-Schliessung",
			en: "entrance door le. RAL9006/PE-closure"
		}
		,A2V00002165293 : {
			de: "Ausgangsstufe Binaer 24V-36V/2A",
			en: "Binary Output 24V-36V/2A"
		}
		,A2V00002764584 : {
			de: "Traktionsstromr. SIBAC DM29-1500 G.4b-3",
			en: "Traction convert. SIBAC DM29-1500 G.4b-3"
		}
		,A2V00002735823 : {
			de: "Bodenprofil mit Mulde",
			en: "Floorprofile with vale"
		}
		,A2V00002735824 : {
			de: "Bodenprofil mit Mulde",
			en: "Floorprofile with vale"
		}
		,A2V00002735978 : {
			de: "Quertraeger UG",
			en: "Cross beam underframe"
		}
		,A2V00002736187 : {
			de: "Tuersaeule MP",
			en: "Door pillar MP"
		}
		,A2V00002736189 : {
			de: "Ecksaeule MP",
			en: "Corner post MP"
		}
		,A2V00002736190 : {
			de: "A-Saeule MP",
			en: "A-pillar mp"
		}
		,A2V00002736281 : {
			de: "STW Profil MP",
			en: "Endwall MP"
		}
		,A2V00002736282 : {
			de: "SW Profil unteres MP",
			en: "SW profile bottom MP"
		}
		,A2V00002736283 : {
			de: "SW Profil KKE unteres MP",
			en: "SW profile SCE bottom MP"
		}
		,A2V00002736284 : {
			de: "SW Profil FSTE unteres MP",
			en: "SW profile DE bottom MP"
		}
		,A2V00002736285 : {
			de: "SW Profil unteres mitte MP",
			en: "SW profile bottom middle MP"
		}
		,A2V00002736358 : {
			de: "SW Profil KKE unteres mitte MP",
			en: "SW profile SCE bottom middle MP"
		}
		,A2V00002736359 : {
			de: "SW Profil FSTE unteres mitte MP",
			en: "SW profile DE bottom middle MP"
		}
		,A2V00002736360 : {
			de: "SW Profil oben MP",
			en: "SW profile upper MP"
		}
		,A2V00002736361 : {
			de: "SW Profil KKE oben MP",
			en: "SW profile SCE upper MP"
		}
		,A2V00002736362 : {
			de: "SW Profil FSTE oben MP",
			en: "SW profile DE upper MP"
		}
		,A2V00002736365 : {
			de: "SW Profil mitte MP",
			en: "SW profile middle MP"
		}
		,A2V00002736391 : {
			de: "Langtraeger Oberteil",
			en: "Long. beam upper part"
		}
		,A2V00002736393 : {
			de: "Langtraeger Oberteil",
			en: "Long. beam upper part"
		}
		,A2V00002736394 : {
			de: "Langtraeger Oberteil",
			en: "Long. beam upper part"
		}
		,A2V00002736395 : {
			de: "Langtraeger Unterteil",
			en: "Long. beam bottom part"
		}
		,A2V00002736416 : {
			de: "SW Profil mitte MP",
			en: "SW profile middle MP"
		}
		,A2V00002736422 : {
			de: "SW Profil mitte MP",
			en: "SW profile middle MP"
		}
		,A2V00002736425 : {
			de: "SW Profil mitte MP",
			en: "SW profile middle MP"
		}
		,A2V00002736442 : {
			de: "Halter, geschw.",
			en: "Holder, welded"
		}
		,A2V00002736512 : {
			de: "Batterie LiIon",
			en: "Battery LiIon"
		}
		,A2V00002736516 : {
			de: "Langtraeger Unterteil",
			en: "Long. beam bottom part"
		}
		,A2V00002736517 : {
			de: "Langtraeger Unterteil",
			en: "Long. beam bottom part"
		}
		,A2V00002736645 : {
			de: "Anbindungsteil 2",
			en: "Connection part 2"
		}
		,A2V00002736663 : {
			de: "Anbindungsteil 1",
			en: "Connection part 1"
		}
		,A2V00002736912 : {
			de: "Klimaanlagenbefestigung, Profil",
			en: "AC-fixing, Profile"
		}
		,A2V00002736915 : {
			de: "Dichtprofil 1, Profil",
			en: "Sealing profile 1, Profile"
		}
		,A2V00002736916 : {
			de: "Dichtprofil 2, Profil",
			en: "Sealing profile 2, Profile"
		}
		,A2V00002737380 : {
			de: "Schliessblech FZA 1",
			en: "Closing plate 1"
		}
		,A2V00002737381 : {
			de: "Schliessblech FZA 1",
			en: "Closing plate 1"
		}
		,A2V00002737905 : {
			de: "Nutenstein",
			en: "Sliding block"
		}
		,A2V00002738815 : {
			de: "Daempfer sekundaer horizontal",
			en: "Secondary lateral shock absorber"
		}
		,A2V00002739071 : {
			de: "Daempfer sekundaer horizontal",
			en: "Secondary lateral shock absorber"
		}
		,A2V00002720229 : {
			de: "Liefergarnitur Schiebetuer",
			en: "Delivering set Sliding door"
		}
		,A2V00002720234 : {
			de: "Liefergarnitur Schiebetuer",
			en: "Delivering set Sliding door"
		}
		,A2V00002695825 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002152331 : {
			de: "SprÃ¼hdÃ¼se",
			en: "Spray nozzle"
		}
		,A2V00002625025 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00207250019 : {
			de: "Kompressoraggregat  SL20-5-65",
			en: "Compressor  complete SL20-5-65"
		}
		,A2V00002742793 : {
			de: "Eckschrank MC+59 T+41, vormontiert",
			en: "Cabinet  MC+59 T+41, pre-assembled"
		}
		,A2V00002743268 : {
			de: "Maske C-Teil",
			en: "Cab C-part"
		}
		,A2V00002743269 : {
			de: "Maske seitlich",
			en: "Cab side"
		}
		,A2V00002743437 : {
			de: "Hilfsbetriebeumrichter gesamt",
			en: "Auxiliary inverter compl."
		}
		,A2V00002743438 : {
			de: "Batterie gesamt",
			en: "Battery compl."
		}
		,A2V00002743439 : {
			de: "Stromrichtercontainer UBMC2 Op. ges.",
			en: "Inverter container UBMC2 op. compl."
		}
		,A2V00002743661 : {
			de: "Nutenstein",
			en: "Sliding block"
		}
		,A2V00002743799 : {
			de: "Nutenstein",
			en: "Sliding block"
		}
		,A2V00002724428 : {
			de: "Anschlussleitung Tritt TSI",
			en: "Bowden cable compl."
		}
		,A2V00002724981 : {
			de: "Platte",
			en: "plate"
		}
		,A2V00002724982 : {
			de: "Platte",
			en: "plate"
		}
		,A2V00002744260 : {
			de: "Bodenplatte FSW Profil",
			en: "Bottom plate FSW profile"
		}
		,A2V00397188428 : {
			de: "Halter f. Bahnraeumer WG, lackiert",
			en: "Holder f. track sweeper AD, painted"
		}
		,A2V00001976953 : {
			de: "Wippe 1950 AC",
			en: "Collector head 1950 AC"
		}
		,A2V00397203111 : {
			de: "BEILAGE",
			en: "SHIM"
		}
		,A2V00002399468 : {
			de: "Isolierschlauch 25kV L=870 kompl.",
			en: "Insulating hose 25kV L=870 compl."
		}
		,A2V00002130122 : {
			de: "Treibradsatz K1 vollstaendig",
			en: "Motor wheelset K1 complete"
		}
		,A2V00001163190 : {
			de: "Stromabnehmer NS",
			en: "Pantograph NS"
		}
		,A2V00397216937 : {
			de: "Blech f. Schienenraeumer lack. W.G.",
			en: "Sheet f. life guard painted as drawn"
		}
		,A2V00001897697 : {
			de: "Schlauchleitung 2TE 5 NN45-370 P",
			en: "hose assembly 2TE 5 NN45-370 P"
		}
		,A2V00002138109 : {
			de: "SISRAP23 HSG 217/877.5",
			en: "SISRAP23 HSC 217/877.5"
		}
		,A2V00001060082 : {
			de: "Stromverbinder 25mm2 - 200 LG",
			en: "Current shunt 25mm2 - 200 LG"
		}
		,A2V00002781776 : {
			de: "Profil MRT",
			en: "Profile MRD"
		}
		,A2V00001897706 : {
			de: "Halter fuer Spruehduese sp.g.",
			en: "Holder for nozle o.a.d."
		}
		,A2V00001897707 : {
			de: "Halter fuer Spruehduese w.g.",
			en: "Holder for nozzle a.d."
		}
		,A2V00002549549 : {
			de: "EK- Kabelhalter",
			en: "Holder for cable  of gc"
		}
		,A2V00002549548 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002229288 : {
			de: "Halter Gleitschutzkabel",
			en: "Holder slip sensor cable"
		}
		,A2V00370036731 : {
			de: "Sechskantschraube",
			en: "hexagon head screw"
		}
		,A2V00002728106 : {
			de: "Abdeckblech w.g.",
			en: "cover plate a.d."
		}
		,A2V00002747670 : {
			de: "LDG A WE1",
			en: "TB A CE1"
		}
		,A2V00002747671 : {
			de: "LDG A WE2",
			en: "TB A CE2"
		}
		,A2V00002747672 : {
			de: "TDG B WE1",
			en: "MB B CE1"
		}
		,A2V00002747673 : {
			de: "TDG B WE2",
			en: "MB B CE2"
		}
		,A2V00002747674 : {
			de: "TDG C WE1",
			en: "MB C CE1"
		}
		,A2V00002747736 : {
			de: "TDG C WE2",
			en: "MB C CE2"
		}
		,A2V00002728940 : {
			de: "Trittleiste, vollst.",
			en: "Threshold, compl."
		}
		,A2V00002361577 : {
			de: "Dehnhuelse",
			en: "extension sleeve"
		}
		,A2V00002327743 : {
			de: "Huelse 16",
			en: "bush  16 short 20 mm"
		}
		,A2V00002510604 : {
			de: "Steuerventil",
			en: "Distributor valve"
		}
		,A2V00002748352 : {
			de: "Triebdrehgestell vollstaendig A",
			en: "Motorbogie complete A"
		}
		,A2V00002748432 : {
			de: "Triebdrehgestell vollstaendig B",
			en: "Motorbogie complete B"
		}
		,A2V00002748433 : {
			de: "Laufdrehgestell vollstaendig C",
			en: "Trailerbogie complete C"
		}
		,A2V00002730893 : {
			de: "Schiebetritt, vollst.",
			en: "Sliding step, compl."
		}
		,A2V00002748688 : {
			de: "Fuehrerstands. ZSB sp.g.",
			en: "Cabend post assy i.m.i."
		}
		,A2V00002748689 : {
			de: "Fuehrerstands. ZSB w.g.",
			en: "Cabend post assy a.d."
		}
		,A2V00002748908 : {
			de: "Automatische Kupplung",
			en: "Automatic coupler"
		}
		,A2V00002749521 : {
			de: "Rahmenprofil 1, sp.g.",
			en: "Frame Profile 1, i.m.i."
		}
		,A2V00002749522 : {
			de: "Rahmenprofil 2, w.g.",
			en: "Frame Profile 2, a.d."
		}
		,A2V00002749524 : {
			de: "Rahmenprofil 1, w.g.",
			en: "Frame Profile 1, a.d."
		}
		,A2V00002749525 : {
			de: "Rahmenprofil 2, sp.g.",
			en: "Frame Profile 2,i.m.i."
		}
		,A2V00370061295 : {
			de: "MEGI-RINGFEDER",
			en: "MEGI-ring spring"
		}
		,A2V00002118093 : {
			de: "Getriebe R160N 2LB8125-9CB58",
			en: "Gear R160N 2LB8125-9CB58"
		}
		,A2V00370078616 : {
			de: "MANUELLE FERNBETAETIGUNG TDG",
			en: "MANUAL REMOTE APPLY PT"
		}
		,A2V00002752014 : {
			de: "Schmiede 6082 T6 18 FSW",
			en: "Forging 6082 T6 18 FSW"
		}
		,A2V00002752041 : {
			de: "Triebdrehgestell vollstaendig D",
			en: "Motorbogie complete D"
		}
		,A2V00002752160 : {
			de: "Horn w.g.",
			en: "Horn a.d."
		}
		,A2V00002752232 : {
			de: "Isolierhorn EHW",
			en: "Insulating horn EHW"
		}
		,A2V00002752233 : {
			de: "Isolierhorn EHW",
			en: "Insulating horn EHW"
		}
		,A2V00002752389 : {
			de: "Rahmenprofil 5, w.g.",
			en: "Frame Profile 5, a.d."
		}
		,A2V00002752390 : {
			de: "Rahmenprofil 4, sp.g.",
			en: "Frame Profile 4, i.m.i."
		}
		,A2V00002752391 : {
			de: "Rahmenprofil 5, sp.g.",
			en: "Frame Profile 5, i.m.i."
		}
		,A2V00002752392 : {
			de: "Rahmenprofil 4, w.g.",
			en: "Frame Profile 4, a.d."
		}
		,A2V00002752737 : {
			de: "Konsole 2, Klimageraet",
			en: "Bracket 2, ACU"
		}
		,A2V00002752738 : {
			de: "Konsole 1, Klimageraet",
			en: "Bracket 1, ACU"
		}
		,A2V00002752742 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002752928 : {
			de: "Hauptquertraeger 2 Zus.",
			en: "Main cross girder 2 Assy."
		}
		,A2V00002752929 : {
			de: "Hauptquertraeger 1 Zus.",
			en: "Main cross girder 1 Assy."
		}
		,A2V00001403718 : {
			de: "Gleitlager",
			en: "Plain Bearing"
		}
		,A2V00002753398 : {
			de: "Bodenplatte Vorbau 2 w.g.",
			en: "Bottom plate car end 2 a.d."
		}
		,A2V00002753399 : {
			de: "Bodenplatte Vorbau 2 sp.g.",
			en: "Bottom plate car end 2 i.m.i."
		}
		,A2V00002732977 : {
			de: "Container 400V kpl., ++RBB+07.40",
			en: "Container 400V cpl., ++RBB+07.40"
		}
		,A2V00002753656 : {
			de: "Bodenplatte WE1",
			en: "Floor plate CE1"
		}
		,A2V00002753846 : {
			de: "Sickenblech D4",
			en: "Corrugated sheet D4"
		}
		,A2V00001858140 : {
			de: "Seitenspiegel kpl. li.",
			en: "side mirror complete left"
		}
		,A2V00002122408 : {
			de: "Unterteil",
			en: "Sub-frame"
		}
		,A2V00001834837 : {
			de: "Kupplunghaelfte  Motorseite",
			en: "Coupling half engine side"
		}
		,A2V00002755374 : {
			de: "Steg Kuka unten 2 w.g.",
			en: "Rib coupling box 2 a.d."
		}
		,A2V00002755375 : {
			de: "Steg Kuka unten 2 sp.g.",
			en: "Rib coupling box 2 i.m.i."
		}
		,A2V00002755729 : {
			de: "Blech WE1, crash, sp.g.",
			en: "Sheet CE1, crash, i.m.i."
		}
		,A2V00002755730 : {
			de: "Blech WE1, crash, w.g.",
			en: "Sheet CE1, crash, a.d."
		}
		,A2V00002755750 : {
			de: "Maskenblech",
			en: "Mask plate"
		}
		,A2V00002755829 : {
			de: "C-Schiene Profil 10",
			en: "C-profil 10"
		}
		,A2V00002755955 : {
			de: "Deckel HQT w.g.",
			en: "Cover HQT a.d."
		}
		,A2V00002755977 : {
			de: "Deckel FST",
			en: "Cover drivers cab"
		}
		,A2V00002756306 : {
			de: "Deckel HQT sp.g.",
			en: "Cover HQT i.m.i."
		}
		,A2V00002756549 : {
			de: "Schleifstueck SK2329 -SK85W-",
			en: "Contact strip SK2329 -SK85W-"
		}
		,A2V00002756555 : {
			de: "UG Stw Quertraeger",
			en: "UG endwall cross beam"
		}
		,A2V00002115612 : {
			de: "Einspannbuchse",
			en: "Elastic bushing"
		}
		,A2V00001863942 : {
			de: "Bedientafel X4E1133 V4",
			en: "Operating panel X4E1133 V4"
		}
		,A2V00002756727 : {
			de: "Zwischenprofil",
			en: "Profile floor"
		}
		,A2V00002756729 : {
			de: "Bodenplatte Profil",
			en: "Part floor plate profile"
		}
		,A2V00002756731 : {
			de: "Zwischenprofil",
			en: "Profile floor"
		}
		,A2V00002756732 : {
			de: "Hauptquertraeger Profil",
			en: "Main cross beam profile"
		}
		,A2V00002756733 : {
			de: "Stirnwandtraeger Profil",
			en: "End wall cross beam profile"
		}
		,A2V00002736439 : {
			de: "Kombikuehlanlage",
			en: "Cooling unit without cover"
		}
		,A2V00002756877 : {
			de: "Dachprofil 2.80",
			en: "Roof profile 2.80"
		}
		,A2V00002757181 : {
			de: "Konsole geschw.",
			en: "Console welded"
		}
		,A2V00002736714 : {
			de: "Kombikuehlanlage Kuehlung zusaetzl. HBU",
			en: "Cooling unit Cooling add. HBU"
		}
		,A2V00002757502 : {
			de: "Konsole vollst.",
			en: "Console compl."
		}
		,A2V00002757847 : {
			de: "Konsole ATC",
			en: "Console ATC"
		}
		,A2V00002359394 : {
			de: "Kraftstoffbehaelter",
			en: "Fuel container"
		}
		,A2V00001876570 : {
			de: "Einstiegstuer re.",
			en: "entrance door ri."
		}
		,A2V00001422957 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002776748 : {
			de: "3PQ3085-0AA00 Rahmen 2",
			en: "3PQ3085-0AA00 frame 2"
		}
		,A2V00002776816 : {
			de: "3PQ3085-0AA00 Rahmen 3",
			en: "3PQ3085-0AA00 frame 3"
		}
		,A2V00001899927 : {
			de: "Druckl.erz.ger. vst.",
			en: "Compressor rack cpl."
		}
		,A2V00002759614 : {
			de: "Mulde Bodenplatte w.g.",
			en: "Slot floor plate a.d."
		}
		,A2V00002759666 : {
			de: "Erdungslasche M10",
			en: "Earthing flap M10"
		}
		,A2V00002738114 : {
			de: "Leitungen kpl. uW",
			en: "conduit cpl. uc"
		}
		,A2V00002759701 : {
			de: "Zwischenprofil UG",
			en: "Intermediate Profile UG"
		}
		,A2V00002738154 : {
			de: "Leitungen kpl. Dach",
			en: "Conduit cpl. roof"
		}
		,A2V00002738163 : {
			de: "Leitungen kpl. uW",
			en: "conduit cpl. uc"
		}
		,A2V00002759743 : {
			de: "Isolierhorn 2-W08.8986",
			en: "Insulating horn 2-W08.8986"
		}
		,A2V00002738171 : {
			de: "Leitungen kpl. Dach",
			en: "Conduit cpl. roof"
		}
		,A2V00002738664 : {
			de: "Sandwichrampe, lackiert",
			en: "Sandwich Ramp, painted"
		}
		,A2V00002761130 : {
			de: "Lehne geschw.",
			en: "Backboard welded"
		}
		,A2V00001751006 : {
			de: "Aufhaengung",
			en: "Suspension"
		}
		,A2V00002739207 : {
			de: "Zwischenboden 2, lackiert",
			en: "intermediate floor 2, painted"
		}
		,A2V00002761669 : {
			de: "Nutenstein",
			en: "Sliding block"
		}
		,A2V00002739439 : {
			de: "Zwischenboden 3, lackiert",
			en: "intermediate floor 3, painted"
		}
		,A2V00002739933 : {
			de: "Druckluftbehaelter",
			en: "air reservoir"
		}
		,A2V00002762015 : {
			de: "Dachwellblech oben seitlich",
			en: "Roof sheet"
		}
		,A2V00002762110 : {
			de: "Dachwellblech oben",
			en: "Corrugated roof profile"
		}
		,A2V00002740183 : {
			de: "LIEFERGARNITUR DACHSCHUERZE AB",
			en: "Delivering set roof skirt assembly"
		}
		,A2V00002740184 : {
			de: "LIEFERGARNITUR DACHSCHUERZE AB TEIL 1",
			en: "Delivering set roof skirt assy. part one"
		}
		,A2V00002740185 : {
			de: "LIEFERGARNITUR DACHSCHUERZE AB TEIL 2",
			en: "Delivering set roof skirt assy. part two"
		}
		,A2V00002741502 : {
			de: "Traktionsstromrichter mit HBU",
			en: "Traction converter with ACU"
		}
		,A2V00002763722 : {
			de: "Verbindungsstueck, gesch.",
			en: "Connection piece, welded"
		}
		,A2V00002763724 : {
			de: "Verbindungsstueck, gesch.",
			en: "Connection piece, welded"
		}
		,A2V00002741860 : {
			de: "Geraetetafel WBT. kpl., ++RBA+49.10",
			en: "Equipm. panel WBT cpl., ++RBA+49.10"
		}
		,A2V00002741861 : {
			de: "Geraetetafel WBT. kpl., ++RBB+49.10",
			en: "Equipm. panel WBT cpl., ++RBB+49.10"
		}
		,A2V00002741971 : {
			de: "Profil",
			en: "Profile"
		}
		,A2V00002742067 : {
			de: "Profil",
			en: "Profile"
		}
		,A2V00002742549 : {
			de: "Bremsbelag BK4972",
			en: "Brake pad BK4972"
		}
		,A2V00002764159 : {
			de: "Bogen, innen, Zusb., w.g.",
			en: "Bow, inner, Assy.,a.d."
		}
		,A2V00002764160 : {
			de: "Bogen, innen, Zusb., sp.g.",
			en: "Bow, inner, Assy., i.m.i."
		}
		,A2V00002764252 : {
			de: "Bogen, aussen, Zusb., w.g.",
			en: "Bow, outside, Assy., a.d."
		}
		,A2V00002764253 : {
			de: "Bogen, aussen, Zusb., sp.g.",
			en: "Bow, outside, Assy., i.m.i."
		}
		,A2V00002764385 : {
			de: "Abdeckblech sp.g.",
			en: "Coversheet i.m.i."
		}
		,A2V00002764512 : {
			de: "Dachquertraeger WE1, Zusb.",
			en: "Roof cross beam CE1, Assy."
		}
		,A2V00002764580 : {
			de: "Endblech w.g.",
			en: "Endsheet a.d."
		}
		,A2V00002764581 : {
			de: "Endblech sp.g.",
			en: "Endsheet i.m.i."
		}
		,A2V00002764616 : {
			de: "Abdeckblech w.g.",
			en: "Coversheet a.d."
		}
		,A2V00002764619 : {
			de: "Dachquertraeger WE2, Zusb.",
			en: "Roof cross beam CE2, Assy."
		}
		,A2V00002764722 : {
			de: "Crashprofil Zuschnitt",
			en: "Crash profile blank cut"
		}
		,A2V00002764723 : {
			de: "Abdeckprofil Zuschnitt",
			en: "Cover profile blank cut"
		}
		,A2V00002764725 : {
			de: "Querprofil Zuschnitt",
			en: "Cross beam blank cut"
		}
		,A2V00002707485 : {
			de: "Stuetzisolator 25kV mit Montagematerial",
			en: "Post insulator 25kV with mounting parts"
		}
		,A2V00002370468 : {
			de: "Bahnisolator 15kV",
			en: "Railway insulator 15kV"
		}
		,A2V00201175235 : {
			de: "Seitenblende",
			en: "Seitenblende"
		}
		,A2V00002764996 : {
			de: "Abdeckprofil Zuschnitt",
			en: "Cover profile blank cut"
		}
		,A2V00002765004 : {
			de: "Gewindestange isoliert ohne Silikon",
			en: "Thread rod insulated without silicone"
		}
		,A2V00002765262 : {
			de: "Windleitblech 75 - 120 fuer SSS87",
			en: "Spoiler 75 - 120 for SSS87"
		}
		,A2V00002765336 : {
			de: "Blech EN 10051 2X1220X4000 S355J2W+N",
			en: "Sheet EN 10051 2X1220X4000 S355J2W+N"
		}
		,A2V00002765339 : {
			de: "Blech EN 10029 3X1440X4000 S355J2W+N",
			en: "Sheet EN 10029 3X1440X4000 S355J2W+N"
		}
		,A2V00002765340 : {
			de: "Blech EN 10029 4X1500X4000 S355J2W+N",
			en: "Sheet EN 10029 4X1500X4000 S355J2W+N"
		}
		,A2V00002765342 : {
			de: "Blech EN 10029 5X1600X4000 S355J2W+N",
			en: "Sheet EN 10029 5X1600X4000 S355J2W+N"
		}
		,A2V00002765343 : {
			de: "Blech EN 10029 6X1600X4000 S355J2W+N",
			en: "Sheet EN 10029 6X1600X4000 S355J2W+N"
		}
		,A2V00002765345 : {
			de: "Blech EN 10029 8X1600X4000 S355J2W+N",
			en: "Sheet EN 10029 8X1600X4000 S355J2W+N"
		}
		,A2V00002765346 : {
			de: "Blech EN 10029 10X1600X4000 S355J2W+N",
			en: "Sheet EN 10029 10X1600X4000 S355J2W+N"
		}
		,A2V00002765349 : {
			de: "Blech EN 10051 6X1500X4000 S460MC",
			en: "Sheet EN 10051 6X1500X4000 S460MC"
		}
		,A2V00002766859 : {
			de: "Schweissbadsicher.,sp.g.",
			en: "Weld Backing, i.m.i."
		}
		,A2V00002766860 : {
			de: "Schweissbadsicherung,w.g.",
			en: "Weld Backing, a.d."
		}
		,A2V00002766887 : {
			de: "Schweissbadsicher., sp.g.",
			en: "Weld Backing, i.m.i."
		}
		,A2V00002766888 : {
			de: "Schweissbadsicher., w.g.",
			en: "Weld Backing, a.d."
		}
		,A2V00002767409 : {
			de: "Antennenhalter 1",
			en: "Antenna Support 1"
		}
		,A2V00002767410 : {
			de: "Antennenhalter 2",
			en: "Antenna Support 2"
		}
		,A2V00397159024 : {
			de: "Buchse fuer Motorquerbeg.",
			en: "Bush for motor cross lim."
		}
		,A2V00002136887 : {
			de: "Tuerhalter, rechts",
			en: "Dragging arm, right"
		}
		,A2V00002556812 : {
			de: "Tuerantrieb",
			en: "Door operator"
		}
		,A2V00002744390 : {
			de: "Abdeckung geschw.",
			en: "Cover welded"
		}
		,A2V00002744391 : {
			de: "Abdeckung geschw.",
			en: "Cover welden"
		}
		,A2V00002771191 : {
			de: "Verbindungsblech",
			en: "Connectionsheet"
		}
		,A2V00002771397 : {
			de: "Scheibe - 200HV - GEO",
			en: "Washer - 200HV - GEO"
		}
		,A2V00002771398 : {
			de: "Verschlussblech, w.g.",
			en: "Coversheet, a.d."
		}
		,A2V00002771515 : {
			de: "Rohr, langes",
			en: "Tube, long"
		}
		,A2V00002771540 : {
			de: "Verschlussblech, w.g.",
			en: "Coversheet, a.d."
		}
		,A2V00002771541 : {
			de: "Verschlussblech, sp.g.",
			en: "Coversheet, i.m.i."
		}
		,A2V00002772090 : {
			de: "Eckschrank T+51, vormontiert",
			en: "Cabinet T+51, pre-assembled"
		}
		,A2V00002772119 : {
			de: "Dachlangtraeger KKE, Zusb., sp.g.",
			en: "Centrail SCE, Assy., i.m.i."
		}
		,A2V00002772120 : {
			de: "Dachlangtraeger KKE, Zusb., w.g.",
			en: "Centrail SCE, Assy., a.d."
		}
		,A2V00002772181 : {
			de: "Abdeckblech, w.g.",
			en: "Coversheet, a.d."
		}
		,A2V00002772182 : {
			de: "Abdeckblech,sp.g.",
			en: "Coversheet, i.m.i."
		}
		,A2V00002772257 : {
			de: "Dachlangtraeger FSE, Zusb. w.g.",
			en: "Centrail CE, Assy. a.d."
		}
		,A2V00002772258 : {
			de: "Dachlangtraeger FSE, Zusb. sp.g.",
			en: "Centrail CE, Assy. i.m.i."
		}
		,A2V00002623449 : {
			de: "Wippe 1450/DC kompl.",
			en: "Collector head 1450/DC compl."
		}
		,A2V00002772885 : {
			de: "Teil Einstiegssteg",
			en: "Part entrance web"
		}
		,A2V00002772938 : {
			de: "Teil Pufferbrust",
			en: "Part puffer plate"
		}
		,A2V00002774406 : {
			de: "Teil Kupplungsraum",
			en: "Part coupler space"
		}
		,A2V00002774429 : {
			de: "Teil Ausschnitt LT",
			en: "Part cutout LT"
		}
		,A2V00002774563 : {
			de: "Endbogen, w.g.",
			en: "Endbow, a.d."
		}
		,A2V00002774564 : {
			de: "Endbogen, sp.g.",
			en: "Endbow, i.m.i."
		}
		,A2V00002774760 : {
			de: "Dachabschlussblech 1, KKE",
			en: "Roof End Sheet 1, SCE"
		}
		,A2V00002774761 : {
			de: "Dachabschlussblech 2, FSTE",
			en: "Roof End Sheet 2, CE"
		}
		,A2V00002774762 : {
			de: "Dachabschlussblech 3, FS",
			en: "Roof End Sheet 3, Cab"
		}
		,A2V00002775714 : {
			de: "KKE Kiste 2 w.g.",
			en: "Coupling box 2 a.d."
		}
		,A2V00002747723 : {
			de: "Leitung kpl. Fuehrerraum",
			en: "Conduit cpl. driver cab"
		}
		,A2V00002747939 : {
			de: "Leitung kpl. Fuehrerraum",
			en: "Conduit cpl. driver cab"
		}
		,A2V00002156625 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002364487 : {
			de: "Laufradsatzwelle",
			en: "Trailer axle"
		}
		,A2V00002147990 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00397136689 : {
			de: "Passfeder f. Drehmomentst",
			en: "Shim  f. reaction rod"
		}
		,A2V00001691935 : {
			de: "Aufsatz komplett",
			en: "Top case complete"
		}
		,A2V00002748785 : {
			de: "Netzspannungswandler",
			en: "Line voltage transducer"
		}
		,A2V00002778620 : {
			de: "Frontplatte geschweisst",
			en: "Base welded"
		}
		,A2V00002778633 : {
			de: "Schleiflstueck",
			en: "Contact strip"
		}
		,A2V00001908703 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002751131 : {
			de: "Probestueck, Profil",
			en: "testing body, profile"
		}
		,A2V00002778780 : {
			de: "Federlagerung SWT",
			en: "Spring support WP"
		}
		,A2V00002778781 : {
			de: "Federlagerung SWT",
			en: "Spring support WP"
		}
		,A2V00002751244 : {
			de: "Langtraeger Profil Mitte",
			en: "longitud. girder profile center"
		}
		,A2V00002751245 : {
			de: "Seitenwandprofil NF 5",
			en: "Sidewall profile lf 5"
		}
		,A2V00002751277 : {
			de: "Seitenwandprofil NF 4",
			en: "Sidewall profile lf 4"
		}
		,A2V00002751278 : {
			de: "Seitenwandprofil NF 2",
			en: "sidewall profile lf 2"
		}
		,A2V00002751279 : {
			de: "Seitenwandprofil NF 1",
			en: "Sidewall profile lf 1"
		}
		,A2V00002779569 : {
			de: "Konsole Pult",
			en: "Support desk"
		}
		,A2V00370026670 : {
			de: "Puffer",
			en: "Buffer"
		}
		,A2V00001999484 : {
			de: "Schutzblech Rohr gesch wg",
			en: "Protection plate weld ad"
		}
		,A2V00110193404 : {
			de: "ENDPROFIL",
			en: "ENDPROFIL"
		}
		,A2V00001415974 : {
			de: "Kegelrollenlagereinheit",
			en: "tapered bearing unit TBU 130X230X160"
		}
		,A2V00002617438 : {
			de: "Hauptfunkenschildeinheit rechts",
			en: "Main arcshield assembly right"
		}
		,A2V00002617439 : {
			de: "Hauptfunkenschildeinheit links",
			en: "Main arcshield assembly left"
		}
		,A2V00002781094 : {
			de: "Eckschrank T+59, vormontiert",
			en: "Cabinet  T+59, pre-assembled"
		}
		,A2V00002781095 : {
			de: "Eckschrank MC+49 T+49, vorm",
			en: "Cabinet MC+49 T+49, pre-asm"
		}
		,A2V00002782201 : {
			de: "Huelse d10,5/12",
			en: "Sleeve d10,5/12"
		}
		,A2V00002193749 : {
			de: "RSLDeckelhinten lackiert",
			en: "Axle box back cover"
		}
		,A2V00001846547 : {
			de: "Knochenbuchse",
			en: "spheribloc"
		}
		,A2V00002160073 : {
			de: "Zugidentifikationssensor vollst.",
			en: "Train identification sensor compl."
		}
		,A2V00002782235 : {
			de: "Stoessel SWT",
			en: "Tappet WP"
		}
		,A2V00002782296 : {
			de: "Federlagerung SWT",
			en: "Spring support WP"
		}
		,A2V00002782302 : {
			de: "Traeger SWT",
			en: "Beam WP"
		}
		,A2V00002782329 : {
			de: "Lasche",
			en: "Link"
		}
		,A2V00002782409 : {
			de: "Feuerloescherhalter",
			en: "Fire extinguisher bracket"
		}
		,A2V00002782465 : {
			de: "Gurtblech WE1",
			en: "Chord plate ce1"
		}
		,A2V00002782532 : {
			de: "Horn sp.g.",
			en: "Horn i.m.i."
		}
		,A2V00002782686 : {
			de: "Gurtblech WE2",
			en: "Chord plate ce2"
		}
		,A2V00002782981 : {
			de: "KKE Kiste 2 sp.g.",
			en: "Coupling box 2 i.m.i."
		}
		,A2V00002783165 : {
			de: "Schleifstueck SK2349 -SK85W-",
			en: "Contact strip SK2349 -SK85W-"
		}
		,A2V00001977745 : {
			de: "Kupplung, komplett",
			en: "coupler, complete"
		}
		,A2V00002758139 : {
			de: "Magnet",
			en: "Magnet"
		}
		,A2V00002446482 : {
			de: "Bremszylinder ohne Federspeicher",
			en: "Bremszylinder ohne Federspeicher"
		}
		,A2V00001947851 : {
			de: "Lagerschild  D030230",
			en: "End shield (N-end) D030230E01"
		}
		,A2V00201179193 : {
			de: "TRAFOSCHUERZE KURZ",
			en: "TRAFOSCHUERZE KURZ"
		}
		,A2V00002783383 : {
			de: "E-Tunnel Blech",
			en: "E-tunnel plate"
		}
		,A2V00002783587 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002358370 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002784045 : {
			de: "Batterie",
			en: "Battery"
		}
		,A2V00002784322 : {
			de: "Flansch SWT/RAL7021",
			en: "Flange wp/RAL7021"
		}
		,A2V00002784474 : {
			de: "Nabe",
			en: "Hub"
		}
		,A2V00002784482 : {
			de: "Befestigungsplatte",
			en: "Support plate"
		}
		,A2V00002784949 : {
			de: "Flansch mit Rohr SWT/RAL7021",
			en: "Flange with tube wp/RAL7021"
		}
		,A2V00002785121 : {
			de: "Hohlwelle",
			en: "Hollow shaft"
		}
		,A2V00002761738 : {
			de: "Seitenwand Modul 2 komplett",
			en: "sidewall module 2 complete"
		}
		,A2V00002785205 : {
			de: "Kolbenstange",
			en: "Piston rod"
		}
		,A2V00002761800 : {
			de: "Seitenwand Modul 2 komplett",
			en: "sidewall module 2 complete"
		}
		,A2V00002785260 : {
			de: "Stromanschlusslasche",
			en: "Current connection link"
		}
		,A2V00002761945 : {
			de: "Langtraeger bearb.",
			en: "longitudinal girder mach."
		}
		,A2V00002785277 : {
			de: "Distanzrohr",
			en: "Distance tube"
		}
		,A2V00002761976 : {
			de: "Langtraeger bearb.",
			en: "longitudinal girder mach."
		}
		,A2V00002785281 : {
			de: "Distanzrohr",
			en: "Distance tube"
		}
		,A2V00002785450 : {
			de: "Scheibe d45",
			en: "Washer d45"
		}
		,A2V00002785493 : {
			de: "Rippe WE1, w.g.",
			en: "Rib WE1, a.d."
		}
		,A2V00002785512 : {
			de: "Rippe 2, sp.g.",
			en: "Rib 2, i.m.i."
		}
		,A2V00002785579 : {
			de: "Rippe WE1, sp.g.",
			en: "Rib WE1, i.m.i."
		}
		,A2V00002762890 : {
			de: "Crash Modul vollst.",
			en: "crash modul complete"
		}
		,A2V00002785587 : {
			de: "Distanzbuchse d20",
			en: "Spacer sleeve d20"
		}
		,A2V00002785607 : {
			de: "Rippe 2, w.g.",
			en: "Rib 2, a.d."
		}
		,A2V00002785636 : {
			de: "Schliessblech FZA 3",
			en: "Closing plate 3"
		}
		,A2V00002785652 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002785655 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002785728 : {
			de: "Bodenplatte WE1",
			en: "Floor plate CE1"
		}
		,A2V00002785768 : {
			de: "Bodenblech, WE2",
			en: "Floor panel, CE2"
		}
		,A2V00001225690 : {
			de: "IGBT-CGE-1200-330-01",
			en: "IGBT adapter"
		}
		,A2V00156017928 : {
			de: "DROSSEL",
			en: "choke"
		}
		,A2V00109400169 : {
			de: "UNTERGESTELL WGT.2",
			en: "UNTERGESTELL WGT.2"
		}
		,A2V00002785881 : {
			de: "Rippe WE2",
			en: "Rib CE2"
		}
		,A2V00002786032 : {
			de: "Oberarm H Alu SWT/RAL9006",
			en: "Upper arm H Alu wp/RAL9006"
		}
		,A2V00002786068 : {
			de: "Rampe sp.g.",
			en: "Ramp i.m.i."
		}
		,A2V00002786069 : {
			de: "Rampe w.g.",
			en: "Ramp a.d."
		}
		,A2V00002786073 : {
			de: "Stuetzblech Grundrahmen",
			en: "Support plate base frame"
		}
		,A2V00002786230 : {
			de: "Verschlussschraube, bearbeitet",
			en: "Plug, machined"
		}
		,A2V00002786329 : {
			de: "Nutenstein",
			en: "Sliding block"
		}
		,A2V00002786330 : {
			de: "Nutenstein",
			en: "Sliding block"
		}
		,A2V00002786955 : {
			de: "Konsole f. Spaltueberbrueckung",
			en: "Support for gap bridging"
		}
		,A2V00002787148 : {
			de: "Spannhuelse",
			en: "Clamping sleeve"
		}
		,A2V00002787338 : {
			de: "Einschweissrohr 1",
			en: "Weld-in pipe 1"
		}
		,A2V00002765164 : {
			de: "Anschlussleitung Unterbautritt, lang",
			en: "Connecting cable sliding step, long"
		}
		,A2V00002765191 : {
			de: "Liefergarnitur Seitenwandverkl. EWA",
			en: "Delivering set side wall paneling EWA"
		}
		,A2V00002765193 : {
			de: "Liefergarnitur Seitenwandverkl. EWB",
			en: "Delivering set side wall paneling EWB"
		}
		,A2V00002765277 : {
			de: "Liefergarnitur Seitenwandverkl. MWE",
			en: "Delivering set side wall paneling MWE"
		}
		,A2V00002787529 : {
			de: "Langtraeger Unterteil",
			en: "Long. beam bottom part"
		}
		,A2V00002788041 : {
			de: "Deckel",
			en: "Cover"
		}
		,A2V00002788043 : {
			de: "Anschlag, verzinkt",
			en: "Stopper, zinc plated"
		}
		,A2V00001973451 : {
			de: "Bugschuerze rechts",
			en: "Side Skirt right"
		}
		,A2V00001885781 : {
			de: "Luftbehaelter",
			en: "Air reservoir"
		}
		,A2V00002331049 : {
			de: "C-Schiene Tisch",
			en: "C-rail table"
		}
		,A2V00002727101 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002772634 : {
			de: "Leitungen kpl. uW",
			en: "conduit cpl. uc"
		}
		,A2V00002772635 : {
			de: "Leitungen kpl. uW",
			en: "conduit cpl. uc"
		}
		,A2V00002772775 : {
			de: "E-Box Trainserver ++RBB+67.12 komplett",
			en: "E-Box Trainserver ++RBB+67.12 complete"
		}
		,A2V00002772806 : {
			de: "E-Box Trainserver ++RBA+67.12 komplett",
			en: "E-Box Trainserver ++RBA+67.12 complete"
		}
		,A2V00002790079 : {
			de: "Bef. f. Lautsprecher",
			en: "Mount. f. loudspeaker"
		}
		,A2V00002790754 : {
			de: "Gurtblech, w.g.",
			en: "Belt plate, a.d."
		}
		,A2V00002772926 : {
			de: "Leitungen kpl. im Wagen",
			en: "Conduit cpl. in car"
		}
		,A2V00002790755 : {
			de: "Gurt, sp.g.",
			en: "Belt, i.m.i."
		}
		,A2V00002772927 : {
			de: "Leitungen kpl. im Wagen",
			en: "Conduit cpl. in car"
		}
		,A2V00002772928 : {
			de: "Leitungen kpl. Dach",
			en: "Conduit cpl. roof"
		}
		,A2V00002772929 : {
			de: "Leitungen kpl. Dach",
			en: "Conduit cpl. roof"
		}
		,A2V00002790818 : {
			de: "Schweissbadsicherung sp.g.",
			en: "Weld backing i.m.i."
		}
		,A2V00002790819 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002790820 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002790821 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002790822 : {
			de: "Schweissbadsicherung w.g.",
			en: "Weld backing a.d."
		}
		,A2V00002790926 : {
			de: "Gurtblech, sp.g.",
			en: "Belt plate, i.m.i."
		}
		,A2V00002790927 : {
			de: "Gurt, w.g.",
			en: "Belt, a.d."
		}
		,A2V00002774872 : {
			de: "Zwischenboden 4, lackiert",
			en: "intermediate floor 4, painted"
		}
		,A2V00002791847 : {
			de: "Schweissbadsicherung sp.g.",
			en: "Weld backing i.m.i."
		}
		,A2V00002791848 : {
			de: "Schweissbadsicherung w.g.",
			en: "Weld backing a.d."
		}
		,A2V00002792081 : {
			de: "E-Tunnel Blech",
			en: "E-tunnel plate"
		}
		,A2V00002792085 : {
			de: "Kupplungskasten 2 KKE",
			en: "Coupling-box 2 SCE"
		}
		,A2V00002792843 : {
			de: "Fuehrungsglied kpl 3-1038.2962",
			en: "Guiding element cpl 3-1038.2962"
		}
		,A2V00002792845 : {
			de: "Lagerbuchse 4-L08.1961",
			en: "Bearing bushing 4-L08.1961"
		}
		,A2V00002793048 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002793360 : {
			de: "Schweissbadsicherung",
			en: "Weld Backing"
		}
		,A2V00002776319 : {
			de: "Probestueck grundiert, Profil",
			en: "testing body primed, profile"
		}
		,A2V00002793666 : {
			de: "Montageblock",
			en: "Assembly block"
		}
		,A2V00002793715 : {
			de: "Distanzplatte",
			en: "Spacer"
		}
		,A2V00002793837 : {
			de: "E-Durchfuehrung w.g.",
			en: "E-leadthrough a.d."
		}
		,A2V00002793838 : {
			de: "E-Durchfuehrung sp.g.",
			en: "E-leadthrough i.m.i."
		}
		,A2V00002794264 : {
			de: "Verzurrlasche",
			en: "Fixing clamp"
		}
		,A2V00002794288 : {
			de: "Langtr. Deckel sp.g.",
			en: "Long. girder i.m.i."
		}
		,A2V00002794289 : {
			de: "Langtr. Deckel w.g.",
			en: "Long. girder a.d."
		}
		,A2V00002776942 : {
			de: "Seitenwandmodul F-Ende links",
			en: "sidewall frontend left"
		}
		,A2V00002794423 : {
			de: "Kupplungskastenblech, sp.g.",
			en: "Coupling-box panel, i.m.i."
		}
		,A2V00002794424 : {
			de: "Kupplungskastenblech, w.g.",
			en: "Coupling-box panel, a.d."
		}
		,A2V00002794537 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002794866 : {
			de: "Verschlussblech",
			en: "Cap plate"
		}
		,A2V00002794909 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002778515 : {
			de: "L-Profil Sitzstuetze vst.",
			en: "L-profile seat supp asm."
		}
		,A2V00002795072 : {
			de: "Sitzkonsole vollst.",
			en: "Seat frame compl."
		}
		,A2V00002795109 : {
			de: "Deckel Bodenplatte sp.g.",
			en: "Cover bottom plate i.m.i."
		}
		,A2V00002795110 : {
			de: "Anhebeplatte",
			en: "Lifting plate"
		}
		,A2V00002795248 : {
			de: "Deckel Bodenplatte w.g.",
			en: "Cover bottem plate a.d."
		}
		,A2V00002779497 : {
			de: "Wagenuebergangs-  kasten",
			en: "Inter-vehicle     passage box"
		}
		,A2V00002779509 : {
			de: "Wagenuebergangs-  kasten",
			en: "Inter-vehicle     passage box"
		}
		,A2V00002795827 : {
			de: "Stuetzisolator 25kV",
			en: "Support insulator 25kV"
		}
		,A2V00002795828 : {
			de: "Stuetzisolator 25kV",
			en: "Support insulator 25kV"
		}
		,A2V00002796004 : {
			de: "Verschlussblech, sp.g.",
			en: "Coversheet, i.m.i."
		}
		,A2V00002796020 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002796037 : {
			de: "Datenrekorder MC-B31",
			en: "Event recorder MC-B31"
		}
		,A2V00002796045 : {
			de: "Schweissbadsicherung sp.g.",
			en: "Weld Backing i.m.i."
		}
		,A2V00002796226 : {
			de: "Schweissbadsicherung w.g.",
			en: "Weld Backing a.d."
		}
		,A2V00002796259 : {
			de: "Rahmen f. Spaltueberbr.",
			en: "Frame f. gap-bridging"
		}
		,A2V00002796260 : {
			de: "Rahmen f. Spaltueberbr.",
			en: "Frame f. gap-bridging"
		}
		,A2V00002796261 : {
			de: "Rahmen Spaltueberbr. 2, w.g.",
			en: "Frame gap-bridging 2, a.d."
		}
		,A2V00002796262 : {
			de: "Rahmen Spaltueberbr. 2, sp.g.",
			en: "Frame gap-bridging 2, i.m.i."
		}
		,A2V00002796263 : {
			de: "Rahmen Spaltueberbr. 4, sp.g.",
			en: "Frame gap-bridging 4, i.m.i."
		}
		,A2V00002796264 : {
			de: "Rahmen Spaltueberbr. 3, w.g.",
			en: "Frame gap-bridging 3, a.d."
		}
		,A2V00002796265 : {
			de: "Rahmen Spaltueberbr. 3, sp.g.",
			en: "Frame gap-bridging 3, i.m.i."
		}
		,A2V00002796270 : {
			de: "Blech Spaltueberbrueckung",
			en: "Gap bridging sheet"
		}
		,A2V00002796306 : {
			de: "Rahmen Spaltueberbr. 4, w.g.",
			en: "Frame gap-bridging 4, a.d."
		}
		,A2V00002796571 : {
			de: "Lagerbolzen",
			en: "Bearing bolt"
		}
		,A2V00002796572 : {
			de: "Lagerbolzen",
			en: "Bearing bolt"
		}
		,A2V00002796573 : {
			de: "Abstandshuelse Kuppelstange",
			en: "Spacer coupling rod"
		}
		,A2V00002398947 : {
			de: "Horn komplett",
			en: "Horn complete"
		}
		,A2V00002399960 : {
			de: "Lagerhuelse T5",
			en: "Bearing sleeve p5"
		}
		,A2V00002796689 : {
			de: "Lagerhuelse",
			en: "Bearing bushing"
		}
		,A2V00002796798 : {
			de: "Halter geschw.",
			en: "Holder welded"
		}
		,A2V00002796828 : {
			de: "Holm",
			en: "Beam"
		}
		,A2V00002796878 : {
			de: "Parallelfuehrungsstange SWT/RAL7021",
			en: "Collector head guide wp/RAL7021"
		}
		,A2V00002797008 : {
			de: "C - Traeger WG",
			en: "C - beam AD"
		}
		,A2V00002797009 : {
			de: "C - Traeger SPG",
			en: "C - beam OAD"
		}
		,A2V00002797060 : {
			de: "Pneumatikzylinder",
			en: "Pneumatic cylinder"
		}
		,A2V00002797188 : {
			de: "Tragkonsole",
			en: "Support bracket"
		}
		,A2V00002797252 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002797255 : {
			de: "Stromanschlusslasche",
			en: "Current connection plate"
		}
		,A2V00002797283 : {
			de: "Abdeckung",
			en: "Cover"
		}
		,A2V00002797284 : {
			de: "Gewindestange",
			en: "Thread rod"
		}
		,A2V00002797581 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002797790 : {
			de: "Mulde Bodenplatte sp.g.",
			en: "Slot floor plate i.m.i."
		}
		,A2V00002797866 : {
			de: "Stuetzlasche",
			en: "Support plate"
		}
		,A2V00002797867 : {
			de: "Lasche fuer Strukturdaempfer",
			en: "Link for for profile damper"
		}
		,A2V00002797870 : {
			de: "Anbaulasche fuer Ventilkasten",
			en: "Support plate for valve box"
		}
		,A2V00397280916 : {
			de: "PLATTE ANHEBESTELLE",
			en: "PLATE FOR LIFTINGPOINT"
		}
		,A2V00397281809 : {
			de: "Halfen Montageschiene HM28/15-A2-650",
			en: "Halfen-mounting rail HM28/15-A2-650"
		}
		,A2V00002798027 : {
			de: "Distanzstueck H58",
			en: "Spacer H58"
		}
		,A2V00002798028 : {
			de: "Distanzstueck H23,5",
			en: "Spacer H23,5"
		}
		,A2V00002782971 : {
			de: "Abdeckblech",
			en: "cover plate"
		}
		,A2V00002798345 : {
			de: "Maskenblech",
			en: "Mask plate"
		}
		,A2V00002798760 : {
			de: "Rippe sp.g.",
			en: "Rib i.m.i."
		}
		,A2V00002798891 : {
			de: "Erdungslasche M10",
			en: "Grounding connection M10"
		}
		,A2V00002799692 : {
			de: "Schweissbadsicherung w.g.",
			en: "Weld backing a.d."
		}
		,A2V00002799693 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002799694 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002799695 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002799736 : {
			de: "Schweissbadsicherung sp.g.",
			en: "Weld backing i.m.i"
		}
		,A2V00002799737 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002799738 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002801285 : {
			de: "Befestigungskonsole",
			en: "Mounting bracket"
		}
		,A2V00002801456 : {
			de: "Gewindestueck",
			en: "Thread piece"
		}
		,A2V00002801457 : {
			de: "Lagerkopf",
			en: "Bearing head"
		}
		,A2V00002801461 : {
			de: "Lagerhuelse",
			en: "Bearing sleeve"
		}
		,A2V00002801511 : {
			de: "Distanzhuelse",
			en: "Spacer tube"
		}
		,A2V00002803859 : {
			de: "Beilage",
			en: "Spacer"
		}
		,A2V00002804987 : {
			de: "Maske Verbinder sp.g.",
			en: "Cab connection i.m.i."
		}
		,A2V00002805002 : {
			de: "Maske Verbinder w.g.",
			en: "Cab connection a.d."
		}
		,A2V00002805029 : {
			de: "Maske C",
			en: "Cab C"
		}
		,A2V00002805036 : {
			de: "Lagerbolzen",
			en: "Bearing bolt"
		}
		,A2V00002805379 : {
			de: "Kurvenscheibe Geometrie M",
			en: "Cam disc geometry M"
		}
		,A2V00002805588 : {
			de: "Rahmen Spaltueberbr. 1",
			en: "Frame gap-bridging 1"
		}
		,A2V00002805654 : {
			de: "Schweissbadsicherung sp.g.",
			en: "Weld backing i.m.i."
		}
		,A2V00002805655 : {
			de: "Schweissbadsicherung w.g.",
			en: "Weld backing a.d."
		}
		,A2V00002805752 : {
			de: "Schweissbadsicherung sp.g.",
			en: "Weld backing i.m.i."
		}
		,A2V00002805753 : {
			de: "Schweissbadsicherung w.g.",
			en: "Weld backing a.d."
		}
		,A2V00002806106 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002806138 : {
			de: "Lampenbox Deckel sp.g.",
			en: "Lightbox lid i.m.i."
		}
		,A2V00002807048 : {
			de: "Kurvenscheibe Geometrie M",
			en: "Cam disc geometry M"
		}
		,A2V00002448765 : {
			de: "Tuerfluegel, 1-flg., rechts",
			en: "Door panel, 1 leaf, right"
		}
		,A2V00002448796 : {
			de: "Tuerfluegel, 1-flg., links",
			en: "Door panel, 1 leaf, left"
		}
		,A2V00002558966 : {
			de: "Motoraufhaengung komplett",
			en: "Motor mounting complete"
		}
		,A2V00002787641 : {
			de: "Langtraeger vollst.",
			en: "longitudinal girder compl"
		}
		,A2V00002787989 : {
			de: "Langtraeger vollst. Untergestell EW",
			en: "longitudinal girder compl. underframe ec"
		}
		,A2V00002788197 : {
			de: "Liefergarnitur Bodenplatten EC08",
			en: "Delivering set Floor plate EC08"
		}
		,A2V00002788213 : {
			de: "Liefergarnitur Bodenplatten EC01",
			en: "Delivering set Floor plate EC01"
		}
		,A2V00002788214 : {
			de: "Liefergarnitur Bodenplatten CC06",
			en: "Delivering set Floor plate CC06"
		}
		,A2V00002788215 : {
			de: "Liefergarnitur Bodenplatten MC04",
			en: "Delivering set Floor Plate MC04"
		}
		,A2V00002788226 : {
			de: "Liefergarnitur Bodenplatten CC03",
			en: "Delivering set Floor plate CC03"
		}
		,A2V00002788227 : {
			de: "Liefergarnitur Bodenplatten TC02",
			en: "Delivering set Floor plate TC02"
		}
		,A2V00002788229 : {
			de: "Liefergarnitur Seitendecke EC",
			en: "Delivering Set Upper Side Panel EC"
		}
		,A2V00002788230 : {
			de: "Liefergarnitur Seitendecke",
			en: "Delivering Set Upper Side Panel"
		}
		,A2V00002788231 : {
			de: "Liefergarnitur Seitendecke MC04",
			en: "Delivering Set Upper Side Panel MC04"
		}
		,A2V00002788232 : {
			de: "Liefergarnitur Seitendecke CC03",
			en: "Delivering Set Upper Side Panel CC03"
		}
		,A2V00002788233 : {
			de: "Liefergarnitur Seitendecke TC02",
			en: "Delivering Set Upper Side Panel TC02"
		}
		,A2V00002788234 : {
			de: "Liefergarnitur Seitenwandverkldg. EC08",
			en: "Delivering Set Side Wall Panelling EC08"
		}
		,A2V00002788235 : {
			de: "Liefergarnitur Seitenwandverkldg.",
			en: "Delivering Set Side Wall Panelling"
		}
		,A2V00002788236 : {
			de: "Liefergarnitur Seitenwandverkldg. CC06",
			en: "Delivering Set Side Wall Panelling CC06"
		}
		,A2V00002788237 : {
			de: "Liefergarnitur Seitenwandverkldg. MC04",
			en: "Delivering Set Side Wall Panelling MC04"
		}
		,A2V00002788238 : {
			de: "Liefergarnitur Seitenwandverkldg. CC03",
			en: "Delivering Set Side Wall Panelling CC03"
		}
		,A2V00002788239 : {
			de: "Liefergarnitur Seitenwandverkldg. TC02",
			en: "Delivering Set Side Wall Panelling TC02"
		}
		,A2V00002788240 : {
			de: "Liefergarnitur Seitenwandverkldg. EC01",
			en: "Delivering Set Side Wall Panelling EC01"
		}
		,A2V00002788254 : {
			de: "Liefergarnitur Lochdecken EC",
			en: "Delivering Set Perforated Ceiling EC"
		}
		,A2V00002788255 : {
			de: "Liefergarnitur Lochdecken CC06/TC07",
			en: "Delivering Set Perforated Ceiling 06/07"
		}
		,A2V00002788256 : {
			de: "Liefergarnitur Lochdecken MC05",
			en: "Delivering Set Perforated Ceiling MC05"
		}
		,A2V00002788257 : {
			de: "Liefergarnitur Lochdecken MC04",
			en: "Delivering Set Perforated Ceiling MC04"
		}
		,A2V00002788258 : {
			de: "Liefergarnitur Lochdecken CC03",
			en: "Delivering Set Perforated Ceiling CC03"
		}
		,A2V00002788259 : {
			de: "Liefergarnitur Lochdecken TC02",
			en: "Delivering Set Perforated Ceiling TC02"
		}
		,A2V00002788650 : {
			de: "Seitenwandmodul F-Ende rechts",
			en: "sidewall frontend right"
		}
		,A2V00002789080 : {
			de: "Probestueck, Klebeschottwand",
			en: "testing body, partition underframe"
		}
		,A2V00002789290 : {
			de: "KK-Ende vollst.",
			en: "short coupler end compl."
		}
		,A2V00001949712 : {
			de: "Schlauchleitung 2TE",
			en: "Hose Pipe 2TE"
		}
		,A2V00001949711 : {
			de: "Schlauchleitung 2TE",
			en: "Hose Pipe 2TE"
		}
		,A2V00002790357 : {
			de: "KK-Ende vollst. 2",
			en: "SC end compl. 2"
		}
		,A2V00002790710 : {
			de: "Langtraeger bearb.",
			en: "longitudinal girder mach."
		}
		,A2V00002790737 : {
			de: "Langtraeger bearb.",
			en: "longitudinal girder mach."
		}
		,A2V00002794047 : {
			de: "Rohrdurchfuehrung WC",
			en: "pipe lead through WC"
		}
		,A2V00002794166 : {
			de: "Rohrdurchfuehrung",
			en: "pipe feedthrough"
		}
		,A2V00002794354 : {
			de: "Dachschottblech w.g. , vollst.",
			en: "Roof plate as d. , complete"
		}
		,A2V00002794355 : {
			de: "Dachschottblech sp.g., vollst.",
			en: "Partition plate roof r.i. , complete"
		}
		,A2V00002794604 : {
			de: "Flachdach vollst. WE1",
			en: "Flat roof comlete CE1"
		}
		,A2V00002794832 : {
			de: "Flachdach vollst. WE2",
			en: "Flat roof comlete CE2"
		}
		,A2V00002795173 : {
			de: "F-Ende vollstaendig",
			en: "front end complete"
		}
		,A2V00002795441 : {
			de: "Gewindeplatte M12",
			en: "thread plate M12"
		}
		,A2V00002797025 : {
			de: "Bodenwinkel",
			en: "Floor cover"
		}
		,A2V00002797491 : {
			de: "Haupttrafo mit Kuehlanl. + Mon.",
			en: "Maintransform. with. mount"
		}
		,A2V00002797590 : {
			de: "KK-Ende vollst.",
			en: "short coupler end compl."
		}
		,A2V00002797762 : {
			de: "Kupplungsplatte WE2",
			en: "Couplerplate CE2"
		}
		,A2V00002797763 : {
			de: "Kupplungsplatte WE 1",
			en: "Couplerplate CE 1"
		}
		,A2V00002798094 : {
			de: "Liefergarnitur Seitenfenster",
			en: "Delivering set Side windows"
		}
		,A2V00002798326 : {
			de: "Liefergarnitur Seitenfenster",
			en: "Delivering set Side windows"
		}
		,A2V00002798327 : {
			de: "Liefergarnitur Seitenfenster",
			en: "Delivering set Side windows"
		}
		,A2V00002621240 : {
			de: "Bedientafel_X4E1131_V03_A22 E+M",
			en: "Control panel X4E1131_V03_A22 E+M"
		}
		,A2V00002673728 : {
			de: "Radbremszange mit Federspeicher rechts",
			en: "WM brake calliper w. parking brake right"
		}
		,A2V00002619882 : {
			de: "Wellenbalg, vollst.",
			en: "Corrugated bellows, assy"
		}
		,A2V00002800442 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002801413 : {
			de: "Konsole vollst. Automat",
			en: "console complete automat"
		}
		,A2V00002801453 : {
			de: "Konsole vollst. Automat",
			en: "console complete automat"
		}
		,A2V00002801592 : {
			de: "Langtraeger vollst.",
			en: "longitudinal girder compl"
		}
		,A2V00002801626 : {
			de: "Langtraeger vollst. Untergestell EW",
			en: "longitudinal girder compl. underframe ec"
		}
		,A2V00002803740 : {
			de: "Seitenwandmodul F-Ende links",
			en: "sidewall frontend left"
		}
		,A2V00002803960 : {
			de: "Seitenwandmodul F-Ende rechts",
			en: "sidewall frontend right"
		}
		,A2V00002804264 : {
			de: "Liefergarnitur Tuer",
			en: "Delivering set Door"
		}
		,A2V00002806345 : {
			de: "Seitenwandmodul Mitte li",
			en: "sidewall middle left"
		}
		,A2V00002806749 : {
			de: "Seitenwandmodul Mitte re",
			en: "sidewall middle ri"
		}
		,A2V00002807952 : {
			de: "Seitenwand 1, NF vollst",
			en: "sidewall 1, low-fl. complete"
		}
		,A2V00002807980 : {
			de: "Seitenwand 2, NF vollst",
			en: "sidewall 2, low-fl. complete"
		}
		,A2V00002809862 : {
			de: "Seitenwandmodul mitte links vollst.",
			en: "Sidewall middle left compl."
		}
		,A2V00002810350 : {
			de: "Seitenwandmodul mitte rechts vollst.",
			en: "Sidewall middle right compl."
		}
		,A2V00002690663 : {
			de: "Hilfsbetriebeumrichter",
			en: "Auxiliary inverter"
		}
		,A2V00002613948 : {
			de: "SekundÃ¤rfeder EDG",
			en: "Secondary spring EBG"
		}
		,A2V00002633728 : {
			de: "Radbremszange links",
			en: "WM brake calliper left"
		}
		,A2V00002278756 : {
			de: "Einstiegstuer re.",
			en: "entrance door ri."
		}
		,A2V00002505386 : {
			de: "Einstiegstuer re. RAL9006/PE-Schliessung",
			en: "entrance door ri. RAL9006/PE-closure"
		}
		,A2V00002279781 : {
			de: "Einstiegstuer li.",
			en: "entrance door le."
		}
		,A2V00102070600 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001916433 : {
			de: "Polrad",
			en: "pulse wheel"
		}
		,A2V00001821291 : {
			de: "Adapter R3        vollstaendig",
			en: "adapter R3        complete"
		}
		,A2V00001821290 : {
			de: "Adapter L3        vollstaendig",
			en: "adapter L3        complete"
		}
		,A2V00002399405 : {
			de: "Grundrahmen SBS kompl. RAL3000",
			en: "Base frame SBS compl. RAL3000"
		}
		,A2V00110164150 : {
			de: "Nutenstein  M8",
			en: "Groove stone M8"
		}
		,A2V00112402563 : {
			de: "NUTENSCHRAUBE M8X35",
			en: "GROOVE SCREW       M8X35"
		}
		,A2V00002400648 : {
			de: "Schleifstueckbefestigung",
			en: "Collector strip carrier"
		}
		,A2V00001896003 : {
			de: "Gehaeuse WSB",
			en: "Housing ECB"
		}
		,A2V00002466701 : {
			de: "Triebdrehgestell 1, vollst.",
			en: "Motor bogie 1, complete"
		}
		,A2V00002466702 : {
			de: "Triebdrehgestell 2, vollst.",
			en: "Motor bogie 2, complete"
		}
		,A2V00002466703 : {
			de: "Laufdrehgestell , vollst.",
			en: "Trailer bogie , complete"
		}
		,A2V00002400079 : {
			de: "Grundrahmen, SSS/A1/RAL3000 kompl.",
			en: "Base frame, SSS/A1/RAL3000 compl."
		}
		,A2V00113021370 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00110149380 : {
			de: "SCHUTZGAS-SCHWEISSDRAHT                .",
			en: "SCHUTZGAS-SCHWEISSDRAHT                ."
		}
		,A2V00002559669 : {
			de: "Bereiftes Rad, vollst",
			en: "Tired Wheel, complete"
		}
		,A2V00002566237 : {
			de: "Hilfsstromabnehmer pneumatisch",
			en: "Auxiliary pantograph pneumatical"
		}
		,A2V00002583324 : {
			de: "Radsatzlagergehaeuse lackiert",
			en: "Axle box housing painted"
		}
		,A2V00002553559 : {
			de: "Treibradsatzwelle, lackiert",
			en: "Motor wheelset axle, painted"
		}
		,A2V00002446496 : {
			de: "RSL-Deckel",
			en: "AB-Cover"
		}
		,A2V00002446497 : {
			de: "RSL-Deckel fÃ¼r EK und GS rh",
			en: "AB-Cover for GD and SP rh"
		}
		,A2V00002446499 : {
			de: "RSL-Deckel fÃ¼r GLVM rh",
			en: "AB-Cover for GLVM rh"
		}
		,A2V00002446500 : {
			de: "RSL-Deckel fÃ¼r GLVM lh",
			en: "AB-Cover for GLVM lh"
		}
		,A2V00002627332 : {
			de: "Knochenbuchse",
			en: "Bush"
		}
		,A2V00002698304 : {
			de: "Laengspuffer",
			en: "Longitudinal buffer"
		}
		,A2V00002171960 : {
			de: "RSL-Deckel vorne rh lackiert",
			en: "Axle box front cover rh painted"
		}
		,A2V00002613949 : {
			de: "SekundÃ¤rfeder JDG",
			en: "Secondary spring JBG"
		}
		,A2V00002674182 : {
			de: "Radbremszange mit Federspeicher links",
			en: "WM brake calliper w. parking brake left"
		}
		,A2V00002674183 : {
			de: "Radbremszange rechts",
			en: "WM brake calliper right"
		}
		,A2V00002675599 : {
			de: "Konusfeder",
			en: "Conical spring"
		}
		,A2V00002695835 : {
			de: "Zug/Druck-Stange JDG",
			en: "push/pull rod JB"
		}
		,A2V00002695877 : {
			de: "Zug/Druck-Stange ETDG",
			en: "push/pull rod EMB"
		}
		,A2V00002698754 : {
			de: "Daempfer primaer vertikal",
			en: "primary vertical shock absorber"
		}
		,A2V00002704957 : {
			de: "Querpuffer",
			en: "Lateral buffer"
		}
		,A2V00001421076 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00001778135 : {
			de: "Druckring",
			en: "Preassure ring"
		}
		,A2V00002513561 : {
			de: "Daempfer sekundaer vertikal",
			en: "Secondary vertical shock absorber"
		}
		,A2V00346500100 : {
			de: "Verduennung 200",
			en: "Thinner thinner 200"
		}
		,A2V00113006637 : {
			de: "Luftbehaelter EN 286-4",
			en: "Air reservoir EN 286-4"
		}
		,A2V00113007023 : {
			de: "Tragschiene DIN 3015-1 - L TS-11x2000-W4",
			en: "Mounting rail DIN 3015-1 - L TS-11x2000"
		}
		,A2V00358800242 : {
			de: "Verbundblock VD1692",
			en: "Composite Block VD1692"
		}
		,A2V00370005904 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370009807 : {
			de: "Deckplatte DIN 3015-L DP-0-W1",
			en: "Cover plate DIN 3015-L DP-0-W1"
		}
		,A2V00370010523 : {
			de: "FL DIN1017-50x8-S355JO",
			en: "FL DIN1017-50x8-S355JO"
		}
		,A2V00113033089 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00113034229 : {
			de: "Sicherheitsventil",
			en: "Safety valve"
		}
		,A2V00370018631 : {
			de: "HP DIN59410-120X120X6,3-ST52-3     31B",
			en: "HP DIN59410-120X120X6,3-ST52-3     31B"
		}
		,A2V00370020663 : {
			de: "FL DIN1017-50x5-S355JO",
			en: "FL DIN1017-50x5-S355JO"
		}
		,A2V00370023173 : {
			de: "RSF-GLEITLAGER",
			en: "WSG-bush bearing"
		}
		,A2V00370023998 : {
			de: "Betaetigungshebel f. Luftfedersteuerung",
			en: "Operating lever f. air spring control"
		}
		,A2V00370024264 : {
			de: "GLEITBLECH                        31B",
			en: "slide plate       31B"
		}
		,A2V00370024823 : {
			de: "SEILPAAR F.ABHEBESICHERUNG",
			en: "ROPE PAIR"
		}
		,A2V00002603684 : {
			de: "Schlauchleitung",
			en: "Hose assembly"
		}
		,A2V00002399271 : {
			de: "Sechsk M12,0 x 60 mm     70    A2",
			en: "Sechsk M12,0 x 60 mm     70    A2"
		}
		,A2V00207471091 : {
			de: "Unterflurgeruestbr. 1 RB",
			en: "underfloor rack 1 bs"
		}
		,A2V00370025366 : {
			de: "BUCHSE DIN1552-C 22x28x12-E-C10",
			en: "BUSH DIN1552-C 22x28x12-E-C10"
		}
		,A2V00113042285 : {
			de: "Luftbehaelter DIN 5580-B1-25",
			en: "Air reservoir DIN 5580-B1-25"
		}
		,A2V00370026288 : {
			de: "VERBUNDFEDER",
			en: "COMPOUNDSPRING"
		}
		,A2V00370026387 : {
			de: "4KT DIN1014-10-S235JRG2",
			en: "SQUARE DIN1014-10-S235JRG2"
		}
		,A2V00370028764 : {
			de: "Fuehrungszapfen",
			en: "Pilot"
		}
		,A2V00370030929 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370033291 : {
			de: "RO ISO1127-40x2-S-1.4541+C2/H     31B",
			en: "RO ISO1127-40x2-S-1.4541+C2/H     31B"
		}
		,A2V00370036261 : {
			de: "RO ISO1127-22x1,5-D4T3-S-1.4301+M",
			en: "TUBE ISO1127-22x1,5-D4T3-S-1.4301+M"
		}
		,A2V00370037624 : {
			de: "4KT DIN178-50-S235JRG2 K",
			en: "4KT DIN178-50-S235JRG2 K"
		}
		,A2V00370038624 : {
			de: "Stuetze",
			en: "support"
		}
		,A2V00002185141 : {
			de: "Untergurt",
			en: "Lower flange"
		}
		,A2V00002186250 : {
			de: "Steg 1",
			en: "Web 1"
		}
		,A2V00002186694 : {
			de: "Rippe 2",
			en: "Rib 2"
		}
		,A2V00002187158 : {
			de: "Blattfederbuchse",
			en: "flat spring bush"
		}
		,A2V00370046714 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370046908 : {
			de: "Decklack DS-GLIMMER RAL 7012",
			en: "top coat DS-GLIMMER RAL 7012"
		}
		,A2V00370053214 : {
			de: "RO ISO1127-28x1,5-D4T3-S-1.4301+M",
			en: "TUBE ISO1127-28x1,5-D4T3-S-1.4301+M"
		}
		,A2V00370054333 : {
			de: "ANSCHLUSZTEILE F.FLEXBALL-TYP160",
			en: "connecting parts f. FLEXBALL-type  160"
		}
		,A2V00370054351 : {
			de: "Verschraubung",
			en: "Equal tees"
		}
		,A2V00370054363 : {
			de: "Verschraubung",
			en: "Straight union"
		}
		,A2V00370054480 : {
			de: "Einschraubverschraubung",
			en: "Parallel male union"
		}
		,A2V00370054482 : {
			de: "T-Reduzierverschraubung",
			en: "Tee-reduced"
		}
		,A2V00370058278 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00370060203 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002206030 : {
			de: "Halter HK geschweisst",
			en: "holder TB welding"
		}
		,A2V00397128011 : {
			de: "BUCHSE",
			en: "sleeve"
		}
		,A2V00370058028 : {
			de: "Daempfer sekundaer horizontal",
			en: "secondary horizontal damper"
		}
		,A2V00002404808 : {
			de: "Treibradsatzwelle, lackiert",
			en: "Motor wheelset axle, lacquered"
		}
		,A2V00002381124 : {
			de: "Zugstange",
			en: "drawbar"
		}
		,A2V00002381211 : {
			de: "Oberschere",
			en: "upper support"
		}
		,A2V00002036402 : {
			de: "EVB 200 kVA",
			en: "ACU 200 kVA"
		}
		,A2V00001252149 : {
			de: "Adapter",
			en: "adaptor"
		}
		,A2V00112500657 : {
			de: "Nutenstein M10",
			en: "Nutenstein M10"
		}
		,A2V00001988480 : {
			de: "Bugkupplungsabdeckung vollst.",
			en: "Front coupler cover cpl."
		}
		,A2V00002305838 : {
			de: "Frontklappe CargS vst.",
			en: "front flap CargS cpl."
		}
		,A2V00002410380 : {
			de: "Batterie NiCd 420 Ah",
			en: "Battery NiCd 420 Ah"
		}
		,A2V00156019062 : {
			de: "Hochspannungs-    umrichter 80kVA",
			en: "high voltage      inverter 80kVA"
		}
		,A2V00205394116 : {
			de: "Impulsg.Kmg-1,4H",
			en: "Impulsg.Kmg-1,4H"
		}
		,A2V00002279782 : {
			de: "Frontklappe boxXp vst.",
			en: "front flap boxXp cpl."
		}
		,A2V00001220550 : {
			de: "Kohleschleifstueck RH85 M6",
			en: "Carbon trolley shoe RH85 M6"
		}
		,A2V00370033937 : {
			de: "Blech 15x2000x4000 S355J2W+N",
			en: "Sheet 15x2000x4000 S355J2W+N"
		}
		,A2V00370050112 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001989073 : {
			de: "Blech EN 10051 2,5X1500X3000 S460MC",
			en: "Sheet EN 10051 2,5X1500X3000 S460MC"
		}
		,A2V00002539057 : {
			de: "Einstiegstuer li. RAL9016/kein Zylinder",
			en: "entrance door le. RAL9016/no cylinder"
		}
		,A2V00370073563 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00370073613 : {
			de: "Knochenbuchse",
			en: "spheribloc"
		}
		,A2V00370075672 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001189198 : {
			de: "Halbscherenstromabnehmer SBB/SNCF (AC)",
			en: "Single arm pantograph SBB/SNCF (AC)"
		}
		,A2V00002119153 : {
			de: "Treibradsatz",
			en: "Motor wheelset"
		}
		,A2V00001944236 : {
			de: "Stromabnehmer DC 3kV IT Kohle metall",
			en: "Pantograph DC 3kV IT Carbon metal"
		}
		,A2V00001815617 : {
			de: "Steighoehenbegrenzung o. Fangkonsole",
			en: "Limit. of raising height wo. catch cons."
		}
		,A2V00001168867 : {
			de: "Ventilkasten",
			en: "Valve box"
		}
		,A2V00397208866 : {
			de: "Spritzschutz",
			en: "Splash Guard"
		}
		,A2V00370058026 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical damper"
		}
		,A2V00001424509 : {
			de: "Bremskupplung 1 inch x G1 1/4",
			en: "Brake-hose coupling 1 inch x G1 1/4"
		}
		,A2V00001424510 : {
			de: "Bremskupplung H-1 inch x G1 1/4",
			en: "Brake-hose coupling H-1 inch x G1 1/4"
		}
		,A2V00001865735 : {
			de: "Schleifleiste",
			en: "grinding bar"
		}
		,A2V00201352111 : {
			de: "Systemwahlschalter C310363",
			en: "System change over switch C310363"
		}
		,A2V00207351957 : {
			de: "Schuetz LTHSE900-1pol  DC 110V",
			en: "Contactor LTHSE900-1pole  DC 110V"
		}
		,A2V00001695673 : {
			de: "Stegblech eben SP.G.",
			en: "web plate flat o.a.d."
		}
		,A2V00001695736 : {
			de: "Kopftraegergrundplatte",
			en: "baseplate"
		}
		,A2V00001695737 : {
			de: "Kopftraegerrohr",
			en: "pipe for head beam"
		}
		,A2V00001695739 : {
			de: "Hauptblech SP.G.",
			en: "master plate o.a.d."
		}
		,A2V00001695741 : {
			de: "Hohlprofil",
			en: "hollow section"
		}
		,A2V00001695742 : {
			de: "Hauptblech W.G.",
			en: "master plate a.d."
		}
		,A2V00001695743 : {
			de: "Versteifungsrippe",
			en: "strengthening rib"
		}
		,A2V00001695745 : {
			de: "Stegblech SP.G.",
			en: "web plate o.a.d."
		}
		,A2V00001695746 : {
			de: "Stegblech W.G.",
			en: "web plate a.d."
		}
		,A2V00397155680 : {
			de: "Rippe",
			en: "rip"
		}
		,A2V00397155681 : {
			de: "Anschlussplatte",
			en: "connecting plate"
		}
		,A2V00397155682 : {
			de: "Einstellplatte",
			en: "adjusting plate"
		}
		,A2V00397165361 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00370078989 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002327194 : {
			de: "Huelse 16",
			en: "bush  16"
		}
		,A2V00002323272 : {
			de: "Huelse 27",
			en: "bush  27"
		}
		,A2V00001695674 : {
			de: "Stegblech eben W.G.",
			en: "web plate flat a.d."
		}
		,A2V00370082056 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370082193 : {
			de: "Reduzier-Steckverschraubung",
			en: "Reducing union adaptor"
		}
		,A2V00370084253 : {
			de: "DISTANZSTUECK",
			en: "DISTANCE PIECE"
		}
		,A2V00379990753 : {
			de: "Schweissdraht 1,6 S Al 5087",
			en: "Welding wire 1,6 S Al 5087"
		}
		,A2V00384203522 : {
			de: "Drahtelektrode 1 BOEHLER NiCu 1-IG",
			en: "Wire electrode 1 BOEHLER NiCu 1-IG"
		}
		,A2V00384204129 : {
			de: "Drahtelektrode 1 BOEHLER CN 23/12-IG",
			en: "Wire rod 1 BOEHLER CN 23/12-IG"
		}
		,A2V00200316124 : {
			de: "Blech EN 10029 12X1500X4000 S355J2C+N",
			en: "Sheet EN 10029 12X1500X4000 S355J2C+N"
		}
		,A2V00397076032 : {
			de: "Verschlussschraube",
			en: "Locking screw"
		}
		,A2V00397119939 : {
			de: "Stab FL 25X8-35E EN10058 S355JO",
			en: "bar FL 25X8-35E EN10058 S355JO"
		}
		,A2V00397121884 : {
			de: "Scheibe",
			en: "Washer"
		}
		,A2V00002167998 : {
			de: "Blech 100X2000X2000 S355J2+N+Z25+100%UT",
			en: "Sheet 100X2000X2000 S355J2+N+Z25+100%UT"
		}
		,A2V00002238733 : {
			de: "Blech 85X2000X2000 S355J2+N+Z25+100%UT",
			en: "Sheet 85X2000X2000 S355J2+N+Z25+100%UT"
		}
		,A2V00002167179 : {
			de: "Blech 70X2000X4000 S355J2+N+Z25+100%UT",
			en: "Sheet 70X2000X4000 S355J2+N+Z25+100%UT"
		}
		,A2V00002167178 : {
			de: "Blech 50X2000X4000 S355J2+N+Z25+100%UT",
			en: "Sheet 50X2000X4000 S355J2+N+Z25+100%UT"
		}
		,A2V00002238287 : {
			de: "Blech 75X2000X4000 S355J2+N+Z25+100%UT",
			en: "Sheet 75X2000X4000 S355J2+N+Z25+100%UT"
		}
		,A2V00002348769 : {
			de: "Kit Radbremsscheibe R1085R805LGUP",
			en: "Kit wheelmounted brake disk R1085R805LUP"
		}
		,A2V00397122915 : {
			de: "FUEHRUNGSBOLZEN",
			en: "guide bolt"
		}
		,A2V00397124060 : {
			de: "BUCHSE",
			en: "bush"
		}
		,A2V00397124850 : {
			de: "FEDERBEILAGE",
			en: "spring shim"
		}
		,A2V00397130201 : {
			de: "Erdungslasche",
			en: "Earthing plate"
		}
		,A2V00397132005 : {
			de: "Erdungslasche",
			en: "Grounding Plate"
		}
		,A2V00397133372 : {
			de: "SCHEIBE",
			en: "shim plate"
		}
		,A2V00002156214 : {
			de: "Magnetschienenbremse",
			en: "Magnetic track brake"
		}
		,A2V00001403456 : {
			de: "Kegelrollenlager (f. PA 103292)",
			en: "Taper roller bearing (f. PA 103292)"
		}
		,A2V00002050645 : {
			de: "Blech 90X2000X4000 S355J2+N+Z25+100%UT",
			en: "Sheet 90X2000X4000 S355J2+N+Z25+100%UT"
		}
		,A2V00397134417 : {
			de: "Querrohr bearb.",
			en: "Cross tube machined"
		}
		,A2V00397138290 : {
			de: "Konsole fuer Wankstabilisator WG",
			en: "Bracket for anti roll bar AD"
		}
		,A2V00397138291 : {
			de: "Konsole fuer Wankstabilisator SPG",
			en: "Bracket for anti roll bar OAD"
		}
		,A2V00397149971 : {
			de: "Knotenstueck, mech. bearbeitet  SPG",
			en: "Node part, machined OAD"
		}
		,A2V00397157042 : {
			de: "DECKEL",
			en: "PLATE"
		}
		,A2V00002235751 : {
			de: "Blech 110X2000X2500 S355J2+N+Z25+100%UT",
			en: "Sheet 110X2000X2500 S355J2+N+Z25+100%UT"
		}
		,A2V00002235754 : {
			de: "Blech 140X1000X2000 S355J2+N+Z25+100%UT",
			en: "Sheet 140X1000X2000 S355J2+N+Z25+100%UT"
		}
		,A2V00002235747 : {
			de: "Blech 55X2000X4000 S355J2+N+Z25+100%UT",
			en: "Sheet 55X2000X4000 S355J2+N+Z25+100%UT"
		}
		,A2V00002235752 : {
			de: "Blech 120X1100X2000 S355J2+N+Z25+100%UT",
			en: "Sheet 120X1100X2000 S355J2+N+Z25+100%UT"
		}
		,A2V00002357370 : {
			de: "Puffer",
			en: "buffer"
		}
		,A2V00002234339 : {
			de: "Blech 30X2000X4000 S355J2C+N",
			en: "Sheet 30X2000X4000 S355J2C+N"
		}
		,A2V00002235750 : {
			de: "Blech 80X2000X2000 S355J2+N+Z25+100%UT",
			en: "Sheet 80X2000X2000 S355J2+N+Z25+100%UT"
		}
		,A2V00002156624 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002382346 : {
			de: "Maschinenraumventilator V3",
			en: "machine room ventilator V3"
		}
		,A2V00002233267 : {
			de: "Schlauchleitung",
			en: "Hose assembly"
		}
		,A2V00207356241 : {
			de: "Kugelhahn",
			en: "Ball cock"
		}
		,A2V00397159193 : {
			de: "SCHEIBE",
			en: "WASHER"
		}
		,A2V00397167829 : {
			de: "Radsatzanlenkung",
			en: "Wheelset guiding"
		}
		,A2V00397169228 : {
			de: "GERAETETRAEGER,VOLLST.",
			en: "GERAETETRAEGER,VOLLST."
		}
		,A2V00397174294 : {
			de: "DECKEL,VORDERER F.RADSATZLAGER E",
			en: "cover, front f. wheel set bearing E"
		}
		,A2V00397174295 : {
			de: "DECKEL,VORDERER F.RADSATZLAGER BSG",
			en: "cover, front f. wheel set bearing BSG"
		}
		,A2V00002223587 : {
			de: "Blech 15X2000X4000 S355J2C+N",
			en: "Sheet 15X2000X4000 S355J2C+N"
		}
		,A2V00002216619 : {
			de: "Blech 20X2000X4000 S355NL+N",
			en: "Sheet 20X2000X4000 S355NL+N"
		}
		,A2V00002216620 : {
			de: "Blech 16X2000X4000 S355NL+N",
			en: "Sheet 16X2000X4000 S355NL+N"
		}
		,A2V00002216621 : {
			de: "Blech 14X2000X4000 S355NL+N",
			en: "Sheet 14X2000X4000 S355NL+N"
		}
		,A2V00002216624 : {
			de: "Blech 8X2000X4000 S355NL+N",
			en: "Sheet 8X2000X4000 S355NL+N"
		}
		,A2V00002216636 : {
			de: "Blech 4X1500X3000 S355NL+N",
			en: "Sheet 4X1500X3000 S355NL+N"
		}
		,A2V00002223405 : {
			de: "Blech 18X2000X4000 S355J2C+N",
			en: "Sheet 18X2000X4000 S355J2C+N"
		}
		,A2V00002223589 : {
			de: "Blech 14X2000X4000 S355J2C+N",
			en: "Sheet 14X2000X4000 S355J2C+N"
		}
		,A2V00002223595 : {
			de: "Blech 8X2000X4000 S355J2C+N",
			en: "Sheet 8X2000X4000 S355J2C+N"
		}
		,A2V00002223586 : {
			de: "Blech 16X2000X4000 S355J2C+N",
			en: "Sheet 16X2000X4000 S355J2C+N"
		}
		,A2V00002223592 : {
			de: "Blech 12X2000X4000 S355J2C+N",
			en: "Sheet 12X2000X4000 S355J2C+N"
		}
		,A2V00002331146 : {
			de: "Blech 14X2000X6000 S355J2C+N",
			en: "Sheet 14X2000X6000 S355J2C+N"
		}
		,A2V00002230840 : {
			de: "Sechskant-Passschraube",
			en: "Hexagon fit bolt"
		}
		,A2V00002223608 : {
			de: "Blech 5X2000X4000 S355J2C+N",
			en: "Sheet 5X2000X4000 S355J2C+N"
		}
		,A2V00002223594 : {
			de: "Blech 10X2000X4000 S355J2C+N",
			en: "Sheet 10X2000X4000 S355J2C+N"
		}
		,A2V00002223607 : {
			de: "Blech 6X2000X4000 S355J2C+N",
			en: "Sheet 6X2000X4000 S355J2C+N"
		}
		,A2V00001695358 : {
			de: "Zylinderschraube",
			en: "hexagon socket head cap screwÂ "
		}
		,A2V00397189527 : {
			de: "HALTER",
			en: "Holder"
		}
		,A2V00002108402 : {
			de: "Schlauchleitung 2TE 16 NN-520 P",
			en: "Hose assembly 2TE 16 NN-520 P"
		}
		,A2V00002098680 : {
			de: "Schlauchleitung 2TE 10 NN90-560 P",
			en: "Hose assembly 2TE 10 NN90-560 P"
		}
		,A2V00397189556 : {
			de: "Befestigungsbuegel",
			en: "bracket"
		}
		,A2V00397189983 : {
			de: "Winkel",
			en: "angle"
		}
		,A2V00397189984 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397191067 : {
			de: "Unterlegscheibe",
			en: "Washer"
		}
		,A2V00397204841 : {
			de: "Erdungsanschluss M10",
			en: "Ground connection M10"
		}
		,A2V00370036722 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002718300 : {
			de: "Konusfeder SF1000",
			en: "Conical Spring SF1000"
		}
		,A2V00002106041 : {
			de: "Sechskantmutter ISO 8673 M24 10",
			en: "Hexagon nut ISO 8673 M24 10"
		}
		,A2V00002346228 : {
			de: "Schraubenisolierung",
			en: "Screw isolation"
		}
		,A2V00002470123 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002470125 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002580786 : {
			de: "Augenschraube",
			en: "Eye bolt"
		}
		,A2V00002594960 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00370071909 : {
			de: "Haerter 58 EP W",
			en: "Hardener 58 EP W"
		}
		,A2V00397264051 : {
			de: "Anschlag, verzinkt",
			en: "Stopper, zinc plated"
		}
		,A2V00002610292 : {
			de: "Puffer KTL links",
			en: "buffer cathodic coating left"
		}
		,A2V00001905889 : {
			de: "Schild Wartungsintervalle",
			en: "label service intervals"
		}
		,A2V00397274132 : {
			de: "GEWINDEBUTZEN ND05-0007-M8X30X20",
			en: "THREADED SLUG ND05-0007-M8X30X200"
		}
		,A2V00397285446 : {
			de: "BEILAGE 1 MM F. QUERPUFFER",
			en: "SHIM 1 MM F. LATERAL BUFFER"
		}
		,A2V00001195096 : {
			de: "Wiegenende",
			en: "Bolster end"
		}
		,A2V00370071097 : {
			de: "IS BS Kegelrollenlager TBU 180x195 isol.",
			en: "IS BS Taper roller bear.TBU 180x195 ins."
		}
		,A2V00112549013 : {
			de: "Modul-Ausgleichs- Behaelter",
			en: "modul-adjustment- case"
		}
		,A2V00397119391 : {
			de: "Primaerfederauflage",
			en: "Primary spring support"
		}
		,A2V00110163560 : {
			de: "VERBINDUNGSPROFIL  IM BODEN",
			en: "connecting profile in ground"
		}
		,A2V00001948368 : {
			de: "Antennengehaeuse",
			en: "Antenna housing"
		}
		,A2V00201334591 : {
			de: "Kondensatanlage",
			en: "Kondensatanlage"
		}
		,A2V00201334746 : {
			de: "Schraube m.Kerbz.",
			en: "T-HEAD BOLTS W NIBS"
		}
		,A2V00201178979 : {
			de: "Sandrohrhalter li.",
			en: "Sandrohrhalter li."
		}
		,A2V00001433411 : {
			de: "Kegelrollenlagereinheit",
			en: "taper bearing unit"
		}
		,A2V00397215083 : {
			de: "Unterlage",
			en: "Support"
		}
		,A2V00002804818 : {
			de: "Zugentlastung, oben Ersatzteil",
			en: "Strain relief, bottom Spare Part"
		}
		,A2V00002804817 : {
			de: "Zugentlastung, unten, Ersatzteil",
			en: "Strain relief, above Spare Part"
		}
		,A2V00311100019 : {
			de: "STAB RD 20H9-3000 S235JRG2C+C EN10278",
			en: "BAR RO 20H9-3000 S235JRG2C+C EN10278"
		}
		,A2V00312500143 : {
			de: "Stab LR 40X40X4-6000 EN10056-1-2 S355J0",
			en: "BAR LR 40X40X4-6000 EN10056-1-2 S355J0"
		}
		,A2V00312500154 : {
			de: "L EN10056-1-60x40x5-S355JO",
			en: "L EN10056-1-60x40x5-S355JO"
		}
		,A2V00002524847 : {
			de: "Treibrad V60 LK600",
			en: "Wheel V60 dia 600"
		}
		,A2V00370020037 : {
			de: "FL DIN1017-50x20-S355JO",
			en: "FL DIN1017-50x20-S355JO"
		}
		,A2V00370026209 : {
			de: "HP DIN2395-B-80x50x5-S355JO       31B",
			en: "HP DIN2395-B-80x50x5-S355JO       31B"
		}
		,A2V00115000110 : {
			de: "Puffer fuer Rollkranztraeger",
			en: "Rubber bumper"
		}
		,A2V00002519556 : {
			de: "Albertkupplung f. Traktion",
			en: "Albert coupler f. traction"
		}
		,A2V00370049191 : {
			de: "Drehstabfeder",
			en: "anti roll bar"
		}
		,A2V00370052550 : {
			de: "Schraubendruckfeder R=436 N/MM",
			en: "Helical compression spring R=436 N/MM"
		}
		,A2V00370052593 : {
			de: "Schichtfeder",
			en: "layer spring"
		}
		,A2V00370054357 : {
			de: "Verschraubung",
			en: "Connection"
		}
		,A2V00370054750 : {
			de: "Torsionszapfen w.g.",
			en: "Torsion adapter end a.d."
		}
		,A2V00002109833 : {
			de: "Hohlbuchse - Torsionsfeder",
			en: "Cylindrical bush - torsion bar"
		}
		,A2V00370054751 : {
			de: "Torsionszapfen sp.g.",
			en: "Torsion adaptor end o.a.d."
		}
		,A2V00370058171 : {
			de: "Schraubendruckfeder R=479 N/mm",
			en: "helical pressure spring R=479 N/mm"
		}
		,A2V00002128536 : {
			de: "Kompressoraggregat VV180-T",
			en: "Comressor Unit VV180-T"
		}
		,A2V00001216950 : {
			de: "Langtraeger Mitte WE1 wg",
			en: "longitudinal beam mid CE1 ad"
		}
		,A2V00370066721 : {
			de: "Lager",
			en: "Bearing"
		}
		,A2V00370067545 : {
			de: "Erdungskontakt AB407 Ausf.Links",
			en: "Grounding device AB407"
		}
		,A2V00370068836 : {
			de: "Radsatzlagerdeckel (EK)",
			en: "Front cover (GD)"
		}
		,A2V00370068837 : {
			de: "Radsatzlagerdeckel (GS, links)",
			en: "Front cover (WSP,left)"
		}
		,A2V00370068838 : {
			de: "Radsatzlagerdeckel (GS,rechts)",
			en: "Front cover (WSP,right)"
		}
		,A2V00370068839 : {
			de: "Radsatzlagerdeckel (O.BEL.)",
			en: "Front cover (CLOSED)"
		}
		,A2V00370076370 : {
			de: "Senkschraube",
			en: "Countersink screw"
		}
		,A2V00384200122 : {
			de: "SE 3,25X350 BOE FOX CN 23/12-A",
			en: "SE 3,25x350 BOE FOX CN 23/12-A"
		}
		,A2V00397166484 : {
			de: "Steg, inn. f. Quertraeger",
			en: "Web, inside f. cross beam"
		}
		,A2V00397166488 : {
			de: "Rippe f. Quertraeger",
			en: "Rib f. cross beam"
		}
		,A2V00397166489 : {
			de: "Rippe f. Quertraeger",
			en: "Rib f. cross beam"
		}
		,A2V00397166494 : {
			de: "Anschlussplatte WG",
			en: "Plate WG"
		}
		,A2V00397166495 : {
			de: "Anschlussplatte SPG",
			en: "Plate SPG"
		}
		,A2V00397167585 : {
			de: "LASCHE",
			en: "ear"
		}
		,A2V00397167586 : {
			de: "GRUNDPLATTE",
			en: "base plate"
		}
		,A2V00397167669 : {
			de: "Rippe, innen",
			en: "Rib, inside"
		}
		,A2V00397167670 : {
			de: "Rippe, Mitte",
			en: "Rib, middle"
		}
		,A2V00397167671 : {
			de: "Rippe, aussen",
			en: "Rib, outside"
		}
		,A2V00397167673 : {
			de: "Rippe f. Bremskonsole",
			en: "Rib f. brake bracket"
		}
		,A2V00397168157 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397168500 : {
			de: "Steg WG f. Quertraeger",
			en: "Web AD f. cross beam"
		}
		,A2V00397168501 : {
			de: "Steg SPG f. Quertraeger",
			en: "Web SPG f. cross beam"
		}
		,A2V00397168505 : {
			de: "Unterteil WG f. Halbschale",
			en: "Lower part AD f. half shell"
		}
		,A2V00397168506 : {
			de: "Unterteil SPG f. Halbschale",
			en: "Lower part OAD f. half shell"
		}
		,A2V00397168507 : {
			de: "Halbschale f. Oberteil",
			en: "Half shell f. upper part"
		}
		,A2V00397168791 : {
			de: "Rippe WG f. Schlingerd.",
			en: "Rib WG f. yaw damper"
		}
		,A2V00397168792 : {
			de: "Rippe SPG f. Schlingerd.",
			en: "Rib OAD f. yaw damper"
		}
		,A2V00397170834 : {
			de: "Steg,aussen WG f. Quertraeger",
			en: "Web outside AD f. cross beam"
		}
		,A2V00397170835 : {
			de: "Steg,aussen SPG f. Quertraeger",
			en: "Web, outside OAD f. cross beam"
		}
		,A2V00397171461 : {
			de: "Deckel WG f. Bremskonsole",
			en: "Cover AD f. brake bracket"
		}
		,A2V00397171462 : {
			de: "Deckel SPG f. Bremskonsole",
			en: "Cover OAD f. brake bracket"
		}
		,A2V00397172070 : {
			de: "Erdungsanschluss",
			en: "grounding connection"
		}
		,A2V00397175369 : {
			de: "Haltebuegel",
			en: "Holder"
		}
		,A2V00397175665 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00002078197 : {
			de: "Knochenbuchse fuer Stromabnehmer",
			en: "Trunnion bush for shoegear"
		}
		,A2V00002541967 : {
			de: "Antrieb, 2.flg., vollst.",
			en: "Drive, 2 leafs, compl."
		}
		,A2V00002032495 : {
			de: "Wellenbremszange mit Federspeicher",
			en: "Brake disc calliper with parking br."
		}
		,A2V00002348093 : {
			de: "Nottaster",
			en: "Emergency push button"
		}
		,A2V00002592755 : {
			de: "Wanne komplett",
			en: "Tub complete"
		}
		,A2V00002382347 : {
			de: "Transformatorventilator V3",
			en: "transformer ventilator V3"
		}
		,A2V00397213115 : {
			de: "Querpufferkonsole",
			en: "Cross bumper bracket"
		}
		,A2V00397216210 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397220150 : {
			de: "Federtopf WG",
			en: "Suspension pot AD"
		}
		,A2V00397220156 : {
			de: "Federtopf SPG",
			en: "Suspension pot OAD"
		}
		,A2V00397220169 : {
			de: "Rippe f. Daempferkonsole",
			en: "Rib f. damper bracket"
		}
		,A2V00397220170 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397236986 : {
			de: "Bremskonsole",
			en: "Brake bracket"
		}
		,A2V00397241949 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397241951 : {
			de: "Obergurt Mittelteil",
			en: "Upper chord middle"
		}
		,A2V00397241953 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397241954 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397241955 : {
			de: "Laensgmitnahme WG",
			en: "Traction link AD"
		}
		,A2V00397241998 : {
			de: "Laengsmitnahme SPG",
			en: "Traction link OAD"
		}
		,A2V00397242068 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397242070 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397242096 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397242098 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397242099 : {
			de: "Daempferkonsole",
			en: "Bracket f. damper"
		}
		,A2V00397242278 : {
			de: "Versteifung",
			en: "Stiffener"
		}
		,A2V00397245846 : {
			de: "Scheibe f. Luftfeder",
			en: "Shim f. air spring"
		}
		,A2V00397247594 : {
			de: "Rippe WG",
			en: "Stiffener AD"
		}
		,A2V00397247595 : {
			de: "Rippe SPG",
			en: "Stiffener OAD"
		}
		,A2V00397248330 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397248822 : {
			de: "Rippe",
			en: "Web"
		}
		,A2V00397248825 : {
			de: "Rippe",
			en: "Web"
		}
		,A2V00397248845 : {
			de: "Steg, innen",
			en: "Web, inside"
		}
		,A2V00397248850 : {
			de: "Steg, aussen",
			en: "Web, outside"
		}
		,A2V00397249905 : {
			de: "Zentrierbolzen",
			en: "Center pin"
		}
		,A2V00100626945 : {
			de: "Silentblock",
			en: "SILENTBLOC"
		}
		,A2V00397267295 : {
			de: "Daempferkonsole W.G.",
			en: "damper bracket A. D."
		}
		,A2V00397267296 : {
			de: "Daempferkonsole sp.g.",
			en: "damper bracket o.a.d."
		}
		,A2V00397274381 : {
			de: "DREHZAPFEN MECH.BEARB.",
			en: "BOGIE PIVOT MACHINED"
		}
		,A2V00001852336 : {
			de: "Radsatzlagerdeckel, hinten",
			en: "Wheelset box back cover"
		}
		,A2V00002128957 : {
			de: "Lufttrockner LTZ015.2H",
			en: "air dryer LTZ015.2H"
		}
		,A2V00002101211 : {
			de: "Montagematerial f. Wellenbremse",
			en: "Mounting mat. f.wheel-mounted brake disk"
		}
		,A2V00000217205 : {
			de: "GerÃ¤tedose(6-pol) vergold. Kontakte",
			en: "Equipment box(6-pole) gold plated contac"
		}
		,A2V00002400375 : {
			de: "Daempfermont. Tieftemp. SSS; 82-2498 SP1",
			en: "Damper ass. low temp. SSS; 82-2498 SP1"
		}
		,A2V00002136876 : {
			de: "Tuerhalter, links",
			en: "Dragging arm, left"
		}
		,A2V00002080822 : {
			de: "Erdungskontakt",
			en: "Earthing contact"
		}
		,A2V00001184113 : {
			de: "SCHELLE AXIALFIXIERUNG",
			en: "AXIAL FIXING CLAMP"
		}
		,A2V00001193710 : {
			de: "HALTER ABHEBESICHERUNG SP",
			en: "HOLDER TAKING OFF"
		}
		,A2V00001969436 : {
			de: "Laengspuffer",
			en: "Longitudional buffer"
		}
		,A2V00002534173 : {
			de: "Tuersaeulenprofil NF",
			en: "door pillar profile lf"
		}
		,A2V00002534174 : {
			de: "Adapterprofil",
			en: "adapter profile"
		}
		,A2V00002694113 : {
			de: "Hilfsbetriebeumrichter",
			en: "Auxiliary inverter"
		}
		,A2V00001258918 : {
			de: "Unterlegplatte",
			en: "Spacer"
		}
		,A2V00001258942 : {
			de: "BEILAGE t=2",
			en: "SHIM t=2"
		}
		,A2V00001281030 : {
			de: "Niederhalter f.Luftfeder",
			en: "holding down clamp for air spring"
		}
		,A2V00001318449 : {
			de: "Motorkabelblech",
			en: "motor cable sheet"
		}
		,A2V00001344431 : {
			de: "Schlauchleitung",
			en: "hose assembly"
		}
		,A2V00002174673 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screwÂ "
		}
		,A2V00001345169 : {
			de: "Halter fuer Bremszylinder",
			en: "Bracket for brake zylinder"
		}
		,A2V00002707371 : {
			de: "Druckhuelse fuer Motorlagerung",
			en: "Pressure sleeve for motor bearing"
		}
		,A2V00001348581 : {
			de: "Knochenbuchse",
			en: "spheribloc"
		}
		,A2V00001351110 : {
			de: "Geschweifter Schaekel GSCHW 10",
			en: "curved clevis GSCH13/5,3T"
		}
		,A2V00001878650 : {
			de: "Satz Radbremsscheibe R085R805LGUP + Kit",
			en: "SetWheel-m. brake disk R1085R805LUP +Kit"
		}
		,A2V00001882785 : {
			de: "Sandtreppe KM-Ed li lack.",
			en: "Sand stair KM-Ed left coated"
		}
		,A2V00001361671 : {
			de: "Deckel",
			en: "Cap"
		}
		,A2V00001366346 : {
			de: "Rohrhalter",
			en: "Pipe holder"
		}
		,A2V00001366347 : {
			de: "Halter f. Luftfedersteuerung",
			en: "Holder f. leveling control"
		}
		,A2V00001373606 : {
			de: "Halter f. Notloesezug",
			en: "Holder f. emergency release"
		}
		,A2V00001379328 : {
			de: "Puffer",
			en: "buffer"
		}
		,A2V00001380401 : {
			de: "Puffer",
			en: "buffer"
		}
		,A2V00001385051 : {
			de: "Befestigungsblech",
			en: "sheet for track sweeper"
		}
		,A2V00001390886 : {
			de: "Beilage 190x90x2",
			en: "Washer 190x90x2"
		}
		,A2V00001390889 : {
			de: "Beilage 190x90x3",
			en: "Washer 190x90x3"
		}
		,A2V00001393760 : {
			de: "Sicherungsblech",
			en: "Locking plate"
		}
		,A2V00001394593 : {
			de: "Beilage 6mm",
			en: "Washer 6mm"
		}
		,A2V00001416394 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001425286 : {
			de: "V Bolzen Ã˜25h9x60x52",
			en: "J Bolt Ã˜25h9x60x52"
		}
		,A2V00001425288 : {
			de: "Bolzen Ã˜25h9x70x62",
			en: "bolt Ã˜25h9x70x62"
		}
		,A2V00001426264 : {
			de: "Uebungsstueck",
			en: "Exercise part"
		}
		,A2V00001426376 : {
			de: "Uebungsstueck",
			en: "Exercise part"
		}
		,A2V00001427206 : {
			de: "Platte wg MG-Zentrierung",
			en: "Plate oad MG-centering"
		}
		,A2V00001427208 : {
			de: "Platte spg MG-Zentrierung",
			en: "Plate oad MG-centering"
		}
		,A2V00001427274 : {
			de: "Beilage 2mm",
			en: "Shim 2mm"
		}
		,A2V00001442966 : {
			de: "Bremssattel HYA 26/50 links",
			en: "Brake caliper HYA 26/50 left"
		}
		,A2V00001445372 : {
			de: "Abdeckplatte",
			en: "cover plate"
		}
		,A2V00001445896 : {
			de: "Anschlagblech fuer  Abhebesicherung",
			en: "Sheet metal for lift stop"
		}
		,A2V00001446868 : {
			de: "SE 2,4x1000 BOE DMO-IG",
			en: "SE 2,4x1000 BOE DMO-IG"
		}
		,A2V00001446870 : {
			de: "SE 2,0x1000 BOE DMO-IG",
			en: "SE 2,0x1000 BOE DMO-IG"
		}
		,A2V00001452369 : {
			de: "Stiftschraube",
			en: "stud"
		}
		,A2V00001662821 : {
			de: "Federteller oben",
			en: "Spring plate top"
		}
		,A2V00001665422 : {
			de: "Beilage Querspielbegr. 1mm",
			en: "Shim for lateral limitation bracket"
		}
		,A2V00001665557 : {
			de: "Beilage Querspielbegr. 2mm",
			en: "Shim for lateral limitation bracket"
		}
		,A2V00001667235 : {
			de: "Radsatzlager Zwischendeckel",
			en: "wheelset cover, intermediate"
		}
		,A2V00001667297 : {
			de: "Radsatzlagerdeckel, geschlossen",
			en: "wheelset front cover, closed"
		}
		,A2V00001673378 : {
			de: "Deckel",
			en: "cap"
		}
		,A2V00002130306 : {
			de: "Knochenbuchse Motorlager",
			en: "Bush engine mount"
		}
		,A2V00001695196 : {
			de: "Beilage 1mm",
			en: "Shim 1mm"
		}
		,A2V00001699332 : {
			de: "Distanzscheibe",
			en: "spacer"
		}
		,A2V00001700058 : {
			de: "Beilage 1mm",
			en: "Shim 1mm"
		}
		,A2V00001700059 : {
			de: "Beilage 2mm",
			en: "Shim 2mm"
		}
		,A2V00001702095 : {
			de: "Grundierung",
			en: "outer layer"
		}
		,A2V00001702236 : {
			de: "Haerter",
			en: "hardener"
		}
		,A2V00001702238 : {
			de: "Haerter",
			en: "hardener"
		}
		,A2V00001720714 : {
			de: "Stuetzrippe WG",
			en: "Web AD"
		}
		,A2V00001720715 : {
			de: "Lager 1 fuer Querdaempfer",
			en: "Bracket 1 for Cross Damper"
		}
		,A2V00001733323 : {
			de: "Stuetzrippe SPG",
			en: "Web OAD"
		}
		,A2V00207473163 : {
			de: "FH-Aufstieg voll.",
			en: "FH-Aufstieg voll."
		}
		,A2V00001747823 : {
			de: "Kegelrollenlagereinheit",
			en: "compact tapered bearing unit"
		}
		,A2V00001747824 : {
			de: "Deckel fuer Erdungskontakt",
			en: "Front cover, grounding"
		}
		,A2V00001673745 : {
			de: "Fahrmotor 3Ph fremdbel. 1020kW",
			en: "3ph. asynchronous motor 1020kW"
		}
		,A2V00397212832 : {
			de: "Deckel komplett",
			en: "Cover compl."
		}
		,A2V00001786622 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00001758564 : {
			de: "Ef: (RSL) Labyrinthring",
			en: "EF: (WSB) Labyrinth ring"
		}
		,A2V00001758481 : {
			de: "Ef: (RS) Wellenmutter",
			en: "Sp: (WS) Shaft nut"
		}
		,A2V00001362516 : {
			de: "Sechskantschraube M24X140 (Aufhaengung)",
			en: "Hexagon bolt M24x140 (suspension)"
		}
		,A2V00001802442 : {
			de: "Achskopf, w.g.",
			en: "Axlehead, a.d."
		}
		,A2V00001802443 : {
			de: "Achskopf, sp.g.",
			en: "Axlehead, o.a.d."
		}
		,A2V00001803892 : {
			de: "Gehaeusering",
			en: "housing ring"
		}
		,A2V00001811510 : {
			de: "Daempfer primaer vertikal",
			en: "primary vertical shock absorber"
		}
		,A2V00001811513 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical shock absorber"
		}
		,A2V00001811514 : {
			de: "Daempfer sekundaer horizontal",
			en: "secondary horizontal shock absorber"
		}
		,A2V00001811515 : {
			de: "Drehdaempfer",
			en: "Yaw daemper"
		}
		,A2V00001813821 : {
			de: "Anschweisslasche",
			en: "Weld on plate"
		}
		,A2V00001817672 : {
			de: "Steg Anschlag WG",
			en: "Web Stop AD"
		}
		,A2V00001817673 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00001817674 : {
			de: "Anschlag",
			en: "Stop"
		}
		,A2V00001817746 : {
			de: "Lagerbock",
			en: "Bearing Stand"
		}
		,A2V00001817748 : {
			de: "Steg Anschlag SPG",
			en: "Web Stop OAD"
		}
		,A2V00001817749 : {
			de: "Rippe Lagerbock",
			en: "Rib Bearing Stand"
		}
		,A2V00001819524 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00001820134 : {
			de: "Untergurt fuer Langtraeger",
			en: "Lower frame for longitudinal beam"
		}
		,A2V00001820195 : {
			de: "Treibradsatzwelle lackiert",
			en: "Motor axle painted"
		}
		,A2V00001821350 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00001821351 : {
			de: "Steg waagrecht",
			en: "Web horizontal"
		}
		,A2V00001823150 : {
			de: "Deckel",
			en: "Cap"
		}
		,A2V00001823223 : {
			de: "Schweissbadsicherung 2 fuer Langtraeger",
			en: "Weld backing 2 for longitudinal beam"
		}
		,A2V00001823224 : {
			de: "Rippe fuer Ausdrehanschlag",
			en: "Rib for rotation stop"
		}
		,A2V00001823225 : {
			de: "Platte fuer Ausdrehanschlag",
			en: "Plate for rotation stop"
		}
		,A2V00001823230 : {
			de: "Rippe fuer Queranschlag",
			en: "Rib for sidestop"
		}
		,A2V00001823231 : {
			de: "Platte fuer Queranschlag",
			en: "Plate for sidestop"
		}
		,A2V00001823281 : {
			de: "Rippe fuer Quertraeger",
			en: "Rib for cross beam"
		}
		,A2V00001823282 : {
			de: "Schweissbadsicherung 1 fuer Quertraeger",
			en: "Weld backing 1 for cross beam"
		}
		,A2V00001823283 : {
			de: "Schweissbadsicherung 2 fuer Quertraeger",
			en: "Weld backing 2 for cross beam"
		}
		,A2V00001823284 : {
			de: "Motoranbindung sp.g. fuer Quertraeger",
			en: "Motor connection o.a.d. for cross beam"
		}
		,A2V00001823285 : {
			de: "Motoranbindung w.g. fuer Quertraeger",
			en: "Motor connection a.d. for cross beam"
		}
		,A2V00001823306 : {
			de: "Obergurt fuer Quertraeger",
			en: "Upper flange for cross beam"
		}
		,A2V00001823307 : {
			de: "Untergurt fuer Quertraeger",
			en: "Lower flange for cross beam"
		}
		,A2V00001823308 : {
			de: "Steg 1 fuer Quertraeger",
			en: "Web 1 for cross beam"
		}
		,A2V00001823309 : {
			de: "Steg 2 fuer Quertraeger",
			en: "Web 2 for cross beam"
		}
		,A2V00001823337 : {
			de: "Obergurt fuer Kopftraeger",
			en: "Upper flange for front beam"
		}
		,A2V00001823339 : {
			de: "Rippe 1 fuer Kopftraeger",
			en: "Rib 1 for front beam"
		}
		,A2V00001823340 : {
			de: "Rippe 3 fuer Kopftraeger",
			en: "Rib 3 for front beam"
		}
		,A2V00001823341 : {
			de: "Querdaempferkonsole fuer Kopftraeger",
			en: "Lateral damper bracket for front beam"
		}
		,A2V00001823342 : {
			de: "Steg 1 fuer Kopftraeger",
			en: "Web 1 for front beam"
		}
		,A2V00001823343 : {
			de: "Steg 2 fuer Kopftraeger",
			en: "Web 2 for front beam"
		}
		,A2V00001823344 : {
			de: "Rippe 2 fuer Kopftraeger",
			en: "Rib 2 for front beam"
		}
		,A2V00002604491 : {
			de: "Druckbehaelter 75l",
			en: "Air reservoir 75l"
		}
		,A2V00002683454 : {
			de: "Befestigungsstange fÃ¼r Stromfst.",
			en: "Connecting rod f. current rod"
		}
		,A2V00002683538 : {
			de: "Lagerkopf Stromfuehrungsstange",
			en: "Bearing head current connector rod"
		}
		,A2V00002683545 : {
			de: "Spannhuelse Stromfuehrungsstange",
			en: "Clamping sleeve"
		}
		,A2V00002683640 : {
			de: "Distanzhuelse Stromfuehrungsstange",
			en: "Spacer current connector rod"
		}
		,A2V00002684383 : {
			de: "Faltenbalg schwarz",
			en: "Bellow black"
		}
		,A2V00002723217 : {
			de: "Wanne",
			en: "Tub"
		}
		,A2V00002684991 : {
			de: "Distanzstueck",
			en: "Spacer"
		}
		,A2V00002716773 : {
			de: "Befestigungswinkel Luftfilter",
			en: "Mounting bracket air filter"
		}
		,A2V00002732227 : {
			de: "Schalldaemmplatte SWT",
			en: "Acoustic panel WP"
		}
		,A2V00002147856 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00001823345 : {
			de: "Schweissbadsicherung 1 fuer Kopftraeger",
			en: "Weld backing 1 for front beam"
		}
		,A2V00002668130 : {
			de: "Grundrahmen, SWT/RAL7021",
			en: "Base frame, WP/RAL7021"
		}
		,A2V00001823346 : {
			de: "Untergurt fuer Kopftraeger",
			en: "Lower flange for front beam"
		}
		,A2V00001823956 : {
			de: "Primaerdaempferkonsole fuer Langtraeger",
			en: "Pri. damper bracket f. longitudinal beam"
		}
		,A2V00001823957 : {
			de: "Lenkeranschluss fuer Langtraeger",
			en: "Guide rod connect. for longitudinal beam"
		}
		,A2V00001823958 : {
			de: "Federauflage fuer Langtraeger",
			en: "Suspension support for longitudinal beam"
		}
		,A2V00001823959 : {
			de: "Steg 2 sp.g. fuer Langtraeger",
			en: "Web 2 o.a.d. for longitudinal beam"
		}
		,A2V00001823960 : {
			de: "Platte fuer Langtraeger",
			en: "Plate for longitudinal beam"
		}
		,A2V00001823961 : {
			de: "Schlingerdaempferkonsole f. Langtraeger",
			en: "Yaw damper support for longitudinal beam"
		}
		,A2V00001823962 : {
			de: "Notfangkonsole fuer Langtraeger",
			en: "Emergency catch b. f. longitudinal beam"
		}
		,A2V00001823963 : {
			de: "Steg 1 fuer Langtraeger",
			en: "Web 1 for longitudinal beam"
		}
		,A2V00001823964 : {
			de: "Obergurt fuer Langtraeger",
			en: "Upper flange for longitudinal beam"
		}
		,A2V00001823965 : {
			de: "Sekundaerdaempferkonsole f. Langtraeger",
			en: "Sec. damper bracket f. longitudinal beam"
		}
		,A2V00001823966 : {
			de: "Steg 1 aussen fuer Langtraeger",
			en: "Web 1 outside for longitudinal beam"
		}
		,A2V00001823967 : {
			de: "Rippe 4 fuer Langtraeger",
			en: "Rib 4 for longitudinal beam"
		}
		,A2V00001823968 : {
			de: "Steg 2 w.g. fuer Langtraeger",
			en: "Web 2 a.d. for longitudinal beam"
		}
		,A2V00001823969 : {
			de: "Rippe 3 fuer Langtraeger",
			en: "Rib 3 for longitudinal beam"
		}
		,A2V00001823970 : {
			de: "Rippe 6 fuer Langtraeger",
			en: "Rib 6 for longitudinal beam"
		}
		,A2V00001823971 : {
			de: "Rippe 1 fuer Langtraeger",
			en: "Rib 1 for longitudinal beam"
		}
		,A2V00001823972 : {
			de: "Schweissbadsicherung 1 fuer Langtraeger",
			en: "Weld backing 1 for longitudinal beam"
		}
		,A2V00001823973 : {
			de: "Rippe 7 fuer Langtraeger",
			en: "Rib 7 for longitudinal beam"
		}
		,A2V00001823997 : {
			de: "Konsole 2",
			en: "Bracket 2"
		}
		,A2V00001824649 : {
			de: "Gummigefedertes Rad Typ A (Seite B)",
			en: "resilient wheel type A (side B)"
		}
		,A2V00001828620 : {
			de: "Spannstift ISO13337-21x50-St",
			en: "clamping pin ISO13337-21x50-St"
		}
		,A2V00001828905 : {
			de: "Steg SPG",
			en: "Web  OAD"
		}
		,A2V00001828906 : {
			de: "Steg WG",
			en: "Web AD"
		}
		,A2V00001828950 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001835219 : {
			de: "Fuehrungsleiste 1 WG",
			en: "Gib 1 AD"
		}
		,A2V00001835220 : {
			de: "Fuehrungsleiste 1 SPG",
			en: "Gib 1 OAD"
		}
		,A2V00001835224 : {
			de: "Fuehrungsleiste 2 WG",
			en: "Gib 2 AD"
		}
		,A2V00001835225 : {
			de: "Fuehrungsleiste 2 SPG",
			en: "Gib 2 OAD"
		}
		,A2V00001839523 : {
			de: "Konsole fuer Motordaempfer",
			en: "Bracket for motor damper"
		}
		,A2V00001840620 : {
			de: "Gummigefedertes Rad Typ B (Seite A)",
			en: "resilient wheel type B (side A)"
		}
		,A2V00001841339 : {
			de: "Druckkappe",
			en: "End Cap"
		}
		,A2V00001842419 : {
			de: "Auflager 2",
			en: "Bracket 2"
		}
		,A2V00001844403 : {
			de: "Radsatzlagergehaeuse WG",
			en: "wheelset box housing as drawn"
		}
		,A2V00001844404 : {
			de: "Radsatzlagergehaeuse SPG",
			en: "Wheelset Box Housing opp. as Drawn"
		}
		,A2V00001844468 : {
			de: "Adapter f. Erdungskontakt w.g.",
			en: "Adapter for ground contact a.d."
		}
		,A2V00001844830 : {
			de: "Konsole Drehdaempfer, SPG",
			en: "Bracket yaw damper OAD"
		}
		,A2V00001844831 : {
			de: "Konsole Drehdaempfer, WG",
			en: "Bracket yaw damper AD"
		}
		,A2V00001844832 : {
			de: "Konsole fuer Laengsmitnahme",
			en: "Bracket for traction link"
		}
		,A2V00001844867 : {
			de: "Buchse",
			en: "bush"
		}
		,A2V00001845113 : {
			de: "Radsatzlenker vollst.",
			en: "Wheelset stearing link"
		}
		,A2V00001848214 : {
			de: "Deckel fuer Secherongeber",
			en: "Cover for secheron sensor"
		}
		,A2V00001848731 : {
			de: "Untergurt f. Motortraeger",
			en: "Lower boom f. engine mount"
		}
		,A2V00001848732 : {
			de: "Steg fuer Motortraeger oben",
			en: "Web for engine mount top"
		}
		,A2V00001848733 : {
			de: "Steg fuer Motortraeger oben",
			en: "Web for engine mount top"
		}
		,A2V00001848830 : {
			de: "Flansch f. Motortraeger",
			en: "Flange f. engine mount"
		}
		,A2V00001848850 : {
			de: "Obergurt",
			en: "upper boom"
		}
		,A2V00001849359 : {
			de: "Querstrebe f. Fahrmotortraeger",
			en: "Cross-beam f. engine mount"
		}
		,A2V00001849360 : {
			de: "Rippe f. Fahrmotortraeeger",
			en: "Rib f. engine mount"
		}
		,A2V00001849361 : {
			de: "Anbindung WG f. Fahrmotordaempfer",
			en: "Bracket as drawn f. engine damper"
		}
		,A2V00001849362 : {
			de: "Anbindung SPG f. Fahrmotordaempfer",
			en: "Bracket opp. as drawn  f. engine damper"
		}
		,A2V00001849363 : {
			de: "Rippe f. Schichtfederauflage",
			en: "Rib f. support plate of layer spring"
		}
		,A2V00001849364 : {
			de: "Querstrebe f. Fahrmotortraeeger",
			en: "Cross-beam f. engine mount"
		}
		,A2V00001849739 : {
			de: "Bremszangeneinheit RZS50K27F140 DV",
			en: "Brake caliper unit RZS50K27F140 DV"
		}
		,A2V00001849740 : {
			de: "Bremszangeneinheit RZS50K27X140",
			en: "Brake caliper unit RZS50K27X140"
		}
		,A2V00001852364 : {
			de: "Montagematerial f. Radsatzlager AB",
			en: "Mounting material f. wheelset bearing as"
		}
		,A2V00001852796 : {
			de: "Konsole 1",
			en: "Bracket 1"
		}
		,A2V00001853278 : {
			de: "Rippe f. Stuetze s.p.g.",
			en: "rib f. support opp. as drawn"
		}
		,A2V00001853279 : {
			de: "Rippe f. Stuetze w.g.",
			en: "Rib f. support as drawn"
		}
		,A2V00001853280 : {
			de: "Flansch fuer Stuetze",
			en: "Flange for support"
		}
		,A2V00001853281 : {
			de: "Untergurt f. Stuetze",
			en: "Lower boom f. support"
		}
		,A2V00001853347 : {
			de: "Steg fuer Stuetze",
			en: "Web for column"
		}
		,A2V00001853348 : {
			de: "Obergurt",
			en: "Upper plate"
		}
		,A2V00001858488 : {
			de: "Bolzen f. Notfang",
			en: "Bolt f. safty catch"
		}
		,A2V00001858490 : {
			de: "Lasche f. Notfang SPG",
			en: "Plate f. safty catch opp. as drawn"
		}
		,A2V00001858491 : {
			de: "Lasche f. Notfang WG",
			en: "Plate f. safty catch as drawn"
		}
		,A2V00001860810 : {
			de: "Hebelasche",
			en: "lifting lug"
		}
		,A2V00001862435 : {
			de: "Blech",
			en: "Plate"
		}
		,A2V00001862462 : {
			de: "Fahrmotordaempfer",
			en: "shock absorber for motor unit"
		}
		,A2V00001862477 : {
			de: "Rippe fuer Traeger",
			en: "Rib for bearing"
		}
		,A2V00001862939 : {
			de: "Blech",
			en: "Plate"
		}
		,A2V00001863197 : {
			de: "Indusikonsole SPG",
			en: "Indusi bracket OAD"
		}
		,A2V00001865522 : {
			de: "Federadapter LDG",
			en: "Spring adapter TB"
		}
		,A2V00001865523 : {
			de: "Federadapter TDG",
			en: "Spring adapter MB"
		}
		,A2V00001867691 : {
			de: "Wegimpulsgeber 5.8604.095/25",
			en: "Wheel sensor 5.8604.095/25"
		}
		,A2V00001868350 : {
			de: "Federbeilage 2mm",
			en: "spacer primary spring 2mm"
		}
		,A2V00001872971 : {
			de: "Konsole fuer Querspielbegrenzung",
			en: "Braket for limitation of lateral gap"
		}
		,A2V00001873202 : {
			de: "Primaerfederunterlage",
			en: "Primary Spring Washer"
		}
		,A2V00001873743 : {
			de: "Puffer",
			en: "bumper"
		}
		,A2V00001876552 : {
			de: "Adapter f. Erdungskontakt sp.g.",
			en: "Adapter for grounding contact o.a.d."
		}
		,A2V00001878348 : {
			de: "Labyrinthring",
			en: "Labyrinth ring"
		}
		,A2V00001880565 : {
			de: "Konsole fuer Querpuffer, roh",
			en: "Bracket for lateral bumper stop, raw"
		}
		,A2V00001884054 : {
			de: "Drehzahlgeber Typ 41",
			en: "Speed sensor Typ 41"
		}
		,A2V00001886994 : {
			de: "Schweissbadsicherung 2 fuer Kopftraeger",
			en: "Weld backing 2 for cross beam"
		}
		,A2V00001887334 : {
			de: "Rippe 8 fuer Langtraeger",
			en: "Rib 8 for longitudinal beam"
		}
		,A2V00001894810 : {
			de: "Flanschplatte",
			en: "Flange plate"
		}
		,A2V00001894811 : {
			de: "Blech sp.g",
			en: "Plate o.a.d."
		}
		,A2V00001894812 : {
			de: "Lasche",
			en: "Ear"
		}
		,A2V00001894813 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001894814 : {
			de: "Blech w.g.",
			en: "Plate a.d."
		}
		,A2V00001896005 : {
			de: "Deckel fuer Gehaeuse WSB",
			en: "Cover for housing ECB"
		}
		,A2V00001896121 : {
			de: "Schienenraeumerblech w.g.",
			en: "Rail guard plate a.d."
		}
		,A2V00001896125 : {
			de: "Schienenraeumerblech sp.g.",
			en: "Rail guard plate o.a.d."
		}
		,A2V00001896143 : {
			de: "Rippe senkrecht",
			en: "Vertical rib"
		}
		,A2V00001896188 : {
			de: "Grundplatte w.g.",
			en: "ground plate a.d."
		}
		,A2V00001896191 : {
			de: "Grundplatte sp.g.",
			en: "ground plate o.a.d."
		}
		,A2V00001896192 : {
			de: "Abschlussplatte",
			en: "end plate"
		}
		,A2V00001896193 : {
			de: "Befestigungsblech w.g.",
			en: "sheet a.d."
		}
		,A2V00001896194 : {
			de: "Befestigungsblech sp.g.",
			en: "sheet o.a.d."
		}
		,A2V00001896365 : {
			de: "Montageplatte",
			en: "Mounting plate"
		}
		,A2V00001896702 : {
			de: "Gleitplatte",
			en: "Sliding plate"
		}
		,A2V00001897211 : {
			de: "Befestigungsblech",
			en: "fixation sheet metal"
		}
		,A2V00001897243 : {
			de: "Blech fuer Antenne Anbau SHP 2003",
			en: "Plate for antenna assembly SHP 2003"
		}
		,A2V00001897368 : {
			de: "Rippe fuer Antenne Anbau SHP 2003",
			en: "Rip for antenna assembly SHP 2003"
		}
		,A2V00001897394 : {
			de: "Platte fuer Abhebesicherung",
			en: "Plate for lift-off protection"
		}
		,A2V00001897809 : {
			de: "Halter w.g.",
			en: "Holder a.d."
		}
		,A2V00001897810 : {
			de: "Halter sp.g.",
			en: "Holder o.a.d."
		}
		,A2V00001897815 : {
			de: "Halter w.g.",
			en: "Bracket a.d."
		}
		,A2V00001897856 : {
			de: "Halter sp.g.",
			en: "Bracket o.a.d."
		}
		,A2V00001898275 : {
			de: "Blech WG",
			en: "Plate AD"
		}
		,A2V00001898326 : {
			de: "Blech",
			en: "Plate"
		}
		,A2V00001898327 : {
			de: "Blech SPG",
			en: "Blech OAD"
		}
		,A2V00001898329 : {
			de: "Blech",
			en: "Plate"
		}
		,A2V00001898626 : {
			de: "Blech",
			en: "plate"
		}
		,A2V00001898661 : {
			de: "Blech",
			en: "Plate"
		}
		,A2V00001898712 : {
			de: "Blech f. Indusikonsole",
			en: "Sheet f. indusi bracket"
		}
		,A2V00001898713 : {
			de: "Indusikonsole WG",
			en: "Indusi bracket AD"
		}
		,A2V00001898715 : {
			de: "Blech fuer Traeger",
			en: "plate for bearing"
		}
		,A2V00001898818 : {
			de: "Blech f. Indusikonsole",
			en: "Sheet f. indusi bracket"
		}
		,A2V00001898819 : {
			de: "Blech f. Indusikonsole",
			en: "Plate f. indusi bracket"
		}
		,A2V00001899111 : {
			de: "Blech",
			en: "Plate"
		}
		,A2V00001899112 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001899510 : {
			de: "Rippe fuer Konsole",
			en: "rib for bracket"
		}
		,A2V00001899605 : {
			de: "Blech fuer Konsole",
			en: "plate for bracket"
		}
		,A2V00001903275 : {
			de: "Zwischenplatte",
			en: "Distance plate"
		}
		,A2V00001905370 : {
			de: "Druckplatte Laengsmitnahme",
			en: "Pressure disc traction linkage"
		}
		,A2V00001905983 : {
			de: "Platte Balise",
			en: "Plate Balise"
		}
		,A2V00001908543 : {
			de: "Winkel f. Kabelschellen",
			en: "Angle f. cable clamp"
		}
		,A2V00001908704 : {
			de: "Steg f. Querpufferkonsole, kurz",
			en: "Web f. lateral bumper bracket, short"
		}
		,A2V00001908705 : {
			de: "Rippe f. Federmontageplatte",
			en: "Rip f . spring mounting plate"
		}
		,A2V00001908924 : {
			de: "Obergurt f. Querpufferkonsole",
			en: "Upper flange f. lat. bumper supp."
		}
		,A2V00001909877 : {
			de: "Untergurt f. Querpufferkonsole",
			en: "Lower flange f. lat. bumper supp."
		}
		,A2V00001909883 : {
			de: "Steg, kurz f. Getriebeseite",
			en: "Web, short f. gearbox side"
		}
		,A2V00001910547 : {
			de: "Joch unten",
			en: "Transport plate bottom"
		}
		,A2V00001910548 : {
			de: "Joch oben",
			en: "Transport plate top"
		}
		,A2V00001910604 : {
			de: "Schottblech",
			en: "Web for brake support"
		}
		,A2V00001910605 : {
			de: "Rippe 1 fuer Bremskonsole",
			en: "Rib 1 for brake support"
		}
		,A2V00001910797 : {
			de: "Steg 1 WG",
			en: "Web 1 AD"
		}
		,A2V00001910799 : {
			de: "Steg 1 SPG",
			en: "Web 1 OAD"
		}
		,A2V00001910800 : {
			de: "Steg 2",
			en: "Web 2"
		}
		,A2V00001910822 : {
			de: "Rippe 1 WG",
			en: "Rib 1 AD"
		}
		,A2V00001910823 : {
			de: "Rippe 3",
			en: "Rib 3"
		}
		,A2V00001910824 : {
			de: "Rippe 7",
			en: "Rib 7"
		}
		,A2V00001910825 : {
			de: "Rippe 6 WG",
			en: "Rib 6 AD"
		}
		,A2V00001910836 : {
			de: "Steg 6",
			en: "Web 6"
		}
		,A2V00001910838 : {
			de: "Rippe 1 SPG",
			en: "Rib 1 OAD"
		}
		,A2V00001910839 : {
			de: "Konsole 1  fuer Schlingerdaempfer, sp.g.",
			en: "Bracket 1 for yaw damper, o.a.d."
		}
		,A2V00001910841 : {
			de: "Steg 5 WG",
			en: "Web 5 AD"
		}
		,A2V00001910842 : {
			de: "Steg 5 SPG",
			en: "Web 5 OAD"
		}
		,A2V00001910843 : {
			de: "Rippe 2",
			en: "Rib 2"
		}
		,A2V00001910844 : {
			de: "Rippe 4",
			en: "Rib 4"
		}
		,A2V00001910845 : {
			de: "Konsole 1  fuer Schlingerdaempfer, w.g.",
			en: "Bracket 1 for yaw damper, a.d."
		}
		,A2V00001910846 : {
			de: "Rippe 5 WG",
			en: "Rib 5 AD"
		}
		,A2V00001910849 : {
			de: "Anhebeoese",
			en: "Lifting ear"
		}
		,A2V00001910850 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00001910886 : {
			de: "Rippe 2 fuer Bremskonsole",
			en: "Rib 2 for brake support"
		}
		,A2V00001910891 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00001911319 : {
			de: "Notfangkonsole fuer Motortraeger",
			en: "Emergency stop for motor beam"
		}
		,A2V00001911355 : {
			de: "Rippe fuer Notfangkonsole, innen",
			en: "Rib for emergency stop, inside"
		}
		,A2V00001911358 : {
			de: "Rippe fuer Notfangkonsole, aussen",
			en: "Rib for emergency stop, outside"
		}
		,A2V00001913374 : {
			de: "Beilage 1mm f. Megifeder",
			en: "Shim 1mm f. chevron spring"
		}
		,A2V00001913920 : {
			de: "Blech fuer Bremshaengung",
			en: "Plate for brake mounting"
		}
		,A2V00001914072 : {
			de: "Lasche f. Bremstraeger WG",
			en: "Plate f. brake support AD"
		}
		,A2V00001914073 : {
			de: "Lasche f. Bremstraeger SPG",
			en: "Plate f. brake support OAD"
		}
		,A2V00001914655 : {
			de: "Rippe 5 SPG",
			en: "Rib 5 OAD"
		}
		,A2V00001914669 : {
			de: "Steg 5 WG",
			en: "Web 5 AD"
		}
		,A2V00001914672 : {
			de: "Rippe 1 SPG",
			en: "Rib 1 OAD"
		}
		,A2V00001914713 : {
			de: "Konsole 1 fuer Schlingerdaempfer, sp.g.",
			en: "Bracket 1 for yaw damper, o.a.d."
		}
		,A2V00001914828 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00001914829 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00001915385 : {
			de: "Steg lang fuer Querpufferkonsole",
			en: "Sheet long for lateral bumper support"
		}
		,A2V00370069851 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002342887 : {
			de: "Schelle 1",
			en: "Clamp 1"
		}
		,A2V00002453537 : {
			de: "Anschlaghebel RAL7037",
			en: "Stop Lever RAL7037"
		}
		,A2V00002398622 : {
			de: "Tragleiste T2 RAL3000",
			en: "Support bar P2 RAL3000"
		}
		,A2V00002398623 : {
			de: "Tragleiste T1 RAL3000",
			en: "Support bar P1 RAL 3000"
		}
		,A2V00001915605 : {
			de: "Steg 7 LDG SPG",
			en: "Web 7 TB OAD"
		}
		,A2V00002699232 : {
			de: "Isoliertes Rohr",
			en: "Isolation tube"
		}
		,A2V00002689672 : {
			de: "Parallelfuehrungstange SWT RAL7012",
			en: "Collector head guide rod WP RAL7012"
		}
		,A2V00002699629 : {
			de: "Gewindebolzen Basis UA kompl.",
			en: "Threaded bolt base LA compl."
		}
		,A2V00002687505 : {
			de: "Teleskoprohr lackiert",
			en: "Telescopic tube painted"
		}
		,A2V00001915635 : {
			de: "Rohrkonsole",
			en: "Pipe support"
		}
		,A2V00002452510 : {
			de: "Lenkstange RAL7037",
			en: "Handle bar RAL7037"
		}
		,A2V00002441237 : {
			de: "Wellenstummel",
			en: "Shaft stub"
		}
		,A2V00002699226 : {
			de: "Basis Unterarm isoliert RAL7012",
			en: "Base lower arm isolated RAL7012"
		}
		,A2V00002503746 : {
			de: "Wippenkasten m. AS 2-W08.13022",
			en: "Rocker box w. ADD  2-W08.13022"
		}
		,A2V00001915696 : {
			de: "Steg 7 LDG WG",
			en: "Web 7 TB AD"
		}
		,A2V00001916810 : {
			de: "Rohrdeckel",
			en: "Pipe cover plate"
		}
		,A2V00001921923 : {
			de: "Stuetzrohr 1 sp.g.",
			en: "prop tube 1 o.a.d."
		}
		,A2V00001921926 : {
			de: "Stuetzrohr 2 sp.g.",
			en: "prop tube 2 o.a.d."
		}
		,A2V00001922331 : {
			de: "Puffer mit Anschlagring",
			en: "buffer with hard stop"
		}
		,A2V00001922830 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001927871 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00001927988 : {
			de: "Schellenhalter",
			en: "Handcuffs holder"
		}
		,A2V00001929710 : {
			de: "Montageplatte innen WG",
			en: "Inner mounting plate AD"
		}
		,A2V00001929711 : {
			de: "Rippe senkrecht innen 2",
			en: "Vertical inner rib 2"
		}
		,A2V00001929712 : {
			de: "Rippe senkrecht innen 1",
			en: "Inner vertical rib 1"
		}
		,A2V00001930667 : {
			de: "Montageplatte inn. SPG",
			en: "Inner mount. plate OAD"
		}
		,A2V00001933595 : {
			de: "Rohrhalter 02",
			en: "Pipe Bracket 02"
		}
		,A2V00001933841 : {
			de: "Schellenhalter 03",
			en: "Pipe Clamp Bracket  03"
		}
		,A2V00001933897 : {
			de: "Schellenhalter 01",
			en: "Pipe clamb bracket 01"
		}
		,A2V00001934025 : {
			de: "Halter Kon 01",
			en: "bracket kon 01"
		}
		,A2V00001934082 : {
			de: "V Bolzen Ã˜20h9x80x72",
			en: "J Bolt Ã˜20h9x80x72"
		}
		,A2V00001934083 : {
			de: "V Bolzen Ã˜16h9x90x85",
			en: "J Bolt Ã˜16h9x90x85"
		}
		,A2V00001935600 : {
			de: "Rohr Uebergabeblech sp.g.",
			en: "Piping porting plate oad"
		}
		,A2V00001935630 : {
			de: "Drehdaempfer",
			en: "yaw damper"
		}
		,A2V00001935674 : {
			de: "Rohr Uebergabeblech w.g.",
			en: "Piping porting plate a.d."
		}
		,A2V00001935937 : {
			de: "V ME E1 Rippe 4",
			en: "J MA E1 Rib 4"
		}
		,A2V00001935939 : {
			de: "V ME E1 Steg 5",
			en: "J MA E1 Web 5"
		}
		,A2V00001937360 : {
			de: "Bolzen Ã˜20h9x70x62",
			en: "bolt Ã˜20h9x70x62"
		}
		,A2V00001938119 : {
			de: "V VE Lasche D16",
			en: "J SU Lug D16"
		}
		,A2V00001938502 : {
			de: "Primaeranschlag",
			en: "primary arrester"
		}
		,A2V00001938808 : {
			de: "Adapterplatte",
			en: "Adapter plate"
		}
		,A2V00001939121 : {
			de: "Butzen",
			en: "Button"
		}
		,A2V00001942088 : {
			de: "Gleitblech",
			en: "Sliding Plate"
		}
		,A2V00001942475 : {
			de: "Daempfer primaer vertikal",
			en: "damper primary vertical"
		}
		,A2V00001946330 : {
			de: "Laufradsatzwelle 15,5-178-178,6-2-110",
			en: "Trailer axle 15,5-178-178,6-2-110"
		}
		,A2V00001946897 : {
			de: "Beilage Ausdrehanschlag",
			en: "shim end stop"
		}
		,A2V00001947792 : {
			de: "Rohrhalter TDG",
			en: "Pipebracket TDG"
		}
		,A2V00001947998 : {
			de: "Indusikonsole",
			en: "Indusi bracket"
		}
		,A2V00001947999 : {
			de: "Indusikonsole",
			en: "Indusi bracket"
		}
		,A2V00001948046 : {
			de: "Konsole f. Antenne",
			en: "Bracket for antenna"
		}
		,A2V00001948047 : {
			de: "Konsole f. Antenne",
			en: "Bracket f. antenna"
		}
		,A2V00001948399 : {
			de: "Halter FSB 1",
			en: "Pipebracket FSP 1"
		}
		,A2V00001948748 : {
			de: "Rohrhalter gebog. 3",
			en: "Pipebracket bend. 3"
		}
		,A2V00001948782 : {
			de: "Rohrhalter gebog. 4",
			en: "Pipebracket bend. 4"
		}
		,A2V00001948899 : {
			de: "Rohrhalter gebog, 1",
			en: "Pipebracket bend. 1"
		}
		,A2V00001948922 : {
			de: "Rohrhalter gebog. 5",
			en: "Pipebracket bend. 5"
		}
		,A2V00001948944 : {
			de: "Rohrhalter LDG 3",
			en: "Pipebracket LDG 3"
		}
		,A2V00001948964 : {
			de: "Halter FSB 1",
			en: "Bracket FSB 1"
		}
		,A2V00001949131 : {
			de: "Rohrhalter geb. 5",
			en: "Pipebracket bend. 5"
		}
		,A2V00001949219 : {
			de: "Schienenraeumerblech w.g.",
			en: "Plate rail guard a.d."
		}
		,A2V00001949220 : {
			de: "Schienenraeumerblech sp.g.",
			en: "Plate rail guard o.a.d."
		}
		,A2V00001949406 : {
			de: "Gewindebutzen 1",
			en: "Thread scrap 1"
		}
		,A2V00001949424 : {
			de: "Rohrhalter",
			en: "pipe bracket"
		}
		,A2V00001949481 : {
			de: "Rohrhalter, gerade",
			en: "Bracket f. Piping, straight"
		}
		,A2V00001949493 : {
			de: "Schutzwinkel FDU",
			en: "Protectionangle FDU"
		}
		,A2V00001949556 : {
			de: "Rohrhalter gebogen",
			en: "pipe bracket"
		}
		,A2V00001949706 : {
			de: "Halter 1 TDG",
			en: "bracket 1 mb"
		}
		,A2V00001949888 : {
			de: "Schutzwinkel FDU2",
			en: "Protection FDU 2"
		}
		,A2V00001950295 : {
			de: "Halter 2",
			en: "Bracket 2"
		}
		,A2V00002246939 : {
			de: "EMV Halterblech",
			en: "EMV bracket"
		}
		,A2V00002256775 : {
			de: "Impulsgeber KMG-2HS mit Wellrohr,Stecker",
			en: "Speed sensor KMG-2HS w. corr. tube, plug"
		}
		,A2V00001950433 : {
			de: "Konsole Luftfedersteuerung",
			en: "console level valve"
		}
		,A2V00001950434 : {
			de: "Konsole Luftfedersteuerung",
			en: "console level valve"
		}
		,A2V00001951439 : {
			de: "Halter",
			en: "bracket"
		}
		,A2V00002356268 : {
			de: "Dehnhuelse",
			en: "Extension sleeve"
		}
		,A2V00001951448 : {
			de: "Halter",
			en: "bracket"
		}
		,A2V00001952034 : {
			de: "Halter Eisschutz 1",
			en: "Ice protection bracket 1"
		}
		,A2V00001952682 : {
			de: "Halter fuer Rohr",
			en: "holder for pipe"
		}
		,A2V00001952700 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00001952746 : {
			de: "Halter fuer Rohr",
			en: "holder for pipe"
		}
		,A2V00001952750 : {
			de: "Halter fuer Rohr",
			en: "holder for pipe"
		}
		,A2V00001954392 : {
			de: "Abstuetzblech w.g.",
			en: "Support plate a.d."
		}
		,A2V00001954859 : {
			de: "Flanschplatte",
			en: "flange plate"
		}
		,A2V00001954865 : {
			de: "Bolzen fuer Kastenkulisse",
			en: "Bolt for motion link"
		}
		,A2V00001954875 : {
			de: "Bolzen fuer Kastenkulisse",
			en: "Bolt for motion link"
		}
		,A2V00001955000 : {
			de: "Obergurtblech Ant. sp.g.",
			en: "Top boom antenna o.a.d."
		}
		,A2V00001955001 : {
			de: "Rohrhalter",
			en: "holder-f.-piping"
		}
		,A2V00001955011 : {
			de: "Bolzen fuer Kastenkulisse",
			en: "Bolt for motion link"
		}
		,A2V00001955012 : {
			de: "Obergurtblech Ant. w.g.",
			en: "Top boom antenna a.d."
		}
		,A2V00002108140 : {
			de: "Distanzscheibe 2mm",
			en: "spacer 2mm"
		}
		,A2V00001221210 : {
			de: "Bremswiderstandsmodul",
			en: "brake resistor modul"
		}
		,A2V00001955029 : {
			de: "Stegblech Antenne w.g.",
			en: "web plate antenna a.d."
		}
		,A2V00001955037 : {
			de: "Bolzen fuer Kastenkulisse",
			en: "Bolt for motion link"
		}
		,A2V00001955040 : {
			de: "Stegblech Antenne sp.g.",
			en: "web plate antenna o.a.d."
		}
		,A2V00001955043 : {
			de: "Untergurtbl Antenne w.g.",
			en: "bottom boom plate antenna a.d."
		}
		,A2V00001955118 : {
			de: "Untergurtbl Antenne sp.g.",
			en: "bottom boom plate antenna o.a.d."
		}
		,A2V00001955172 : {
			de: "Lasche Antennen Halter",
			en: "ear antenna bracket"
		}
		,A2V00001955175 : {
			de: "Rippe innen",
			en: "rib inside"
		}
		,A2V00001955176 : {
			de: "Federteller unten",
			en: "lower spring cap"
		}
		,A2V00001955195 : {
			de: "Rippe innen sp.g.",
			en: "rib inside o.a.d."
		}
		,A2V00001955213 : {
			de: "Platte",
			en: "plate"
		}
		,A2V00001955229 : {
			de: "Notloesehalter 3",
			en: "emergency release bracket 3"
		}
		,A2V00001955291 : {
			de: "Verliersicherung",
			en: "locking device"
		}
		,A2V00001955583 : {
			de: "Zylinderstift",
			en: "dowel"
		}
		,A2V00001956045 : {
			de: "Rippe aussen",
			en: "rib outside"
		}
		,A2V00001956070 : {
			de: "Rippe aussen sp.g.",
			en: "rib outside o.a.d."
		}
		,A2V00001956179 : {
			de: "Gewindebutzen M5 16x20",
			en: "Thread socket M5 16x20"
		}
		,A2V00001963683 : {
			de: "Distanzhuelse 01",
			en: "distance bush 01"
		}
		,A2V00001964287 : {
			de: "Deckplatte 02",
			en: "cover plate 02"
		}
		,A2V00001970725 : {
			de: "Polrad",
			en: "Pole wheel"
		}
		,A2V00001970762 : {
			de: "Schelle",
			en: "clamp"
		}
		,A2V00001971787 : {
			de: "Konsole Luftfederst.",
			en: "Console pneu. spring ctrl"
		}
		,A2V00001971796 : {
			de: "Konsole Luftfederst.",
			en: "Console pneu. spring ctrl"
		}
		,A2V00001977861 : {
			de: "Grundblech w.g.",
			en: "Base plate a.d."
		}
		,A2V00001977936 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001977998 : {
			de: "Grundblech sp.g.",
			en: "Base plate o.a.d."
		}
		,A2V00001978217 : {
			de: "Grundblech sp.g.",
			en: "Base plate o.a.d."
		}
		,A2V00001978218 : {
			de: "Rippe sp.g",
			en: "rip o.a.d"
		}
		,A2V00001978220 : {
			de: "Rippe sp.g.",
			en: "rip o.a.d"
		}
		,A2V00001978300 : {
			de: "Rippe w.g.",
			en: "rip a.d"
		}
		,A2V00001978301 : {
			de: "Rippe w.g.",
			en: "rip a.d"
		}
		,A2V00001978302 : {
			de: "Grundblech w.g.",
			en: "Base plate a.d."
		}
		,A2V00001980323 : {
			de: "Gewindebutzen M6 20x20",
			en: "Thread socket M6 20x20"
		}
		,A2V00001980629 : {
			de: "Grundblech Typenschild DG",
			en: "Type plate serial, bogie"
		}
		,A2V00001981391 : {
			de: "Schienenraeumer Blech spg",
			en: "Rail guard plate o.a.d."
		}
		,A2V00001981447 : {
			de: "Anschlag",
			en: "end stop"
		}
		,A2V00001981448 : {
			de: "Schienenraeumer Blech wg",
			en: "Rail guard sheet a.d."
		}
		,A2V00001986701 : {
			de: "Halter LG Geber",
			en: "Holder LG"
		}
		,A2V00001987616 : {
			de: "Adapterblech",
			en: "Adapter plate"
		}
		,A2V00001988167 : {
			de: "Druckkappe",
			en: "Pressure cap"
		}
		,A2V00001991345 : {
			de: "Halter F V1 E4 50",
			en: "Holder F V1 E4 50"
		}
		,A2V00001991355 : {
			de: "Halter F V1 E5 50",
			en: "Holder F V1 E5 50"
		}
		,A2V00001991544 : {
			de: "Halter F V2 E2 50",
			en: "Holder F V2 E2 50"
		}
		,A2V00001991761 : {
			de: "Halter F V1 D2 50",
			en: "Holder F V1 D2 50"
		}
		,A2V00002000002 : {
			de: "Schutzblech Rohr gesc spg",
			en: "Protection plate weld oad"
		}
		,A2V00002000565 : {
			de: "Abhebesicherung",
			en: "lifting device"
		}
		,A2V00002000676 : {
			de: "Abstuetzblech sp.g.",
			en: "Support plate o.a.d."
		}
		,A2V00002006682 : {
			de: "Untergurt",
			en: "Lower Flange"
		}
		,A2V00002036825 : {
			de: "Drehdaempferkonsole SPG, mech.",
			en: "Bracket yaw damper OAD, machined"
		}
		,A2V00002036848 : {
			de: "Tauchzapfen",
			en: "Center pivot"
		}
		,A2V00002038274 : {
			de: "Wanklagerbock Oberteil",
			en: "Anti roll bar upper bearing housing"
		}
		,A2V00002038296 : {
			de: "Lagergehaeuse Unterteil",
			en: "Lower bearing housing"
		}
		,A2V00002338671 : {
			de: "Dehnhuelse M12",
			en: "extension sleeve M12"
		}
		,A2V00002140418 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002564397 : {
			de: "Nutenstein M8",
			en: "Slot nut M8"
		}
		,A2V00002051594 : {
			de: "Fahrmotordaempfer",
			en: "damper, motor unit"
		}
		,A2V00002051867 : {
			de: "Daempfer sekundaer horizontal",
			en: "secondary horizontal damper"
		}
		,A2V00002052272 : {
			de: "Faltenbalg",
			en: "Bellow"
		}
		,A2V00002053227 : {
			de: "Kegelrollenlager 150/250",
			en: "tapered roller bearing 150/250"
		}
		,A2V00002057123 : {
			de: "Radsatzdeckel",
			en: "Cup for wheel set"
		}
		,A2V00002058196 : {
			de: "Radbremszange",
			en: "Brake disc calliper / wheel m."
		}
		,A2V00002058197 : {
			de: "Radbremszange",
			en: "Brake disc calliper / wheel m."
		}
		,A2V00002059201 : {
			de: "Rippe 1",
			en: "Rib 1"
		}
		,A2V00002059203 : {
			de: "Rippe 3",
			en: "Rib 3"
		}
		,A2V00002059315 : {
			de: "Steg 3",
			en: "Web 3"
		}
		,A2V00002059355 : {
			de: "Steg 4",
			en: "Web 4"
		}
		,A2V00002059498 : {
			de: "Motornotfang, unten",
			en: "Motor emergency stop, low"
		}
		,A2V00002059543 : {
			de: "Obergurt 2",
			en: "Upper flange 2"
		}
		,A2V00002059635 : {
			de: "Buchse f. Motoraufnahme 2",
			en: "Bush for motor bracket 2"
		}
		,A2V00002059699 : {
			de: "Blattfederbuchse",
			en: "flat spring bush"
		}
		,A2V00002061311 : {
			de: "Steg 2, gefraest",
			en: "web 2, milled"
		}
		,A2V00002063102 : {
			de: "Pufferkonsole Roh",
			en: "Transversal stop bracket row"
		}
		,A2V00002063107 : {
			de: "Knotenstueck Stegblech aussen Roh",
			en: "Joint unit web plate outside row"
		}
		,A2V00002066641 : {
			de: "Halter w.g.",
			en: "bracket a.d."
		}
		,A2V00002066646 : {
			de: "Halter sp.g.",
			en: "bracket o.a.d."
		}
		,A2V00002074812 : {
			de: "Schlingerdaempfer - Anbindung  Roh",
			en: "lurch damper - connection O.A.D. raw"
		}
		,A2V00002074813 : {
			de: "Druckplatte Roh",
			en: "pressureplate raw"
		}
		,A2V00002075036 : {
			de: "Konsole Roh",
			en: "Console raw"
		}
		,A2V00002075037 : {
			de: "Befestigung fuer Bremslasche Roh",
			en: "Support for brake mounting link raw"
		}
		,A2V00002075038 : {
			de: "Querdaempferkonsole Roh",
			en: "Lateral damper support raw"
		}
		,A2V00002075039 : {
			de: "Laengsmitnahmekonsole Roh",
			en: "Lateral linkage bracket raw"
		}
		,A2V00002075040 : {
			de: "Bremszylinderbefestigung Roh",
			en: "Brake cylinder support raw"
		}
		,A2V00002075041 : {
			de: "Luftfederrohr Roh",
			en: "Air suspension tube raw"
		}
		,A2V00002075042 : {
			de: "Luftfederrohr Roh",
			en: "Air suspension tube raw"
		}
		,A2V00002075043 : {
			de: "Endstueck Rohr Roh",
			en: "end cap pipe raw"
		}
		,A2V00002076229 : {
			de: "Montagematerial Lageranbauten",
			en: "Mounting material bearing assembly"
		}
		,A2V00002076230 : {
			de: "Montagematerial Lageranbauten",
			en: "Mounting material bearing assembly"
		}
		,A2V00002076231 : {
			de: "Montagematerial Lageranbauten",
			en: "Mounting material bearing assembly"
		}
		,A2V00002076279 : {
			de: "Druckkappe",
			en: "End cap"
		}
		,A2V00002076282 : {
			de: "Druckkappe f EK",
			en: "End cap F. GD"
		}
		,A2V00002076336 : {
			de: "RSL-Deckel f.Geschwindigkeitsgeber",
			en: "AB-cover f. speed sensor"
		}
		,A2V00002076439 : {
			de: "Getriebemontageplatte Roh",
			en: "Gearbox mounting raw"
		}
		,A2V00002076441 : {
			de: "Federmontageplatte Roh",
			en: "Spring mounting plate raw"
		}
		,A2V00002076444 : {
			de: "Konsole 2  fuer Schlingerdaempfer Roh",
			en: "Bracket 2 for yaw damper raw"
		}
		,A2V00002076457 : {
			de: "Daempferkonsole, aussen Roh",
			en: "Damper bracket, outside row"
		}
		,A2V00002076459 : {
			de: "Daempferkonsole, innen Roh",
			en: "Damper bracket, inside raw"
		}
		,A2V00002076461 : {
			de: "Stuetzkonsole Roh",
			en: "support raw"
		}
		,A2V00002076471 : {
			de: "Konsole fuer Abhebesicherung Roh",
			en: "Bracket for lifting stop raw"
		}
		,A2V00002076770 : {
			de: "Lasche fuer Wankstuetze,  Roh",
			en: "Plate f. anti rolling sup.,raw"
		}
		,A2V00002076974 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00001896866 : {
			de: "Tuerholmverkl. 8  vollstaendig",
			en: "door panel 8      complete"
		}
		,A2V00001896695 : {
			de: "Tuerholmverkl. 7  vollstaendig",
			en: "door panel 7      complete"
		}
		,A2V00001821289 : {
			de: "Tuerholmverkl. 6  vollstaendig",
			en: "door panel 6      complete"
		}
		,A2V00001821286 : {
			de: "Tuerholmverkl. 4  vollstaendig",
			en: "door panel 4      complete"
		}
		,A2V00001821265 : {
			de: "Tuerholmverkl. 3  vollstaendig",
			en: "door panel 3      complete"
		}
		,A2V00397284294 : {
			de: "GERAETETRAEGER ZUSAMMENBAU",
			en: "EQUIPMENT RANK ASEMBLY"
		}
		,A2V00002077975 : {
			de: "Konsole 2 fuer Schlingerdaempfer Roh",
			en: "Bracket 2 for yaw damper, raw"
		}
		,A2V00002080528 : {
			de: "Bolzen Ã˜20h9x60x55",
			en: "bolt Ã˜20h9x60x55"
		}
		,A2V00002082903 : {
			de: "Schellenhalter",
			en: "Clamp bracket"
		}
		,A2V00002083160 : {
			de: "Rippe 2 fuer Quertraeger",
			en: "Rib 2 for cross beam"
		}
		,A2V00002083162 : {
			de: "Luftfederkonsole fuer Langtraeger",
			en: "Air spring bracket for longitudinal beam"
		}
		,A2V00002083163 : {
			de: "Rippe 1 fuer Quertraeger",
			en: "Rib 1 for cross beam"
		}
		,A2V00002083164 : {
			de: "Schweissbadsicherung 1 fuer Langtraeger",
			en: "Weld backing 1 for longitudinal beam"
		}
		,A2V00002083166 : {
			de: "Obergurt 2 fuer Langtraeger",
			en: "Upper flange 2 for longitudinal beam"
		}
		,A2V00002083172 : {
			de: "Querdaempferkonsole fuer Langtraeger",
			en: "Lateral damper bracket for longit. beam"
		}
		,A2V00002083179 : {
			de: "Steg 1 fuer Quertraeger",
			en: "Web 1 for cross beam"
		}
		,A2V00002083180 : {
			de: "Untergurt 2 fuer Langtraeger",
			en: "Lower flange 2 for longitudinal beam"
		}
		,A2V00002083182 : {
			de: "Steg 2 fuer Quertraeger",
			en: "Web 2 for cross beam"
		}
		,A2V00002085747 : {
			de: "Halter 11",
			en: "Holder 11"
		}
		,A2V00002085752 : {
			de: "Halter 3 wg",
			en: "Holder 3 ad"
		}
		,A2V00002085753 : {
			de: "Halter 4",
			en: "Holder 4"
		}
		,A2V00002085755 : {
			de: "Halter 3 spg",
			en: "Holder 3 oad"
		}
		,A2V00002086564 : {
			de: "Halter 4",
			en: "Holder 4"
		}
		,A2V00002086565 : {
			de: "Halter 1 spg",
			en: "Holder 1 oad"
		}
		,A2V00002087531 : {
			de: "Federteller",
			en: "spring cap"
		}
		,A2V00002088504 : {
			de: "Halter 14",
			en: "Holder 14"
		}
		,A2V00002089079 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002089080 : {
			de: "Scheibe",
			en: "Shim"
		}
		,A2V00002089588 : {
			de: "Querpuffer Konsole",
			en: "Lateral buffer bracket"
		}
		,A2V00002090076 : {
			de: "Primaere Abhebekonsole fuer Langtraeger",
			en: "Prim. lifting bracket for longit. beam"
		}
		,A2V00002090476 : {
			de: "Distanzhuelse lack.",
			en: "bush painted"
		}
		,A2V00002090635 : {
			de: "Radschallabsorber Anbau",
			en: "Wheel sound absorber mounting"
		}
		,A2V00002090651 : {
			de: "Spritzschutzhalter",
			en: "splash guard holder"
		}
		,A2V00002090773 : {
			de: "Halter w.g.",
			en: "bracket"
		}
		,A2V00002090840 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002091383 : {
			de: "Sechskantmutter ISO 7040 M24 10",
			en: "Hexagon nut ISO 7040 M24 10"
		}
		,A2V00002092157 : {
			de: "Temperatursensor  Radlager Achse 1 und 4",
			en: "Temperature sensor wheel bearing"
		}
		,A2V00002092158 : {
			de: "Temperatursensor  Radlager Achse 2 und 3",
			en: "Temperature sensor wheel bearing"
		}
		,A2V00002093643 : {
			de: "PrÃ¼fstÃ¼ck Bl 5x125x150",
			en: "test piece Bl 5x125x150"
		}
		,A2V00002096878 : {
			de: "PrimÃ¤rschichtfeder ICX SN Sammler",
			en: "Air spring system ICX SN collector"
		}
		,A2V00002097132 : {
			de: "Druckkappe",
			en: "End cap"
		}
		,A2V00002097133 : {
			de: "Stuetzring",
			en: "Backing ring"
		}
		,A2V00002100827 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002101722 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00112518900 : {
			de: "FANGKORB (GELENKWELLE)",
			en: "trap basket (drive shaft)"
		}
		,A2V00200500918 : {
			de: "Sicherungsmutter",
			en: "prevailing torque type hexagon nut"
		}
		,A2V00002101790 : {
			de: "Konsole spg",
			en: "Bracket oad"
		}
		,A2V00002101791 : {
			de: "Konsole wg",
			en: "Bracket ad"
		}
		,A2V00002101793 : {
			de: "Konsole spg",
			en: "Bracket oad"
		}
		,A2V00002102019 : {
			de: "Halter SPG",
			en: "Bracket OAD"
		}
		,A2V00002103537 : {
			de: "Steg 2 fuer Laengsmitnahme",
			en: "Web 2 for traction link"
		}
		,A2V00002104120 : {
			de: "Beilage 01",
			en: "Shim 01"
		}
		,A2V00002105320 : {
			de: "Vollrad, lackiert",
			en: "solid wheel, lacquered"
		}
		,A2V00002105831 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002107602 : {
			de: "Geberverschlussdeckel",
			en: "Sensorcover plate"
		}
		,A2V00002109896 : {
			de: "Schienenraeumer SPG",
			en: "Lifeguard O.A.D."
		}
		,A2V00002109897 : {
			de: "Schienenraeumer WG",
			en: "Lifeguard A.D."
		}
		,A2V00002118009 : {
			de: "Halter 1 SPG",
			en: "Bracket 1 OAD"
		}
		,A2V00002118131 : {
			de: "Seitenblech w.g.",
			en: "Side plate a.d."
		}
		,A2V00002118159 : {
			de: "Seitenblech w.g.",
			en: "Side plate a.d."
		}
		,A2V00002118251 : {
			de: "Seitenblech sp.g.",
			en: "Side plate o.a.d."
		}
		,A2V00002118252 : {
			de: "Seitenblech sp.g.",
			en: "Side plate o.a.d."
		}
		,A2V00002118607 : {
			de: "Deckblech w.g.",
			en: "Cover sheet a.d."
		}
		,A2V00002118616 : {
			de: "Deckblech sp.g.",
			en: "Cover sheet o.a.d."
		}
		,A2V00002119056 : {
			de: "Erdungslasche 2",
			en: "Earthing lug 2"
		}
		,A2V00002119152 : {
			de: "Treibradsatzwelle, lackiert",
			en: "Motor wheelset axle, lacquered"
		}
		,A2V00002119220 : {
			de: "Erdungslasche 3",
			en: "Grounding link 3"
		}
		,A2V00002120038 : {
			de: "Winkelblech w.g.",
			en: "Angular sheet a.d."
		}
		,A2V00002120184 : {
			de: "Winkelblech sp.g.",
			en: "Angular sheet o.a.d."
		}
		,A2V00002121786 : {
			de: "Sechskantmutter ISO 10513 M24 10",
			en: "Hexagon nut ISO 10513 M24 10"
		}
		,A2V00002130308 : {
			de: "Schichtfeder",
			en: "Layer spring"
		}
		,A2V00002132497 : {
			de: "Luftfedersystem",
			en: "Airspring system"
		}
		,A2V00002134245 : {
			de: "Rippe 2",
			en: "Rib 2"
		}
		,A2V00002134399 : {
			de: "Notfangplatte",
			en: "Emergency catch"
		}
		,A2V00002135806 : {
			de: "Blech f. Indusikonsole",
			en: "Sheet f. indusi bracket"
		}
		,A2V00002136117 : {
			de: "Treibradsatzwelle",
			en: "Motor axle shaft"
		}
		,A2V00002140785 : {
			de: "Vollrad ER9, final bearbeitet",
			en: "Solid wheel ER9, final machined"
		}
		,A2V00002140820 : {
			de: "RS-Lagergehaeuse LH lackiert",
			en: "Axle box housing LH lacquered"
		}
		,A2V00002140825 : {
			de: "RS-Lagergehaeuse RH lackiert",
			en: "Axle box housing RH lacquered"
		}
		,A2V00002140842 : {
			de: "Druckkappe",
			en: "End cap"
		}
		,A2V00002140843 : {
			de: "Radsatzlager Waelzlager",
			en: "Axle box rolling bearing"
		}
		,A2V00002146846 : {
			de: "Schellenhalter 9",
			en: "Clip collar bracket 9"
		}
		,A2V00002146847 : {
			de: "Klemmenkastenhalter",
			en: "Holder for clamping box"
		}
		,A2V00002146848 : {
			de: "Schellenhalter 13",
			en: "Clip collar bracket 13"
		}
		,A2V00002146849 : {
			de: "Schellenhalter 8",
			en: "Clip collar bracket 8"
		}
		,A2V00002146850 : {
			de: "Schellenhalter 14",
			en: "Clip collar bracket 14"
		}
		,A2V00002146851 : {
			de: "Halter fuer Notloesezug",
			en: "Holder for emergency release"
		}
		,A2V00002146852 : {
			de: "Schellenhalter 7",
			en: "Clip collar bracket 7"
		}
		,A2V00002146853 : {
			de: "Schellenhalter 10",
			en: "Clip collar bracket 10"
		}
		,A2V00002146854 : {
			de: "Schellenhalter 11",
			en: "Clip collar bracket 11"
		}
		,A2V00002146855 : {
			de: "Schellenhalter 12",
			en: "Clip collar bracket 12"
		}
		,A2V00002146856 : {
			de: "Schellenhalter 15",
			en: "Clip collar bracket 15"
		}
		,A2V00002147292 : {
			de: "Kabelhalter 1",
			en: "Cable bracket 1"
		}
		,A2V00002147824 : {
			de: "Huelse",
			en: "bush"
		}
		,A2V00100614645 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00100614599 : {
			de: "BOLZEN",
			en: "Bolt"
		}
		,A2V00002149176 : {
			de: "Kabelhalter 2",
			en: "Cable holder 2"
		}
		,A2V00002152931 : {
			de: "Bolzen f. Notfang fÃ¼r -40Â°C",
			en: "Bolt f. safty catch for -40Â°C"
		}
		,A2V00002159827 : {
			de: "Anlaufblech 1",
			en: "Starting sheet 1"
		}
		,A2V00002160900 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002168381 : {
			de: "Radschutzkastenhalter w.g.",
			en: "wheel guard bracket a.d."
		}
		,A2V00002168382 : {
			de: "Radschutzkastenhalter sp.g",
			en: "Wheel guard bracket o.a.d"
		}
		,A2V00156027630 : {
			de: "SIBCOS M1300-0-0",
			en: "SIBCOS-M1300-0-0"
		}
		,A2V00002171499 : {
			de: "Puffer",
			en: "Buffer"
		}
		,A2V00002175462 : {
			de: "Pruefstueck 15 12x150x400 / 12 / 0",
			en: "Test Piece 15 12x150x400 / 12 / 0"
		}
		,A2V00002175464 : {
			de: "Pruefstueck 29 6x150x400 / 2 / 55",
			en: "Test Piece 29 6x150x400 / 2 / 55"
		}
		,A2V00002175493 : {
			de: "Pruefstueck 26 20x150x400 / 2 / 45",
			en: "Test Piece 26 20x150x400 / 2 / 45"
		}
		,A2V00002175498 : {
			de: "Pruefstueck 23 12x150x400 / 2 / 45",
			en: "Test Piece 23 12x150x400 / 2 / 45"
		}
		,A2V00002175625 : {
			de: "Halter Schlauch",
			en: "holder hose"
		}
		,A2V00002175966 : {
			de: "Drehzapfen mech. bearbeitet",
			en: "Centre pivot machined"
		}
		,A2V00002176669 : {
			de: "Joch",
			en: "Ledger"
		}
		,A2V00002178396 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002178397 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002178756 : {
			de: "Knochenbuchse",
			en: "Spheribloc"
		}
		,A2V00002184980 : {
			de: "Grundblech",
			en: "base sheet"
		}
		,A2V00002185160 : {
			de: "Winkel Rahmen",
			en: "Angle frame"
		}
		,A2V00002185206 : {
			de: "Winkel Schutz",
			en: "angle guard"
		}
		,A2V00002185218 : {
			de: "Rippe aussen w.g.",
			en: "rib outside a.d."
		}
		,A2V00002185219 : {
			de: "Rippe aussen sp.g.",
			en: "rib outside o.a.d."
		}
		,A2V00002185262 : {
			de: "Rippe sp.g.",
			en: "rib o.a.d."
		}
		,A2V00002185278 : {
			de: "Rippe mittig",
			en: "rib in center"
		}
		,A2V00002185355 : {
			de: "Halter 1",
			en: "Holder 1"
		}
		,A2V00002185379 : {
			de: "Rippe w.g.",
			en: "rib a.d."
		}
		,A2V00002186166 : {
			de: "Winkel Kasten",
			en: "angle box"
		}
		,A2V00002186714 : {
			de: "Hohlbuchse f. Drehzapfen",
			en: "Cylindrical bush"
		}
		,A2V00002188760 : {
			de: "Schellenhaelfte",
			en: "Clamp half"
		}
		,A2V00002190882 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002193755 : {
			de: "Drehstabfeder lackiert",
			en: "Torsion bar painted"
		}
		,A2V00002193864 : {
			de: "Halter 2",
			en: "Holder 2"
		}
		,A2V00002193941 : {
			de: "Halter 3",
			en: "Holder 3"
		}
		,A2V00397158983 : {
			de: "Fangbuegel fuer Motor",
			en: "Catch holder for motor"
		}
		,A2V00397124852 : {
			de: "FEDERBEILAGE",
			en: "spring shim"
		}
		,A2V00002194808 : {
			de: "V Bolzen Ã˜25h9x50x42",
			en: "J Bolt Ã˜25h9x50x42"
		}
		,A2V00002195618 : {
			de: "Daempfer primaer vertikal",
			en: "Primary vertical shock absorber"
		}
		,A2V00002195839 : {
			de: "Halter 15",
			en: "Holder 15"
		}
		,A2V00002195840 : {
			de: "Halter 1",
			en: "Holder 1"
		}
		,A2V00002195844 : {
			de: "Adapterblech",
			en: "Adapter plate"
		}
		,A2V00002197555 : {
			de: "Hohlbuchse vollst.",
			en: "Gliding bush"
		}
		,A2V00002199180 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002201302 : {
			de: "Halter 7",
			en: "Holder 7"
		}
		,A2V00002201612 : {
			de: "Daempfer primaer vertikal",
			en: "primary vertical shock absorber"
		}
		,A2V00002202487 : {
			de: "Halter 16",
			en: "Holder 16"
		}
		,A2V00002205216 : {
			de: "Gleitblech",
			en: "Slide plate"
		}
		,A2V00002210942 : {
			de: "Halter Rahmen",
			en: "holder frame"
		}
		,A2V00002210972 : {
			de: "Halter Motor",
			en: "holder motor"
		}
		,A2V00002211032 : {
			de: "Kegelschmiernippel M10x1 A2 Form A 180Â°",
			en: "Grease nipple M10x1 A2 type A 180Â°"
		}
		,A2V00002215409 : {
			de: "Beilage f. Querpuffer 4mm",
			en: "Shim f. lateral buffer 4mm"
		}
		,A2V00002215673 : {
			de: "Halteblech LT",
			en: "retaining plate AD"
		}
		,A2V00002215689 : {
			de: "Radbremsscheibe TDG",
			en: "Wheel mounted brake disc MB"
		}
		,A2V00002216081 : {
			de: "Halter",
			en: "holder"
		}
		,A2V00002218391 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002219345 : {
			de: "Getriebe K3S mit Treibradsatzwelle",
			en: "Gearbox K3S with motor axle"
		}
		,A2V00002219792 : {
			de: "Zentrierring",
			en: "Center Ring"
		}
		,A2V00002220292 : {
			de: "Rippe Motorkonsole",
			en: "Stiffener engine bracket"
		}
		,A2V00002220297 : {
			de: "Rippe",
			en: "Stiffener"
		}
		,A2V00002220836 : {
			de: "Rippe Laengsmitnahme",
			en: "Stiffener traction link"
		}
		,A2V00002220941 : {
			de: "Steg Bremsk. aussen WG",
			en: "Web brake supp. out. AD"
		}
		,A2V00002220942 : {
			de: "Steg Bremsk. innen WG",
			en: "Web brake supp. ins. AD"
		}
		,A2V00002220943 : {
			de: "Steg Bremsk. aussen SPG",
			en: "Web brake supp. out. OAD"
		}
		,A2V00002220944 : {
			de: "Steg Bremsk. innen SPG",
			en: "Web Brake supp. ins. OAD"
		}
		,A2V00002221037 : {
			de: "Rippe Motorkonsole innen",
			en: "Stiffener engine bracket"
		}
		,A2V00002221267 : {
			de: "Rippe Motorkonsole aussen",
			en: "Outer stiffener engine bracket"
		}
		,A2V00002221464 : {
			de: "Lasche f. Notfang SPG",
			en: "Plate f. safty catch opp. as drawn"
		}
		,A2V00002221465 : {
			de: "Lasche f. Notfang WG",
			en: "Plate f. safty catch as drawn"
		}
		,A2V00002221473 : {
			de: "Bremsbefestigung",
			en: "Brake mounting"
		}
		,A2V00002221515 : {
			de: "Verbindungssteg,auss.wg.",
			en: "Connection web,outer ad."
		}
		,A2V00002221524 : {
			de: "Untergurt BK WG",
			en: "Lower flange BS AD"
		}
		,A2V00002221525 : {
			de: "Untergurt BK SPG",
			en: "Lower flange BS OAD"
		}
		,A2V00002221615 : {
			de: "Rippe fuer Motor",
			en: "Rib for motor"
		}
		,A2V00002221716 : {
			de: "Rippe f. Luftfeder spg.",
			en: "Rib f. air spring oad."
		}
		,A2V00002221717 : {
			de: "Verbindungssteg,inn.spg.",
			en: "Connection web,inner oad."
		}
		,A2V00002221718 : {
			de: "Rippe f. Luftfeder wg.",
			en: "Rib f. air spring ad."
		}
		,A2V00002221719 : {
			de: "Anhebeoese",
			en: "Lift ear"
		}
		,A2V00002221720 : {
			de: "Rippe wg.",
			en: "Rib spg."
		}
		,A2V00002221721 : {
			de: "Anschlagplatte, oben",
			en: "Limit plate, above"
		}
		,A2V00002221722 : {
			de: "Verbindungssteg,inn.wg.",
			en: "Con.web,inner ad."
		}
		,A2V00002221723 : {
			de: "Rippe wg.",
			en: "Rib ad."
		}
		,A2V00002221725 : {
			de: "Rippe wg.",
			en: "Rib ad."
		}
		,A2V00002221726 : {
			de: "Rippe spg.",
			en: "Rib oad."
		}
		,A2V00002221729 : {
			de: "Verbindungssteg,auss.spg.",
			en: "Connection web,outer oad."
		}
		,A2V00002221730 : {
			de: "Luftfederrohr",
			en: "Air spring tube"
		}
		,A2V00002221731 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002221733 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002221741 : {
			de: "Rippe fuer Daempfer",
			en: "Rib for damper"
		}
		,A2V00002221772 : {
			de: "Steg fuer Getriebe",
			en: "Web for gear"
		}
		,A2V00002222511 : {
			de: "Daempferkonsole",
			en: "damper bracket"
		}
		,A2V00002222523 : {
			de: "Steg fuer Getriebe",
			en: "Web for gear"
		}
		,A2V00002223175 : {
			de: "Abhebesicherung",
			en: "lifting device"
		}
		,A2V00002223535 : {
			de: "Flanschplatte",
			en: "Flange plate"
		}
		,A2V00201185366 : {
			de: "Kupplungshalterung re",
			en: "Coupler support right"
		}
		,A2V00002223867 : {
			de: "Anhebeoese wg.",
			en: "Lift ear ad."
		}
		,A2V00002223868 : {
			de: "Anhebeoese spg.",
			en: "Lift ear oad."
		}
		,A2V00002223892 : {
			de: "Zentrierhuelse",
			en: "Center sleeve"
		}
		,A2V00002224074 : {
			de: "Federbeilage 1mm",
			en: "Spring shim 1mm"
		}
		,A2V00002224075 : {
			de: "Federbeilage 2mm",
			en: "Spring shim 2mm"
		}
		,A2V00002224076 : {
			de: "Federbeilage 5mm",
			en: "Spring shim 5mm"
		}
		,A2V00002224077 : {
			de: "Federbeilage 10mm",
			en: "Spring shim 10mm"
		}
		,A2V00002224138 : {
			de: "Anschlagplatte, oben spg.",
			en: "Limit plate, above oad."
		}
		,A2V00002224239 : {
			de: "Traeger Abhebesicherung",
			en: "Lift lock bracket"
		}
		,A2V00002225618 : {
			de: "Grundplatte w.g",
			en: "Ground plate a.d."
		}
		,A2V00002225678 : {
			de: "Abschlussplatte",
			en: "End plate"
		}
		,A2V00002225679 : {
			de: "Formrohr, kurz",
			en: "Hollow profile, short"
		}
		,A2V00002225680 : {
			de: "Befestigungsblech sp.g.",
			en: "Mouning sheet o.a.d."
		}
		,A2V00002225681 : {
			de: "Grundplatte sp.g",
			en: "Ground plate o.a.d."
		}
		,A2V00002225682 : {
			de: "Formrohr, lang",
			en: "Hollow profile, long"
		}
		,A2V00002225683 : {
			de: "Befestigungsblech w.g.",
			en: "Mounting sheet a.d."
		}
		,A2V00002226239 : {
			de: "Blech Mittelteil",
			en: "Sheet center part"
		}
		,A2V00002227640 : {
			de: "Rippe spg.",
			en: "Rip wg."
		}
		,A2V00002227710 : {
			de: "Rippe spg.",
			en: "Rib oad."
		}
		,A2V00002227855 : {
			de: "Auffangsicherung",
			en: "Safety catch"
		}
		,A2V00002228020 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002228331 : {
			de: "Federteller Oben",
			en: "Spring plate top"
		}
		,A2V00002229204 : {
			de: "Notfangstab",
			en: "Motor safety catch"
		}
		,A2V00002229226 : {
			de: "Gewindebutzen - M12x30x25",
			en: "Thread core - M12x30x25"
		}
		,A2V00002230906 : {
			de: "Distanzblech",
			en: "Distance plate"
		}
		,A2V00002627299 : {
			de: "Fernbetaetigung 2000mm",
			en: "Emergency release device 2000mm"
		}
		,A2V00002232352 : {
			de: "Erdungslasche M8",
			en: "Ground point M8"
		}
		,A2V00002233020 : {
			de: "Abdeckblech",
			en: "Cover sheet"
		}
		,A2V00002234233 : {
			de: "Halter f. Schelle",
			en: "holder for clamp"
		}
		,A2V00002628407 : {
			de: "Montagemat. Lagergehaeuse und Druckkappe",
			en: "Mounting material axle box and end cap"
		}
		,A2V00002235246 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002236209 : {
			de: "Halter fuer Sensor",
			en: "Holder for sensor"
		}
		,A2V00002236291 : {
			de: "Halter 1 spg",
			en: "Holder 1 oad"
		}
		,A2V00002236292 : {
			de: "Halter 1 wg",
			en: "Holder 1 ad"
		}
		,A2V00002236346 : {
			de: "Halter 2 spg",
			en: "Holder 2 oad"
		}
		,A2V00002236347 : {
			de: "Halter 2 wg",
			en: "Holder 2 ad"
		}
		,A2V00002238153 : {
			de: "Halter",
			en: "holder"
		}
		,A2V00002238190 : {
			de: "Halter",
			en: "holder"
		}
		,A2V00002238859 : {
			de: "Konsole f. Luftfedersteuerung",
			en: "Bracket f. air spring leveling"
		}
		,A2V00002238902 : {
			de: "Halter Motorkonsole",
			en: "Holder motor bracket"
		}
		,A2V00002240461 : {
			de: "Konsole Notloese kurz",
			en: "Bracket emergency rel. short"
		}
		,A2V00002243710 : {
			de: "Schutzblech QT 5",
			en: "Protection plate MF 5"
		}
		,A2V00002245007 : {
			de: "Beilage f. Querpuffer 1mm",
			en: "Shim f. lateral buffer 1mm"
		}
		,A2V00002245008 : {
			de: "Beilage f. Querpuffer 2mm",
			en: "Shim f. lateral buffer 2mm"
		}
		,A2V00002245956 : {
			de: "Abdeckung lang",
			en: "cover long"
		}
		,A2V00101068539 : {
			de: "LENKSTANGE",
			en: "Guiding arm"
		}
		,A2V00002248358 : {
			de: "Polrad Z=80,D=195, B=14",
			en: "phonic wheel Z=80,D=195, B=14"
		}
		,A2V00002248360 : {
			de: "Beilage 190x90x10",
			en: "Washer 190x90x5"
		}
		,A2V00002255522 : {
			de: "Erdungslasche M10",
			en: "Ground point M10"
		}
		,A2V00002257281 : {
			de: "Blech",
			en: "plate"
		}
		,A2V00002257731 : {
			de: "Puffer",
			en: "Buffer"
		}
		,A2V00002261567 : {
			de: "Beilagscheibe",
			en: "Shim"
		}
		,A2V00002263513 : {
			de: "Blech VB Format 2x605x1250",
			en: "Sheet JD format 2x605x1250"
		}
		,A2V00002263514 : {
			de: "Blech VB Format 2,99x605x1250",
			en: "Sheet JD format 2,99x605x1250"
		}
		,A2V00002263515 : {
			de: "Blech VB Format 2x605x1250",
			en: "Sheet JD format 2x605x1250"
		}
		,A2V00002263606 : {
			de: "Blech VB Format 3x605x1250",
			en: "Sheet JD format 3x605x1250"
		}
		,A2V00002264448 : {
			de: "Erdungskontakt",
			en: "Earthing contact"
		}
		,A2V00002265526 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002269517 : {
			de: "Haltelasche",
			en: "Retaining bracket"
		}
		,A2V00002269586 : {
			de: "Schutzblech Verrohrung",
			en: "Guard plate piping"
		}
		,A2V00002269603 : {
			de: "Halteblech",
			en: "Retaining plate"
		}
		,A2V00002270137 : {
			de: "Halteblech spg.",
			en: "Retaining plate oad."
		}
		,A2V00002273849 : {
			de: "Luftfederrohr Roh",
			en: "Air spring tube raw"
		}
		,A2V00002275460 : {
			de: "RS-Lagergehaeuse TDG WG lackiert",
			en: "Axle box MB AD painted"
		}
		,A2V00002275461 : {
			de: "RS-Lagergehaeuse TDG SPG lackiert",
			en: "Axle box MB OAD painted"
		}
		,A2V00002275462 : {
			de: "RS-Lagergehaeuse LDG WG lackiert",
			en: "Axle box TB AD painted"
		}
		,A2V00002275463 : {
			de: "RS-Lagergehaeuse LDG SPG lackiert",
			en: "Axle box TB OAD painted"
		}
		,A2V00002277093 : {
			de: "Zusatzschild Messing",
			en: "Additional sign brass"
		}
		,A2V00002278448 : {
			de: "Bolzen",
			en: "Pin"
		}
		,A2V00002278739 : {
			de: "Blech Abhebesicherung - ROH",
			en: "Lift lock plate - Raw"
		}
		,A2V00002280301 : {
			de: "Platte Roh",
			en: "Plate raw"
		}
		,A2V00002312841 : {
			de: "Halter EK2",
			en: "Holder gk2"
		}
		,A2V00002317624 : {
			de: "Halter EK1",
			en: "Holder gk1"
		}
		,A2V00002317937 : {
			de: "Montagematerial RSL-Deckel",
			en: "Mounting material axle box front cover"
		}
		,A2V00002317941 : {
			de: "RS-Lagerdeckel hinten, lackiert",
			en: "Axle box back cover, painted"
		}
		,A2V00002318522 : {
			de: "Halter EK3",
			en: "Holder gk3"
		}
		,A2V00207311711 : {
			de: "Antennenhalter LZB",
			en: "antenna holder LZB"
		}
		,A2V00001162975 : {
			de: "Wechselrichter 1x 240V AC/50Hz",
			en: "Inverter for 1x 240V AC/50Hz"
		}
		,A2V00002326101 : {
			de: "RS-Lagerdeckel Erdungskontakt, lackiert",
			en: "ws front cover grounding device, painted"
		}
		,A2V00002326102 : {
			de: "RS-Lagerdeck. Gleitschutz. re., lackiert",
			en: "ws frontcover slide prot. ri., painted"
		}
		,A2V00002326104 : {
			de: "RS-Lagerdeckel ohne Belegung, lackiert",
			en: "ws frontcover without configur., painted"
		}
		,A2V00002327955 : {
			de: "Befestigungsblech",
			en: "mounting sheet"
		}
		,A2V00002331572 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002335477 : {
			de: "Halter MG wg",
			en: "Holder mb ad"
		}
		,A2V00002335478 : {
			de: "Halter MG spg",
			en: "Holder mb oad"
		}
		,A2V00002335546 : {
			de: "Halter MG Kabel",
			en: "Bracket mb cable"
		}
		,A2V00002336550 : {
			de: "Bolzen ISO 13918 - PD M8x75 - A4-50",
			en: "Stud ISO 13918 - PD M8x75 - A4-50"
		}
		,A2V00002337401 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002338016 : {
			de: "RS-Lagerdeck. Gleitschutz. li., lackiert",
			en: "ws frontcover slide protec. le., painted"
		}
		,A2V00002338054 : {
			de: "Blech fÃ¼r MG",
			en: "Plate for mg"
		}
		,A2V00002338494 : {
			de: "Halter Kabelabgang",
			en: "Bracket cabling"
		}
		,A2V00002338764 : {
			de: "Halter Indusi",
			en: "Bracket Indusi"
		}
		,A2V00002338827 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002338828 : {
			de: "Abgangshalter",
			en: "Bracket cabling end"
		}
		,A2V00002339700 : {
			de: "Schutzrohr",
			en: "Safetypipe"
		}
		,A2V00002342186 : {
			de: "RSL-Deckel rechts",
			en: "axle box cover right"
		}
		,A2V00002342187 : {
			de: "RSL-Deckel links",
			en: "axle box cover left"
		}
		,A2V00002346724 : {
			de: "Halter Klemmenkasten",
			en: "Holder clamping box"
		}
		,A2V00002355614 : {
			de: "Schellenhalter sp.g.",
			en: "Clamp bracket o.a.d."
		}
		,A2V00002359160 : {
			de: "Radsatzanlenkung",
			en: "Wheelset steering"
		}
		,A2V00002359350 : {
			de: "Rippe 1",
			en: "Rib 1"
		}
		,A2V00002359351 : {
			de: "Rippe 2",
			en: "Rib 2"
		}
		,A2V00002359352 : {
			de: "Rippe 3",
			en: "Rib 3"
		}
		,A2V00002359353 : {
			de: "Rippe 4 WG",
			en: "Rib 4 AD"
		}
		,A2V00002359354 : {
			de: "Rippe 6 WG",
			en: "Rib 6 AD"
		}
		,A2V00002359355 : {
			de: "Rippe 4 SPG",
			en: "Rib 4 OAD"
		}
		,A2V00002359457 : {
			de: "Rippe 8 WG",
			en: "Rib 8 AD"
		}
		,A2V00002359477 : {
			de: "Rippe 5 WG",
			en: "Rib 5 AD"
		}
		,A2V00002359480 : {
			de: "Rippe 7 WG",
			en: "Rib 7 AD"
		}
		,A2V00002363204 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002365472 : {
			de: "Schellenhalter",
			en: "Clamp bracket"
		}
		,A2V00002365750 : {
			de: "Untergurt",
			en: "Bottom flange"
		}
		,A2V00002367920 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002369512 : {
			de: "Winkel Eurobalise",
			en: "Angle Eurobalise"
		}
		,A2V00002369826 : {
			de: "Halter Schelle",
			en: "Bracket clamp"
		}
		,A2V00002367344 : {
			de: "Horn kompl. Rechts",
			en: "Horn compl. right"
		}
		,A2V00002367343 : {
			de: "Horn kompl. links",
			en: "Horn compl. left"
		}
		,A2V00002466834 : {
			de: "Ersatzteil - Vollrad, lackiert",
			en: "Spare part - Solid wheel, painted"
		}
		,A2V00002370931 : {
			de: "Daempferkonsole - Rohteil",
			en: "Damper bracket - raw material"
		}
		,A2V00002373580 : {
			de: "Halter HAN3 w.g. TDG",
			en: "Bracket HAN 3 a.d. mb"
		}
		,A2V00002373586 : {
			de: "Halter HAN3 sp.g. TDG",
			en: "Bracket HAN3 o.a.d. mb"
		}
		,A2V00002376233 : {
			de: "Halter HAN3 2 LDG w.g.",
			en: "Bracket HAN3 2 tb a.d."
		}
		,A2V00002376330 : {
			de: "Halter HAN3 1 LDG sp.g.",
			en: "Bracket HAN3 1 tb o.a.d."
		}
		,A2V00002376357 : {
			de: "Halter HAN3 2 LDG sp.g.",
			en: "Bracket HAN3 2 tb o.a.d."
		}
		,A2V00002376376 : {
			de: "Halter HAN3 1 LDG w.g.",
			en: "Bracket HAN3 1 tb a.d."
		}
		,A2V00002380049 : {
			de: "Halter f. Kabelschellen",
			en: "Bracket f. cable clips"
		}
		,A2V00002381750 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002381977 : {
			de: "Halterblech",
			en: "Holder plate"
		}
		,A2V00002382247 : {
			de: "Lagerdeckel lackiert",
			en: "Bearing cover painted"
		}
		,A2V00002382884 : {
			de: "Fahrmotordaempfer",
			en: "damper, motor unit"
		}
		,A2V00002382938 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical shock absorber"
		}
		,A2V00002386128 : {
			de: "Montagematerial fuer RSL-Deckel, hinten",
			en: "Mountingmaterial for axle box cover,rear"
		}
		,A2V00002386131 : {
			de: "Montagematerial fuer RSL-Deckel",
			en: "Mounting material for axle box cover"
		}
		,A2V00002387647 : {
			de: "Rippe",
			en: "Stiffener"
		}
		,A2V00002387648 : {
			de: "Steg 2",
			en: "Web 2"
		}
		,A2V00002387998 : {
			de: "Steg 2 WG",
			en: "Web 2 AD"
		}
		,A2V00002388019 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002388020 : {
			de: "Schlingerdaempferkonsole",
			en: "Yaw damper support"
		}
		,A2V00002388022 : {
			de: "Rippe 9",
			en: "Rib 9"
		}
		,A2V00002388023 : {
			de: "Rippe 10",
			en: "Rib 10"
		}
		,A2V00002388025 : {
			de: "Rippe 8",
			en: "Rib 8"
		}
		,A2V00002388076 : {
			de: "Steg 2 SPG",
			en: "Web 2 OAD"
		}
		,A2V00002388078 : {
			de: "Federauflage",
			en: "Suspension support"
		}
		,A2V00002388079 : {
			de: "Federauflage 2",
			en: "Suspension support 2"
		}
		,A2V00002388096 : {
			de: "Rippe 3",
			en: "Rib 3"
		}
		,A2V00002388097 : {
			de: "Rippe 11",
			en: "Stiffener 11"
		}
		,A2V00002388098 : {
			de: "Rippe 6",
			en: "Rib 6"
		}
		,A2V00002388099 : {
			de: "Rippe 7",
			en: "Rib 7"
		}
		,A2V00002388100 : {
			de: "Sekundaerdaempferkonsole",
			en: "Secundary damper bracket"
		}
		,A2V00002388101 : {
			de: "Rippe 1",
			en: "Rib 1"
		}
		,A2V00002388102 : {
			de: "Rippe 4",
			en: "Rib 4"
		}
		,A2V00002389678 : {
			de: "Halter MG Verteiler",
			en: "Bracket EM distributor"
		}
		,A2V00002390610 : {
			de: "Flanschplatte WG",
			en: "Connection plate AD"
		}
		,A2V00002390630 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002390631 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002390673 : {
			de: "Rippe 2",
			en: "Rib 2"
		}
		,A2V00002391310 : {
			de: "Polrad",
			en: "Pole wheel"
		}
		,A2V00002391521 : {
			de: "Schweissbadsicherung 2",
			en: "Weld backing 2"
		}
		,A2V00002392111 : {
			de: "Bremszangeneinheit RZS50K27X140",
			en: "Brake caliper unit RZS50K27X140"
		}
		,A2V00002392112 : {
			de: "FernbetÃ¤tigung NotlÃ¶sezug kurz",
			en: "Rem. contr. f. emerg. release dev. short"
		}
		,A2V00002393411 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002393957 : {
			de: "Querdaempferkonsole",
			en: "Lateral damper bracket"
		}
		,A2V00002393959 : {
			de: "Schlingerdaempferkonsole",
			en: "Yaw damper support"
		}
		,A2V00002393960 : {
			de: "Rippe 1",
			en: "Rib 1"
		}
		,A2V00002393961 : {
			de: "Untergurt",
			en: "Lower flange"
		}
		,A2V00002393962 : {
			de: "Rippe 3",
			en: "Rib 3"
		}
		,A2V00002393963 : {
			de: "Schweissbadsicherung 1",
			en: "Weld backing 1"
		}
		,A2V00002393964 : {
			de: "Putzklotzkonsole",
			en: "Bracket f. cleaning device"
		}
		,A2V00002393965 : {
			de: "Obergurt",
			en: "Upper flange"
		}
		,A2V00002147987 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002393966 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00000216902 : {
			de: "Bremswirkgruppe fÃ¼r I60-R",
			en: "brakes actuation group for  I60-R"
		}
		,A2V00002393967 : {
			de: "Deckel WG.",
			en: "Cap AD."
		}
		,A2V00002393968 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002393969 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002393972 : {
			de: "Steg 2",
			en: "Web 2"
		}
		,A2V00002393974 : {
			de: "Rippe 7",
			en: "Rib 7"
		}
		,A2V00002393975 : {
			de: "Motoranbindung WG.",
			en: "Motor connection AD."
		}
		,A2V00002393976 : {
			de: "Rippe 3",
			en: "Rib 3"
		}
		,A2V00002393977 : {
			de: "Sekundaerfederrippe",
			en: "Rib for sec.spring"
		}
		,A2V00002393978 : {
			de: "Schweissbadsicherung 2",
			en: "Weld backing 2"
		}
		,A2V00002393979 : {
			de: "Einsatzbutzen",
			en: "Insert button"
		}
		,A2V00002393980 : {
			de: "Rippe 8",
			en: "Rib 6"
		}
		,A2V00002393981 : {
			de: "Motoranbindung SPG.",
			en: "Motor connection OAD."
		}
		,A2V00002393982 : {
			de: "Untergurt",
			en: "Lower flange"
		}
		,A2V00002393983 : {
			de: "Rippe 2",
			en: "Rib 2"
		}
		,A2V00002393985 : {
			de: "Queranschlag",
			en: "Cross catch"
		}
		,A2V00002393986 : {
			de: "Motordaempferkonsole",
			en: "Bracket f. motor damper"
		}
		,A2V00002393987 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002393988 : {
			de: "Rippe 4",
			en: "Rib 4"
		}
		,A2V00002393989 : {
			de: "Lenkeranschluss",
			en: "Guide rod connection"
		}
		,A2V00002393990 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002393992 : {
			de: "Obergurt",
			en: "Upper flange"
		}
		,A2V00002393993 : {
			de: "Deckel SPG.",
			en: "Cap OAD."
		}
		,A2V00002393994 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002393995 : {
			de: "Schweissbadsicherung 1",
			en: "Weld backing 1"
		}
		,A2V00002393996 : {
			de: "Rippe 6",
			en: "Rib 6"
		}
		,A2V00002393997 : {
			de: "Steg innen",
			en: "Web inside"
		}
		,A2V00002393998 : {
			de: "Steg aussen",
			en: "Web outside"
		}
		,A2V00002393999 : {
			de: "Schweissbadsicherung 1",
			en: "Weld backing 1"
		}
		,A2V00002394809 : {
			de: "Sekundaerfederzentrierung",
			en: "Cent. secondary spring"
		}
		,A2V00002395415 : {
			de: "Schweissbadsicherung 1",
			en: "Weld backing 1"
		}
		,A2V00002395603 : {
			de: "Schraubendruckfeder prim.",
			en: "Helical compression spring prim."
		}
		,A2V00002395604 : {
			de: "Schraubendruckfeder sek.",
			en: "Helical compression spring"
		}
		,A2V00002396048 : {
			de: "Rippe f. Stuetze SPG",
			en: "Rib f. support OAD"
		}
		,A2V00002396049 : {
			de: "Flansch f. Stuetze",
			en: "Flange f. support"
		}
		,A2V00002396050 : {
			de: "StÃ¼tze Steg",
			en: "Motor column web"
		}
		,A2V00002396052 : {
			de: "Flansch",
			en: "Flange"
		}
		,A2V00002396053 : {
			de: "Untergurt",
			en: "Lower flange"
		}
		,A2V00002396054 : {
			de: "Rippe f. Stuetze WG",
			en: "Rib f. support AD"
		}
		,A2V00002396126 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002396127 : {
			de: "Querstrebe",
			en: "Cross-beam"
		}
		,A2V00002396128 : {
			de: "Querstrebe",
			en: "Cross-beam"
		}
		,A2V00002396130 : {
			de: "Abstuetzrippe",
			en: "Rib f. spring plate"
		}
		,A2V00002396131 : {
			de: "Anbindung SPG",
			en: "Bracket OAD"
		}
		,A2V00002396132 : {
			de: "Anbindung WG",
			en: "Bracket AD"
		}
		,A2V00002396133 : {
			de: "Steg, gebogen",
			en: "Web, bended"
		}
		,A2V00002396134 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00002402421 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002403973 : {
			de: "Bremszangeneinheit 40Â° Abgang FSP",
			en: "Brake caliper 40Â° connection park brake"
		}
		,A2V00002404074 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002404689 : {
			de: "Rippe",
			en: "Stiffener"
		}
		,A2V00370073982 : {
			de: "HANDTUCHHALTER B-MOD,VOLLST.",
			en: "towel rail B-module, complete"
		}
		,A2V00370046905 : {
			de: "EPOXY 51 CA.RAL3012          HO20-002050",
			en: "EPOXY 51 CA.RAL3012          HO20-002050"
		}
		,A2V00150267861 : {
			de: "DECKEL",
			en: "top cover"
		}
		,A2V00002405696 : {
			de: "Schweissbadsicherung 1",
			en: "Weld backing 1"
		}
		,A2V00002407343 : {
			de: "Flanschplatte SPG",
			en: "Connection plate OAD"
		}
		,A2V00002410856 : {
			de: "Schellenhalter 2",
			en: "Clip collar bracket 2"
		}
		,A2V00002411027 : {
			de: "Standard Halter F 50",
			en: "Standard Bracket F 50"
		}
		,A2V00002411028 : {
			de: "Standard Halter F 70",
			en: "Standard Bracket F 70"
		}
		,A2V00002411029 : {
			de: "Standard Halter F 90",
			en: "Standard Bracket F 90"
		}
		,A2V00002412414 : {
			de: "Erdungslasche",
			en: "Grounding strip"
		}
		,A2V00002412491 : {
			de: "Flansch w.g.",
			en: "Flange a.d."
		}
		,A2V00002412495 : {
			de: "Flansch sp.g.",
			en: "Flange o.a.d."
		}
		,A2V00002413147 : {
			de: "Schienenraeumerblech w.g",
			en: "rail guard sheet a.d."
		}
		,A2V00002413148 : {
			de: "Schienenraeumerblech sp.g",
			en: "rail guard sheet o.a.d."
		}
		,A2V00002413215 : {
			de: "Abhebebuegel TDG",
			en: "Lifting stop MB"
		}
		,A2V00002413494 : {
			de: "Grundplatte",
			en: "Ground plate"
		}
		,A2V00002418458 : {
			de: "Halter 2 w.g.",
			en: "Bracket 2 a.d."
		}
		,A2V00002419353 : {
			de: "Adapter fuer Erdungskontakt",
			en: "Adapter for ground contact"
		}
		,A2V00002419657 : {
			de: "Halter 2",
			en: "Holder 2"
		}
		,A2V00002419696 : {
			de: "Primaerfederzentrierung klein",
			en: "Primary spring aligment small"
		}
		,A2V00002419697 : {
			de: "PrimÃ¤rfederzentrierung groÃŸ",
			en: "Primary spring centering large"
		}
		,A2V00002423929 : {
			de: "Grundplatte w.g.",
			en: "base plate a.d."
		}
		,A2V00002423930 : {
			de: "Grundplatte sp.g.",
			en: "Base plate o.a.d."
		}
		,A2V00001856574 : {
			de: "TGB Anschlusskassette",
			en: "TGB cartridge"
		}
		,A2V00002428828 : {
			de: "Halter 6",
			en: "Bracket 6"
		}
		,A2V00002432663 : {
			de: "Halter Schelle",
			en: "bracket clamp"
		}
		,A2V00002434763 : {
			de: "Laufradsatzwelle",
			en: "Trailer axle"
		}
		,A2V00002436725 : {
			de: "Rippe f. FMT Anschlag",
			en: "Rib f. eng. support stop"
		}
		,A2V00002437104 : {
			de: "Blech 2",
			en: "plate 2"
		}
		,A2V00002437396 : {
			de: "Konsole f. Fmt Anschlag",
			en: "bracket f. eng. support"
		}
		,A2V00002437397 : {
			de: "Konsole f. Fmt Anschlag",
			en: "bracket f. eng. stop"
		}
		,A2V00002437547 : {
			de: "Blech",
			en: "plate"
		}
		,A2V00002437763 : {
			de: "Befestigungsblech sp.g.",
			en: "Mounting sheet o.a.d."
		}
		,A2V00002437802 : {
			de: "Befestigungsblech w.g.",
			en: "Mounting sheet a.d."
		}
		,A2V00002438076 : {
			de: "Abschlussblech",
			en: "sheet life guard"
		}
		,A2V00002440403 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002457169 : {
			de: "Anlaufblech",
			en: "runoff tap"
		}
		,A2V00002459154 : {
			de: "Bremsbelag IR P16",
			en: "axle mount. brake calliper wo. park. br."
		}
		,A2V00002469669 : {
			de: "Wellenbremsscheibe WKS640",
			en: "Axle mounted Brake Disc  WKS640"
		}
		,A2V00002469670 : {
			de: "Bremszange mit Bremszylinder C3 SB 9",
			en: "Brake caliper with ser. brake C3 SB 9"
		}
		,A2V00002469671 : {
			de: "Bremszange mit Bremszylinder C3 SB 9",
			en: "Brake caliper with ser. brake C3 SB 9"
		}
		,A2V00002469672 : {
			de: "Bremszange mit Bremszylinder C3 PB 8/8",
			en: "Brake caliper with ser. brake C3 PB 8/8"
		}
		,A2V00002469673 : {
			de: "Bremszange mit Bremszylinder C3 PB 8/8",
			en: "Brake caliper service brake C3 PB 8/8"
		}
		,A2V00002470124 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002470166 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002505451 : {
			de: "Zylinderschraube",
			en: "Hexagon socket head cap screw"
		}
		,A2V00002509149 : {
			de: "Montageplatte Messgeraet",
			en: "Mounting board acquisition unit"
		}
		,A2V00002513396 : {
			de: "Drahtelektrode 1.0 - alform - Rolle 18kg",
			en: "Solid wire 1.0 - alform - reel 18Kg"
		}
		,A2V00002513631 : {
			de: "Konusfeder Avenio 110mm Scheibe",
			en: "conical spring Avenio 110mm disk"
		}
		,A2V00002515357 : {
			de: "Treibradsatzwelle lack.",
			en: "Motor axle painted"
		}
		,A2V00002516507 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00001975444 : {
			de: "Maschinenraumventilator",
			en: "Machine room ventilator"
		}
		,A2V00002597781 : {
			de: "Delimon SprÃ¼hdÃ¼se",
			en: "Delimon spray nozzle"
		}
		,A2V00002398971 : {
			de: "Lenkstange kompl. RAL3000",
			en: "Collector head guide compl. RAL3000"
		}
		,A2V00002533933 : {
			de: "Langtr. Profil F-Ende",
			en: "Longit. beam dc-end"
		}
		,A2V00002329778 : {
			de: "Schuerze lackiert",
			en: "shroud lacquered"
		}
		,A2V00002329777 : {
			de: "Schuerze lackiert",
			en: "shroud lacquered"
		}
		,A2V00002516965 : {
			de: "Halter f. Erdung",
			en: "Holder f. ground contact"
		}
		,A2V00002517751 : {
			de: "Drehzahlgeber GEL 2475DI - 2 KanÃ¤le",
			en: "Speed sensor GEL 2475DI - 2 signals"
		}
		,A2V00002524089 : {
			de: "Drehzapfenlager",
			en: "Centre pivot"
		}
		,A2V00002526865 : {
			de: "Scheibe",
			en: "Washer"
		}
		,A2V00002528651 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002528774 : {
			de: "Fahrmotor 1TB1808-0GA02",
			en: "Traction Motor 1TB1808-0GA02"
		}
		,A2V00002529336 : {
			de: "TRSW mit Getriebe",
			en: "MWSA with gearbox"
		}
		,A2V00002534465 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002538808 : {
			de: "RSL Waelzlager CRU150x250 SKF",
			en: "AB rolling bearing CRU150x250 SKF"
		}
		,A2V00002538997 : {
			de: "Treibradsatzwelle, lackiert",
			en: "Motor wheelset axle, painted"
		}
		,A2V00002540439 : {
			de: "Federspeicherbremssattel HYS 106",
			en: "Spring applied brake caliper HYS 106"
		}
		,A2V00002542363 : {
			de: "Montageplatte WK-Geraet",
			en: "Mounting board CB device"
		}
		,A2V00002543237 : {
			de: "RSL Waelzlager TBU150x250 Timken",
			en: "AB rolling bearing TBU150x250 Timken"
		}
		,A2V00002543333 : {
			de: "Verschlussdeckel EK spg.",
			en: "cover plate EC oad."
		}
		,A2V00002543334 : {
			de: "Verschlussdeckel WIG",
			en: "cover plate DPG"
		}
		,A2V00002543335 : {
			de: "Verschlussdeckel EK wg.",
			en: "cover plate EC ad."
		}
		,A2V00002543458 : {
			de: "Verschlussdeckel",
			en: "cover plate"
		}
		,A2V00002544470 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002544496 : {
			de: "Abschlussscheibe fÃ¼r WIG Hasler rh",
			en: "Closure disc for WIG Hasler rh"
		}
		,A2V00002544497 : {
			de: "Abschlussscheibe fÃ¼r WIG Hasler lh",
			en: "Closure disc for WIG Hasler lh"
		}
		,A2V00002544821 : {
			de: "Stift",
			en: "Bolt"
		}
		,A2V00002544887 : {
			de: "FÃ¼hrungsstÃ¼ck",
			en: "Guid Part"
		}
		,A2V00002544897 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00002545931 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002550032 : {
			de: "Kombikonsole TDG SPG",
			en: "Combined console MB OAD"
		}
		,A2V00002550033 : {
			de: "Kombikonsole TDG WG",
			en: "Combined console MB AD"
		}
		,A2V00002550156 : {
			de: "Kombikonsole LDG WG",
			en: "Combined console TB AD"
		}
		,A2V00002550176 : {
			de: "Kombikonsole LDG SPG",
			en: "Combined console TB OAD"
		}
		,A2V00002551883 : {
			de: "Radschallabsorber, stirnseite voll.",
			en: "Wheel noise absorber, front side compl."
		}
		,A2V00002553265 : {
			de: "Beilage 1mm",
			en: "Shim 1mm"
		}
		,A2V00002553286 : {
			de: "Beilage 2mm",
			en: "Shim 2mm"
		}
		,A2V00002558586 : {
			de: "Radsatzlagergehaeuse lackiert",
			en: "Wheelset bearing housing painted"
		}
		,A2V00002558752 : {
			de: "Halter 3",
			en: "Holder 3"
		}
		,A2V00002558776 : {
			de: "Halter 3",
			en: "Holder 3"
		}
		,A2V00002558784 : {
			de: "Halter 1",
			en: "Holder 1"
		}
		,A2V00002558818 : {
			de: "Halter 2",
			en: "Holder 2"
		}
		,A2V00002558825 : {
			de: "Halter 2",
			en: "Holder 2"
		}
		,A2V00002558829 : {
			de: "Halter 5",
			en: "Holder 5"
		}
		,A2V00002558844 : {
			de: "Halter 2 spg",
			en: "Holder 2 oad"
		}
		,A2V00002558845 : {
			de: "Halter 5",
			en: "Holder 5"
		}
		,A2V00002559006 : {
			de: "Halter 2 wg",
			en: "Holder 2 ad"
		}
		,A2V00002559007 : {
			de: "Halter 1",
			en: "Holder 1"
		}
		,A2V00002559009 : {
			de: "Halter Radsatzlagergeh.",
			en: "Holder wheelset housing"
		}
		,A2V00002559047 : {
			de: "Radschutzkasten",
			en: "wheel guard"
		}
		,A2V00002559146 : {
			de: "Halter 2",
			en: "Holder 2"
		}
		,A2V00002559148 : {
			de: "Halter 1",
			en: "Holder 1"
		}
		,A2V00002560330 : {
			de: "Halter Sandung",
			en: "Holder sander"
		}
		,A2V00002560395 : {
			de: "Halter Indusi",
			en: "Holder Indusi"
		}
		,A2V00002560478 : {
			de: "Halter 1",
			en: "holder 1"
		}
		,A2V00002560587 : {
			de: "Halter WIG",
			en: "Holder WIG"
		}
		,A2V00002562135 : {
			de: "Halter 2 wg.",
			en: "Holder 2 ad."
		}
		,A2V00002562226 : {
			de: "Halter 2 spg.",
			en: "Holder 2 oad."
		}
		,A2V00001167816 : {
			de: "Getriebegehaeuse",
			en: "Gearbox housing"
		}
		,A2V00002562674 : {
			de: "Halter 4",
			en: "Holder 4"
		}
		,A2V00002563873 : {
			de: "Halter 4",
			en: "Holder 4"
		}
		,A2V00002565195 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002565897 : {
			de: "Schutzmanschette",
			en: "Protection seal"
		}
		,A2V00002565961 : {
			de: "Kennzeichnungsblech spg",
			en: "Label sheet oad"
		}
		,A2V00002565962 : {
			de: "Kennzeichnungsblech wg",
			en: "Label sheet ad"
		}
		,A2V00002566074 : {
			de: "Verschlussdeckel Sensor",
			en: "Cover plate sensor"
		}
		,A2V00002567125 : {
			de: "Rippe 3",
			en: "Rib 3"
		}
		,A2V00002567276 : {
			de: "Rippe 4",
			en: "Rip 3"
		}
		,A2V00002567407 : {
			de: "Unterlegplatte",
			en: "Shim Plate"
		}
		,A2V00002568044 : {
			de: "Querpufferadapter LDG",
			en: "Lateral buffer adapter TB"
		}
		,A2V00002568045 : {
			de: "Querpufferadapter TDG",
			en: "Lateral buffer adapter MB"
		}
		,A2V00002568047 : {
			de: "Beilage 5mm",
			en: "Shim 5mm"
		}
		,A2V00002568343 : {
			de: "Knotenstueck WG fuer Abhebesicherung",
			en: "Joint unit AD for lifting stop"
		}
		,A2V00002568344 : {
			de: "Knotenstueck SPG fuer Abhebesicherung",
			en: "Joint unit OAD for lifting stop"
		}
		,A2V00002569417 : {
			de: "Halteblech",
			en: "Holder sheet"
		}
		,A2V00002570704 : {
			de: "Beilage Luftfeder 35mm",
			en: "Shim air spring 35mm"
		}
		,A2V00002571671 : {
			de: "Knotenstueck WG fuer Daempfer",
			en: "Joint unit AD for damper"
		}
		,A2V00002572095 : {
			de: "Luftfedersystem NÃ¼rnberg",
			en: "air spring system NÃ¼rnberg"
		}
		,A2V00002572441 : {
			de: "Blech 1; Rohr- Kabeltraeger TDG1/WE1",
			en: "Plate 1; pipe- cable support MB1/CE1"
		}
		,A2V00002573205 : {
			de: "Knotenstueck SPG fuer LF-Ventil",
			en: "Joint unit OAD for AS-valve"
		}
		,A2V00002573212 : {
			de: "Beilage 01",
			en: "Shim 01"
		}
		,A2V00002573536 : {
			de: "HÃ¼lse",
			en: "Sleeve"
		}
		,A2V00002573981 : {
			de: "Halter f. Rohr; gerade",
			en: "Holder f. pipe; flat"
		}
		,A2V00002574006 : {
			de: "Halter f. Rohr; gebogen",
			en: "Holder f. pipe; bended"
		}
		,A2V00002574051 : {
			de: "Lasche WG",
			en: "Mounting link AD"
		}
		,A2V00002574590 : {
			de: "Lasche SPG",
			en: "Mounting link OAD"
		}
		,A2V00002575573 : {
			de: "P-Distanzhalter",
			en: "P-Spacer"
		}
		,A2V00002575628 : {
			de: "RS Lagerdeckel o. Belegung lackiert",
			en: "AB Cover w/o configuration painted"
		}
		,A2V00002575631 : {
			de: "RS Lagerdeckel f. Gleitschutzgeber lack.",
			en: "AB Cover for slip sensor painted"
		}
		,A2V00002576157 : {
			de: "Querblech",
			en: "Cross plate"
		}
		,A2V00002576836 : {
			de: "Stuetzring",
			en: "Backing ring"
		}
		,A2V00002578349 : {
			de: "PrÃ¼fteil 6x125x300",
			en: "PrÃ¼fteil 6x125x300"
		}
		,A2V00002578926 : {
			de: "Halter Schelle WG",
			en: "Holder clamp AD"
		}
		,A2V00002578927 : {
			de: "Halter Schelle SPG",
			en: "Holder clamp OAD"
		}
		,A2V00002574060 : {
			de: "Hilfsfahrschalter Avenio",
			en: "Auxiliary driving controller Avenio"
		}
		,A2V00207353200 : {
			de: "Wellschlauchleitung -",
			en: "corrugated hose -"
		}
		,A2V00001855106 : {
			de: "MAK-Schichtfeder",
			en: "Layer spring"
		}
		,A2V00002580691 : {
			de: "Beilage 10mm",
			en: "Shim 10mm"
		}
		,A2V00002580692 : {
			de: "Beilage 1mm",
			en: "Shim 1mm"
		}
		,A2V00002580693 : {
			de: "Beilage 2mm",
			en: "Shim 2mm"
		}
		,A2V00002580694 : {
			de: "Beilage 5mm",
			en: "Shim 5mm"
		}
		,A2V00002581469 : {
			de: "Beilage 5mm",
			en: "Shim 5mm"
		}
		,A2V00002582627 : {
			de: "Kabelschutzrohr gerade",
			en: "cable conduit straight"
		}
		,A2V00002582628 : {
			de: "Kabelschutzrohr gerade",
			en: "cable conduit straight"
		}
		,A2V00002582950 : {
			de: "Radschutzhalter",
			en: "wheel guard holder"
		}
		,A2V00002582951 : {
			de: "Radschutzhalter",
			en: "wheel guard holder"
		}
		,A2V00002583332 : {
			de: "Beilage 10mm",
			en: "Shim 10mm"
		}
		,A2V00002583906 : {
			de: "Deckplatte",
			en: "Cover plate"
		}
		,A2V00002584397 : {
			de: "Rippe 2 Kabeltraeger",
			en: "Rib 2 cable beam"
		}
		,A2V00002584398 : {
			de: "Rippe 1 Kabeltraeger",
			en: "Rib 1 cable beam"
		}
		,A2V00002585351 : {
			de: "SchienenrÃ¤umer",
			en: "life guard"
		}
		,A2V00002586441 : {
			de: "RSL-Deckel, radiale Geber lackiert",
			en: "WSB-cover, for 2 sensors laquered"
		}
		,A2V00002586598 : {
			de: "Halter 2",
			en: "Holder 2"
		}
		,A2V00002587173 : {
			de: "Wellenbremsscheibe 610/322X110",
			en: "Axle m. brake disc 610/322x110"
		}
		,A2V00002587863 : {
			de: "Drehzahlgeber GEL 2475Y112",
			en: "Speed sensor GEL 2475Y112"
		}
		,A2V00002587913 : {
			de: "Abhebesicherung",
			en: "Lifting stop"
		}
		,A2V00002591905 : {
			de: "Seil vollst.",
			en: "Rope complete"
		}
		,A2V00002592243 : {
			de: "Einstellbeilage 4mm",
			en: "Adjustment shim 4mm"
		}
		,A2V00002592393 : {
			de: "Gewindebolzen",
			en: "Stud bolt"
		}
		,A2V00002592973 : {
			de: "Daempfer sekundaer vertikal",
			en: "Secondary vertical shock absorber"
		}
		,A2V00002592974 : {
			de: "Daempfer sekundaer horizontal",
			en: "Secondary lateral shock absorber"
		}
		,A2V00002593252 : {
			de: "Abhebekonsole",
			en: "Lifting stop"
		}
		,A2V00002593873 : {
			de: "Gewindebolzen",
			en: "Stud bolt"
		}
		,A2V00002594362 : {
			de: "Halter 3",
			en: "Holder 3"
		}
		,A2V00002594363 : {
			de: "Halter 1",
			en: "Holder 1"
		}
		,A2V00002594364 : {
			de: "Halter 2",
			en: "Holder 2"
		}
		,A2V00002594389 : {
			de: "Halter",
			en: "holder"
		}
		,A2V00002594558 : {
			de: "Montagematerial Lageranbauten",
			en: "Mounting material bearing assembly"
		}
		,A2V00002595907 : {
			de: "Rippe 1 Quertraeger",
			en: "Rib 1 cross beam"
		}
		,A2V00002596012 : {
			de: "Rippe 2 Quertraeger",
			en: "Rib 2 cross beam"
		}
		,A2V00002597654 : {
			de: "Abhebebuegel Sofia",
			en: "Lifting stop Sofia"
		}
		,A2V00002597670 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002597671 : {
			de: "Sechskantmutter",
			en: "Hexagon nut"
		}
		,A2V00002598158 : {
			de: "Rippe 3 Quertraeger",
			en: "Rib 3 cross beam"
		}
		,A2V00002598420 : {
			de: "Kloben",
			en: "Block"
		}
		,A2V00002598791 : {
			de: "Dehnhuelse",
			en: "Extension sleeve"
		}
		,A2V00002598891 : {
			de: "Schienenraeumer",
			en: "Rail guard"
		}
		,A2V00002599602 : {
			de: "Rippe 4 Quertraeger",
			en: "Rib 4 cross beam"
		}
		,A2V00002599631 : {
			de: "Dehnschraube RSF-Buchse, beschichtet.",
			en: "Bolt with waisted shank, coated"
		}
		,A2V00002599633 : {
			de: "Dehnschraube Abhebesicherung",
			en: "Extension bolt Anti lifting device"
		}
		,A2V00002601047 : {
			de: "WIG-SchweiÃŸstab 2.4, alform Karton 15kg",
			en: "WIG-Welding Rod 2.4, alform Carton 15Kg"
		}
		,A2V00002602557 : {
			de: "Kabelhalter",
			en: "Bracket for cable"
		}
		,A2V00002603072 : {
			de: "Primaere Abhebekonsole LT",
			en: "Prim. lifting stop device LB"
		}
		,A2V00002603089 : {
			de: "Rippe 3 Langtraeger",
			en: "Rip 3 longitudinal beam"
		}
		,A2V00002603515 : {
			de: "Schweissbadsicherung 1 LT",
			en: "Weld backing 1 LB"
		}
		,A2V00002603605 : {
			de: "Rippe 2 Langtraeger",
			en: "Rib 2 longitudinal beam"
		}
		,A2V00002603679 : {
			de: "Schlauchleitung",
			en: "Hose assembly"
		}
		,A2V00002603680 : {
			de: "Schlauchleitung",
			en: "Hose assembly"
		}
		,A2V00002603681 : {
			de: "Schlauchleitung",
			en: "Hose assembly"
		}
		,A2V00002603682 : {
			de: "Schlauchleitung",
			en: "Hose assembly"
		}
		,A2V00002603683 : {
			de: "Schlauchleitung",
			en: "Hose assembly"
		}
		,A2V00002605547 : {
			de: "Notfederbeilage 2mm",
			en: "Shim emergency spring 2mm"
		}
		,A2V00002605548 : {
			de: "Notfederbeilage 5mm",
			en: "Shim emergency spring 5mm"
		}
		,A2V00002605549 : {
			de: "Notfederbeilage 1mm",
			en: "Shim emergency spring 1mm"
		}
		,A2V00002606663 : {
			de: "BÃ¼gel",
			en: "Joke"
		}
		,A2V00002606664 : {
			de: "Schienenbremshalter A",
			en: "Track brake holder A"
		}
		,A2V00002606665 : {
			de: "Schienenbremshalter B",
			en: "Track brake holder B"
		}
		,A2V00002606756 : {
			de: "Anschlag A",
			en: "Endstop A"
		}
		,A2V00002606757 : {
			de: "Anschlag B",
			en: "Endstop B"
		}
		,A2V00002607521 : {
			de: "Abzweigkasten ZL 156 Q-4",
			en: "Junction box ZL 156 Q-4"
		}
		,A2V00002609368 : {
			de: "Rippe 2",
			en: "Rib 2"
		}
		,A2V00002609874 : {
			de: "Beilage Festanschlag, 4mm",
			en: "Shim hard stop, 4mm"
		}
		,A2V00002609969 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002611089 : {
			de: "Probeplatte 6mm",
			en: "Sample plate 6mm"
		}
		,A2V00002611090 : {
			de: "Probeplatte 10mm",
			en: "Sample plate 10mm"
		}
		,A2V00002611091 : {
			de: "Probeplatte 16mm",
			en: "Sample plate 16mm"
		}
		,A2V00002611670 : {
			de: "HÃ¼lse",
			en: "Sleeve"
		}
		,A2V00002614052 : {
			de: "Halter Verrohrung 4",
			en: "holder piping 4"
		}
		,A2V00002614053 : {
			de: "Halter Verrohrung 1",
			en: "holder piping 1"
		}
		,A2V00002614832 : {
			de: "Halter Verrohrung 3",
			en: "holder piping 3"
		}
		,A2V00002614834 : {
			de: "Halter Verrohrung 2",
			en: "holder piping 2"
		}
		,A2V00002615371 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002615408 : {
			de: "Versteifung",
			en: "Stiffener"
		}
		,A2V00002615595 : {
			de: "Schmierstift",
			en: "Interlocking stick"
		}
		,A2V00002615605 : {
			de: "Flanschblech",
			en: "Flange plate"
		}
		,A2V00002615748 : {
			de: "Seil vollstaendig",
			en: "Rope complete"
		}
		,A2V00002615782 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002615839 : {
			de: "Stromabnehmer links",
			en: "Current collector left"
		}
		,A2V00002615841 : {
			de: "Stromabnehmer rechts",
			en: "Current collector right"
		}
		,A2V00002615917 : {
			de: "Anschlussblech",
			en: "Gusset plate"
		}
		,A2V00002616001 : {
			de: "Konsolblech 1 fuer Bremsverrohrung",
			en: "Bracket plate 1 for piping"
		}
		,A2V00002617626 : {
			de: "Zylinderschraube",
			en: "Socket set screw"
		}
		,A2V00002617929 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002617975 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002618775 : {
			de: "Deckel",
			en: "Cover"
		}
		,A2V00002619601 : {
			de: "Beilage 5mm",
			en: "Shim 5mm"
		}
		,A2V00002619602 : {
			de: "Beilage 2mm",
			en: "Shim 2mm"
		}
		,A2V00002619603 : {
			de: "Beilage 1mm",
			en: "Shim 1mm"
		}
		,A2V00002620617 : {
			de: "Halter 1",
			en: "Holder 1"
		}
		,A2V00002620639 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002621155 : {
			de: "Grundbeilage",
			en: "basic shim"
		}
		,A2V00002621403 : {
			de: "Kegelring",
			en: "Cone ring"
		}
		,A2V00002621662 : {
			de: "Traegerrohr",
			en: "Carrier pipe"
		}
		,A2V00002622875 : {
			de: "Halter 27",
			en: "Holder"
		}
		,A2V00002623060 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002623078 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002623187 : {
			de: "Halter 28",
			en: "Holder 28"
		}
		,A2V00002624554 : {
			de: "Halter Verkabelung 1",
			en: "holder cabling 1"
		}
		,A2V00002624921 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002624955 : {
			de: "Motorhalter 1",
			en: "motor holder 1"
		}
		,A2V00002625022 : {
			de: "Senkschraube",
			en: "Hexagon socket countersunk head screw"
		}
		,A2V00002625149 : {
			de: "Scheibe",
			en: "wascher"
		}
		,A2V00002625160 : {
			de: "Beilage Laengsmitnahme",
			en: "Guid Part"
		}
		,A2V00002625342 : {
			de: "Halter Verkabelung 4",
			en: "holder cabling 4"
		}
		,A2V00002625343 : {
			de: "Halter Verkabelung 3",
			en: "holder cabling 3"
		}
		,A2V00002625344 : {
			de: "Halter Verkabelung 5",
			en: "holder cabling 5"
		}
		,A2V00002625345 : {
			de: "Motorhalter 2",
			en: "motor holder 2"
		}
		,A2V00002625539 : {
			de: "Motorhalter 6",
			en: "motor holder 6"
		}
		,A2V00002625784 : {
			de: "Spannstift",
			en: "Spring type straight pin"
		}
		,A2V00002626652 : {
			de: "Beilagenhaelfte 10mm",
			en: "Half shim 10mm"
		}
		,A2V00002626653 : {
			de: "Beilagenhaelfte 5mm",
			en: "Half shim 5mm"
		}
		,A2V00002626696 : {
			de: "Beilagenhaelfte 2mm",
			en: "Shim half 2mm"
		}
		,A2V00002626762 : {
			de: "Grundbeilage 2mm",
			en: "Basic shim 2mm"
		}
		,A2V00002626763 : {
			de: "Grundbeilage 5mm",
			en: "Basic shim 5mm"
		}
		,A2V00002626764 : {
			de: "Grundbeilage 1mm",
			en: "Basic shim 1mm"
		}
		,A2V00002627298 : {
			de: "Fernbetaetigung 2595mm",
			en: "Emergency release device 2595mm"
		}
		,A2V00002627333 : {
			de: "Puffer, Laengsmitnahme",
			en: "buffer"
		}
		,A2V00002627377 : {
			de: "MAK-Schichtfeder",
			en: "Layer spring"
		}
		,A2V00002628408 : {
			de: "Montagematerial Lagerdeckel",
			en: "Mounting material Bearing cover"
		}
		,A2V00002629803 : {
			de: "Fernbetaetigung 2370mm",
			en: "Emergency release device 2370mm"
		}
		,A2V00002629868 : {
			de: "Fernbetaetigung 1330mm",
			en: "Emergency release device 1330mm"
		}
		,A2V00002630114 : {
			de: "Faltenbalg",
			en: "Expansion joint"
		}
		,A2V00002631299 : {
			de: "Halter Bremse 1 SPG",
			en: "holder break 1 OAD"
		}
		,A2V00002631497 : {
			de: "Flexball DZ160,G=3130,H=90+HZ9",
			en: "Flexball DZ160,G=3130,H=90+HZ9"
		}
		,A2V00002631498 : {
			de: "Flexball DZ160,G=2720,H=90+HZ9",
			en: "Flexball DZ160,G=2720,H=90+HZ9"
		}
		,A2V00002632716 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical shock absorber"
		}
		,A2V00002632720 : {
			de: "Daempfer sekundaer horizontal",
			en: "Secondary lateral shock absorber"
		}
		,A2V00002633845 : {
			de: "Passschraube",
			en: "Fit bolt"
		}
		,A2V00002634142 : {
			de: "Kegelrollenlager",
			en: "tapered roller bearing"
		}
		,A2V00002634143 : {
			de: "Kegelrollenlager",
			en: "tapered roller bearing"
		}
		,A2V00002634163 : {
			de: "Stromabnehmer",
			en: "Current collector"
		}
		,A2V00002667085 : {
			de: "Sechskantmutter mit Klemmteil",
			en: "Hexaconal nut with clemp component"
		}
		,A2V00002668450 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00002668661 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002669049 : {
			de: "Rippe Motorkonsole  2",
			en: "Rib Engine bracket 2"
		}
		,A2V00002669051 : {
			de: "Rippe Motorkonsole 4",
			en: "Rib Engine bracket 4"
		}
		,A2V00002669054 : {
			de: "Rippe Motorkonsole 1",
			en: "Rib Engine bracket 1"
		}
		,A2V00002669057 : {
			de: "Rippe LT",
			en: "Rib LB"
		}
		,A2V00002669058 : {
			de: "Rippe Motorkonsole 3",
			en: "Rib Engine bracket 3"
		}
		,A2V00002669357 : {
			de: "Halter Spus",
			en: "Bracket wfl"
		}
		,A2V00002669370 : {
			de: "Boehler EMK 6 1,0 mm (SchweiÃŸdraht)",
			en: "Boehler EMK 6 1,0 mm (welding rod)"
		}
		,A2V00002669373 : {
			de: "Boehler EMK 6 1,0 mm (SchweiÃŸdraht)",
			en: "Boehler EMK 6 1,0 mm (welding rod)"
		}
		,A2V00002669374 : {
			de: "Boehler EMK 6 1,2 mm (SchweiÃŸdraht)",
			en: "Boehler EMK 6 1,2 mm (welding rod)"
		}
		,A2V00002670082 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002670391 : {
			de: "Beilage 2mm",
			en: "Shim 2mm"
		}
		,A2V00002673354 : {
			de: "MG Zylinder Halter SPG",
			en: "MB cylinder holder OAD"
		}
		,A2V00002673355 : {
			de: "MG Zylinder Halter WG",
			en: "MB cylinder holder AD"
		}
		,A2V00002673699 : {
			de: "Beilage Festanschlag, 1mm",
			en: "Shim lateral stop, 1mm"
		}
		,A2V00002673730 : {
			de: "Endlagenschalter",
			en: "end position switch"
		}
		,A2V00002673773 : {
			de: "MG Bremse JDG",
			en: "MTB jacobs bogie"
		}
		,A2V00002673816 : {
			de: "Halter fuer Schelle",
			en: "Holder for clamp"
		}
		,A2V00002673817 : {
			de: "Halter klein",
			en: "Holder small"
		}
		,A2V00002673818 : {
			de: "Halter HTS",
			en: "Holder HTS"
		}
		,A2V00002674607 : {
			de: "Adapter fÃ¼r Druckkappe fÃ¼r WIG HASLER",
			en: "Adapter for axle end cap for WIG HASLER"
		}
		,A2V00002675617 : {
			de: "Verbindungslasche",
			en: "Mounting link"
		}
		,A2V00002677234 : {
			de: "Halter 3",
			en: "Bracket 3"
		}
		,A2V00002677674 : {
			de: "Beilage 1.5mm f. Megifeder",
			en: "Shim 1.5mm f. chevron spring"
		}
		,A2V00002267132 : {
			de: "Kegelradsatz ZS430/2,29 BR643",
			en: "Bevel gear set ZS430/2,29 BR643"
		}
		,A2V00002677957 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00002677982 : {
			de: "Sicherungsschraube",
			en: "Protection screw"
		}
		,A2V00002679461 : {
			de: "Anhebeoese",
			en: "Lifting ear"
		}
		,A2V00002679462 : {
			de: "Rippe Anschluss WG",
			en: "Rib connection AD"
		}
		,A2V00002679463 : {
			de: "Rippe Fahrsperre",
			en: "Rib train stop"
		}
		,A2V00002679465 : {
			de: "Platte Untergurt",
			en: "Plate Lower flange"
		}
		,A2V00002679476 : {
			de: "Motorlager",
			en: "Motor support"
		}
		,A2V00002679502 : {
			de: "Rippe Anschluss SPG",
			en: "Rib connection OAD"
		}
		,A2V00002679567 : {
			de: "Platte Fahrsperre",
			en: "Plate train stop"
		}
		,A2V00002679570 : {
			de: "Primaere Abhebesicherung",
			en: "Primary lift stop"
		}
		,A2V00002679978 : {
			de: "Halter Schelle",
			en: "Holder bracket"
		}
		,A2V00002681480 : {
			de: "Halter SV",
			en: "Bracket SV"
		}
		,A2V00002681561 : {
			de: "Schellenhalter 10",
			en: "Bracket 10"
		}
		,A2V00002682404 : {
			de: "Distanzhuelse PWS",
			en: "socket pws"
		}
		,A2V00002682602 : {
			de: "Gewindelasche MQD",
			en: "Threadplate MQD"
		}
		,A2V00002683801 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002686739 : {
			de: "Rippe 11",
			en: "Rib 11"
		}
		,A2V00002686760 : {
			de: "Rippe 13",
			en: "Rib 13"
		}
		,A2V00002686765 : {
			de: "Rippe 12",
			en: "Rib 12"
		}
		,A2V00002686825 : {
			de: "Rippe 9",
			en: "Rib 9"
		}
		,A2V00002686851 : {
			de: "Steg 6",
			en: "Web 6"
		}
		,A2V00002686878 : {
			de: "Steg 5",
			en: "Web 5"
		}
		,A2V00002686906 : {
			de: "Deckel 10",
			en: "Cover plate 10"
		}
		,A2V00002689289 : {
			de: "Rippe sp.g.",
			en: "Rib o.a.d."
		}
		,A2V00002689476 : {
			de: "Rippe w.g.",
			en: "Rib a.d."
		}
		,A2V00002689610 : {
			de: "FDUE Wagenkastensensor A-Muster",
			en: "BDM car body sensor A sample"
		}
		,A2V00002690708 : {
			de: "Halter 1",
			en: "bracket 1"
		}
		,A2V00002690794 : {
			de: "Halter 2",
			en: "bracket 2"
		}
		,A2V00002690874 : {
			de: "Halter 5",
			en: "bracket 5"
		}
		,A2V00002691261 : {
			de: "Anlenkstange Zuschnitt",
			en: "Tie Rod cutting"
		}
		,A2V00002691290 : {
			de: "Federblatt LDG 378mm",
			en: "leaf spring trailerbogie 378mm"
		}
		,A2V00002691292 : {
			de: "Federblatt TDG 397mm",
			en: "Leaf spring trailer bogie 397mm"
		}
		,A2V00002691293 : {
			de: "Federblatt TDG 390mm",
			en: "Leaf spring motor bogie 390mm"
		}
		,A2V00002692360 : {
			de: "Temperatursensor KL=1250mm",
			en: "Temperature sensor CL=1250mm"
		}
		,A2V00002692361 : {
			de: "Temperatursensor KL=1400mm",
			en: "Temperature sensor CL=1400mm"
		}
		,A2V00002693344 : {
			de: "Halter 3",
			en: "bracket 3"
		}
		,A2V00002693432 : {
			de: "Anschlagscheibe, mech. bearb.",
			en: "Stop plate, machined"
		}
		,A2V00002693618 : {
			de: "Schellenhalter 1",
			en: "Clip collar bracket 1"
		}
		,A2V00002693689 : {
			de: "Halter 4",
			en: "bracket 4"
		}
		,A2V00002693758 : {
			de: "Halter 8",
			en: "bracket 8"
		}
		,A2V00002693787 : {
			de: "Halter 6 wg",
			en: "bracket 6 ad"
		}
		,A2V00002693788 : {
			de: "Halter 6 spg",
			en: "bracket 6 oad"
		}
		,A2V00002694081 : {
			de: "Halter 9",
			en: "bracket 9"
		}
		,A2V00112504040 : {
			de: "KONSOLE",
			en: "Bracket"
		}
		,A2V00112508861 : {
			de: "EINFUELLSTUTZEN   LINKS",
			en: "filler left"
		}
		,A2V00002694753 : {
			de: "Schellenhalter 6",
			en: "clamp holder 6"
		}
		,A2V00002694813 : {
			de: "Halter Verkabelung",
			en: "holder cabling"
		}
		,A2V00002694893 : {
			de: "Halter QuertrÃ¤ger 2",
			en: "holder crossbeam 2"
		}
		,A2V00002694905 : {
			de: "Platte 2",
			en: "Plate 2"
		}
		,A2V00002694953 : {
			de: "Halter Verrohrung 2",
			en: "Holder piping 2"
		}
		,A2V00002694954 : {
			de: "Halter Verkabelung 2",
			en: "Holder cabling 2"
		}
		,A2V00002694977 : {
			de: "Schellenhalter 7",
			en: "clamp holder 7"
		}
		,A2V00002694987 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002694988 : {
			de: "Platte 1",
			en: "Plate 1"
		}
		,A2V00002695118 : {
			de: "Halter Motor 3",
			en: "holder motor 3"
		}
		,A2V00002695119 : {
			de: "Halter Motor 7",
			en: "holder motor 7"
		}
		,A2V00002695120 : {
			de: "Halter Motor 2",
			en: "holder motor 2"
		}
		,A2V00002695766 : {
			de: "Schichtfeder",
			en: "layer spring"
		}
		,A2V00002695783 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screw"
		}
		,A2V00002696370 : {
			de: "Bolzen",
			en: "bolt"
		}
		,A2V00002696491 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002696543 : {
			de: "Zylinderschraube",
			en: "hexagon socket head cap screwÂ "
		}
		,A2V00002696544 : {
			de: "Zylinderschraube",
			en: "hexagon socket head cap screwÂ "
		}
		,A2V00002697333 : {
			de: "Deckplatte",
			en: "Cover plate"
		}
		,A2V00002697487 : {
			de: "Blech  2",
			en: "Sheet 2"
		}
		,A2V00002697494 : {
			de: "Blech 1",
			en: "Sheet 1"
		}
		,A2V00002698668 : {
			de: "Halter Verkabelung 5",
			en: "holder cabeling 5"
		}
		,A2V00002698669 : {
			de: "Rippe 2 WG",
			en: "Web 2 AD"
		}
		,A2V00002698724 : {
			de: "Rippe 2 SPG",
			en: "Web 2 o.a.d."
		}
		,A2V00002698931 : {
			de: "SchienenrÃ¤umer Rippe",
			en: "Rail guard:rib"
		}
		,A2V00002699014 : {
			de: "Schienenraeumer",
			en: "Rail guard"
		}
		,A2V00002699071 : {
			de: "Drehzahlgeber GEL 247D - 2 Kanaele",
			en: "Speed sensor GEL 247D - 2 signals"
		}
		,A2V00002699363 : {
			de: "Federbeilage 1mm",
			en: "Spring shim 1mm"
		}
		,A2V00002699364 : {
			de: "Federbeilage 2mm",
			en: "Spring shim 2mm"
		}
		,A2V00002699376 : {
			de: "Notfederbeilage 1mm",
			en: "Spring f. emergency spring 1mm"
		}
		,A2V00002699377 : {
			de: "Notfederbeilage 2mm",
			en: "Spring f. emergency spring 2mm"
		}
		,A2V00002699490 : {
			de: "Notfederbeilage 1mm",
			en: "Spring f. emergency spring 1mm"
		}
		,A2V00002699491 : {
			de: "Notfederbeilage 2mm",
			en: "Spring f. emergency spring 2mm"
		}
		,A2V00002699781 : {
			de: "Bolzen Verkleidung TDG",
			en: "Pin bogie fairing"
		}
		,A2V00002699823 : {
			de: "Stift mit Innengewinde M5x12",
			en: "stud with internal thread m5x12"
		}
		,A2V00002700437 : {
			de: "Erdungskontakt",
			en: "Earthing contact"
		}
		,A2V00002701987 : {
			de: "Beilage Schelle",
			en: "garnish clip"
		}
		,A2V00002703471 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002705207 : {
			de: "Antenne Dummy",
			en: "Antenna Dummy"
		}
		,A2V00002705395 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002705589 : {
			de: "Transportbeilage",
			en: "transport shim"
		}
		,A2V00002706365 : {
			de: "Antennenkonsole",
			en: "Antenna console"
		}
		,A2V00002706653 : {
			de: "Antennentraeger Kragarm",
			en: "Antenna console beam"
		}
		,A2V00002706674 : {
			de: "Halter Lenker hor.",
			en: "Console rod hor."
		}
		,A2V00002706893 : {
			de: "Konsole Lenker vert.",
			en: "Console rod vert."
		}
		,A2V00002707041 : {
			de: "Rippe",
			en: "Gill"
		}
		,A2V00002707042 : {
			de: "Rohr",
			en: "Pipe"
		}
		,A2V00001190296 : {
			de: "SCHIEBEFENSTER",
			en: "WINDOW"
		}
		,A2V00002418143 : {
			de: "Achsstrebe 66 + 50",
			en: "LINK 66 + 50"
		}
		,A2V00002423730 : {
			de: "Einstiegstuer re.",
			en: "Entrance door ri."
		}
		,A2V00002423731 : {
			de: "Einstiegstuer li.",
			en: "Entrance door le."
		}
		,A2V00002185968 : {
			de: "LED-Modul 940 ohne Notlicht",
			en: "LED module 940 without emergency light"
		}
		,A2V00002712198 : {
			de: "LZB-Antenne mit Hartingstecker",
			en: "LZB-antenna with Harting-plug"
		}
		,A2V00002713173 : {
			de: "Fernbetaetigung 1075mm",
			en: "Emergency Release Device 1075mm"
		}
		,A2V00002713174 : {
			de: "Fernbetaetigung 2290mm",
			en: "Emergency Release Device 2290mm"
		}
		,A2V00002715027 : {
			de: "Halter Erdungskontakt",
			en: "holder earthing contact"
		}
		,A2V00002717645 : {
			de: "Wegimpulsgeber 16P",
			en: "Odometer pulse generator 16P"
		}
		,A2V00002719209 : {
			de: "Montagematerial Lageranbauten ATP",
			en: "Mounting material bearing assembly ATP"
		}
		,A2V00002722147 : {
			de: "UT-Referenzwelle SF7500",
			en: "UT-test axle SF7500"
		}
		,A2V00002722651 : {
			de: "Konsole Flexballzug",
			en: "Bracket flexball pull"
		}
		,A2V00002032035 : {
			de: "Bodenpaneel links",
			en: "Floor plate left"
		}
		,A2V00002032034 : {
			de: "Bodenpaneel rechts",
			en: "Floor plate right"
		}
		,A2V00370031901 : {
			de: "SCHLAUCHLEITUNG 2TE 6 AA 320 P",
			en: "hose assembly 2TE 6 AA 320 P"
		}
		,A2V00001856356 : {
			de: "Indusi-Frequenz-BG I60R/I80",
			en: "ATW frequency unit I60R/I80"
		}
		,A2V00002590845 : {
			de: "Drehgestell SF4-17A",
			en: "Bogie SF4-17A"
		}
		,A2V00002590896 : {
			de: "Drehgestell SF4-17B",
			en: "Bogie SF4-17B"
		}
		,A2V00002724148 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002724978 : {
			de: "Halter 1",
			en: "holder 1"
		}
		,A2V00002726815 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screws - full thread"
		}
		,A2V00002726829 : {
			de: "Antriebseinheit 2KF1422-0GB15",
			en: "Drive unit 2KF1422-0GB15"
		}
		,A2V00002730551 : {
			de: "Montagematerial RBS710",
			en: "Mounting kit WMD710"
		}
		,A2V00002731499 : {
			de: "Wankbock",
			en: "ARS bracket"
		}
		,A2V00002731575 : {
			de: "Pruefstueck 39 6x150x400 / 1 / 25",
			en: "Test Piece 39 6x150x400 / 1 / 25"
		}
		,A2V00002732141 : {
			de: "Motornotfang",
			en: "motor safety catch"
		}
		,A2V00002733206 : {
			de: "Versuchswelle, NSB Berlin, Lucchini",
			en: "Test axle, NSB Berlin, Lucchini"
		}
		,A2V00002739739 : {
			de: "Konusfeder Conti",
			en: "Conical spring Conti"
		}
		,A2V00002735730 : {
			de: "Anlenkstange Zuschnitt",
			en: "Tie rod cut"
		}
		,A2V00002735869 : {
			de: "Rippe Notfang",
			en: "emergency fin"
		}
		,A2V00002735907 : {
			de: "Zug-Druckstange Fuehrung2",
			en: "connection rod guidance2"
		}
		,A2V00002735909 : {
			de: "Untergurt Fuehrung",
			en: "lower flange guidance"
		}
		,A2V00002736011 : {
			de: "Zug-Druckstange Fuehrung",
			en: "connection rod guidance"
		}
		,A2V00002736034 : {
			de: "Stegblech Fuehrung",
			en: "web plate guidance"
		}
		,A2V00002736042 : {
			de: "Gewindeplatte",
			en: "threaded plate"
		}
		,A2V00002737397 : {
			de: "Halter spg.",
			en: "holder gd"
		}
		,A2V00002738218 : {
			de: "Verschlussdeckel",
			en: "cap"
		}
		,A2V00002738246 : {
			de: "Gewindelasche",
			en: "thread sheet"
		}
		,A2V00001915349 : {
			de: "Obergurt f. Querpufferkonsole, LDG",
			en: "Upper flange f. lat. bumper support, TB"
		}
		,A2V00002739900 : {
			de: "Delimon DÃ¼senblock Anschluss rechts",
			en: "Delimon spray nozzle connection right"
		}
		,A2V00002739901 : {
			de: "Delimon DÃ¼senblock Anschluss links",
			en: "Delimon spray nozzle connection left"
		}
		,A2V00002742685 : {
			de: "Halter 14",
			en: "Holder 14"
		}
		,A2V00002743246 : {
			de: "Abhebeplatte",
			en: "Lifting plate"
		}
		,A2V00002743338 : {
			de: "Profil f. Verkleid., mittlere",
			en: "Profile f. fairing, middle"
		}
		,A2V00002744267 : {
			de: "Beilage",
			en: "Garnish"
		}
		,A2V00002744329 : {
			de: "Adapterblech",
			en: "Adapter plate"
		}
		,A2V00002748234 : {
			de: "Antriebseinheit 2KF1422-0GB12",
			en: "Drive unit 2KF1422-0GB12"
		}
		,A2V00117611028 : {
			de: "STECKKLAPPE LINKS  VOLLST.",
			en: "Plug cover left complete"
		}
		,A2V00002592609 : {
			de: "Bremsbelag BMBS F570*",
			en: "axle mount. brake calliper wo. park. br."
		}
		,A2V00002592608 : {
			de: "Bremsbelag BMBS F570*",
			en: "axle mount. brake calliper wo. park. br."
		}
		,A2V00002753097 : {
			de: "Drehzahlgeber GEL 2475Y272 - E-D Signal",
			en: "Pulse generator GE2475Y272 - E-D Signal"
		}
		,A2V00001192684 : {
			de: "PC Profil  122mm",
			en: "PC Profile 122mm"
		}
		,A2V00001221112 : {
			de: "Windschutzscheibe inkl. Befestigungsmat.",
			en: "Windscreen includes connector"
		}
		,A2V00001221085 : {
			de: "Gasdruckstrebe inkl. Zubehoer",
			en: "gas strut incl. attachments"
		}
		,A2V00002753873 : {
			de: "Halter Erdungskontakt",
			en: "Holder earthing contact"
		}
		,A2V00002754872 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002755021 : {
			de: "Konsole Flexballzug",
			en: "Bracket flexball pull"
		}
		,A2V00002755322 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screws - full thread"
		}
		,A2V00002755678 : {
			de: "Sechskantschraube",
			en: "Hex Cap Screws - full thread"
		}
		,A2V00002757538 : {
			de: "Anschweissteil",
			en: "welding part"
		}
		,A2V00002759755 : {
			de: "Abdeckplatte",
			en: "Cover plate"
		}
		,A2V00002762813 : {
			de: "Halter",
			en: "holder"
		}
		,A2V00001896340 : {
			de: "Druckl.verarb.ger. vst",
			en: "compr.air ctrl. Cpl"
		}
		,A2V00002768563 : {
			de: "Halter SPG",
			en: "holder OAD"
		}
		,A2V00002768564 : {
			de: "Halter WG",
			en: "holder AD"
		}
		,A2V00002769004 : {
			de: "Armatur",
			en: "fitting"
		}
		,A2V00002772495 : {
			de: "Befestigung",
			en: "Mounting"
		}
		,A2V00002772496 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002772632 : {
			de: "Rippe LT 2",
			en: "Rib Lb 2"
		}
		,A2V00002772821 : {
			de: "Rippe LT 1",
			en: "Rib Lb 1"
		}
		,A2V00002772868 : {
			de: "Luftfederrohr",
			en: "Air spring tube"
		}
		,A2V00002773044 : {
			de: "Obergurt LT WG",
			en: "Upper flange Lb AD"
		}
		,A2V00002773045 : {
			de: "Obergurt LT SPG",
			en: "Upper flange Lb OAD"
		}
		,A2V00002773053 : {
			de: "Untergurt LT WG",
			en: "Lower flange Lb AD"
		}
		,A2V00002773551 : {
			de: "Untergurt LT SPG",
			en: "Lower flange Lb OAD"
		}
		,A2V00002774323 : {
			de: "Anschlussblech",
			en: "Connecting plate"
		}
		,A2V00002774576 : {
			de: "Blech Motorkonsole",
			en: "Plate motor bracket"
		}
		,A2V00002774631 : {
			de: "Ring Motorkonsole",
			en: "Ring motor bracket"
		}
		,A2V00002774719 : {
			de: "Steg 5",
			en: "Web 5"
		}
		,A2V00002774729 : {
			de: "Rippe quer",
			en: "Rib cross"
		}
		,A2V00002774767 : {
			de: "Rippe 1",
			en: "Rib 1"
		}
		,A2V00002774847 : {
			de: "Getriebenotfang",
			en: "Gear emerg. stop"
		}
		,A2V00002774852 : {
			de: "Deckel",
			en: "Cover"
		}
		,A2V00002774970 : {
			de: "Konsole Laengsmitnahme",
			en: "Bracket traction link"
		}
		,A2V00002775088 : {
			de: "Rippe 2",
			en: "Rib 2"
		}
		,A2V00002775113 : {
			de: "Versteifung",
			en: "Stiffener"
		}
		,A2V00002775299 : {
			de: "Befestigungsbuegel",
			en: "Mounting clamp"
		}
		,A2V00002775320 : {
			de: "Steg 3",
			en: "Web 3"
		}
		,A2V00001842254 : {
			de: "Toilettenmodul VT2-S",
			en: "Toilet modul VT2-S"
		}
		,A2V00002775772 : {
			de: "Steg 4 SPG",
			en: "Web 4 OAD"
		}
		,A2V00002775773 : {
			de: "Steg 4 WG",
			en: "Web 4 AD"
		}
		,A2V00002776205 : {
			de: "Daempferblech",
			en: "Damper plate"
		}
		,A2V00002776288 : {
			de: "Steg 3 WG",
			en: "Web 3 AD"
		}
		,A2V00002776289 : {
			de: "Steg 3 SPG",
			en: "Web 3 OAD"
		}
		,A2V00002776294 : {
			de: "Daempferplatte",
			en: "Damper plate"
		}
		,A2V00002776303 : {
			de: "Steg 6 SPG",
			en: "Web 6 OAD"
		}
		,A2V00002776304 : {
			de: "Steg 6 WG",
			en: "Web 6 AD"
		}
		,A2V00002776478 : {
			de: "Halter Junction Box",
			en: "Holder junction box"
		}
		,A2V00002776483 : {
			de: "Rippe quer",
			en: "Rib cross"
		}
		,A2V00002776786 : {
			de: "Halter f. Kabel",
			en: "holder f. cable"
		}
		,A2V00002777013 : {
			de: "Stegblech WG",
			en: "Web plate AD"
		}
		,A2V00002777014 : {
			de: "Stegblech SPG",
			en: "Web plate OAD"
		}
		,A2V00002777330 : {
			de: "Rippe 2",
			en: "Rib 2"
		}
		,A2V00117621033 : {
			de: "STECKKLAPPE        RECHTS, VOLLST.",
			en: "Plug cover right complete"
		}
		,A2V00002606575 : {
			de: "Luftversorgungsanlage TA 9 BTSC-GL",
			en: "Air supply unit TA 9 BTSC-GL"
		}
		,A2V00002778573 : {
			de: "Verschlussblech",
			en: "Closing plate"
		}
		,A2V00002778738 : {
			de: "Halter 2 Antenne",
			en: "Holder 2 antenna"
		}
		,A2V00002778739 : {
			de: "Halter 1 Antenne",
			en: "Holder 1 antenna"
		}
		,A2V00002779438 : {
			de: "Profil",
			en: "profile"
		}
		,A2V00002779470 : {
			de: "Steg innen",
			en: "Web inside"
		}
		,A2V00002779802 : {
			de: "Konsolenblech",
			en: "Bracket plate"
		}
		,A2V00002779878 : {
			de: "Steg 1",
			en: "Web 1"
		}
		,A2V00002779917 : {
			de: "Gurt",
			en: "Chord"
		}
		,A2V00002780395 : {
			de: "Halter",
			en: "holder"
		}
		,A2V00002780974 : {
			de: "Halter SPG",
			en: "Holder OAD"
		}
		,A2V00002780975 : {
			de: "Halter WG",
			en: "Holder AD"
		}
		,A2V00002781441 : {
			de: "Schieneraeumer w.g.",
			en: "rail guard a.d."
		}
		,A2V00002781442 : {
			de: "SchienenrÃ¤umer sp. g.",
			en: "rail  scraper o. a.d."
		}
		,A2V00002782437 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002782877 : {
			de: "FÃ¼hrungsbeilage",
			en: "Guide washer"
		}
		,A2V00002783256 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002783406 : {
			de: "Halter Notloesezug",
			en: "holder ERD"
		}
		,A2V00002783698 : {
			de: "Halter Terminal",
			en: "holder terminal"
		}
		,A2V00002785160 : {
			de: "Stiftschraube",
			en: "stud"
		}
		,A2V00002785263 : {
			de: "Prueblech",
			en: "Test plate"
		}
		,A2V00002267128 : {
			de: "Gehaeuse kompl I Spindelz  8755982 O DB",
			en: "Housing compl. I Spindelz  875598 O DB"
		}
		,A2V00002785815 : {
			de: "Beilage Sek. verzinnt",
			en: "Shim sec. tin coated"
		}
		,A2V00002786862 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002787122 : {
			de: "Probe BM S355",
			en: "Test specimen FM S355"
		}
		,A2V00002787357 : {
			de: "Probe BM S700",
			en: "Test specimen FM S700"
		}
		,A2V00002787429 : {
			de: "Kegelring vormontiert",
			en: "Cone ring pre-assembled"
		}
		,A2V00002787532 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002787618 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00002787643 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002787733 : {
			de: "Leitungshalter",
			en: "Wire holder"
		}
		,A2V00002787734 : {
			de: "U-BÃ¼gel",
			en: "U-clamp"
		}
		,A2V00002787735 : {
			de: "Buegel",
			en: "Clamp"
		}
		,A2V00002787746 : {
			de: "Rohrhalter",
			en: "Pipe support"
		}
		,A2V00002787747 : {
			de: "BÃ¼gel",
			en: "Clamp"
		}
		,A2V00002787748 : {
			de: "BÃ¼gel",
			en: "Clamp"
		}
		,A2V00002787749 : {
			de: "Halter SPG",
			en: "Bracket OAD"
		}
		,A2V00002787750 : {
			de: "Halter WG",
			en: "Bracket AD"
		}
		,A2V00002787766 : {
			de: "Halteblech",
			en: "Support plate"
		}
		,A2V00002787768 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002787769 : {
			de: "Rohrhalter, gerade",
			en: "Pipe holder,  straight"
		}
		,A2V00002787770 : {
			de: "Rohrhalter gerade",
			en: "Pipe Holder straight"
		}
		,A2V00002787942 : {
			de: "Abhebesicherung primÃ¤r",
			en: "Lifting stop primary"
		}
		,A2V00001003812 : {
			de: "SandeinfÃ¼llgehÃ¤use",
			en: "Sanding case"
		}
		,A2V00002787996 : {
			de: "Grundplatte",
			en: "Base plate"
		}
		,A2V00002787997 : {
			de: "Stegblech",
			en: "Web plate"
		}
		,A2V00002788087 : {
			de: "Gurt, w.g.",
			en: "Flange, as drawn"
		}
		,A2V00002788088 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00002788089 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002788090 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002788091 : {
			de: "Gurt, sp.g.",
			en: "Flange, opp. as drawn"
		}
		,A2V00002788095 : {
			de: "Lasche f. Motornotfang",
			en: "Safety bar f. motor"
		}
		,A2V00002788097 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002788141 : {
			de: "Traeger, w.g.",
			en: "Support, a.d."
		}
		,A2V00002788142 : {
			de: "Zahnplatte",
			en: "Toothed plate"
		}
		,A2V00002788147 : {
			de: "Traeger, sp.g.",
			en: "Support, o.a.d."
		}
		,A2V00002788152 : {
			de: "Versteifung",
			en: "Stiffening"
		}
		,A2V00002788154 : {
			de: "Versteifung",
			en: "Stiffening"
		}
		,A2V00002788155 : {
			de: "Traverse",
			en: "Center part"
		}
		,A2V00002788168 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00002788169 : {
			de: "L-Profil, rechts",
			en: "L-Profile, right"
		}
		,A2V00002788170 : {
			de: "Versteifung",
			en: "Stiffening"
		}
		,A2V00002788171 : {
			de: "Traverse",
			en: "Center part"
		}
		,A2V00002788174 : {
			de: "L-Profil, links",
			en: "L-Profile, left"
		}
		,A2V00002790160 : {
			de: "Halter kurz",
			en: "Holder short"
		}
		,A2V00002790161 : {
			de: "Halter fÃ¼r Notloesezug",
			en: "Holder for bowden"
		}
		,A2V00002790478 : {
			de: "Deckplatte",
			en: "Cover plate"
		}
		,A2V00002790877 : {
			de: "Montagekonsole links",
			en: "Mounting bracket left"
		}
		,A2V00002791301 : {
			de: "Probe BM S700",
			en: "Test specimen FM S700"
		}
		,A2V00002792666 : {
			de: "Steg 1 Langtraeger SchweiÃŸpruefung",
			en: "Web 1 longitudinal beam weldingtest"
		}
		,A2V00002792668 : {
			de: "Obergurt 2 Langtraeger SchweiÃŸpruefung",
			en: "Upper flange 2 LB weldingtest"
		}
		,A2V00397141308 : {
			de: "SCHIENENRAEUMER W.G.",
			en: "rail catcher"
		}
		,A2V00002794564 : {
			de: "Scheibe Wanksystem 1mm",
			en: "Shim anti roll system 1mm"
		}
		,A2V00002795778 : {
			de: "Kugelscheibe",
			en: "Spherical washer"
		}
		,A2V00002795779 : {
			de: "Kugelscheibe",
			en: "Spherical washer"
		}
		,A2V00002795780 : {
			de: "Kegelpfanne",
			en: "Conical seat"
		}
		,A2V00002795781 : {
			de: "Kegelpfanne",
			en: "Conical seat"
		}
		,A2V00002797294 : {
			de: "V Pruefstueck 40 12x150x400 / 1 / 22,5",
			en: "Test Piece 40 12x150x400 / 1 / 22,5"
		}
		,A2V00002801926 : {
			de: "RBS 710 bearbeitet Messradsatz",
			en: "WMD 710 machined test wheel set"
		}
		,A2V00397157716 : {
			de: "Stuetzring",
			en: "Supporting ring"
		}
		,A2V00002804133 : {
			de: "Frontblech TDG CFK",
			en: "Front Panel MB CFC"
		}
		,A2V00002805162 : {
			de: "Drehzahlgeber GEL 2474Y100",
			en: "Pulse generator GEL 2474Y100"
		}
		,A2V00207312440 : {
			de: "Triebdrehgestell We 1",
			en: "Triebdrehgestell We 1"
		}
		,A2V00001827855 : {
			de: "Masterring",
			en: "Masterring"
		}
		,A2V00001018336 : {
			de: "Zwischenradwelle fÃ¼r Antrieb ICE 1",
			en: "Zwischenradwelle fÃ¼r Antrieb ICE 1"
		}
		,A2V00002806748 : {
			de: "Festanschlag, gefraest Zuschnitt",
			en: "Lateral stop, machined rawcut"
		}
		,A2V00370052233 : {
			de: "GELENK M.LINKSGEWINDE",
			en: "JOINT WITH LEFT-HAND THREAD"
		}
		,A2V00207280027 : {
			de: "Heizflansch vollst",
			en: "Heating flange cpl"
		}
		,A2V00201351822 : {
			de: "Batterieladegeraet",
			en: "Battery charger"
		}
		,A2V00002383150 : {
			de: "Bahnraeumer vst.",
			en: "Track sweeper cpl."
		}
		,A2V00002809461 : {
			de: "Fernbetaetigung 2530mm",
			en: "Emergency Release Device 2530mm"
		}
		,A2V00001847349 : {
			de: "LED-Platine oben",
			en: "LED-board HW1.1-up"
		}
		,A2V00001375502 : {
			de: "ERS 7471056  Crash-Element",
			en: "7471056 Crash element"
		}
		,A2V00001375503 : {
			de: "ERS 7471057  Crash-Element",
			en: "7471057  Crash-Element"
		}
		,A2V00001840716 : {
			de: "LED-Platine unten",
			en: "LED-board HW1.1-lower"
		}
		,A2V00311137410 : {
			de: "RD DIN668-10-CK35K",
			en: "RD DIN668-10-CK35K"
		}
		,A2V00201185367 : {
			de: "Kupplungshalterung li",
			en: "Coupler support left"
		}
		,A2V00002310917 : {
			de: "Unterfahrschutz komplett",
			en: "underrun bumper complete"
		}
		,A2V00001374308 : {
			de: "Hohlwelle mit Bremse ID 142",
			en: "Hollow shaft w. brake ID 142"
		}
		,A2V00002621241 : {
			de: "Bedientafel_X4E1136_V2_N01 E+M",
			en: "Control panel X4E1136_V2_N01 E+M"
		}
		,A2V00002524174 : {
			de: "Tuersteuerung MTB3",
			en: "Door control unit MTB3"
		}
		,A2V00207310979 : {
			de: "MaschinenraumtÃ¼r",
			en: "Engine room door"
		}
		,A2V00370025685 : {
			de: "4KT DIN1014-50x6000-S355JO",
			en: "SQUARE DIN1014-50x6000-S355JO"
		}
		,A2V00370026384 : {
			de: "4KT DIN1014-40-S355JO",
			en: "SQUARE DIN1014-40-S355JO"
		}
		,A2V00370026667 : {
			de: "BOLZEN DIN1444-B 30H11x90-ST50",
			en: "Bolt"
		}
		,A2V00201176982 : {
			de: "Sandauslaufrohr vst.",
			en: "Sand pipe cpl."
		}
		,A2V00370035878 : {
			de: "Gewindestueck",
			en: "Union"
		}
		,A2V00397255269 : {
			de: "Schienenraeumer WG",
			en: "Rail catcher AD"
		}
		,A2V00397249484 : {
			de: "Schienenraeumer SPG",
			en: "Rail catcher OAD"
		}
		,A2V00397212878 : {
			de: "Spindel fuer Sekundaerfeder",
			en: "Spindle f.sec.spring"
		}
		,A2V00370036374 : {
			de: "Spurkranzschmierung w.g.",
			en: "Wheel Flange Lubrication a.d."
		}
		,A2V00370036376 : {
			de: "Spurkranzschmierung sp.g.",
			en: "Wheel Flange Lubrication o.a.d."
		}
		,A2V00397215058 : {
			de: "DISTANZHUELSE",
			en: "DISTANCE SLEEVE"
		}
		,A2V00397164284 : {
			de: "Unterlagsplatte",
			en: "Support plate"
		}
		,A2V00002534056 : {
			de: "Voute",
			en: "concrete haunch"
		}
		,A2V00370057759 : {
			de: "Passfeder DIN6885-A 20x12x160-ST60-2K",
			en: "Parallel key DIN6885-A 20x12x160"
		}
		,A2V00370059173 : {
			de: "Knochenbuchse",
			en: "spheribloc"
		}
		,A2V00370067279 : {
			de: "L EN10056-1-30X20X4-S235J0",
			en: "L EN10056-1-30X20X4-S235J0"
		}
		,A2V00002778632 : {
			de: "Traeger",
			en: "Beam"
		}
		,A2V00002398120 : {
			de: "Huelse d10,5/10,5",
			en: "Sleeve d10,5/10,5"
		}
		,A2V00370081673 : {
			de: "Axialfixierungsschelle",
			en: "axial fixing clamp"
		}
		,A2V00370084996 : {
			de: "Oberteil f. Lagergeh., lack.",
			en: "Bearing housing upper part, coated"
		}
		,A2V00370084997 : {
			de: "Unterteil f. Lagergeh., lack.",
			en: "Bearing housing lower part, coated"
		}
		,A2V00384200121 : {
			de: "SE 2,5X250 BOE FOX CN 23/12 MO-A",
			en: "SE 2, 5X250 BOE FOX CN 23/12 MO-A"
		}
		,A2V00384200286 : {
			de: "Stabelektrode BOE FOX EV 50 4X450",
			en: "Welding rod BOE FOX EV 50 4x450"
		}
		,A2V00397008437 : {
			de: "Rohrhalter, gerade",
			en: "Pipe holder,  straight"
		}
		,A2V00397122765 : {
			de: "Lasche f. Erdungsband",
			en: "Lug f. grounding strip"
		}
		,A2V00207450053 : {
			de: "Deformationselement",
			en: "in process Deformationselement"
		}
		,A2V00397216731 : {
			de: "U-Bogen",
			en: "U-bow"
		}
		,A2V00397216736 : {
			de: "Steg",
			en: "web"
		}
		,A2V00397216738 : {
			de: "Lasche",
			en: "plate"
		}
		,A2V00002315873 : {
			de: "Crash Modul vollst.",
			en: "crash modul complete"
		}
		,A2V00001879021 : {
			de: "FahrzeuggerÃ¤t 24V LZB80E MVB-Trafo, BWG",
			en: "Vehicle Device 24V LZB80E MVB-Trafo, BWG"
		}
		,A2V00397126982 : {
			de: "Untergurt SP.G.",
			en: "Lower flange O.A.D."
		}
		,A2V00397128352 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397128383 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00397128388 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397128428 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397128433 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397128439 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397128447 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397128450 : {
			de: "Erdungsbuchse",
			en: "Grounding Bush"
		}
		,A2V00397128462 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397128463 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397128464 : {
			de: "Anhebeoese DRS/LPZ",
			en: "Lift on Ear DRS/LPZ"
		}
		,A2V00397128468 : {
			de: "Achsschluessel",
			en: "Axle Rod"
		}
		,A2V00397128487 : {
			de: "Untergurt",
			en: "Lower Belt"
		}
		,A2V00397128488 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397128489 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397128491 : {
			de: "Anschlag",
			en: "Stop"
		}
		,A2V00397128492 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397128493 : {
			de: "Anschlag",
			en: "Stop"
		}
		,A2V00397128495 : {
			de: "Buegel",
			en: "Stirrup"
		}
		,A2V00397128496 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128505 : {
			de: "Lager",
			en: "Bearing"
		}
		,A2V00397128506 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128508 : {
			de: "Lager",
			en: "Bearing"
		}
		,A2V00397128509 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128510 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128511 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397128513 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397128515 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128516 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128517 : {
			de: "Seitenwangensteg",
			en: "Side Flanks Web"
		}
		,A2V00397128518 : {
			de: "Seitenwangensteg",
			en: "Side Flanks Web"
		}
		,A2V00397128519 : {
			de: "Untergurt",
			en: "Lower Belt"
		}
		,A2V00397128521 : {
			de: "Obergurt",
			en: "Upper Belt"
		}
		,A2V00397128522 : {
			de: "Obergurt",
			en: "Upper Belt"
		}
		,A2V00397128523 : {
			de: "Obergurt",
			en: "Upper Belt"
		}
		,A2V00397128524 : {
			de: "Obergurt",
			en: "Upper Belt"
		}
		,A2V00397128526 : {
			de: "Obergurt",
			en: "Upper Belt"
		}
		,A2V00397128528 : {
			de: "Steg",
			en: "web"
		}
		,A2V00397128529 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128530 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128531 : {
			de: "Gewindebutzen",
			en: "Thread Slug"
		}
		,A2V00397128532 : {
			de: "Buchse",
			en: "Bush"
		}
		,A2V00397128533 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128535 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00397128536 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397128537 : {
			de: "Anschlag",
			en: "Stop"
		}
		,A2V00397128538 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128539 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128540 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00397128543 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128544 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128546 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128547 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128548 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128550 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128553 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128554 : {
			de: "Obergurt",
			en: "Top Flange"
		}
		,A2V00397128561 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00397128567 : {
			de: "Obergurt",
			en: "Upper Belt"
		}
		,A2V00397128568 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128569 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128570 : {
			de: "Rippe",
			en: "Rip"
		}
		,A2V00397128571 : {
			de: "Rippe",
			en: "Rip"
		}
		,A2V00397128572 : {
			de: "Rippe",
			en: "Rip"
		}
		,A2V00397128573 : {
			de: "Untergurt",
			en: "Lower Belt"
		}
		,A2V00397128574 : {
			de: "Fuehrungsrohr",
			en: "Guiding Tube"
		}
		,A2V00397128575 : {
			de: "Obergurt",
			en: "Upper Belt"
		}
		,A2V00397128576 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128577 : {
			de: "Rohr",
			en: "Tube"
		}
		,A2V00397128578 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397128579 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397128580 : {
			de: "Kappe",
			en: "Cap"
		}
		,A2V00397128581 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128582 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00397128583 : {
			de: "Buchse",
			en: "Bush"
		}
		,A2V00397128584 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397128585 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397128587 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128589 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128591 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397128709 : {
			de: "GEWINDEBOLZEN",
			en: "thread bolt"
		}
		,A2V00397128718 : {
			de: "BUEGEL",
			en: "stirrup"
		}
		,A2V00397128719 : {
			de: "Gewindeklotz",
			en: "threaded block"
		}
		,A2V00397128720 : {
			de: "Buegel",
			en: "stirrup"
		}
		,A2V00397128721 : {
			de: "Gewindeklotz",
			en: "thread chunk"
		}
		,A2V00397128735 : {
			de: "Beilage 2mm",
			en: "Shim 2mm"
		}
		,A2V00397128736 : {
			de: "Beilage 1mm",
			en: "Shim 1mm"
		}
		,A2V00397128768 : {
			de: "Untergurt",
			en: "Lower Belt"
		}
		,A2V00000216639 : {
			de: "Gleismagnet 200V mit Kabelflansch",
			en: "Track magnet 200V with cable flange"
		}
		,A2V00397128769 : {
			de: "Obergurt",
			en: "Upper Belt"
		}
		,A2V00397165387 : {
			de: "KLEMMENKASTEN,VOLLST. =92-X192.01",
			en: "terminal box complete =92-X192.01"
		}
		,A2V00397128770 : {
			de: "Obergurt",
			en: "upper belt"
		}
		,A2V00397128773 : {
			de: "Gewindestueck",
			en: "union"
		}
		,A2V00397128774 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128775 : {
			de: "Befestigungsplatte",
			en: "fastening plate"
		}
		,A2V00397128776 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128777 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128778 : {
			de: "Steg",
			en: "web"
		}
		,A2V00397128780 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128781 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128782 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128783 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128784 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128785 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128786 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128787 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397128788 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397128789 : {
			de: "Anschlag",
			en: "Stop"
		}
		,A2V00397128791 : {
			de: "Winkel",
			en: "Angle"
		}
		,A2V00397128792 : {
			de: "Buchse",
			en: "Bush"
		}
		,A2V00397128793 : {
			de: "Gewindebutzen",
			en: "Thread Slug"
		}
		,A2V00397128797 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128800 : {
			de: "Untergurt",
			en: "Lower Belt"
		}
		,A2V00397128806 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128807 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128808 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397128809 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128811 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128812 : {
			de: "Entlastungsbolzen",
			en: "Traction Relief Bolt"
		}
		,A2V00397128813 : {
			de: "Entlastungsbolzen",
			en: "Traction Relief Bolt"
		}
		,A2V00397128814 : {
			de: "Entlastungsbolzen",
			en: "Traction Relief Bolt"
		}
		,A2V00397128815 : {
			de: "Gewindebutzen",
			en: "Thread Slug"
		}
		,A2V00397128816 : {
			de: "Gewindebutzen",
			en: "Thread Slug"
		}
		,A2V00397128819 : {
			de: "Entlastungsbolzen",
			en: "Traction Relief Bolt"
		}
		,A2V00397128822 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128823 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128825 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397128829 : {
			de: "Gewindebutzen",
			en: "Thread Slug"
		}
		,A2V00397128871 : {
			de: "Stange",
			en: "Bar"
		}
		,A2V00397128872 : {
			de: "Schaft",
			en: "Shank"
		}
		,A2V00397128888 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397128889 : {
			de: "Rohr",
			en: "Pipe"
		}
		,A2V00397128890 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397128896 : {
			de: "Winkel",
			en: "Angle"
		}
		,A2V00397128897 : {
			de: "Winkel",
			en: "Angle"
		}
		,A2V00397128898 : {
			de: "Winkel",
			en: "Angle"
		}
		,A2V00397128899 : {
			de: "Winkel",
			en: "Angle"
		}
		,A2V00397128901 : {
			de: "Scheibe",
			en: "Disc"
		}
		,A2V00397128974 : {
			de: "Blattfeder",
			en: "Compound spring"
		}
		,A2V00397128975 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397129090 : {
			de: "Rippe fuer MEGI-Halter",
			en: "Rip for chevron spring holder"
		}
		,A2V00397129254 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397129255 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397129256 : {
			de: "Rippe",
			en: "Rip"
		}
		,A2V00397129257 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397129258 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397129261 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397129263 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397129264 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397129265 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397129266 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397129267 : {
			de: "Winkel",
			en: "Angle"
		}
		,A2V00397129270 : {
			de: "Untergurt",
			en: "Lower belt"
		}
		,A2V00397129271 : {
			de: "Obergurt",
			en: "Upper belt"
		}
		,A2V00397129273 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397129274 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397129276 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397129277 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397129278 : {
			de: "Anschlagplatte",
			en: "Stop plate"
		}
		,A2V00397129279 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397129280 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397129281 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397129283 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397129284 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397129297 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397129299 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397129300 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397129301 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397129302 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397129315 : {
			de: "Steg",
			en: "Bar"
		}
		,A2V00397129316 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397129321 : {
			de: "Gewindebutzen",
			en: "threaded bush"
		}
		,A2V00397135840 : {
			de: "Beilage 1mm",
			en: "Shim 1mm"
		}
		,A2V00397138596 : {
			de: "Obergurt",
			en: "Upper Belt"
		}
		,A2V00397138609 : {
			de: "Gewindebutzen",
			en: "Thread Slug"
		}
		,A2V00397138613 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397138615 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397143031 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397144472 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00397144473 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397144474 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397144475 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397144828 : {
			de: "Radschutzkasten",
			en: "Wheel guard"
		}
		,A2V00397144878 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397144882 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397144883 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397144884 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397144885 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397144886 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397144887 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397144888 : {
			de: "Platte",
			en: "Plate"
		}
		,A2V00397147198 : {
			de: "Halter",
			en: "holder"
		}
		,A2V00397147199 : {
			de: "Halter",
			en: "holder"
		}
		,A2V00397150954 : {
			de: "Abhebesicherung primÃ¤r",
			en: "Lifting stop primary"
		}
		,A2V00001451939 : {
			de: "Wellblech",
			en: "Corrugation"
		}
		,A2V00001461674 : {
			de: "Wellblech",
			en: "Corrugation"
		}
		,A2V00001461675 : {
			de: "Wellblech",
			en: "Corrugation"
		}
		,A2V00397153735 : {
			de: "Distanzblech",
			en: "Distance plate"
		}
		,A2V00397154885 : {
			de: "Druckring unten",
			en: "Clamping ring below"
		}
		,A2V00397154887 : {
			de: "Druckring oben",
			en: "Clamping ring upper"
		}
		,A2V00397154894 : {
			de: "Befestigungsplatte",
			en: "Fastening plate"
		}
		,A2V00397156110 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00001407390 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001417611 : {
			de: "Wellblech",
			en: "Corrugation"
		}
		,A2V00001417940 : {
			de: "Wellblech",
			en: "Corrugation"
		}
		,A2V00001424170 : {
			de: "Spriegel",
			en: "Stick"
		}
		,A2V00001415607 : {
			de: "Quertraeger",
			en: "transverse girders"
		}
		,A2V00001397063 : {
			de: "Saeule oben",
			en: "Stick"
		}
		,A2V00001397773 : {
			de: "Saeule",
			en: "Stick"
		}
		,A2V00001398184 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001398246 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001398247 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001398248 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001400272 : {
			de: "Saeule unten",
			en: "Stick"
		}
		,A2V00001400273 : {
			de: "Saeule oben",
			en: "Stick"
		}
		,A2V00001405243 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001405244 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001405406 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001405407 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001405408 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001405849 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001406440 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001406505 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001407104 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001407105 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001407206 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001407207 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001409595 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001412435 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001424169 : {
			de: "Spriegel",
			en: "Stick"
		}
		,A2V00001428797 : {
			de: "Spriegel",
			en: "Stick"
		}
		,A2V00001428796 : {
			de: "Spriegel",
			en: "Stick"
		}
		,A2V00001417693 : {
			de: "Quertraeger",
			en: "Cross-bearer"
		}
		,A2V00001417610 : {
			de: "Bodenprofil",
			en: "Bottom profile"
		}
		,A2V00001417894 : {
			de: "Bodenprofil",
			en: "Bottom profile"
		}
		,A2V00001417892 : {
			de: "Bodenprofil",
			en: "Bottom profile"
		}
		,A2V00001417893 : {
			de: "Bodenprofil",
			en: "Bottom profile"
		}
		,A2V00397157140 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00001185064 : {
			de: "Glastrennscheibe  mit Bohrungen",
			en: "Draught screen    with holes"
		}
		,A2V00001400216 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001400387 : {
			de: "Saeule",
			en: "Stick"
		}
		,A2V00001400388 : {
			de: "Saeule",
			en: "Stick"
		}
		,A2V00001403741 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001404049 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001404050 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001404051 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001404412 : {
			de: "Saeule",
			en: "Stick"
		}
		,A2V00001404413 : {
			de: "Saeule",
			en: "Stick"
		}
		,A2V00001404414 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001407026 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001407070 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001407364 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001407388 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001407576 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001412290 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001400255 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001400262 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001400263 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001400264 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001400386 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001402472 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001406332 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001406333 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001406442 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001406443 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001406503 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001406626 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001407072 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001409594 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001411343 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001408569 : {
			de: "Spriegel",
			en: "Stick"
		}
		,A2V00001411304 : {
			de: "Spriegel",
			en: "Stick"
		}
		,A2V00001411321 : {
			de: "Spriegel",
			en: "Stick"
		}
		,A2V00001417680 : {
			de: "Wellblech",
			en: "Corrugation"
		}
		,A2V00001417682 : {
			de: "Bodenprofil",
			en: "Bottom profile"
		}
		,A2V00001422396 : {
			de: "Quertraeger",
			en: "Transverse girders"
		}
		,A2V00001428502 : {
			de: "Wellblech",
			en: "Corrugation"
		}
		,A2V00001428503 : {
			de: "Bodenprofil",
			en: "Bottom profile"
		}
		,A2V00001428504 : {
			de: "Bodenprofil",
			en: "Bottom profile"
		}
		,A2V00397282624 : {
			de: "FEDERBEILAGE 1 MM",
			en: "spring shim 1 mm"
		}
		,A2V00397282625 : {
			de: "FEDERBEILAGE 1.5 MM",
			en: "spring shim 1.5 mm"
		}
		,A2V00397282626 : {
			de: "FEDERBEILAGE 2 MM",
			en: "spring shim 2 mm"
		}
		,A2V00397282627 : {
			de: "FEDERBEILAGE 5 MM",
			en: "spring shim 5 mm"
		}
		,A2V00397282628 : {
			de: "FEDERBEILAGE 10 MM",
			en: "spring shim 10 mm"
		}
		,A2V00397214225 : {
			de: "SCHELLE",
			en: "CLIP"
		}
		,A2V00397132822 : {
			de: "Rippe fuer Wankstabilisatorkonsole W.G.",
			en: "Rib for anti rolling bracket A.D."
		}
		,A2V00397132823 : {
			de: "Rippe fuer Wankstabilisatorkonsole SP.G.",
			en: "Rib for anti rolling bracket O.A.D."
		}
		,A2V00397124073 : {
			de: "Konsole fuer Sekundaerdaempfer",
			en: "Bracket for secondary damper"
		}
		,A2V00397281874 : {
			de: "Drehzapfen",
			en: "centre pivot"
		}
		,A2V00397124076 : {
			de: "Schlingerdaempfer Anbindung SPG",
			en: "Lurch damper connection OAD"
		}
		,A2V00001189138 : {
			de: "Rippe fuer Schlingerdaempferkon. SP.G.",
			en: "Rib for lurch damper bracket O.A.D"
		}
		,A2V00001277406 : {
			de: "Schienenraeumer vollst.,wg.,lack.",
			en: "life guard compl.,a.d.,painted"
		}
		,A2V00117515109 : {
			de: "Erdungslasche",
			en: "Grounding connection"
		}
		,A2V00397164282 : {
			de: "Beilage 5MM",
			en: "Shim 5mm"
		}
		,A2V00001428697 : {
			de: "Zug-/ Druckstange",
			en: "pull-/ push rod"
		}
		,A2V00002024208 : {
			de: "Halter Z2",
			en: "Holder Z2"
		}
		,A2V00397169032 : {
			de: "Halter f. Sandung SP.G.",
			en: "Attachment for sanding SP.G"
		}
		,A2V00397212807 : {
			de: "Puffer komplett",
			en: "Buffer complete"
		}
		,A2V00001908259 : {
			de: "Lagerdeckel lackiert",
			en: "Bearing cover painted"
		}
		,A2V00397241793 : {
			de: "Leiter,vollst. SPG",
			en: "Ladder,complete OAD"
		}
		,A2V00397166480 : {
			de: "Wankstabkonsole",
			en: "bracket anti  roll bar"
		}
		,A2V00397166482 : {
			de: "Konsole f. Laengsmitnahme",
			en: "Bracket f. traction link"
		}
		,A2V00397166485 : {
			de: "Steg f. Laengsmitnahme",
			en: "Web f. traction link"
		}
		,A2V00397166486 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397166487 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00397167674 : {
			de: "Lasche f. Bremskonsole",
			en: "Ear f. brake bracket"
		}
		,A2V00397167813 : {
			de: "Platte f. Querspielbegrenzungskonsole",
			en: "Plate f. cross clearance limit. bracket"
		}
		,A2V00397167827 : {
			de: "Anschlussblech WG f. Schlingerd.",
			en: "Connection plate AD f. yaw damper"
		}
		,A2V00397167828 : {
			de: "Anschlussblech SPG f. Schlingerd.",
			en: "Connection plate OAD f. yaw damper"
		}
		,A2V00397168156 : {
			de: "Rippe f. Schlingerd. innen",
			en: "Rib f. yaw damper inside"
		}
		,A2V00397168160 : {
			de: "Schweissbadsicherung",
			en: "Backing strip"
		}
		,A2V00397168207 : {
			de: "Anschlussplatte f. Querdaempfer JDG",
			en: "Connection plate f. cross damper JB"
		}
		,A2V00397168208 : {
			de: "Rippe f. Querdaempferkonsole JDG",
			en: "Rip f. cross damper bracket JB"
		}
		,A2V00397174292 : {
			de: "Deckel WG f. Bremskonsole JDG",
			en: "Cover AD f. brake bracket TB"
		}
		,A2V00397174293 : {
			de: "Deckel SPG f. Bremskonsole JDG",
			en: "Cover OAD f. brake bracket TB"
		}
		,A2V00082033545 : {
			de: "Leiterplatte ANT5A Sende Modul",
			en: "Printed circuit board ANT5A Transmitter"
		}
		,A2V00082033563 : {
			de: "Leiterplatte ANT5A-EM",
			en: "Printed circuit board ANT5A-EM"
		}
		,A2V00082033571 : {
			de: "Leiterplatte ANT5A-VM",
			en: "Printed circuit board ANT5A-VM"
		}
		,A2V00002547170 : {
			de: "Unterlage Rad Typ5",
			en: "wheel support block type5"
		}
		,A2V00002725793 : {
			de: "Einpressversuch Rohteil",
			en: "Fitting test raw part"
		}
		,A2V00397175663 : {
			de: "Obergurt f. Mittelverb. JLDG",
			en: "Upper chord f. center beam JTB"
		}
		,A2V00397175664 : {
			de: "Untergurt",
			en: "Lower chord"
		}
		,A2V00397176527 : {
			de: "TRAEGERPLATTE",
			en: "beam plate"
		}
		,A2V00397176551 : {
			de: "SCHIENENRAEUMERBLECH W.G.",
			en: "rail guard sheet a.d."
		}
		,A2V00397181162 : {
			de: "STEG",
			en: "web"
		}
		,A2V00397181164 : {
			de: "GURT F.INDUSI-KONSOLE",
			en: "belt f. INDUSI-bracket"
		}
		,A2V00397181165 : {
			de: "GURT F.INDUSI-KONSOLE",
			en: "belt f. INDUSI-bracket"
		}
		,A2V00397181167 : {
			de: "HALTER F.SPKS",
			en: "holder f. wheel flange lubrication"
		}
		,A2V00397181168 : {
			de: "VERSTELLBLECH",
			en: "adjust sheet"
		}
		,A2V00397181170 : {
			de: "Abdeckung",
			en: "cover"
		}
		,A2V00397183652 : {
			de: "Hohlbuchse",
			en: "cylindrical bush"
		}
		,A2V00397187921 : {
			de: "RUNDBOLZEN",
			en: "round bolt"
		}
		,A2V00002215692 : {
			de: "RSL Waelzlager NTN-SNR TBU 150x250",
			en: "AB rolling bearing NTN-SNR TBU 150x250"
		}
		,A2V00002215690 : {
			de: "RSL Waelzlager SKF CRU 150x250",
			en: "AB rolling bearing SKF CRU 150x250"
		}
		,A2V00001948367 : {
			de: "Bodenplatte",
			en: "Base plate"
		}
		,A2V00001930934 : {
			de: "Untergurt, Quertraeger",
			en: "Lower flange, cross girder"
		}
		,A2V00002597324 : {
			de: "Beilage 5mm",
			en: "shim 5mm"
		}
		,A2V00002184974 : {
			de: "Schienenraeumer vorne,sp.g.",
			en: "Railguard front, o.a.d."
		}
		,A2V00002184975 : {
			de: "Schienenraeumer vorne,w.g.",
			en: "Railguard front, a.d."
		}
		,A2V00002221612 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002265288 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002621824 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00002622039 : {
			de: "Rohr-Anschweissteil",
			en: "Pipe-weld on part"
		}
		,A2V00002622874 : {
			de: "Befestigungsblech w.g.",
			en: "Sheet a.d."
		}
		,A2V00002623210 : {
			de: "Befestigungsblech sp.g.",
			en: "mounting plate oa.d."
		}
		,A2V00002623360 : {
			de: "Befestigungsblech 2",
			en: "mounting plate 2"
		}
		,A2V00002624587 : {
			de: "Klmh.Paar-LKP-G-21,5-35-PA",
			en: "Clamp-LKP-G-21,5-35-PA"
		}
		,A2V00397217563 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397239966 : {
			de: "Integralbehaelter WG",
			en: "Air reservoir AD"
		}
		,A2V00397239967 : {
			de: "Integralbehaelter SPG",
			en: "Air reservoir OAD"
		}
		,A2V00397241035 : {
			de: "Daempferkonsole WG",
			en: "Damper bracket AD"
		}
		,A2V00397241037 : {
			de: "Daempferkonsole SPG",
			en: "Damper bracket OAD"
		}
		,A2V00002134317 : {
			de: "Bahnraeumer",
			en: "track clearer"
		}
		,A2V00397242159 : {
			de: "Blech",
			en: "Plate"
		}
		,A2V00397244421 : {
			de: "Halter f. Bremszylinder",
			en: "Holder f. brake cylinder"
		}
		,A2V00397248415 : {
			de: "Daempferkonsole",
			en: "Braket for damper"
		}
		,A2V00397248587 : {
			de: "Bolzen lang",
			en: "Pin long"
		}
		,A2V00397248588 : {
			de: "Bolzen kurz",
			en: "Pin short"
		}
		,A2V00397248792 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397272207 : {
			de: "Gleitbuchse Sekundaerfeder D=48mm",
			en: "Slide sleeve sec. spring D=48mm"
		}
		,A2V00397276461 : {
			de: "Halter wg",
			en: "Holder ad"
		}
		,A2V00397276462 : {
			de: "Halter spg",
			en: "Holder oad"
		}
		,A2V00397276464 : {
			de: "PLATTE",
			en: "PLATE"
		}
		,A2V00397281707 : {
			de: "Q.P. Rippe",
			en: "L.B.B. Rib"
		}
		,A2V00397281964 : {
			de: "DRUCKLUFTROHR",
			en: "AIR PIPE"
		}
		,A2V00397284543 : {
			de: "Zahnplatte",
			en: "Ribbed plate"
		}
		,A2V00397290612 : {
			de: "PRUEFSTUECK     BL12x125x200-45/2",
			en: "test piece     pl 12x125x200-45/2"
		}
		,A2V00397290616 : {
			de: "PRUEFSTUECK     BL12x125x220",
			en: "test piece     pl 12x125x220"
		}
		,A2V00397290619 : {
			de: "PRUEFSTUECK    BL12x125x300-22,5/1",
			en: "test piece    pl 12x125x300-22, 5/1"
		}
		,A2V00002714200 : {
			de: "Scheibe f. Radsatzmontage",
			en: "Washer f. wheelset ass."
		}
		,A2V00002714201 : {
			de: "Scheibe f. Transport-",
			en: "Washer f. transport lock"
		}
		,A2V00002693791 : {
			de: "Quertraeger",
			en: "Crossbar"
		}
		,A2V00001182360 : {
			de: "Gummipuffer f. Abschleppvorr. vollst.",
			en: "Rubber Buffer f. Towing Device compl."
		}
		,A2V00001706196 : {
			de: "Spannhuelsenschraube M16x60 komplett",
			en: "Sleeve bolt M16x60 complete"
		}
		,A2V00397290618 : {
			de: "PRUEFSTUECK     BL12x125x300-45/1",
			en: "test piece     pl 12x125x300-45/1"
		}
		,A2V00001187151 : {
			de: "Lenker",
			en: "steering rod"
		}
		,A2V00002023913 : {
			de: "Schienenraeumer Anbau",
			en: "Lifeguard assembly"
		}
		,A2V00397012504 : {
			de: "Sicherungsschraube       SGP300",
			en: "Locking Screw"
		}
		,A2V00002265660 : {
			de: "Distanzstueck,lackiert",
			en: "Distance piece,painted"
		}
		,A2V00001253677 : {
			de: "Beilage",
			en: "shim"
		}
		,A2V00002613707 : {
			de: "Leitung WIG/OPG-Sensorkabel(Wellrohr)",
			en: "Cable WIG/OPG sensorcable(wall conduit)"
		}
		,A2V00001290648 : {
			de: "Luftfederungsventil SV1205-D10X2",
			en: "Levelling valve SV1205-D10X2"
		}
		,A2V00002565046 : {
			de: "Platte geschweiszt",
			en: "Plate welded"
		}
		,A2V00001373150 : {
			de: "Puffer f. Schraubenisolierung",
			en: "buffer for isolation of screw"
		}
		,A2V00002100942 : {
			de: "Kabelhalter",
			en: "Bracket for cable"
		}
		,A2V00002101249 : {
			de: "Funkenschildkonsole WG",
			en: "Bracket for arcshield ad"
		}
		,A2V00080066003 : {
			de: "Zunge",
			en: "Point blade"
		}
		,A2V00002120405 : {
			de: "Druckbehaelter Stahl 100L",
			en: "air receiver steel 100L"
		}
		,A2V00002190478 : {
			de: "Blech 1; Rohr- Kabeltraeger",
			en: "Plate 1; pipe- cable support"
		}
		,A2V00002076473 : {
			de: "Konsole fuer Sekundaerdaempfer Roh",
			en: "Bracket for secondary damper raw"
		}
		,A2V00002676940 : {
			de: "Federbeilage 2mm",
			en: "Spring shim 2mm"
		}
		,A2V00002676941 : {
			de: "Federbeilage 1mm",
			en: "Spring shim 1mm"
		}
		,A2V00002156003 : {
			de: "Buchse",
			en: "Bush"
		}
		,A2V00002738859 : {
			de: "DehnhÃ¼lse",
			en: "Extension sleeve"
		}
		,A2V00002702507 : {
			de: "Kabelstuetzblech",
			en: "cable fixing plate"
		}
		,A2V00002702508 : {
			de: "Kabelstuetzblech",
			en: "cable fixing plate"
		}
		,A2V00002089985 : {
			de: "Sekundaerkonsole spg",
			en: "Secondary vertical"
		}
		,A2V00002316525 : {
			de: "Getriebe mit Treibradsatzwelle KE505",
			en: "Gearbox with motor axle KE505"
		}
		,A2V00002316523 : {
			de: "Getriebe mit Treibradsatzwelle SK505",
			en: "Gearbox with motor axle SK505"
		}
		,A2V00001418432 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002505860 : {
			de: "Schutzblech geschw. EW",
			en: "Protection plate weld. EC"
		}
		,A2V00001445210 : {
			de: "Bolzen",
			en: "Pin"
		}
		,A2V00001445211 : {
			de: "Fuehrungsbolzen",
			en: "Pin rod"
		}
		,A2V00002406481 : {
			de: "Radsatzlager",
			en: "Axle box rolling bearing"
		}
		,A2V00397129563 : {
			de: "ANSCHLAG                         DRS/LPZ",
			en: "stop                         DRS/LPZ"
		}
		,A2V00397120389 : {
			de: "Konsole f. Querspielbegrenzung",
			en: "bracket f. cross clearance limiting"
		}
		,A2V00002029569 : {
			de: "V TR2  Steg 1",
			en: "J TR2  web 1"
		}
		,A2V00002575884 : {
			de: "Schutzblech",
			en: "Protection plate"
		}
		,A2V00001660258 : {
			de: "Beilage 1 mm",
			en: "Shim 1 mm"
		}
		,A2V00002522090 : {
			de: "Grundblech Typenschild",
			en: "Base plate specification"
		}
		,A2V00002076545 : {
			de: "Schellenkonsole 2 geschweisst",
			en: "Console for clamps 2 welded"
		}
		,A2V00002076676 : {
			de: "Schellenkonsole 3 geschweisst",
			en: "Console fof clamps 3 welded"
		}
		,A2V00002373916 : {
			de: "Vollrad, lackiert 920/858-19-214",
			en: "Solid wheel, painted 920/858-19-214"
		}
		,A2V00002086647 : {
			de: "V Spanneisen",
			en: "J clamp"
		}
		,A2V00001673103 : {
			de: "Stuetzring Zug-Druck Stange",
			en: "Reatining ring push-pull rod"
		}
		,A2V00002683928 : {
			de: "Verdrehsichere Unterlegscheibe",
			en: "BackUp Washer"
		}
		,A2V00002422178 : {
			de: "Scheibe 13-45",
			en: "Shim 13-45"
		}
		,A2V00001852288 : {
			de: "Spriegel",
			en: "Stick"
		}
		,A2V00001852289 : {
			de: "Spriegel",
			en: "Stick"
		}
		,A2V00001852291 : {
			de: "Spriegel",
			en: "Stick"
		}
		,A2V00002454487 : {
			de: "Profil Schottwand UG",
			en: "profile partition u.f."
		}
		,A2V00002454486 : {
			de: "Profil Schottwand UG",
			en: "profile partition u.f."
		}
		,A2V00002413120 : {
			de: "Konsole Laengsmitnahme WK",
			en: "Bracket traction rod CB"
		}
		,A2V00001754538 : {
			de: "Fuehrungsstueck AL",
			en: "Guiding Piece AL"
		}
		,A2V00001759184 : {
			de: "Konsole fuer Sandung w.g.",
			en: "Console for sanding a.d."
		}
		,A2V00001759185 : {
			de: "Konsole fuer Sandung sp.g.",
			en: "Console for sanding o.a.d."
		}
		,A2V00001759206 : {
			de: "Konsole fuer Sandung",
			en: "Console for sanding"
		}
		,A2V00001759207 : {
			de: "Konsole fuer Sandung w.g.",
			en: "Console for sanding a.d."
		}
		,A2V00001759208 : {
			de: "Konsole fuer Sandung sp.g.",
			en: "Console for sanding o.a.d."
		}
		,A2V00001759209 : {
			de: "Konsole fuer Sandung sp.g.",
			en: "Console for sanding o.a.d."
		}
		,A2V00001759210 : {
			de: "Konsole fuer Sandung sp.g.",
			en: "Console for sanding o.a.d."
		}
		,A2V00001759211 : {
			de: "Konsole fuer Sandung sp.g.",
			en: "Console for sanding o.a.d."
		}
		,A2V00001759212 : {
			de: "Konsole fuer Sandung w.g.",
			en: "Console for sanding a.d."
		}
		,A2V00001759213 : {
			de: "Konsole fuer Sandung w.g.",
			en: "Console for sanding a.d."
		}
		,A2V00001759214 : {
			de: "Konsole fuer Sandung w.g.",
			en: "Console for sanding a.d."
		}
		,A2V00001759215 : {
			de: "Konsole fuer Sandung w.g.",
			en: "Console for sanding a.d."
		}
		,A2V00001759216 : {
			de: "Konsole fuer Sandung sp.g.",
			en: "Console for sanding o.a.d."
		}
		,A2V00001776076 : {
			de: "Unterlage Sekundaerfeder",
			en: "Shim Secondary Spring"
		}
		,A2V00002551382 : {
			de: "Befestigung Kupplung vollst.",
			en: "Holder coupler compl."
		}
		,A2V00002429045 : {
			de: "Deckel lackiert",
			en: "Cover painted"
		}
		,A2V00002232425 : {
			de: "Halter vollst. geschweisst",
			en: "Bracket compl. welded"
		}
		,A2V00001103878 : {
			de: "Kompaktumrichter-Kern Classic DC750V",
			en: "Compact inverter core classic DC750V"
		}
		,A2V00002589165 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002444978 : {
			de: "Kabelhalter 02",
			en: "Cable bracket 02"
		}
		,A2V00002436549 : {
			de: "Beilage",
			en: "Shim"
		}
		,A2V00001841497 : {
			de: "Schutzblech",
			en: "Protection panel"
		}
		,A2V00002426130 : {
			de: "Klappsitzgestell",
			en: "Folding seat frame"
		}
		,A2V00001878084 : {
			de: "Radsatzlagergeh. rechts",
			en: "axlebox housing right"
		}
		,A2V00001878085 : {
			de: "Radsatzlagergeh. links",
			en: "axle box housing left"
		}
		,A2V00002194738 : {
			de: "Uebergang Doppelgelenk",
			en: "Gangway double articulation"
		}
		,A2V00002194737 : {
			de: "Uebergang Einfachgelenk wankfaehig",
			en: "Gangway single articulation rollable"
		}
		,A2V00002418998 : {
			de: "Einschweissrohr",
			en: "Weld-in pipe"
		}
		,A2V00001890737 : {
			de: "Halter fuer langen Flexballzug",
			en: "Bracket for long Flexball cable"
		}
		,A2V00002406149 : {
			de: "Rippe, Dach, sp.g.",
			en: "Web, roof, i.m.i."
		}
		,A2V00002377440 : {
			de: "Vertikaldaempferkonsole",
			en: "Vertical damper supp."
		}
		,A2V00002559709 : {
			de: "C-Schiene Nosta 15",
			en: "C-rail Nosta 15"
		}
		,A2V00002559712 : {
			de: "C-Schiene Nosta 15",
			en: "C-rail Nosta 15"
		}
		,A2V00002610815 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002611150 : {
			de: "Halter",
			en: "Bracket"
		}
		,A2V00002613536 : {
			de: "Konsole ZSB",
			en: "Console ASSY"
		}
		,A2V00002544840 : {
			de: "Rippe, Stirnwand Klimawanne",
			en: "Rib, ACU pan endwall"
		}
		,A2V00002590291 : {
			de: "Tuersaeule ZSB",
			en: "Door post ASSY"
		}
		,A2V00002591758 : {
			de: "Kasten, vollst.",
			en: "Kasten, compl."
		}
		,A2V00002406551 : {
			de: "Blechteil fuer Arbeitsproben",
			en: "Sheet piece for work samples"
		}
		,A2V00002325864 : {
			de: "Stromrichtercontainer Metro Riyadh",
			en: "Inverter container Metro Riyadh"
		}
		,A2V00002388773 : {
			de: "Feuerschutzblech",
			en: "Fire protection panel"
		}
		,A2V00002518644 : {
			de: "Pruefstueck T=3 C=1 W=25Â°",
			en: "Test piece T=3 C=1 W=25Â°"
		}
		,A2V00001925676 : {
			de: "Scheibe",
			en: "Washer"
		}
		,A2V00002160875 : {
			de: "Lackmuster Edelstahl",
			en: "Varnishing sample Stainless steel"
		}
		,A2V00002542444 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002498014 : {
			de: "Anschweissblech w.g.",
			en: "Welding plate a.d."
		}
		,A2V00002573578 : {
			de: "Deckblech w.g.",
			en: "Covering plate a.d."
		}
		,A2V00002573577 : {
			de: "Deckblech sp.g.",
			en: "Covering plate i.m.i."
		}
		,A2V00002542609 : {
			de: "Verzurrlasche",
			en: "Lash plate"
		}
		,A2V00002542325 : {
			de: "Ausgleichsblech sp.g.",
			en: "Compensating panel i.m.i."
		}
		,A2V00002542324 : {
			de: "Ausgleichsblech w.g.",
			en: "Compensating panel a.d."
		}
		,A2V00002578307 : {
			de: "Ventiltafel Tieftemp. 2-stufig f.STA A/D",
			en: "Valve panel low temp. 2-steps Panto A/D"
		}
		,A2V00002544639 : {
			de: "Stromverbinder 35mm2 - 290 LG",
			en: "Current connector 35mm2 - 290 LG"
		}
		,A2V00002695491 : {
			de: "Wippe 1800/AC kompl.",
			en: "Collector head 1800/AC compl."
		}
		,A2V00112401559 : {
			de: "Nutenstein",
			en: "slot nut"
		}
		,A2V00002540205 : {
			de: "Fraesteil Anhebestelle w.g.",
			en: "Milled part jacking pad a.d."
		}
		,A2V00001957221 : {
			de: "Oberplatte beschichtet",
			en: "Top plate coated"
		}
		,A2V00001958737 : {
			de: "Oberplatte roh",
			en: "top plate raw"
		}
		,A2V00002495615 : {
			de: "Voute, unten, WE1, sp.g.",
			en: "Cove, lower, CE1, i.m.i."
		}
		,A2V00002495614 : {
			de: "Voute, unten, WE1, w.g.",
			en: "Cove, lower, CE1, a.d."
		}
		,A2V00002030674 : {
			de: "Grundbl. Typenschild TDG",
			en: "Base pl. specification MB"
		}
		,A2V00002042511 : {
			de: "Winkel 2",
			en: "angle 2"
		}
		,A2V00002613694 : {
			de: "Bef. f. Pantorohr w.g.",
			en: "Mount. f. panto. pipe a.d."
		}
		,A2V00002596450 : {
			de: "Winkel f. Tuersaeule",
			en: "Bracket f. door post"
		}
		,A2V00002101645 : {
			de: "Winkel sp.g.",
			en: "Angle o.a.d."
		}
		,A2V00002101664 : {
			de: "Steg sp.g.",
			en: "Bar o.a.d."
		}
		,A2V00002101843 : {
			de: "Rippe sp.g.",
			en: "Web o.a.d."
		}
		,A2V00002101879 : {
			de: "Rippe sp.g.",
			en: "Web o.a.d."
		}
		,A2V00002101988 : {
			de: "Traeger f. Sandung w.g.",
			en: "Support f. sander a.d."
		}
		,A2V00002101989 : {
			de: "Traeger f. Sandung sp.g.",
			en: "Support f. sander o.a.d."
		}
		,A2V00397165388 : {
			de: "KLEMMENKASTEN,VOLLST. =92-X192.02",
			en: "terminal box complete =92-X192.02"
		}
		,A2V00002106157 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002599786 : {
			de: "Bremsgeraeteeinheit BTSC-GL",
			en: "Brake equipment unit BTSC-GL"
		}
		,A2V00002121290 : {
			de: "BG CS Stiftschraube",
			en: "BG CS stud"
		}
		,A2V00002594338 : {
			de: "Entwaesserungsrohr ZSB",
			en: "Drainage pipe ASSY"
		}
		,A2V00002540277 : {
			de: "Fraesteil Anhebestelle sp.g.",
			en: "Milled part jacking pad i.m.i."
		}
		,A2V00002533527 : {
			de: "Deckblech, w.g.",
			en: "Cover sheet, a.d."
		}
		,A2V00002190848 : {
			de: "Drehmomentstuetze",
			en: "Torque support"
		}
		,A2V00002192414 : {
			de: "Puffer, Laengsmitnahme",
			en: "Longitudinal buffer"
		}
		,A2V00002281101 : {
			de: "Quertraeger Schiebetritt, sp.g.",
			en: "Cross beam sliding step, i.m.i."
		}
		,A2V00002553958 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00002625499 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002265208 : {
			de: "Distanzstueck",
			en: "Distance piece"
		}
		,A2V00002315988 : {
			de: "DC/DC Wandler 30 W, 110 V, 24 V, 1,25 A",
			en: "DC/DC Converter 30 W, 110 V, 24 V, 1.25A"
		}
		,A2V00002366352 : {
			de: "Stegblech sp. g.",
			en: "Web plate o.a. d."
		}
		,A2V00002366456 : {
			de: "Halter Schienenraeumer sp.g.",
			en: "Bracket track sweeper"
		}
		,A2V00002366596 : {
			de: "Stegblech w.g.",
			en: "Web plate a.d."
		}
		,A2V00002522001 : {
			de: "Konsole 125x65, E-Schrank/ WC-Tank",
			en: "console 125x65, e-cabinet/ WC-tank"
		}
		,A2V00002550051 : {
			de: "Fahnenhalter",
			en: "Flag holder"
		}
		,A2V00001008157 : {
			de: "GTO-Stromrichter 1120 kW Einsystem",
			en: "GTO"
		}
		,A2V00002406706 : {
			de: "Daempfer sekundaer vertikal",
			en: "secondary vertical shock absorber"
		}
		,A2V00002423756 : {
			de: "Montageplatte",
			en: "Mounting board"
		}
		,A2V00002505050 : {
			de: "Abdeckblech, vollst.",
			en: "Cover sheet, compl."
		}
		,A2V00002534458 : {
			de: "Haltewinkel geschw.",
			en: "Holder welded"
		}
		,A2V00002539594 : {
			de: "Rippe, sp.g.",
			en: "Rib, i.m.i."
		}
		,A2V00002529722 : {
			de: "Kopfwinkel, kpl., rechts",
			en: "Head angle, compl., right"
		}
		,A2V00002529690 : {
			de: "Kopfwinkel kpl., links",
			en: "Head angle, compl. left"
		}
		,A2V00002533456 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002530304 : {
			de: "Bodenplatte Profil",
			en: "Floor plate profile"
		}
		,A2V00002530298 : {
			de: "B-Saeule Profil",
			en: "B-pillar profile"
		}
		,A2V00002465178 : {
			de: "Dachschott Profil",
			en: "bulkhead roof profile"
		}
		,A2V00002465177 : {
			de: "Dachschott Profil",
			en: "bulkhead roof profile"
		}
		,A2V00002465058 : {
			de: "Profil Quertraeger Dach",
			en: "profile cross girder roof"
		}
		,A2V00002464884 : {
			de: "Querprofil, Bodenplatte",
			en: "cross profile, floor plate"
		}
		,A2V00001687759 : {
			de: "Blech EN 10143 1,5X1500X3000 DX51D+Z275",
			en: "Sheet EN 10143 1,5X1500X3000 DX51D+Z275"
		}
		,A2V00002574062 : {
			de: "Traeger mech. bearb.",
			en: "Beam machined"
		}
		,A2V00001692054 : {
			de: "Schraubenkupplung lackiert",
			en: "screw coupling coated"
		}
		,A2V00001692055 : {
			de: "Zugeinrichtung",
			en: "drawgear"
		}
		,A2V00002527906 : {
			de: "Verstaerkungsprofil Tuersaeule",
			en: "reinforcement profile door pillar"
		}
		,A2V00002503976 : {
			de: "Stirnwandprofil Saeule",
			en: "endwall profile tie bar"
		}
		,A2V00002503725 : {
			de: "Stirnwandprofil quer",
			en: "endwall profile crosswise"
		}
		,A2V00002598103 : {
			de: "Fassung geschw. sp.g.",
			en: "Sleeve welded i.m.i."
		}
		,A2V00001707678 : {
			de: "HBU-Transformator T-D",
			en: "Aux. conv. transformer T-D"
		}
		,A2V00001709356 : {
			de: "Wellschlauchleitung",
			en: "corrugated tube"
		}
		,A2V00002692754 : {
			de: "Zug-/ Druckstange",
			en: "pull-/ push rod"
		}
		,A2V00002699626 : {
			de: "Badsicherung w.g.",
			en: "weld pool backing as d."
		}
		,A2V00002699627 : {
			de: "Badsicherung sp.g.",
			en: "weld pool backing r.i."
		}
		,A2V00002582714 : {
			de: "Hauptquertraeger 3 bearbeitet",
			en: "main cross girder 3 machined"
		}
		,A2V00002581105 : {
			de: "Hauptquertraeger 2 bearbeitet",
			en: "main cross girder 2 machined"
		}
		,A2V00002533109 : {
			de: "Hauptquertraeger bearbeitet",
			en: "main cross girder machined"
		}
		,A2V00002736911 : {
			de: "Dichtrahmen 6, Profil",
			en: "Sealing frame 6, profile"
		}
		,A2V00002735079 : {
			de: "Einfassprofil 3",
			en: "ACU-Profile 3"
		}
		,A2V00002718862 : {
			de: "Halter 1 Sandung",
			en: "Holder 1 sanding"
		}
		,A2V00002718863 : {
			de: "Halter 2 Sandung",
			en: "Holder 2 sanding"
		}
		,A2V00002738795 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002739892 : {
			de: "Distanzring",
			en: "Spacer ring"
		}
		,A2V00002740486 : {
			de: "Distanzbuchse",
			en: "Spacer ring"
		}
		,A2V00002759694 : {
			de: "Ausschnitt-Langtraegerobergurt",
			en: "Cutting - Longitudinal beam upper flange"
		}
		,A2V00002759705 : {
			de: "Grundplatte geschw.",
			en: "Base plate welded"
		}
		,A2V00002759738 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00002759863 : {
			de: "Beilage 1",
			en: "Shim 1"
		}
		,A2V00002759864 : {
			de: "Beilage 2",
			en: "Shim 2"
		}
		,A2V00002786204 : {
			de: "Langtraeger unten sp.g.",
			en: "Long. beam bottom i.m.i."
		}
		,A2V00002786205 : {
			de: "Langtraeger unten w.g.",
			en: "Long. beam bottom a.d."
		}
		,A2V00002786354 : {
			de: "Langtraeger unten w.g.",
			en: "Long. beam bottom a.d."
		}
		,A2V00002786355 : {
			de: "Langtraeger unten sp.g.",
			en: "Long. beam bottom i.m.i."
		}
		,A2V00002752639 : {
			de: "Dachplatte 1",
			en: "Roofplate 1"
		}
		,A2V00002766910 : {
			de: "Dachplatte, aussen 2",
			en: "Roofplate, outer 2"
		}
		,A2V00002766911 : {
			de: "Dachplatte, aussen 1",
			en: "Roofplate, outer 1"
		}
		,A2V00002766912 : {
			de: "Dachplatte, innen 1",
			en: "Roofplate, inner 1"
		}
		,A2V00002766913 : {
			de: "Dachplatte, innen 2",
			en: "Roofplate, inner 2"
		}
		,A2V00002759696 : {
			de: "Schweissbadsicherung 1",
			en: "Weld backing 1"
		}
		,A2V00002759605 : {
			de: "Schweissbadsicherung 3",
			en: "Backing 3"
		}
		,A2V00002759601 : {
			de: "Schweissbadsicherung WE1",
			en: "Weld backing WE1"
		}
		,A2V00002796240 : {
			de: "Blech f. Rahmen",
			en: "Sheet for frame"
		}
		,A2V00002757148 : {
			de: "Stirnwandblech vollst. WE2 sp.g.",
			en: "End wall sheet compl. CE2 i.m.i."
		}
		,A2V00002757117 : {
			de: "Kragen vollst.",
			en: "Flange compl."
		}
		,A2V00002757111 : {
			de: "Radkasten vollst. sp.g.",
			en: "Wheelhouse compl. i.m.i."
		}
		,A2V00002757108 : {
			de: "Radkasten vollst. w.g.",
			en: "Wheelhouse compl. a.d."
		}
		,A2V00002757027 : {
			de: "Stirnwandblech vollst. WE1",
			en: "End wall sheet compl. CE1"
		}
		,A2V00002756943 : {
			de: "Deckblech vollst. sp.g.",
			en: "Cover sheet compl. i.m.i."
		}
		,A2V00002756939 : {
			de: "Deckblech vollst. w.g",
			en: "Cover sheet compl. a.d."
		}
		,A2V00002756930 : {
			de: "Bodenprofil f. Vorbau vollst.",
			en: "Floor profile f. front end compl."
		}
		,A2V00002756913 : {
			de: "Haupquertraeger vollst.",
			en: "Main cross beam compl."
		}
		,A2V00002756905 : {
			de: "Anschluss Bodenplatte bearb.",
			en: "Floor connection machined"
		}
		,A2V00002756920 : {
			de: "Stirnwandblech vollst. WE2 w.g.",
			en: "End wall sheet compl. CE2 a.d."
		}
		,A2V00002756903 : {
			de: "Zwischenprofil Einzelteil",
			en: "Part profile floor"
		}
		,A2V00002791050 : {
			de: "Fuehrungszapfen",
			en: "Pilot"
		}
		,A2V00002791051 : {
			de: "Fuehrungszapfen",
			en: "Pilot"
		}
		,A2V00002792383 : {
			de: "Gehaeuse UT",
			en: "Casing bottom"
		}
		,A2V00002792384 : {
			de: "Spannhuelse klein",
			en: "Clamping sleeve small"
		}
		,A2V00002792385 : {
			de: "Spannhuelse groÃŸ",
			en: "Clamping sleeve big"
		}
		,A2V00002795330 : {
			de: "Platte FDÃœ lackiert",
			en: "plate FDUE lacquered"
		}
		,A2V00002796447 : {
			de: "Spannhuelse klein V2",
			en: "Clamping sleeve small V2"
		}
		,A2V00002796448 : {
			de: "Spannhuelse groÃŸ V2",
			en: "Clamping sleeve big V2"
		}
		,A2V00002796777 : {
			de: "Halter FDÃœ Motor lackiert",
			en: "holder FDUE motor lacquered"
		}
		,A2V00002796778 : {
			de: "Halter FDÃœ Radsatzlager wg lackiert",
			en: "BM holder axle box ad lacquered"
		}
		,A2V00002796779 : {
			de: "Halter FDÃœ Radsatzlager spg lackiert",
			en: "BM holder axle box o.a.d. lacquered"
		}
		,A2V00002797229 : {
			de: "Distanzscheibe",
			en: "Spacer"
		}
		,A2V00001757892 : {
			de: "Hauptschalter AC incl. Erdungstrenner",
			en: "Circuit breaker AC with earthing discon."
		}
		,A2V00001788011 : {
			de: "Kondensatsammelbehaelter",
			en: "Condens. equipment"
		}
		,A2V00002761129 : {
			de: "Behindertenplatz geschw.",
			en: "Handicapped place welded"
		}
		,A2V00002759793 : {
			de: "Nutenstein",
			en: "Sliding block"
		}
		,A2V00002801472 : {
			de: "Dachplatte, aussen 2",
			en: "Roofplate, outer 2"
		}
		,A2V00002801481 : {
			de: "Dachplatte, innen 2",
			en: "Roofplate, inner 2"
		}
		,A2V00002801482 : {
			de: "Dachplatte, innen 1",
			en: "Roofplate, inner 1"
		}
		,A2V00002801483 : {
			de: "Dachplatte, aussen 1",
			en: "Roofplate, outer 1"
		}
		,A2V00002789931 : {
			de: "Bef. f. Lautsprecher ZSB",
			en: "Mount. f loudspeaker ASSY"
		}
		,A2V00001791162 : {
			de: "Haupttrafo T1",
			en: "Main transformer T1"
		}
		,A2V00001791165 : {
			de: "Haupttrafo T6",
			en: "Main transformer T6"
		}
		,A2V00001791731 : {
			de: "Traktionsstromr. SIBAC E23-1500 D.0-1",
			en: "Traction converter SIBAC E23-1500 D.0-1"
		}
		,A2V00001794937 : {
			de: "Traktionsstromr. SIBAC E28-3000 B.1-1",
			en: "Traction converter SIBAC E28-3000 B.1-1"
		}
		,A2V00001809279 : {
			de: "Maschinenraumtuer",
			en: "engine room door"
		}
		,A2V00001809968 : {
			de: "Tuer mechanisch vorm., Basis",
			en: "Door mechanical pre-asm, base"
		}
		,A2V00001810659 : {
			de: "Geraetetafel 31 Zus., mech., Basis",
			en: "Equipment panel 31 Asm, mech., base"
		}
		,A2V00001810860 : {
			de: "Geraetetafel 13 mech. Zus.",
			en: "Equipment panel 13 mech. asm."
		}
		,A2V00001811347 : {
			de: "Befestigungswinkel re. vormontiert",
			en: "Mounting angle right pre-assembled"
		}
		,A2V00001811348 : {
			de: "Befestigungswinkel li. vormontiert",
			en: "Mounting angle left pre-assembled"
		}
		,A2V00001813135 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00001813875 : {
			de: "Wasserbehaelter",
			en: "water supply tank"
		}
		,A2V00001817552 : {
			de: "Waermesteuergeraet Vectron",
			en: "Heater control unit Vectron"
		}
		,A2V00001822685 : {
			de: "Systemumschalter RM25.4000-5103",
			en: "System change over switch RM25.4000-5103"
		}
		,A2V00001822838 : {
			de: "Systemumschalter RM25.4000-5104",
			en: "System change over switch RM25.4000-5104"
		}
		,A2V00001822843 : {
			de: "Systemumschalter RM25.600-5105",
			en: "System change over switch RM25.600-5105"
		}
		,A2V00001833630 : {
			de: "Abdeckblech links",
			en: "Cover sheet left"
		}
		,A2V00001833787 : {
			de: "Abdeckblech vorne",
			en: "Cover sheet front"
		}
		,A2V00001833790 : {
			de: "Luefterpumpe",
			en: "blower pump"
		}
		,A2V00001834645 : {
			de: "Abdeckhaube komplett",
			en: "Cover sheet complete"
		}
		,A2V00001838801 : {
			de: "Kontrolleinheit 24V-4V4",
			en: "Control unit 24V-4V4"
		}
		,A2V00001840478 : {
			de: "Abdeckung 1 Zusammenstellung",
			en: "Cover 1 Assembly"
		}
		,A2V00001843166 : {
			de: "Faltenbalg",
			en: "Folding bellows"
		}
		,A2V00001845990 : {
			de: "Batterieladegeraet DC 24V 300A",
			en: "Battery charger DC 24V 300A"
		}
		,A2V00001846608 : {
			de: "Wellschlauchleitung",
			en: "corrugated hose"
		}
		,A2V00001849737 : {
			de: "Bremssteuerung BCU",
			en: "Brake control unit"
		}
		,A2V00001849741 : {
			de: "Fuehrerbremsventil FBS 1-6-SB2",
			en: "DriverÂ´s brake valve FBS 1-6-SB2"
		}
		,A2V00001849743 : {
			de: "Schnellbremsventil mit SNCF Druckknopf",
			en: "Emergency appl. valve w. SNCF push butt."
		}
		,A2V00001849806 : {
			de: "Gleitschutz MGS2",
			en: "Wheel slide control MGS2"
		}
		,A2V00001851645 : {
			de: "Bedientafel X4E1112 V1",
			en: "Operating panel X4E1112 V1"
		}
		,A2V00001851673 : {
			de: "Bedientafel X4E1123 V1",
			en: "Operating panel X4E1123 V1"
		}
		,A2V00001851674 : {
			de: "Bedientafel X4E1125 V1",
			en: "Operating panel X4E1125 V1"
		}
		,A2V00001851851 : {
			de: "Bedientafel X4E1511 V1",
			en: "Operating panel X4E1511 V1"
		}
		,A2V00001854729 : {
			de: "Luftbehaelter EN 286-3",
			en: "Air reservoir EN 286-3"
		}
		,A2V00001857100 : {
			de: "Rueckspiegelkamera links",
			en: "Camera left"
		}
		,A2V00001857101 : {
			de: "Rueckspiegelkamera rechts",
			en: "Camera right"
		}
		,A2V00001857354 : {
			de: "Sicherheitsventil NHS 10,3 bar",
			en: "Safety valve NHS 10,3 bar"
		}
		,A2V00001858297 : {
			de: "Lueftungsgitter Luftauslass",
			en: "air outlet grille"
		}
		,A2V00001859611 : {
			de: "Geraetetafel 45 Zus. mech.",
			en: "Equipment panel 45 Asm. mech."
		}
		,A2V00001860375 : {
			de: "Grundgeruest RB",
			en: "basis rack rb"
		}
		,A2V00001860698 : {
			de: "Makrofon-Steuertafel",
			en: "Horn control panel"
		}
		,A2V00001862068 : {
			de: "Wellschlauchleitung",
			en: "flex. metal hose"
		}
		,A2V00001863725 : {
			de: "Kompressoraggregat TA 18",
			en: "Compressor unit TA 18"
		}
		,A2V00001863890 : {
			de: "Bedientafel X4E1113 V1",
			en: "Operating panel X4E1113 V1"
		}
		,A2V00001863941 : {
			de: "Bedientafel X4E1134 V1",
			en: "Operating panel X4E1134 V1"
		}
		,A2V00001864316 : {
			de: "Lufttrocknungsanlage",
			en: "Air dryer unit"
		}
		,A2V00370027214 : {
			de: "SCHRAUBENFEDER",
			en: "helical spring"
		}
		,A2V00001868507 : {
			de: "Besandungsanlage",
			en: "Sand distributor"
		}
		,A2V00001872638 : {
			de: "Wellschlauchleitung",
			en: "corrugated hose line"
		}
		,A2V00001873867 : {
			de: "Abdeckung oben, AC HSG mechanisch",
			en: "Cover top, AC HVC mechanical"
		}
		,A2V00001874724 : {
			de: "Bedientafel X4E4711 V3",
			en: "Operating panel X4E4711 V3"
		}
		,A2V00001874725 : {
			de: "Bedientafel X4E4711 V4",
			en: "Operating panel X4E4711 V4"
		}
		,A2V00001874817 : {
			de: "Bedientafel X4E4711 V2",
			en: "Operating panel X4E4711 V2"
		}
		,A2V00001878661 : {
			de: "Geraetetafel 22 Zus., mech., Basis",
			en: "Equipment panel 22 Asm., mech., base"
		}
		,A2V00001880550 : {
			de: "Traeger 779 Zusammenstellung",
			en: "Beam  779 Assembly"
		}
		,A2V00001882315 : {
			de: "Wellschlauchleitung",
			en: "corrugated hose line"
		}
		,A2V00001882418 : {
			de: "Wellschlauchleitung",
			en: "corrugated hose line"
		}
		,A2V00001882478 : {
			de: "Wellschlauchleitung",
			en: "corrugated hose line"
		}
		,A2V00001882643 : {
			de: "Wellschlauchleitung",
			en: "corrugated hose line"
		}
		,A2V00001885453 : {
			de: "Wellschlauchleitung",
			en: "corrugated hose line"
		}
		,A2V00001891398 : {
			de: "Bedientafel X4E4713 V1",
			en: "Operating panel X4E4713 V1"
		}
		,A2V00001892149 : {
			de: "Wellschlauchleitung",
			en: "flex. metal hose"
		}
		,A2V00001892272 : {
			de: "Wellschlauchleitung",
			en: "flex. metal hose"
		}
		,A2V00001892274 : {
			de: "Wellschlauchleitung",
			en: "flex. metal hose"
		}
		,A2V00001893413 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00001893618 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00001893620 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00001893622 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00001893624 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00001893728 : {
			de: "Halter 19, vorm.",
			en: "Holder 19, pre-asm."
		}
		,A2V00001894774 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00370038311 : {
			de: "GLEITPLATTE",
			en: "Sliding Plate"
		}
		,A2V00001895658 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00001895659 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00001895660 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00001895661 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00001897714 : {
			de: "Geraetetafel 22 Zusammenstellung",
			en: "Equipment panel 22 Assembly"
		}
		,A2V00002738885 : {
			de: "UBX Bremswiderstand 3PQ4-B00962",
			en: "Brake resistor"
		}
		,A2V00370040092 : {
			de: "KABELPEITSCHE TDG 3T76 1 12502392",
			en: "cable harness"
		}
		,A2V00001906890 : {
			de: "Bedientafel X4E8511 V1",
			en: "Operating panel X4E8511 V1"
		}
		,A2V00001909988 : {
			de: "Halter lackiert",
			en: "mounting, lacquered"
		}
		,A2V00001910032 : {
			de: "Halter lackiert",
			en: "mounting, lacquered"
		}
		,A2V00001913206 : {
			de: "Halter RB",
			en: "bracket bs"
		}
		,A2V00002400249 : {
			de: "Unterarm G/H SBS-2T A1 kompl. RAL3000",
			en: "Lower arm G/H SBS-2T A1 compl. RAL3000"
		}
		,A2V00001189197 : {
			de: "Halbscherenstromabnehmer DB/OEBB/DSB",
			en: "Singel arm pantograph DB/OEBB/DSB"
		}
		,A2V00002399627 : {
			de: "Wippe kompl. NS X4 1950/DC",
			en: "Collector head compl. NS X4 1950/DC"
		}
		,A2V00002400202 : {
			de: "Wippe 1950/AC",
			en: "Collector head 1950/AC"
		}
		,A2V00002399397 : {
			de: "Oberarm H Alu Ausf.3 DC kompl.",
			en: "Upper arm H Alu vers.3 DC compl."
		}
		,A2V00002400349 : {
			de: "Kuppelstange; GH/AC/Std HGV",
			en: "Coupling rode; GH/AC/Std HS"
		}
		,A2V00002400652 : {
			de: "Unterarm SSS/A3/RAL3000 kompl.",
			en: "Lower arm SSS/A3/RAL3000 compl."
		}
		,A2V00002400815 : {
			de: "Stromabnehmer OEBB 8e-AS-1600",
			en: "Pantograph OEBB 8e-ADD-1600"
		}
		,A2V00002400723 : {
			de: "Ventiltafel f. 2 Stromabnehmer 2AC",
			en: "Valve panel for 2 pantographs 2AC"
		}
		,A2V00002399138 : {
			de: "Unterarm G/H SWT SBS-2T",
			en: "Lower arm G/H wp SBS-2T"
		}
		,A2V00001921744 : {
			de: "Bremstafel",
			en: "Brake panel"
		}
		,A2V00002693582 : {
			de: "Laengsholm",
			en: "Longitudinal beam"
		}
		,A2V00002693724 : {
			de: "Quertraeger",
			en: "Cross beam"
		}
		,A2V00002695890 : {
			de: "Tragblech",
			en: "Mounting plate"
		}
		,A2V00002696314 : {
			de: "Lasche Hebantrieb",
			en: "Link lifting device"
		}
		,A2V00002696504 : {
			de: "Traeger Wippenauflage",
			en: "Beam collector head support"
		}
		,A2V00002698522 : {
			de: "Konsole Isolator",
			en: "Panel insulator"
		}
		,A2V00002698675 : {
			de: "Anschlussblech",
			en: "Connection plate"
		}
		,A2V00002699292 : {
			de: "Winkel",
			en: "Bracket"
		}
		,A2V00002699650 : {
			de: "Lagerbock",
			en: "Bearing support"
		}
		,A2V00002700899 : {
			de: "Winkel",
			en: "Bracket"
		}
		,A2V00002703306 : {
			de: "Rippe Auflager",
			en: "Rib pad"
		}
		,A2V00002712819 : {
			de: "Knotenblech T1",
			en: "Junction plate P1"
		}
		,A2V00002712821 : {
			de: "Knotenblech T2",
			en: "Junction plate P2"
		}
		,A2V00002713126 : {
			de: "Knotenblech",
			en: "Junction plate"
		}
		,A2V00002713132 : {
			de: "Holm",
			en: "Beam"
		}
		,A2V00002713896 : {
			de: "Versteifung",
			en: "Stiffening"
		}
		,A2V00002714357 : {
			de: "Abdeckblech",
			en: "Cover sheet"
		}
		,A2V00002717254 : {
			de: "Querholm",
			en: "Transversal beam"
		}
		,A2V00000010699 : {
			de: "Bolzen m. Sicherung",
			en: "Bolt w. lock"
		}
		,A2V00001066499 : {
			de: "Stromabnehmer  AC15kV  DB _ E182",
			en: "Pantograph  AC15kV   DB _ E182"
		}
		,A2V00002400714 : {
			de: "Stromabnehmer SA AC1 k",
			en: "Pantograph SA AC1 k"
		}
		,A2V00002400172 : {
			de: "Stromabnehmer BR 189 FS",
			en: "Pantograph BR 189 FS"
		}
		,A2V00002807543 : {
			de: "Scharblech kpl Ersatzteil",
			en: "Fixed Plate complete SparePart"
		}
		,A2V00002807542 : {
			de: "Scharblech kpl Ersatzteil",
			en: "Fixed Plate complete SparePart"
		}
		,A2V00370058981 : {
			de: "Schraubendruckfeder",
			en: "Helical compression spring"
		}
		,A2V00001955440 : {
			de: "Datenrekorder REDBOXlog Hutschienenbef.",
			en: "Datarecorder REDBOXlog mounting rail fix"
		}
		,A2V00001957271 : {
			de: "Wellschlauchleitung",
			en: "Flex. metal hose"
		}
		,A2V00001962168 : {
			de: "Wellschlauchleitung",
			en: "flex. metal hose"
		}
		,A2V00001963405 : {
			de: "ZugSi-Schrank DE mech. Basis JD52",
			en: "ATP com. DE mech. basis JD52"
		}
		,A2V00001756442 : {
			de: "Grundplatte mit Schweissmuttern",
			en: "base plate with hexagon weld nut"
		}
		,A2V00001973282 : {
			de: "Wellschlauchleitung",
			en: "Flex. metal hose"
		}
		,A2V00001856788 : {
			de: "Schaltschrank AP 500x500x210 Scharnier",
			en: "Cabinet AP 500x500x210 hinge"
		}
		,A2V00082045015 : {
			de: "TWIN-Antenne, 3.2m Ltg., HTS-Stecker",
			en: "TWIN-aerial 3.2m cable, HTS-plug"
		}
		,A2V00001992768 : {
			de: "Halter SHP oben genietet",
			en: "Bracket SHP top riveted"
		}
		,A2V00001992770 : {
			de: "Halter SHP unten genietet",
			en: "Bracket SHP down riveted"
		}
		,A2V00002185131 : {
			de: "GSM-R Unit  CGU6012",
			en: "GSM-R Unit  CGU6012"
		}
		,A2V00002019833 : {
			de: "Drehgestell SF4-05A",
			en: "bogie SF4-05A"
		}
		,A2V00002019834 : {
			de: "Drehgestell SF4-05B",
			en: "bogie SF4-05B"
		}
		,A2V00002023009 : {
			de: "Kabelfuehrung mech.",
			en: "Cable conduit mech."
		}
		,A2V00002023386 : {
			de: "Partikelfilter B",
			en: "carbon part. filter B"
		}
		,A2V00002028565 : {
			de: "Blech EN 10029  S700MC",
			en: "Sheet EN 10029  S700MC"
		}
		,A2V00002028776 : {
			de: "Blech EN 10029  S700MC",
			en: "Sheet EN 10029  S700MC"
		}
		,A2V00002028777 : {
			de: "Blech EN 10029  S700MC",
			en: "Sheet EN 10029  S700MC"
		}
		,A2V00002539062 : {
			de: "Bremssteuergeraet HEY-C",
			en: "Brake control unit HEY-C"
		}
		,A2V00002628060 : {
			de: "Bremsgeraeteeinheit 2PM",
			en: "brake control unit 2PM"
		}
		,A2V00002632651 : {
			de: "E-Geraeteschrank 15, vormontiert",
			en: "Electrical cabinet 15, pre-assembled"
		}
		,A2V00002574970 : {
			de: "Steuergeraet",
			en: "Control Unit"
		}
		,A2V00002535517 : {
			de: "Notsprechzentrale inkl. ELA Controller",
			en: "Emergency headquarters"
		}
		,A2V00002010242 : {
			de: "Abdeckleiste",
			en: "Cover strip"
		}
		,A2V00002041830 : {
			de: "Hilfsbetriebetrafoge.lack",
			en: "Aux.transf.rack cpl. laqu"
		}
		,A2V00002045104 : {
			de: "Bedientafel X4E5111 V4",
			en: "Operating panel X4E5111 V4"
		}
		,A2V00002045105 : {
			de: "Bedientafel X4E5811 V3",
			en: "Operating panel X4E5811 V3"
		}
		,A2V00002049718 : {
			de: "Stuetzluefter Druck.erz.ger.",
			en: "Support blower compressor rack"
		}
		,A2V00002056899 : {
			de: "Blech EN 10051 2,5X1500X4000 S355J2C+N",
			en: "Sheet EN 10051 2,5X1500X4000 S355J2C+N"
		}
		,A2V00002057350 : {
			de: "Gleitsch.ventil GV 12-3 m. Ventiltraeger",
			en: "Anti-skid valve GV 12-3 with base plate"
		}
		,A2V00370083129 : {
			de: "Radschutzkasten",
			en: "wheel guard"
		}
		,A2V00002062302 : {
			de: "Wellschlauchleitung",
			en: "Flex. metal hose"
		}
		,A2V00002064772 : {
			de: "Gleitsch.ventil GV 12-3 m. Ventiltraeger",
			en: "Anti-skid valve GV 12-3 with base plate"
		}
		,A2V00207280046 : {
			de: "Sandtreppe KM Ed",
			en: "Sanding step KM Ed"
		}
		,A2V00002073479 : {
			de: "Versteifungswinkel vormontiert",
			en: "Stiffening angle preassembled"
		}
		,A2V00002757612 : {
			de: "DB Grosskomponenten Ersatzteil",
			en: "DB Grosskomponenten Ersatzteil"
		}
		,A2V00002669485 : {
			de: "Lok vst X4 - A01 Unipetrol",
			en: "Loco cpl X4 - A01 Unipetrol"
		}
		,A2V00002613520 : {
			de: "Lok vst X4 - A16 SBBC",
			en: "Loco cpl X4 - A16 SBBC"
		}
		,A2V00002802219 : {
			de: "Schneeraeumer N lackiert Ersatzteil",
			en: "Snowplow N coated SparePart"
		}
		,A2V00002800726 : {
			de: "Schneeraeumer lack Ersatzteil",
			en: "Schneeraeumer lack Ersatzteil"
		}
		,A2V00002707704 : {
			de: "HB-Geruest und BB-Anlage kpl.",
			en: "A-Compartment and FF-system. cpl."
		}
		,A2V00002076273 : {
			de: "Hammerschraube aehnlich DIN 188",
			en: "T-head bolt similar to DIN 188"
		}
		,A2V00002076389 : {
			de: "Hammerschraube aehnlich DIN 188",
			en: "T-head bolt similar to DIN 188"
		}
		,A2V00002076390 : {
			de: "Hammerschraube aehnlich DIN 188",
			en: "T-head bolt similar to DIN 188"
		}
		,A2V00002083023 : {
			de: "Scheinwerfer oben",
			en: "Head Light"
		}
		,A2V00002083744 : {
			de: "Bedientafel X4E1122 V4",
			en: "Operating panel X4E1122 V4"
		}
		,A2V00002083745 : {
			de: "Bedientafel X4E1133 V8",
			en: "Operating panel X4E1133 V8"
		}
		,A2V00002084086 : {
			de: "Bedientafel X4E5111 V5",
			en: "Operating panel X4E5111 V5"
		}
		,A2V00002084088 : {
			de: "Bedientafel X4E1133 V6",
			en: "Operating panel X4E1133 V6"
		}
		,A2V00002087771 : {
			de: "Zentrales Steuergeraet Typ 34V2",
			en: "Central control unit type 34V2"
		}
		,A2V00002088219 : {
			de: "Bedientafel X4E4711 V5",
			en: "Operating panel X4E4711 V5"
		}
		,A2V00397128594 : {
			de: "PLATTE",
			en: "plate"
		}
		,A2V00397128595 : {
			de: "STEG",
			en: "web"
		}
		,A2V00002092394 : {
			de: "Halter geschw.",
			en: "Bracket welded"
		}
		,A2V00397128903 : {
			de: "Anschlag",
			en: "Stop"
		}
		,A2V00002094802 : {
			de: "Kompressormodul UBMC2",
			en: "air supply unit UBMC2"
		}
		,A2V00082035247 : {
			de: "Kontaktschiene",
			en: "Contact bar"
		}
		,A2V00002544331 : {
			de: "EP2002 Smart Ventil",
			en: "EP2002 Smart Valve"
		}
		,A2V00207476464 : {
			de: "Batteriek. 2 RB",
			en: "Battery box 2 bs"
		}
		,A2V00002544327 : {
			de: "EP 2002 Gateway Ventil",
			en: "EP 2002 Gateway Valve"
		}
		,A2V00002097167 : {
			de: "Gleitsch.ventil GV 12-3 m. Ventiltraeger",
			en: "Anti-skid valve GV 12-3 with base plate"
		}
		,A2V00397129598 : {
			de: "Gewindebutzen",
			en: "Thread Scrap"
		}
		,A2V00397135842 : {
			de: "Beilage 2mm",
			en: "shim 2mm"
		}
		,A2V00397142145 : {
			de: "KLEMMPLATTE",
			en: "clamping plate"
		}
		,A2V00002121848 : {
			de: "Drehgestell SF4-10A",
			en: "bogie SF4-10A"
		}
		,A2V00002121849 : {
			de: "Drehgestell SF4-10B",
			en: "bogie SF4-10B"
		}
		,A2V00002122691 : {
			de: "Bremstafel",
			en: "Brake panel"
		}
		,A2V00397153856 : {
			de: "Blech fuer Sandungskonsole",
			en: "Sheet for sanding bracket"
		}
		,A2V00397153861 : {
			de: "Winkel fuer Sandungskonsole",
			en: "Angle profile for sanding bracket"
		}
		,A2V00397153873 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00001134945 : {
			de: "Kontaktwinkel",
			en: "contact angle"
		}
		,A2V00397156326 : {
			de: "SICHERUNG WANKSTUETZE LAGER",
			en: "Loking Dvice of Anti-Roll Bar"
		}
		,A2V00397157712 : {
			de: "Buegel lackiert",
			en: "bracket painted"
		}
		,A2V00397159451 : {
			de: "LENKER,VOLLST.",
			en: "LENKER,VOLLST."
		}
		,A2V00002544330 : {
			de: "EP2002 Smart Ventil",
			en: "EP2002 Smart Valve"
		}
		,A2V00002544328 : {
			de: "EP2002 Gateway Ventil",
			en: "EP2002 Gateway Valve"
		}
		,A2V00397161935 : {
			de: "Halterung lackiert",
			en: "Retaining cap painted"
		}
		,A2V00002143464 : {
			de: "Maschinenraumtuer",
			en: "engine room door"
		}
		,A2V00397163143 : {
			de: "KLEMME 91 F.LEITUNGSGERUEST TFW",
			en: "clamp 91 f. lead frame MB"
		}
		,A2V00002148027 : {
			de: "Blech EN 10051 S700MC",
			en: "Sheet EN 10051 S700MC"
		}
		,A2V00397163989 : {
			de: "FEDERBEILAGE 2MM",
			en: "Spring attachment 2mm"
		}
		,A2V00397163990 : {
			de: "FEDERBEILAGE 3mm",
			en: "Spring washer 3mm"
		}
		,A2V00397163991 : {
			de: "FEDERBEILAGE 5mm",
			en: "Spring attachment 5mm"
		}
		,A2V00397164285 : {
			de: "UNTERLAGSPLATTE",
			en: "shimming plate"
		}
		,A2V00002149163 : {
			de: "Bedientafel X4E1201 V4",
			en: "Operating panel X4E1201 V4"
		}
		,A2V00002149165 : {
			de: "Bedientafel X4E1301 V4",
			en: "Operating panel X4E1301 V4"
		}
		,A2V00002149426 : {
			de: "Bedientafel X4E1132 V5",
			en: "Operating panel X4E1132 V5"
		}
		,A2V00397164648 : {
			de: "Bolzen",
			en: "Bolt"
		}
		,A2V00002150208 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00002152717 : {
			de: "Wellschlauchleitung",
			en: "Corrugated hose line"
		}
		,A2V00002156066 : {
			de: "Bedientafel X4E1201 V5",
			en: "Operating panel X4E1201 V5"
		}
		,A2V00002156068 : {
			de: "Bedientafel X4E1301 V5",
			en: "Operating panel X4E1301 V5"
		}
		,A2V00002162140 : {
			de: "Kraftstoffbehaelter",
			en: "Fuel container"
		}
		,A2V00002168193 : {
			de: "Diesel Power Modul DPM-Standard",
			en: "Diesel Power Module DPM-Standard"
		}
		,A2V00397168648 : {
			de: "PLATTE",
			en: "plate"
		}
		,A2V00397169033 : {
			de: "Blech, gebogenes SPG",
			en: "Sheet, bended OAD"
		}
		,A2V00397169068 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00397169069 : {
			de: "Rippe",
			en: "rip"
		}
		,A2V00002169768 : {
			de: "Bedientafel X4E5811 V5",
			en: "Operating panel X4E5811 V5"
		}
		,A2V00000517362 : {
			de: "Einschub",
			en: "Plug-in module"
		}
		,A2V00397176552 : {
			de: "SCHIENENRAEUMERBLECH SP.G.",
			en: "rail guard sheet o.a.d."
		}
		,A2V00002186757 : {
			de: "Drehgestell SF4-11A",
			en: "bogie SF4-11A"
		}
		,A2V00002186758 : {
			de: "Drehgestell SF4-11B",
			en: "bogie SF4-11B"
		}
		,A2V00002188443 : {
			de: "Bedientafel X4E1133 V12",
			en: "Operating panel X4E1133 V12"
		}
		,A2V00002683650 : {
			de: "Mirelhalter RB Ersatzteil",
			en: "Mirel bracket bs spare part"
		}
		,A2V00397197983 : {
			de: "HALTER F.SANDSCHLAUCH W.G.",
			en: "holder f. sanding hose a.d."
		}
		,A2V00397197985 : {
			de: "HALTER F.SANDSCHLAUCH SP.G.",
			en: "holder f. sanding hose o.a.d."
		}
		,A2V00002228013 : {
			de: "Vorwaermgeraet",
			en: "preheater"
		}
		,A2V00002230422 : {
			de: "Ring",
			en: "ring"
		}
		,A2V00002232798 : {
			de: "Drehgestell SF4-12A",
			en: "bogie SF4-12A"
		}
		,A2V00002232799 : {
			de: "Drehgestell SF4-12B",
			en: "bogie SF4-12B"
		}
		,A2V00397212851 : {
			de: "Adapterblech sp. g.",
			en: "Adapter sheet opp. as drawn"
		}
		,A2V00002252778 : {
			de: "Blech 4 EN 10051 S700MC",
			en: "Sheet 4 EN 10051 S700MC"
		}
		,A2V00397213752 : {
			de: "BLECH F. ANTENNEN ANBAU INNEN",
			en: "SHEET F. ANTENNA MOUNTING INSIDE"
		}
		,A2V00397213753 : {
			de: "BLECH F. ANTENNEN ANBAU AUSSEN",
			en: "SHEET F. ANTENNA MOUNTING OUTSIDE"
		}
		,A2V00397213754 : {
			de: "RIPPE F. ANTENNEN ANBAU",
			en: "RIB F. ANTENNA MOUNTING"
		}
		,A2V00397213755 : {
			de: "ROHR  F. ANTENNEN ANBAU",
			en: "PIPE F. ANTENNA MOUNTING"
		}
		,A2V00397213756 : {
			de: "FLANSCHPLATTE F. ANTENNEN ANBAU",
			en: "FLANGEPLATE F. ANTENNE MOUNTING"
		}
		,A2V00397215082 : {
			de: "Unterlage",
			en: "Support"
		}
		,A2V00002260113 : {
			de: "Schuerze lackiert",
			en: "shroud coated"
		}
		,A2V00002260114 : {
			de: "Schuerze lackiert",
			en: "shroud coated"
		}
		,A2V00002261648 : {
			de: "Fahnenhalter mech. Zus.",
			en: "Flag holder mech. asm."
		}
		,A2V00397216907 : {
			de: "GEWINDEBUTZEN ND05-0007-M10x30x20",
			en: "THREADED SLUG ND05-0007-M10x30x20"
		}
		,A2V00397216939 : {
			de: "Schienenraeumerblech WG",
			en: "sheet for life guard as drawn"
		}
		,A2V00002270962 : {
			de: "Einstiegstuer re.",
			en: "entrance door ri."
		}
		,A2V00002270963 : {
			de: "Einstiegstuer li.",
			en: "entrance door le."
		}
		,A2V00397226184 : {
			de: "Halter f. Schienenbr. W.G.",
			en: "HOLDER F.TRACK BRAKE AS DRAWN"
		}
		,A2V00397226185 : {
			de: "Halter f. Schienenbr. SP.G.",
			en: "HOLDER F. TRACK BR. OP.AS DRAWN"
		}
		,A2V00002278923 : {
			de: "Aufhaengung",
			en: "suspension"
		}
		,A2V00397229083 : {
			de: "Halter Schienenbremse W.G.",
			en: "Holder Track Brake A.D."
		}
		,A2V00397229084 : {
			de: "Halter Schienenbremse SP.G",
			en: "HOLDER TRACK BRAKE O.A.D."
		}
		,A2V00397229851 : {
			de: "Verschleissplatte",
			en: "abrasion plate"
		}
		,A2V00397237681 : {
			de: "Querpuffersteg",
			en: "Lateral Web"
		}
		,A2V00397237704 : {
			de: "Querpufferplatte",
			en: "Lateral Plate"
		}
		,A2V00002312662 : {
			de: "Dichtblech",
			en: "sealing plate"
		}
		,A2V00002312664 : {
			de: "Dichtblech",
			en: "sealing plate"
		}
		,A2V00002312665 : {
			de: "Dichtblech",
			en: "sealing plate"
		}
		,A2V00002312765 : {
			de: "Dichtblech  sp.g.",
			en: "sealing plate  r.i."
		}
		,A2V00002312821 : {
			de: "Dichtblech  w.g.",
			en: "sealing plate  as d."
		}
		,A2V00002312956 : {
			de: "Dichtblech  w.g.",
			en: "sealing plate  as d."
		}
		,A2V00002312957 : {
			de: "Dichtblech  sp.g.",
			en: "sealing plate  r.i."
		}
		,A2V00002316748 : {
			de: "Abdeckblech, Dach mech. Zus.",
			en: "Cover sheet, roof mech. asm."
		}
		,A2V00397239525 : {
			de: "GRUNDBLECH F. SANDUNG U. RAEUMER W.G.",
			en: "base pl. f. sand. dev. a. track sw. a.d."
		}
		,A2V00397239875 : {
			de: "BL 30x30x50-S355J2G3C",
			en: "pl 30x30x50-S355J2G3C"
		}
		,A2V00397242437 : {
			de: "Anlenkstange, lackiert",
			en: "Tie rod painted"
		}
		,A2V00397243400 : {
			de: "KONSOLE F. ZUB",
			en: "BRACKET F. ZUB"
		}
		,A2V00397246425 : {
			de: "RIPPE RECHTS",
			en: "RIB RIGHT"
		}
		,A2V00397246468 : {
			de: "HALTER KROKODIL",
			en: "BRACKET KROKODIL"
		}
		,A2V00002339389 : {
			de: "Dichtblech",
			en: "sealing plate"
		}
		,A2V00002339390 : {
			de: "Dichtblech",
			en: "sealing plate"
		}
		,A2V00397250664 : {
			de: "RIPPE VORNE",
			en: "RIB FRONT"
		}
		,A2V00397250666 : {
			de: "RIPPE MITTE",
			en: "RIB MIDDLE"
		}
		,A2V00397250715 : {
			de: "STEG KROKODIL",
			en: "WEB KROKODIL"
		}
		,A2V00002344709 : {
			de: "Einstiegstuer re.",
			en: "entrance door ri."
		}
		,A2V00002344710 : {
			de: "Einstiegstuer li.",
			en: "Entrance door le."
		}
		,A2V00002346219 : {
			de: "Blech A588 6 mm",
			en: "Sheet A588 6 mm"
		}
		,A2V00002347747 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00002348868 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00002350228 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00002533819 : {
			de: "Doppelmanometer",
			en: "Double pressure gauge"
		}
		,A2V00002353960 : {
			de: "Blech A588 8 mm",
			en: "Sheet A588 8 mm"
		}
		,A2V00002353962 : {
			de: "Blech A588 12 mm",
			en: "Sheet A588 12 mm"
		}
		,A2V00002353964 : {
			de: "Blech A588 16 mm",
			en: "Sheet A588 16 mm"
		}
		,A2V00002354478 : {
			de: "Blech A606-Type 4 4 mm",
			en: "Sheet A606-Type 4 4 mm"
		}
		,A2V00002356946 : {
			de: "Haupttrafo T5",
			en: "Main transformer T5"
		}
		,A2V00002356947 : {
			de: "Traktionsstromr. SIBAC E23-1500 E.0-3",
			en: "Traction converter SIBAC E23-1500 E.0-3"
		}
		,A2V00002371587 : {
			de: "Kompaktklimageraet",
			en: "Air conditioning unit"
		}
		,A2V00002374291 : {
			de: "Bedientafel X4E1123 V10",
			en: "Operating panel X4E1123 V10"
		}
		,A2V00002379211 : {
			de: "Zentrales Steuergeraet Typ 34V2",
			en: "Central control unit type 34V2"
		}
		,A2V00002380727 : {
			de: "Diesel Power Modul DPM-Finnland",
			en: "Diesel Power Module DPM-Finland"
		}
		,A2V00002380735 : {
			de: "Dachtrennschalter RM 593-202",
			en: "Roof connector RM 593-202"
		}
		,A2V00397279114 : {
			de: "Gleitplatte",
			en: "SLIDING PLATE"
		}
		,A2V00002381957 : {
			de: "Kupplungsvorderteil lackiert",
			en: "Coupler front part lacquered"
		}
		,A2V00002381958 : {
			de: "Zug-Druckfederwerk lackiert",
			en: "Draft gear lacquered"
		}
		,A2V00002383334 : {
			de: "Fahrmotorluefter V4",
			en: "Traction motor blower v4"
		}
		,A2V00002386394 : {
			de: "Kuehlanlage 1 X4N, klein (ohne BHR)",
			en: "Cooling unit 1 X4N, small (without BHR)"
		}
		,A2V00002386395 : {
			de: "Kuehlanlage 2 X4N, klein (mit BHR)",
			en: "Cooling unit 2 X4N, small (with BHR)"
		}
		,A2V00002387104 : {
			de: "Puffer li lackiert",
			en: "buffer le lacqu."
		}
		,A2V00002387105 : {
			de: "Puffer re lackiert",
			en: "buffer ri lacqu."
		}
		,A2V00002387624 : {
			de: "Bodenpaneel rechts",
			en: "Floor plate right"
		}
		,A2V00002387625 : {
			de: "Bodenpaneel links",
			en: "Floor plate left"
		}
		,A2V00002387694 : {
			de: "Einstiegstuer re.",
			en: "Entrance door ri."
		}
		,A2V00002387695 : {
			de: "Einstiegstuer li.",
			en: "Entrance door le."
		}
		,A2V00002387737 : {
			de: "DE-Maschinenraumtuer Fahrtrichtung links",
			en: "DE-Engine room door driving direct. left"
		}
		,A2V00002387738 : {
			de: "DE-Maschinenraumtuer Fahrtrichtg. rechts",
			en: "DE-engine room door driving direct. ri."
		}
		,A2V00002391945 : {
			de: "Heizluefter",
			en: "Air heater"
		}
		,A2V00002394897 : {
			de: "Waermesteuergeraet Finnland",
			en: "Heater control Finland"
		}
		,A2V00397290615 : {
			de: "PRUEFSTUECK     BL10x125x200",
			en: "test piece     pl 10x125x200"
		}
		,A2V00002404299 : {
			de: "Frontklappenblech",
			en: "Front flap sheet"
		}
		,A2V00002404514 : {
			de: "Drucktaupunktsensor",
			en: "Dewpoint sensor"
		}
		,A2V00002404638 : {
			de: "Kuehlanlage 1 X4D, klein (ohne BHR)",
			en: "Cooling unit 1 X4D, small (without BHR)"
		}
		,A2V00002404639 : {
			de: "Kuehlanlage 2 X4D, klein (mit BHR)",
			en: "Cooling unit 2 X4D, small (with BHR)"
		}
		,A2V00002406737 : {
			de: "Kompressoraggregat",
			en: "Compressor aggregate"
		}
		,A2V00002406738 : {
			de: "Lufttrockungsanlage LTZ 015.2H",
			en: "Air dryer LTZ 015.2H"
		}
		,A2V00002406739 : {
			de: "Bremstafel",
			en: "Brake panel"
		}
		,A2V00002410379 : {
			de: "Batterieladegeraet 12 LGB 440 M24 W20",
			en: "Battery charger 12 LGB 440 M24 W20"
		}
		,A2V00002413326 : {
			de: "Lueftungsgitter lack N2",
			en: "Air grille lacq N2"
		}
		,A2V00002413327 : {
			de: "Lueftungsgitter lack N1",
			en: "Air grille lacq N1"
		}
		,A2V00002419924 : {
			de: "Drehgestell SF4B-01A",
			en: "bogie SF4B-01A"
		}
		,A2V00002419925 : {
			de: "Drehgestell SF4B-01B",
			en: "bogie SF4B-01B"
		}
		,A2V00002424843 : {
			de: "Bremswiderstand BWD 193",
			en: "Braking resistor BWD 193"
		}
		,A2V00002427338 : {
			de: "Wellschlauchleitung",
			en: "Corrugated hose line"
		}
		,A2V00002428386 : {
			de: "Bedientafel X4E1112 V10",
			en: "Operating panel X4E1112 V10"
		}
		,A2V00002428387 : {
			de: "Bedientafel X4E1122 V10",
			en: "Operating panel_X4E1122_V10"
		}
		,A2V00002428424 : {
			de: "Bedientafel X4E1123 V20",
			en: "Operating panel X4E1123 V20"
		}
		,A2V00002428425 : {
			de: "Bedientafel X4E1511 V10",
			en: "Operating panel X4E1511 V10"
		}
		,A2V00002428726 : {
			de: "Bedientafel X4E8511 V10",
			en: "Operating panel X4E8511 V10"
		}
		,A2V00002429162 : {
			de: "Druckluftbehaelter aus Aluminium 250l",
			en: "Air reservoir aluminum 250l"
		}
		,A2V00002430742 : {
			de: "Sitzoberteil MSG 90.3P",
			en: "Seat top part MSG 90.3P"
		}
		,A2V00002432042 : {
			de: "Sanddosieranlage N45",
			en: "sand dosing unit N45"
		}
		,A2V00002432289 : {
			de: "Sandrohr el. beheizt",
			en: "sand pipe elec. heated"
		}
		,A2V00002432290 : {
			de: "Fuellstandssensor Sandk.",
			en: "level sensor sand box"
		}
		,A2V00002433258 : {
			de: "Schwenkkonsole",
			en: "Swivel console"
		}
		,A2V00002557303 : {
			de: "Stirnwandsaeule, geschw. sp.g.",
			en: "End wall column,welded i.m.i."
		}
		,A2V00002557355 : {
			de: "Stirnwandsaeule, geschw. w.g.",
			en: "End wall column, welded a.d."
		}
		,A2V00002434012 : {
			de: "Bedientafel X4E4911 V2",
			en: "Operating panel_X4E4911_V2"
		}
		,A2V00002434682 : {
			de: "Beifahrersitz",
			en: "Co driver`s seat"
		}
		,A2V00207311815 : {
			de: "Stromabnehmersteuertafel",
			en: "control panel of pantograph"
		}
		,A2V00002611368 : {
			de: "Schweissbadsicherung WE1, w.g.",
			en: "Weld pool backing CE1, a.d."
		}
		,A2V00002611367 : {
			de: "Schweissbadsicherung WE1, sp.g.",
			en: "Weld pool backing CE1, i.m.i."
		}
		,A2V00002442701 : {
			de: "Einstiegstuer re. PE RAL9016",
			en: "entrance door ri. PE RAL9016"
		}
		,A2V00002442702 : {
			de: "Einstiegstuer li. PE RAL9016",
			en: "Entrance door le. PE RAL9016"
		}
		,A2V00002444343 : {
			de: "Schutzblech RB",
			en: "Cover plate bs"
		}
		,A2V00002449136 : {
			de: "Schutzblech RB",
			en: "Cover plate bs"
		}
		,A2V00002454973 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00002454974 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00002454975 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00002455006 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00002455156 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00002455157 : {
			de: "Wellschlauchleitung",
			en: "Corrugated metal hose assembly"
		}
		,A2V00002455766 : {
			de: "Wellschlauchleitung",
			en: "Flex. metal hose"
		}
		,A2V00002457381 : {
			de: "Wellschlauchleitung",
			en: "corrugated hose line"
		}
		,A2V00002457382 : {
			de: "Wellschlauchleitung",
			en: "corrugated hose line"
		}
		,A2V00002457383 : {
			de: "Wellschlauchleitung",
			en: "corrugated hose line"
		}
		,A2V00002553577 : {
			de: "A-Saeule lackiert w.g.",
			en: "A-pillar varnished a.d."
		}
		,A2V00002442141 : {
			de: "Geruest",
			en: "Frame"
		}
		,A2V00002554082 : {
			de: "A-Saeule lackiert sp.g.",
			en: "A-pillar varnished i.m.i."
		}
		,A2V00002601616 : {
			de: "Feuerschutzblech",
			en: "Fire protection panel"
		}
		,A2V00002459019 : {
			de: "Wellschlauchleitung",
			en: "Flex. metal hose"
		}
		,A2V00002460308 : {
			de: "X4 BG Fan Control",
			en: "X4 asm fan control"
		}
		,A2V00002496528 : {
			de: "X4 BG Funkfernsteuerung Verteilung",
			en: "X4 asm remote control distribution"
		}
		,A2V00002496966 : {
			de: "Spannungswandler DV4200/SP3 kalibriert",
			en: "Voltage transducer DV4200/SP3 calibrated"
		}
		,A2V00002504549 : {
			de: "Drehgestell SF4-14A",
			en: "Bogie SF4-14A"
		}
		,A2V00002504550 : {
			de: "Drehgestell SF4-14B",
			en: "Bogie SF4-14B"
		}
		,A2V00002505389 : {
			de: "Einstiegstuer re. graumet./PE-Schliessg.",
			en: "entrance door ri. greymetallic/PEclosure"
		}
		,A2V00002505390 : {
			de: "Einstiegstuer li. graumet./PE-Schliessg.",
			en: "Entrance door le. greymetallic/PEclosure"
		}
		,A2V00002546646 : {
			de: "Drehgestell SF4-15A",
			en: "Bogie SF4-15A"
		}
		,A2V00002546647 : {
			de: "Drehgestell SF4-15B",
			en: "Bogie SF4-15B"
		}
		,A2V00002553979 : {
			de: "Fahrersitz",
			en: "Driver`s seat"
		}
		,A2V00002560604 : {
			de: "Zyklonabscheider",
			en: "Cyclone separator"
		}
		,A2V00002563795 : {
			de: "Einstiegstuer re. RAL5015/ABUS",
			en: "Entrance door ri. RAL5015/ABUS"
		}
		,A2V00002563806 : {
			de: "Einstiegstuer li. RAL5015/ABUS",
			en: "Entrance door le. RAL5015/ABUS"
		}
		,A2V00002566763 : {
			de: "Raeumblech",
			en: "sweeper sheet"
		}
		,A2V00002611088 : {
			de: "Aktiver Drehdaempfer",
			en: "Active yaw damper"
		}
		,A2V00001785499 : {
			de: "Bremswiderstand",
			en: "brake resistor"
		}
		,A2V00002598525 : {
			de: "Schweissbadsicherung 5",
			en: "Weld backing 5"
		}
		,A2V00002632477 : {
			de: "Schraubenkupplung OEBB lackiert",
			en: "screw coupling OEBB coated"
		}
		,A2V00002632479 : {
			de: "Puffer OEBB lackiert",
			en: "Buffer OEBB lacquered"
		}
		,A2V00002632480 : {
			de: "Zugeinrichtung OEBB lackiert",
			en: "Drawgear OEBB coated"
		}
		,A2V00002676845 : {
			de: "Ballast lackiert",
			en: "Ballast lacquered"
		}
		,A2V00002681877 : {
			de: "Einstiegstuer re. RAL3020/Kaba",
			en: "Entrance door ri. RAL3020/Kaba"
		}
		,A2V00002681878 : {
			de: "Einstiegstuer li. RAL3020/Kaba",
			en: "Entrance door le. RAL3020/Kaba"
		}
		,A2V00002682405 : {
			de: "Profil ET vert",
			en: "Profile ED vert"
		}
		,A2V00002682421 : {
			de: "Drehgestell SF4-19A",
			en: "Bogie SF4-19A"
		}
		,A2V00002682422 : {
			de: "Drehgestell SF4-19B",
			en: "Bogie SF4-19B"
		}
		,A2V00002684677 : {
			de: "Profil ET hor",
			en: "Profile ED hor"
		}
		,A2V00002691151 : {
			de: "Rohr vollst. oben",
			en: "Rohr vollst. oben"
		}
		,A2V00002691152 : {
			de: "Rohr vollst. unten",
			en: "Rohr vollst. unten"
		}
		,A2V00002764572 : {
			de: "Haupttrafo VDM",
			en: "Main transformer VDM"
		}
		,A2V00002764575 : {
			de: "Hauptschalter AC incl. Erdungstrenner",
			en: "Circuit breaker AC with earthing discon."
		}
		,A2V00002764717 : {
			de: "Aufsteckstromwandler GSOFB 1907 TSI",
			en: "Window type current tran GSOFB 1907 TSI"
		}
		,A2V00002764718 : {
			de: "Aufsteckstromwandler GSO 1305 200A/1A",
			en: "Current transformer GSO 1305 200A/1A"
		}
		,A2V00002767430 : {
			de: "Oel Kuehler",
			en: "Oil cooler"
		}
		,A2V00002775346 : {
			de: "Ballast lackiert",
			en: "Ballast coated"
		}
		,A2V00002785033 : {
			de: "Drehgestell SF4-22A",
			en: "Bogie SF4-22A"
		}
		,A2V00002785034 : {
			de: "Drehgestell SF4-22B",
			en: "Bogie SF4-22B"
		}
		,A2V00002790381 : {
			de: "DE-Maschinenraumtuer Fahrtrichtg. rechts",
			en: "DE-engine room door driving direct. ri."
		}
		,A2V00002790382 : {
			de: "DE-Maschinenraumtuer Fahrtrichtg. links",
			en: "DE-engine room door driving direct. left"
		}
		,A2V00002791112 : {
			de: "Einstiegstuer re RAL5002/PE",
			en: "Entrance door ri. RAL5002/PE"
		}
		,A2V00002791113 : {
			de: "Einstiegstuer li. RAL5002/PE",
			en: "Entrance door le. RAL5002/PE"
		}
		,A2V00002793366 : {
			de: "Einstiegstuer re. RAL5017/PE",
			en: "Entrance door ri. RAL5017/PE"
		}
		,A2V00002793367 : {
			de: "Einstiegstuer li. RAL5017/PE",
			en: "Entrance door le. RAL5017/PE"
		}
		,A2V00002793396 : {
			de: "Maschinenraumtuer",
			en: "Engine room door"
		}
		,A2V00002799099 : {
			de: "Einstiegstuer re. RAL7047/EVVA",
			en: "Entrance door ri. RAL7047/EVVA"
		}
		,A2V00002799100 : {
			de: "Einstiegstuer li. RAL7047/EVVA",
			en: "Entrance door le. RAL7047/EVVA"
		}
		,A2V00002799101 : {
			de: "Einstiegstuer re. RAL9005/Wilka",
			en: "Entrance door ri. RAL9005/Wilka"
		}
		,A2V00002799102 : {
			de: "Einstiegstuer li. RAL9005/Wilka",
			en: "Entrance door le. RAL9005/Wilka"
		}
		,A2V00002799148 : {
			de: "Einstiegstuer re. RAL9016/PE",
			en: "Entrance door ri. RAL9016/PE"
		}
		,A2V00002799149 : {
			de: "Einstiegstuer li. RAL9016/PE",
			en: "Entrance door le. RAL9016/PE"
		}
		,A2V00002799186 : {
			de: "Einstiegstuer re. RAL5015/ABUS",
			en: "Entrance door ri. RAL5015/ABUS"
		}
		,A2V00002799187 : {
			de: "Einstiegstuer li. RAL5015/ABUS",
			en: "Entrance door le. RAL5015/ABUS"
		}
		,A2V00002692512 : {
			de: "Triebdrehgestell 1/4, vollstaendig",
			en: "Motorbogie 1/4, complete"
		}
		,A2V00002692601 : {
			de: "Triebdrehgestell 2/3, vollstaendig",
			en: "Motorbogie 2/3, complete"
		}
		,A2V00002692604 : {
			de: "Laufdrehgestell, vollstaendig",
			en: "Trailerbogie, complete"
		}
		,A2V00002561230 : {
			de: "Stromabnehmermodul",
			en: "Current collector module"
		}
		,A2V00002533816 : {
			de: "Bremsgeraeteeinheit UBNG1",
			en: "Brake equipment unit UBNG1"
		}
		,A2V00002555184 : {
			de: "Hilfsstromabnehmermodul",
			en: "Current collector module"
		}
		,A2V00002562746 : {
			de: "Tuerleiste",
			en: "Door frame"
		}
		,A2V00200300074 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00200300079 : {
			de: "Blech ISO 9445  X6CrNiMoTi17-12-2",
			en: "Sheet ISO 9445  X6CrNiMoTi17-12-2"
		}
		,A2V00200316115 : {
			de: "Blech EN 10029 16X1500X4000 S355J2+N",
			en: "Sheet EN 10029 16X1500X4000 S355J2+N"
		}
		,A2V00200319301 : {
			de: "Blech ISO 9445  X5CrNi18-10",
			en: "Sheet ISO 9445  X5CrNi18-10"
		}
		,A2V00200319302 : {
			de: "Blech ISO 9445  X5CrNi18-10",
			en: "Sheet ISO 9445  X5CrNi18-10"
		}
		,A2V00200319305 : {
			de: "Blech ISO 9445  X5CrNi18-10",
			en: "Sheet ISO 9445  X5CrNi18-10"
		}
		,A2V00200319306 : {
			de: "Blech ISO 9445  X5CrNi18-10",
			en: "Sheet ISO 9445  X5CrNi18-10"
		}
		,A2V00200319501 : {
			de: "Blech ISO 9445  X6CrNiTi18-10",
			en: "Sheet ISO 9445  X6CrNiTi18-10"
		}
		,A2V00200319541 : {
			de: "Blech ISO 9445  X6CrNiMoTi17-12-2",
			en: "Sheet ISO 9445  X6CrNiMoTi17-12-2"
		}
		,A2V00200319602 : {
			de: "Blech ISO 9445  X6CrNiMoTi17-12-2",
			en: "Sheet ISO 9445  X6CrNiMoTi17-12-2"
		}
		,A2V00200319604 : {
			de: "Blech ISO 9445  X6CrNiMoTi17-12-2",
			en: "Sheet ISO 9445  X6CrNiMoTi17-12-2"
		}
		,A2V00200322321 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00200324301 : {
			de: "Blech EN 10131 1,5X1500X3000 S325G",
			en: "Sheet EN 10131 1,5X1500X3000 S325G"
		}
		,A2V00200329541 : {
			de: "Blech ISO 9445  X6CrNiMoTi17-12-2",
			en: "Sheet ISO 9445  X6CrNiMoTi17-12-2"
		}
		,A2V00200339351 : {
			de: "Blech ISO 9445  X5CrNi18-10",
			en: "Sheet ISO 9445  X5CrNi18-10"
		}
		,A2V00200339355 : {
			de: "Blech ISO 9445  X5CrNi18-10",
			en: "Sheet ISO 9445  X5CrNi18-10"
		}
		,A2V00200339357 : {
			de: "Blech ISO 9445  X5CrNi18-10",
			en: "Sheet ISO 9445  X5CrNi18-10"
		}
		,A2V00200339359 : {
			de: "Blech ISO 9445  X5CrNi18-10",
			en: "Sheet ISO 9445  X5CrNi18-10"
		}
		,A2V00200386005 : {
			de: "Rohr",
			en: "Pipe"
		}
		,A2V00200427015 : {
			de: "Sperrzahnschraube",
			en: "lock screw"
		}
		,A2V00200676904 : {
			de: "Blech",
			en: "Plate"
		}
		,A2V00200676905 : {
			de: "Blech",
			en: "Plate"
		}
		,A2V00200676911 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00200676918 : {
			de: "Blech EN 485-4 EN AW-5754-H22",
			en: "Sheet EN 485-4 EN AW-5754-H22"
		}
		,A2V00200676919 : {
			de: "Blech EN 485-4 EN AW-5754-H22",
			en: "Sheet EN 485-4 EN AW-5754-H22"
		}
		,A2V00200677040 : {
			de: "Winkelprofil",
			en: "angle profile"
		}
		,A2V00001817871 : {
			de: "Langtraeger",
			en: "longitudinal girder"
		}
		,A2V00201334723 : {
			de: "Schraube m.Kerbz.",
			en: "T-bolt with nibs"
		}
		,A2V00002256969 : {
			de: "Tuerrippe, Profil",
			en: "door rib, profile"
		}
		,A2V00002597057 : {
			de: "Seitenwand Kurzkuppelende",
			en: "Sidewall non cabend"
		}
		,A2V00002557094 : {
			de: "Leiste",
			en: "Cover"
		}
		,A2V00002557095 : {
			de: "Leiste",
			en: "Cover"
		}
		,A2V00002736044 : {
			de: "SIBAC Wien X-Wagen Duo",
			en: "Inverter container S3T-DUO UBX"
		}
		,A2V00201351825 : {
			de: "Erdungstrenner 3kV, 3pol., 400A",
			en: "Earthing switch 3kV, 3pol., 400A"
		}
		,A2V00207319688 : {
			de: "Fahrersitz",
			en: "DriverÂ´s seat"
		}
		,A2V00207319689 : {
			de: "Beifahrersitz",
			en: "Beifahrersitz"
		}
		,A2V00207350704 : {
			de: "Druckbehaelter DIN 5590-A 5",
			en: "Air reservoir DIN 5590-A-5"
		}
		,A2V00002698771 : {
			de: "Stromrichtercontainer Metro Bangkok Blue",
			en: "Inverter container Metro Bangkok Blue"
		}
		,A2V00002534044 : {
			de: "Abdeckprofil",
			en: "Cover profile"
		}
		,A2V00002541680 : {
			de: "Hilfsbetriebeumrichter",
			en: "Auxiliary inverter"
		}
		,A2V00002610807 : {
			de: "Konsole Hauptschalter",
			en: "console main switch"
		}
		,A2V00002611045 : {
			de: "Konsole Hauptschalter",
			en: "console main switch"
		}
		,A2V00002620576 : {
			de: "Halter Isolator",
			en: "holder isolator"
		}
		,A2V00002667386 : {
			de: "Haube Powerkabel",
			en: "box powercable"
		}
		,A2V00002673215 : {
			de: "Konsole Kabeldurchfuehrung",
			en: "console cable feedthrough"
		}
		,A2V00002674543 : {
			de: "Konsole Dachelektrant",
			en: "console connector block"
		}
		,A2V00002683622 : {
			de: "Befuellungstopf",
			en: "filling box"
		}
		,A2V00002697918 : {
			de: "Druckluftdurchfuehrung",
			en: "pneumatic feedthrough"
		}
		,A2V00002270818 : {
			de: "Rippe sp.g.",
			en: "rib r.i."
		}
		,A2V00002567498 : {
			de: "Druckluftbehaelter",
			en: "compressed air reservoir"
		}
		,A2V00002567499 : {
			de: "Druckluftbehaelter",
			en: "compressed air reservoir"
		}
		,A2V00002676701 : {
			de: "Bodenblech geschw. EWET",
			en: "Bottom plate welded EWET"
		}
		,A2V00002691186 : {
			de: "Langtraeger links F-Ende",
			en: "longitudinal girder left front end"
		}
		,A2V00002691196 : {
			de: "Langtraeger rechts F-Ende",
			en: "longitudinal girder right front end"
		}
		,A2V00002694781 : {
			de: "Langtraeger sp.g.",
			en: "longitud.girder rev.im."
		}
		,A2V00002694852 : {
			de: "Langtraeger w.g.",
			en: "longitud.girder a.d."
		}
		,A2V00001001371 : {
			de: "SIBAS-KLIP AE 4x+-10V",
			en: "SIBAS-KLIP AE 4x+-10V"
		}
		,A2V00002607423 : {
			de: "Rahmen Kabelkamin",
			en: "frame cable pipe"
		}
		,A2V00002683483 : {
			de: "Verschlussdeckel Ersatzteil",
			en: "Sealing cap spare part"
		}
		,A2V00002393508 : {
			de: "Hauptquertraeger aussen",
			en: "main cross girder outside"
		}
		,A2V00002530344 : {
			de: "Stirnwand",
			en: "endwall"
		}
		,A2V00002468844 : {
			de: "Gelenkplatte",
			en: "articulation plate"
		}
		,A2V00002451277 : {
			de: "Platte voll.",
			en: "Plate complt."
		}
		,A2V00002533932 : {
			de: "Langtr. Profil F-Ende",
			en: "Longit. beam dc-end"
		}
		,A2V00002534002 : {
			de: "Badsicherung",
			en: "weld pool backing"
		}
		,A2V00001008915 : {
			de: "Zentralrechner",
			en: "Central Processing Unit"
		}
		,A2V00001010506 : {
			de: "Zentralrechner",
			en: "MC central processing unit: MCCPU"
		}
		,A2V00002572313 : {
			de: "Langtraeger F-Ende voll.",
			en: "longitudinal girder"
		}
		,A2V00001805654 : {
			de: "Seitendecke 04    vollst.",
			en: "sideceiling 04    compl."
		}
		,A2V00002093228 : {
			de: "H-Profil",
			en: "H-section"
		}
		,A2V00002566493 : {
			de: "Tuermodul",
			en: "door-module"
		}
		,A2V00002566494 : {
			de: "Tuermodul",
			en: "door-module"
		}
		,A2V00002670626 : {
			de: "Unterzug Profil",
			en: "submission profile"
		}
		,A2V00002693645 : {
			de: "Unterzug Profil",
			en: "submission profile"
		}
		,A2V00002693796 : {
			de: "Unterzug Profil",
			en: "submission profile"
		}
		,A2V00002713079 : {
			de: "Unterzug Profil",
			en: "submission profile"
		}
		,A2V00002560666 : {
			de: "Dachplatte Seitenteil",
			en: "roof plate side part"
		}
		,A2V00002570854 : {
			de: "Endsaeule kompl. rechts KK-Ende",
			en: "end pillar compl. right short coupler"
		}
		,A2V00002571270 : {
			de: "Endsaeule kompl. links KK-Ende",
			en: "end pillar compl. left short coupler end"
		}
		,A2V00002578742 : {
			de: "Voute links vollst.",
			en: "a-post inclined left compl."
		}
		,A2V00002578743 : {
			de: "Voute rechts vollst.",
			en: "a-post inclined ri. compl."
		}
		,A2V00002707158 : {
			de: "Rack Konsole",
			en: "Console for rack"
		}
		,A2V00002572450 : {
			de: "Langtraeger F-Ende voll.",
			en: "longitudinal girder"
		}
		,A2V00002606151 : {
			de: "Liefergarnitur DMOSB Einstieg",
			en: "Delivering set DMOSB entrance"
		}
		,A2V00002606152 : {
			de: "Liefergarnitur PTOSB Einstieg",
			en: "Delivering set PTOSB entrance"
		}
		,A2V00002606153 : {
			de: "Liefergarnitur MOS/TOS Einstieg",
			en: "Delivering set MOS/TOS entrance"
		}
		,A2V00002606154 : {
			de: "Liefergarnitur TOS(L)W Einstieg",
			en: "Delivering set TOS(L)W entrance"
		}
		,A2V00001910840 : {
			de: "Konsole fuer Querspielbegrenzung",
			en: "Bracket for lateral limitaion"
		}
		,A2V00001938094 : {
			de: "Queranschlag",
			en: "Lateral stop"
		}
		,A2V00001914648 : {
			de: "Steg 6",
			en: "Web 6"
		}
		,A2V00001914653 : {
			de: "Rippe 1 WG",
			en: "Rib 1 AD"
		}
		,A2V00001914668 : {
			de: "Steg 5 SPG",
			en: "Web 5 OAD"
		}
		,A2V00002707462 : {
			de: "Stirnwand",
			en: "endwall"
		}
		,A2V00002255458 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00002334154 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002112989 : {
			de: "Queranschlag",
			en: "Cross stop"
		}
		,A2V00002163693 : {
			de: "Rohr",
			en: "tube"
		}
		,A2V00001055401 : {
			de: "Luftkessel",
			en: "air tank;"
		}
		,A2V00001055857 : {
			de: "Kupplungsteil",
			en: "Coupling Section"
		}
		,A2V00002116279 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00001068863 : {
			de: "Flanschbremsscheibe LFW COMBINO",
			en: "Breake disk"
		}
		,A2V00001849187 : {
			de: "Trennschalter Stromabnehmer DC",
			en: "Disconnector for Pantograph DC"
		}
		,A2V00001849124 : {
			de: "Erdungstrennschalter DC",
			en: "Earthing disconnector DC"
		}
		,A2V00002102591 : {
			de: "Dachplatte, geschw.",
			en: "Roof plate, welded"
		}
		,A2V00002097605 : {
			de: "Kupplungsplatte vollstaendig",
			en: "coupling plate complete"
		}
		,A2V00002256422 : {
			de: "Laengsprofil      F-Ende",
			en: "longitud. profile f-end"
		}
		,A2V00002316943 : {
			de: "Mittellangtraeger, mit Luftfederauflage",
			en: "Middle longit. girder with spring supp."
		}
		,A2V00002256249 : {
			de: "Langtaregerprofil NF",
			en: "longit. beam profile LF"
		}
		,A2V00002101967 : {
			de: "Blech sp.gl.",
			en: "plate r.i."
		}
		,A2V00002316587 : {
			de: "Konsole",
			en: "console"
		}
		,A2V00002081164 : {
			de: "Anhebetraeger",
			en: "lift holder"
		}
		,A2V00002185311 : {
			de: "Schweissbadsicherung w.g.",
			en: "weld pool backing as d."
		}
		,A2V00002090765 : {
			de: "Quertraeger       Dach",
			en: "crossgirder       roof"
		}
		,A2V00002690987 : {
			de: "LV Box +77 vormontiert",
			en: "LV Box +77 pre-assembled"
		}
		,A2V00002335617 : {
			de: "Firebox mech.",
			en: "Firebox mechanical components"
		}
		,A2V00002232032 : {
			de: "E-Tafel 1 vormontiert",
			en: "E-Cabinet 1 pre-assembled"
		}
		,A2V00002320353 : {
			de: "E-Tafel 2 vormontiert",
			en: "E-Cabinet 2 pre-assembled"
		}
		,A2V00002235646 : {
			de: "E-Tafel 3 vormontiert",
			en: "E-Cabinet 3 pre-assembled"
		}
		,A2V00002550596 : {
			de: "E-Geraeteschrank +15 vormontiert",
			en: "Electrical cabinet +15 pre-assembled"
		}
		,A2V00002561062 : {
			de: "E-Geraeteschrank +14 vormontiert",
			en: "Electrical cabinet +14 pre-assembled"
		}
		,A2V00002714906 : {
			de: "Fahrtenschreiber REDBOXpro-RBF1S1byma",
			en: "Tachograph REDBOXpro-RBF1S1byma"
		}
		,A2V00002121944 : {
			de: "Blech",
			en: "sheet"
		}
		,A2V00002142816 : {
			de: "Rohr",
			en: "tube"
		}
		,A2V00002093010 : {
			de: "Zwischenblech     F-Ende",
			en: "intermediate plate front end"
		}
		,A2V00002093271 : {
			de: "Untergurt F-Ende",
			en: "lower boom front end"
		}
		,A2V00002093272 : {
			de: "Querprofil",
			en: "cross profile"
		}
		,A2V00002116035 : {
			de: "Tuerblech sp.g.",
			en: "door plate r.i."
		}
		,A2V00002122033 : {
			de: "Seitenblech",
			en: "side sheet"
		}
		,A2V00002122368 : {
			de: "Tuerblech w.g.",
			en: "door plate as d."
		}
		,A2V00002090329 : {
			de: "Quertraeger",
			en: "cross beam"
		}
		,A2V00002103465 : {
			de: "Dichtblech  sp.g.",
			en: "Sealing sheet    r.i."
		}
		,A2V00002121925 : {
			de: "Winkelprofil",
			en: "angle profile"
		}
		,A2V00002121969 : {
			de: "Winkelprofil",
			en: "angle profile"
		}
		,A2V00002122486 : {
			de: "Tuerblech w.g.",
			en: "door plate as d."
		}
		,A2V00002122022 : {
			de: "Blech sp. gl.",
			en: "sheet r. i."
		}
		,A2V00002106004 : {
			de: "Kupplungsplatte",
			en: "coupling plate"
		}
		,A2V00002537222 : {
			de: "Seitenwandprofil, oberes",
			en: "Side wall profile, upper"
		}
		,A2V00002536999 : {
			de: "Bodenprofil 2 eben",
			en: "Floor profile 2 flat"
		}
		,A2V00002536934 : {
			de: "Bodenprofil 1, mittleres",
			en: "Floor profile 1, middle"
		}
		,A2V00002537000 : {
			de: "Dachlangtraeger unten MC",
			en: "Roof main beam lower MC"
		}
		,A2V00002537002 : {
			de: "Dachlangtraeger unten T1",
			en: "Roof main beam lower T1"
		}
		,A2V00002537264 : {
			de: "Langtraeger unterer f. Untergestell KKE",
			en: "Longit. beam lower, f. underframe SCE"
		}
		,A2V00002542528 : {
			de: "Konsole ZSB",
			en: "Console ASSY"
		}
		,A2V00002542548 : {
			de: "Bodenplatte",
			en: "Base plate"
		}
		,A2V00001189864 : {
			de: "Glastrennscheibe 2 WE",
			en: "Draught screen 2  car end"
		}
		,A2V00002552372 : {
			de: "Dachlangtraeger Einzug, sp.g.",
			en: "Roof cantrail margin, i.m.i."
		}
		,A2V00002542427 : {
			de: "Bodenblech WE2 ZSB",
			en: "Floor plate CE2 ASSY"
		}
		,A2V00002542439 : {
			de: "Dichtblech",
			en: "Sealing plate"
		}
		,A2V00002542827 : {
			de: "Klimawanne FSTE, Zusb.",
			en: "ACU pan cab-end, assy."
		}
		,A2V00002547397 : {
			de: "Dachplatte, mittlere T1",
			en: "Roof plate, middle T1"
		}
		,A2V00002591987 : {
			de: "Verstaerkungsrippe, DLT, sp.g.",
			en: "Supporting rib, roof CR, i.m.i."
		}
		,A2V00002591991 : {
			de: "Verstaerkungsrippe, DLT, w.g.",
			en: "Supporting rib, roof CR, a.d."
		}
		,A2V00002538451 : {
			de: "Kupplungskasten WE1",
			en: "Coupling box CE1"
		}
		,A2V00002539666 : {
			de: "Hauptquertraeger, geschw.",
			en: "Main cross girder, welded"
		}
		,A2V00002540780 : {
			de: "Kupplungskasten KKE",
			en: "Coupling-box SCE"
		}
		,A2V00002573308 : {
			de: "Bodenprofil, aussen MP",
			en: "Prof. Floor, outside MP"
		}
		,A2V00002573309 : {
			de: "Bodenprofil, aussen MP",
			en: "Prof. Floor, outside MP"
		}
		,A2V00002542506 : {
			de: "Bodenplatte WE1 sp.g.",
			en: "Floor plate CE1 i.m.i."
		}
		,A2V00002542508 : {
			de: "Bodenplatte WE1 w.g.",
			en: "Floor plate CE1 a.d."
		}
		,A2V00002589316 : {
			de: "Flansch, ZSB",
			en: "Flange, ASSY"
		}
		,A2V00002591441 : {
			de: "Deckel, DLT oberer",
			en: "Cover, RC upper"
		}
		,A2V00002553906 : {
			de: "Radkasten ZSB",
			en: "Wheel well ASSY"
		}
		,A2V00002554047 : {
			de: "Radkasten WE1 w.g.",
			en: "Wheel well CE1 a.d."
		}
		,A2V00002554076 : {
			de: "Radkasten WE2 sp.g.",
			en: "Wheel well CE2 i.m.i."
		}
		,A2V00002554077 : {
			de: "Radkasten WE2 w.g.",
			en: "Wheel well CE2 a.d."
		}
		,A2V00002542720 : {
			de: "Tuersaeule ZSB w.g.",
			en: "Door post ASSY a.d."
		}
		,A2V00002542721 : {
			de: "Tuersaeule ZSB sp.g.",
			en: "Door post ASSY i.m.i."
		}
		,A2V00002543675 : {
			de: "Klimawanne KKE, Zusb.",
			en: "ACU pan car-end, assy."
		}
		,A2V00002592120 : {
			de: "Schweissbadsicherung, sp.g.",
			en: "Weld backing, i.m.i."
		}
		,A2V00002544731 : {
			de: "Stirnwand f. Klimawanne",
			en: "ACU pan endwall"
		}
		,A2V00002542520 : {
			de: "Unterzug Pult sp.g.",
			en: "Trussing console i.m.i."
		}
		,A2V00002542516 : {
			de: "Unterzug Pult w.g.",
			en: "Trussing console a.d."
		}
		,A2V00001133851 : {
			de: "Kompensator DN 100",
			en: "Compensator, DN 100"
		}
		,A2V00001889886 : {
			de: "Manometer PG-80D",
			en: "Pressure Gauge Series 80,Double"
		}
		,A2V00001978677 : {
			de: "Makrofon",
			en: "Makrofon ZM75/622"
		}
		,A2V00001910156 : {
			de: "Makrofon",
			en: "Makrofon ZM75/370.1 UIC"
		}
		,A2V00002017711 : {
			de: "Kuehlanlage Transformator",
			en: "Cooling unit      Transformer"
		}
		,A2V00002157172 : {
			de: "Abdeckblech 2",
			en: "cover plate 2"
		}
		,A2V00002332229 : {
			de: "Grauwasserverrohrung geschweisst",
			en: "Gray water piping welded"
		}
		,A2V00002279225 : {
			de: "Luftbehaelter Stahl 25L",
			en: "air reservoir steel 25l"
		}
		,A2V00001148329 : {
			de: "Scharnier w.g.",
			en: "Hinge as drawn"
		}
		,A2V00001165890 : {
			de: "Horn kpl. Re.",
			en: "Horn kpl. Re."
		}
		,A2V00002312309 : {
			de: "Blech",
			en: "plate"
		}
		,A2V00002310628 : {
			de: "Mittellangtraeger F-Ende vollst.",
			en: "longitudinal girder front end compl."
		}
		,A2V00002267248 : {
			de: "Rohr, geschweisst",
			en: "Pipe, welded"
		}
		,A2V00002335507 : {
			de: "Geruest Sat Antenne",
			en: "frame SAT antenna"
		}
		,A2V00002677482 : {
			de: "Bodenblech geschw. EWET",
			en: "Bottom plate welded EWET"
		}
		,A2V00001221324 : {
			de: "Quertraeger Hutprofil",
			en: "Cossbeam hatprofil"
		}
		,A2V00001236100 : {
			de: "Display NG mit Flash Card",
			en: "Display, NG with flash card"
		}
		,A2V00002334038 : {
			de: "Gewindeplatte",
			en: "Threaded plate"
		}
		,A2V00001266924 : {
			de: "Halter Eurobalise Komplett",
			en: "Holder Eurobalise Complete"
		}
		,A2V00002717274 : {
			de: "Container vormontiert",
			en: "Container pre-assembled"
		}
		,A2V00001277449 : {
			de: "StaubaustraggeblÃ¤se fÃ¼r KÃ¼hlanlage ICT2",
			en: "StaubaustraggeblÃ¤se fÃ¼r KÃ¼hlanlage ICT2"
		}
		,A2V00002761131 : {
			de: "Lehne geschw.",
			en: "Backboard welded"
		}
		,A2V00002701245 : {
			de: "Bremswiderstand 3PQ4-B01073 BLE2",
			en: "Brake resistor 3PQ4-B01073 BLE2"
		}
		,A2V00001313222 : {
			de: "Kohlebuerste",
			en: "carbon brush"
		}
		,A2V00002195663 : {
			de: "Gleichstromschnellschalter  1kV 1kA",
			en: "High speed circuit breaker 1kV 1kA"
		}
		,A2V00002615638 : {
			de: "Quertraeger 05 MC ZSB",
			en: "Cross girder 05 MC ASSY"
		}
		,A2V00001851734 : {
			de: "Bedientafel X4E1122 V2",
			en: "Operating panel X4E1122 V2"
		}
		,A2V00002573496 : {
			de: "Schleifstueck mit AS",
			en: "Contact strip with ADD"
		}
		,A2V00002315272 : {
			de: "E-Geraeteschrank 1 vormontiert",
			en: "Electrical cabinet 1 pre-assembled"
		}
		,A2V00002334210 : {
			de: "E-Geraeteschrank +X14 vormontiert",
			en: "Electrical cabinet +X14 pre-assembled"
		}
		,A2V00001863940 : {
			de: "Bedientafel X4E1133 V3",
			en: "Operating panel X4E1133 V3"
		}
		,A2V00002544144 : {
			de: "FIS - Schrank 2 vormontiert",
			en: "PIS - cabinet 2 pre-assembled"
		}
		,A2V00002230962 : {
			de: "Sicherheitsventil 10,5 bar",
			en: "Safety valve 10,5 bar"
		}
		,A2V00001419382 : {
			de: "Ablassventil",
			en: "Drain valve"
		}
		,A2V00001429111 : {
			de: "Steuerung         SIBCOS M2500-0-9",
			en: "control unit        SIBCOS M2500-0-9"
		}
		,A2V00370071869 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002574956 : {
			de: "Blech KK-Ende",
			en: "plate as d. short coupler end"
		}
		,A2V00001687864 : {
			de: "Steuerung SIBCOS M2000-0-0",
			en: "control unit SIBCOS M2000-0-0"
		}
		,A2V00001694499 : {
			de: "Lagerschild AS-FT",
			en: "Bearing shielden"
		}
		,A2V00001695924 : {
			de: "Kopftraeger lackiert SP.G.",
			en: "head beam painted o.a.d."
		}
		,A2V00001695996 : {
			de: "Kopftraeger lackiert W.G.",
			en: "head beam painted a. d."
		}
		,A2V00001711653 : {
			de: "Fahrmotorluefter V3",
			en: "Traction motor blower v3"
		}
		,A2V00001715376 : {
			de: "SIBAS Bremssteuerrechner (BSR)",
			en: "SIBAS - Brake Control Unit (BCU)"
		}
		,A2V00112500620 : {
			de: "GUSSECKE, RECHTS",
			en: "cast angle, right"
		}
		,A2V00113012412 : {
			de: "PROFIL FUER       ENDSAEULE",
			en: "PROFIL FUER       ENDSAEULE"
		}
		,A2V00113046313 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00102504553 : {
			de: "SECHSKANTMUTTER                        .",
			en: "SECHSKANTMUTTER                        ."
		}
		,A2V00001716264 : {
			de: "Schuerze links lackiert",
			en: "shroud left lacquered"
		}
		,A2V00001721498 : {
			de: "Bremsbelag geteilt, isoliert",
			en: "Brake pad split,  underlayer"
		}
		,A2V00100023124 : {
			de: "Sechskantschraube",
			en: "Sechskantschraube"
		}
		,A2V00110163234 : {
			de: "PROFIL FUER FENSTERSAEULE(HOCHFLUR)",
			en: "PROFIL FUER FENSTERSAEULE(HOCHFLUR)"
		}
		,A2V00397250663 : {
			de: "RIPPE HINTEN",
			en: "RIB BACK"
		}
		,A2V00397250665 : {
			de: "KONSOLE F. TBL",
			en: "BRACKET F. TBL"
		}
		,A2V00397155307 : {
			de: "ZWISCHENSTUECK",
			en: "Adaptor"
		}
		,A2V00001778146 : {
			de: "Kuehlelement ICT2",
			en: "cooling element ICT2"
		}
		,A2V00001779178 : {
			de: "Stuetzluefter f. DE-Lokomotiven",
			en: "support ventilator f. DE locomotives"
		}
		,A2V00001801790 : {
			de: "Batterieladegeraet DC 24V",
			en: "Battery charger DC 24V"
		}
		,A2V00100641715 : {
			de: "LENKSTANGE,RECHTS/RECHTS GEWINDE",
			en: "GUIDING ARM"
		}
		,A2V00112514041 : {
			de: "SCHUERZE RECHTS VOLLST.",
			en: "Spoiler right, compl."
		}
		,A2V00370021643 : {
			de: "HP DIN59411-60x60x5-S355JO        22",
			en: "HP DIN59411-60x60x5-S355JO        22"
		}
		,A2V00370035064 : {
			de: "4KT DIN178-30-CK45K",
			en: "4KT DIN178-30-CK45K"
		}
		,A2V00001830766 : {
			de: "Hydraulik-Kombi-Stossdaempfer EP70D",
			en: "hydraulics combination dash pot EP70D"
		}
		,A2V00001830870 : {
			de: "Ausgleichsbehaelter",
			en: "Compansation tank"
		}
		,A2V00100615609 : {
			de: "Spannbuchse, komplett",
			en: "Clamping bush, complete"
		}
		,A2V00397163957 : {
			de: "Schichtfeder",
			en: "layer spring"
		}
		,A2V00370036280 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00397169713 : {
			de: "Luftfederrohr",
			en: "Air spring tube"
		}
		,A2V00370015737 : {
			de: "L EN10056-1-80x40x6-S235JRG2",
			en: "L EN10056-1-80x40x6-S235JRG2"
		}
		,A2V00397176553 : {
			de: "Platte f. HÃ¶heneinstellung",
			en: "plate f. height adjusting"
		}
		,A2V00397248077 : {
			de: "Federteller unten,lackiert",
			en: "Spring plate below, painted"
		}
		,A2V00397220178 : {
			de: "Halter fuer Schelle",
			en: "Bracket for clip"
		}
		,A2V00001849116 : {
			de: "Fahrersitz",
			en: "driver`s seat"
		}
		,A2V00001849117 : {
			de: "Beifahrersitz",
			en: "co driver`s seat"
		}
		,A2V00370072642 : {
			de: "RADREIFEN",
			en: "TYRE"
		}
		,A2V00370052836 : {
			de: "Gelenk mit Rechtsgewinde",
			en: "Hinge with right hand thread"
		}
		,A2V00397258436 : {
			de: "GURTBLECH WG.",
			en: "FLANGE PLATE AS DRAWN"
		}
		,A2V00397258438 : {
			de: "GURTBLECH SPG.",
			en: "FLANGE PLATE OPP.A.D"
		}
		,A2V00397258439 : {
			de: "STEG",
			en: "WEB"
		}
		,A2V00397258440 : {
			de: "ROHR",
			en: "PIPE"
		}
		,A2V00397258441 : {
			de: "LAENGSLENKERKONSOLE",
			en: "GUIDING ROD SUPPORT"
		}
		,A2V00002236398 : {
			de: "Traeger, unterer",
			en: "Beam, lower"
		}
		,A2V00002081938 : {
			de: "Fahrschalter Avenio",
			en: "Driver switch Avenio"
		}
		,A2V00002219036 : {
			de: "Bremsgeraetetafel Laufwagen Typ  2",
			en: "brake panel  Trailer Cars type 2"
		}
		,A2V00002219037 : {
			de: "Bremsgeraetetafel Laufwagen Typ 3",
			en: "brake panel  Trailer Cars type 3"
		}
		,A2V00002124329 : {
			de: "Quertraeger 1",
			en: "Cross beam 1"
		}
		,A2V00002124330 : {
			de: "Steher f. Kasten 2",
			en: "Stayer f. box 2"
		}
		,A2V00002124331 : {
			de: "Langtraeger 1",
			en: "Longitudinal beam 1"
		}
		,A2V00002124332 : {
			de: "Steher f. Kasten 1",
			en: "Stayer f. Box 1"
		}
		,A2V00002124333 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002124334 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002124335 : {
			de: "Steher f. Ventile",
			en: "Stayer f. valves"
		}
		,A2V00002124666 : {
			de: "Traeger",
			en: "Beam"
		}
		,A2V00002124667 : {
			de: "Steher",
			en: "Stayer"
		}
		,A2V00002124668 : {
			de: "Aufhaengung 1",
			en: "Hanging 1"
		}
		,A2V00002140695 : {
			de: "Traeger, unterer",
			en: "Beam, lower"
		}
		,A2V00002140896 : {
			de: "Schutzplatte",
			en: "Protective plate"
		}
		,A2V00002152285 : {
			de: "Aufhaengung 2",
			en: "Hanging 2"
		}
		,A2V00002152563 : {
			de: "Langtreager 2",
			en: "Longitudinal beam 2"
		}
		,A2V00002152564 : {
			de: "Quertraeger 2",
			en: "Cross beam 2"
		}
		,A2V00002236397 : {
			de: "Steher",
			en: "Stayer"
		}
		,A2V00002219038 : {
			de: "Bremsgeraetetafel Laufwagen Typ 4",
			en: "brake panel  Trailer Cars type 4"
		}
		,A2V00002114733 : {
			de: "Bremsgeraetetafel Triebwagen",
			en: "brake panel  Motor Cars"
		}
		,A2V00002114731 : {
			de: "Bremsgeraetetafel Laufwagen Typ 1",
			en: "brake panel  Trailer Cars type 1"
		}
		,A2V00001874181 : {
			de: "Bugspoiler mitte GFK",
			en: "Spoiler, middle grp."
		}
		,A2V00002583443 : {
			de: "Dachplatte, aeussere, sp.g.",
			en: "Roof plate, outer, i.m.i."
		}
		,A2V00002583444 : {
			de: "Dachplatte, mittlere",
			en: "Roof plate, middle"
		}
		,A2V00002583445 : {
			de: "Dachplatte, aeussere, w.g.",
			en: "Roof plate, outer, a.d."
		}
		,A2V00002583507 : {
			de: "Dachplatte, aeussere, w.g.",
			en: "Roof plate, outer, a.d."
		}
		,A2V00002583508 : {
			de: "Dachplatte, aeussere, sp.g.",
			en: "Roof plate, outer, i.m.i."
		}
		,A2V00002583509 : {
			de: "Dachplatte, mittlere",
			en: "Roof plate, middle"
		}
		,A2V00002547375 : {
			de: "Dachplatte, aeussere T1",
			en: "Roof plate, outer T1"
		}
		,A2V00002583471 : {
			de: "Dachplatte, aeussere MC",
			en: "Roof plate, outer MC"
		}
		,A2V00002583498 : {
			de: "Dachplatte, aeussere MC",
			en: "Roof plate, outer MC"
		}
		,A2V00002583516 : {
			de: "Dachplatte, mittlere MC",
			en: "Roof plate, middle MC"
		}
		,A2V00002590784 : {
			de: "Konsole ZSB",
			en: "Console ASSY"
		}
		,A2V00002591096 : {
			de: "Platte 1 f. M6 ZSB",
			en: "Plate 1 f. M6 ASSY"
		}
		,A2V00002591005 : {
			de: "Platte 2 f. M8 ZSB",
			en: "Plate 2 f. M8 ASSY"
		}
		,A2V00002577545 : {
			de: "Durchfuehrung Bowdenzug",
			en: "Duct f. bowden cable"
		}
		,A2V00002544775 : {
			de: "Rippe, Dachquertraeger, KKE",
			en: "Rib, roof cross beam, short coupler end"
		}
		,A2V00002542565 : {
			de: "Haube ZSB",
			en: "Underframe A"
		}
		,A2V00001882737 : {
			de: "Sandtr.Geh. Ed",
			en: "sand-stair housing ed"
		}
		,A2V00001882739 : {
			de: "Sandtr.Geh. Ed",
			en: "sand-stair housing ed"
		}
		,A2V00001882749 : {
			de: "Gewindebolzen Kniegelenk",
			en: "thread bold"
		}
		,A2V00001882906 : {
			de: "Sandtreppe KM-Ed re lack.",
			en: "Sand stair KM-Ed ri coated"
		}
		,A2V00001895438 : {
			de: "Rangiertritt lackiert",
			en: "step painted"
		}
		,A2V00001895565 : {
			de: "Abfallbehaelter",
			en: "Waste container"
		}
		,A2V00370072884 : {
			de: "HANDTUCHHALTER FUER A/B-MODUL",
			en: "HANDTUCHHALTER FUER A/B-MODUL"
		}
		,A2V00397155679 : {
			de: "Indusi - Einstellkonsole, vollst.",
			en: "ndusi - adjustable consol complete"
		}
		,A2V00001897374 : {
			de: "Rangiertritt lackiert",
			en: "step painted"
		}
		,A2V00002615145 : {
			de: "Befestigung f. ATC ZSB sp.g.",
			en: "Mounting f. ATC ASSY i.m.i."
		}
		,A2V00002593796 : {
			de: "UG Haengung lang",
			en: "UF Bracket long"
		}
		,A2V00001955539 : {
			de: "Rahmen geschweisst",
			en: "Frame welded"
		}
		,A2V00001972727 : {
			de: "Filtertrocknersammler inkl Schauglas",
			en: "Filter-dryer-accumulator with refrigeran"
		}
		,A2V00397157715 : {
			de: "Deckel",
			en: "Cover"
		}
		,A2V00001998764 : {
			de: "CS - Erdungskohlebuerste",
			en: "CS - Earthing carbon brush"
		}
		,A2V00311400194 : {
			de: "4KT DIN178-40-ST37-2K",
			en: "4KT DIN178-40-ST37-2K"
		}
		,A2V00002120404 : {
			de: "Druckbehaelter Stahl 75L",
			en: "air receiver steel 75L"
		}
		,A2V00002005797 : {
			de: "Schraubendruckfedersatz 2",
			en: "Helical Compression Spring TB 2"
		}
		,A2V00311400174 : {
			de: "4KT DIN1014-16-S355JO",
			en: "SQUARE DIN1014-16-S355JO"
		}
		,A2V00370037025 : {
			de: "DOPPELFEDERSTECKER DB248859-4-ZN",
			en: "DOPPEL SPRING COTTER DB248859-4-ZN"
		}
		,A2V00370085124 : {
			de: "QRO EN10210-60X60X5-S355J2H",
			en: "QRO EN10210-60X60X5-S355J2H"
		}
		,A2V00002042547 : {
			de: "Kurzkupplungshaelfte A UBMC 2.11",
			en: "Short Coupler A UBMC 2.11"
		}
		,A2V00002042548 : {
			de: "Kurzkupplungshaelfte B UBMC 2.11",
			en: "Short Coupler B UBMC 2.11"
		}
		,A2V00370060014 : {
			de: "Federspeicherbremssattel HYS 101/04",
			en: "Spring applied brake caliper HYS 101/04"
		}
		,A2V00370059788 : {
			de: "3-fach Magnetventil",
			en: "Solenoid valve (3-fold)"
		}
		,A2V00002237264 : {
			de: "Distanzblech",
			en: "Distance plate"
		}
		,A2V00150268033 : {
			de: "DECKEL",
			en: "top cover"
		}
		,A2V00002559784 : {
			de: "Abdeckung links",
			en: "cover left"
		}
		,A2V00002559785 : {
			de: "Abdeckung rechts",
			en: "cover right"
		}
		,A2V00002091435 : {
			de: "Armlehne rechts",
			en: "Right armrest"
		}
		,A2V00002091484 : {
			de: "Schachtfenster,links, kpl. ohne Griff",
			en: "Bay window on the left"
		}
		,A2V00002102528 : {
			de: "Laeuferzentralschraube  M52x3x380",
			en: "Screw   M52x3x380"
		}
		,A2V00002132509 : {
			de: "Bremszylinder mit Federspeicher",
			en: "Brake cylinders with spring store"
		}
		,A2V00002136735 : {
			de: "Ausgleichsbehaelter (AGB) Stromrichter",
			en: "Regulation reservoir converter"
		}
		,A2V00002173176 : {
			de: "Frontscheibe",
			en: "Windscreen"
		}
		,A2V00397286931 : {
			de: "Bodenlager 1,     vollst.",
			en: "Bottom attach-    ment 1,compl."
		}
		,A2V00002344919 : {
			de: "Crashelement",
			en: "Crash element"
		}
		,A2V00002344920 : {
			de: "Crashelement",
			en: "Crash element"
		}
		,A2V00002350221 : {
			de: "Druckfeder Typ 1",
			en: "Compression Spring Type 1"
		}
		,A2V00002189378 : {
			de: "Sandeinfuellklappe aussen",
			en: "sand filling cover outside"
		}
		,A2V00002333741 : {
			de: "Gegenblech",
			en: "Counter sheet"
		}
		,A2V00002191942 : {
			de: "Bugnase vst.",
			en: "Front nose cpl."
		}
		,A2V00002208966 : {
			de: "Relaisventil",
			en: "relay valve"
		}
		,A2V00002255232 : {
			de: "Schutzblech",
			en: "Guard plate"
		}
		,A2V00002226083 : {
			de: "Seitenklappe vollst. sp.g.",
			en: "side flap complete r.i."
		}
		,A2V00002226084 : {
			de: "Seitenklappe vollst. w.g.",
			en: "side flap complete a.d"
		}
		,A2V00002226085 : {
			de: "Seitenklappe vollst.",
			en: "side flap complete"
		}
		,A2V00002226106 : {
			de: "Seitenklappe vollst. sp.g.",
			en: "side flap complete r.i."
		}
		,A2V00002226107 : {
			de: "Seitenklappe vollst. w.g.",
			en: "side flap complete a.d"
		}
		,A2V00002229020 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00002245010 : {
			de: "Hilfsluftkompressor D-100",
			en: "Auxiliary compressor D-100"
		}
		,A2V00002249933 : {
			de: "Fahrmotor (Avenio)",
			en: "Traction motor (Avenio)"
		}
		,A2V00002254078 : {
			de: "Streurohr mit Heizpatrone, komplett",
			en: "Dust tube with heating cartridge, compl."
		}
		,A2V00002255359 : {
			de: "Halter rechts",
			en: "bracket right"
		}
		,A2V00002255360 : {
			de: "Halter links",
			en: "bracket left"
		}
		,A2V00002257023 : {
			de: "Halter rechts",
			en: "Support, right"
		}
		,A2V00002257247 : {
			de: "Wippe",
			en: "collector head"
		}
		,A2V00002265132 : {
			de: "Sitzoberteil",
			en: "Seat top"
		}
		,A2V00002267131 : {
			de: "Kegelradsatz ZS430/2,29 LVT643",
			en: "Bevel gear set ZS430/2,29 LVT643"
		}
		,A2V00002267133 : {
			de: "Kegelradsatz ZS430/2,29 LVT643",
			en: "Bevel gear set ZS430/2,29 LVT643"
		}
		,A2V00002276605 : {
			de: "Schleifleiste fuer Stromabnehmer",
			en: "Carbon-strip for pantograph"
		}
		,A2V00002309152 : {
			de: "Schalttafel innen",
			en: "Internal control panel"
		}
		,A2V00002316109 : {
			de: "Schleifleiste fuer Stromabnehmer",
			en: "carbon-strip for pantograph"
		}
		,A2V00002317057 : {
			de: "Druckluftsteuerungskasten",
			en: "Air Control Box (Pantograph mounted)"
		}
		,A2V00002325249 : {
			de: "Triebdrehgestell VT-6HST EC01 WE1",
			en: "Motorbogie VT-6HST EC01 CE1"
		}
		,A2V00002325831 : {
			de: "Triebdrehgestell VT-6HST EC01 WE2",
			en: "Motorbogie VT-6HST EC01 CE2"
		}
		,A2V00002325884 : {
			de: "Laufdrehgestell VT-6HST TC02 WE1",
			en: "Trailerbogie VT-6HST TC02 CE1"
		}
		,A2V00002325885 : {
			de: "Laufdrehgestell VT-6HST TC02 WE2",
			en: "Trailerbogie VT-6HST TC02 CE2"
		}
		,A2V00002325956 : {
			de: "Triebdrehgestell VT-6HST CC03 WE1",
			en: "Motorbogie VT-6HST CC03 CE1"
		}
		,A2V00002325958 : {
			de: "Laufdrehgestell VT-6HST MC04 WE1",
			en: "Trailerbogie VT-6HST MC04 CE1"
		}
		,A2V00002325959 : {
			de: "Laufdrehgestell VT-6HST MC04 WE2",
			en: "Trailerbogie VT-6HST MC04 CE2"
		}
		,A2V00002341846 : {
			de: "Klimaanlage FGR",
			en: "HVAC-unit saloon"
		}
		,A2V00002342772 : {
			de: "Schleifleiste komplett",
			en: "Schleifleiste komplett"
		}
		,A2V00002344425 : {
			de: "Kegelradsatz ZS430/2,29 LVT643.2",
			en: "Bevel gear set ZS430/2,29 LVT643.2"
		}
		,A2V00002348085 : {
			de: "Oeffnungstaster",
			en: "Push button open"
		}
		,A2V00002348086 : {
			de: "Schliesstaster",
			en: "Push button close"
		}
		,A2V00002354857 : {
			de: "Momentanueberspannungsbegrenzer (MUB)",
			en: "Momentary Overvoltage Limiter (MOL)"
		}
		,A2V00002358088 : {
			de: "Hilfsbetriebeumrichter",
			en: "Auxiliary inverter"
		}
		,A2V00001135271 : {
			de: "TrÃ¤gerhÃ¤lfte, H",
			en: "carrier half, H"
		}
		,A2V00001135270 : {
			de: "TrÃ¤gerhÃ¤lfte, V",
			en: "carrier half, V"
		}
		,A2V00002361972 : {
			de: "Signalleuchte            SL unten, links",
			en: "Signal lamp              SL below, left/"
		}
		,A2V00002362201 : {
			de: "Scheinwerfermodul        rechts",
			en: "Head light module        right"
		}
		,A2V00002362221 : {
			de: "Scheinwerfermodul        links",
			en: "Head light module        left"
		}
		,A2V00002367260 : {
			de: "Fahrerraum-Fenster       links",
			en: "Drivers cab window       left"
		}
		,A2V00002367263 : {
			de: "Fahrerraum-Fenster       rechts",
			en: "Drivers cab window       right"
		}
		,A2V00002376414 : {
			de: "Luefter",
			en: "Fan"
		}
		,A2V00002194083 : {
			de: "Sechskantschraube",
			en: "Hexagon head screw"
		}
		,A2V00001948920 : {
			de: "Halter Notloese 1",
			en: "Emergencyrelease bra. 1"
		}
		,A2V00002406870 : {
			de: "Sandungsanlage Typ A Riyadh",
			en: "Sanding system typ A Riyadh"
		}
		,A2V00002424540 : {
			de: "Abdeckung komplett",
			en: "Cover Complete"
		}
		,A2V00002424541 : {
			de: "Betaetigungszylinder",
			en: "Operating Cylinder"
		}
		,A2V00002424544 : {
			de: "Ventil MRP",
			en: "Valve MRP"
		}
		,A2V00205391570 : {
			de: "Impulsgeber KMG-2H kompl. BR 189",
			en: "PULSE GENERATOR CPL. KMG-2H BR 189"
		}
		,A2V00205394922 : {
			de: "Impulsgeber KMG-2H",
			en: "Pulse generator KMG-2H"
		}
		,A2V00002439138 : {
			de: "Wellenbalg, vollst.",
			en: "Corrugated bellows, assy."
		}
		,A2V00002442392 : {
			de: "Oberarm, kpl. nur f.DB m.geaend.Pruefvor",
			en: "Upper arm, complete"
		}
		,A2V00201190911 : {
			de: "Sandtreppe KM-Ed",
			en: "Sanding step case KM-Ed"
		}
		,A2V00201472022 : {
			de: "Sandtreppe KM-ED",
			en: "Sanding step KM-ED"
		}
		,A2V00201001667 : {
			de: "Sandtreppengehaeuse",
			en: "Sand staircase casing"
		}
		,A2V00200691972 : {
			de: "Profil",
			en: "PROFILE"
		}
		,A2V00201472023 : {
			de: "Sandtreppe KM-ED",
			en: "Sanding step KM-ED"
		}
		,A2V00201102482 : {
			de: "Sandtreppe KM-1Sp",
			en: "Sand staircase KM 1Sp"
		}
		,A2V00207353919 : {
			de: "Schlauchleitung",
			en: "Schlauchleitung"
		}
		,A2V00207353916 : {
			de: "Schlauchleitung",
			en: "Schlauchleitung"
		}
		,A2V00201168178 : {
			de: "Profil,Gummi-",
			en: "in process Profil,Gummi-"
		}
		,A2V00207351013 : {
			de: "FÃ¼hrerstandsterminal neue Generation",
			en: "DISPLAY TERMINAL NEW GENERATION"
		}
		,A2V00207353246 : {
			de: "Stromabnehmer DC3kV NS/SNCB/SNCF",
			en: "Pantograph DC3kV NS/SNCB/SNCF"
		}
		,A2V00201475588 : {
			de: "Sandtreppe",
			en: "Sand staircase KM ED coated"
		}
		,A2V00201335770 : {
			de: "Dosierpumpe",
			en: "Dosing pump"
		}
		,A2V00201191035 : {
			de: "Sandtreppe KM-Ed links lackiert",
			en: "Sand stair KM-Ed left coated"
		}
		,A2V00002503764 : {
			de: "Frontschuerze LH",
			en: "Fairing  front end LH"
		}
		,A2V00002503803 : {
			de: "Frontschuerze RH",
			en: "Fairing  front end RH"
		}
		,A2V00002506303 : {
			de: "Elastomerfedergelenk",
			en: "Elastomer spring hinge"
		}
		,A2V00002508994 : {
			de: "NEA-Fenster klein        Pausenraum",
			en: "NEA-window small         break room"
		}
		,A2V00002508995 : {
			de: "Festfenster gross        WE1 links",
			en: "Fixed wiindow big CE1    left"
		}
		,A2V00002509007 : {
			de: "NEA-Fenster gross        ZuB-Abteil",
			en: "NEA-window big"
		}
		,A2V00002509009 : {
			de: "NEA-Fenster gross        Kleinkind-Abtei",
			en: "NEA-window big baby      section"
		}
		,A2V00002509010 : {
			de: "Festfenster gross        WE1 rechts",
			en: "Fixed wiindow big CE1    right"
		}
		,A2V00002509067 : {
			de: "NEA-Fenster klein",
			en: "Emergency window"
		}
		,A2V00002509156 : {
			de: "Festfenster klein        Bistro",
			en: "Fixed wiindow small     Bistro"
		}
		,A2V00002509157 : {
			de: "Seitenscheibe RW1 FGR",
			en: "side window RW1 FGR"
		}
		,A2V00002511538 : {
			de: "Bremssteuergeraet",
			en: "brake control device"
		}
		,A2V00002521083 : {
			de: "Notbremsventil",
			en: "Emergency brake valve"
		}
		,A2V00002527958 : {
			de: "Luefter",
			en: "Blower"
		}
		,A2V00002527960 : {
			de: "Luefter",
			en: "Blower"
		}
		,A2V00002528040 : {
			de: "Einstroemduese nach VT31077-23",
			en: "injection nozzle"
		}
		,A2V00002529783 : {
			de: "Schneerraeumer           links",
			en: "Snowplow                 left"
		}
		,A2V00002529914 : {
			de: "Schneerraeumer           rechts",
			en: "Snowplow                 right"
		}
		,A2V00200678697 : {
			de: "Blech",
			en: "sheet metal"
		}
		,A2V00207351480 : {
			de: "Buchholzrelais XB0269-01",
			en: "Buchholz relay XB0269-01"
		}
		,A2V00207353917 : {
			de: "Schlauchleitung",
			en: "flexible cord"
		}
		,A2V00201351820 : {
			de: "DC-Schnellschalter IR6040SV 110V",
			en: "High speed DC circuit breaker IR6040SV"
		}
		,A2V00002554279 : {
			de: "H1-Fernscheinwerfer",
			en: "H1-High-beam headlight"
		}
		,A2V00002561200 : {
			de: "Unterbau BWD 193",
			en: "Lower part BWD 193"
		}
		,A2V00397255187 : {
			de: "ROLLE ZU QUERSPIELBEGRENZUNG,LACKIERT",
			en: "ROLL F.MOVEMENT-LIMITATION,LACQUERED"
		}
		,A2V00397255185 : {
			de: "BOLZEN F.QUERSPIELBEGRENZUNGSROLLE,LACK.",
			en: "BOLT F.LIMITATIONROLL,LACQUERED"
		}
		,A2V00002101880 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00001139241 : {
			de: "Dachdurchfuehrung 25 KV, lange Ausf.",
			en: "Roof bushing 25 kV for locomotives"
		}
		,A2V00002597302 : {
			de: "Schraube",
			en: "Screw"
		}
		,A2V00000331958 : {
			de: "Grundblech, vollstaendig",
			en: "Base plate, complete"
		}
		,A2V00002605039 : {
			de: "KohleschleifstÃ¼ck",
			en: "contact piece"
		}
		,A2V00002617972 : {
			de: "Motorseitige Kupplungshaelfte",
			en: "Motor site coupling half"
		}
		,A2V00002618228 : {
			de: "Getriebeseitige Kupplungshaefte",
			en: "Gear site coupling half"
		}
		,A2V00002618230 : {
			de: "Getriebe komplett",
			en: "Gear"
		}
		,A2V00002630309 : {
			de: "Rampe seitlich, lang, Lift links",
			en: "Ramp side, long, lift left"
		}
		,A2V00002630310 : {
			de: "Rampe seitlich, kurz, Lift links",
			en: "Ramp side, short, lift left"
		}
		,A2V00002633462 : {
			de: "Kupplungshaelfte motorseitig",
			en: "Motor coupling"
		}
		,A2V00002683682 : {
			de: "Motorkupplung",
			en: "Motor coupling"
		}
		,A2V00002683683 : {
			de: "Getriebekupplung",
			en: "Drive coupling"
		}
		,A2V00201176973 : {
			de: "Sandtreppe KM-Ed-L",
			en: "Sanding step KM-Ed-L"
		}
		,A2V00201176974 : {
			de: "Sandtreppe KM-Ed-R",
			en: "Sanding step KM-Ed-R"
		}
		,A2V00201176969 : {
			de: "Sandtr. KM-1Sp lackiert",
			en: "Sand staircase KM 1Sp  coated"
		}
		,A2V00201189687 : {
			de: "Einschubduese 1,2/1,2",
			en: "slide-in cone 1,2/1,2"
		}
		,A2V00201336781 : {
			de: "Sandauslaufrohr 200V/15 W",
			en: "Sand run out pipe 200V/15 W"
		}
		,A2V00002695298 : {
			de: "Kit Druckbehaelter 0,5l inkl. O-Ring",
			en: "Kit Air reservoir 0,5l incl. O-Ring"
		}
		,A2V00002696352 : {
			de: "KIT Scroll Ventil SFR5 9,3bar",
			en: "KIT Scroll valve SFR5 9,3bar"
		}
		,A2V00002696353 : {
			de: "KITSicherheitsventil 8,5bar",
			en: "KIT Safety valve 8,5bar"
		}
		,A2V00002697300 : {
			de: "Bremsbelag",
			en: "brakepad"
		}
		,A2V00397160412 : {
			de: "Zahnplatte",
			en: "teeth plate"
		}
		,A2V00370060348 : {
			de: "BREMSSATTEL HYA 26/50 LI.",
			en: "BRAKE CALIPER HYA 26/50 LE."
		}
		,A2V00397242667 : {
			de: "Leiter spg., vollst.",
			en: "Ladder o.a.d., compl."
		}
		,A2V00397242666 : {
			de: "Leiter w.g., vollst.",
			en: "Ladder a.d., compl."
		}
		,A2V00397170663 : {
			de: "Dehnhuelse lackiert",
			en: "Bushing painted"
		}
		,A2V00370032063 : {
			de: "Puffer",
			en: "buffer"
		}
		,A2V00370060349 : {
			de: "BREMSSATTEL HYA 26/50 RE.",
			en: "BRAKE CALIPER HYA 26/50 RI."
		}
		,A2V00002715988 : {
			de: "Kompressoraggregat VV90-T",
			en: "Compressor Set VV90-T"
		}
		,A2V00002722057 : {
			de: "Uebergang, vollst.",
			en: "Gangway complete"
		}
		,A2V00002722058 : {
			de: "Uebergangshaelfte, vollst. Anbau",
			en: "Half gangway complete, mounted"
		}
		,A2V00156020268 : {
			de: "TRANSFORMATOR",
			en: "transformer"
		}
		,A2V00002754992 : {
			de: "Getriebe ASZA418",
			en: "Gear box ASZA418"
		}
		,A2V00207280047 : {
			de: "Sandtreppe KM Ed",
			en: "Sanding step KM Ed"
		}
		,A2V00002765266 : {
			de: "PWR TBK13H-1500W-3P3-1B3",
			en: "PWR TBK13H-1500W-3P3-1B3"
		}
		,A2V00002788694 : {
			de: "Gehaeuse kompl II Spindelz 8755961 m. DB",
			en: "Casing cmpl."
		}
		,A2V00002798147 : {
			de: "Sechskantschraube ISO4017-M10x45-8.8-A2F",
			en: "hexagon head screw ISO4017-M10x45-8.8-A2"
		}
		,A2V00002798149 : {
			de: "Sechskantschraube ISO4014-M24x180-10.9-D",
			en: "hexagon head screw ISO4014-M24x180-10.9-"
		}
		,A2V00002798925 : {
			de: "Druckbehaelter",
			en: "Air reservoir"
		}
		,A2V00002669069 : {
			de: "Mittellangtraeger F-Ende",
			en: "Longit. girder profile front end"
		}
		,A2V00002693797 : {
			de: "Klebeprofil",
			en: "sticking profile"
		}
		,A2V00002670634 : {
			de: "Abschlussprofil Boden",
			en: "End profile floor plate"
		}
		,A2V00002670622 : {
			de: "Abschlussprofil Boden",
			en: "End profile floor plate"
		}
		,A2V00002670623 : {
			de: "Klebeprofil",
			en: "Sticking profile"
		}
		,A2V00002707159 : {
			de: "Konsolenprofil",
			en: "console profile"
		}
		,A2V00002670620 : {
			de: "Abschlussprofil Boden",
			en: "End profile floor plate"
		}
		,A2V00002670621 : {
			de: "Abschlussprofil Boden",
			en: "End profile floor plate"
		}
		,A2V00002670624 : {
			de: "Klebeprofil",
			en: "sticking profile"
		}
		,A2V00002670625 : {
			de: "Klebeprofil",
			en: "sticking profile"
		}
		,A2V00002005151 : {
			de: "PrÃ¼fstÃ¼ck   BL6x125x300 30/0,5",
			en: "test piece   pl 6x125x300 30/0,5"
		}
		,A2V00002577129 : {
			de: "Stift mit Innengewinde M6x20",
			en: "stud with internal thread m6x20"
		}
		,A2V00100613142 : {
			de: "SEILANLENKUNG",
			en: "rope guidance"
		}
		,A2V00105059453 : {
			de: "LUFTHUTZE",
			en: "Air scoop"
		}
		,A2V00002673213 : {
			de: "Kupplung Radsatzwelle",
			en: "Coupling output"
		}
		,A2V00105274931 : {
			de: "Stosspuffer, Bug und Heck",
			en: "Buffer, front and rear"
		}
		,A2V00105398134 : {
			de: "STEG",
			en: "STEG"
		}
		,A2V00105398142 : {
			de: "STEG",
			en: "STEG"
		}
		,A2V00109386627 : {
			de: "Seitenscheibe",
			en: "Side window"
		}
		,A2V00002167358 : {
			de: "Abschlussblech",
			en: "end plate"
		}
		,A2V00002167425 : {
			de: "Abschlussblech",
			en: "end plate"
		}
		,A2V00002405981 : {
			de: "Geraetetafel 12 V4 lackiert",
			en: "Equipment panel 12 V4 coated"
		}
		,A2V00001896101 : {
			de: "Rueckwandschrank +14/+84 lackiert",
			en: "Rear wall cubicle +14/+84 laquered"
		}
		,A2V00397197179 : {
			de: "KONSOLBLECH 2 F.KLEMMENKASTENKONSOLE",
			en: "bracket sheet 2 f. clamping box bracket"
		}
		,A2V00397233297 : {
			de: "Lagerbock spg",
			en: "bearing  block oad"
		}
		,A2V00397225103 : {
			de: "Anhebeoese",
			en: "lifting lug"
		}
		,A2V00397225703 : {
			de: "Abschlussblech",
			en: "end sheet"
		}
		,A2V00397090040 : {
			de: "Gewindebutzen - M8x20x10",
			en: "Threaded slug 205-0007-M8x20x10"
		}
		,A2V00002690546 : {
			de: "Fahrmotor Metro Bangkok BLE2",
			en: "Driving motor Metro Bangkok BLE2"
		}
		,A2V00201103380 : {
			de: "Sandtreppe, Gussteil li m. Aussengewinde",
			en: "Sand staircase casing"
		}
		,A2V00201132712 : {
			de: "Kupplung,Brems-",
			en: "Brake-hose coupling H-1 inch x G1 1/4"
		}
		,A2V00201174976 : {
			de: "Aufstieg lack.",
			en: "Aufstieg lack."
		}
		,A2V00201178435 : {
			de: "Trafooelleitung",
			en: "transformer oil pipe"
		}
		,A2V00001921841 : {
			de: "Rippe f. Abstuetzung",
			en: "Rib for prop"
		}
		,A2V00002519777 : {
			de: "Halter fuer Rsk. sp.g.",
			en: "Holder for wheel o.a.d."
		}
		,A2V00002519826 : {
			de: "Halter fuer Rsk. w.g.",
			en: "Holder for wheel a.d."
		}
		,A2V00001917639 : {
			de: "Stuetzrohr 1 w.g.",
			en: "prop tube 1a.d."
		}
		,A2V00001917640 : {
			de: "Stuetzrohr 2 w.g.",
			en: "prop tube 2 a.d."
		}
		,A2V00002519757 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00002627999 : {
			de: "Drehmomentenstuetze",
			en: "torque reaction bar"
		}
		,A2V00201336793 : {
			de: "Sandtreppe, Gusst. li m. Innengewinde",
			en: "Sand staircase casing"
		}
		,A2V00201336794 : {
			de: "Sandtreppe, Gusst. lre m. Innengewinde",
			en: "Sand staircase casing"
		}
		,A2V00201475580 : {
			de: "Verschlussdeckel v",
			en: "Verschlussdeckel v"
		}
		,A2V00001258941 : {
			de: "BEILAGE t=1",
			en: "SHIM t=1"
		}
		,A2V00001914651 : {
			de: "Rippe 3",
			en: "Rib 3"
		}
		,A2V00001914652 : {
			de: "Rippe 4",
			en: "Rib 4"
		}
		,A2V00001914654 : {
			de: "Rippe 7",
			en: "Rib 7"
		}
		,A2V00001914671 : {
			de: "Rippe 5 WG",
			en: "Rib 5 AD"
		}
		,A2V00001914673 : {
			de: "Rippe 2",
			en: "Rib 2"
		}
		,A2V00001914675 : {
			de: "Rippe 6 WG",
			en: "Rib 6 AD"
		}
		,A2V00001914747 : {
			de: "Rippe 6 SPG",
			en: "Rib 6 OAD"
		}
		,A2V00001915711 : {
			de: "Aufnahme fuer Wirbelstrombremse",
			en: "Support for eddy-current brake"
		}
		,A2V00001915786 : {
			de: "Steg",
			en: "Web"
		}
		,A2V00001915841 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00001915856 : {
			de: "Steg 2",
			en: "Web 2"
		}
		,A2V00001914649 : {
			de: "Steg 1 WG",
			en: "Web 1 AD"
		}
		,A2V00001914666 : {
			de: "Steg 2",
			en: "Web 2"
		}
		,A2V00001914667 : {
			de: "Steg 1 SPG",
			en: "Web 1 OAD"
		}
		,A2V00002089984 : {
			de: "Sekundaerkonsole wg",
			en: "Secondary vertical"
		}
		,A2V00002106336 : {
			de: "Bremskonsole aussen",
			en: "Brake bracket, outer"
		}
		,A2V00001910892 : {
			de: "Steg 7 WG",
			en: "Web 7 AD"
		}
		,A2V00001910893 : {
			de: "Querspielbegrenzung",
			en: "lateral clearance limitation"
		}
		,A2V00001910894 : {
			de: "Steg 7 SPG",
			en: "Web 7 OAD"
		}
		,A2V00207311648 : {
			de: "Mittelflanschpuffer mit Hydr.Kapsel",
			en: "Flange Buffer with Hydr. Cap"
		}
		,A2V00002508853 : {
			de: "Federtopf fuer Primaerfeder",
			en: "Suspension pot for primary spring"
		}
		,A2V00002584320 : {
			de: "Achshalter",
			en: "Axle mounting"
		}
		,A2V00207352533 : {
			de: "Notbremsventil",
			en: "Emergency brake valve"
		}
		,A2V00207352648 : {
			de: "Fahrschaltereinheit M3525",
			en: "Master control unit M3525"
		}
		,A2V00207352669 : {
			de: "Schnellbremsventil",
			en: "Emergency brake valve"
		}
		,A2V00207353068 : {
			de: "Gleitschutzventil",
			en: "Dump valve"
		}
		,A2V00207354329 : {
			de: "Stuetzisolator",
			en: "Post insulator"
		}
		,A2V00082013502 : {
			de: "Flansch M20x1,5",
			en: "Flansch M20x1,5"
		}
		,A2V00002190480 : {
			de: "Distanzscheibe; Rohr- Kabeltraeger",
			en: "washer; pipe- cable support"
		}
		,A2V00207355957 : {
			de: "2/2 Magnetventil",
			en: "2/2 Way valve"
		}
		,A2V00207356853 : {
			de: "3/2-Magnetventil DC 24 V",
			en: "3/2 magnet valve DC 24 V"
		}
		,A2V00207357503 : {
			de: "Zughaken",
			en: "coupling hook"
		}
		,A2V00002626651 : {
			de: "Beilagenhaelfte 1mm",
			en: "Half shim 1mm"
		}
		,A2V00002206197 : {
			de: "Halteblech",
			en: "retaining plate"
		}
		,A2V00001960047 : {
			de: "Halter Rohr WSB2",
			en: "Bracket pipe WSB2"
		}
		,A2V00002055678 : {
			de: "Steg 2 QT LDG",
			en: "Web 2 CB"
		}
		,A2V00002055680 : {
			de: "Steg QT LDG",
			en: "Web CB"
		}
		,A2V00002055681 : {
			de: "Obergurt QT LDG",
			en: "Upper flange CB"
		}
		,A2V00002055682 : {
			de: "Rippe LM LDG",
			en: "Rib CB"
		}
		,A2V00001979842 : {
			de: "Huelse",
			en: "bush"
		}
		,A2V00001960010 : {
			de: "Rohrhalter WSB1",
			en: "Bracket WSB1"
		}
		,A2V00207471065 : {
			de: "FH-Aufstieg, geschw.",
			en: "FH-Aufstieg, geschw."
		}
		,A2V00207471067 : {
			de: "Rangieraufst.geschw.",
			en: "Shunting rise"
		}
		,A2V00002575635 : {
			de: "RS Lagerdeckel f. Odometer lack.",
			en: "WSB Cover for Odometer painted"
		}
		,A2V00002565392 : {
			de: "Beilage 5mm",
			en: "Shim 5mm"
		}
		,A2V00002185173 : {
			de: "Bremstraeger Steg SPG",
			en: "Brake beam web OAD"
		}
		,A2V00001366349 : {
			de: "Halter f. Notloesezug",
			en: "Holder f. emergency release"
		}
		,A2V00001366350 : {
			de: "Blech",
			en: "Sheet"
		}
		,A2V00002435631 : {
			de: "Rohr 80x5x200",
			en: "Pipe 80x5x200"
		}
		,A2V00001429663 : {
			de: "Steg Mitte",
			en: "Web centre"
		}
		,A2V00002055679 : {
			de: "Untergurt QT LDG",
			en: "Lower flange CB"
		}
		,A2V00002055936 : {
			de: "Steg Bremstraeger",
			en: "Web brake beam"
		}
		,A2V00002063630 : {
			de: "Drehkonsole WG",
			en: "Bracket AD"
		}
		,A2V00002063632 : {
			de: "Steg WG",
			en: "Web AD"
		}
		,A2V00002063633 : {
			de: "Rippe WG",
			en: "Rib AD"
		}
		,A2V00002063635 : {
			de: "Drehkonsole SPG",
			en: "Bracket OAD"
		}
		,A2V00002063648 : {
			de: "Obergurt SPG",
			en: "Upper flange AD"
		}
		,A2V00002063649 : {
			de: "Steg SPG",
			en: "Web OAD"
		}
		,A2V00002063651 : {
			de: "Rippe SPG",
			en: "Rib OAD"
		}
		,A2V00002063653 : {
			de: "Rippe WG",
			en: "Rib AD"
		}
		,A2V00002063655 : {
			de: "Obergurt WG",
			en: "Upper flange AD"
		}
		,A2V00002063730 : {
			de: "Rippe w.g.",
			en: "Rib a.d."
		}
		,A2V00002063731 : {
			de: "Rippe sp.g.",
			en: "Rib o.a.d."
		}
		,A2V00002063733 : {
			de: "Primaeranschlag",
			en: "Primary stop"
		}
		,A2V00002064019 : {
			de: "Obergurt, LDG",
			en: "Upper flange, TBF"
		}
		,A2V00002064020 : {
			de: "Oese",
			en: "Bail"
		}
		,A2V00002064068 : {
			de: "Wankkonsole LDG WG",
			en: "Antirollbar bracket TB AD"
		}
		,A2V00002064369 : {
			de: "Wankkonsole LDG SPG",
			en: "Antirollbar bracket  TB OAD"
		}
		,A2V00002064657 : {
			de: "Steg, LDG",
			en: "Web, TBF"
		}
		,A2V00002064837 : {
			de: "Untergurt, LDG",
			en: "Lower flange, TBF"
		}
		,A2V00002065641 : {
			de: "Badsicherung oben LDG",
			en: "Pool backing top TB"
		}
		,A2V00002065644 : {
			de: "Badsicherung unten LDG",
			en: "Pool backing bottom TB"
		}
		,A2V00002065890 : {
			de: "Pufferplatte",
			en: "Buffer mounting plate"
		}
		,A2V00002065891 : {
			de: "Rippe Querpufferkonsole",
			en: "Rip lateral buffer support"
		}
		,A2V00002339230 : {
			de: "Halter groÃŸ",
			en: "Bracket big"
		}
		,A2V00002339231 : {
			de: "Halter klein geschweiÃŸt",
			en: "Braket small welded"
		}
		,A2V00002426137 : {
			de: "Konsole 2",
			en: "Bracket 2"
		}
		,A2V00002428308 : {
			de: "Konsole",
			en: "Bracket"
		}
		,A2V00002059711 : {
			de: "Buchse f. Motoraufnahme 3",
			en: "Bush for motor bracket 3"
		}
		,A2V00002059424 : {
			de: "Steg 2",
			en: "Web 2"
		}
		,A2V00001974447 : {
			de: "Radsatzlagerdeckel fuer HMH",
			en: "wheelset box cover for HMH"
		}
		,A2V00002398221 : {
			de: "Grundrahmen, SBS-2T/SWT",
			en: "Base frame, SBS-2T/WP"
		}
		,A2V00002528094 : {
			de: "Lagerkonsole KopftrÃ¤ger - Roh",
			en: "Bush bracket Head beam - raw"
		}
		,A2V00002036886 : {
			de: "Drehdaempferkonsole WG, mech.",
			en: "Bracket for yaw damper AD, machined"
		}
		,A2V00002732170 : {
			de: "Schichtfederboden lack.",
			en: "Layer Spr. Bottom Paint."
		}
		,A2V00001721227 : {
			de: "Rohrhalter",
			en: "Pipe holder"
		}
		,A2V00001800285 : {
			de: "Halter",
			en: "Holder"
		}
		,A2V00001829597 : {
			de: "Halter fuer RSK",
			en: "Holder f. WP"
		}
		,A2V00002121978 : {
			de: "Abhebelasche",
			en: "Lifting off device braket"
		}
		,A2V00002392911 : {
			de: "UF Kabelkanal geschw MC1, MC2 - 2T, 4T",
			en: "UF Cable duct welded MC1, MC2 - 2T, 4T"
		}
		,A2V00002408607 : {
			de: "Kasten f. Feuerloescher",
			en: "Box f. extinguisher"
		}
		,A2V00597001340 : {
			de: "Unterlage",
			en: "Support plate"
		}
		,A2V00597001451 : {
			de: "Traeger",
			en: "Beam"
		}
		,A2V00597002063 : {
			de: "Profil CH58811 OZ",
			en: "Profile CH58811 WD"
		}
		,A2V00597002239 : {
			de: "Blech",
			en: "Plate"
		}
		,A2V00597002240 : {
			de: "Blech",
			en: "Plate"
		}
		,A2V00597002404 : {
			de: "Platte L",
			en: "Plate L"
		}
		,A2V00597002405 : {
			de: "Platte P",
			en: "Plate P"
		}
		,A2V00597002406 : {
			de: "Platte V",
			en: "Plate V"
		}
		,A2V00597002407 : {
			de: "Platte V",
			en: "Plate V"
		}
		,A2V00597002408 : {
			de: "Platte M",
			en: "Plate M"
		}
		,A2V00597002409 : {
			de: "Platte M",
			en: "Plate M"
		}
		,A2V00597002433 : {
			de: "Klemmenleiste     konsole",
			en: "Terminal console"
		}
		,A2V00597002434 : {
			de: "Abstuetzung Rinne",
			en: "Support tray"
		}
		,A2V00597002439 : {
			de: "Oese",
			en: "Eye"
		}
		,A2V00597003039 : {
			de: "Fuehrung 1",
			en: "Guide 1"
		}
		,A2V00597003040 : {
			de: "Fuehrung 2",
			en: "Guide 2"
		}
		,A2V00597003041 : {
			de: "Fuehrung 3",
			en: "Guide 3"
		}
		,A2V00597003042 : {
			de: "Fuehrung 4",
			en: "Guide 4"
		}
		,A2V00597003043 : {
			de: "Fuehrung 5",
			en: "Guide 5"
		}
		,A2V00597003044 : {
			de: "Fuehrung 6",
			en: "Guide 6"
		}
		,A2V00597003045 : {
			de: "Fuehrung 7",
			en: "Guide 7"
		}
		,A2V00597003046 : {
			de: "Fuehrung 8",
			en: "Guide 8"
		}
		,A2V00597003047 : {
			de: "Fuehrung 9",
			en: "Guide 9"
		}
		,A2V00597003048 : {
			de: "Fuehrung 10",
			en: "Guide 10"
		}
		,A2V00597003049 : {
			de: "Fuehrung 11",
			en: "Guide 11"
		}
		,A2V00597003050 : {
			de: "Fuehrung 12",
			en: "Guide 12"
		}
		,A2V00597003051 : {
			de: "Fuehrung 13",
			en: "Guide 13"
		}
		,A2V00597003052 : {
			de: "Fuehrung 14",
			en: "Guide 14"
		}
		,A2V00597003053 : {
			de: "Fuehrung 15",
			en: "Guide 15"
		}
		,A2V00002169284 : {
			de: "Rohr",
			en: "pipe"
		}
		,A2V00201338161 : {
			de: "Betaetigung,Fern-",
			en: "Betaetigung,Fern-"
		}
		,A2V00207475976 : {
			de: "Langtraeger",
			en: "longitudinal girder"
		}
		,A2V00001827958 : {
			de: "Kabelbaum -UB-W50B.010 Lok-Plattform X4",
			en: "Cable harness-UB-W50B.010 loco platf. X4"
		}
		,A2V00002076337 : {
			de: "Deckel f. Wegimpulsgeber",
			en: "cover f. path impulse generator"
		}
		,A2V00002076343 : {
			de: "Abstandhalter",
			en: "spacer"
		}
		,A2V00002621402 : {
			de: "Pufferschalter",
			en: "Limit switch"
		}
		,A2V00002577126 : {
			de: "Rohrhalter",
			en: "Pipe holder"
		}
		,A2V00002577127 : {
			de: "Erdungsbutzen M8",
			en: "Earthing button M8"
		}
		,A2V00002577128 : {
			de: "Stift mit Innengewinde M6x12",
			en: "stud with internal thread m6x12"
		}
		,A2V00207319711 : {
			de: "Langtraeger",
			en: "longitudinal girder"
		}
		,A2V00001827957 : {
			de: "Kabelbaum -UB-W50B.020 Lok-Plattform X4",
			en: "Cable harness-UB-W50B.020 loco platf. X4"
		}
		,A2V00207475980 : {
			de: "Langtraeger",
			en: "longitudinal girder"
		}
		,A2V00207475979 : {
			de: "Langtraeger",
			en: "longitudinal girder"
		}
		,A2V00002742323 : {
			de: "Fahrmotor m. Kupplungshaelfte",
			en: "Traction motor"
		}
		,A2V00002704899 : {
			de: "Radsatzwelle",
			en: "wheelset axle"
		}
		,A2V00001662665 : {
			de: "Federteller oben",
			en: "Spring plate top"
		}
		,A2V00384200281 : {
			de: "Stabelektrode BOE FOX EV 50 2,5X250",
			en: "Welding rod BOE FOX EV 50 2,5X250"
		}
		,A2V00002729882 : {
			de: "Langtraeger vollst. w.g. , KK-E 2",
			en: "longit. beam complete as d. , SC-E 2"
		}
		,A2V00002729867 : {
			de: "Langtraeger vollst. sp.g. , KK-E 2",
			en: "longit. beam complete r.i. , SC-E 2"
		}
		,A2V00002715322 : {
			de: "Langtraeger vollst. w.g.",
			en: "longit. beam complete as d."
		}
		,A2V00002715320 : {
			de: "Langtraeger vollst. sp.g.",
			en: "longit. beam complete r.i."
		}
		,A2V00002524383 : {
			de: "Blech w.g.",
			en: "Sheet as drawn"
		}
		,A2V00002524379 : {
			de: "Blech sp.g.",
			en: "Sheet sym.opp."
		}
		,A2V00002525287 : {
			de: "Knotenblech",
			en: "Gusset plate"
		}
		,A2V00002762175 : {
			de: "Crashbox vollst.",
			en: "crashbox complete"
		}
		,A2V00002606956 : {
			de: "Nutenstein 1xM24",
			en: "slot nut 1xM24"
		}
		,A2V00002606853 : {
			de: "Nutenstein 2xM24",
			en: "slot nut 2xM24"
		}
		,A2V00002162589 : {
			de: "Blech EN 10029 45X2000X4000 S355J2+N",
			en: "Sheet EN 10029 45X2000X4000 S355J2+N"
		}
		,A2V00002626029 : {
			de: "Gurtblech Fenster",
			en: "Chord plate window"
		}
		,A2V00002626578 : {
			de: "Dachlangtraeger, aeusserer w.g.",
			en: "Roof main beam, outside a.d."
		}
		,A2V00002626582 : {
			de: "Dachlangtraeger, aeusserer sp.g.",
			en: "Roof main beam, outside i.m.i."
		}
		,A2V00002626960 : {
			de: "Dachlangtraeger, aeusserer",
			en: "Roof main beam, outside"
		}
		,A2V00002683815 : {
			de: "Halter sp.g.",
			en: "Holder i.m.i."
		}
		,A2V00002683888 : {
			de: "Halter w.g.",
			en: "Holder a.d."
		}
		,A2V00002683942 : {
			de: "Halter sp.g.",
			en: "Holder i.m.i."
		}
		,A2V00002683943 : {
			de: "Halter w.g.",
			en: "Holder a.d."
		}
		,A2V00002718537 : {
			de: "Dachlangtraeger, innerer w.g.",
			en: "Roof main beam, inside a.d."
		}
		,A2V00002718538 : {
			de: "Dachlangtraeger, innerer",
			en: "Roof main beam, inside"
		}
		,A2V00002718539 : {
			de: "Dachlangtraeger, innen sp.g.",
			en: "Roof main beam, inside i.m.i."
		}
		,A2V00002689155 : {
			de: "Schubfeld SW",
			en: "Shear area side wall"
		}
		,A2V00002725116 : {
			de: "Schubfeld SW",
			en: "Shear area side wall"
		}
		,A2V00001406055 : {
			de: "Saeule unten",
			en: "Stick"
		}
		,A2V00001406054 : {
			de: "Saeule unten",
			en: "Stick"
		}
		,A2V00001405973 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001405972 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001405971 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001405970 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001405245 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001403316 : {
			de: "Saeule unten",
			en: "Stick"
		}
		,A2V00001400270 : {
			de: "Saeule oben",
			en: "Stick"
		}
		,A2V00001400269 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001400268 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001400267 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001400243 : {
			de: "Riegel",
			en: "Stick"
		}
		,A2V00001397772 : {
			de: "Saeule",
			en: "Stick"
		}
		,A2V00001397065 : {
			de: "Saeule unten",
			en: "Stick"
		}
		,A2V00001397064 : {
			de: "Saeule unten",
			en: "Stick"
		}
		,A2V00001397062 : {
			de: "Saeule oben",
			en: "Stick"
		}
		,A2V00001415810 : {
			de: "Spriegel",
			en: "Stick"
		}
		,A2V00001415583 : {
			de: "Spriegel",
			en: "Stick"
		}
		,A2V00002752641 : {
			de: "Dachplatte 2",
			en: "Roofplate 2"
		}
		,A2V00002752640 : {
			de: "Dachplatte 4",
			en: "Roofplate 4"
		}
		,A2V00002203141 : {
			de: "Anticlimber",
			en: "Anticlimber"
		}
		,A2V00002270161 : {
			de: "Vorbau FSTE",
			en: "Underframe extension cab end"
		}
		,A2V00002271048 : {
			de: "Vorbau KKE",
			en: "Underframe SCE"
		}
		,A2V00002547914 : {
			de: "Stirnwand",
			en: "End wall"
		}
		,A2V00002677390 : {
			de: "Seitenwandgerippe",
			en: "Side wall structure"
		}
		,A2V00002677391 : {
			de: "Dach vollst.",
			en: "Roof compl."
		}
		,A2V00002689899 : {
			de: "Dach vollst.",
			en: "Roof compl."
		}
		,A2V00002690746 : {
			de: "Seitenwandgerippe",
			en: "Side wall structure"
		}
		,A2V00002497407 : {
			de: "Blechabdeckung",
			en: "Cover sheet"
		}
		,A2V00397200820 : {
			de: "Steg, innen",
			en: "Web"
		}
		,A2V00397213412 : {
			de: "Rippe  WG",
			en: "Stiffener AD"
		}
		,A2V00397213413 : {
			de: "Rippe SPG",
			en: "Stiffener OAD"
		}
		,A2V00002736735 : {
			de: "Dichtrahmen 2, Profil",
			en: "Sealing frame 2, Profile"
		}
		,A2V00002736906 : {
			de: "Dichtrahmen 1, Profil",
			en: "Sealing frame 1, Profile"
		}
		,A2V00002736907 : {
			de: "Dichtrahmen 3, Profil",
			en: "Sealing frame 3, Profile"
		}
		,A2V00002736909 : {
			de: "Dichtrahmen 4, Profil",
			en: "Sealing frame 4, Profile"
		}
		,A2V00002736910 : {
			de: "Dichtrahmen 5, Profil",
			en: "Sealing frame 5, Profile"
		}
		,A2V00002792079 : {
			de: "E-Tunnel",
			en: "E-tunnel"
		}
		,A2V00002771675 : {
			de: "Dachlangtr. KKE sp.g.",
			en: "Centrail SCE, i.m.i."
		}
		,A2V00002766876 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002766877 : {
			de: "Schweissbadsicherung",
			en: "Weld backing"
		}
		,A2V00002794319 : {
			de: "Dichtrahmen 3, Zusb.",
			en: "Sealing frame 3, assy."
		}
		,A2V00002756898 : {
			de: "Untergestell, ZSB",
			en: "Underframe, Assy."
		}
		,A2V00002757007 : {
			de: "Seitenwand WE w.g.",
			en: "Side wall CE a.d."
		}
		,A2V00002757010 : {
			de: "Seitenwand mitte",
			en: "Side wall center"
		}
		,A2V00002757011 : {
			de: "Seitenwand WE sp.g.",
			en: "Side wall CE i.m.i."
		}
		,A2V00002757081 : {
			de: "Seitenwand mitte",
			en: "Side wall center"
		}
		,A2V00002757134 : {
			de: "Untergestell, ZSB",
			en: "Underframe, Assy."
		}
		,A2V00002752439 : {
			de: "Dichtrahmen 1, Zusb.",
			en: "Sealing frame 1, assy."
		}
		,A2V00002752429 : {
			de: "Dichtrahmen 2, Zusb.",
			en: "Sealing frame 2, assy."
		}
		,A2V00002756891 : {
			de: "Seitenwandsaeule 1 vollst.",
			en: "Side wall pillar 1 compl."
		}
		,A2V00002756892 : {
			de: "Seitenwandsaeule 2 vollst.",
			en: "Side wall pillar 2 compl."
		}
		,A2V00002756893 : {
			de: "Fenstersaeule 1 vollst.",
			en: "Window pillar 1 compl."
		}
		,A2V00002757053 : {
			de: "Fenstersaeule 2 vollst.",
			en: "Window pillar 2 compl."
		}
		,A2V00002793510 : {
			de: "Stirnwand WE1 vollst.",
			en: "Endwall CE1 compl."
		}
		,A2V00002796365 : {
			de: "Stirnwand WE2 vollst.",
			en: "Endwall  CE2 compl."
		}
		,A2V00002730738 : {
			de: "Kabelhalter DG vollst.",
			en: "Cable bracket d.a. compl."
		}
		,A2V00002765348 : {
			de: "Blech EN 10051 2X1170X6900 S460MC",
			en: "Sheet EN 10051 2X1170X6900 S460MC"
		}
		,A2V00002765344 : {
			de: "Blech EN 10029 6X1600X6000 S355J2W+N",
			en: "Sheet EN 10029 6X1600X6000 S355J2W+N"
		}
		,A2V00002765341 : {
			de: "Blech EN 10029 5X1600X5250 S355J2W+N",
			en: "Sheet EN 10029 5X1600X5250 S355J2W+N"
		}
		,A2V00002765325 : {
			de: "Blech EN 10051 2X1220X4350 S355J2W+N",
			en: "Sheet EN 10051 2X1220X4350 S355J2W+N"
		}
		,A2V00002765324 : {
			de: "Blech EN 10051 2X1220X5000 S355J2W+N",
			en: "Sheet EN 10051 2X1220X5000 S355J2W+N"
		}
		,A2V00002765322 : {
			de: "Blech EN 10131 1,2X1500X7100 HC380LA",
			en: "Sheet EN 10131 1,2X1500X7100 HC380LA"
		}
		,A2V00002778242 : {
			de: "Scheibe - 200HV - J45",
			en: "Washer - 200HV - J45"
		}
		,A2V00002790942 : {
			de: "Seitenwandfeld vollst.",
			en: "Sidewall Field compl."
		}
		,A2V00000507760 : {
			de: "Filterplatte",
			en: "Filter plate"
		}
		,A2V00000507761 : {
			de: "Adapter fuer Filterplatte",
			en: "Adaptor for filter plate"
		}
		,A2V00002091385 : {
			de: "Sechskantmutter ISO 7040 M30 8",
			en: "Hexagon nut ISO 7040 M30 8"
		}
		,A2V00001082330 : {
			de: "Fahrzeuggeraet LZB 513 V-Wagen",
			en: "on-board unit LZB513 V-Wagen Wien"
		}
		,A2V00001082333 : {
			de: "Kontaktschiene",
			en: "contact track"
		}
		,A2V00001374302 : {
			de: "Gehaeuseabdeckung ohne Bremse",
			en: "Gehaeuseabdeckung ohne Bremse"
		}
		,A2V00001374293 : {
			de: "Labyrinthabdeckung OD 136",
			en: "Labyrinthabdeckung OD 136"
		}
		,A2V00001374315 : {
			de: "Labyrinthabdeckung OD 136",
			en: "Labyrinthabdeckung OD 136"
		}
		,A2V00001374318 : {
			de: "Gehaeuseabdeckung mit Bremse",
			en: "Gehaeuseabdeckung mit Bremse"
		}
		,A2V00002253538 : {
			de: "Sicherungsscheibe VS12 Schnorr",
			en: "Lock washer VS12 Schnorr"
		}
		,A2V00001135273 : {
			de: "Kontaktwinkel",
			en: "contact angle"
		}
		,A2V00001135326 : {
			de: "Buegel",
			en: "Bracket"
		}
		,A2V00001135975 : {
			de: "Kontaktschiene",
			en: "contact track"
		}
		,A2V00001136398 : {
			de: "Kontaktschiene",
			en: "contact track"
		}
		,A2V00001149577 : {
			de: "Kontaktschiene",
			en: "shield bus"
		}
		,A2V00001149715 : {
			de: "Befestigungswinkel",
			en: "mounting bracket"
		}
		,A2V00001153776 : {
			de: "Steckerplatte komplett",
			en: "connector panal complete"
		}
		,A2V00001859902 : {
			de: "Montageplatte",
			en: "Subplate (weldment)"
		}
		,A2V00397068687 : {
			de: "FL DIN1017-10x5X40-S235JRG2",
			en: "flat steel DIN1017-10x5X40-S235JRG2"
		}
		,A2V00082010724 : {
			de: "Montageplatte",
			en: "Mounting plate"
		}
		,A2V00002423938 : {
			de: "Schaltschrank AP 500x500x210 Scharnier",
			en: "Cabinet AP 500x500x210 hinge"
		}
		,A2V00002621414 : {
			de: "Schaltschrank AP 500x500x210 Scharnier",
			en: "Cabinet AP 500x500x210 hinge"
		}
		,A2V00080099699 : {
			de: "Filterblech",
			en: "Filter plate"
		}
		,A2V00082013052 : {
			de: "Adapter",
			en: "adapter"
		}
		,A2V00082023204 : {
			de: "Kontaktschiene",
			en: "Contact track"
		}
		,A2V00082040449 : {
			de: "Hintere Tragplatte (SchweiÃŸteil)",
			en: "support plate (weldment)"
		}
		,A2V00082041174 : {
			de: "Untere Tragplatte komplett",
			en: "Lower support plate complete"
		}
		,A2V00001379729 : {
			de: "Erdungsbuchse",
			en: "grounding sleeve"
		}
		,A2V00002256230 : {
			de: "Seitenwandprofil Obergurt",
			en: "Side wall profile upper binder"
		}
		,A2V00002332279 : {
			de: "Traegerblech",
			en: "Beam sheet"
		}
		,A2V00002059401 : {
			de: "Untergurt",
			en: "Lower flange"
		}
		,A2V00002059539 : {
			de: "Obergurt 1",
			en: "Upper flange 1"
		}
		,A2V00002061484 : {
			de: "Rippe",
			en: "Rib"
		}
		,A2V00002061998 : {
			de: "Motoraufnahme 1",
			en: "Motor bracket 1"
		}
		,A2V00002072514 : {
			de: "Halter fuer Steuerventil",
			en: "Control valve bracket"
		}
		,A2V00002134246 : {
			de: "Untergurt",
			en: "Lower flange"
		}
		,A2V00002772892 : {
			de: "Dachlangtr. A-Wg, w.g.",
			en: "Centrail A-car, a.d."
		}
		,A2V00002773050 : {
			de: "DLT-oberer A-Wg, w.g.",
			en: "CR upper A-car, a.d."
		}
		,A2V00002772893 : {
			de: "Dachlangtr. A-Wg,sp.g.",
			en: "Centrail A-car, i.m.i."
		}
		,A2V00002773049 : {
			de: "DLT-oberer A-Wg, sp.g.",
			en: "CR upper A-car, i.m.i."
		}
		,A2V00002614111 : {
			de: "Rippe HQT, sp.g.",
			en: "Rib MCG, i.m.i."
		}
		,A2V00002614107 : {
			de: "Rippe HQT, w.g.",
			en: "Rib MCG, a.d."
		}
		,A2V00001893783 : {
			de: "Rohr fuer Schienenraeumer",
			en: "Pipe for rail guard"
		}
		,A2V00001873215 : {
			de: "Rohr f. Bremskonsole",
			en: "Pipe f. brake support"
		}
		,A2V00001950292 : {
			de: "Rohrhalter 1",
			en: "Pipebracket 1"
		}
		,A2V00001950303 : {
			de: "Rohrhalter 2",
			en: "Pipebracket 2"
		}
		,A2V00001415222 : {
			de: "Erdungslasche M12",
			en: "Earthing bracket M12"
		}
		,A2V00002801660 : {
			de: "DLT-oberer M-Wg, w.g.",
			en: "CR upper M-car, a.d."
		}
		,A2V00002801659 : {
			de: "DLT-oberer M-Wg, sp.g.",
			en: "CR upper M-car, i.m.i."
		}
		,A2V00002083157 : {
			de: "Rippe fuer Laengsmitnahme",
			en: "Rib for traction unit"
		}
		,A2V00002083159 : {
			de: "Steg 1 fuer Laengsmitnahme",
			en: "Web 1 for traction link"
		}
		,A2V00002083171 : {
			de: "Gurt fuer Laengsmitnahme",
			en: "Boom for traction unit"
		}
		,A2V00002083176 : {
			de: "Konsole fuer Laengsmitnahme",
			en: "Bracket for traction link"
		}
		,A2V00002697878 : {
			de: "Frontmaske (RTL)",
			en: "Front mask (RTL)"
		}
		,A2V00002560198 : {
			de: "E-Schrank +59.10 vormontiert.",
			en: "E-Schrank +59.10 vormontiert."
		}
		,A2V00002529554 : {
			de: "E-Box +67.12 vormontiert.",
			en: "E-Box +67.12 vormontiert"
		}
    }
    
    return materials;

}
