import React from 'react';
import { NavLink } from 'react-router-dom';
import MdVisibility from 'react-icons/lib/md/visibility';
import MdCreate from 'react-icons/lib/md/create';
import { FormattedMessage } from "react-intl";

const BigButtonsComponent = ({ role }) => {

    return <div className="card" id="bigButtons" style={{ padding: 50, maxWidth: 900, margin: '100px auto' }}
        hidden={!(window.location.origin.length === window.location.href.length - 1 || window.location.origin.concat('/#') === window.location.href)}>
        <div className="d-flex justify-content-center">
            <div hidden={!(role === '')}>
                This account is pending on approval.
            </div>
            <NavLink to="/create-certificate" className="nav-link" href="#" hidden={!(role === "isSupplier")}>
                <button className="btn btn-outline-dark" style={{ width: 300, height: 300 }}><MdCreate size="5em" /><br /><FormattedMessage id="navBar.createCertificate" /></button>
            </NavLink>
            <span style={{ width: 80 }} />
            <NavLink to="/show-certificate" className="nav-link" href="#" hidden={!(role === "isSupplierOrSiemens" || role === "isSupplier" || role === "isSiemensAdmin")}>
                <button className="btn btn-outline-dark" style={{ width: 300, height: 300 }}><MdVisibility size="5em" /><br /><FormattedMessage id="navBar.showCertificate" /></button>
            </NavLink>
            <NavLink to="/retrieve-companies" className="nav-link" href="#" hidden={!(role === "isSiemensAdmin")}>
                <button className="btn btn-outline-dark" style={{ width: 300, height: 300 }}><MdCreate size="5em" /><br /> Pending Companies</button>
            </NavLink>
        </div>
    </div>
};

export default BigButtonsComponent;

