import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
//import { ReactComponent as logo } from '../resources/img/siemens-ag-logo.svg';
import { ReactSVG } from 'react-svg';
import { Navigate, useNavigate } from 'react-router-dom';
import iconMailTo from '../resources/img/icon_mailto.svg';

import { Modal, Button, ModalHeader } from 'react-bootstrap';
import MdInfo from 'react-icons/lib/md/info-outline';
import MdLanguage from 'react-icons/lib/md/language';
import LoadingComponent from '../components/LoadingComponent';
import Messages from '../messages';
import { FormattedMessage } from 'react-intl';
import OidcProvider from '../services/OidcProvider';
import Footer from '../components/Footer';
import { ApiService } from '../services/ApiService';

const url = window.location.origin;
const globalMessages = Messages;

let languageText = localStorage.getItem('language') === "en-US" ? 'English (US)' : 'Deutsch (DE)';

export class CreateUserContainer extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            userScope: '',
            userCreated: '',
            companyName: '',
            companyZipCode: '',
            companyStreet: '',
            companyCity: '',
            companyCountry: '',
            showModal: false,
            companyNameValid: false,
            companyZipCodeValid: false,
            companyStreetValid: false,
            companyCityValid: false,
            companyCountryValid: false,
            showLoadingModal: false,
            formValid: false,
            formErrors: {
                companyName: '',
                companyZipCode: '',
                companyStreet: '',
                companyCity: '',
                companyCountry: '',
            }
        };

    };


    componentDidMount() {       
        
        let user = this.getUser();
        if (user.created || !user) {
            this.setState({
                userCreated: true
            });
        }

    };

    getUser = () => {
        const userData = localStorage.getItem('oidc_user');
        if(!userData) {
            return null;
        }

        const user = JSON.parse(userData);

        // check if expired
        if(user.expires_at * 1000 < Date.now()) {
            //expired
            localStorage.removeItem('oidc_user');
            return null;
        }

        return user;
    };

    showLanguageModal = () => {
        this.setState({
            languageModal: true
        });
    };

    chooseLanguage = (e) => {
        e.target.innerText === "ENGLISH" ? localStorage.setItem('language', 'en-US') : localStorage.setItem('language', 'de-DE');
        window.location.reload();
    };

    handleChange = (e) => {

        let name;
        let value;

        if (e != null) {

            name = e._isAMomentObject ? name = "inspectionDate" : e.target.name;
            value = e._isAMomentObject ? value = e : (e.target.type === "checkbox" ? value = e.target.checked : e.target.value);

            switch (name) {
                case "companyName": {
                    this.setState({ companyName: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "companyZipCode": {
                    this.setState({ companyZipCode: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "companyStreet": {
                    this.setState({ companyStreet: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "companyCity": {
                    this.setState({ companyCity: value }, () => { this.validateField(name, value) });
                    break;
                }
                case "companyCountry": {
                    this.setState({ companyCountry: value }, () => { this.validateField(name, value) });
                    break;
                }
                
                default: {
                    break;
                }

            }
        }
    };

    validateField(fieldName, value) {

        let fieldValidationErrors = this.state.formErrors;
        let companyNameValid = this.state.companyNameValid;
        let companyZipCodeValid = this.state.companyZipCodeValid;
        let companyStreetValid = this.state.companyStreetValid;
        let companyCityValid = this.state.companyCityValid;
        let companyCountryValid = this.state.companyCountryValid;

        switch (fieldName) {
            case "companyName": {
                companyNameValid = value.length >= 5;
                fieldValidationErrors.companyName = companyNameValid ? '' : ` has to be 5 characters or more. Total of characters: ${value.length}`;
                break;
            }
            case "companyZipCode": {
                companyZipCodeValid = value.length >= 2;
                fieldValidationErrors.companyZipCode = companyZipCodeValid ? '' : ` has to be 5 characters or more. Total of characters: ${value.length}`;
                break;
            }
            case "companyStreet": {
                companyStreetValid = value.length >= 5;
                fieldValidationErrors.companyStreet = companyStreetValid ? '' : ` has to be 5 characters or more. Total of characters: ${value.length}`;
                break;
            }
            case "companyCity": {
                companyCityValid = value.length >= 2;
                fieldValidationErrors.companyCity = companyCityValid ? '' : ` has to be 5 characters or more. Total of characters: ${value.length}`;
                break;
            }
            case "companyCountry": {
                companyCountryValid = value.length >= 5;
                fieldValidationErrors.companyCountry = companyCountryValid ? '' : ` has to be 5 characters or more. Total of characters: ${value.length}`;
                break;
            }

            
            default: {
                break;
            }
        }

        this.setState({
            formErrors: fieldValidationErrors,
            companyNameValid: companyNameValid,
            companyZipCodeValid: companyZipCodeValid,
            companyStreetValid: companyStreetValid,
            companyCityValid: companyCityValid,
            companyCountryValid: companyCountryValid,
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid: this.state.companyNameValid
                && this.state.companyZipCodeValid
                && this.state.companyStreetValid
                && this.state.companyCityValid
                && this.state.companyCountryValid
            });
    }

    errorClass = (error) => {
        return (error.length === 0 ? '' : 'is-invalid');
    };

    handleOnboard = () => {

        if (this.state.formValid) {
            this.setState({
                showLoadingModal: true
            });
        }

        let user = this.getUser();
        let userId = '';
        if (user.profile["sub"].includes("Siemens")) {
            userId = (user.profile["sub"].split("|")[4]).replace(/\s+/g, " ").trim();
            this.setState({
                userScope: 'Siemens',
                userId: userId
            });
        } else {
            userId = (user.profile["sub"].split("|")[3]).replace(/\s+/g, " ").trim();
            this.setState({
                userScope: 'Supplier',
                userId: userId
            });
        }

        const company = {
            name: this.state.companyName,
            street: this.state.companyStreet,
            zip: this.state.companyZipCode,
            city: this.state.companyCity,
            country: this.state.companyCountry,
        };

        const certblockAPI = new ApiService();
        const IAM_API = certblockAPI.getIAM_API(user.id_token);

        IAM_API.userOnboard({
            name: this.state.companyName, 
            city: this.state.companyCity, 
            country: this.state.companyCountry, 
            zip: this.state.companyZipCode,
            street: this.state.companyStreet,
            testers: [], 
            extra: '',
            materialTests: []
        })
        .then((user) => {
            console.log(`[Info] User ${user}`);
        })
        .catch((error) => {
            console.log(`[Error] ${error}`);
        })
        .finally(() => {
            this.setState({
                showLoadingModal: false
            });
            localStorage.removeItem('oidc_user');
            window.location.reload(false);
        });
        

    };

    handleLogin = () => {

    };

    render() {

        return (
            <div>
                {/* <FormErrors formErrors={this.state.formErrors} /> */}
                {this.state.userCreated && (
                    <Navigate to="/" replace={true} />
                )}
                <div>
                    <Modal animation={false} show={this.state.languageModal}>
                        <ModalHeader>
                            <Modal.Title><FormattedMessage id="commonMessages.setLanguage" /></Modal.Title>
                        </ModalHeader>
                        <Modal.Body>
                            <div className="list-group">
                                <a className="list-group-item list-group-item-action" value="en-US" href="#" onClick={this.chooseLanguage}>ENGLISH</a>
                                <a className="list-group-item list-group-item-action" value="de-DE" href="#" onClick={this.chooseLanguage}>DEUTSCH</a>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
                <div className="container col-xl-5 col-lg-8 col-md-8 col-sm-12" style={{ marginTop: 30 }}>
                    <form onSubmit={this.handleSubmit}>
                        <div className="card d-flex justify-content-end" style={{ backgroundColor: '#60a1a1', padding: 5 }}>
                            <a href="#" onClick={this.showLanguageModal} style={{ textDecoration: 'none', color: 'white', fontSize: 11 }}><MdLanguage size={16} />&nbsp;{languageText}</a>
                        </div>
                        <div className="card" style={{ backgroundColor: '#343a40', padding: 20, marginTop: 5 }}>
                            <ReactSVG src='../resources/img/siemens-ag-logo.svg' className="nav-logo" alt="logo" />
                            <h4 className="d-flex justify-content-center" style={{ color: '#FFF', marginTop: 20 }}>
                                CertBlock &nbsp;|&nbsp; 
                                {this.props.type === 'supplier' && <FormattedMessage id="createSupplier.onboardSupplier" /> }
                                {this.props.type === 'siemens' && <FormattedMessage id="createSiemens.onboardSiemens" /> }
                            </h4>
                        </div>
                        <div className="card" style={{ padding: 20, marginTop: 8 }}>
                            <div className="form-row">
                                <div className="form-group col-md-12 col-sm-12">
                                    <label htmlFor="companyNameInput"><FormattedMessage id="createSupplier.name" />*</label>
                                    <input name="companyName" type="text" onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.companyName)}`} id="companyNameInput" placeholder={globalMessages[localStorage.getItem('language')].createSupplier.namePlaceHolder} />
                                    <div className="invalid-feedback"><FormattedMessage id="createSupplier.name" /> {this.state.formErrors.companyName}</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-9 col-sm-12">
                                    <label htmlFor="companyStreetInput"><FormattedMessage id="createSupplier.street" />*</label>
                                    <input name="companyStreet" type="text" onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.companyStreet)}`} id="companyStreetInput" placeholder={globalMessages[localStorage.getItem('language')].createSupplier.streetPlaceHolder} />
                                    <div className="invalid-feedback"><FormattedMessage id="createSupplier.street" /> {this.state.formErrors.companyStreet}</div>
                                </div>
                                <div className="form-group col-md-3 col-sm-12">
                                    <label htmlFor="companyZipCodeInput"><FormattedMessage id="createSupplier.zipcode" />*</label>
                                    <input name="companyZipCode" type="text" onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.companyZipCode)}`} id="companyZipCodeInput" placeholder={globalMessages[localStorage.getItem('language')].createSupplier.zipcodePlaceHolder} />
                                    <div className="invalid-feedback"><FormattedMessage id="createSupplier.zipcode" />{this.state.formErrors.companyZipCode}</div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="companyCityInput"><FormattedMessage id="createSupplier.city" />*</label>
                                <input name="companyCity" type="text" onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.companyCity)}`} id="companyCityInput" placeholder={globalMessages[localStorage.getItem('language')].createSupplier.cityPlaceHolder} />
                                <div className="invalid-feedback"><FormattedMessage id="createSupplier.city" /> {this.state.formErrors.companyCity}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="companyCountryInput"><FormattedMessage id="createSupplier.country" />*</label>
                                <input name="companyCountry" type="text" onChange={this.handleChange} className={`form-control ${this.errorClass(this.state.formErrors.companyCountry)}`} id="companyCountryInput" placeholder={globalMessages[localStorage.getItem('language')].createSupplier.countryPlaceHolder} />
                                <div className="invalid-feedback"><FormattedMessage id="createSupplier.country" /> </div>
                            </div>
                        </div>



                        
                        <div className="container" style={{ padding: 20, marginTop: 30, marginBottom: 30 }}>
                            <div className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                <button type="button" onClick={this.handleOnboard} className="btn btn-primary col-4" disabled={!this.state.formValid} >
                                    {this.props.type === 'supplier' && <FormattedMessage id="createSupplier.onboardSupplier" />}
                                    {this.props.type === 'siemens' && <FormattedMessage id="createSiemens.onboardSiemens" />}
                                    </button>
                            </div>
                        </div>

                        <div>

                        </div>
                        <LoadingComponent hidden={this.state.showLoadingModal} />
                    </form>
                </div>
                <Footer />
            </div >
        );
    }
}

export default CreateUserContainer
