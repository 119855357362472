import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';

const RefLinkComponent = () => {

    const [redirect, setRedirect] = useState();
    let navigate = useNavigate();

    useEffect(() => {
        if (redirect) {
            navigate("/show-certificate", { replace: true });
        }
    }, [redirect]);

    let { orderNumber, orderPosition, deliveryNumber } = useParams(); 

    sessionStorage.setItem('orderNumber', orderNumber);
    sessionStorage.setItem('orderPosition', orderPosition);
    sessionStorage.setItem('deliveryNumber', deliveryNumber);

    if (!redirect) {
        setRedirect(true);
    }
    

    
    return null



};

export default RefLinkComponent;